import { useContext, useEffect, useState } from 'react';
import { IDefaultLayoutContext } from 'common/components/layout/types';
import { DefaultLayoutContext } from 'common/components/layout/default-layout';
import { getScale } from 'common/components/zoom/zoom';
import { IResponsiveLayout, IResponsiveLayoutProps } from './types';

export const useResponsiveLayout = ({ contentWrapper, onResize }: IResponsiveLayoutProps): IResponsiveLayout => {
    const [mediaClass, setMediaClass] = useState('');
    const { sidebarWidth }: IDefaultLayoutContext = useContext(DefaultLayoutContext);

    useEffect(() => {
        const handleResize = () => {
            const width = getScale() * window.innerWidth;
            const wrapperEl = typeof contentWrapper === 'string' ? document.querySelector(contentWrapper) : contentWrapper;
            const contentWidth = getScale() * (wrapperEl?.clientWidth || document.body.clientWidth);
            setMediaClass(onResize(width, contentWidth));
        };

        handleResize(); // case: user came from another page
        setTimeout(handleResize, 300); // case: navbar size changed
        window.addEventListener('resize', handleResize);
        window.addEventListener('zoomChanged', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
            window.removeEventListener('zoomChanged', handleResize);
        };
    }, [sidebarWidth]);

    return { mediaClass };
};
