export const EntityStatus = {
    ACTIVE: { label: 'Active', value: 'Active' },
    ARCHIVED: { label: 'Archived', value: 'Archived' },
};

export const displayEntityStatus = (value: string) => {
    if (!value) {
        return '';
    }

    return Object.values(EntityStatus).find((x) => x.value === value)?.label;
};
