import React, { useEffect, useMemo, useState } from 'react';
import { IRequestCaseDetails } from './types';
import { IFormSidebar } from 'common/models/types';
import useFormSidebar from 'common/hooks/use-form-sidebar';
import RightSideBarForm from 'common/components/right-side-bar/right-side-bar-form';
import { IRequestDetails } from 'common/services/api/requests/types';
import RequestCaseForm from './request-case-form';
import RequestService from 'common/services/api/requests/request-service';
import { toLowerFirstSymbol } from 'common/helpers/validation-helper';
import { departmentKey } from 'common/constants/department-key';

const RequestCaseSidebar = ({ data: requestDetails, onClose, onSave }: IFormSidebar<IRequestCaseDetails, IRequestDetails>) => {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const [data, error, handleChangeData, handleCancelClick, handleSaveClick, setData, setErrorData] = useFormSidebar<any>(
        {
            therapyTypeIds: requestDetails?.therapyType?.id ? [requestDetails?.therapyType?.id] : [],
            source: requestDetails?.source,
            customerId: requestDetails?.customer?.id,
            facilityId: requestDetails?.facility?.id,
            populationId: requestDetails?.population?.id,
            languageId: requestDetails?.language?.id,
            internalReferralSourceId: requestDetails?.internalReferralSource?.id,
            externalReferralSourceId: requestDetails?.externalReferralSource?.id,
            caseManagerId: requestDetails?.caseManager?.id,
            primaryInsuranceId: requestDetails?.primaryInsurance?.id,
            secondaryInsuranceId: requestDetails?.secondaryInsurance?.id,
            medicalAssistanceProgramId: requestDetails?.medicalAssistanceProgram?.id,
            zipCode: requestDetails?.zipCode,
            specialInstructions: requestDetails?.specialInstructions,
            address1: requestDetails?.address?.address1,
            address2: requestDetails?.address?.address2,
            city: requestDetails?.address?.city,
            stateId: requestDetails?.address?.state?.id,
            countyId: requestDetails?.address?.county?.id,
            zip: requestDetails?.address?.postalCode,
            labels: {
                stateId: requestDetails?.address?.state?.name,
                countyId: requestDetails?.address?.county?.name,
                therapyTypeIds: requestDetails?.therapyType?.name,
                customerId: requestDetails?.customer?.name,
                facilityId: requestDetails?.facility?.name,
                languageId: requestDetails?.language?.name,
                internalReferralSourceId: requestDetails?.internalReferralSource?.fullName,
                externalReferralSourceId: requestDetails?.externalReferralSource?.name,
                caseManagerId: requestDetails?.caseManager?.name,
                primaryInsuranceId: requestDetails?.primaryInsurance?.name,
                secondaryInsuranceId: requestDetails?.secondaryInsurance?.name,
                medicalAssistanceProgramId: requestDetails?.medicalAssistanceProgram?.name,
            },
        },
        {
            onClose,
            onSave: (data) => {
                return onSave({
                    ...data,
                    address: {
                        address1: data.address1,
                        address2: data.address2,
                        city: data.city,
                        stateId: data.stateId,
                        countyId: data.countyId,
                        zip: data.zip,
                    },
                });
            },
        }
    );

    const isAddressTouched = useMemo(() => {
        return data.address1 || data.city || data.countyId;
    }, [data.address1, data.city, data.countyId]);

    const generateAddressClassName = useMemo(() => {
        if (isAddressTouched) {
            return 'edit-field required-field-star';
        } else {
            setErrorData((prevError: any) => ({ ...prevError, address: null }));

            return 'edit-field';
        }
    }, [isAddressTouched, setErrorData]);

    const [requiredFields, setRequiredFields] = useState([]);

    useEffect(() => {
        RequestService.getSettings(data.customerId).then((data) => {
            if (data?.setting?.requiredFieldKeys) {
                setRequiredFields(data.setting.requiredFieldKeys.map((x: string) => toLowerFirstSymbol(x)));
            }
        });
    }, [data.customerId]);

    return (
        <RightSideBarForm title="Edit Case Details" onCancel={handleCancelClick} onSave={handleSaveClick}>
            <RequestCaseForm
                options={{
                    generateAddressClassName,
                    isMedA: departmentKey.HCA === requestDetails?.department?.key,
                    isMedB: departmentKey.HCB === requestDetails?.department?.key,
                }}
                isEdit={true}
                data={data}
                errors={error}
                onChange={handleChangeData}
                requiredFields={requiredFields}
            />
        </RightSideBarForm>
    );
};

export default RequestCaseSidebar;
