import { InfiniteServerClient } from 'common/helpers/http-clients';
import { ICreateNoteRequest } from 'common/models/create-note-request';
import { INote } from 'common/models/note';
import { AddTherapistToRequest, IRequestDataModel, ITherapistRequestStatusForm } from '../requests/types';
import { INursingHomeRequestCloseReason, INursingHomeRequestDetails, INursingHomeRequestStatusCount } from './types';

export class NursingHomeRequestService {
    getCountGroupStatuses(queryParams: string = ''): Promise<INursingHomeRequestStatusCount> {
        return InfiniteServerClient.get<INursingHomeRequestStatusCount>(`/nursing-home/requests/status/counts?${queryParams}`).then(
            (response) => {
                return response.data;
            },
            (error) => {
                throw error;
            }
        );
    }

    closeSingleRequest(id: number, { reasonId, comment }: INursingHomeRequestCloseReason) {
        return InfiniteServerClient.put(`/nursing-home/requests/${id}/actions/close`, {
            ReasonId: reasonId,
            Comment: comment,
        }).then(
            (response) => {
                return response.data;
            },

            (error) => {
                throw error;
            }
        );
    }

    getDetails(requestId: number): Promise<INursingHomeRequestDetails> {
        return InfiniteServerClient.get(`/nursing-home/requests/${requestId}/details`).then(
            (response) => {
                return response.data;
            },
            (error) => {
                throw error;
            }
        );
    }

    createNote(requestId: number, request: ICreateNoteRequest): Promise<INote> {
        return InfiniteServerClient.post<INote>(`/nursing-home/requests/${requestId}/notes`, request || {}).then(
            (response) => {
                return response.data;
            },
            (error) => {
                throw error;
            }
        );
    }

    addTherapistToRequest(addModel: AddTherapistToRequest): Promise<void> {
        return InfiniteServerClient.post(`/nursing-home/requests/therapists`, addModel).then(
            (response) => {
                return response.data;
            },
            (error) => {
                throw error;
            }
        );
    }

    setStatusToTherapistRequest(requestId: number, therapistId: number, statusForm: ITherapistRequestStatusForm) {
        return InfiniteServerClient.put(`/nursing-home/requests/${requestId}/therapists/${therapistId}/status`, statusForm).then(
            (response) => {
                return response.data;
            },
            (error) => {
                throw error;
            }
        );
    }

    addNoteToTherapistRequest(requestId: number, therapistId: number, note: ICreateNoteRequest) {
        return InfiniteServerClient.put(`/nursing-home/requests/${requestId}/therapists/${therapistId}/note`, note).then(
            (response) => {
                return response.data;
            },

            (error) => {
                throw error;
            }
        );
    }

    deleteTherapistFromRequest(requestId: number, therapistId: number) {
        return InfiniteServerClient.delete(`/nursing-home/requests/${requestId}/therapists/${therapistId}`).then(
            (response) => {
                return response.data;
            },

            (error) => {
                throw error;
            }
        );
    }

    assignToUser(requestId: number, userId: string): Promise<void> {
        const request = { userId: userId };

        return InfiniteServerClient.put<any>(`/nursing-home/requests/${requestId}/assigns`, request).then(
            (response) => {
                return response.data;
            },
            (error) => {
                throw error;
            }
        );
    }

    getRequestForMap(id: number): Promise<IRequestDataModel> {
        return InfiniteServerClient.get<IRequestDataModel>(`/nursing-home/requests/${id}/map`).then(
            (response) => {
                return response.data;
            },
            (error) => {
                throw error;
            }
        );
    }
}

export default new NursingHomeRequestService();
