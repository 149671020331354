import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import Tabs from 'common/components/tabs/tabs';
import Tab from 'common/components/tabs/tab';
import MessageTab from './components/message-tab/message-tab';
import NoteTab from './components/note-tab/note-tab';
import './style.scss';
import { ICommunicationSearchResult } from 'common/services/api/communication/types';
import communcationService from 'common/services/api/communication/communcation-service';
import CountIndicator from 'common/components/count-indicator/count-indicator';
import { IToggleFeatureList } from 'common/services/api/settings/types';
import InterviewTab from './components/interview-tab/interview-tab';

const defaultSearchResult: ICommunicationSearchResult = {
    note: { totalCount: 0, data: [] },
    message: { totalCount: 0, data: [] },
    interview: { totalCount: 0, data: [] },
};

const CommunicationTab = () => {
    const [activeTab, setActiveTab] = useState('notes-tab');

    const therapist = useSelector((state: any) => state.therapistDetails.therapist);

    const [currentSearchTerm, setCurrentSearchTerm] = useState<string>('');
    const [searchResult, setSearchResult] = useState<ICommunicationSearchResult>(defaultSearchResult);
    const featureList: IToggleFeatureList = useSelector((state: any) => state.siteSettings?.settings?.features || {});

    if (!therapist) {
        return null;
    }
    const handleSearchTermChange = (value: string, limit: number): Promise<void> => {
        if (!value) {
            setSearchResult(defaultSearchResult);
            setCurrentSearchTerm(value);

            return;
        }

        return communcationService.search(therapist.id, value, 1, limit).then((data) => {
            setSearchResult(data);
            setCurrentSearchTerm(value);
        });
    };

    const onTabClick = (index: any) => {
        switch (index) {
            case 0:
                setActiveTab('notes-tab');
                break;
            case 1:
                setActiveTab('interviews-tab');
                break;
            case 2:
                setActiveTab('emails-tab');
                break;
            default:
                setActiveTab('default');
                break;
        }
    };

    const messageTabTitleRenderer = () => {
        return renderTabTitle('Emails & SMS', searchResult?.message?.totalCount || 0);
    };

    const noteTabTitleRenderer = () => {
        return renderTabTitle('Notes', searchResult?.note?.totalCount || 0);
    };

    const interviewTabTitleRenderer = () => {
        return renderTabTitle('Interviews', searchResult?.interview?.totalCount || 0);
    };

    const renderTabTitle = (title: string, totalCount: number) => {
        return (
            <span className="title-counter">
                <span>{title}</span>
                {currentSearchTerm && <CountIndicator value={totalCount}></CountIndicator>}
            </span>
        );
    };

    return (
        <div className={`tab-with-table tab-with-table-and-tabs communication ${activeTab}`}>
            <div className="actions-wrapper-with-table"></div>
            <div className="left-side-tabs-with-table">
                <Tabs orientation="vertical" parentTabUrl="communication">
                    <Tab
                        id="notes"
                        linkClassName={'custom-link'}
                        routeName="notes"
                        titleRenderer={noteTabTitleRenderer}
                        onTabClick={(index: any) => onTabClick(index)}
                    >
                        <NoteTab
                            onSearchInputChange={handleSearchTermChange}
                            therapistId={therapist.id}
                            searchData={{ term: currentSearchTerm, result: searchResult.note }}
                        />
                    </Tab>
                    {featureList.isInterviewEnabled && (
                        <Tab
                            id="interviews"
                            routeName="interviews"
                            linkClassName={'custom-link'}
                            titleRenderer={interviewTabTitleRenderer}
                            onTabClick={(index: any) => onTabClick(index)}
                        >
                            <InterviewTab
                                therapist={therapist}
                                onSearchInputChange={handleSearchTermChange}
                                searchData={{ term: currentSearchTerm, result: searchResult.interview }}
                            />
                        </Tab>
                    )}
                    {featureList.isEmailEnabled && (
                        <Tab
                            id="emails"
                            routeName="emails-sms"
                            linkClassName={'custom-link'}
                            titleRenderer={messageTabTitleRenderer}
                            onTabClick={(index: any) => onTabClick(index)}
                        >
                            <MessageTab
                                onSearchInputChange={handleSearchTermChange}
                                therapist={therapist}
                                searchData={{ term: currentSearchTerm, result: searchResult.message }}
                            />
                        </Tab>
                    )}
                </Tabs>
            </div>
        </div>
    );
};

export default React.memo(CommunicationTab);
