import React, { useState } from 'react';
import StaffingAgenciesTable from './components/staffing-agencies-table/staffing-agencies-table';
import CreateStaffingAgencyForm from './components/create-staffing-agency-form/create-staffing-agency-form';

const StaffingAgenciesPage = () => {
    const [isCreateDialogOpen, setIsCreateDialogOpen] = useState(false);

    return (
        <div className="therapist-page table-page">
            <header>
                <div className="header-wrapper">
                    <div className="title-wrapper">
                        <h1>Staffing Agencies</h1>
                    </div>
                    <button
                        className="create-therapist-btn base-text-btn blue"
                        id="createNewRequestBtn"
                        onClick={() => {
                            setIsCreateDialogOpen(true);
                        }}
                    >
                        Create New
                    </button>
                </div>
            </header>
            {isCreateDialogOpen && (
                <CreateStaffingAgencyForm onCancel={() => setIsCreateDialogOpen(false)} onSave={() => setIsCreateDialogOpen(false)} />
            )}
            <StaffingAgenciesTable />
        </div>
    );
};

export default StaffingAgenciesPage;
