import React, { useEffect, useState } from 'react';
import FieldSet from 'common/components/field-set/field-set';
import PaginationAutocomplete from 'common/components/field/pagination-autocomplete/pagination-autocomplete';
import DropdownOption from 'common/models/dropdown-option';
import AutoPopulateDropdownOption from 'common/models/auto-populate-dropdown-option';
import NumberField from 'common/components/field/number-field';
import { generalValidationRules } from 'common/validation/general-validation-rules';
import CreatePatientForm from 'features/patients-page/components/create-patients-form/create-patients-form';
import { ICreatePatientRequest, IPatientOption } from 'common/services/api/patient/types';
import { fetchPatients } from 'app/store/patients/action-creators';
import PatientService from 'common/services/api/patient/patient-service';
import { useDispatch } from 'react-redux';
import RequestCaseForm from './request-case-form';
import TherapyService from 'common/services/api/therapy/therapy-service';
import { IForm } from 'common/models/types';
import { ICreateRequestForm } from 'common/services/api/requests/types';
import { IRequiredFields } from './types';
import { validationRules } from 'features/patients-page/validation-rules';
import { SELECT_CUSTOMER_BEFORE_TYPING } from 'features/request-page/request-validation-messages';
import DropdownAutocomplete from 'common/components/field/dropdown/dropdown-autocomplete';
import { departmentKey } from 'common/constants/department-key';
import EntityService from 'common/services/api/entity/entity-service';
import { useIsUserType } from 'common/hooks/use-is-user-type';
import InputField from 'common/components/field/input-field';
import StateService from 'common/services/api/state/state-service';
import { ConverterHelper } from 'common/helpers/converter-helper';
import { ICounty } from 'common/services/api/county/types';
import CountyService from 'common/services/api/county/county-service';

// TO-DO: Set tab indexes on the form
const RequestCreateForm = ({
    data: createRequestInfo,
    errors,
    onChange,
    requiredFields,
    options,
}: IForm<ICreateRequestForm> & IRequiredFields) => {
    const [isPatientFetch, setIsPatientFetch] = useState(false);
    const [therapyTypeOptions, setTherapyTypeOptions] = useState([]);
    const [therapyTypeSearchTerm, setTherapyTypeSearchTerm] = useState('');
    const { isExternal } = useIsUserType();
    const addressRequired = options?.generateAddressClassName ?? '';
    const [defaultState, setDefaultSate] = useState(null);

    useEffect(() => {
        StateService.getAll('New York').then((data) => {
            if (data.length) {
                setDefaultSate(data[0]);
                onChange({ name: 'stateId', value: data[0]?.id });
            }
        });
    }, []);

    useEffect(() => {
        TherapyService.getAll(departmentKey.HCA).then((data) => {
            setTherapyTypeOptions(data.map((item) => new DropdownOption(item.id, item.name)));
        });
    }, []);

    const dispatch = useDispatch();

    const patientIdConvertFunction = (patient: IPatientOption) => {
        return new AutoPopulateDropdownOption(patient.patientAgencyCode, patient.patientName, null, patient.id, patient.zipCode);
    };

    const openCreatePatientForm = (patientAgencyCode: string) => {
        onChange({ name: 'patientAgencyCode', value: patientAgencyCode });
        setIsCreatePatientFormOpen(true);
    };

    const closeCreatePatientForm = () => {
        setIsCreatePatientFormOpen(false);
    };

    const savePatient = (patient: ICreatePatientRequest) => {
        return PatientService.create(patient).then((res) => {
            setIsPatientFetch(true);

            if (patient.basicInfo.customerId !== createRequestInfo?.customerId) {
                EntityService.getOption(patient.basicInfo.customerId).then((response) => {
                    onChange({ name: 'customerId', value: patient.basicInfo.customerId, lable: response.name });
                });
            }

            if (patient.basicInfo.facilityId !== createRequestInfo?.facilityId) {
                EntityService.getOption(patient.basicInfo.facilityId).then((response) => {
                    onChange({ name: 'facilityId', value: patient.basicInfo.facilityId, lable: response.name });
                });
            }

            onChange({ name: 'zipCode', value: patient.basicInfo.zip });
            onChange({ name: 'patientId', value: res.id });
            onChange({ name: 'patientAgencyCode', value: patient.basicInfo.patientAgencyCode });
            setIsCreatePatientFormOpen(false);
            dispatch(fetchPatients());
        });
    };

    const [isCreatePatientFormOpen, setIsCreatePatientFormOpen] = useState(false);

    const fetchPatientsData = (term: string, skip: number, take: number): Promise<IPatientOption[]> => {
        return PatientService.getPatientsOptions(term, skip, take, createRequestInfo.customerId);
    };

    const fetchCountiesData = (term: string, skip: any, take: number): Promise<ICounty[]> => {
        if (createRequestInfo.stateId) {
            return CountyService.getByStateWithPagination(createRequestInfo.stateId, term, skip, take);
        } else {
            return new Promise<ICounty[]>(() => {});
        }
    };

    return (
        <>
            {isCreatePatientFormOpen && (
                <CreatePatientForm
                    onClose={() => closeCreatePatientForm()}
                    onSave={savePatient}
                    dataForCreation={{
                        patientAgencyCodeForCreation: createRequestInfo.patientAgencyCode,
                        customerIdForCreation: createRequestInfo?.customerId,
                        zipCodeForCreation: createRequestInfo?.zipCode,
                        facilityIdForCreation: createRequestInfo?.facilityId,
                    }}
                    hideCustomer={isExternal}
                />
            )}

            {!isCreatePatientFormOpen && (
                <>
                    <div className="edit-agency-form edit-form">
                        <h3>Therapy type</h3>
                        <FieldSet name="therapyTypeIds" errors={errors} customClass="edit-field" requiredFields={requiredFields}>
                            <DropdownAutocomplete
                                tabIndex=""
                                cleanable={requiredFields.indexOf('therapyTypeIds') === -1}
                                items={
                                    therapyTypeSearchTerm
                                        ? therapyTypeOptions.filter((therapyType) =>
                                              therapyType.label.toLowerCase().includes(therapyTypeSearchTerm.toLowerCase())
                                          )
                                        : therapyTypeOptions
                                }
                                label="Therapy Type"
                                multiselect
                                searchPlaceholder={'Search Therapy Type'}
                                value={therapyTypeOptions.filter((item) =>
                                    createRequestInfo.therapyTypeIds.some((id) => id === item.value)
                                )}
                                onChange={setTherapyTypeSearchTerm}
                                onSelect={(item: DropdownOption[]) => onChange({ name: 'therapyTypeIds', value: item.map((i) => i.value) })}
                            />
                        </FieldSet>

                        <h3>Case Details</h3>
                        <RequestCaseForm
                            errors={errors}
                            data={createRequestInfo}
                            onChange={onChange}
                            requiredFields={requiredFields}
                            isFetch={isPatientFetch}
                            setFetch={setIsPatientFetch}
                        />

                        <h3>Patient Details</h3>
                        <FieldSet
                            name="patientId"
                            errors={errors}
                            customClass="edit-field state-autocomplete"
                            requiredFields={requiredFields}
                        >
                            <PaginationAutocomplete
                                className="autocomplete-one-border"
                                fetchData={fetchPatientsData}
                                fetchTrigger={createRequestInfo.customerId}
                                convertFunction={patientIdConvertFunction}
                                defaultValue={createRequestInfo.patientAgencyCode}
                                id="patientId"
                                placeholder="Patient ID"
                                name="patientId"
                                tabIndex=""
                                onDelete={() => onChange({ name: 'patientId', value: undefined })}
                                onSelect={(item) => {
                                    onChange({ name: 'patientId', value: item.id });
                                    onChange({ name: 'zipCode', value: item.zipCode });
                                }}
                                onInputChange={(value) => {
                                    onChange({ name: 'patientAgencyCode', value: value });
                                    onChange({ name: 'patientId', value: undefined });
                                }}
                                autoPopulateDropdown
                                autoPopulateDropdownCallback={openCreatePatientForm}
                                errorMessage={!createRequestInfo.customerId && SELECT_CUSTOMER_BEFORE_TYPING}
                                maxInputLength={validationRules.patientAgencyCode.maxLength}
                            />
                        </FieldSet>
                        <FieldSet name="address1" errors={errors?.address} customClass={addressRequired}>
                            <InputField
                                placeholder="Address 1"
                                name="address1"
                                onChange={(data: any) => {
                                    onChange({ ...data, parentName: 'address' });
                                }}
                                value={createRequestInfo.address1}
                                tabIndex={null}
                            />
                        </FieldSet>
                        <FieldSet name="address2" errors={errors?.address} customClass="edit-field">
                            <InputField
                                placeholder="Address 2"
                                name="address2"
                                onChange={(data: any) => {
                                    onChange({ ...data, parentName: 'address' });
                                }}
                                value={createRequestInfo.address2}
                                tabIndex={null}
                            />
                        </FieldSet>
                        <div className="row-two-column">
                            <FieldSet name="city" errors={errors?.address} customClass={addressRequired}>
                                <InputField
                                    placeholder="City"
                                    name="city"
                                    maxLength={generalValidationRules.city.maxLength}
                                    onChange={(data: any) => {
                                        onChange({ ...data, parentName: 'address' });
                                    }}
                                    value={createRequestInfo.city}
                                    tabIndex={null}
                                />
                            </FieldSet>
                            <FieldSet name="stateId" errors={errors?.address} customClass={`${addressRequired} state-autocomplete`}>
                                <PaginationAutocomplete
                                    cleanable={true}
                                    fetchData={StateService.getAll}
                                    convertFunction={ConverterHelper.stateConvert}
                                    id="state"
                                    defaultValue={defaultState?.name}
                                    placeholder="State"
                                    name="stateId"
                                    tabIndex={null}
                                    disabled
                                    onDelete={() => onChange({ name: 'stateId', parentName: 'address', value: undefined })}
                                    onSelect={(item) => onChange({ name: 'stateId', parentName: 'address', value: item.value })}
                                />
                            </FieldSet>
                        </div>
                        <div className="row-two-column">
                            <FieldSet name="countyId" errors={errors?.address} customClass={`${addressRequired} state-autocomplete`}>
                                <PaginationAutocomplete
                                    cleanable={!addressRequired?.includes('required')}
                                    fetchData={fetchCountiesData}
                                    convertFunction={ConverterHelper.countyConvert}
                                    id="county"
                                    defaultValue={createRequestInfo?.labels?.countyName || ''}
                                    placeholder="County"
                                    name="county"
                                    tabIndex={null}
                                    fetchTrigger={createRequestInfo.stateId}
                                    onDelete={() => onChange({ name: 'countyId', value: undefined })}
                                    onSelect={(item) => onChange({ name: 'countyId', parentName: 'address', value: item.value })}
                                />
                            </FieldSet>
                            <FieldSet name="zip" errors={errors?.address} customClass={addressRequired}>
                                <NumberField
                                    id="zip"
                                    classic
                                    maxLength={generalValidationRules.zip.maxLength}
                                    placeholder="Zip"
                                    name="zip"
                                    tabIndex={null}
                                    onChange={(option: any) =>
                                        onChange({
                                            name: 'zip',
                                            parentName: 'address',
                                            value: option?.value?.length > 0 ? option?.value : null,
                                        })
                                    }
                                    value={createRequestInfo.zip}
                                />
                            </FieldSet>
                        </div>
                    </div>
                </>
            )}
        </>
    );
};

export default RequestCreateForm;
