import React, { useState, useRef, useEffect } from 'react';
import { ColDef, IHeaderParams } from 'ag-grid-community';
import './custom-header.scss';

interface ICustomHeader {
    selectAllCheckbox?: boolean;
}

const CustomHeader = (props: IHeaderParams) => {
    const [getSort, setSort] = useState('');
    const [countSelected, setCountSelected] = useState(0);
    const refButton = useRef(null);
    const { api: gridApi, column } = props;
    const colId = column.getColId();

    const colDef: ColDef & ICustomHeader = column.getColDef();

    const { filter, headerName, selectAllCheckbox } = colDef;

    // Hide header cell
    if (props.eGridHeader && headerName === '') {
        const pinnedCell: HTMLDivElement = props.eGridHeader.closest('.ag-pinned-right-header');
        if (pinnedCell) pinnedCell.style.display = 'none';
    }

    const preventOpenMenu = useRef(false);
    const handleMenuClick = () => {
        if (preventOpenMenu.current) {
            window.dispatchEvent(new Event('unblockMenu'));
        } else {
            props.showColumnMenu(refButton.current);
        }
    };

    const handleSortChange = () => {
        if (!props.enableSorting) return;

        if (props.column.isSortAscending()) setSort('asc');
        else if (props.column.isSortDescending()) setSort('desc');
        else setSort('');
    };

    const handleSortClick = () => {
        if (!props.enableSorting) return;

        if (getSort === '') props.setSort('asc');
        else if (getSort === 'asc') props.setSort('desc');
        else if (getSort === 'desc') props.setSort('');
    };

    const handleFilterChange = () => {
        const filterModel = gridApi.getFilterModel()[colId];
        const { values, isNullable, filterType } = filterModel || {};

        const count = values?.length || isNullable || filterType === 'date' ? 1 : 0;

        setCountSelected(count);
    };

    useEffect(() => {
        handleSortChange();
        handleFilterChange();
        const handlePreventOpenMenu = () => {
            preventOpenMenu.current = true;
        };
        const handleUnblockMenu = () => {
            preventOpenMenu.current = false;
        };
        props.column.addEventListener('filterChanged', handleFilterChange);
        props.column.addEventListener('sortChanged', handleSortChange);
        window.addEventListener('preventOpenMenu', handlePreventOpenMenu);
        window.addEventListener('unblockMenu', handleUnblockMenu);

        return () => {
            props.column.removeEventListener('filterChanged', handleFilterChange);
            props.column.removeEventListener('sortChanged', handleSortChange);
            window.removeEventListener('preventOpenMenu', handlePreventOpenMenu);
            window.removeEventListener('unblockMenu', handleUnblockMenu);
        };
    }, []);

    const sortIconStatus = () => {
        if (getSort === '') return 'icon-down';

        if (getSort === 'desc') return 'icon-down to-bottom';

        return 'icon-down to-top';
    };

    return (
        <div className={`table-dropdown-filter-wrapper`} ref={refButton} data-set-colid={colId}>
            {selectAllCheckbox && (
                <div className="table-checkbox-wrapper">
                    <input type="checkbox" onChange={(e) => gridApi.forEachNode((node) => node.setSelected(e.target.checked))} />
                </div>
            )}
            {countSelected > 0 && (
                <span onClick={() => (props.enableMenu && filter ? handleMenuClick() : null)} className="selected-filters-counter"></span>
            )}
            <div className="table-dropdown-filter-main-view">
                <div
                    id={`headerColumn ` + colId}
                    className="table-dropdown-filter-main-view-text "
                    onClick={() => (props.enableMenu && filter ? handleMenuClick() : null)}
                >
                    <span className={`header-column-name ${countSelected !== 0 ? 'with-counter' : ''}`}>{headerName}</span>
                </div>
            </div>
            {props.enableSorting && <span className={sortIconStatus()} onClick={handleSortClick} onTouchEnd={handleSortClick}></span>}
        </div>
    );
};

export default CustomHeader;
