import React, { useRef, useState, useMemo, useCallback } from 'react';
import AgTable from 'common/components/ag-table/ag-table';
import { IParamsWatcher } from 'common/components/ag-table/types';
import { CellClickedEvent } from 'ag-grid-community/dist/lib/events';
import { getTableData } from 'common/services/api/tabel-fetch-service';
import { IEntityExistingContact, IEntityExistingContactForm } from 'common/services/api/entity-contact/type';
import HeaderWrapper from 'common/components/header/header-wrapper';
import HeaderLeftSide from 'common/components/header/header-left-side';
import { IForm } from 'common/models/types';
import { IExistingContactsCustomFilters } from '../../../types';
import { ExistingContactsListFilters } from './existing-contact-list-filters';

const defaultFilters: IExistingContactsCustomFilters = {
    entityFilterId: null,
};

const EntityExistingContactForm = ({ data, onChange }: IForm<IEntityExistingContactForm>) => {
    const [filters, setFilters] = useState(defaultFilters);

    const paramsWatcher = useRef<IParamsWatcher>();

    const fetchOption = useMemo(
        () => ({
            handleFetchData: getTableData(`entities/${data.entityId}/contacts/existing`),
        }),
        [data.entityId]
    );

    const handleOnCellClicked = useCallback(
        (contact: IEntityExistingContact) => {
            const contactIndex = data.existingContacts.findIndex((x) => x.id === contact.id);

            if (contactIndex >= 0) {
                data.existingContacts.splice(contactIndex, 1);
            } else {
                data.existingContacts.push(contact);
            }

            onChange({ name: 'existingContacts', value: data.existingContacts });
        },
        [onChange, data.existingContacts]
    );

    const handleFiltersChange = (filterValues: any) => {
        setFilters((prev) => ({ ...prev, ...filterValues }));
    };

    const gridOptions = useMemo(
        () => ({
            onCellClicked: (event: CellClickedEvent) => {
                handleOnCellClicked(event.data);
            },
            columnDefs: [
                {
                    field: 'fullName',
                    headerName: 'Name',
                    width: 265,
                },
                {
                    field: 'roles',
                    wrapText: true,
                    headerName: 'Role',
                    width: 95,
                    cellClass: 'entity-exist-role',
                    cellRenderer: 'roleRender',
                },
                {
                    headerName: '',
                    colId: 'status',

                    cellRenderer: 'statusRender',
                    cellClass: ['dote-cell', 'icons-changes'],
                    width: 40,
                    sortable: false,
                },
            ],
            defaultColDef: {
                flex: 1,
            },
            frameworkComponents: {
                roleRender: (props: any) => {
                    const otherRoles = props.data?.otherRoles?.length ? ', ' + props.data.otherRoles : '';

                    return props.data?.roles + otherRoles;
                },
                statusRender: () => {
                    return <i className="icon icons-changes-icon" />;
                },
            },
            rowSelection: 'multiple',
            rowMultiSelectWithClick: true,
        }),
        [handleOnCellClicked]
    );

    return (
        <div className="existing-entity-contacts content-flex">
            <div className="main-info-wrapper ag-table-wrapper">
                <HeaderWrapper>
                    <HeaderLeftSide>
                        <ExistingContactsListFilters entityId={data.entityId} filters={filters} onFiltersChange={handleFiltersChange} />
                    </HeaderLeftSide>
                </HeaderWrapper>
                <AgTable
                    gridOptions={gridOptions}
                    paramsWatcher={paramsWatcher}
                    onFetchData={fetchOption.handleFetchData}
                    customFilters={filters}
                />
            </div>
        </div>
    );
};

export default EntityExistingContactForm;
