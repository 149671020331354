import React from 'react';
import FieldList from 'common/components/field-list/field-list';
import FieldSet from 'common/components/field-set/field-set';
import FileField from 'common/components/field/file-field/file-field';
import { DocumentAttachmentsFormProps } from './types';
import { FieldValue } from '../../models/field-value';

const DocumentAttachmentsForm: React.FC<DocumentAttachmentsFormProps> = ({
    fieldName,
    disableRemove,
    addButtonText,
    values,
    errors,
    onChange,
    customClass,
    placeholder = 'Upload or Drop File',
    disabledPlaceholder,
}) => {
    return (
        <FieldList
            disableRemove={disableRemove}
            name={fieldName}
            isDragNDrop={false}
            values={[...values]}
            defaultValue={{ document: '' }}
            addButtonText={addButtonText}
            onChange={(values: any[]) => onChange({ name: fieldName, value: values })}
            renderInput={(value, index) => (
                <FieldSet name={fieldName} errors={errors} customClass={customClass} renderError={(error: any) => error && error[index]}>
                    <FileField
                        id={`document${index}`}
                        name="document"
                        onChange={(data: FieldValue) => {
                            values[index] = { ...data };
                            onChange({ name: fieldName, value: [...values] });
                        }}
                        placeholder={value.isDisabled ? disabledPlaceholder : placeholder}
                        iconClass={value.isDisabled ? null : 'icon-upload-file'}
                        uploadedFileName={value?.originalName || value?.value?.name}
                        isError={errors[fieldName] && Object.values(errors[fieldName])[index]}
                        isDisabled={value.isDisabled}
                    />
                </FieldSet>
            )}
        />
    );
};

export default DocumentAttachmentsForm;
