import RightSideBar from 'common/components/right-side-bar/right-side-bar';
import { phoneTypes } from 'common/constants/patients/phone-types';
import { ObjectHelper } from 'common/helpers/object-helper';
import { ValidationHelper } from 'common/helpers/validation-helper';
import { usePopulationData } from 'common/hooks/use-population-data';
import DropdownOption from 'common/models/dropdown-option';
import { IFormItem } from 'common/models/types';
import { IPatientBasicInfo, IPatientDetails } from 'common/services/api/patient/types';
import FocusTrap from 'focus-trap-react';
import React, { useEffect, useMemo, useState } from 'react';
import PatientBasicInfoForm from '../../../patient-info-form/patient-basic-info-form';
import { GeneralInfoEditFormProps } from './types';
import { useTabIndex } from 'common/hooks/use-tab-index';
import { PatientErrorCodes } from '../../../types';
import { ENROLLMENT_DATE_RANGE_ERROR } from '../../../../constans';

const GeneralInfoEditForm = ({ patient, onSave, onCancel }: GeneralInfoEditFormProps) => {
    const [basicInfo, setBasicInfo] = useState<IPatientBasicInfo>(getInitState(patient));
    const [basicInfoErrors, setBasicInfoErrors] = useState({});
    const [countyDefault, setCountyDefault] = useState(patient.county?.name);
    const populationsOptions = usePopulationData().map((item) => new DropdownOption(item.id, item.name));

    const handleBasicInfoChange = (data: IFormItem) => {
        setBasicInfo((prevInfo) => ({ ...prevInfo, [data.name]: data.value }));
        setBasicInfoErrors((prevError) => ({ ...prevError, [data.name]: undefined }));
    };

    const isAddressTouched = useMemo(() => {
        return basicInfo.address1 || basicInfo.address2 || basicInfo.city || basicInfo.stateId || basicInfo.countyId || basicInfo.zip;
    }, [basicInfo.address1, basicInfo.address2, basicInfo.city, basicInfo.stateId, basicInfo.countyId, basicInfo.zip]);

    const generateAddressClassName = useMemo(() => {
        if (!basicInfo?.facilityId || (basicInfo?.facilityId && isAddressTouched)) {
            return 'edit-field required-field-star';
        } else {
            setBasicInfoErrors((prevError) => ({
                ...prevError,
                address1: undefined,
                address2: undefined,
                city: undefined,
                stateId: undefined,
                countyId: undefined,
                zip: undefined,
            }));

            return 'edit-field';
        }
    }, [basicInfo?.facilityId, isAddressTouched]);

    useEffect(() => {
        if (basicInfo.stateId !== patient.state?.id) {
            setCountyDefault('');
            handleBasicInfoChange({ name: 'countyId', value: null });
        }
    }, [basicInfo.stateId, patient.state]);

    useEffect(() => {
        if (basicInfo.patientAgencyCode === patient?.patientAgencyCode && basicInfo.customerId !== patient?.customer?.id) {
            handleBasicInfoChange({ name: 'patientAgencyCode', value: undefined });
        }
    }, [basicInfo.customerId]);

    const handleOnSaveClick = () => {
        onSave(basicInfo).catch((error: any) => {
            if (!error?.response?.data) return;

            const errors = ValidationHelper.handleErrorResponse(error.response.data);
            if (errors) {
                if (errors.errorCode === PatientErrorCodes.CONFLICT_WITH_ANOTHER_ENROLLMENT) {
                    setBasicInfoErrors({
                        startDate: [ENROLLMENT_DATE_RANGE_ERROR],
                        terminationDate: [ENROLLMENT_DATE_RANGE_ERROR],
                    });
                } else {
                    setBasicInfoErrors(errors);
                }
            }
        });
    };

    const handleCancelClick = () => {
        setBasicInfoErrors({});
        onCancel();
    };

    const { getTabIndex } = useTabIndex();

    return (
        <div className="create-patients-form">
            <FocusTrap focusTrapOptions={{ allowOutsideClick: true }}>
                <RightSideBar title="Edit Basic Info" onCancel={handleCancelClick} onSave={handleOnSaveClick}>
                    <div className="create-form">
                        <PatientBasicInfoForm
                            getTabIndex={getTabIndex}
                            basicInfo={basicInfo}
                            errors={basicInfoErrors}
                            onChange={handleBasicInfoChange}
                            options={{
                                countyName: countyDefault,
                                phoneTypes: phoneTypes,
                                populationsOptions: populationsOptions,
                                customer: patient?.customer,
                                facility: patient?.facility,
                            }}
                            selectedState={patient.state}
                            generateAddressClassName={generateAddressClassName}
                        />
                    </div>
                </RightSideBar>
            </FocusTrap>
            <div className="right-sidebar-overlay"></div>
        </div>
    );
};

const getInitState = (patient: IPatientDetails): IPatientBasicInfo => {
    return {
        id: patient.id,
        patientAgencyCode: patient.patientAgencyCode,
        firstName: patient.firstName,
        lastName: patient.lastName,
        middleName: patient.middleName,
        countyId: patient.county?.id,
        zip: patient.zip,
        populationId: patient.population?.id,
        stateId: patient.state?.id,
        address1: patient.address1,
        address2: patient.address2,
        city: patient.city,
        specialInstructions: patient.specialInstructions,
        phones: ObjectHelper.deepCopy(patient.phones) ?? [],
        emails: ObjectHelper.deepCopy(patient.emails) ?? [],
        dateOfBirth: patient.dateOfBirth,
        customerId: patient.customer?.id ?? 0,
        facilityId: patient.facility?.id,
        genderId: patient.gender?.id,
        physicians: ObjectHelper.deepCopy(patient.physicians) ?? [],
        startDate: null,
        terminationDate: null,
    };
};
export default GeneralInfoEditForm;
