import { IPoint } from 'common/models/point';
import { IUserBaseInfo } from 'common/services/api/user/types';
import { DisplayAddressType, IMapPoint } from 'common/services/api/map/types';
import { ITherapistOnMapModel } from 'common/services/api/therapist/types';

export type TherapistMapWrapperProps = {
    points: IMapPoint[];
    center: IPoint | null;
    radius?: number;
    children?: any;
    onDisplayTypeChange?: (type: DisplayAddressType[], resetLegend: () => void) => void;
    onMarkerClick: (item: IMapPoint) => void;
    iconArray?: IIcon[];
    onManualDisplayTypeChange?: any;
};

export const convertTherapistToUserInfo = (therapist: ITherapistOnMapModel) => {
    const userData: IUserBaseInfo = {
        firstName: therapist.firstName,
        lastName: therapist.lastName,
        middleName: therapist.middleName,
        fullName: `${therapist.firstName} ${therapist.lastName}`,
        id: therapist.id?.toString(),
    };

    return userData;
};

export interface ISimplePoint {
    x: number;
    y: number;
}

export interface IFontSizeMap {
    size: string;
    point?: ISimplePoint;
}

export interface IIcon {
    name?: string;
    value?: string;
    icon?: string;
    color: string;
    legendTextColor?: string;
    group?: string;
    hideLegend?: boolean;
    manualHideOnMap?: boolean;
    disabled?: boolean;
    strokeColor?: string;
    strokeWeight?: number;
    scale?: number;
}

export interface IIconMap {
    [key: string]: IIcon;
}
