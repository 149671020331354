import therapistDashboardService from 'common/services/api/therapist-dahsboard/therapist-dashboard-service';
import { ITherapistMissingDataWidgetFilter, IWidgetOptions } from 'common/services/api/therapist-dahsboard/types';
import useWidget from 'features/dashboard-page/hooks/use-widget';
import React, { useEffect, useRef, useState } from 'react';
import { trackPromise } from 'react-promise-tracker';
import DepartmentFilter from '../department-filter/department-filter';
import DisciplineFilter from '../discipline-filter/discipline-filter';
import FilterSection from '../filter-section/filter-section';
import WidgetHeader from '../widget-header/widget-header';
import WidgetTable from '../widget-table/widget-table';
import { MissingWidgetProps } from './types';

const defaultFilters: ITherapistMissingDataWidgetFilter = {
    pageIndex: 1,
    limit: 5,
    isSupervisorView: false,
    assignedTo: [],
};

const TITLE: string = 'Missing Data';

const MissingDataWidget: React.FC<MissingWidgetProps> = ({ isSupervisorView, globalFilters }) => {
    const [filter, totalCount, repository, fetchData, handleFilterChange] = useWidget({
        title: TITLE,
        defaultFilter: defaultFilters,
        fetchFunction: therapistDashboardService.getTherapistMissingData,
        exportFunction: null,
        isSupervisorView,
    });

    useEffect(() => {
        if (globalFilters) {
            handleFilterChange({ ...filter, assignedTo: globalFilters.assignedTo });
        }
    }, [globalFilters]);

    const [widgetIsReady, setWidgetReady] = useState(false);
    const widgetOptionsRef = useRef<IWidgetOptions>();

    const handleDisciplinesChange = (values: number[]) => {
        handleFilterChange({ ...filter, disciplines: values });
    };

    const handleDepartmentChange = (values: number[]) => {
        handleFilterChange({ ...filter, departments: values });
    };

    useEffect(() => {
        trackPromise(
            therapistDashboardService.getTherapistMissingDataOptions(isSupervisorView).then((data) => {
                widgetOptionsRef.current = data;
                setWidgetReady(true);
            })
        );
    }, [isSupervisorView]);

    return widgetIsReady === false ? (
        <></>
    ) : (
        <>
            <WidgetHeader title={TITLE} totalCount={totalCount} />
            <FilterSection>
                <DepartmentFilter value={filter.departments} onChange={handleDepartmentChange} />
                <DisciplineFilter value={filter.disciplines} onChange={handleDisciplinesChange} />
            </FilterSection>
            <WidgetTable
                widgetOptions={widgetOptionsRef}
                filter={filter}
                fetchFunction={fetchData}
                repository={repository}
                supervisorView={isSupervisorView}
                hideColumns={hideColumns}
            />
        </>
    );
};

const hideColumns = {
    days: false,
};

export default React.memo(MissingDataWidget);
