export const entityTypeKey = {
    CHHA: 'CHHA',
    AL: 'AL',
    NH: 'NH',
    DO: 'DO',
    CONS: 'CONS',
    ORG: 'ORG',
    SA: 'SA',
    PS: 'PS',
    SCHOOL: 'SC',
};

export enum ContactActionType {
    NewContact,
    ExistingContact,
}
