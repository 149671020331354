export const requestIcon: any =
    'data:image/svg+xml;charset=UTF-8,' +
    encodeURIComponent(`<?xml version="1.0" encoding="UTF-8"?>
<svg width="28px" height="35px" viewBox="0 0 40 40" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
    <title>requests</title>
    <g id="Show-on-Map" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
        <g id="requests">
            <polygon id="Path" points="0 0 40 0 40 40 0 40"></polygon>
            <path d="M20,3.33333333 C13,3.33333333 6.66666667,8.7 6.66666667,17 C6.66666667,22.5333333 11.1166667,29.0833333 20,36.6666667 C28.8833333,29.0833333 33.3333333,22.5333333 33.3333333,17 C33.3333333,8.7 27,3.33333333 20,3.33333333 Z" id="Shape" fill="#ED5285"></path>
            <g id="feed_black_24dp" transform="translate(11.000000, 8.000000)">
                <polygon id="Path" points="0 0 18 0 18 18 0 18"></polygon>
                <path d="M12,2.25 L3.75,2.25 C2.925,2.25 2.25,2.925 2.25,3.75 L2.25,14.25 C2.25,15.075 2.925,15.75 3.75,15.75 L14.25,15.75 C15.075,15.75 15.75,15.075 15.75,14.25 L15.75,6 L12,2.25 Z M6,5.25 L8.25,5.25 C8.6625,5.25 9,5.5875 9,6 L9,6 C9,6.4125 8.6625,6.75 8.25,6.75 L6,6.75 C5.5875,6.75 5.25,6.4125 5.25,6 L5.25,6 C5.25,5.5875 5.5875,5.25 6,5.25 Z M12,12.75 L6,12.75 C5.5875,12.75 5.25,12.4125 5.25,12 L5.25,12 C5.25,11.5875 5.5875,11.25 6,11.25 L12,11.25 C12.4125,11.25 12.75,11.5875 12.75,12 L12.75,12 C12.75,12.4125 12.4125,12.75 12,12.75 Z M12,9.75 L6,9.75 C5.5875,9.75 5.25,9.4125 5.25,9 L5.25,9 C5.25,8.5875 5.5875,8.25 6,8.25 L12,8.25 C12.4125,8.25 12.75,8.5875 12.75,9 L12.75,9 C12.75,9.4125 12.4125,9.75 12,9.75 Z M11.25,6 L11.25,3.75 L14.25,6.75 L12,6.75 C11.5875,6.75 11.25,6.4125 11.25,6 Z" id="Shape" fill="#FFFFFF" fill-rule="nonzero"></path>
            </g>
        </g>
    </g>
</svg>`);

export const homeIcon: any =
    'data:image/svg+xml;charset=UTF-8,' +
    encodeURIComponent(`<?xml version="1.0" encoding="UTF-8"?>
<svg width="28px" height="35px" viewBox="0 0 40 40" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
    <title>home</title>
    <g id="Show-on-Map" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
        <g id="home">
            <polygon id="Path" points="0 0 40 0 40 40 0 40"></polygon>
            <path d="M20,3.33333333 C13,3.33333333 6.66666667,8.7 6.66666667,17 C6.66666667,22.5333333 11.1166667,29.0833333 20,36.6666667 C28.8833333,29.0833333 33.3333333,22.5333333 33.3333333,17 C33.3333333,8.7 27,3.33333333 20,3.33333333 Z" id="Shape" fill="#AF52DE"></path>
            <g id="home_black_24dp" transform="translate(10.000000, 7.000000)">
                <polygon id="Path" points="0 0 20 0 20 20 0 20"></polygon>
                <polygon id="Path" fill="#FFFFFF" points="8.33333333 16.6666667 8.33333333 11.6666667 11.6666667 11.6666667 11.6666667 16.6666667 15.8333333 16.6666667 15.8333333 10 18.3333333 10 10 2.5 1.66666667 10 4.16666667 10 4.16666667 16.6666667"></polygon>
            </g>
        </g>
    </g>
</svg>`);

export const preferencesIcon: any =
    'data:image/svg+xml;charset=UTF-8,' +
    encodeURIComponent(`<?xml version="1.0" encoding="UTF-8"?>
<svg width="28px" height="35px" viewBox="0 0 40 40" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
    <title>preferences</title>
    <g id="Show-on-Map" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
        <g id="preferences">
            <polygon id="Path" points="0 0 40 0 40 40 0 40"></polygon>
            <path d="M20,3.33333333 C13,3.33333333 6.66666667,8.7 6.66666667,17 C6.66666667,22.5333333 11.1166667,29.0833333 20,36.6666667 C28.8833333,29.0833333 33.3333333,22.5333333 33.3333333,17 C33.3333333,8.7 27,3.33333333 20,3.33333333 Z" id="Shape" fill="#5856D6"></path>
        </g>
    </g>
</svg>`);

export const workplaceIcon: any =
    'data:image/svg+xml;charset=UTF-8,' +
    encodeURIComponent(`<?xml version="1.0" encoding="UTF-8"?>
<svg width="28px" height="35px" viewBox="0 0 40 40" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
    <title>workplace</title>
    <g id="Show-on-Map" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
        <g id="workplace">
            <polygon id="Path" points="0 0 40 0 40 40 0 40"></polygon>
            <path d="M20,3.33333333 C13,3.33333333 6.66666667,8.7 6.66666667,17 C6.66666667,22.5333333 11.1166667,29.0833333 20,36.6666667 C28.8833333,29.0833333 33.3333333,22.5333333 33.3333333,17 C33.3333333,8.7 27,3.33333333 20,3.33333333 Z" id="Shape" fill="#919191"></path>
            <g id="work_black_24dp-(1)" transform="translate(11.000000, 8.000000)">
                <polygon id="Path" points="0 0 18 0 18 18 0 18"></polygon>
                <path d="M15,4.5 L12,4.5 L12,3 C12,2.1675 11.3325,1.5 10.5,1.5 L7.5,1.5 C6.6675,1.5 6,2.1675 6,3 L6,4.5 L3,4.5 C2.1675,4.5 1.5075,5.1675 1.5075,6 L1.5,14.25 C1.5,15.0825 2.1675,15.75 3,15.75 L15,15.75 C15.8325,15.75 16.5,15.0825 16.5,14.25 L16.5,6 C16.5,5.1675 15.8325,4.5 15,4.5 Z M10.5,4.5 L7.5,4.5 L7.5,3 L10.5,3 L10.5,4.5 Z" id="Shape" fill="#FFFFFF" fill-rule="nonzero"></path>
            </g>
        </g>
    </g>
</svg>`);
