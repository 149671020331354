import DropdownOption from 'common/models/dropdown-option';

export enum PhoneType {
    CellPhone = 'CellPhone',
    Home = 'Home',
}

export const PhoneDescription = new Map<PhoneType, string>([
    [PhoneType.CellPhone, 'Cell Phone'],
    [PhoneType.Home, 'Home'],
]);

export const phoneTypes = Array.from(PhoneDescription, ([name, value]) => new DropdownOption(name, value));

export const customerPhoneTypes = [
    new DropdownOption('Home', 'Home'),
    new DropdownOption('Mobile', 'Mobile'),
    new DropdownOption('Work', 'Work'),
];

export const entityPhoneTypes = [
    new DropdownOption('Home', 'Home'),
    new DropdownOption('Mobile', 'Mobile'),
    new DropdownOption('Work', 'Work'),
];
