export enum FollowUpStatusCount {
    OFFERED = 'offeredCount',
    ADDED = 'addedCount',
}

export const FollowUpStatusCountToStatus = new Map<FollowUpStatusCount, string>([
    [FollowUpStatusCount.OFFERED, 'Offered'],
    [FollowUpStatusCount.ADDED, 'Added'],
]);

export const FollowUpStatusToCount = new Map<string, FollowUpStatusCount>([
    ['Offered', FollowUpStatusCount.OFFERED],
    ['Added', FollowUpStatusCount.ADDED],
]);

export interface ITopFollowUpFilter {
    [FollowUpStatusCount.OFFERED]: number;
    [FollowUpStatusCount.ADDED]: number;
}
