import roleService from 'common/services/api/role/role-service';
import { IApplicationRole, ICreateUpdateRoleRequest } from 'common/services/api/role/types';
import React, { useState } from 'react';
import { toast } from 'react-toastify';
import AddEditRoleSidebar from './components/add-edit-role-sidebar/add-edit-role-sidebar';
import RolesList from './components/roles-list/roles-list';
import { RolesPageProps } from './types';

const TOAST_ID = 'TOAST_ROLES_FORBIDDEN';

const RolesPage: React.FC<RolesPageProps> = ({ hasAccess }) => {
    if (hasAccess !== null && !hasAccess && !toast.isActive(TOAST_ID)) {
        toast.error('Access Denied', { toastId: TOAST_ID });
    }

    const [isAddEditDialogOpen, toggleAddEditDialog] = useState(false);
    const [forstUpdate, setForceUpdate] = useState(false);
    const [currentEditRole, setCurrentEditRole] = useState<IApplicationRole | null>(null);

    const handleClickOnRole = (role: IApplicationRole) => {
        setCurrentEditRole(role);
        openDialog();
    };

    const handleClickOnSave = (data: ICreateUpdateRoleRequest) => {
        if (currentEditRole) {
            return roleService.update(currentEditRole.id, data).then(handleOnSuccess);
        } else {
            return roleService.create(data).then(handleOnSuccess);
        }
    };

    const openDialog = () => {
        toggleAddEditDialog(true);
        setForceUpdate(false);
    };

    const closeDialog = () => {
        toggleAddEditDialog(false);
        setForceUpdate(false);
        setCurrentEditRole(null);
    };

    const handleOnSuccess = () => {
        setForceUpdate(true);
        closeDialog();
    };

    return hasAccess ? (
        <>
            <div className="table-page">
                <RolesList onEditRoleClick={handleClickOnRole} forceUpdate={forstUpdate} onNewRoleClick={openDialog} />
            </div>
            {isAddEditDialogOpen && <AddEditRoleSidebar data={currentEditRole} onClose={closeDialog} onSave={handleClickOnSave} />}
        </>
    ) : (
        <></>
    );
};

export default React.memo(RolesPage);
