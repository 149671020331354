import Breadcrumb from 'common/components/Breadcrumb/breadcrumb';
import ClientNavigation from 'common/components/details-navigation/client-navigation';
import TooltipButton from 'common/components/reopened-button/tooltip-button';
import Tab from 'common/components/tabs/tab';
import Tabs from 'common/components/tabs/tabs';
import { ReasonType } from 'common/services/api/reason/types';
import schoolService from 'common/services/api/school/school-service';
import { ISchoolRequestDetails, SchoolRequestStatus } from 'common/services/api/school/types';
import { IToggleFeatureList } from 'common/services/api/settings/types';
import RequestDeclineSidebar from 'features/request-page/components/request-forms/request-decline-sidebar';
import { IRequestDeclineReason } from 'features/request-page/components/request-forms/types';
import { NavigationRoutes } from 'models/routes/navigation-routes';
import React, { useCallback, useEffect, useState } from 'react';
import { trackPromise } from 'react-promise-tracker';
import { useSelector } from 'react-redux';
import { Link, useParams } from 'react-router-dom';
import SchoolRequestGeneralInfoTab from './general-info-tab/school-request-general-info-tab';
import SchoolRequestHeaderInfo from './request-header-info/school-request-header-info';
import { ISchoolRequestDetailsContext, ISchoolRequestParamType } from './types';

export const SchoolRequestDetailsContext = React.createContext<ISchoolRequestDetailsContext>(null);

const SchoolRequestDetailsPage = () => {
    const [requestDetails, setRequestDetails] = useState<ISchoolRequestDetails>();
    const [isDeclineBarOpen, toggleDeclineBar] = useState(false);

    const featureList: IToggleFeatureList = useSelector((state: any) => state.siteSettings?.settings?.features || {});

    const items = [
        { to: '/school/requests', label: 'Requests' },
        { to: '', label: 'Request Details' },
    ];

    const params = useParams<ISchoolRequestParamType>();
    const requestId = Number.parseInt(params?.requestId);

    const updateDetailsPage = useCallback(
        () => trackPromise(schoolService.getDetails(requestId)).then((detailsData) => setRequestDetails(detailsData)),
        [requestId]
    );

    useEffect(() => {
        if ((!requestDetails && requestId) || requestDetails?.id !== requestId) {
            updateDetailsPage();
        }
    }, [requestDetails, requestId, updateDetailsPage]);

    const handleSeeActivityButton = () => {
        window.open(`/logging?object=SchoolRequests&objectId=${requestId}`, '_blank').focus();
    };

    // Decline
    const handleClickOnDecline = () => {
        toggleDeclineBar(true);
    };

    const handleClickDeclineClose = () => {
        toggleDeclineBar(false);
    };

    const handleClickSaveDecline = (requestReason: IRequestDeclineReason) => {
        return trackPromise(schoolService.setDeclineStatus(requestId, requestReason)).then(() => {
            toggleDeclineBar(false);
            setRequestDetails(null);
        });
    };

    const isDeclined = () => requestDetails?.status === SchoolRequestStatus.Declined;

    return (
        <SchoolRequestDetailsContext.Provider value={{ setRequestDetails, updateDetailsPage }}>
            <div className="details request">
                <header>
                    {featureList.isDetailsNavigationEnabled && (
                        <ClientNavigation entityId={requestId} navigationRoute={NavigationRoutes.schoolRequestDetailsRoute} />
                    )}
                    <div className="header-mock">
                        <Breadcrumb>
                            {items.map(({ to, label }) => (
                                <Link key={to} to={to}>
                                    {label}
                                </Link>
                            ))}
                        </Breadcrumb>
                        <div className="button-block">
                            <TooltipButton id="declineRequestBtn" disabled={isDeclined()} onClick={handleClickOnDecline}>
                                {isDeclined() ? 'Declined' : 'Decline'}
                            </TooltipButton>
                        </div>
                    </div>
                    {requestDetails && (
                        <SchoolRequestHeaderInfo details={{ ...requestDetails }} handleSeeActivityButton={handleSeeActivityButton} />
                    )}
                </header>
                <Tabs orientation="horizontal">
                    <Tab linkClassName={'custom-link'} text="General Info">
                        <SchoolRequestGeneralInfoTab requestId={requestId} requestDetails={requestDetails} />
                    </Tab>
                </Tabs>

                {isDeclineBarOpen && (
                    <RequestDeclineSidebar
                        onClose={handleClickDeclineClose}
                        onSave={handleClickSaveDecline}
                        options={{ reasonType: ReasonType.DeclineSchoolRequest }}
                    />
                )}
            </div>
        </SchoolRequestDetailsContext.Provider>
    );
};

export default SchoolRequestDetailsPage;
