import React from 'react';
import { NavLink } from 'react-router-dom';

const NavigationItem = (props) => {
    const { classIcon, abbreviation, children, routePath } = props;
    const activeClassName = 'active-navigation-sub-item';

    return (
        <NavLink activeClassName={activeClassName} to={routePath} className="navigation-item">
            {classIcon && <i className={classIcon}></i>}
            {abbreviation && <span className={`ab-icon ab-icon-${abbreviation.length}`}>{abbreviation}</span>}
            <span className="navigation-item-content">{children}</span>
        </NavLink>
    );
};

export default NavigationItem;
