import Card from 'common/components/card';
import FieldSet from 'common/components/field-set/field-set';
import DateField from 'common/components/field/date-field';
import MoneyField from 'common/components/field/money-field';
import PaginationAutocomplete from 'common/components/field/pagination-autocomplete/pagination-autocomplete';
import TextAreaField from 'common/components/field/text-area-field';
import IndividualDays from 'common/components/individual-days/individual-days-list';
import WeekDays from 'common/components/week-days/week-days';
import CheckBoxField from 'common/components/field/checkbox-field';
import { dateTimeConstants } from 'common/constants/common';
import { departmentKey } from 'common/constants/department-key';
import { entityTypeKey } from 'common/constants/entity-type';
import { ConverterHelper } from 'common/helpers/converter-helper';
import { FieldValue } from 'common/models/field-value';
import { IOption } from 'common/models/types';
import BaseService from 'common/services/api/base/base-service';
import EntityContactService from 'common/services/api/entity-contact/entity-contact-service';
import { IEntityContactWithRoleOption } from 'common/services/api/entity-contact/type';
import EntityService from 'common/services/api/entity/entity-service';
import { IEntityOption } from 'common/services/api/entity/types';
import coverageTypeService from 'common/services/api/nursing-home/coverage-type-service';
import RateService from 'common/services/api/rate/rate-servise';
import { IRateOption } from 'common/services/api/rate/types';
import TherapyService from 'common/services/api/therapy/therapy-service';
import { ITherapyTypeOption } from 'common/services/api/therapy/types';
import React, { useEffect } from 'react';
import '../../nursing-home-page.scss';
import { coverageTypes, NursingAddRequestFormProps, placementTypeEnum } from './types';
import { DisplayValueHelper } from 'common/helpers/display-value-helper';

const placementType = 'PlacementType';

// TO-DO: Set tab indexes on the form.
const NursingHomeBaseForm: React.FC<NursingAddRequestFormProps> = ({ data, errors, onChange, isEdit, options, isInstructionField }) => {
    const {
        placementTypeId,
        coverageType,
        specialInstructions,
        cameInDate,
        startDate,
        endDate,
        individualDays,
        isCustomRate,
        labels,
    } = data;

    const getTherapyOptions = (term: string, skip: number, take: number): Promise<ITherapyTypeOption[]> => {
        return TherapyService.getTherapyTypeOptions(departmentKey.SNF, term, skip, take);
    };

    const getCustomerOptions = (term: string, skip: number, take: number): Promise<IEntityOption[]> => {
        return EntityService.getEntityOptions(term, skip, take, entityTypeKey.NH);
    };

    const getFacilitiesOptions = (term: string, skip: number, take: number): Promise<IEntityOption[]> => {
        if (data?.entityId) return EntityService.getEntityOptions(term, skip, take, entityTypeKey.PS, data?.entityId);
        else return Promise.resolve([]);
    };

    const placementTypeOptions = (term: string, skip: number, take: number): Promise<IOption[]> => {
        return BaseService.getOptions(term, skip, take, placementType);
    };

    const getSourceOptions = (term: string, skip: number, take: number): Promise<IEntityContactWithRoleOption[]> => {
        return EntityContactService.getContactWithRole(term, skip, take, data.entityId, data.facilityId);
    };

    const getRateOptions = (term: string, skip: number, take: number): Promise<IRateOption[]> => {
        return RateService.getRateOptions(term, skip, take, data.entityId, data.therapyTypeId);
    };

    useEffect(() => {
        if (!isEdit) {
            switch (placementTypeId) {
                case placementTypeEnum.Coverage:
                    onChange({ name: 'coverageType', value: 'IndividualDays', lable: 'Individual Days' });
                    break;
                case placementTypeEnum.OnGoing:
                    onChange({ name: 'coverageType', value: undefined });
                    break;
                case placementTypeEnum.onCall:
                    onChange({ name: 'coverageType', value: undefined });
                    break;
            }
            onChange({ name: 'individualDays', value: [] });
            onChange({ name: 'weekDays', value: [] });
        }
    }, [placementTypeId]);

    return (
        <div className="create-form">
            <div className="edit-therapist-form nursing-home-basic-form">
                <h3 className="title">
                    <i className="icon icon-technician" /> Therapy type
                </h3>
                <FieldSet name="therapyTypeId" errors={errors} customClass="edit-field required-field-star">
                    <PaginationAutocomplete
                        cleanable={false}
                        fetchData={getTherapyOptions}
                        convertFunction={ConverterHelper.therapyTypeConvert}
                        id="therapyTypeId"
                        disabled={isEdit}
                        defaultValue={labels?.therapyTypeId}
                        placeholder="Discipline"
                        name="therapyTypeId"
                        tabIndex=""
                        onDelete={() => onChange({ name: 'therapyTypeId', value: undefined })}
                        onSelect={(item) => onChange({ name: 'therapyTypeId', value: item.value, lable: item.label })}
                    />
                </FieldSet>
                <h3 className="title">
                    <i className="icon icon-job" /> Case Details
                </h3>
                <FieldSet name="entityId" errors={errors} customClass="edit-field required-field-star">
                    <PaginationAutocomplete
                        cleanable={false}
                        className="autocomplete-one-border"
                        fetchData={getCustomerOptions}
                        convertFunction={ConverterHelper.entityConvert}
                        defaultValue={data?.labels?.entityId}
                        id="entityId"
                        disabled={isEdit || options?.disabledEntity}
                        placeholder="Entity"
                        name="entityId"
                        tabIndex=""
                        onDelete={() => onChange({ name: 'entityId', value: undefined })}
                        onSelect={(item) => onChange({ name: 'entityId', value: item.value, lable: item.label })}
                    />
                </FieldSet>
                <FieldSet name="facilityId" errors={errors} customClass="edit-field required-field-star">
                    <PaginationAutocomplete
                        cleanable={false}
                        className="autocomplete-one-border"
                        fetchData={getFacilitiesOptions}
                        convertFunction={ConverterHelper.entityConvert}
                        id="facility"
                        disabled={isEdit}
                        defaultValue={data?.labels?.facilityId}
                        placeholder="Facility"
                        name="facility"
                        tabIndex=""
                        onDataLoaded={(items) => {
                            if (items?.length === 1) {
                                onChange({ name: 'facilityId', value: items[0].value, lable: items[0].label });
                            }
                        }}
                        fetchTrigger={data?.entityId}
                        onDelete={() => onChange({ name: 'facilityId', value: undefined })}
                        onSelect={(item) => onChange({ name: 'facilityId', value: item.value, lable: item.label })}
                    />
                </FieldSet>
                <FieldSet name="placementTypeId" errors={errors} customClass="required-field-star">
                    <PaginationAutocomplete
                        cleanable={false}
                        fetchData={placementTypeOptions}
                        convertFunction={ConverterHelper.optionConvert}
                        id="placementTypeId"
                        disabled={isEdit}
                        placeholder="Placement Type"
                        name="placementTypeId"
                        defaultValue={data?.labels?.placementTypeId}
                        tabIndex=""
                        onDelete={() => onChange({ name: 'placementTypeId', value: undefined })}
                        onSelect={(item) => onChange({ name: 'placementTypeId', value: item.value })}
                    />
                </FieldSet>

                {data.isCustomRate ? (
                    <FieldSet errors={errors} name="customRate" customClass="edit-rate-field required-field-star">
                        <MoneyField
                            id="customRate"
                            placeholder="Rate"
                            disabled={isEdit && options?.isTherapists}
                            name="customRate"
                            onChange={onChange}
                            value={data?.customRate}
                        ></MoneyField>
                    </FieldSet>
                ) : (
                    <FieldSet name="rateId" errors={errors} customClass="required-field-star">
                        <PaginationAutocomplete
                            cleanable={false}
                            fetchData={getRateOptions}
                            convertFunction={ConverterHelper.rateConvert}
                            id="rateId"
                            defaultValue={data?.labels?.rateId}
                            disabled={isEdit && options?.isTherapists}
                            placeholder="Rate"
                            name="rateId"
                            tabIndex=""
                            fetchTrigger={'' + data?.entityId + data?.therapyTypeId}
                            onDelete={() => onChange({ name: 'rateId', value: undefined })}
                            onSelect={(item) => onChange({ name: 'rateId', value: item.value, lable: item.label })}
                            onFormatOption={(item: any) => DisplayValueHelper.rateEmptyOrIsSet(item?.label)}
                            onRenderInput={(fieldProps: any) => <MoneyField fixedDecimalScale={false} {...fieldProps} />}
                        />
                    </FieldSet>
                )}
                {(!isEdit || (isEdit && options?.isTherapists)) && (
                    <FieldSet name="isCustomRate" errors={errors} customClass="edit-therapist-field">
                        <CheckBoxField
                            id="isCustomRate"
                            placeholder="Custom Rate"
                            value={isCustomRate}
                            name=" isCustomRate"
                            onChange={(item: { value: any }) => {
                                if (!isEdit) {
                                    if (item.value && data?.rateId !== undefined && data?.rateId !== null) {
                                        onChange({ name: 'rateId', value: undefined });
                                    } else {
                                        onChange({ name: 'customRate', value: undefined });
                                    }
                                }
                                onChange({ name: 'isCustomRate', value: item.value });
                            }}
                        />
                    </FieldSet>
                )}

                {placementTypeId === placementTypeEnum.Coverage && (
                    <FieldSet name="coverageType" errors={errors} customClass="required-field-star">
                        <PaginationAutocomplete
                            cleanable={false}
                            fetchData={coverageTypeService.getOptions}
                            convertFunction={ConverterHelper.coverageTypeConvert}
                            id="coverageType"
                            disabled={isEdit}
                            placeholder="Coverage Type"
                            name="coverageType"
                            tabIndex=""
                            defaultValue={data?.coverageType}
                            onDelete={() => onChange({ name: 'coverageType', value: undefined, lable: undefined })}
                            onSelect={(item) => {
                                const valuesMap: any = {
                                    0: { value: 'IndividualDays', lable: 'Individual Days' },
                                    1: { value: 'DateRange', lable: 'Date Range' },
                                };
                                switch (valuesMap[item.value]) {
                                    case coverageTypes.individualDays:
                                        onChange({ name: 'weekDays', value: [] });
                                        onChange({ name: 'startDate', value: null });
                                        onChange({ name: 'endDate', value: null });
                                        onChange({ name: 'flexDate', value: false });
                                        onChange({ name: 'flexTime', value: false });
                                        break;
                                    case coverageTypes.dateRange:
                                        onChange({ name: 'individualDays', value: [] });
                                        break;
                                    default:
                                }
                                onChange({ name: 'coverageType', ...valuesMap[item.value] });
                            }}
                        />
                    </FieldSet>
                )}
                {coverageType === 'IndividualDays' && (
                    <Card className="void-card pl-34" noBorder>
                        <IndividualDays
                            data={individualDays}
                            addable={!isEdit}
                            errors={errors}
                            onChange={(data) => onChange({ name: 'individualDays', value: data?.value })}
                        />
                    </Card>
                )}
                {(coverageType === 'DateRange' ||
                    placementTypeId === placementTypeEnum.OnGoing ||
                    placementTypeId === placementTypeEnum.onCall) && (
                    <>
                        <div className="row-two-column edges">
                            <FieldSet errors={errors} name="startDate" customClass="two-column required-field-star">
                                <DateField
                                    id="startDate"
                                    placeholder="Start Date"
                                    value={startDate}
                                    name="startDate"
                                    onChange={(data: any) => {
                                        onChange({ name: 'startDate', value: data.value });
                                    }}
                                    dateFormat={dateTimeConstants.MM_DD_YYYY}
                                    maskFormat="##/##/####"
                                ></DateField>
                            </FieldSet>
                            <FieldSet errors={errors} name="endDate" customClass="two-column required-field-star">
                                <DateField
                                    id="endDate"
                                    placeholder="End Date"
                                    name="endDate"
                                    value={endDate}
                                    onChange={(data: any) => onChange({ name: 'endDate', value: data.value })}
                                    dateFormat={dateTimeConstants.MM_DD_YYYY}
                                    maskFormat="##/##/####"
                                ></DateField>
                            </FieldSet>
                        </div>
                        <WeekDays
                            title="Days"
                            name="weekDays"
                            errors={errors}
                            icon="icon-dropdown"
                            flexDays={data?.flexDate}
                            flexTimes={data?.flexTime}
                            selected={data?.weekDays}
                            onChange={({ weekdays, flexDays, flexTimes }) => {
                                onChange({ name: 'weekDays', value: weekdays });
                                onChange({ name: 'flexDate', value: flexDays });
                                onChange({ name: 'flexTime', value: flexTimes });
                            }}
                        />
                    </>
                )}
                <FieldSet name="sourceId" errors={errors} customClass="required-field-star">
                    <PaginationAutocomplete
                        cleanable={false}
                        fetchData={getSourceOptions}
                        convertFunction={ConverterHelper.sourceConvert}
                        id="sourceId"
                        disabled={isEdit}
                        placeholder="Source"
                        name="sourceId"
                        tabIndex=""
                        defaultValue={data?.labels?.sourceId}
                        onFormatOption={(item: any) => {
                            const roles = [
                                ...item?.value?.roles.map((r: any) => r.role),
                                ...item?.value?.otherRoles.map((or: any) => or.name),
                            ];

                            return (
                                <>
                                    <span>{item?.label}</span>
                                    <div className="option-roles">
                                        <span>Roles: </span> {roles.join(', ') || '-'}
                                    </div>
                                </>
                            );
                        }}
                        onDelete={() => onChange({ name: 'sourceId', value: undefined })}
                        onSelect={(item) => onChange({ name: 'sourceId', value: item.value?.id, lable: item })}
                        fetchTrigger={[data?.entityId, data?.facilityId]}
                    />
                </FieldSet>
                <FieldSet errors={errors} name="cameInDate" customClass="edit-start-date-field">
                    <DateField
                        id="cameInDate"
                        disabled={isEdit}
                        placeholder="Date Request Came In"
                        name="cameInDate"
                        onChange={(data: any) => onChange({ name: 'cameInDate', value: data.value })}
                        value={cameInDate}
                        dateFormat={dateTimeConstants.MM_DD_YYYY}
                        maskFormat="##/##/####"
                    ></DateField>
                </FieldSet>
                <FieldSet name="specialInstructions" errors={errors} customClass="edit-field">
                    <TextAreaField
                        isFocused={isInstructionField}
                        id="specialInstructions"
                        value={specialInstructions}
                        placeholder="Special Instructions"
                        name="specialInstructions"
                        onChange={(data: FieldValue) => onChange({ name: 'specialInstructions', value: data.value })}
                    />
                </FieldSet>
            </div>
        </div>
    );
};

export default NursingHomeBaseForm;
