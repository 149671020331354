import { IdentityServerClient } from '../../common/helpers/http-clients';
import qs from 'qs';
import { ACCESS_TOKEN_KEY, REFRESH_TOKEN_KEY, USER_FULL_NAME } from '../../common/constants/local-storage-keys';
import {
    LOGIN_FAILURE,
    LOGIN_RESET_ERROR,
    LOGIN_SUCCESS,
    LOGOUT,
    SET_ENTITY,
    SET_REDIRECT_TO,
    SET_USER,
} from './login-page/auth-constants';
import { AuthSettings } from '../../settings.json';
import { IdentityAPI } from '../../endpoints.json';
import jwt from 'jsonwebtoken';

export const loginRequest = (userName, password) => {
    return function (dispatch) {
        const authData = {
            client_id: AuthSettings.ClientId,
            client_secret: AuthSettings.SecretKey,
            grant_type: 'password',
            username: userName,
            password: password,
        };

        return IdentityServerClient.post(IdentityAPI.TokenEndpoint, qs.stringify(authData)).then(
            (response) => {
                const data = response.data;
                if (data) {
                    localStorage.setItem(ACCESS_TOKEN_KEY, data.access_token);
                    localStorage.setItem(REFRESH_TOKEN_KEY, data.refresh_token);

                    const decodedToken = jwt.decode(data.access_token);

                    localStorage.setItem(USER_FULL_NAME, decodedToken.FullName);

                    dispatch(
                        setUser({
                            fullName: decodedToken.FullName,
                            type: decodedToken.Type,
                            permissions: Array.isArray(decodedToken.permission) ? decodedToken.permission : [decodedToken.permission],
                        })
                    );

                    dispatch(setEntity(decodedToken?.entity ? JSON.parse(decodedToken?.entity) : null));

                    dispatch(loginSuccess());
                }
            },
            (_error) => {
                dispatch(loginFailure('Login or password is incorrect'));
            }
        );
    };
};

export const refreshToken = () => {
    const refreshToken = localStorage.getItem(REFRESH_TOKEN_KEY);

    const refreshRequest = {
        client_id: AuthSettings.ClientId,
        client_secret: AuthSettings.SecretKey,
        grant_type: 'refresh_token',
        refresh_token: refreshToken,
    };

    return IdentityServerClient.post(IdentityAPI.TokenEndpoint, qs.stringify(refreshRequest));
};

export const logOut = () => {
    return (dispatch) => {
        const refreshTokenValue = {
            client_id: AuthSettings.ClientId,
            token: localStorage.getItem(REFRESH_TOKEN_KEY),
            token_type_hint: 'refresh_token',
        };

        //TODO: Implement logic for catching rejected requests
        return IdentityServerClient.post(IdentityAPI.EndSessionEndpoint, qs.stringify(refreshTokenValue)).finally(() => {
            localStorage.removeItem(ACCESS_TOKEN_KEY);
            localStorage.removeItem(REFRESH_TOKEN_KEY);

            dispatch({
                type: LOGOUT,
            });
        });
    };
};

export const loginFailure = (errorMessage) => {
    return {
        type: LOGIN_FAILURE,
        errorMessage,
    };
};

export const resetLoginErrors = () => {
    return { type: LOGIN_RESET_ERROR };
};

export const loginSuccess = () => {
    return {
        type: LOGIN_SUCCESS,
        loggedIn: true,
        redirectTo: '/',
    };
};

export const setUser = (user) => {
    return { type: SET_USER, user: user };
};

export const setEntity = (entity) => {
    return { type: SET_ENTITY, entity: entity };
};

export const setRedirectTo = (location) => {
    return { type: SET_REDIRECT_TO, redirectTo: location };
};
