import { InfiniteServerClient } from 'common/helpers/http-clients';
import { IApplicationRole, ICreateUpdateRoleRequest } from './types';

export class RoleService {
    getAll(term?: string, skip?: number, take?: number): Promise<IApplicationRole[]> {
        return InfiniteServerClient.get<IApplicationRole[]>('/roles', { params: { term, skip, take } }).then(
            (response) => {
                return response.data;
            },
            (error) => {
                throw error;
            }
        );
    }

    create(request: ICreateUpdateRoleRequest): Promise<IApplicationRole> {
        return InfiniteServerClient.post<IApplicationRole>('/roles', request).then(
            (response) => {
                return response.data;
            },
            (error) => {
                throw error;
            }
        );
    }

    update(id: string, request: ICreateUpdateRoleRequest): Promise<IApplicationRole> {
        return InfiniteServerClient.put<IApplicationRole>(`/roles/${id}`, request).then(
            (response) => {
                return response.data;
            },
            (error) => {
                throw error;
            }
        );
    }
}

export default new RoleService();
