import DropdownOption from 'common/models/dropdown-option';
import { FieldValue } from 'common/models/field-value';
import { INewSmsModel } from 'common/services/api/communication/types';
import React from 'react';
import { useState } from 'react';
import FieldSet from '../field-set/field-set';
import TextAreaField from '../field/text-area-field';
import InfiniteSelect from '../infinite-select/infinite-select';
import RightSideBar from '../right-side-bar/right-side-bar';
import { NewSmsDialogProps } from './types';
import { PhoneDescription } from 'common/constants/phone-types';

const NewSmsDialog: React.FC<NewSmsDialogProps> = ({ phones, onCancel, onSave }) => {
    const [sms, setSms] = useState<INewSmsModel>({ phoneNumber: phones?.[0]?.number, text: undefined });
    const [errorData, setErrorData] = useState({});
    const options = phones.map((phone) => new DropdownOption(phone, phone?.number));

    const handleClickOnSave = () => {
        onSave(sms).catch((error) => {
            if (error.response.status === 400) {
                setErrorData(error.response.data);
            }
        });
    };

    const handleFieldChange = (data: FieldValue) => {
        setSms((prevSms) => ({ ...(prevSms as INewSmsModel), [data.name]: data.value }));
        setErrorData((prevError) => ({ ...prevError, [data.name]: undefined }));
    };

    return (
        <div>
            <RightSideBar saveText="Send" title="New SMS" onCancel={onCancel} onSave={handleClickOnSave}>
                <div className="edit-therapist-form">
                    <div className="edit-therapist-form-therapist-email">
                        <FieldSet errors={errorData} name="phoneNumber" customClass="edit-therapist-field">
                            <InfiniteSelect
                                items={options}
                                id="phoneNumber"
                                name="phoneNumber"
                                label="To"
                                value={options.find((item) => sms?.phoneNumber === item.value?.number)}
                                onRenderSelected={renderPhoneItem}
                                onFormatOption={renderPhoneItem}
                                onChange={(item: DropdownOption) => handleFieldChange({ name: 'phoneNumber', value: item.value?.number })}
                            />
                        </FieldSet>
                    </div>
                    <div className="edit-therapist-form-message">
                        <FieldSet name="text" errors={errorData} customClass="edit-therapist-field">
                            <TextAreaField
                                id="message"
                                placeholder="Text"
                                name="text"
                                onChange={handleFieldChange}
                                value={sms.text}
                            ></TextAreaField>
                        </FieldSet>
                    </div>
                </div>
            </RightSideBar>
            <div className="right-sidebar-overlay"></div>
        </div>
    );
};

const renderPhoneItem = (item: any) => (
    <>
        {item?.value?.number}
        <span className="phone-type">({PhoneDescription.get(item?.value?.type) ?? item?.value?.type})</span>
    </>
);

export default React.memo(NewSmsDialog);
