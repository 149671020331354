import { EntityNoteType, IEntityNote } from 'common/models/note';
import { NavigationRoutes } from 'models/routes/navigation-routes';

export class RedirectHelper {
    static GetNotePagePathnameByEntity(entity: IEntityNote): string {
        switch (entity.type) {
            case EntityNoteType.Therapist:
                return NavigationRoutes.therapistDetailsRoute(entity.id) + '/communication/notes';
            case EntityNoteType.Patient:
                return NavigationRoutes.patientDetailsRoute(entity.id) + '/general-info/notes';
            case EntityNoteType.Request:
                return NavigationRoutes.requestDetailsRoute(entity.id) + '/general-info/notes';
            case EntityNoteType.NursingHomeRequest:
                return NavigationRoutes.nursinghomerequestDetailsRoute(entity.id) + '/general-info/notes';
            case EntityNoteType.LegalEntity:
                return NavigationRoutes.entityDetailsRoute(entity.id) + '/general-info/notes';
            case EntityNoteType.SchoolRequest:
                return NavigationRoutes.schoolRequestDetailsRoute(entity.id) + '/general-info/notes';
            case EntityNoteType.Lead:
                return NavigationRoutes.leadDetaisPage(entity.id);
            default:
                throw new Error('Invalid entity type.');
        }
    }
}
