import React, { useCallback } from 'react';
import RightSideBarForm from 'common/components/right-side-bar/right-side-bar-form';
import useFormSidebar from 'common/hooks/use-form-sidebar';
import { IFormSidebar } from 'common/models/types';
import { ICreateUpdateUserRequest, IUser } from 'common/services/api/user/types';
import AddEditUserForm from '../add-edit-user-form/add-edit-user-form';

const AddEditUserSidebar = ({ onClose, onSave, data: user, options }: IFormSidebar<ICreateUpdateUserRequest, IUser>) => {
    const [
        data,
        error,
        handleChangeData,
        handleCancelClick,
        handleSaveClick,
        setData, // eslint-disable-line @typescript-eslint/no-unused-vars
        setErrorData, // eslint-disable-line @typescript-eslint/no-unused-vars
        resetErrorData,
    ] = useFormSidebar<ICreateUpdateUserRequest>(
        {
            firstName: user?.firstName || '',
            lastName: user?.lastName || '',
            email: user?.email || '',
            username: (options?.isExternal ? user?.email : user?.username) || '',
            rolesIds: user?.roles?.map((i) => i.id) || [],
            lockoutEnabled: user?.lockoutEnabled || false,
            legacyId: user?.legacyId,
        },
        { onClose, onSave }
    );

    const generateTitle = useCallback(() => {
        if (options?.isExternal) {
            return options?.isExternalCreate ? 'Create User' : 'Edit User';
        }

        return user ? 'Edit User' : 'Create User';
    }, [user, options]);

    return (
        <div className="add-edit-user-sidebar">
            <RightSideBarForm title={generateTitle()} onCancel={handleCancelClick} onSave={handleSaveClick}>
                <AddEditUserForm
                    data={data}
                    errors={error}
                    onChange={handleChangeData}
                    resetErrorData={resetErrorData}
                    isEdit={options?.isExternal}
                />
            </RightSideBarForm>
        </div>
    );
};

export default React.memo(AddEditUserSidebar);
