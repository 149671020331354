import React from 'react';
import { IButtonToolbarItem } from 'common/components/button-toolbar/types';
import { TherapistMessageFilterTypeProps } from './types';
import { TherapistCommunicationType } from 'common/services/api/communication/types';
import ButtonToolbar from 'common/components/button-toolbar/button-toolbar';

import './style.scss';

const TherapistMessageFilterType: React.FC<TherapistMessageFilterTypeProps> = ({ filter, onFilterChange }) => {
    const options: IButtonToolbarItem[] = [
        { value: null, title: 'All' },
        { value: TherapistCommunicationType.Email, title: 'Email' },
        { value: TherapistCommunicationType.Sms, title: 'Sms' },
    ];

    const handleClickOnButtonItem = (value?: string) => {
        onFilterChange({ ...filter, type: value ? (value as TherapistCommunicationType) : null });
    };

    return <ButtonToolbar currentId={filter.type} className="message-type-filter" buttons={options} onClick={handleClickOnButtonItem} />;
};

export default React.memo(TherapistMessageFilterType, (prev, next) => prev.filter.type === next.filter.type);
