import Button from 'common/components/Button/button';
import React from 'react';
import { OpenPageButtonProps } from './types';

import './_styles.scss';

const OpenPageButton: React.FC<OpenPageButtonProps> = ({ url, className, label = 'Open Profile' }) => {
    const handleClick = () => {
        window.open(url, '_blank').focus();
    };

    return (
        <Button id="open-profile-button" onClick={handleClick} className={`base-text-btn white open-profile ${className || ''}`}>
            {label}
        </Button>
    );
};

export default React.memo(OpenPageButton);
