import { IResetPasswordRequest, IVerifyResetTokenRequest } from 'common/services/api/user/types';
import userService from 'common/services/api/user/user-service';
import React, { useCallback, useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router';
import SetupPasswordForm from './components/setup-password-form/setup-password-form';
import queryString from 'query-string';
import { toast } from 'react-toastify';
import { useDispatch } from 'react-redux';
import { logOut } from 'features/authentication/auth.actions';
import { IUpdatePasswordModel, SetupPasswordPageProps, UpdatePasswordType } from './types';
import ResetPasswordForm from './components/reset-password-form/reset-password-form';
import { trackPromise } from 'react-promise-tracker';

const SetupPasswordPage: React.FC<SetupPasswordPageProps> = ({ type }) => {
    const [tokenIsValid, setTokenIsValid] = useState<boolean>(false);
    const history = useHistory();
    const queryParams = queryString.parse(useLocation().search);

    const [entityName, setEntityName] = useState<string>(undefined);
    const [userName, setUserName] = useState<string>(undefined);

    const dispatch = useDispatch();

    useEffect(() => {
        const verifyRequst: IVerifyResetTokenRequest = {
            email: ((queryParams.email as string) || '').trim(),
            token: (queryParams.token as string) || '',
        };

        userService.verifyResetPasswordToken(verifyRequst).then(
            (response) => {
                setTokenIsValid(response.isValid);

                if (response.isValid) {
                    dispatch(logOut());

                    setEntityName((queryParams.entity_name as string) || '');
                    setUserName((queryParams.name as string) || '');
                    setTokenIsValid(true);
                } else {
                    toast.error('The link is expired or invalid');
                }
            },
            () => {
                toast.error('The link is expired or invalid');
            }
        );
    }, [dispatch, queryParams.email, queryParams.entity_name, queryParams.name, queryParams.token, queryParams.userName]);

    const handleSave = useCallback(
        (model: IUpdatePasswordModel) => {
            const request: IResetPasswordRequest = {
                password: model.password,
                confirmPassword: model.confirmPassword,
                email: (queryParams.email as string).trim() || '',
                token: (queryParams.token as string) || '',
            };

            return trackPromise(
                userService.resetPassword(request).then(() => {
                    history.push('/login');
                })
            );
        },
        [history, queryParams.email, queryParams.token]
    );

    const renderForm = useCallback(() => {
        if (type === UpdatePasswordType.Reset) {
            return <ResetPasswordForm onSubmit={handleSave} />;
        } else {
            return <SetupPasswordForm onSubmit={handleSave} username={userName} entityName={entityName} />;
        }
    }, [entityName, handleSave, type, userName]);

    return <>{tokenIsValid && renderForm()}</>;
};

export default React.memo(SetupPasswordPage);
