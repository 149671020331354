import React from 'react';
import EntitiesList, { entitiesColumnDefs } from '../../entities-list/entities-list';
import { GeneralInfoTabProp } from '../types';

const ChildEntitiesListTab: React.FC<GeneralInfoTabProp> = ({ details: entityDetails }) => {
    const parentId = entityDetails?.id;

    return (
        <div className="entity-child-tab tabs-active-horizontal table-page">
            <EntitiesList
                storePrefix="child"
                extendDefaultCustomFilters={{ defaultParentId: parentId || -1 }}
                serachTitle="Child Entities"
                extendGridOptions={{
                    columnDefs: patientColumnDef,
                }}
            />
        </div>
    );
};

const patientColumnDef = [...entitiesColumnDefs];
const indexParent = entitiesColumnDefs.findIndex((columnConfig) => columnConfig.field === 'parent');

if (indexParent > -1) {
    patientColumnDef[indexParent] = {
        field: 'parent',
        headerName: 'Parent',
        sortable: false,
        hide: true,
    };
}

export default ChildEntitiesListTab;
