import RightSideBarForm from 'common/components/right-side-bar/right-side-bar-form';
import useFormSidebar from 'common/hooks/use-form-sidebar';
import { IFormSidebar } from 'common/models/types';
import { IPatientContactForm } from 'common/services/api/patient-contact/types';
import React from 'react';
import PatientContactForm from './patient-contact-form';

const PatientContactSidebarCreate = ({
    data: isMainContactChecked,
    onClose,
    onSave,
    options,
}: IFormSidebar<IPatientContactForm, boolean>) => {
    const [data, error, handleChangeData, handleCancelClick, handleSaveClick] = useFormSidebar<IPatientContactForm>(
        {
            firstName: null,
            middleName: null,
            lastName: null,
            phones: [],
            emails: [],
            isEmergencyContact: false,
            isMainContact: isMainContactChecked,
            relationshipId: null,
        },
        { onClose, onSave }
    );

    return (
        <RightSideBarForm title="Add Contact" onCancel={handleCancelClick} onSave={handleSaveClick}>
            <PatientContactForm data={data} errors={error} onChange={handleChangeData} options={options} />
        </RightSideBarForm>
    );
};

export default PatientContactSidebarCreate;
