import { InfiniteServerClient } from 'common/helpers/http-clients';
import { ICreateNoteRequest } from 'common/models/create-note-request';
import { IDuplicateTherapistError } from 'common/models/duplicate-therapist-error';
import { INote } from 'common/models/note';
import { IRejectRequest } from 'common/models/reject-request';
import { ILead, ILeadStatusCount, IVerifyLeadResponse } from './types';

export class LeadService {
    refuse(id: number, request: IRejectRequest): Promise<void> {
        return InfiniteServerClient.post<void>(`/leads/${id}/refuse`, request || {}).then(
            (response) => {
                return response.data;
            },
            (error) => {
                throw error;
            }
        );
    }

    verify(id: number, verifyAnyway: boolean): Promise<IVerifyLeadResponse> {
        return InfiniteServerClient.post<IVerifyLeadResponse>(`/leads/${id}/verification`, { verifyAnyway }).then((response) => {
            if (response) {
                return response.data;
            }
        });
    }

    getCountsGroupedByStatus(queryParams: string = ''): Promise<ILeadStatusCount> {
        return InfiniteServerClient.get<ILeadStatusCount>(`/leads/count?${queryParams}`).then(
            (response) => {
                return response.data;
            },
            (error) => {
                throw error;
            }
        );
    }

    getSimilarTherapists(id: number): Promise<IDuplicateTherapistError> {
        return InfiniteServerClient.get<IDuplicateTherapistError>(`/leads/${id}/duplicate/therapists`).then(
            (response) => {
                return response.data;
            },
            (error) => {
                throw error;
            }
        );
    }

    getLead(id: number): Promise<ILead> {
        return InfiniteServerClient.get<ILead>(`/leads/${id}`).then(
            (response) => {
                return response.data;
            },
            (error) => {
                throw error;
            }
        );
    }

    assignToUser(id: number, userId: string): Promise<void> {
        return InfiniteServerClient.put<void>(`/leads/${id}/assigns`, { userId: userId }).then(
            (response) => {
                return response.data;
            },
            (error) => {
                throw error;
            }
        );
    }

    createNote(leadId: number, note: ICreateNoteRequest): Promise<INote> {
        return InfiniteServerClient.post<INote>(`/leads/${leadId}/notes`, note || {}).then(
            (response) => {
                return response.data;
            },
            (error) => {
                throw error;
            }
        );
    }
}

export default new LeadService();
