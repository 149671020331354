import { IUserBaseInfo } from 'common/services/api/user/types';
import React from 'react';
import './_avatar.scss';

type AvatarComponentProps = {
    user?: IUserBaseInfo;
    imageData?: string;
    className?: string;
};

const Avatar = ({ user, imageData, className }: AvatarComponentProps) => {
    const createInitials = () => {
        const { firstName, lastName } = user ?? {};

        if (!firstName || !lastName || firstName.length === 0 || lastName.length === 0) {
            return '';
        }

        return `${user.firstName[0]}${user.lastName[0]}`;
    };

    if (!imageData && createInitials().length === 0) return null;

    return (
        <span className={`avatar-wrapper ${className}`}>
            {!imageData && <span className="avatar-initials">{createInitials()}</span>}
            {imageData && <img src={`data:image/jpeg;base64,${imageData}`} alt={user?.fullName || ''} />}
        </span>
    );
};

export default Avatar;
