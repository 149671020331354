import React, { useEffect, useState } from 'react';
import FieldSet from 'common/components/field-set/field-set';
import { IRequestDeclineReason } from './types';
import DropdownOption from 'common/models/dropdown-option';
import InfiniteSelect from 'common/components/infinite-select/infinite-select';
import TextAreaField from 'common/components/field/text-area-field';
import { IForm } from 'common/models/types';
import { validationRules } from '../../validation-rules';
import ReasonService from 'common/services/api/reason/reason-service';
import { ReasonType } from 'common/services/api/reason/types';
import { ErrorCodes } from 'common/constants/error-codes';
import { PLEASE_CHOOSE_A_REASON, PLEASE_EXPLAIN_THE_DECLINING_REASON } from 'features/request-page/request-validation-messages';
import { OTHER_REASON } from 'common/constants/common';

const RequestDeclineForm = ({ data: reasonDecline, errors, onChange, options }: IForm<IRequestDeclineReason>) => {
    const [requestDeclineReasonOptions, setRequestDeclineReasonOptions] = useState([]);
    const [validationErrors, setValidationErrors] = useState(errors);

    useEffect(() => {
        if (errors.errorCode) {
            switch (errors.errorCode) {
                case ErrorCodes.INVALID_REASON:
                    if (!validationErrors.reasonId) {
                        setValidationErrors({ reasonId: [PLEASE_CHOOSE_A_REASON] });
                    }

                    return;
                case ErrorCodes.REASON_COMMENT_IS_EMPTY:
                    if (!validationErrors.comment) {
                        setValidationErrors({ comment: [PLEASE_EXPLAIN_THE_DECLINING_REASON] });
                    }

                    return;
            }
        } else {
            setValidationErrors(errors);
        }
    }, [errors]);

    useEffect(() => {
        ReasonService.getReasons(options?.reasonType ?? ReasonType.DeclineRequest).then((data) => {
            setRequestDeclineReasonOptions(data.map((item) => new DropdownOption(item.id, item.name)));
        });
    }, []);

    let isOtherReason = false;
    const declineCurrentValue: DropdownOption = requestDeclineReasonOptions.find((item: DropdownOption) => {
        const result = item.value === reasonDecline.reasonId;
        if (result && item.label === OTHER_REASON) isOtherReason = true;

        return result;
    });

    return (
        <>
            <div className="edit-form">
                <FieldSet errors={validationErrors} name="reasonId" customClass="edit-field required-field-star">
                    <InfiniteSelect
                        id="reasonId"
                        label="Reason"
                        items={requestDeclineReasonOptions}
                        value={declineCurrentValue}
                        onChange={(item) => {
                            onChange({ name: 'reasonId', value: item.value });
                            setValidationErrors({ reasonId: [] });
                        }}
                    />
                </FieldSet>
                {isOtherReason && (
                    <FieldSet name="comment" errors={validationErrors} customClass="edit-field">
                        <TextAreaField
                            maxLength={validationRules.decliningReason.maxLength}
                            id="comment"
                            placeholder="Decline Reason"
                            name="comment"
                            onChange={(item: any) => {
                                onChange({ name: 'comment', value: item.value });
                                setValidationErrors({ comment: [] });
                            }}
                            value={reasonDecline.comment}
                            tabIndex={13}
                        />
                    </FieldSet>
                )}
            </div>
        </>
    );
};

export default RequestDeclineForm;
