import React, { useCallback } from 'react';
import RightSideBarForm from 'common/components/right-side-bar/right-side-bar-form';
import useFormSidebar from 'common/hooks/use-form-sidebar';
import { IFormSidebar } from 'common/models/types';
import { IPhysiciansForm, IPhysiciansRow } from 'common/services/api/physicians/types';
import AddEditPhysicianForm from '../add-edit-physician-form/add-edit-physician-form';
import { SequenceHelper } from 'common/helpers/sequence-helper';

const AddEditPhysicianSidebar = ({ onClose, onSave, data: physician, options }: IFormSidebar<IPhysiciansForm, IPhysiciansRow>) => {
    const [data, error, handleChangeData, handleCancelClick, handleSaveClick] = useFormSidebar<IPhysiciansRow>(
        {
            ...physician,
            practices: physician?.practices?.length > 0 ? physician?.practices : options?.presetPractice,
            phones: physician?.phones ?? [],
            emails: physician?.emails ?? [],
        },
        {
            onClose,
            onSave: (physician) => {
                SequenceHelper.setSequenceItems(physician.phones);
                SequenceHelper.setSequenceItems(physician.emails);
                const { address1, address2, city, state, postalCode } = physician;

                return onSave({
                    id: physician.id,
                    firstName: physician.firstName,
                    lastName: physician.lastName,
                    npiNumber: physician.npiNumber,
                    licenseTypes: physician?.licenseTypes?.length > 0 ? physician?.licenseTypes?.map((item) => item.id) : undefined,
                    specialities: physician?.specialities?.length > 0 ? physician?.specialities?.map((item) => item.id) : undefined,
                    practices: physician?.practices?.length > 0 ? physician?.practices?.map((item) => item.id) : undefined,
                    address1: address1,
                    address2: address2,
                    city: city,
                    stateId: state?.id,
                    postalCode: postalCode,
                    phones: physician.phones?.filter((item) => item.number).length > 0 ? physician.phones : null,
                    emails: physician.emails?.filter((item) => item.email)?.length > 0 ? physician.emails : null,
                });
            },
        }
    );

    const generateTitle = useCallback(() => {
        return physician ? 'Edit Physician' : 'Create Physician';
    }, [physician]);

    return (
        <div className="add-edit-user-sidebar">
            <RightSideBarForm title={generateTitle()} onCancel={handleCancelClick} onSave={handleSaveClick}>
                <AddEditPhysicianForm data={data} errors={error} onChange={handleChangeData} options={options} />
            </RightSideBarForm>
        </div>
    );
};

export default React.memo(AddEditPhysicianSidebar);
