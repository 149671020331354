import { Action, AnyAction, ThunkAction } from '@reduxjs/toolkit';
import { IDocument } from 'common/services/api/document/types';

export type ThunkResult<R> = ThunkAction<R, IDocumentState, null, AnyAction>;

export interface IDocumentState {
    readonly data: IDocument[];
}

export interface ISetDocumentsAction extends Action<'SET_DOCUMENTS'> {
    data: IDocument[];
}

export type DocumentActionsTypes = ISetDocumentsAction;

export const SET_DOCUMENTS = 'SET_DOCUMENTS';
