import React, { useEffect, useMemo, useRef, useState } from 'react';
import { CellClickedEvent, GridReadyEvent } from 'ag-grid-community/dist/lib/events';
import { IParamsWatcher } from 'common/components/ag-table/types';
import { getTableData, getFilterData, getExportData } from 'common/services/api/tabel-fetch-service';
import HeaderWrapper from 'common/components/header/header-wrapper';
import HeaderLeftSide from 'common/components/header/header-left-side';
import SearchFilterInput from 'common/components/header/search-filter-input/search-filter-input';
import ColumnsVisibleFilterSelect from 'common/components/header/сolumns-visible-filter-select/columns-visible-filter-select';
import HeaderRightSide from 'common/components/header/header-right-side';
import ExportButton from 'common/components/header/export-button/export-button';
import ClearFiltersButton from 'common/components/header/clear-filters-button/clear-filters-button';
import AgTable from 'common/components/ag-table/ag-table';
import { useSearchInput } from 'common/hooks/use-serch-input';
import { NavigationRoutes } from 'models/routes/navigation-routes';
import { useHistory } from 'react-router';
import { ExtentedDefaultTableProps } from 'common/constants/types';
import { LocalStoreRepository } from 'common/helpers/repository/local-store-repository';
import { IEntitiesCustomFilters } from './types';
import { EntitiesCustomFilter } from './entities-list-filters';
import AddButton from 'common/components/header/add-button/add-button';
import { ColDef } from 'ag-grid-community';
import { LocalTableRepository } from 'common/helpers/repository/local-table-repository';
import { DetailsNavigationHelper } from 'common/helpers/details-navigation-helper';

const handleFetchData = getTableData('entities');
const handleFetchFilters = getFilterData('entities/filter/source');
const handleExportData = getExportData('entities/export');

const EntitiesList = ({
    updateTable,
    storePrefix = '',
    extendGridOptions,
    extendDefaultCustomFilters,
    serachTitle = 'Entities',
}: ExtentedDefaultTableProps) => {
    // Repositories
    const tableRepository = useMemo(() => new LocalTableRepository(`${storePrefix}-entity-table`, 'v2'), [storePrefix]);
    const customFiltersRepository = useMemo(() => new LocalStoreRepository(`${storePrefix}-entities-table-custom-filters`), [storePrefix]);
    const loadedFilters = useRef<IEntitiesCustomFilters>(customFiltersRepository.load());
    const defaultFilters: IEntitiesCustomFilters = {
        search: null,
        defaultParentId: null,
        status: null,
    };
    if (extendDefaultCustomFilters) {
        Object.keys(extendDefaultCustomFilters).forEach((key) => {
            defaultFilters[key] = extendDefaultCustomFilters[key];
            if (loadedFilters.current) {
                loadedFilters.current[key] = extendDefaultCustomFilters[key];
            }
        });
    }

    const [gridApi, setGridApi] = useState(null);
    const [gridColumnApi, setColumnApi] = useState(null);
    const [filters, setFilters] = useState(loadedFilters.current ?? defaultFilters);
    const { search, handleSearchInputChange, setSearch } = useSearchInput(loadedFilters.current?.search, (value: string) => {
        setFilters((prev) => ({ ...prev, search: value }));
    });
    const paramsWatcher = useRef<IParamsWatcher>();
    const history = useHistory();

    useEffect(() => {
        customFiltersRepository.save(filters);
        loadedFilters.current = filters;
    }, [filters, customFiltersRepository]);

    const gridOptions = useMemo(
        () => ({
            ...extendGridOptions,
            onCellClicked: (event: CellClickedEvent) => {
                history.push(
                    { pathname: NavigationRoutes.entityDetailsRoute(event.data.id) },
                    DetailsNavigationHelper.getStateForDetails(paramsWatcher.current, event.node.rowIndex)
                );
                if (extendGridOptions?.onCellClicked) extendGridOptions?.onCellClicked(event);
            },
            columnDefs: extendGridOptions?.columnDefs ?? entitiesColumnDefs,
            defaultColDef: {
                resizable: true,
                filterParams: {
                    onFetchFilters: handleFetchFilters,
                },
            },
            frameworkComponents: {
                ListRender: (props: any) => {
                    let cellValue = props.value;

                    if (Array.isArray(cellValue)) {
                        cellValue = cellValue.map((item: any) => item?.name).join(', ');
                    }

                    return cellValue;
                },
                ...extendGridOptions?.frameworkComponents,
            },
        }),
        [extendGridOptions, history]
    );

    useEffect(() => {
        if (updateTable && gridApi) {
            gridApi.onFilterChanged();
        }
    }, [updateTable, gridApi]);

    // Clear
    const handleFiltersClear = () => {
        setSearch('');
        setFilters({ ...defaultFilters });
    };

    const handleGridReady = (params: GridReadyEvent) => {
        setGridApi(params.api);
        setColumnApi(params.columnApi);
    };

    const handleFiltersChange = (filterValues: any) => {
        setFilters((prev) => ({ ...prev, ...filterValues }));
    };

    return (
        <>
            <div className="main-info-wrapper ag-table-wrapper">
                <HeaderWrapper>
                    <HeaderLeftSide>
                        <SearchFilterInput title={serachTitle} value={search} onInput={handleSearchInputChange} />
                        <ColumnsVisibleFilterSelect title="Entities" gridApi={gridApi} gridColumnApi={gridColumnApi} />
                        <EntitiesCustomFilter filters={filters} onFiltersChange={handleFiltersChange} />
                    </HeaderLeftSide>
                    <HeaderRightSide>
                        <ExportButton title="Entities" paramsWatcher={paramsWatcher} onExportData={handleExportData} />
                        <ClearFiltersButton title="Entities" gridApi={gridApi} gridColumnApi={gridColumnApi} onClick={handleFiltersClear} />
                        {defaultFilters.defaultParentId && <AddButton title="Add Child Entity" />}
                    </HeaderRightSide>
                </HeaderWrapper>
                <AgTable
                    onGridReady={handleGridReady}
                    gridOptions={gridOptions}
                    customFilters={filters}
                    onFiltersLoadedFromUrl={handleFiltersClear}
                    repository={tableRepository}
                    paramsWatcher={paramsWatcher}
                    onFetchData={handleFetchData}
                />
            </div>
        </>
    );
};

export const entitiesColumnDefs: ColDef[] = [
    {
        field: 'name',
        headerName: 'Name',
        filter: 'agCustomFilter',
        initialSort: 'asc',
        width: 350,
    },
    {
        field: 'functions',
        headerName: 'Type',
        filter: 'agCustomFilter',
        cellRenderer: 'ListRender',
        width: 350,
    },
    {
        field: 'parent',
        headerName: 'Parent',
        filter: 'agCustomFilter',
        width: 350,
        filterParams: {
            isNullable: true,
        },
    },
    {
        field: 'address',
        headerName: 'Address',
        sortable: false,
        width: 350,
    },
];

export default EntitiesList;
