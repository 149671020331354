import React from 'react';
import './filter-section.scss';
import { FilterSectionProps } from './types';

export const FilterSection: React.FC<FilterSectionProps> = ({ children, className }) => {
    const renderFilter = (child: React.ReactNode) => {
        return <div className="filter">{child}</div>;
    };

    return <div className={`filter-section ${className ?? ''}`}>{React.Children.map(children, (child) => renderFilter(child))}</div>;
};

export default React.memo(FilterSection);
