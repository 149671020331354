import { CellClickedEvent, ColumnApi, GridApi, GridReadyEvent } from 'ag-grid-community';
import AgTable from 'common/components/ag-table/ag-table';
import { IParamsWatcher } from 'common/components/ag-table/types';
import ClearFiltersButton from 'common/components/header/clear-filters-button/clear-filters-button';
import ExportButton from 'common/components/header/export-button/export-button';
import HeaderLeftSide from 'common/components/header/header-left-side';
import HeaderRightSide from 'common/components/header/header-right-side';
import HeaderWrapper from 'common/components/header/header-wrapper';
import SearchFilterInput from 'common/components/header/search-filter-input/search-filter-input';
import ColumnsVisibleFilterSelect from 'common/components/header/сolumns-visible-filter-select/columns-visible-filter-select';
import { dateTimeConstants } from 'common/constants/common';
import { DisplayValueHelper } from 'common/helpers/display-value-helper';
import { LocalStoreRepository } from 'common/helpers/repository/local-store-repository';
import { useSearchInput } from 'common/hooks/use-serch-input';
import { getExportData, getFilterData, getTableData } from 'common/services/api/tabel-fetch-service';
import { ILoggingFilters } from 'features/logging-page/components/logging-table/types';
import { PayTypes } from 'models/enums/pay-types';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import { filterParamsBoolean } from 'common/components/ag-table/filters/constants/filter-params-boolean';
import ObjectLinkValueRender from 'common/components/ag-table/render-components/object-link-value-render';
import { DocumentRulesTableProps, IDocumentRuleFilter } from './types';
import { NavigationRoutes } from 'models/routes/navigation-routes';
import { DateTimeHelper } from 'common/helpers/date-time-helper';
import { LocalTableRepository } from 'common/helpers/repository/local-table-repository';

const customerColumnId = 'customer';

const handleFetchData = getTableData('document-rules');
const handleFetchFilters = getFilterData('document-rules/filter/source');
const handleExportData = getExportData('document-rules/export');
const convertDateToFormat = (data: string, format: string): string => DateTimeHelper.format(data, format, true);

const localStoreRepository = new LocalTableRepository('document-rules-table', 'v2');
const customFiltersRepository = new LocalStoreRepository('document-rules-table-custom-filters');
let loadedFilters: ILoggingFilters = customFiltersRepository.load();

const defaultFilters: IDocumentRuleFilter = {
    search: null,
};

const DocumentRulesTable: React.FC<DocumentRulesTableProps> = ({ updateDocumentRules, onDocumentRuleClick, onDataLoaded }) => {
    const [gridApi, setGridApi] = useState<GridApi>(null);
    const [gridColumnApi, setColumnApi] = useState<ColumnApi>(null);
    const [filters, setFilters] = useState(loadedFilters ?? defaultFilters);
    const { search, handleSearchInputChange, setSearch } = useSearchInput(loadedFilters?.search, (value: string) => {
        setFilters((prev) => ({ ...prev, search: value }));
    });
    const paramsWatcher = useRef<IParamsWatcher>();
    const resetSortByFilter = useRef<Function>(null);

    useEffect(() => {
        if (updateDocumentRules && gridApi) {
            gridApi.onFilterChanged();
        }
    }, [updateDocumentRules, gridApi]);

    useEffect(() => {
        customFiltersRepository.save(filters);
        loadedFilters = filters;
    }, [filters]);

    const getRows = (api: GridApi) => {
        const rows: Array<any> = [];
        api?.forEachNode((node) => rows.push(node.data));

        return rows;
    };

    const gridOptions = useMemo(
        () => ({
            onCellClicked: (event: CellClickedEvent) => {
                const { customer } = event.data;

                switch (event.column.getColId()) {
                    case customerColumnId: {
                        if (customer?.id) {
                            window.open(NavigationRoutes.entityDetailsRoute(customer?.id), '_blank').focus();
                        }
                        break;
                    }
                    default: {
                        onDataLoaded(getRows(event.api));
                        onDocumentRuleClick(event.data);
                        break;
                    }
                }
            },
            columnDefs: [
                {
                    field: 'name',
                    headerName: 'Name',
                    initialSort: 'asc',
                    width: 350,
                    filter: 'agCustomFilter',
                },
                {
                    field: 'acceptableDocuments',
                    headerName: 'Documents',
                    width: 350,
                    valueFormatter: (props: any) => props?.value.map((item: any) => item.name).join(', '),
                    filter: 'agCustomFilter',
                },
                {
                    field: 'effectiveDate',
                    headerName: 'Effective Date',
                    valueFormatter: (props: any) => convertDateToFormat(props.value, dateTimeConstants.MM_DD_YYYY),
                    accessor: 'effectiveDate',
                    filter: 'agDateColumnFilter',
                    width: 154,
                },
                {
                    field: 'endDate',
                    headerName: 'End Date',
                    valueFormatter: (props: any) => convertDateToFormat(props.value, dateTimeConstants.MM_DD_YYYY),
                    accessor: 'endDate',
                    filter: 'agDateColumnFilter',
                    width: 154,
                },
                {
                    field: 'payType',
                    headerName: 'Pay Type',
                    valueFormatter: (props: any) => Object.values(PayTypes).find((i) => i.value === props.value)?.label,
                    filter: 'agCustomFilter',
                    width: 115,
                    filterParams: {
                        isNullable: true,
                    },
                },
                {
                    field: 'department',
                    headerName: 'Department',
                    valueFormatter: (props: any) => DisplayValueHelper.getValue(props.value, 'name'),
                    filter: 'agCustomFilter',
                    width: 180,
                    filterParams: {
                        isNullable: true,
                    },
                },
                {
                    field: 'subDepartment',
                    headerName: 'Sub Department',
                    valueFormatter: (props: any) => DisplayValueHelper.getValue(props.value, 'name'),
                    filter: 'agCustomFilter',
                    width: 209,
                    filterParams: {
                        isNullable: true,
                    },
                },
                {
                    field: 'discipline',
                    headerName: 'Discipline',
                    valueFormatter: (props: any) => DisplayValueHelper.getValue(props.value, 'name'),
                    filter: 'agCustomFilter',
                    width: 140,
                    filterParams: {
                        isNullable: true,
                    },
                },
                {
                    field: customerColumnId,
                    headerName: 'Customer',
                    valueFormatter: (props: any) => DisplayValueHelper.getValue(props.value, 'name'),
                    filter: 'agCustomFilter',
                    cellRenderer: 'objectLinkValueRender',
                    width: 350,
                    filterParams: {
                        isNullable: true,
                    },
                },
                {
                    field: 'isOverrideAllow',
                    headerName: 'Allow override',
                    valueFormatter: (props: any) => DisplayValueHelper.convertToYesOrNo(props.value),
                    filter: 'agCustomFilter',
                    filterParams: filterParamsBoolean,
                    width: 170,
                },
                {
                    field: 'isOrientationRelated',
                    headerName: 'Orientation Related',
                    valueFormatter: (props: any) => DisplayValueHelper.convertToYesOrNo(props.value),
                    filter: 'agCustomFilter',
                    filterParams: filterParamsBoolean,
                    width: 200,
                },
                {
                    field: 'isComplianceRelated',
                    headerName: 'Compliance Related',
                    valueFormatter: (props: any) => DisplayValueHelper.convertToYesOrNo(props.value),
                    filter: 'agCustomFilter',
                    filterParams: filterParamsBoolean,
                    width: 204,
                },
                {
                    field: 'isPublic',
                    headerName: 'Public',
                    valueFormatter: (props: any) => DisplayValueHelper.convertToYesOrNo(props.value),
                    filter: 'agCustomFilter',
                    filterParams: filterParamsBoolean,
                    width: 92,
                },
            ],
            defaultColDef: {
                resizable: true,
                filterParams: {
                    onFetchFilters: handleFetchFilters,
                },
            },
            frameworkComponents: {
                objectLinkValueRender: ObjectLinkValueRender,
            },
        }),
        [onDataLoaded, onDocumentRuleClick]
    );

    // Clear
    const onFiltersClear = () => {
        setSearch('');
        setFilters({ ...defaultFilters });
        if (resetSortByFilter.current) {
            resetSortByFilter.current();
        }
    };

    const onGridReady = (params: GridReadyEvent) => {
        setGridApi(params.api);
        setColumnApi(params.columnApi);
    };

    return (
        <>
            <div className="main-info-wrapper ag-table-wrapper">
                <HeaderWrapper>
                    <HeaderLeftSide>
                        <SearchFilterInput title="Documents" value={search} onInput={handleSearchInputChange} />
                        <ColumnsVisibleFilterSelect title="Documents" gridApi={gridApi} gridColumnApi={gridColumnApi} />
                    </HeaderLeftSide>
                    <HeaderRightSide>
                        <ExportButton title="Documents" paramsWatcher={paramsWatcher} onExportData={handleExportData} />
                        <ClearFiltersButton title="Documents" gridApi={gridApi} gridColumnApi={gridColumnApi} onClick={onFiltersClear} />
                    </HeaderRightSide>
                </HeaderWrapper>
                <AgTable
                    onGridReady={onGridReady}
                    gridOptions={gridOptions}
                    customFilters={filters}
                    paramsWatcher={paramsWatcher}
                    onFetchData={handleFetchData}
                    repository={localStoreRepository}
                />
            </div>
        </>
    );
};

export default DocumentRulesTable;
