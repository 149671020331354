import { IRejectRequest } from 'common/models/reject-request';
import { IOption } from 'common/models/types';
import { ILegalEntityRate } from '../rate/types';
import { IUserBaseInfo } from '../user/types';
import { IEntityAddress } from '../entity/types';

export enum NursingHomeRequestStatusCount {
    New = 'newCount',
    Open = 'openCount',
    Assigned = 'assignedCount',
    Closed = 'closedCount',
}

export interface INursingHomeRequestStatusCount {
    [NursingHomeRequestStatusCount.New]: number;
    [NursingHomeRequestStatusCount.Open]: number;
    [NursingHomeRequestStatusCount.Assigned]: number;
    [NursingHomeRequestStatusCount.Closed]: number;
}

export interface IFacilityDetails extends IOption {
    address?: IEntityAddress;
}

export interface INursingHomeRequestDetails {
    id: number;
    status: NursingHomeRequestStatus;
    entity: IOption;
    facility: IFacilityDetails;
    therapyType: IOption;
    placementType: IOption;
    rate: ILegalEntityRate;
    customRate?: number;
    coverageType: string;
    startDate: Date;
    endDate: Date;
    source: string;
    cameInDate: Date;
    requestNumber: string;
    specialInstructions: string;
    flexDate: boolean;
    flexTime: boolean;
    createdDate: Date;
    updatedAt?: Date;
    createdBy: string;
    updatedBy: string;
    assignedTo: IUserBaseInfo;
    weekDays: IWeekDay[];
    therapists: any[];
}

export interface IWeekDay {
    id: number;
    day: string;
    startTime: string;
    endTime: string;
}

export enum NursingHomeRequestStatus {
    New = 'New',
    Closed = 'Closed',
}

export interface INursingHomeRequestCloseReason extends IRejectRequest {}
