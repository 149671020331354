import { InfiniteServerClient } from 'common/helpers/http-clients';
import { INote } from 'common/models/note';
import { INoteStatusReponse } from './types';

export class NoteService {
    complete(id: number): Promise<void> {
        return InfiniteServerClient.put<void>(`/notes/${id}/actions/complete`).then(
            (response) => {
                return response.data;
            },
            (error) => {
                throw error;
            }
        );
    }

    getStatus(): Promise<INoteStatusReponse> {
        return InfiniteServerClient.get<INoteStatusReponse>(`/notes/status`).then(
            (response) => {
                return response.data;
            },
            (error) => {
                throw error;
            }
        );
    }

    getTodayNotes(): Promise<INote[]> {
        return InfiniteServerClient.get('/notes/today').then(
            (response) => {
                return response.data;
            },
            (error) => {
                throw error;
            }
        );
    }
}

export default new NoteService();
