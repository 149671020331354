import React, { useEffect, useState } from 'react';
import './data-pagination.scss';
import { DataPaginationProps } from './types';

const DataPagination = ({ children, className, onBack }: DataPaginationProps) => {
    const childrenArray = React.Children.toArray(children);
    const length = childrenArray.length || 0;
    const [page, setPage] = useState<number>(0);

    const increment = () => setPage((prev) => (prev < length - 1 ? prev + 1 || 0 : prev));
    const decrement = () => setPage((prev) => (prev > 0 ? prev - 1 : 0));

    useEffect(() => {
        setPage(0);
    }, [children]);

    return (
        <div className={`data-pagination ${className ?? ''}`}>
            <div className={'data-pagination__header'}>
                {onBack && (
                    <button className="base-text-btn white" onClick={onBack}>
                        Back
                    </button>
                )}
                <span className="icon icon-back data-pagination__back" onClick={decrement} />
                <span className="data-pagination__counter">{`${page + 1}/${length}`}</span>
                <span className="icon  icon-back gly-rotate-45 data-pagination__next" onClick={increment} />
            </div>
            <div className={'data-pagination__body'}>{childrenArray[page]}</div>
        </div>
    );
};

export default DataPagination;
