import { Dispatch } from '@reduxjs/toolkit';
import noteService from 'common/services/api/note/note-service';
import { CurrentNoteStatus } from 'common/services/api/note/types';
import { ISetNoteStatus, ThunkResult } from './types';

export const setCurrentNoteStatus = (payload: CurrentNoteStatus): ISetNoteStatus => {
    return {
        status: payload,
        type: 'SET_NOTE_STATUS',
    };
};

export const refreshNoteStatus = (): ThunkResult<Promise<CurrentNoteStatus>> => {
    return async (dispatch: Dispatch) => {
        const data = await noteService.getStatus();
        dispatch(setCurrentNoteStatus(data.status));

        return data.status;
    };
};
