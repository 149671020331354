import { GridApi } from 'ag-grid-community';
import React, { RefObject, useCallback, useEffect, useRef, useState } from 'react';
import useOnClickOutside from 'react-cool-onclickoutside';
import ReactDOM from 'react-dom';
import TherapistAssignDialogHeader from './therapist-assign-dialog-header';
import './therapist-assign-dialog.scss';
import { ITableWrapperReady, RequestType } from 'common/constants/types';
import { IDiscipline } from 'common/services/api/disciplince/types';
import disciplinceService from 'common/services/api/disciplince/disciplince-service';
import { DisplayMode } from 'common/components/toggle-view-mode/types';
import { IIcon } from 'common/components/map/map-wrapper/types';
import { useDepartmentData } from 'common/hooks/use-department-data';
import { departmentKey } from 'common/constants/department-key';
import AvailableTherapistTable from 'common/components/availability/availability-table/available-therapist-table';
import { IAvailableTherapistFilter } from 'common/components/availability/availability-table/types';
import { IAddressDetails, IAvailableTherapistMapFilter } from 'common/components/availability/availability-map/types';
import AvailableTherapistMapDialog from 'common/components/availability/availability-map/available-therapist-map-dialog';
import { getWeekOptions } from 'common/components/availability/types';
import { IDepartment } from 'common/services/api/department/types';

type TherapistAssignDialogProp = {
    addressDetails?: IAddressDetails;
    headerInfo?: () => JSX.Element | JSX.Element[];
    toggleTherapistAssign: (isOpen: boolean) => void;
    requestDetails: any;
    onAddTherapistToRequest: (requestId: number, therapistIds: number[]) => void;
    requestType: RequestType;
    iconArray?: IIcon[];
    extendGridOptions?: any;
};

const TherapistAssignDialog: React.FC<TherapistAssignDialogProp> = ({
    headerInfo,
    addressDetails,
    toggleTherapistAssign,
    requestDetails,
    requestType,
    onAddTherapistToRequest,
    iconArray,
    extendGridOptions,
}) => {
    const modalWrapper = useRef() as RefObject<HTMLDivElement>;
    const gridApiRef = useRef<GridApi>();

    const [displayMode, setDisplayMode] = useState(DisplayMode.List);
    const [tableFilter, setTableFilter] = useState<IAvailableTherapistFilter>({
        search: null,
        searchZip: addressDetails?.zip || requestDetails?.zipCode,
        assignmentStatus: 'Active',
        week: getWeekOptions()[0]?.value,
        department: null,
        therapyTypeId: requestDetails?.therapyType?.id,
        customerId: requestDetails?.customer?.id,
        language: requestDetails?.language?.id,
        populations: requestDetails?.population ? [requestDetails?.population.name] : [],
        zipCodes: [],
        counties: [],
        disciplines: [],
        requestId: requestDetails?.id,
    });

    const [availableDisciplines, setAvailableDisciplines] = useState<IDiscipline[]>([]);
    const [selectedTherapists, setSelectedTherapists] = useState<number[]>([]);
    const departments = useDepartmentData();

    const getRequestDepartment = useCallback(() => {
        if (requestType === RequestType.HomeCare) {
            return requestDetails?.department as IDepartment;
        }

        if (requestType === RequestType.NursingHome) {
            return departments.find((i) => i.key === departmentKey.SNF);
        }

        if (requestType === RequestType.School) {
            return departments.find((i) => i.key === departmentKey.SCHOOL);
        }

        throw new Error('Invalid request type');
    }, [departments, requestDetails, requestType]);

    // Set DEFAULT department
    useEffect(() => {
        if (departments?.length > 0) {
            const departmentId = getRequestDepartment().id;

            setTableFilter((prev) => ({ ...prev, department: departmentId }));
        }
    }, [departments, getRequestDepartment]);

    const getDepartments = useCallback(() => {
        if (requestType === RequestType.NursingHome || requestType === RequestType.School) {
            return [];
        }

        return departments.filter((d) => d.key === departmentKey.HCA || d.key === departmentKey.HCB);
    }, [departments, requestType]);

    useEffect(() => {
        document.body.classList.add('scroll-hidden');

        return () => {
            document.body.classList.remove('scroll-hidden');
        };
    }, []);

    useEffect(() => {
        if (departments === null || departments.length === 0 || !tableFilter.department) {
            return;
        }

        const therapyTypeid = requestDetails?.therapyType?.id;

        const customerId = requestType === RequestType.NursingHome ? requestDetails?.entity?.id : requestDetails?.customer?.id;
        const populationId = requestType === RequestType.NursingHome ? null : requestDetails?.population?.id;

        const key = departments.find((i) => i.id === tableFilter.department).key;

        if (therapyTypeid && key) {
            disciplinceService.getAvailableDisciplines(key, therapyTypeid, customerId, populationId).then((result) => {
                setAvailableDisciplines(result);
            });
        }
    }, [departments, requestDetails, requestType, tableFilter.department]);

    const handleSelectedTherapistsChange = useCallback((ids: number[]) => {
        setSelectedTherapists([...ids]);
    }, []);

    const handleListClick = () => {
        setDisplayMode(DisplayMode.List);
    };

    const handleMapClick = (currentTableFilter: IAvailableTherapistFilter) => {
        setDisplayMode(DisplayMode.Map);
        setTableFilter(currentTableFilter);
    };

    useOnClickOutside(
        () => {
            toggleTherapistAssign(false);
        },
        { refs: [modalWrapper], ignoreClass: ['pac-container', 'pac-logo', 'patients-in-zip-dialog-wrapper', 'zoomer'] }
    );

    const handleOnCancelClick = () => {
        toggleTherapistAssign(false);
    };

    const handleSearchChange = (value: string) => {
        setTableFilter((prev) => ({ ...prev, search: value }));
    };

    const handleZipChange = (value: string) => {
        setTableFilter((prev) => ({ ...prev, searchZip: value }));
    };

    const handleOnSaveClick = () => {
        if (displayMode === DisplayMode.Map) {
            if (selectedTherapists?.length === 0) {
                toggleTherapistAssign(false);
            } else {
                onAddTherapistToRequest(requestDetails.id, selectedTherapists);
            }
        } else {
            if (gridApiRef.current) {
                const selectedRows = gridApiRef.current.getSelectedRows();

                if (selectedRows?.length === 0) {
                    toggleTherapistAssign(false);
                } else {
                    onAddTherapistToRequest(
                        requestDetails.id,
                        selectedRows.map((s) => s.id)
                    );
                }
            }
        }
    };

    const handleOnGridReady = ({ params }: ITableWrapperReady) => {
        gridApiRef.current = params.api;
    };

    const handleFilterChange = (value: IAvailableTherapistFilter) => {
        setTableFilter((prev) => ({ ...prev, ...value }));
    };

    const handleFilterClear = useCallback(() => {
        const filter: IAvailableTherapistFilter = {
            ...tableFilter,
            search: null,
            searchZip: addressDetails?.zip || requestDetails?.zipCode,
            assignmentStatus: 'Active',
            week: getWeekOptions()[0]?.value,
            department: getRequestDepartment().id,
        };

        setTableFilter(filter);

        return filter;
    }, [addressDetails, getRequestDepartment, requestDetails, tableFilter]);

    const renderListType = useCallback(() => {
        return (
            <AvailableTherapistTable
                onGridReady={handleOnGridReady}
                onMapClick={handleMapClick}
                departments={getDepartments()}
                filter={tableFilter}
                filterConfig={{ department: true }}
                onFilterChange={handleFilterChange}
                onSearchChange={handleSearchChange}
                onZipChange={handleZipChange}
                onFilterClear={handleFilterClear}
                extendGridOptions={extendGridOptions}
            />
        );
    }, [getDepartments, handleFilterClear, tableFilter, extendGridOptions]);

    const renderShowOnMapType = useCallback(() => {
        const defaultFilter: IAvailableTherapistMapFilter = {
            languages: requestDetails?.language ? [requestDetails?.language?.id] : [],
            populations: requestDetails?.population ? [requestDetails?.population?.id] : [],
            week: tableFilter?.week,
            preferredDepartments: [],
            preferredSubDepartments: [],
            departments: [],
            disciplines: availableDisciplines.map((i) => i.id),
            complianceStatuses: [],
            statuses: [],
            subDepartments: [],
            employmentStatuses: [],
        };

        const zip = tableFilter?.searchZip || requestDetails?.zipCode;

        return (
            <AvailableTherapistMapDialog
                iconArray={iconArray}
                addressDetails={addressDetails}
                request={requestDetails}
                onSelectedTherapistsChange={handleSelectedTherapistsChange}
                zip={zip}
                filter={defaultFilter}
                onListClick={handleListClick}
            />
        );
    }, [requestDetails, tableFilter, availableDisciplines, iconArray, addressDetails, handleSelectedTherapistsChange]);

    const renderContent = useCallback(() => {
        return displayMode === DisplayMode.List ? renderListType() : renderShowOnMapType();
    }, [displayMode, renderListType, renderShowOnMapType]);

    const modal = (
        <>
            <div>
                <div className="therapist-assign-dialog-backgroud"></div>
                <div ref={modalWrapper} className="therapist-assign-dialog">
                    <div className="table-page">
                        <TherapistAssignDialogHeader
                            headerInfo={headerInfo}
                            handleOnCancelClick={handleOnCancelClick}
                            handleOnSaveClick={handleOnSaveClick}
                        />
                        {tableFilter.department && renderContent()}
                    </div>
                </div>
            </div>
        </>
    );

    return ReactDOM.createPortal(modal, document.body);
};

export default TherapistAssignDialog;
