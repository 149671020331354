import { Action } from '@reduxjs/toolkit';
import Avatar from 'common/components/Avatar/Avatar';
import Button from 'common/components/Button/button';
import React, { useRef } from 'react';
import { useDispatch } from 'react-redux';
import { toast } from 'react-toastify';
import { ThunkDispatch } from 'redux-thunk';
import './update-avatar-section.scss';
import { UpdateAvatarSectionProps } from './types';
import { MAX_IMAGE_SIZE } from './constant';

const UpdateAvatarSection = ({ currentImageData, handleFileChange, user }: UpdateAvatarSectionProps) => {
    const dispatch = useDispatch<ThunkDispatch<any, any, Action>>();
    const inputRef = useRef<HTMLInputElement>();
    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const files = event.target.files;

        if (!files || files.length === 0) return;

        if (files[0].size > MAX_IMAGE_SIZE) {
            toast.error('The image is too large to upload and needs to be resized, maximum size is 2mb');

            return;
        }

        dispatch(handleFileChange(files[0], event)).catch((error) => {
            const errorMessage = (error.photo && error.photo[0]) || 'An error occurred. Please try to upload another image.';
            toast.error(errorMessage);
        });
    };

    const handleClickOnUploadButton = () => {
        inputRef.current.click();
    };

    let avatar;
    if (user || currentImageData)
        avatar = (
            <div className="image-preview inline">
                <Avatar user={user} imageData={currentImageData} />
            </div>
        );

    return (
        <div className="update-therapist-profile-photo">
            <div>
                <h1 className="avatar-header">Avatar Image</h1>
            </div>
            <div className="update-section">
                {avatar}
                <div className="image-upload-button inline">
                    <input type="file" ref={inputRef} hidden id="update-avatar-input" accept=".png,.jpg,.jpeg" onChange={handleChange} />
                    <Button id="upload-avatar-button" onClick={handleClickOnUploadButton} className="base-text-btn white">
                        {currentImageData ? 'Update Avatar' : 'Upload Avatar'}
                    </Button>
                </div>
            </div>
        </div>
    );
};

export default UpdateAvatarSection;
