import { trackPromise } from 'react-promise-tracker';
import { SequenceHelper } from 'common/helpers/sequence-helper';
import {
    ICreateEditTherapistAssignment,
    IEditContactInfo,
    IEditGeneralInfo,
    IEditPreferences,
    IInactiveReason,
    ITherapistDetailsModel,
} from 'common/services/api/therapist/types';
import {
    GET_THERAPIST_DETAILS_SUCCESS,
    GET_THERAPIST_DOCUMENTS_SUCCESS,
    ThunkResult,
    UPDATE_INACTIVE_REASON,
    UPDATE_THERAPIST_ASSIGNED_TO,
    UPDATE_THERAPIST_HAS_ASSIGNMENTS,
    UPDATE_THERAPIST_PROFILE_PHOTO_SUCCESS,
    UPDATE_THERAPIST_STATUS,
    UPDATE_THERAPIST_SUCCESS,
} from './types';
import therapistService from 'common/services/api/therapist/therapist-service';
import { IPhone } from 'features/shared/types';
import { TherapistStatus } from 'features/therapist-details-page/shared/constants';
import { IRejectRequest } from 'common/models/reject-request';

export const getTherapistDetailsRequest = (id: number): ThunkResult<Promise<ITherapistDetailsModel>> => {
    return async (dispatch: any) => {
        const data = await therapistService.getTherapistDetails(id);

        if (data) {
            data.contactInfo.phones = (SequenceHelper.getSequenceItems(data.contactInfo.phones) as IPhone[]) ?? [];
            dispatch(getTherapistDetailsSuccess(data));
        }

        return data;
    };
};

export const initiateStartProcessRequest = (therapistId: number): ThunkResult<Promise<void>> => {
    return async function (dispatch: any) {
        await therapistService.initiateStartProcess(therapistId);
        dispatch(getTherapistDetailsRequest(therapistId));
    };
};

export const approveProfile = (therapistId: number): ThunkResult<Promise<void>> => {
    return async function (dispatch: any) {
        await therapistService.approveProfile(therapistId);
        dispatch(getTherapistDetailsRequest(therapistId));
    };
};

export const rejectProfile = (therapistId: number, request: IRejectRequest): ThunkResult<Promise<void>> => {
    return async function (dispatch: any) {
        await therapistService.rejectProfile(therapistId, request);
        dispatch(getTherapistDetailsRequest(therapistId));
    };
};

export const updateTherapistGeneralInfoRequest = (
    id: number,
    generalInfo: IEditGeneralInfo
): ThunkResult<Promise<ITherapistDetailsModel>> => {
    return async function (dispatch: any) {
        const response = await therapistService.updateTherapistGeneralInfo(id, generalInfo);
        const data = response.data;
        if (data) {
            dispatch(updateTherapistGeneralInfoSuccess(data));

            return data;
        }
    };
};

export const updateTherapistContactInfoRequest = (
    id: number,
    contactInfo: IEditContactInfo
): ThunkResult<Promise<ITherapistDetailsModel>> => {
    return async function (dispatch: any) {
        const response = await therapistService.updateTherapistContactInfo(id, contactInfo);
        const data = response.data;
        if (data) {
            dispatch(updateTherapistGeneralInfoSuccess(data));

            return data;
        }
    };
};

export const updateTherapistPreferencesRequest = (
    id: number,
    preferences: IEditPreferences
): ThunkResult<Promise<ITherapistDetailsModel>> => {
    return async function (dispatch: any) {
        const response = await therapistService.updateTherapistPreferences(id, preferences);
        const data = response.data;
        if (data) {
            dispatch(updateTherapistGeneralInfoSuccess(data));

            return data;
        }
    };
};

export const updateTherapistProflePhotoRequest = (id: number, photo: File) => {
    return async function (dispatch: any) {
        const response = await therapistService.updateTherapistProflePhoto(id, photo);
        const responseBody = response.data;

        if (responseBody) {
            dispatch(updateTherapistProfilePhotoSuccuess(responseBody.data));

            return responseBody.data;
        }
    };
};

export const addAssignmentRequest = (
    therapistId: number,
    assignment: ICreateEditTherapistAssignment
): ThunkResult<Promise<ITherapistDetailsModel>> => {
    return async function (dispatch: any) {
        const response = await therapistService.addAssignment(therapistId, assignment);
        const data = response.data;
        if (data) {
            trackPromise(dispatch(getTherapistDetailsRequest(therapistId)));

            return data;
        }
    };
};

export const editAssignmentRequest = (
    therapistId: number,
    assignment: ICreateEditTherapistAssignment
): ThunkResult<Promise<ITherapistDetailsModel>> => {
    return async function (dispatch: any) {
        const data = await therapistService.editAssignment(therapistId, assignment);
        if (data) {
            trackPromise(dispatch(getTherapistDetailsRequest(therapistId)));

            return data;
        }
    };
};

export const deactivateTherapist = (therapistId: number, data: IInactiveReason): ThunkResult<Promise<void>> => {
    return async function (dispatch: any) {
        await therapistService.deactivateTherapist(therapistId, data);
        dispatch({ type: UPDATE_THERAPIST_STATUS, status: TherapistStatus.INACTIVE.value });
        dispatch({
            type: UPDATE_INACTIVE_REASON,
            inactiveReasons: data.reasons,
            inactiveReasonComment: data.comment,
        });
    };
};

export const updateTherapistStatus = (therapistId: number, status: string): ThunkResult<Promise<void>> => {
    return async function (dispatch: any) {
        await therapistService.updateTherapistStatus(therapistId, status);
        dispatch({
            type: UPDATE_THERAPIST_STATUS,
            status: status,
        });
        dispatch({
            type: UPDATE_INACTIVE_REASON,
            inactiveReasons: [],
            inactiveReasonComment: null,
        });
    };
};

export const getDocumentsRequest = (therapistId: number): ThunkResult<Promise<void>> => {
    return async function (dispatch: any) {
        const data = await therapistService.getDocuments(therapistId);
        if (data) {
            dispatch({ type: GET_THERAPIST_DOCUMENTS_SUCCESS, data: data });
        }
    };
};

export const getTherapistDetailsSuccess = (therapist: ITherapistDetailsModel) => {
    return {
        type: GET_THERAPIST_DETAILS_SUCCESS,
        therapist,
    };
};

export const updateTherapistGeneralInfoSuccess = (therapist: ITherapistDetailsModel) => {
    return {
        type: UPDATE_THERAPIST_SUCCESS,
        therapist,
    };
};

export const updateTherapistProfilePhotoSuccuess = (imageData: string) => {
    return {
        type: UPDATE_THERAPIST_PROFILE_PHOTO_SUCCESS,
        profilePhoto: imageData,
    };
};

export const updateTherapistAssignedTo = (assignedTo: string) => {
    return {
        type: UPDATE_THERAPIST_ASSIGNED_TO,
        assignedTo,
    };
};

export const updateTherapistHasAssignments = (hasAssignments: true) => {
    return {
        type: UPDATE_THERAPIST_HAS_ASSIGNMENTS,
        hasAssignments,
    };
};
