import { CUSTOMER, FACILITY } from 'common/constants/common';
import PatientsList from 'features/patients-page/components/patients-list/patients-list';
import { patientColumnDef } from 'features/patients-page/components/patients-list/patients-list';
import React from 'react';
import { GeneralInfoTabProp } from '../types';

const EntityPatintsTab: React.FC<GeneralInfoTabProp> = ({ id: entityId }) => {
    return (
        <div className="entity-child-tab tabs-active-horizontal table-page">
            <PatientsList
                storePrefix="entity-patients"
                extendGridOptions={{
                    columnDefs: entityColumnDef,
                }}
                extendDefaultCustomFilters={{ entityId: entityId }}
                serachTitle="Entity Patients"
            />
        </div>
    );
};

const entityColumnDef = [...patientColumnDef];
const indexCustomerName = patientColumnDef.findIndex((columnConfig) => columnConfig.field === CUSTOMER);
const indexFacilityName = patientColumnDef.findIndex((columnConfig) => columnConfig.field === FACILITY);

if (indexCustomerName > -1) {
    entityColumnDef[indexCustomerName] = {
        field: CUSTOMER,
        headerName: 'Customer',
        cellRenderer: 'cellLinkRender',
        sortable: false,
        minWidth: 350,
        valueFormatter: (props: any) => props?.value?.name,
        hide: true,
    };

    entityColumnDef[indexFacilityName] = {
        field: FACILITY,
        headerName: 'Facility',
        cellRenderer: 'cellLinkRender',
        sortable: false,
        minWidth: 350,
        valueFormatter: (props: any) => props?.value?.name,
        hide: true,
    };
}

export default EntityPatintsTab;
