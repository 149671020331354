import DropdownAutocomplete from 'common/components/field/dropdown/dropdown-autocomplete';
import { useDisciplinceData } from 'common/hooks/use-disciplince-data';
import DropdownOption from 'common/models/dropdown-option';
import React, { useCallback, useState } from 'react';
import { DisciplineAutocompleteProps } from './types';

const DisciplineAutocomplete: React.FC<DisciplineAutocompleteProps> = ({
    onChange,
    hasError,
    value,
    cleanable = true,
    popper,
    widthOptions,
    label = 'Discipline',
    defaultSelectedLabel = 'All',
    searchPlaceholder,
}) => {
    const [disciplineSearchTerm, setDisciplineSearchTerm] = useState<string>('');

    const disciplineOptions = useDisciplinceData().map((item) => new DropdownOption(item.id, item.name));

    const determineOptions = useCallback(() => {
        return disciplineSearchTerm
            ? disciplineOptions.filter((discpline) => discpline.label.toLowerCase().includes(disciplineSearchTerm.toLowerCase()))
            : disciplineOptions;
    }, [disciplineSearchTerm, disciplineOptions]);

    return (
        <DropdownAutocomplete
            hasError={hasError}
            popper={popper}
            widthOptions={widthOptions}
            defaultSelectedLabel={defaultSelectedLabel}
            items={determineOptions()}
            label={label}
            cleanable={cleanable}
            multiselect
            searchPlaceholder={searchPlaceholder ? searchPlaceholder : 'Discipline'}
            value={disciplineOptions.filter((item) => value.some((language) => language === item.value))}
            onChange={setDisciplineSearchTerm}
            onSelect={onChange}
        />
    );
};

export default React.memo(DisciplineAutocomplete);
