import DepartmentDropdownOption from 'common/models/department-dropdown-option';
import DropdownOption from 'common/models/dropdown-option';
import { ITherapistAddRate, ITherapistEditRate } from 'common/services/api/therapist-rates/types';
import { IForm } from 'common/models/types';

// Add Sidebar
export interface ITherapistRateForm {
    therapistId: number;
    departmentId: number;
    subDepartmentIds: number[];
    payType: string;
    corporateName?: string;
    taxId?: string;
    rateId?: number;
    rate: string;
    startDate: string;
    endDate: string;
    department?: DepartmentDropdownOption;
    subDepartment?: DropdownOption[];

    corporateNameSelector?: string;
    disableSave?: boolean;
}

// Edit Sidebar
export interface ITherapistRateEditForm {
    rateId: number;
    rate: string;
    startDate: string;
    endDate: string;
}

export interface ITherapistRateUpdateForm {
    editForm: ITherapistRateEditForm;
    createForm: ITherapistRateForm;
}

// Rate table row
export interface ITherapistRateTableView {
    id: number;
    rate: string;
    subDepartments: string;
    department: string;
    startDate: string;
    endDate: string;
    payType: string;
    contractState: string;
    corporateName: string;
    createdBy: string;
    dateCreation: string;
    taxId: string;
}

// Rate sidebar options
export interface IRateOption<T, D> {
    isOpen: boolean;
    rateTemplate: T;
    rateData: D;
}
export type RateEditOptionType = IRateOption<ITherapistTemplateView, ITherapistRateEditForm>;

export interface ITherapistTemplateView {
    subDepartments: string;
    department: string;
}
export type TherapistRateTemplateTypesProps = {
    rateTemplate: ITherapistTemplateView;
};

export type TherapistRateDialogUpdateProps = {
    data: ITherapistRateForm;
    template: ITherapistTemplateView;
    onClose?: () => void;
    onSave?: (rate: ITherapistEditRate, newRate: ITherapistAddRate) => Promise<void>;
};

export const DIFFERENT_CORPORATE_NAME = 'differentCorporateName';

export const EDIT_RATE_REQUEST = 'editRate.';
export const NEW_RATE_REQUEST = 'createRate.';

export enum RateErrorCodes {
    AddRateActiveConstraintFailed = 'ADD_CONFLICT_WITH_CURRENT_ACTIVE_RATE',
    AddRateActiveFutureConstraintFailed = 'ADD_CONFLICT_WITH_FUTURE_ACTIVE_RATE',
    AddtRatePastConstraintFailed = 'ADD_CONFLICT_WITH_PAST_RATE',
    EditRateActiveConstraintFailed = 'EDIT_CONFLICT_WITH_CURRENT_ACTIVE_RATE',
    EditRateActiveFutureConstraintFailed = 'EDIT_CONFLICT_WITH_FUTURE_ACTIVE_RATE',
    EditRatePastConstraintFailed = 'EDIT_CONFLICT_WITH_PAST_RATE',
}

export interface ITherapistRateFormBaseProps extends IForm<ITherapistEditRate> {
    isUpdate?: boolean;
}
export interface ITherapistRateFormCreateProps extends IForm<ITherapistRateForm> {
    isUpdate?: boolean;
}
