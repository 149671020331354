import React, { ErrorInfo } from 'react';
import { IErrorBoundaryState } from './type';

class ErrorBoundary extends React.Component<any, IErrorBoundaryState> {
    constructor(props: any) {
        super(props);
        this.state = { hasError: false };
    }

    static getDerivedStateFromError(_error: Error) {
        return { hasError: true };
    }

    componentDidCatch(error: Error, _errorInfo: ErrorInfo) {
        console.log(error);
    }

    render() {
        if (this.state.hasError) {
            return 'A error happened. Please connect with the development team';
        }

        return this.props.children;
    }
}

export default ErrorBoundary;
