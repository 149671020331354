export enum UpdatePasswordType {
    Reset,
    Setup,
}

export type SetupPasswordPageProps = {
    type: UpdatePasswordType;
};

export interface IUpdatePasswordModel {
    password: string;
    confirmPassword: string;
}
