import React from 'react';
import { IRequestReopenReason } from './types';
import { IFormSidebar } from 'common/models/types';
import useFormSidebar from 'common/hooks/use-form-sidebar';
import RightSideBarForm from 'common/components/right-side-bar/right-side-bar-form';
import RequestReopenForm from './request-reopen-form';

const RequestReopenSidebar = ({ onClose, onSave }: IFormSidebar<IRequestReopenReason, null>) => {
    const [data, error, handleChangeData, handleCancelClick, handleSaveClick] = useFormSidebar<IRequestReopenReason>(
        {
            reasonId: 0,
            comment: null,
        },
        { onClose, onSave }
    );

    return (
        <RightSideBarForm saveText="Reopen" title="Reopen a Declined Request" onCancel={handleCancelClick} onSave={handleSaveClick}>
            <RequestReopenForm data={data} errors={error} onChange={handleChangeData} />
        </RightSideBarForm>
    );
};

export default RequestReopenSidebar;
