import { InfiniteServerClient } from 'common/helpers/http-clients';
import { ITherapistAssignment } from './types';

export class TherapistAssignmentService {
    getAssignments(therapisId: number, filterQueryParams: string): Promise<ITherapistAssignment[]> {
        return InfiniteServerClient.get<ITherapistAssignment[]>(`/therapists/${therapisId}/assignments?${filterQueryParams}`).then(
            (response) => {
                return response.data;
            },
            (error) => {
                throw error;
            }
        );
    }
}

export default new TherapistAssignmentService();
