import { IPhone, IEmail } from '../../features/shared/types';

interface ICommunicationInfo {
    phones: IPhone[];
    isPhonesRequiered: boolean;
    emails?: IEmail[];
    isEmailsRequiered?: boolean;
}

export class CommunicationHelper {
    static Validate(communicationInfo: ICommunicationInfo) {
        const { phones, emails, isPhonesRequiered, isEmailsRequiered } = communicationInfo;
        const validPhonesAndEmails = { ...communicationInfo };

        const filtredEmails = emails?.filter((email) => email.email !== '');
        const filtredPhones = phones?.filter((phone) => phone.number !== '');

        const isEmailsExist = filtredEmails?.length > 0;
        const isPhonesExist = filtredPhones?.length > 0;

        if (isEmailsExist || !isEmailsRequiered) {
            validPhonesAndEmails.emails = filtredEmails;
        }

        if (isPhonesExist || !isPhonesRequiered) {
            validPhonesAndEmails.phones = filtredPhones;
        }

        return validPhonesAndEmails;
    }
}
