import React from 'react';
import Button from 'common/components/Button/button';
import { ShowOnMapButtonProps } from '../type';

const ShowOnMapButton = ({ disabled, onClick }: ShowOnMapButtonProps) => {
    const handleClick = () => {
        if (onClick) {
            onClick();
        }
    };

    return (
        <Button className={'base-text-btn white toggle-button'} id={`ShowOnMap`} onClick={handleClick} disabled={disabled}>
            <span className="icon icon-map"></span>
            Map
        </Button>
    );
};

export default React.memo(ShowOnMapButton);
