import React from 'react';
import './header.scss';

type HeaderRightSideProps = {
    className?: string;
    children: React.ReactNode;
};

const HeaderRightSide = ({ children, className = '' }: HeaderRightSideProps) => {
    return <div className={`table-header-actions ${className}`}>{children}</div>;
};

export default HeaderRightSide;
