import React, { useEffect, useRef, useState, useCallback } from 'react';
import { DepartmentListProps } from './types';
import './departments-list.scss';
import { useDepartmentData } from 'common/hooks/use-department-data';
import { ITherapistDepartments } from 'common/services/api/therapist/types';
import { trackPromise } from 'react-promise-tracker';
import therapistService from 'common/services/api/therapist/therapist-service';
import { DateTimeHelper } from 'common/helpers/date-time-helper';
import { dateTimeConstants } from 'common/constants/common';
import StatusDropdown from 'common/components/status-dropdown/status-dropdown';
import DropdownOption from 'common/models/dropdown-option';
import { ConverterHelper } from 'common/helpers/converter-helper';
import AssignToDropdown from 'common/components/assign-to-dropdown/assign-to-dropdown';

export const DepartmentList = ({ therapist }: DepartmentListProps) => {
    const departmentData = useDepartmentData();
    const [linkedDepartments, setLinkedDepartments] = useState<ITherapistDepartments[]>([]);

    const updateLinkedDepartments = useCallback(
        () =>
            trackPromise(
                therapistService.getLinkedDepartments(therapist.id).then((departments) => {
                    setLinkedDepartments(departments);
                })
            ),
        [therapist.id]
    );

    const idRef = useRef();
    useEffect(() => {
        if (idRef.current !== therapist.id) {
            idRef.current = therapist.id;
            updateLinkedDepartments();
        }
    }, [therapist.id, updateLinkedDepartments]);

    return (
        <div className="departments">
            {departmentData?.map((department) => {
                const linkedDepartmentIndex = linkedDepartments.findIndex(
                    (linkedDepartment) => linkedDepartment?.department?.id === department.id
                );
                const linkedDepartment = linkedDepartments[linkedDepartmentIndex];
                const handleLinkDepartmentWithTherapist = (statusId?: number, assignedTo?: string): Promise<ITherapistDepartments> => {
                    return therapistService.linkDepartmentWithTherapist(department.id, therapist.id, {
                        statusId: statusId ?? null,
                        assignedTo: assignedTo ?? null,
                    });
                };

                return (
                    <div className="departments__items" key={department.id}>
                        <div className="departments__header">
                            <div className="departments__name">{department.name}</div>
                            {linkedDepartment?.updatedAt && (
                                <div className="departments__last-update">
                                    {'Last Updated ' +
                                        DateTimeHelper.formatToLocalTime(linkedDepartment.updatedAt, dateTimeConstants.MM_DD_YYYY)}
                                </div>
                            )}
                        </div>
                        <div className="departments__selects">
                            {!linkedDepartment && (
                                <button
                                    className="base-text-btn white departments__add-button"
                                    onClick={() => {
                                        handleLinkDepartmentWithTherapist().then(updateLinkedDepartments);
                                    }}
                                >
                                    Add <span className="icon icon-add"></span>
                                </button>
                            )}
                            {linkedDepartment && (
                                <>
                                    <div className="general-info-actions-wrapp departments__employment-status">
                                        <span className="label">Employment status</span>
                                        <StatusDropdown
                                            getClass={(option: DropdownOption) => option?.label?.replace(' ', '')?.toLowerCase()}
                                            currentStatus={linkedDepartment?.employmentStatus?.name ?? ''}
                                            onChange={(option) => {
                                                trackPromise(
                                                    handleLinkDepartmentWithTherapist(option?.value, linkedDepartment?.assignedTo?.id).then(
                                                        (data) => {
                                                            linkedDepartments[linkedDepartmentIndex] = data;
                                                            setLinkedDepartments([...linkedDepartments]);
                                                        }
                                                    )
                                                );
                                            }}
                                            convertFunction={ConverterHelper.optionConvert}
                                            fetchData={() => therapistService.getEmploymentStatusOptions(department.id)}
                                        />
                                    </div>
                                    <div className="general-info-actions-wrapp departments__assign-top">
                                        <AssignToDropdown
                                            cleanable={false}
                                            defaultValue={linkedDepartment?.assignedTo?.fullName}
                                            onChange={(option: DropdownOption) => {
                                                trackPromise(
                                                    handleLinkDepartmentWithTherapist(
                                                        linkedDepartment?.employmentStatus?.id,
                                                        option?.value
                                                    ).then((data) => {
                                                        linkedDepartments[linkedDepartmentIndex] = data;
                                                        setLinkedDepartments([...linkedDepartments]);
                                                    })
                                                );
                                            }}
                                        />
                                    </div>
                                </>
                            )}
                        </div>
                    </div>
                );
            })}
        </div>
    );
};
