import { ColumnApi, GridApi } from 'ag-grid-community';
import { CellClickedEvent, GridReadyEvent } from 'ag-grid-community/dist/lib/events';
import AgTable from 'common/components/ag-table/ag-table';
import BadgeTopFilter from 'common/components/badges/badge/badge-top-filter/badge-top-filter';
import { IParamsWatcher } from 'common/components/ag-table/types';
import ClearFiltersButton from 'common/components/header/clear-filters-button/clear-filters-button';
import ExportButton from 'common/components/header/export-button/export-button';
import HeaderLeftSide from 'common/components/header/header-left-side';
import HeaderRightSide from 'common/components/header/header-right-side';
import HeaderWrapper from 'common/components/header/header-wrapper';
import SearchFilterInput from 'common/components/header/search-filter-input/search-filter-input';
import ColumnsVisibleFilterSelect from 'common/components/header/сolumns-visible-filter-select/columns-visible-filter-select';
import { DateTimeHelper } from 'common/helpers/date-time-helper';
import { LocalStoreRepository } from 'common/helpers/repository/local-store-repository';
import { useSearchInput } from 'common/hooks/use-serch-input';
import { getExportData, getFilterData, getTableData } from 'common/services/api/tabel-fetch-service';
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { ILead, ILeadStatusCount, LeadStatus } from 'common/services/api/lead/types';
import { ICustomLeadFilter, LeadsManagementTablesProps, LeadStatusLabel } from './types';
import { useDispatch } from 'react-redux';
import { ThunkDispatch } from 'redux-thunk';
import { Action } from '@reduxjs/toolkit';
import { FileHelper } from 'common/helpers/file-helper';
import { getFileActionCreator } from 'features/document-viewer/store/document-viewer-action-creators';
import LeadService from 'common/services/api/lead/lead-service';
import { LocalTableRepository } from 'common/helpers/repository/local-table-repository';
import { maskConstants } from 'common/constants/common';
import DisplayValue from 'common/components/display-value/display-value';
import { FilterHelper } from 'common/helpers/filter-helper';
import { IBadge } from 'common/components/badges/types';
import MultipleBadges from 'common/components/badges/multiple-badges';
import { DetailsNavigationHelper } from 'common/helpers/details-navigation-helper';
import { InfiniteTooltip } from 'common/components/infinite-tooltip/infinite-tooltip';

// Fetches
const handleFetchData = getTableData('leads');
const handleFetchFilters = getFilterData('leads/filter/source');
const handleExportData = getExportData('leads/export');

const convertDateToFormat = (data: string, format: string): string => DateTimeHelper.format(data, format);

const defaultBadgeList = [
    { value: LeadStatus.New.toString(), title: 'New', body: 0 },
    { value: LeadStatus.InProgress.toString(), title: 'In Progress', body: 0 },
    { value: LeadStatus.Verified.toString(), title: 'Verified', body: 0 },
    { value: LeadStatus.Refused.toString(), title: 'Refused', body: 0 },
];

const LeadsManagementTable: React.FC<LeadsManagementTablesProps> = ({
    updateTable,
    storePrefix = '',
    onVerifyClick,
    onRefuseClick,
    onOpenClick,
}) => {
    const dispatch = useDispatch<ThunkDispatch<any, any, Action>>();

    const [gridApi, setGridApi] = useState<GridApi>(null);
    const [gridColumnApi, setColumnApi] = useState<ColumnApi>(null);
    const [badgeList, setBadgeList] = useState(defaultBadgeList);
    const tableRepository = useMemo(() => new LocalTableRepository(`${storePrefix}-leads-table`, 'v7'), [storePrefix]);
    const customFiltersRepository = useMemo(() => new LocalStoreRepository(`${storePrefix}-leads-table-custom-filters`, 'v5'), [
        storePrefix,
    ]);

    const loadedFilters = useRef(customFiltersRepository.load());

    const { search, handleSearchInputChange, setSearch } = useSearchInput(loadedFilters.current?.search, (value: string) => {
        setFilters((prev: any) => ({ ...prev, search: value }));
    });

    const [filters, setFilters] = useState<ICustomLeadFilter>(loadedFilters.current ?? { search: null, status: [LeadStatus.New] });
    const paramsWatcher = useRef<IParamsWatcher>();
    const lastQuery = useRef(null);
    const [defaultKeys, setDefaultKeys] = useState([LeadStatus.New.toString()]);

    const handleGridReady = (params: GridReadyEvent) => {
        setGridApi(params.api);
        setColumnApi(params.columnApi);
    };

    const handleDataChange = (data: any, params?: IParamsWatcher) => {
        const queryParams = formatQueryParams(params);

        if (lastQuery.current === queryParams) {
            return;
        }

        updateScoreboard(queryParams);
    };

    const updateScoreboard = (queryParams: string) => {
        lastQuery.current = queryParams;
        LeadService.getCountsGroupedByStatus(queryParams).then((data: ILeadStatusCount) => {
            const inProgressBadge = {
                ...defaultBadgeList.find((i) => i.value === LeadStatus.InProgress.toString()),
                body: data.inProgress,
            };
            const newBadge = { ...defaultBadgeList.find((i) => i.value === LeadStatus.New.toString()), body: data.new };
            const verifiedBadge = { ...defaultBadgeList.find((i) => i.value === LeadStatus.Verified.toString()), body: data.verified };
            const refusedBadge = { ...defaultBadgeList.find((i) => i.value === LeadStatus.Refused.toString()), body: data.refused };

            setBadgeList([newBadge, inProgressBadge, verifiedBadge, refusedBadge]);
        });
    };

    const formatQueryParams = (params?: IParamsWatcher) => {
        if (params) {
            const { getFilterModelParams, getSortParams, getCustomFilterParams, request } = paramsWatcher.current;

            return FilterHelper.toURLQueryParams({
                ...getSortParams(request),
                ...getFilterModelParams(request),
                ...getCustomFilterParams,
                status: null,
            }).join('&');
        } else {
            return '';
        }
    };

    const handleBadgeChange = useCallback(
        (currentValues: IBadge[]) => {
            if (!gridApi || !gridColumnApi) {
                return;
            }

            setFilters((prev) => ({ ...prev, status: currentValues.map((i) => (i.value as any) as LeadStatus) }));
        },
        [gridApi, gridColumnApi]
    );

    useEffect(() => {
        customFiltersRepository.save(filters);
        loadedFilters.current = filters;
    }, [filters, customFiltersRepository]);

    useEffect(() => {
        if (updateTable && gridApi) {
            gridApi.onFilterChanged();
            updateScoreboard(formatQueryParams(paramsWatcher.current));
        }
    }, [updateTable, gridApi]);

    useEffect(() => {
        if (filters.status) {
            setDefaultKeys(filters.status.map((i) => i.toString()));
        } else {
            setDefaultKeys([]);
        }
    }, [filters]);

    const gridOptions = useMemo(
        () => ({
            onCellClicked: (event: CellClickedEvent) => {
                const columnId = event.column.getColId();

                switch (columnId) {
                    case 'resumeId':
                        dispatch(getFileActionCreator(event.data.resumeId)).then((response: any) => {
                            FileHelper.downloadOrOpen(response.file);
                        });
                        break;
                    case 'actions':
                        break;
                    default:
                        onOpenClick(event.data, DetailsNavigationHelper.getStateForDetails(paramsWatcher.current, event.node.rowIndex));
                        break;
                }
            },
            columnDefs: requestColumnDefs,
            defaultColDef: {
                resizable: true,
                filterParams: {
                    onFetchFilters: handleFetchFilters,
                },
            },
            frameworkComponents: {
                resumeRender: () => {
                    return (
                        <div className="icon-wrapper">
                            <span className="icon icon-document"></span>
                        </div>
                    );
                },
                phoneFormat: (props: any) => {
                    const cellValue = props.valueFormatted ? props.valueFormatted : props.value;

                    return <DisplayValue id="phone" placeholder="" value={cellValue} mask={maskConstants.PHONE_NUMBER} />;
                },
                statusRender: (props: any) => {
                    const cellValue = (props.valueFormatted ? props.valueFormatted : props.value) || '';

                    return (
                        <span className={`d-flex fixed-width lead-status-column-value ${LeadStatus[props.value].toLowerCase()}`}>
                            {LeadStatusLabel.get(cellValue)}
                        </span>
                    );
                },
                actionsRender: (props: any) => {
                    const lead: ILead = props.data as ILead;

                    const currentRef = React.createRef<HTMLDivElement>();

                    return (
                        <div className="actions-button-wrapper" ref={currentRef} id={`lead-${lead.id}`}>
                            {(lead.status === LeadStatus.New || lead.status === LeadStatus.InProgress) && (
                                <>
                                    <InfiniteTooltip
                                        content={
                                            <div className="tooltip-lead-actions">
                                                <span>Verify!</span>
                                            </div>
                                        }
                                        direction="down"
                                    >
                                        <button onClick={() => onVerifyClick(lead, currentRef.current)} className="verify-btn"></button>
                                    </InfiniteTooltip>
                                    <InfiniteTooltip
                                        content={
                                            <div className="tooltip-lead-actions">
                                                <span>Refuse</span>
                                            </div>
                                        }
                                        direction="down"
                                    >
                                        <button className="refuse-btn" onClick={() => onRefuseClick(lead, currentRef.current)}></button>
                                    </InfiniteTooltip>
                                </>
                            )}
                        </div>
                    );
                },
            },
        }),
        []
    );

    const handleFiltersClear = () => {
        setSearch('');
        setFilters({ status: [LeadStatus.New], search: null });
        setDefaultKeys([LeadStatus.New.toString()]);
    };

    return (
        <>
            <div className="main-info-wrapper ag-table-wrapper">
                <HeaderWrapper>
                    <HeaderLeftSide>
                        <SearchFilterInput title="Leads" value={search} onInput={handleSearchInputChange} />
                        <ColumnsVisibleFilterSelect title="Leads" gridApi={gridApi} gridColumnApi={gridColumnApi} />
                    </HeaderLeftSide>
                    <HeaderRightSide>
                        <ExportButton title="Leads" paramsWatcher={paramsWatcher} onExportData={handleExportData} />
                        <ClearFiltersButton title="Leads" gridApi={gridApi} gridColumnApi={gridColumnApi} onClick={handleFiltersClear} />
                    </HeaderRightSide>
                </HeaderWrapper>

                <MultipleBadges
                    className="pl-25"
                    items={badgeList}
                    defaultKey={defaultKeys}
                    onChange={handleBadgeChange}
                    badgeComponent={BadgeTopFilter}
                    repository={new LocalStoreRepository(`${storePrefix}-leads-table-badge`, 'v2')}
                />
                <AgTable
                    onGridReady={handleGridReady}
                    gridOptions={gridOptions}
                    onFiltersLoadedFromUrl={handleFiltersClear}
                    repository={tableRepository}
                    customFilters={filters}
                    onDataChange={handleDataChange}
                    paramsWatcher={paramsWatcher}
                    onFetchData={handleFetchData}
                />
            </div>
        </>
    );
};

export const requestColumnDefs = [
    {
        field: 'createdAt',
        headerName: 'Applied On',
        valueFormatter: (props: any) => convertDateToFormat(props.value, 'MM/DD/YYYY'),
        filter: 'agDateColumnFilter',
        filterParams: {
            isDateTime: true,
        },
        width: 149,
    },
    {
        field: 'firstName',
        headerName: 'First Name',
        minWidth: 250,
        flex: 1,
    },
    {
        field: 'lastName',
        headerName: 'Last Name',
        minWidth: 250,
        flex: 1,
    },
    {
        field: 'phoneNumber',
        headerName: 'Phone',
        width: 210,
        cellRenderer: 'phoneFormat',
    },
    {
        field: 'email',
        headerName: 'Email',
        width: 300,
    },
    {
        field: 'assignedTo',
        headerName: 'Assigned To',
        filter: 'agCustomFilter',
        width: 300,
        filterParams: {
            debounceMs: 0,
            isNullable: true,
        },
    },
    {
        field: 'disciplines',
        filter: 'agCustomFilter',
        headerName: 'Disciplines',
        width: 140,
    },
    {
        field: 'refusedOrVerifiedAt',
        headerName: 'Updated',
        sortable: false,
        width: 220,
        valueFormatter: (props: any) => DateTimeHelper.format(props.value, 'MM/DD/YYYY hh:mm A'),
        filter: 'agDateColumnFilter',
        filterParams: {
            isDateTime: true,
        },
    },
    {
        field: 'source',
        sortable: false,
        headerName: 'Source',
        width: 200,
    },
    {
        field: 'comment',
        headerName: 'Comment',
        sortable: false,
        width: 210,
    },
    {
        field: 'resumeId',
        headerName: '',
        cellRenderer: 'resumeRender',
        width: 50,
        sortable: false,
        hideForExport: true,
    },
    {
        field: 'actions',
        headerName: '',
        cellRenderer: 'actionsRender',
        width: 170,
        pinned: 'right',
        sortable: false,
        hideForExport: true,
        cellClass: 'actions-leads',
    },
    {
        field: 'status',
        headerName: 'Status',
        pinned: 'right',
        cellRenderer: 'statusRender',
        cellClass: 'd-flex',
        sortable: false,
        width: 130,
    },
];

export default LeadsManagementTable;
