import React from 'react';
import { IFormSidebar } from 'common/models/types';
import useFormSidebar from 'common/hooks/use-form-sidebar';
import RightSideBarForm from 'common/components/right-side-bar/right-side-bar-form';
import PatientContactForm from './patient-contact-form';
import { IPatientContactForm, IPatientContactDetails } from 'common/services/api/patient-contact/types';

const PatientContactSidebarEdit = ({
    data: contactDetails,
    onClose,
    onSave,
    options,
}: IFormSidebar<IPatientContactForm, IPatientContactDetails>) => {
    const [data, error, handleChangeData, handleCancelClick, handleSaveClick] = useFormSidebar<IPatientContactForm>(
        {
            id: contactDetails?.id,
            firstName: contactDetails?.contact?.firstName,
            middleName: contactDetails?.contact?.middleName,
            lastName: contactDetails?.contact?.lastName,
            phones: contactDetails?.contact?.phones || [],
            emails: contactDetails?.contact?.emails || [],
            relationshipId: contactDetails?.relationship?.id,
            isEmergencyContact: contactDetails?.isEmergencyContact,
            isMainContact: contactDetails?.isMainContact,
        },
        { onClose, onSave }
    );

    return (
        <RightSideBarForm title="Edit Contact" onCancel={handleCancelClick} onSave={handleSaveClick}>
            <PatientContactForm data={data} errors={error} onChange={handleChangeData} options={options} />
        </RightSideBarForm>
    );
};

export default PatientContactSidebarEdit;
