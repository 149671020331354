import { InfiniteServerClient } from 'common/helpers/http-clients';
import { IState } from './types';

export class StateService {
    getAll(term?: string, skip?: number, take?: number): Promise<IState[]> {
        return InfiniteServerClient.get<IState[]>('/states', { params: { term, skip, take } }).then(
            (response) => {
                return response.data;
            },
            (error) => {
                throw error;
            }
        );
    }

    getByName(name: string): Promise<IState> {
        return InfiniteServerClient.get<IState>(`/states/${name}`).then(
            (response) => {
                return response.data;
            },
            (error) => {
                throw error;
            }
        );
    }
}

export default new StateService();
