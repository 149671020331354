import { Reducer } from '@reduxjs/toolkit';
import { StaffingAgencyActionTypes, IStaffingAgencyState } from './types';

const initialState: IStaffingAgencyState = {
    data: undefined,
};

export const staffingAgencyReducer: Reducer<IStaffingAgencyState, StaffingAgencyActionTypes> = (state = initialState, action) => {
    switch (action.type) {
        case 'SET_STAFFING_AGENCIES':
            return { ...state, data: action.staffingAgencies };
        default:
            return state;
    }
};
