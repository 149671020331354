import DropdownOption from '../../../models/dropdown-option';
import { IPopulation } from '../population/types';
import { ILanguage } from '../language/types';
import { IState } from '../state/types';
import { IEntityContactDetails } from '../entity-contact/type';
import { IUserBaseInfo } from '../user/types';
import { IRequestTherapistAssignment } from 'common/components/assigned-therapist-list/types';

export enum SchoolRequestStatus {
    Undefined,
    New,
    InProgress,
    PartiallyCovered,
    PendingInterview,
    Completed,
    Declined,
}

export const SchoolRequestStatusLabel = new Map<SchoolRequestStatus, string>([
    [SchoolRequestStatus.New, 'New'],
    [SchoolRequestStatus.InProgress, 'In Progress'],
    [SchoolRequestStatus.PartiallyCovered, 'Partially Covered'],
    [SchoolRequestStatus.PendingInterview, 'Pending Interview'],
    [SchoolRequestStatus.Completed, 'Completed'],
    [SchoolRequestStatus.Declined, 'Declined'],
]);

export interface ISchoolOption {
    year: number;
    startDate: string;
    endDate: string;
}

export interface IPOSContactOption {
    additionalRoleInformation: string;
    email: string;
    fullName: string;
    id: number;
    otherRoles: string[];
    phone: string;
    roles: string[];
}

export interface IContractType {
    id: number;
    name: string;
}

export interface IBidRank {
    id: number;
    name: string;
}

export interface IServiceType {
    id: number;
    name: string;
}

export interface IPriorityLevel {
    id: number;
    name: string;
}

export interface ISchoolAddress {
    address1: string;
    address2?: any;
    postalCode: string;
    city: string;
    country: string;
    state: IState;
    county?: any;
    longitude?: any;
    latitude?: any;
}

export interface IPlaceOfService {
    id: number;
    dbn?: any;
    name: string;
    address: ISchoolAddress;
}

export interface ISchoolRequestTherapistAssignment extends IRequestTherapistAssignment {
    updatedBy: IUserBaseInfo;
}

export interface ISchoolGridModel {
    id: number;
    status: number;
    year: number;
    startDate: Date;
    endDate: Date;
    contractType: IContractType;
    bidRank: IBidRank;
    language: ILanguage;
    population: IPopulation;
    serviceType: IServiceType;
    priorityLevel: IPriorityLevel;
    createdAt: Date;
    updatedAt: Date;
    placeOfService: IPlaceOfService;
    providers: IProvider[];
    therapists: ISchoolRequestTherapistAssignment[];
}

export interface ISchoolGridRow {
    caseId: number;
    dbn: string;
    bidRank: string;
    //createdAt: Date;
    daysOpen: number;
    therapy: string;
    lang: string;
    stdnts: string[];
    days: string[];
    startDate: string;
    endDate: string;
    placeOfService: string;
    status: string;
    schoolYear: string;
    populations: string[];
    priorityLevel: string[];
    schoolName: string;
    assignedTo: string[];
    assignedBy: string[];
    assignedDate: string[];
}

export interface ISchoolGridResponse {
    filters: ISchoolGridSourceFilters;
    rows: ISchoolGridRow[];
}
export interface ISchoolGridSourceFilters {
    schoolYear: string[];
    populations: string[];
    priorityLevel: string[];
}
export interface ISchoolGridFilters {
    schoolYears: DropdownOption[];
    populations: DropdownOption[];
    priorityLevels: DropdownOption[];
}

export interface ISchoolNewRequestDataModel {
    year: DropdownOption;
    serviceTypeId: DropdownOption;
    languageId: DropdownOption;
    bidRankId: DropdownOption;
    providers: IProvider[];
    populationId: DropdownOption;
    priorityLevelId: DropdownOption;
    contractTypeId: DropdownOption;
    doeContactId: DropdownOption;
    providersCount: string;
    startDate: any;
    endDate: any;
    schoolEntityId: DropdownOption;
    posContactId: DropdownOption;
    specialInstructions: string;
}

export interface ISchoolNewRequest {
    year: number;
    serviceTypeId: number;
    languageId: number;
    bidRankId: number;
    providers: IProvider[];
    providersCount: number;
    populationId: number;
    priorityLevelId: number;
    contractTypeId: number;
    doeContactId: number;
    startDate: string;
    endDate: string;
    schoolEntityId: number;
    POSContactId: number;
    specialInstructions: string;
}

export interface IProvider {
    students: string;
    days: number;
}

export interface ISchoolName {
    id: number;
    entityType: string;
    name: string;
    dbn: string;
    address1: string;
}

export interface ISchoolRequestEntityContact extends IEntityContactDetails {
    name: string;
}

export interface ISchoolRequestDetails {
    id: number;
    status: SchoolRequestStatus;
    year: number;
    startDate: Date;
    endDate: Date;
    contractType: IContractType;
    bidRank: IBidRank;
    language: ILanguage;
    population: IPopulation;
    serviceType: IServiceType;
    priorityLevel: IPriorityLevel;
    createdAt: Date;
    updatedAt: Date;
    createdBy: IUserBaseInfo;
    updatedBy: IUserBaseInfo;
    placeOfService: IPlaceOfService;
    providers: IProvider[];
    specialInstructions: string;
    posContact: ISchoolRequestEntityContact;
    doeContact: ISchoolRequestEntityContact;
}
