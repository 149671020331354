import React from 'react';
import { CountIndicatorProps } from './types';

const CountIndicator: React.FC<CountIndicatorProps> = ({ value, className }) => {
    const indicatorClassName = `count-indicator ${className ? className : ''}`;

    return (
        <div className={indicatorClassName}>
            <span>{value}</span>
        </div>
    );
};

export default React.memo(CountIndicator);
