export class ObjectHelper {
    static deepCopy(object: any) {
        if (!object) {
            return;
        }

        return JSON.parse(JSON.stringify(object));
    }

    static parsObjects(data: { [key: string]: any }, needPars: string[] = []) {
        if (!data || typeof data !== 'object') return {};

        const result: { [key: string]: any } = {};
        needPars.forEach((key) => {
            if (data[key] && typeof data[key] === 'string' && data[key] !== '') {
                try {
                    result[key] = JSON.parse(data[key]);
                } catch (e) {
                    console.error(e);
                    result[key] = {};
                }
            } else result[key] = {};
        });

        return result;
    }
}
