import { connect } from 'react-redux';
import { loginRequest, resetLoginErrors } from '../auth.actions';
import LoginForm from './login-form';
import React from 'react';
import { Redirect } from 'react-router-dom';
import { trackPromise } from 'react-promise-tracker';

const SKIP_REDIRECT_URLS = ['/reset-password', '/setup-new-password'];

class LoginPage extends React.Component {
    constructor(props) {
        super(props);

        if (SKIP_REDIRECT_URLS.filter((url) => props.redirectTo.includes(url)).length === 0) {
            props.history.replace({
                state: {
                    from: props.redirectTo,
                },
            });
        }
    }

    render() {
        if (this.props.loggedIn) {
            return <Redirect to="/"></Redirect>;
        } else {
            return (
                <LoginForm
                    onLogin={this.props.login}
                    errorMessage={this.props.errorMessage}
                    onResetFormErrors={this.props.resetFormErrors}
                ></LoginForm>
            );
        }
    }
}

const mapDispatchToProps = (dispatch, props) => {
    return {
        login: (username, password) => {
            trackPromise(dispatch(loginRequest(username, password))).then(() => {
                if (props.location.state?.from) {
                    const redirectTo = props.location.state.from;
                    if (redirectTo !== '/' && redirectTo !== '/login') {
                        props.history.push(redirectTo);
                    }
                }
            });
        },
        resetFormErrors: () => {
            dispatch(resetLoginErrors());
        },
    };
};

const mapStateToProps = (state) => {
    return {
        errorMessage: state.auth.errorMessage,
        loggedIn: state.auth.loggedIn,
        redirectTo: state.auth.redirectTo,
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(LoginPage);
