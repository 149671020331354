import { GridReadyEvent } from 'ag-grid-community';
import AgTable from 'common/components/ag-table/ag-table';
import AddButton from 'common/components/header/add-button/add-button';
import ClearFiltersButton from 'common/components/header/clear-filters-button/clear-filters-button';
import HeaderLeftSide from 'common/components/header/header-left-side';
import HeaderRightSide from 'common/components/header/header-right-side';
import HeaderWrapper from 'common/components/header/header-wrapper';
import ColumnsVisibleFilterSelect from 'common/components/header/сolumns-visible-filter-select/columns-visible-filter-select';
import InfiniteSelect from 'common/components/infinite-select/infinite-select';
import { dateTimeConstants } from 'common/constants/common';
import { DateTimeHelper } from 'common/helpers/date-time-helper';
import { DisplayValueHelper } from 'common/helpers/display-value-helper';
import { LocalStoreRepository } from 'common/helpers/repository/local-store-repository';
import { LocalTableRepository } from 'common/helpers/repository/local-table-repository';
import { useHasPermission } from 'common/hooks/use-has-permission';
import DropdownOption from 'common/models/dropdown-option';
import { PermissionType } from 'common/models/permission-type';
import { getFilterData, getTableData } from 'common/services/api/tabel-fetch-service';
import TherapistRatesService from 'common/services/api/therapist-rates/therapist-rates-service';
import React, { useEffect, useMemo, useRef, useState, useCallback } from 'react';
import { trackPromise } from 'react-promise-tracker';
import { contractStates, therapistRateActions } from './constants';
import './styles.scss';
import { TherapistRateTabProps } from './types';
import { useDispatch } from 'react-redux';
import { Action } from '@reduxjs/toolkit';
import { ThunkDispatch } from 'redux-thunk';
import TherapistRateDialogAdd from './therapist-rate-forms/therapist-rate-dialog-add';
import { ITherapistRateEditForm, ITherapistRateTableView, RateEditOptionType } from './therapist-rate-forms/types';
import TherapistRateDialogEdit from './therapist-rate-forms/therapist-rate-dialog-edit';
import { ITherapistAddRate, ITherapistEditRate } from 'common/services/api/therapist-rates/types';
import TherapistRateDialogUpdate from './therapist-rate-forms/therapist-rate-dialog-update';
import Popper from 'common/components/infinite-popper/infinite-popper';
import ConfirmPopup from 'common/components/inifinite-popups/confirm-popup';
import { getTherapistDetailsRequest } from 'app/store/therapist/action-creators';

const convertDateToFormat = (data: string, format: string): string => DateTimeHelper.format(data, format, true);
const tableRepository = new LocalTableRepository('therapist-rates-table', 'v2');
const customFiltersRepository = new LocalStoreRepository('therapist-rates-table-custom-filters');

const defaultFilters: any = {};

const TherapistRateTab: React.FC<TherapistRateTabProps> = ({ id: therapistId, corporateName, taxId }) => {
    const [updateTable, setUpdateTable] = useState(false);
    const loadedFilters = useRef(customFiltersRepository.load());

    const dispatch = useDispatch<ThunkDispatch<any, any, Action>>();

    const [gridApi, setGridApi] = useState(null);
    const [gridColumnApi, setColumnApi] = useState(null);
    const [filters, setFilters] = useState<any>(loadedFilters.current ?? defaultFilters);

    const fetchOption = useMemo(
        () => ({
            handleFetchData: getTableData(`therapists/${therapistId}/rates`),
            handleFetchFilters: getFilterData(`therapists/${therapistId}/rates/filter/source`),
        }),
        [therapistId]
    );

    useEffect(() => {
        customFiltersRepository.save(filters);
        loadedFilters.current = filters;
    }, [filters]);

    useEffect(() => {
        if (updateTable && gridApi) {
            gridApi.onFilterChanged();
        }
    }, [updateTable, gridApi]);

    const hasAddEditRatePrivileges = useHasPermission(PermissionType.CanAddEditTherapistRates);
    const hasDeleteRatePrivilege = useHasPermission(PermissionType.CanDeleteTherapistRates);

    const handleGridReady = (params: GridReadyEvent) => {
        setGridApi(params.api);
        setColumnApi(params.columnApi);
    };

    const handleFiltersClear = () => {
        setFilters({ ...defaultFilters });
    };

    const getOptionList = useRef<(rate: any) => DropdownOption[]>();

    getOptionList.current = (rate: any): DropdownOption[] => {
        const itemOptions = [];

        if (hasAddEditRatePrivileges && rate.id) {
            itemOptions.push(new DropdownOption(therapistRateActions.Edit, therapistRateActions.Edit));
            // itemOptions.push(new DropdownOption(therapistRateActions.Change, therapistRateActions.Change));
        }

        if (hasDeleteRatePrivilege && rate.id) {
            itemOptions.push(new DropdownOption(therapistRateActions.Delete, therapistRateActions.Delete));
        }

        if (rate.id && rate.contractState === contractStates.NotVerified) {
            itemOptions.push(new DropdownOption(therapistRateActions.ValidateContract, therapistRateActions.ValidateContract));
        }

        if (
            rate.id &&
            rate.hasCorrespondingAssignment &&
            (rate.contractState !== contractStates.Signed || rate.contractState !== contractStates.NotVerified)
        ) {
            // itemOptions.push(new DropdownOption(therapistRateActions.NotifyTherapist, therapistRateActions.NotifyTherapist));
        }

        return itemOptions;
    };

    const onValidateContractClick = useCallback(
        (rateId: number) => {
            return trackPromise(TherapistRatesService.validateContract(therapistId, rateId)).then(() => {
                setUpdateTable(true);
            });
        },
        [therapistId]
    );

    const onNotifyTherapistClick = useCallback(
        (rateId: number) => {
            return trackPromise(TherapistRatesService.NotifyTherapist(therapistId, rateId)).catch();
        },
        [therapistId]
    );

    // Add Rate sidebar
    const [isAddRateOpen, setAddRateOpen] = useState<boolean>();

    const handleAddRateClick = () => {
        setAddRateOpen(true);
    };

    const handleSaveRateAddDialog = (data: ITherapistAddRate) => {
        return trackPromise(TherapistRatesService.addRate(therapistId, data))
            .then(() => {
                if (!corporateName && !taxId) {
                    trackPromise(dispatch(getTherapistDetailsRequest(therapistId)));
                }

                gridApi.onFilterChanged();
                setAddRateOpen(false);
            })
            .catch();
    };

    const handleCancelRateAddDialog = () => {
        setAddRateOpen(false);
    };

    // Edit Rate sidebar
    const [editRateOptions, setEditRateOptions] = useState<RateEditOptionType>({ isOpen: false, rateTemplate: null, rateData: null });

    const handleEditRateClick = useCallback((rate: ITherapistRateTableView) => {
        setEditRateOptions({
            isOpen: true,
            rateData: {
                rateId: rate.id,
                rate: rate.rate,
                startDate: rate.startDate,
                endDate: rate.endDate,
            },
            rateTemplate: {
                department: rate.department,
                subDepartments: rate.subDepartments,
            },
        });
    }, []);

    const handleSaveRateEditDialog = (data: ITherapistRateEditForm) => {
        const { rateId, ...restData } = data;

        return trackPromise(TherapistRatesService.editRate(therapistId, rateId, restData)).then(() => {
            gridApi.onFilterChanged();
            setEditRateOptions({ isOpen: false, rateTemplate: null, rateData: null });
        });
    };

    const handleCancelRateEditDialog = () => {
        setEditRateOptions({ isOpen: false, rateTemplate: null, rateData: null });
    };

    // Update Rate sidebar
    const [changeRateOptions, setChangeRateOptions] = useState<any>({ isOpen: false, rateTemplate: null, rateData: null });

    const handleChangeRateClick = useCallback(
        (rate: ITherapistRateTableView) => {
            setChangeRateOptions({
                isOpen: true,
                rateData: {
                    therapistId,
                    rateId: rate.id,
                    rate: rate.rate,
                    startDate: rate.startDate,
                    endDate: rate.endDate,
                    payType: rate.payType,
                    corporateName: corporateName,
                    taxId: taxId,
                    createdBy: rate.createdBy,
                    dateCreation: rate.dateCreation,
                },
                rateTemplate: {
                    department: rate.department,
                    subDepartments: rate.subDepartments,
                },
            });
        },
        [therapistId, corporateName, taxId]
    );

    const handleSaveRateChangeDialog = (editRate: ITherapistEditRate, newRate: ITherapistAddRate) => {
        return trackPromise(TherapistRatesService.updateRate(therapistId, editRate?.id, editRate, newRate))
            .then(() => {
                gridApi.onFilterChanged();
                setChangeRateOptions({ isOpen: false, rateTemplate: null, rateData: null });
            })
            .catch();
    };

    const handleCancelRateChangeDialog = () => {
        setChangeRateOptions({ isOpen: false, rateTemplate: null, rateData: null });
    };

    const onMenuOptionClick = useCallback(
        (action: any, rate: any, target: HTMLElement) => {
            switch (action) {
                case therapistRateActions.Edit:
                    return handleEditRateClick(rate);
                case therapistRateActions.Change:
                    return handleChangeRateClick(rate);
                case therapistRateActions.Delete:
                    return setTargetConfirm({
                        rateId: rate.id,
                        target,
                    });
                case therapistRateActions.ValidateContract:
                    return onValidateContractClick(rate.id);
                case therapistRateActions.NotifyTherapist:
                    return onNotifyTherapistClick(rate.id);
                default:
                    return;
            }
        },
        [handleEditRateClick, handleChangeRateClick, onValidateContractClick, onNotifyTherapistClick]
    );

    const gridOptions = useMemo(
        () => ({
            columnDefs: [
                {
                    field: 'department',
                    headerName: 'Department',
                    width: 180,
                    filter: 'agCustomFilter',
                    initialSort: 'asc',
                },
                {
                    field: 'subDepartments',
                    headerName: 'Sub Department',
                    width: 180,
                    filter: 'agCustomFilter',
                    filterParams: {
                        isNullable: true,
                    },
                },
                {
                    field: 'rate',
                    headerName: 'Rate',
                    width: 170,
                    valueFormatter: (props: any) => DisplayValueHelper.rateEmptyOrIsSet(props.value),
                    filter: 'agCustomFilter',
                },
                {
                    field: 'payType',
                    headerName: 'Pay Type',
                    width: 115,
                    filter: 'agCustomFilter',
                },
                {
                    field: 'corporateName',
                    headerName: 'Corporate Name',
                    width: 250,
                    filter: 'agCustomFilter',
                    filterParams: {
                        isNullable: true,
                    },
                    sortable: false,
                },
                {
                    field: 'taxId',
                    headerName: 'Tax Id',
                    width: 123,
                    filter: 'agCustomFilter',
                    filterParams: {
                        isNullable: true,
                    },
                    sortable: false,
                },
                {
                    field: 'startDate',
                    headerName: 'Start Date',
                    width: 155,
                    valueFormatter: (props: any) => convertDateToFormat(props.value, dateTimeConstants.MM_DD_YYYY),
                    filter: 'agDateColumnFilter',
                },
                {
                    field: 'endDate',
                    headerName: 'End Date',
                    width: 155,
                    valueFormatter: (props: any) => convertDateToFormat(props.value, dateTimeConstants.MM_DD_YYYY),
                    filter: 'agDateColumnFilter',
                },
                {
                    field: 'createdBy',
                    headerName: 'Created By',
                    width: 300,
                    filter: 'agCustomFilter',
                },
                {
                    field: 'dateCreation',
                    headerName: 'Date Creation',
                    width: 155,
                    valueFormatter: (props: any) => DateTimeHelper.format(props.value, dateTimeConstants.MM_DD_YYYY),
                    filterParams: {
                        isDateTime: true,
                    },
                    filter: 'agDateColumnFilter',
                },
                {
                    field: 'contractState',
                    headerName: 'Contract State',
                    width: 180,
                    filter: 'agCustomFilter',
                },
                {
                    field: '',
                    headerName: '',
                    colId: 'doteMenu',
                    pinned: 'right',
                    cellRenderer: 'doteRender',
                    width: 40,
                    cellClass: ['dote-cell'],
                    hideForExport: true,
                },
            ],
            suppressRowClickSelection: true,
            treeData: true,
            isServerSideGroup: (dataItem: any) => {
                return dataItem?.notMainRates?.length > 0;
            },
            getServerSideGroupKey: function (dataItem: any) {
                return dataItem?.notMainRates;
            },
            defaultColDef: {
                resizable: true,
                filterParams: {
                    onFetchFilters: fetchOption.handleFetchFilters,
                },
            },
            autoGroupColumnDef: {
                headerName: '',
                field: 'department',
                sortable: false,
                lockPosition: true,
                maxWidth: 50,
                minWidth: 50,
                cellClass: 'arrow-cell',

                cellRendererParams: {
                    innerRenderer: function (params: any) {
                        return params.data.department;
                    },
                },
            },
            frameworkComponents: {
                doteRender: (props: any) => {
                    const itemOptions = getOptionList.current(props.data);

                    return (
                        itemOptions.length > 0 && (
                            <InfiniteSelect
                                id="rateMenu"
                                popper
                                items={itemOptions}
                                className="no-select-border"
                                icon={<i className="icon icon-options" />}
                                onChange={(item: DropdownOption, target) => {
                                    onMenuOptionClick(item?.value, props.data, target);
                                }}
                            />
                        )
                    );
                },
            },
        }),
        [fetchOption.handleFetchFilters, onMenuOptionClick]
    );

    // Popup confirm
    const [targetConfirm, setTargetConfirm] = useState(null);
    const handleDeleteRateConfirm = () => {
        return trackPromise(TherapistRatesService.deleteTherapistRate(therapistId, targetConfirm.rateId)).then(() => {
            setTargetConfirm(null);
            gridApi.onFilterChanged();
        });
    };

    return (
        <>
            <div className="main-info-wrapper ag-table-wrapper rates-table-wrapper">
                <HeaderWrapper>
                    <HeaderLeftSide>
                        <ColumnsVisibleFilterSelect title="Provider Rates" gridApi={gridApi} gridColumnApi={gridColumnApi} />
                    </HeaderLeftSide>
                    <HeaderRightSide>
                        <ClearFiltersButton
                            title="Provider Rates"
                            gridApi={gridApi}
                            gridColumnApi={gridColumnApi}
                            onClick={handleFiltersClear}
                        />
                        {hasAddEditRatePrivileges && <AddButton title="Add Rate" onClick={handleAddRateClick} />}
                    </HeaderRightSide>
                </HeaderWrapper>
                <AgTable
                    onGridReady={handleGridReady}
                    gridOptions={gridOptions}
                    onFetchData={fetchOption.handleFetchData}
                    onFiltersLoadedFromUrl={handleFiltersClear}
                    customFilters={filters}
                    repository={tableRepository}
                />
                {isAddRateOpen && (
                    <TherapistRateDialogAdd
                        onClose={handleCancelRateAddDialog}
                        onSave={handleSaveRateAddDialog}
                        options={{ therapistId, corporateName, taxId: taxId }}
                    />
                )}
                {editRateOptions?.isOpen && (
                    <TherapistRateDialogEdit
                        onClose={handleCancelRateEditDialog}
                        onSave={handleSaveRateEditDialog}
                        data={editRateOptions.rateData}
                        options={editRateOptions.rateTemplate}
                    />
                )}
                {changeRateOptions?.isOpen && (
                    <TherapistRateDialogUpdate
                        onClose={handleCancelRateChangeDialog}
                        onSave={handleSaveRateChangeDialog}
                        data={changeRateOptions.rateData}
                        template={changeRateOptions.rateTemplate}
                    />
                )}
            </div>
            {targetConfirm?.target && (
                <Popper target={targetConfirm?.target} width={271} onClickOutside={() => setTargetConfirm(null)}>
                    <ConfirmPopup
                        onCancel={() => setTargetConfirm(null)}
                        onConfirm={handleDeleteRateConfirm}
                        textCancel="No"
                        textConfirm="Yes"
                    >
                        Are you sure you want to delete?
                    </ConfirmPopup>
                </Popper>
            )}
        </>
    );
};

export default TherapistRateTab;
