import { CellClickedEvent, GridApi, GridOptions, GridReadyEvent, IServerSideGetRowsParams } from 'ag-grid-community';
import AgTable from 'common/components/ag-table/ag-table';
import HeaderLeftSide from 'common/components/header/header-left-side';
import HeaderWrapper from 'common/components/header/header-wrapper';
import PageHeader from 'common/components/page-header/page-header';
import { useSearchInput } from 'common/hooks/use-serch-input';
import React, { useEffect, useMemo, useState } from 'react';
import { IRolesListFilter, RolesListProps } from './types';
import SearchFilterInput from 'common/components/header/search-filter-input/search-filter-input';
import HeaderRightSide from 'common/components/header/header-right-side';
import AddButton from 'common/components/header/add-button/add-button';
import { IApplicationRole } from 'common/services/api/role/types';
import roleService from 'common/services/api/role/role-service';

export const getRoles = () => {
    return (event: IServerSideGetRowsParams, { pageIndex, limit, getCustomFilterParams }: any) => {
        const term: string = (getCustomFilterParams as IRolesListFilter).term;

        return roleService.getAll(term, (pageIndex - 1) * limit, limit).then((roles) => ({
            data: roles,
        }));
    };
};

const handleFetchData = getRoles();

const RolesList: React.FC<RolesListProps> = ({ forceUpdate, onNewRoleClick, onEditRoleClick }) => {
    const [gridApi, setGridApi] = useState<GridApi>(null);
    const [filter, setFilters] = useState<IRolesListFilter>({ term: '' });

    const { search, handleSearchInputChange } = useSearchInput(filter?.term || '', (value: string) => {
        setFilters((prev) => ({ ...prev, term: value }));
    });

    useEffect(() => {
        if (gridApi || forceUpdate) {
            gridApi.onFilterChanged();
        }
    }, [gridApi, forceUpdate]);

    const gridOptions: GridOptions = useMemo(
        () => ({
            onCellClicked: (event: CellClickedEvent) => {
                const role = event.data as IApplicationRole;
                onEditRoleClick(role);
            },
            columnDefs: [
                {
                    field: 'name',
                    headerName: 'Role',
                    sortable: false,
                    width: 350,
                },
                {
                    field: 'permissions',
                    headerName: 'Privileges',
                    valueFormatter: (props: any) => (props.value as string[]).join(', ') || '',
                    sortable: false,
                    flex: 1,
                    width: 300,
                },
            ],
        }),
        [onEditRoleClick]
    );

    const handleGridReady = (params: GridReadyEvent) => {
        setGridApi(params.api);
    };

    return (
        <>
            <PageHeader title="Roles" />
            <div className="main-info-wrapper ag-table-wrapper">
                <HeaderWrapper>
                    <HeaderLeftSide>
                        <SearchFilterInput title="Roles" value={search} onInput={handleSearchInputChange} />
                    </HeaderLeftSide>
                    <HeaderRightSide>
                        <AddButton title="Add Role" onClick={onNewRoleClick} />
                    </HeaderRightSide>
                </HeaderWrapper>
                <AgTable onGridReady={handleGridReady} customFilters={filter} gridOptions={gridOptions} onFetchData={handleFetchData} />
            </div>
        </>
    );
};

export default RolesList;
