import React from 'react';
import './therapist-assign-dialog.scss';

type TherapistAssignDialogHeaderProp = {
    headerInfo?: () => JSX.Element | JSX.Element[];
    handleOnCancelClick: () => void;
    handleOnSaveClick: () => void;
};

const TherapistAssignDialogHeader: React.FC<TherapistAssignDialogHeaderProp> = ({ handleOnCancelClick, handleOnSaveClick, headerInfo }) => {
    return (
        <>
            <header>
                <div className="rh-info">
                    <div className="rh-info__header">
                        <div className="rh-info__title">Assign a Provider</div>
                        <div className="therapist-assign-dialog__right">
                            <button className="base-text-btn white " id="cancelTherapistAssignBtn" onClick={handleOnCancelClick}>
                                Cancel
                            </button>
                            <button className="base-text-btn blue " id="saveTherapistAssignBtn" onClick={handleOnSaveClick}>
                                Save
                            </button>
                        </div>
                    </div>

                    {headerInfo && (
                        <div className="rh-info__body">
                            <span className="rh-info__body-info">{headerInfo()}</span>
                        </div>
                    )}
                </div>
            </header>
        </>
    );
};

export default TherapistAssignDialogHeader;
