import { Reducer } from '@reduxjs/toolkit';
import { CurrentNoteStatus } from 'common/services/api/note/types';
import { INoteState, NoteActionTypes } from './types';

const initialState: INoteState = {
    status: CurrentNoteStatus.Empty,
};

export const noteReducer: Reducer<INoteState, NoteActionTypes> = (state = initialState, action) => {
    switch (action.type) {
        case 'SET_NOTE_STATUS':
            return { ...state, status: action.status };
        default:
            return state;
    }
};
