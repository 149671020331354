import { IParamsWatcher } from 'common/components/ag-table/types';

export class DetailsNavigationHelper {
    static getStateForDetails(paramsWatcher: IParamsWatcher, rowIndex: number): any {
        const { getSortParams, getFilterModelParams, getCustomFilterParams, limit, request } = paramsWatcher;

        return {
            entityIndex: ++rowIndex,
            queryParams: {
                ...getSortParams(request),
                ...getFilterModelParams(request),
                ...getCustomFilterParams,
                limit,
            },
        };
    }
}
