import React from 'react';
import './icon-button.scss';
import { IconButtonProps } from './types';

export const IconButton = ({ icon, className, onClick }: IconButtonProps) => {
    return (
        <button className={`icon-button ${className ?? ''}`} onClick={onClick}>
            <span className={`icon ${icon}`}></span>
        </button>
    );
};
