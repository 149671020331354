import { dateTimeConstants } from 'common/constants/common';
import { FileHelper } from 'common/helpers/file-helper';
import { SessionStoreRepository } from 'common/helpers/repository/session-store-repository';
import { ITherapistWidgetFilter } from 'common/services/api/therapist-dahsboard/types';
import { useCallback, useEffect, useState } from 'react';
import { trackPromise } from 'react-promise-tracker';
import { IWdigetConfig, WidgetData } from './types';
import moment from 'moment';
import { OverdueFilterOption } from '../components/overdue-filter/types';
import { IServerSideGetRowsParams } from 'ag-grid-community';

const useWidget = <T extends ITherapistWidgetFilter>({
    title,
    defaultFilter,
    fetchFunction,
    exportFunction,
    isSupervisorView,
}: IWdigetConfig<T>): WidgetData<T> => {
    const repository = new SessionStoreRepository<any>(title + 'v3');
    const [filter, setFilters] = useState<T>(repository.load()?.filter ?? defaultFilter);

    const [totalCount, setTotalCount] = useState(0);

    const fetchData = useCallback(
        (
            event: IServerSideGetRowsParams,
            { pageIndex, limit, getFilterModelParams, getSortParams, getCustomFilterParams, request }: any
        ) => {
            const params = {
                ...getSortParams(request),
                ...getCustomFilterParams,
                ...getFilterModelParams(request),
                pageIndex: pageIndex,
                limit: limit,
            };

            params.assignedTo = [...getCustomFilterParams?.assignedTo, ...params?.assignedTo];

            return fetchFunction(params).then((response) => {
                setTotalCount(response.count);

                return response;
            });
        },
        [fetchFunction]
    );

    const handleFilterChange = useCallback(
        (value: T) => {
            setFilters((prev: any) => ({ ...prev, ...value }));
        },
        [setFilters]
    );

    const handleOverdueChange = useCallback(
        (value: OverdueFilterOption) => {
            setFilters((prev: any) => ({ ...prev, isOverdue: value === OverdueFilterOption.OVERDUE }));
        },
        [setFilters]
    );

    useEffect(() => {
        setFilters((prev) => ({ ...prev, isSupervisorView: isSupervisorView, assignedTo: [] }));
    }, [isSupervisorView]);

    const handleExport = useCallback(
        (type: string) => {
            trackPromise(
                exportFunction(filter, type).then((fileBlob: Blob) => {
                    FileHelper.downloadBlob(
                        fileBlob,
                        `${title}_${moment().format(dateTimeConstants.MMDDYYYY)}.${type.toLocaleLowerCase()}`
                    );
                })
            );
        },
        [exportFunction, filter, title]
    );

    useEffect(() => {
        repository.add({ filter: filter });
    }, [filter, repository]);

    return [filter, totalCount, repository, fetchData, handleFilterChange, handleOverdueChange, handleExport];
};

export default useWidget;
