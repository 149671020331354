import RequestTable, { requestColumnDefs } from 'features/request-page/components/requests-table';
import React, { useState } from 'react';
import { PatientRequestsTabProp } from '../types';
import { PATIENT_AGENCY_CODE, PATIENT_NAME } from 'common/constants/common';
import { ITablePageSetting } from 'common/components/ag-table/types';

const PatientRequestsTab: React.FC<PatientRequestsTabProp> = ({ patientId }) => {
    const [requestPageSettings] = useState<ITablePageSetting>({
        isCreateDialogOpen: false,
        updateTable: false,
    });

    return (
        <div className="content-flex">
            <RequestTable
                updateTable={requestPageSettings.updateTable}
                storePrefix="patient"
                extendGridOptions={{
                    columnDefs: patientColumnDef,
                }}
                extendDefaultCustomFilters={{ patientId: patientId }}
            />
        </div>
    );
};

const patientColumnDef = [...requestColumnDefs];
const indexPatientName = requestColumnDefs.findIndex((columnConfig) => columnConfig.field === PATIENT_NAME);
const indexPatientAgencyCode = requestColumnDefs.findIndex((columnConfig) => columnConfig.field === PATIENT_AGENCY_CODE);

if (indexPatientName > -1) {
    patientColumnDef[indexPatientName] = {
        field: 'patientName',
        headerName: 'Patient Name',
        filter: null,
        flex: 1,
        width: 230,
        filterParams: {
            isNullable: true,
        },
        hide: true,
        resizable: true,
    };
}

if (indexPatientAgencyCode > -1) {
    patientColumnDef[indexPatientAgencyCode] = {
        field: 'patientAgencyCode',
        headerName: 'Patient ID',
        filter: null,
        flex: 1,
        width: 230,
        filterParams: {
            isNullable: true,
        },
        hide: true,
        resizable: true,
    };
}

export default PatientRequestsTab;
