import { IWeekDay } from 'common/components/week-days/type';
import { IFormItem } from 'common/models/types';
import { ILegalEntityRate } from 'common/services/api/rate/types';

export type NursingAddRequestFormProps = {
    onClose?: () => void;
    onSave?: (rate: ILegalEntityRate) => Promise<void>;
    data: any;
    isEdit?: boolean;
    options?: any;
    errors?: any;
    isFetch?: boolean;
    isInstructionField?: boolean;
    setFetch?: (value: boolean) => void;
    onChange: (data: IFormItem) => void;
};

export type RateBaseFormProps = {
    rate?: ILegalEntityRate;
    errors?: any;
    onChange: (data: IFormItem) => void;
};

export interface INursingAddRequestForm {
    individualDays: IIndividualDay[];
}

export interface IIndividualDay {
    date: any;
    startTime: string;
    endTime: string;
}

export interface INursingHomeBasicForm {
    id?: number;
    therapyTypeId: number;
    entityId: number;
    rateId?: number;
    facilityId: number;
    placementTypeId: number;
    customRate: number;
    flexDate: boolean;
    flexTime: boolean;
    coverageType: string;
    sourceId: number;
    specialInstructions: string;
    cameInDate: string;
    startDate: any;
    endDate: any;
    individualDays: IIndividualDay[];
    weekDays: IWeekDay[];
    isCustomRate: boolean;
    labels: any;
}

export enum coverageTypes {
    individualDays = 'IndividualDays',
    dateRange = 'DateRange',
}

export enum placementTypeEnum {
    'Coverage' = 1,
    'OnGoing' = 2,
    'onCall' = 3,
}
