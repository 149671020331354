import React from 'react';
import { IRequestAuthorization } from './types';
import { IFormSidebar } from 'common/models/types';
import useFormSidebar from 'common/hooks/use-form-sidebar';
import RightSideBarForm from 'common/components/right-side-bar/right-side-bar-form';
import { IRequestDetails } from 'common/services/api/requests/types';
import RequestAuthorizationForm from './request-authorization-form';
import { departmentKey } from 'common/constants/department-key';

const RequestAuthorizationSidebar = ({ data: requestDetails, onClose, onSave }: IFormSidebar<IRequestAuthorization, IRequestDetails>) => {
    const [data, error, handleChangeData, handleCancelClick, handleSaveClick] = useFormSidebar<IRequestAuthorization>(
        {
            startDate: requestDetails?.authorization?.startDate ?? undefined,
            endDate: requestDetails?.authorization?.endDate ?? undefined,
            isAuthorized: requestDetails?.authorization?.isAuthorized ?? false,
            files: requestDetails?.authorization?.files?.map((item) => ({ ...item, isDisabled: item?.id !== null })) ?? [],
            evalId: requestDetails?.authorization?.evalId,
        },
        { onClose, onSave }
    );

    return (
        <RightSideBarForm title="Edit Authorization Details" onCancel={handleCancelClick} onSave={handleSaveClick}>
            <RequestAuthorizationForm
                isMedBDepartment={departmentKey.HCB === requestDetails?.department?.key}
                data={data}
                errors={error}
                onChange={handleChangeData}
            />
        </RightSideBarForm>
    );
};

export default RequestAuthorizationSidebar;
