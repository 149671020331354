import { DisplayValueHelper } from 'common/helpers/display-value-helper';
import React, { useState, useEffect } from 'react';
import Field from './field';
import { ICheckBoxField } from './types';

const CheckBoxField = (props: ICheckBoxField) => {
    const { leftSide, classic, label, disabled } = props;
    const [value, setValue] = useState<boolean>(props.value);
    useEffect(() => {
        setValue(props.value);
    }, [props.value]);

    const handleClick = () => {
        if (disabled) {
            return;
        }

        const currentValue = value;
        setValue((prev) => !prev);
        props.onChange({ value: !currentValue, name: props.name });
    };

    return (
        <Field
            {...props}
            customClass={`checkbox-wrapper ${classic ? 'classic' : 'form-checkbox'}`}
            value={label ? value : DisplayValueHelper.convertToYesOrNo(value)}
            isFocused={false}
        >
            <div className={`check-box-field ${leftSide ? 'left-label' : ''}`} onClick={handleClick}>
                <input
                    id={props.id}
                    type="checkbox"
                    className="nps-checkbox"
                    checked={value}
                    readOnly
                    tabIndex={props.tabIndex as number}
                />
                <label htmlFor={props.id} className={`${disabled ? 'disabled' : ''}`}></label>
                <span>{label ? label : DisplayValueHelper.convertToYesOrNo(value)}</span>
            </div>
        </Field>
    );
};

export default CheckBoxField;
