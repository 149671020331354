import React, { useEffect, useState } from 'react';
import NavigationGroup from './navigation-group';
import NavigationItem from './navigation-item';
import SideBar from './side-bar';
import MenuItem from '../../models/menu-item';
import { useHistory } from 'react-router-dom';
import { useHasPermission } from 'common/hooks/use-has-permission';
import { getWidgetPermissions, PermissionType } from 'common/models/permission-type';
import NotesMenuItem from './notes-navigation-item';
import SingleNavItem from './single-nav-item';
import { useSelector } from 'react-redux';

export const LeftNavigationBar = (props) => {
    const { expanded, toggle, onLogOut } = props;
    const [activeNavItem, setActiveNavItemType] = useState(null);
    const history = useHistory();

    const hasSettingsEditPermission = useHasPermission(PermissionType.SettingsEdit);
    const hasCanViewUsersPermission = useHasPermission(PermissionType.CanViewInternalUsers);
    const hasCanUpdateInternalUsersPermission = useHasPermission(PermissionType.CanUpdateInternalUsers);
    const hasCanViewExternalUsersPermission = useHasPermission(PermissionType.CanViewExternalUsers);
    const hasCanUpdateExternalUsersPermission = useHasPermission(PermissionType.CanUpdateExternalUsers);

    const hasCanManageRolesPermission = useHasPermission(PermissionType.CanManageRoles);
    const hasAccessToDashboardMenu = useHasPermission(...getWidgetPermissions());

    const featureList = useSelector((state) => state.siteSettings?.settings?.features || {});

    const schoolOptions = [new MenuItem(1, 'Requests', '/school/requests', featureList.isSchoolRequestsEnabled)];
    const hrOptions = [
        new MenuItem(1, 'Providers', '/providers'),
        new MenuItem(2, 'Documents Management', '/documents'),
        new MenuItem(3, 'Staffing Agencies', '/staffing-agencies', featureList.isStaffingAgenciesEnabled),
        new MenuItem(4, 'Leads Management', '/leads', featureList.isLeadManagementEnabled),
        new MenuItem(5, 'Map', '/providers-map', featureList.isTherapistsMapEnabled),
        new MenuItem(6, 'Availability Portal', '/availability'),
    ];
    const homeCareOptions = [
        new MenuItem(1, 'Requests', '/requests', featureList.isHCEnabled),
        new MenuItem(2, 'Patients', '/patients', featureList.isHCEnabled),
        new MenuItem(2, 'Placement Follow-Ups', '/placement-follow-ups', featureList.isHCEnabled),
    ];
    const nursingHomesOptions = [new MenuItem(1, 'Requests', '/nursing-home/requests')];
    const generalOptions = [
        new MenuItem(1, 'Entities', '/entities'),
        new MenuItem(2, 'Dashboard', '/dashboard', hasAccessToDashboardMenu),
        new MenuItem(2, 'Physicians', '/physicians'),
    ];
    const settingsOptions = [
        new MenuItem(1, 'System settings', '/settings', hasSettingsEditPermission && featureList.isHCEnabled),
        new MenuItem(
            1,
            'Site Users',
            '/users',
            hasCanViewUsersPermission ||
                hasCanUpdateInternalUsersPermission ||
                hasCanViewExternalUsersPermission ||
                hasCanUpdateExternalUsersPermission
        ),
        new MenuItem(1, 'Roles', '/roles', hasCanManageRolesPermission),
    ];
    const navigationInfo = [
        { name: 'hr', optionsRoutes: ['/providers', '/documents', '/staffing-agencies', '/leads'] },
        { name: 'homeCare', optionsRoutes: ['/patients', '/requests', '/placement-follow-ups'] },
        { name: 'nursingHomes', optionsRoutes: ['/nursing-home/requests'] },
        { name: 'school', optionsRoutes: ['/school/requests'] },
        { name: 'general', optionsRoutes: ['/entities', '/dashboard', '/physicians'] },
        { name: 'settings', optionsRoutes: ['/settings', '/users', '/roles'] },
        { name: 'global', optionsRoutes: ['/logging', '/notes'] },
    ];

    const configNavigationOptions = (menuOptions) => {
        return menuOptions
            .filter((i) => i.render)
            .map((item) => {
                return (
                    <NavigationItem routePath={item.route} key={item.key}>
                        {item.name}
                    </NavigationItem>
                );
            });
    };

    const onNavItemClick = () => {
        if (!expanded) {
            toggle();
        }
    };

    useEffect(() => {
        navigationInfo.forEach((item) => {
            const activeNavItemType = item.optionsRoutes.some((option) => option === history.location.pathname) && item.name;
            if (activeNavItemType) {
                setActiveNavItemType(activeNavItemType);
            }
        });
    }, [history.location.pathname, navigationInfo]);

    return (
        <div className="left-sidebar-menu">
            <SideBar
                onLogOut={() => onLogOut()}
                expanded={expanded}
                toggle={toggle}
                isNotificationsEnabled={featureList.isNotificationsEnabled}
            >
                <NavigationGroup
                    text="HR Management"
                    navItemType="hr"
                    classIcon="icon icon-job"
                    expandedSidebar={expanded}
                    activeNavItemType={activeNavItem}
                    onNavItemClick={onNavItemClick}
                >
                    {configNavigationOptions(hrOptions)}
                </NavigationGroup>
                {featureList.isHCEnabled && (
                    <NavigationGroup
                        text="Home Care"
                        navItemType="homeCare"
                        classIcon="icon icon-home"
                        expandedSidebar={expanded}
                        activeNavItemType={activeNavItem}
                        onNavItemClick={onNavItemClick}
                    >
                        {configNavigationOptions(homeCareOptions)}
                    </NavigationGroup>
                )}
                {featureList.isSNFEnabled && (
                    <NavigationGroup
                        text="Nursing Homes"
                        navItemType="nursingHomes"
                        className="nursingHomes"
                        classIcon="icon icon-facility"
                        expandedSidebar={expanded}
                        activeNavItemType={activeNavItem}
                        onNavItemClick={onNavItemClick}
                    >
                        {configNavigationOptions(nursingHomesOptions)}
                    </NavigationGroup>
                )}
                {featureList.isSchoolRequestsEnabled && (
                    <NavigationGroup
                        text="School"
                        navItemType="school"
                        classIcon="icon icon-job"
                        expandedSidebar={expanded}
                        activeNavItemType={activeNavItem}
                        onNavItemClick={onNavItemClick}
                    >
                        {configNavigationOptions(schoolOptions)}
                    </NavigationGroup>
                )}
                {featureList.isHCEnabled && (
                    <NavigationGroup
                        text="General"
                        navItemType="general"
                        classIcon="icon icon-list"
                        expandedSidebar={expanded}
                        activeNavItemType={activeNavItem}
                        onNavItemClick={onNavItemClick}
                    >
                        {configNavigationOptions(generalOptions)}
                    </NavigationGroup>
                )}
                <NotesMenuItem
                    extended={expanded}
                    expandedSidebar={expanded}
                    activeNavItemType={activeNavItem}
                    onNavItemClick={onNavItemClick}
                />

                {featureList.isHCEnabled && (
                    <SingleNavItem routePath="/logging" classIcon="icon-logging" expanded={expanded} itemName="Logs" />
                )}

                <NavigationGroup
                    text="Settings"
                    navItemType="settings"
                    classIcon="icon icon-settings"
                    expandedSidebar={expanded}
                    activeNavItemType={activeNavItem}
                    onNavItemClick={onNavItemClick}
                >
                    {configNavigationOptions(settingsOptions)}
                </NavigationGroup>
            </SideBar>
        </div>
    );
};

export default LeftNavigationBar;
