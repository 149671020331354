import React, { useState } from 'react';
import VerticalTabWrapper from '../vertical-tab-wrapper';
import ContactInfoDisplaySection from './contact-info-display-section';
import ContactInfoEditForm from './contact-info-edit-form';
import { useDispatch, useSelector } from 'react-redux';
import { trackPromise } from 'react-promise-tracker';
import { CommunicationHelper } from 'common/helpers/communication-helper';
import { IEditContactInfo } from 'common/services/api/therapist/types';
import { getTherapistDetailsRequest, updateTherapistContactInfoRequest } from 'app/store/therapist/action-creators';
import { ThunkDispatch } from 'redux-thunk';
import { Action } from '@reduxjs/toolkit';

const ContactInfoTab: React.FC = () => {
    const dispatch = useDispatch<ThunkDispatch<any, any, Action>>();
    const [isEditDialogOpen, toggleEditDialog] = useState(false);
    const therapist = useSelector((state: any) => state.therapistDetails.therapist);

    const handleClickOnEdit = () => {
        toggleEditDialog(true);
    };

    const handleCloseEditDialog = () => {
        toggleEditDialog(false);
    };

    const saveContactInfo = (contactInfo: IEditContactInfo) => {
        const contactInfoValidCommunicationInfo = CommunicationHelper.Validate({
            phones: contactInfo.phones,
            isPhonesRequiered: true,
        });

        contactInfo.phones = contactInfoValidCommunicationInfo.phones;

        return trackPromise(dispatch(updateTherapistContactInfoRequest(therapist.id, contactInfo))).then((response) => {
            trackPromise(dispatch(getTherapistDetailsRequest(therapist.id)));
            handleCloseEditDialog();

            return response;
        });
    };

    return (
        <VerticalTabWrapper onEdit={handleClickOnEdit}>
            <ContactInfoDisplaySection contactInfo={therapist.contactInfo}></ContactInfoDisplaySection>
            {isEditDialogOpen && (
                <div>
                    <ContactInfoEditForm
                        therapist={therapist.contactInfo}
                        onSave={saveContactInfo}
                        onCancel={handleCloseEditDialog}
                    ></ContactInfoEditForm>
                </div>
            )}
        </VerticalTabWrapper>
    );
};

export default ContactInfoTab;
