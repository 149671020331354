import { ISequenceItem } from 'features/shared/types';
import { ObjectHelper } from './object-helper';

export class SequenceHelper {
    static getSequenceItems = (sequenceItems: ISequenceItem[]) => {
        if (!sequenceItems || sequenceItems.length === 0) {
            return;
        }

        const result: ISequenceItem[] = ObjectHelper.deepCopy(sequenceItems) ?? [];

        return result.sort((a, b) => a.seq - b.seq);
    };

    static setSequenceItems = (sequenceItems: ISequenceItem[]) => {
        if (!sequenceItems || sequenceItems.length === 0) {
            return;
        }

        sequenceItems.forEach((sequenceItem, index) => {
            sequenceItem.seq = index + 1;
        });
    };
}
