import React from 'react';
import { FieldListPhonesViewProps } from './types';
import './field-list-view.scss';
import DisplayValue from 'common/components/display-value/display-value';
import { maskConstants } from 'common/constants/common';

const FieldListPhonesView = ({ phones }: FieldListPhonesViewProps) => {
    return (
        <div className="phones-list-view">
            {phones.map((phone, index) => (
                <>
                    <div className="row-two-column">
                        <DisplayValue id="phone" customClass="phones-list-view__phone-type" placeholder="Phone" value={phone.type} />
                        <DisplayValue
                            id="phone"
                            placeholder="No"
                            customClass="phones-list-view__number"
                            value={phone.number}
                            mask={maskConstants.PHONE_NUMBER}
                        />
                        {phone.extension && <DisplayValue id="middleName" placeholder="Ext." value={phone.extension} />}
                    </div>
                    {phones.length > 0 && index === 0 && <span className="main-status">main</span>}
                </>
            ))}
        </div>
    );
};

export default FieldListPhonesView;
