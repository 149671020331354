import { FieldValue } from 'common/models/field-value';
import React, { useState, KeyboardEvent } from 'react';
import Field from '../field';

export type Props = {
    onChange: (data: FieldValue) => void;
    chips: string[];
    pattern: RegExp;
    customClass: string;
    placeholder?: string;
    name: string;
    id: string;
};

const ChipInput = (props: Props) => {
    const [isFocused, toggleFocus] = useState(false);
    const [value, setValue] = useState<string[]>(props.chips);

    const handleKeyDown = (e: KeyboardEvent<HTMLInputElement>) => {
        switch (e.key) {
            case 'Enter': {
                const success: boolean = addChip(e.currentTarget.value);
                if (success) {
                    e.currentTarget.value = '';
                }

                break;
            }
            case 'Backspace': {
                if (!e.currentTarget.value && value.length > 0) {
                    removeChip(value.length - 1);
                }

                break;
            }
        }
    };

    const addChip = (chip: string) => {
        if (!props.pattern.test(chip)) {
            return false;
        }

        const chips = value.slice() || [];
        chips.push(chip);
        setValue(chips);
        props.onChange({ name: props.name, value: chips });

        return true;
    };

    const removeChip = (index: number) => {
        const chips = value.slice();
        chips.splice(index, 1);
        setValue(chips);
        props.onChange({ name: props.name, value: chips });
    };

    const handleOnFocus = () => {
        toggleFocus(true);
    };

    const handleOnBlur = () => {
        toggleFocus(false);
    };

    return (
        <Field isFocused={isFocused} id={props.id} placeholder={props.placeholder} value={value}>
            <div className={`chip-input ${props.customClass}`}>
                <div className="chip-list-wrapper">
                    {value.map((chip, index) => (
                        <div className="chip-element" key={index}>
                            <span className="remove-chip" onClick={() => removeChip(index)}></span>
                            <span>{chip}</span>
                        </div>
                    ))}
                </div>
                <input name={props.name} onFocus={handleOnFocus} onBlur={handleOnBlur} onKeyDown={handleKeyDown}></input>
            </div>
        </Field>
    );
};

export default ChipInput;
