import PageHeader from 'common/components/page-header/page-header';
import DropdownOption from 'common/models/dropdown-option';
import { PermissionType } from 'common/models/permission-type';
import { IDashboarSettings } from 'common/services/api/settings/types';
import therapistDashboardService from 'common/services/api/therapist-dahsboard/therapist-dashboard-service';
import { IHRWidgetFilter } from 'common/services/api/therapist-dahsboard/types';
import React, { useCallback, useContext, useEffect, useRef, useState } from 'react';
import GridLayout from 'react-grid-layout';
import { useSelector } from 'react-redux';
import { HrWidget } from './components/hr-widget/hr-widget';
import MyFollowUpWidget from './components/my-follow-up-widget/my-follow-up-widget';
import RecruitmentWidget from './components/recruitment-widget/recruitment-widget';
import {
    ACTIVE_DOCUMENTS_WIDGET_KEY,
    dashboardStoreRepository,
    INTERVIEW_SCHEDULED_WIDGET_KEY,
    IN_PROCESS_WIDGET_KEY,
    MISSING_DATA_WIDGET_KEY,
    MY_FOLLOW_UP_WIDGET_KEY,
    NEW_WIDGET_KEY,
    ONBOARDING_DOCUMENTS_WIDGET_KEY,
    ONBOARDING_WIDGET_KEY,
    repository,
    supervisorOptions,
    IDashboardPageContext,
} from './types';

import './_styles.scss';
import { FakeTabs } from './components/fake-tabs/fake-tabs';
import { DefaultLayoutContext } from '../../common/components/layout/default-layout';
import { IDefaultLayoutContext } from '../../common/components/layout/types';
import MissingDataWidget from './components/missing-data-widget/missing-data-widget';
import Button from '../../common/components/Button/button';
import userService from 'common/services/api/user/user-service';
import { IUserBaseInfo } from '../../common/services/api/user/types';
import PaginationAutocomplete from '../../common/components/field/pagination-autocomplete/pagination-autocomplete';
import { SessionStoreRepository } from 'common/helpers/repository/session-store-repository';
import { ColDef } from 'ag-grid-community';
import { getScale } from '../../common/components/zoom/zoom';

const pushIf = (array: string[], element: string, conditition: boolean) => {
    if (conditition) {
        array.push(element);
    }
};

const customFiltersRepository = new SessionStoreRepository(`dashboard-top-filters`);
const savedFilters = customFiltersRepository.load();

export const DashboardPageContext = React.createContext<IDashboardPageContext>(null);

const DashboardPage: React.FC = () => {
    const userPermissions = useSelector<string[]>((state: any) => state.auth.user.permissions || []) as string[];
    const [isSupervisorView, setIsSupervisorView] = useState(dashboardStoreRepository.load() ?? false);

    const dashboardSettings = useSelector((state: any) => state?.siteSettings?.settings?.dashboard) as IDashboarSettings;

    const hasAccessToMyFollowUpWidget = userPermissions.includes(PermissionType.AccessMyFollowUpWidget);

    const hasAccessToWidget = useCallback(
        (widgetPermission: PermissionType, supervisorPermission: PermissionType) => {
            if (isSupervisorView) {
                return userPermissions.includes(supervisorPermission);
            }

            return userPermissions.includes(widgetPermission) || userPermissions.includes(supervisorPermission);
        },
        [isSupervisorView, userPermissions]
    );

    const hasAccessToSupervisorDropdown = useCallback(() => {
        const supervisorPermissions: string[] = [
            PermissionType.AccessNewSupervisorWidget,
            PermissionType.AccessInProcessSupervisorWidget,
            PermissionType.AccessInterviewScheduledSupervisorWidget,
            PermissionType.AccessOnboardingSupervisorWidget,
            PermissionType.AccessActiveWithDocumentsSupervisorWidget,
            PermissionType.AccessOnboardingWithDocumentsSupervisorWidget,
        ];

        return userPermissions.some((permission) => supervisorPermissions.includes(permission));
    }, [userPermissions]);

    const isNewAvailable = useCallback(() => {
        return hasAccessToWidget(PermissionType.AccessNewWidget, PermissionType.AccessNewSupervisorWidget);
    }, [hasAccessToWidget]);

    const isInProcessAvailable = useCallback(() => {
        return hasAccessToWidget(PermissionType.AccessInProcessWidget, PermissionType.AccessInProcessSupervisorWidget);
    }, [hasAccessToWidget]);

    const isInterviewScheduledAvailable = useCallback(() => {
        return hasAccessToWidget(PermissionType.AccessInterviewScheduledWidget, PermissionType.AccessInterviewScheduledSupervisorWidget);
    }, [hasAccessToWidget]);

    const isOnboardingDocumentsAvailable = useCallback(() => {
        return hasAccessToWidget(
            PermissionType.AccessOnboardingWithDocumentsWidget,
            PermissionType.AccessOnboardingWithDocumentsSupervisorWidget
        );
    }, [hasAccessToWidget]);

    const isActiveDocumentsAvailable = useCallback(() => {
        return hasAccessToWidget(PermissionType.AccessActiveWithDocumentsWidget, PermissionType.AccessActiveWithDocumentsSupervisorWidget);
    }, [hasAccessToWidget]);

    const isOboardingAvailable = useCallback(() => {
        return hasAccessToWidget(PermissionType.AccessOnboardingWidget, PermissionType.AccessOnboardingSupervisorWidget);
    }, [hasAccessToWidget]);

    const isMissingDataAvailable = useCallback(() => {
        return hasAccessToWidget(PermissionType.AccessMissingDataWidget, PermissionType.AccessMissingDataSupervisorWidget);
    }, [hasAccessToWidget]);

    const generateWidgets = () => {
        const result: string[] = [];

        pushIf(result, MY_FOLLOW_UP_WIDGET_KEY, hasAccessToMyFollowUpWidget);
        pushIf(result, NEW_WIDGET_KEY, isNewAvailable());
        pushIf(result, IN_PROCESS_WIDGET_KEY, isInProcessAvailable());
        pushIf(result, INTERVIEW_SCHEDULED_WIDGET_KEY, isInterviewScheduledAvailable());
        pushIf(result, ONBOARDING_WIDGET_KEY, isOboardingAvailable());
        pushIf(result, ONBOARDING_DOCUMENTS_WIDGET_KEY, isOnboardingDocumentsAvailable());
        pushIf(result, ACTIVE_DOCUMENTS_WIDGET_KEY, isActiveDocumentsAvailable());
        pushIf(result, MISSING_DATA_WIDGET_KEY, isMissingDataAvailable());

        return result;
    };

    const generateLayout = (): GridLayout.Layout[] => {
        const layout = repository.load();

        if (layout) {
            return layout;
        }

        return generateWidgets().map((widget, i) => {
            const element: GridLayout.Layout = {
                x: i % 3,
                y: 0,
                w: 1,
                h: 1,
                i: widget,
            };

            return element;
        });
    };

    const handleLayoutChange = (layout: GridLayout.Layout[]) => {
        repository.save(layout);
    };

    const fetchOnboardingWithDocumentsWidgetData = useCallback((filter: IHRWidgetFilter) => {
        return therapistDashboardService.getOnboardingWithDocumentsWidget(filter);
    }, []);

    const exportOnboardingWithDocumentsWidgetData = useCallback((filter: IHRWidgetFilter, type: string) => {
        return therapistDashboardService.exportOnboardingWidgetWithDocumentsWidgetData(filter, type);
    }, []);

    const fetchActiveWithDocumentsWidgetData = useCallback((filter: IHRWidgetFilter) => {
        return therapistDashboardService.getActiveWithDocumentsWidget(filter);
    }, []);

    const exportActiveWithDocumentsWidgetData = useCallback((filter: IHRWidgetFilter, type: string) => {
        return therapistDashboardService.exportActiveWidgetWithDocumentsWidgetData(filter, type);
    }, []);

    const fetchOnboardingWidgetData = useCallback((filter: IHRWidgetFilter) => {
        return therapistDashboardService.getOnboardingWidgetData({ ...filter, status: 'Onboarding' });
    }, []);

    const exportOnboardingWidgetData = useCallback((filter: IHRWidgetFilter, type: string) => {
        return therapistDashboardService.exportOnboardingWidgetData({ ...filter, status: 'Onboarding' }, type);
    }, []);

    const handleSupervisorViewChange = (value: DropdownOption) => {
        dashboardStoreRepository.save(value.value);
        setIsSupervisorView(value.value);
    };

    const getUserStaffOptions = (term: string, skip: number, take: number): Promise<IUserBaseInfo[]> => {
        return userService.getUserStaffOptions(term, skip, take, true);
    };

    // Responsive settings
    const { sidebarWidth, setContentClass }: IDefaultLayoutContext = useContext(DefaultLayoutContext);
    const scaleRef = useRef(getScale());
    const [pageSize, setPageSize] = useState(scaleRef.current * (window.innerWidth - sidebarWidth));

    useEffect(() => {
        setPageSize(scaleRef.current * (window.innerWidth - (sidebarWidth + 15)));
    }, [sidebarWidth]);

    useEffect(() => {
        const handleResize = () => {
            const sidebarWidth = document.querySelector('.navigation-bar')?.clientWidth ?? 0;
            setPageSize(scaleRef.current * (window.innerWidth - (sidebarWidth + 15)));
        };
        const handleZoomChange = (ev: any) => {
            scaleRef.current = ev.detail?.scale;
            handleResize();
        };

        window.addEventListener('zoomChanged', handleZoomChange);
        window.addEventListener('resize', handleResize);
        setContentClass('fix-dashboard-layout');

        return () => {
            window.removeEventListener('resize', handleResize);
            window.removeEventListener('zoomChanged', handleZoomChange);
            setContentClass('');
        };
    }, [setContentClass]);

    const [globalDashboardFilters, setGlobalDashboardFilters] = useState(
        savedFilters ?? {
            assignedTo: [],
        }
    );

    // save filters to store
    useEffect(() => {
        customFiltersRepository.save(globalDashboardFilters);
    }, [globalDashboardFilters]);

    const handleClickOnClearButton = () => {
        agAPIs.current.forEach(({ gridApi, gridColumnApi }) => {
            if (!gridApi && !gridColumnApi) return;

            gridColumnApi.resetColumnState();
            gridApi.setFilterModel(null);

            // Restore sort
            const sortableColumn: ColDef = gridApi.getColumnDefs().find((column: ColDef) => column?.initialSort);
            if (sortableColumn) {
                gridApi.setSortModel([
                    {
                        colId: sortableColumn.colId,
                        sort: sortableColumn.initialSort,
                    },
                ]);
            }
        });
        setGlobalDashboardFilters((prev: any) => ({
            ...prev,
            assignedTo: [],
        }));
    };

    const agAPIs = useRef([]);

    return (
        <DashboardPageContext.Provider value={{ agAPIs: agAPIs }}>
            <PageHeader title="Dashboard" />
            {hasAccessToSupervisorDropdown() && (
                <div className="header-options">
                    <FakeTabs
                        id="supervisor-select"
                        className="pl-34 mt-28"
                        items={supervisorOptions}
                        value={supervisorOptions.find((i) => i.value === isSupervisorView)}
                        onChange={handleSupervisorViewChange}
                    >
                        {isSupervisorView && (
                            <>
                                <PaginationAutocomplete
                                    id="global-assignedTo-to"
                                    name="assignedTo"
                                    onDelete={null}
                                    placeholder={'Assigned To'}
                                    cleanable={true}
                                    multiselect
                                    tabIndex={null}
                                    defaultValue={globalDashboardFilters.assignedTo.map((item: any) => new DropdownOption(item, null))}
                                    onSelect={(data: DropdownOption[]) => {
                                        setGlobalDashboardFilters((prev: any) => ({
                                            ...prev,
                                            assignedTo: data.map((option) => option.value),
                                        }));
                                    }}
                                    showSelectedCounter
                                    selectDebounceMs={600}
                                    defaultSelectedLabel="All Users"
                                    convertFunction={(item: IUserBaseInfo) => new DropdownOption(item.id, item.fullName)}
                                    fetchData={getUserStaffOptions}
                                    className="assign-to-multiple-user-dropdown"
                                ></PaginationAutocomplete>

                                <Button id="dashboardClearFilters" onClick={handleClickOnClearButton} className="base-text-btn white">
                                    Clear Filters
                                </Button>
                            </>
                        )}
                    </FakeTabs>
                </div>
            )}
            <GridLayout
                className="dashboard-layout"
                onLayoutChange={handleLayoutChange}
                layout={generateLayout()}
                cols={2}
                margin={[24, 24]}
                draggableCancel={'.ag-table-main'}
                draggableHandle={'.drag-this'}
                rowHeight={window.innerWidth >= 1439 ? 402 : 440}
                width={pageSize}
            >
                {hasAccessToMyFollowUpWidget && (
                    <div key={MY_FOLLOW_UP_WIDGET_KEY} className="table-page my-follow-up-widget">
                        <MyFollowUpWidget globalFilters={globalDashboardFilters} isSupervisorView={isSupervisorView} />
                    </div>
                )}
                {isNewAvailable() && (
                    <div key={NEW_WIDGET_KEY} className="table-page new-widget">
                        <RecruitmentWidget
                            title="New"
                            status="New"
                            globalFilters={globalDashboardFilters}
                            fetchFunction={therapistDashboardService.getNewWidgetData}
                            assignedToOptions={therapistDashboardService.getNewAssignedToOptions}
                            exportFunction={therapistDashboardService.exportNewWidgetData}
                            isSupervisorView={isSupervisorView}
                            overdueDaysSetting={dashboardSettings?.newWidget?.overdueInDays}
                        />
                    </div>
                )}
                {isInProcessAvailable() && (
                    <div key={IN_PROCESS_WIDGET_KEY} className="table-page in-process-widget">
                        <RecruitmentWidget
                            status="InProcess"
                            title="InProcess"
                            globalFilters={globalDashboardFilters}
                            fetchFunction={therapistDashboardService.getInProcessWidgetData}
                            assignedToOptions={therapistDashboardService.getInProcessAssignedToOptions}
                            exportFunction={therapistDashboardService.exportInProcessWidgetData}
                            isSupervisorView={isSupervisorView}
                            overdueDaysSetting={dashboardSettings?.inProcessWidget?.overdueInDays}
                        />
                    </div>
                )}
                {isInterviewScheduledAvailable() && (
                    <div key={INTERVIEW_SCHEDULED_WIDGET_KEY} className="table-page interview-scheduled-widget">
                        <RecruitmentWidget
                            status="InterviewScheduled"
                            title="Interview Scheduled"
                            globalFilters={globalDashboardFilters}
                            fetchFunction={therapistDashboardService.getInterviewScheduledWidgetData}
                            assignedToOptions={therapistDashboardService.getInterviewScheduledAssignedToOptions}
                            exportFunction={therapistDashboardService.exportInterviewScheduledWidgetData}
                            isSupervisorView={isSupervisorView}
                            overdueDaysSetting={dashboardSettings?.interviewScheduledWidget?.overdueInDays}
                        />
                    </div>
                )}
                {isOnboardingDocumentsAvailable() && (
                    <div key={ONBOARDING_DOCUMENTS_WIDGET_KEY} className="table-page oboarding-documents-widget">
                        <HrWidget
                            enableDocumentFilter={true}
                            globalFilters={globalDashboardFilters}
                            title="Onboarding with Expired/Missing Docs"
                            fetchFunction={fetchOnboardingWithDocumentsWidgetData}
                            exportFunction={exportOnboardingWithDocumentsWidgetData}
                            isSupervisorView={isSupervisorView}
                            status="Onboarding"
                            overdueDaysSetting={dashboardSettings?.onboardingWithDocumentsWidget?.overdueInDays}
                        />
                    </div>
                )}
                {isActiveDocumentsAvailable() && (
                    <div key={ACTIVE_DOCUMENTS_WIDGET_KEY} className="table-page active-documents-widget">
                        <HrWidget
                            enableDocumentFilter={true}
                            globalFilters={globalDashboardFilters}
                            status="Active"
                            title="Active with Expired/Missing Docs"
                            fetchFunction={fetchActiveWithDocumentsWidgetData}
                            exportFunction={exportActiveWithDocumentsWidgetData}
                            isSupervisorView={isSupervisorView}
                            overdueDaysSetting={dashboardSettings?.activeWithDocumentsWidget?.overdueInDays}
                        />
                    </div>
                )}
                {isOboardingAvailable() && (
                    <div key={ONBOARDING_WIDGET_KEY} className="table-page onboarding-widget">
                        <HrWidget
                            enableDocumentFilter={false}
                            globalFilters={globalDashboardFilters}
                            title="Onboarding"
                            status="Onboarding"
                            fetchFunction={fetchOnboardingWidgetData}
                            exportFunction={exportOnboardingWidgetData}
                            overdueDaysSetting={dashboardSettings?.onboardingWidget?.overdueInDays}
                            isSupervisorView={isSupervisorView}
                        />
                    </div>
                )}
                {isMissingDataAvailable() && (
                    <div key={MISSING_DATA_WIDGET_KEY} className="table-page missing-data-widget">
                        <MissingDataWidget globalFilters={globalDashboardFilters} isSupervisorView={isSupervisorView} />
                    </div>
                )}
            </GridLayout>
        </DashboardPageContext.Provider>
    );
};

export default DashboardPage;
