import React from 'react';
import { DepartmentFilterProps } from './types';
import './department-filter.scss';
import { DepartmentGroupAutocomplete } from 'common/components/department-group-autocomplete/department-group-autocomplete';

const DepartmentFilter: React.FC<DepartmentFilterProps> = ({ value, onChange, cleanable = true }) => {
    return (
        <div className="department-filter small-size">
            <DepartmentGroupAutocomplete
                popper={true}
                widthOptions={250}
                cleanable={cleanable}
                defaultSelectedLabelDepartment="Department"
                onDepartmentChange={onChange}
                departments={value || []}
                subDepartments={[]}
                hideSubDepartment={true}
            />
        </div>
    );
};

export default React.memo(DepartmentFilter);
