import React, { useState } from 'react';
import { IFormItem } from 'common/models/types';
import { RateEditFormProps, RateErrorCodes } from './types';
import RightSideBar from 'common/components/right-side-bar/right-side-bar';
import FocusTrap from 'focus-trap-react';
import RateBaseForm from './rate-basic-form';
import './_rate-form.scss';
import RateTemplateTypes from './rate-template-types';
import { EXISTING_RATE_CONFLICT } from '../constants';
import { ILegalEntityRate } from 'common/services/api/rate/types';

const RateEditForm: React.FC<RateEditFormProps> = ({ rateTemplate, rateData, onClose, onSave }) => {
    const [rate, setRate] = useState<ILegalEntityRate>({
        rateId: rateData.rateId,
        rateTemplateId: rateTemplate.id,
        rate: rateData.rate,
        startDate: rateData.startDate,
        endDate: rateData.endDate,
        documents: rateData.documents.map((doc) => {
            return { ...doc, isDisabled: doc.fileId !== null };
        }),
    });

    const [rateErrors, setRateErrors] = useState({});

    const [allowSave, setAllowSave] = useState(false);

    const handleOnSaveClick = () => {
        const documents = rate.documents?.filter((doc) => doc.value != null).map((doc) => doc.value);

        onSave({ ...rate, documents }).catch((errors) => {
            if (errors && !errors.errorCode) {
                setRateErrors(errors);
            }

            if (errors.errorCode) {
                switch (errors.errorCode) {
                    case RateErrorCodes.EditRateActiveConstraintFailed:
                    case RateErrorCodes.EditRateActiveFutureConstraintFailed:
                    case RateErrorCodes.EditRatePastConstraintFailed: {
                        const activeRateError = {
                            startDate: [EXISTING_RATE_CONFLICT],
                            endDate: [EXISTING_RATE_CONFLICT],
                        };
                        setRateErrors(activeRateError);
                        break;
                    }
                    default:
                        break;
                }
            }
        });
    };

    const handleRateChange = (data: IFormItem) => {
        setRate((prevInfo) => ({ ...prevInfo, [data.name]: data.value }));
        setRateErrors((prevError) => ({ ...prevError, [data.name]: undefined }));
        setAllowSave(true);
    };

    const handleCancelClick = () => {
        setRateErrors({});
        onClose();
    };

    const renderRateTemplateTypes = () => {
        return <RateTemplateTypes rateTemplate={rateTemplate}></RateTemplateTypes>;
    };

    return (
        <div className="rate-form">
            <FocusTrap focusTrapOptions={{ allowOutsideClick: true }}>
                <RightSideBar
                    title="Edit Rate"
                    onCancel={handleCancelClick}
                    onSave={handleOnSaveClick}
                    renderAdditionalContent={renderRateTemplateTypes}
                    disableSave={!allowSave}
                >
                    <div className="create-form">
                        <RateBaseForm rate={rate} errors={rateErrors} onChange={handleRateChange} />
                    </div>
                </RightSideBar>
            </FocusTrap>
            <div className="right-sidebar-overlay"></div>
        </div>
    );
};

export default RateEditForm;
