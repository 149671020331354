import React from 'react';
import { FieldListEmailsViewProps } from './types';
import './field-list-view.scss';
import DisplayValue from 'common/components/display-value/display-value';

const FieldListEmailsView = ({ emails }: FieldListEmailsViewProps) => {
    return (
        <div className="emails-list-view">
            {emails.map((email, index) => (
                <>
                    <DisplayValue id="emails" customClass="Emails-list-view__phone-type" placeholder="Email Address" value={email.email} />
                    {emails.length > 0 && index === 0 && <span className="main-status">main</span>}
                </>
            ))}
        </div>
    );
};

export default FieldListEmailsView;
