import { IFilterParams, RowNode } from 'ag-grid-community';
import React, { forwardRef, useEffect, useImperativeHandle, useState, useRef, useCallback } from 'react';
import BaseCustomInput from 'common/components/BaseCustomInput/BaseCustomInput';
import './ag-custom-set-column-filter.scss';
import { IExtendParams, ICustomerFilterModel } from '../../types';
import { useNextPage } from 'common/hooks/use-next-page';
const debounce = require('lodash.debounce');

const DEFAULT_PAGE_SIZE = 20;

const blankOption = '(blank)';

const AgCustomSetColumnFilter = forwardRef((props: IFilterParams & IExtendParams, ref) => {
    // Checkbox states
    const [filtersList, setFiltersList] = useState([]);
    const [filtersListPreset, setFiltersListPreset] = useState([]);
    const [filtersState, setFiltersState] = useState<{
        [key: string]: boolean;
    }>({});
    const handleSaveFilters = useRef<any>();

    // Select Button State
    const [selectState, setSelectState] = useState(0);

    // Input
    const [filterText, setFilterText] = useState('');
    const inputWrapper = useRef(null);

    // Grid API
    const [model, setModel] = useState<ICustomerFilterModel>(null);
    const {
        api: gridApi,
        column,
        colDef,
        values,
        keyCreator,
        transformToServer,
        transformFromServer,
        onFetchFilters,
        disableSearch = false,
        isNullable = false,
        valueGetter,
        hasSaveButton,
        debounceMs = 600,
    } = props;
    const colId = column.getColId();

    // @ts-ignore
    const isClientSide = gridApi?.clientSideRowModel?.gridOptionsWrapper?.gridOptions?.rowModelType === 'clientSide';

    const items = new Set();
    if (isClientSide) {
        const addItem = (cellValue: any) => {
            if (typeof cellValue === 'string' || typeof cellValue === 'number' || typeof cellValue === 'boolean') {
                items.add(cellValue + '');
            } else if (typeof cellValue === 'object' && keyCreator) {
                items.add(keyCreator(cellValue));
            }
        };
        gridApi.forEachNode((node: any) => {
            if (Array.isArray(node.data[colId])) {
                node.data[colId].forEach((cellValue: any) => addItem(cellValue));
            } else {
                addItem(node.data[colId]);
            }
        });
    }

    const [data, loadNextPage, resetPageCounter] = useNextPage(
        isClientSide ? () => Promise.resolve({ data: values ? values() : Array.from(items), clientSide: true }) : onFetchFilters,
        isClientSide ? Number.MAX_SAFE_INTEGER : DEFAULT_PAGE_SIZE
    );

    // expose AG Grid Filter Lifecycle callbacks
    useImperativeHandle(ref, () => {
        return {
            isFilterActive() {
                return (model != null && model.values && model.values.length > 0) || model?.isNullable;
            },

            doesFilterPass(params: RowNode) {
                const value: any = valueGetter({
                    ...params,
                } as RowNode)
                    ?.toString()
                    ?.toLowerCase();

                if (model?.isNullable) {
                    return !value || value?.length === 0;
                } else if (value === null || value === undefined) {
                    return false;
                }

                return !!model?.values?.some((filterWord: string) => value.indexOf(filterWord.toString().toLowerCase()) >= 0);
            },

            getModel() {
                if (!this.isFilterActive()) {
                    return null;
                }
                const result: any = {
                    exclude: selectState > 0,
                    isNullable: filtersState[blankOption],
                    filterType: 'customSet',
                    ...model,
                };
                if (!result.values) result.values = [];

                return !model || (!result.isNullable && !result.exclude && result.values.length === 0) ? null : result;
            },

            setModel(model: ICustomerFilterModel) {
                if (model === null) {
                    setSelectState(0);
                }

                setModel(model);
            },
        };
    });

    const getDifference = (arrayFirst: string[], arraySecond: string[]) => {
        const mainList = new Set(arrayFirst);
        const result: string[] = [];
        (arraySecond ?? []).forEach((rawKey) => {
            if (!mainList.has(rawKey)) {
                result.push(rawKey);
            }
        });

        return result;
    };

    // Input search
    const refreshData = useCallback(
        debounce(
            (value: string) => {
                setFiltersList([]);
                resetPageCounter();
                loadNextPage(colId, value);
            },
            isClientSide ? 0 : 200
        ),
        []
    );

    const handleSearchInputChange = useCallback(
        (event: any) => {
            setFilterText(event.target.value);
            refreshData(event.target.value);
        },
        [refreshData]
    );

    const resetFilterSearch = () => {
        setFilterText('');
        refreshData('');
    };

    // Input search resizer
    const filtersListWrapper = useRef(null);

    // Fetch Filter Data watcher
    useEffect(() => {
        if (!data) return;

        const preset = getDifference(filtersList, filtersListPreset);
        data.forEach((item: string) => {
            if (filtersState[item] === undefined) filtersState[item] = selectState > 0;
        });
        setFiltersListPreset([...preset]);
        setFiltersList((filtersList) => {
            return [...filtersList, ...data];
        });
    }, [data]);

    useEffect(() => {
        const preset = getDifference(filtersList, filtersListPreset);
        setFiltersListPreset([...preset]);
    }, [filtersList]);

    const loadDataFromModel = () => {
        const clearFiltersState = (array: string[], select: boolean) => {
            const result: { [key: string]: boolean } = {};
            (array ?? []).forEach((rawKey: string) => {
                result[rawKey] = select;
            });

            return result;
        };
        if (model !== null) {
            const preset = getDifference(filtersList, model?.values);
            const resetFilterState = filtersState ? clearFiltersState(Object.keys(filtersState), model?.exclude) : {};
            const updatedFilterState = clearFiltersState(model?.values, !model?.exclude);
            setFiltersListPreset([...preset]);
            setFiltersState((prev) => ({ ...prev, ...resetFilterState, ...updatedFilterState, [blankOption]: model?.isNullable }));
        }
    };

    // Model change watcher (update only presetFilters and filterState !!!)
    useEffect(() => {
        if (model !== null) {
            loadDataFromModel();
        } else {
            setFiltersListPreset([]);
            setFiltersState({});
        }
        props.filterChangedCallback();
    }, [model]);

    const handleScrollFilterBox = () => {
        const { scrollTop, scrollHeight, clientHeight } = columnFilterBody.current;
        if (scrollTop > 0 && Math.ceil(scrollTop + clientHeight) + 2 >= scrollHeight) {
            loadNextPage(colId, filterText);
        }
    };

    const onCloseFilterClick = () => {
        loadDataFromModel();
        resetFilterSearch();
        gridApi.hidePopupMenu();
    };

    // DROP DOWN
    const columnFilterBody = useRef(null);

    const globalCheckboxState = (state: boolean) => {
        setFiltersState((prev) => {
            const newFilterState: any = {};
            Object.keys(prev ?? {}).forEach((key) => {
                newFilterState[key] = state;
            });

            return newFilterState;
        });
        if (!hasSaveButton) {
            setTimeout(() => handleSaveFilters.current());
        }
    };

    const toggleUniqueCheckbox = (value: any) => {
        setFiltersState((prev) => ({ ...prev, [value]: !prev[value] }));
        if (!hasSaveButton) {
            setTimeout(handleRowDataChanged);
        }
    };

    const getUniqueSelectedValues = (state: boolean) => {
        return [...filtersList, ...filtersListPreset]
            .filter((key) => filtersState[key] === state)
            .map((filterName) => {
                return transformToServer ? transformToServer(filterName) : filterName;
            });
    };

    const onSaveFilterClick = async (item?: any, event?: any) => {
        if (event) {
            event.stopPropagation(event);
        }

        const filters = getUniqueSelectedValues(true);

        const currentModel = {
            exclude: false,
            filterType: 'customSet',
            isNullable: filtersState[blankOption],
            values: filters,
        };

        setModel(currentModel);
        if (hasSaveButton) {
            resetFilterSearch();
            gridApi.hidePopupMenu();
        }
    };

    handleSaveFilters.current = onSaveFilterClick;
    const handleRowDataChanged = useCallback(
        debounce(() => handleSaveFilters.current(), debounceMs),
        []
    );

    const getCheckBoxes = (arr: any[] = []) => {
        return arr.map((filterName: string, idx) => {
            return (
                filterName.trim() && (
                    <div className="column-filter-option" key={idx} onClick={() => toggleUniqueCheckbox(filterName)}>
                        <input
                            type="checkbox"
                            className="nps-checkbox"
                            id={`nps-checkbox` + idx}
                            checked={filtersState[filterName] === true}
                            readOnly
                        />
                        <label htmlFor={`nps-checkbox` + idx}>
                            <span>{transformFromServer ? transformFromServer(filterName.trim()) : filterName.trim()}</span>
                        </label>
                    </div>
                )
            );
        });
    };

    useEffect(() => {
        if (filtersList.length === 0) {
            loadNextPage(colId);
        }
    }, []);

    const filterContent = (
        <div className="custom-column-filter-body">
            <div className="column-filter-dropdown-wrapper">
                <div id={`column-wrapper_${colId}`} className="column-filter-options-wrapper" ref={filtersListWrapper}>
                    <div className="filter-set__header">
                        <button
                            id="columnFilterSelectAll"
                            className="filter-set__btn filter-set__btn--blue"
                            onClick={() => globalCheckboxState(false)}
                        >
                            Clear filters
                        </button>
                        <span className="filter-set__spacer"></span>
                        {hasSaveButton && (
                            <>
                                <button id="columnFilterCancelBtn" className="filter-set__btn" onClick={() => onCloseFilterClick()}>
                                    Cancel
                                </button>
                                <button
                                    id="columnFilterSaveBtn"
                                    className="filter-set__btn filter-set__btn--blue ml-5"
                                    onClick={(event) => onSaveFilterClick(column, event)}
                                >
                                    Save
                                </button>
                            </>
                        )}
                    </div>

                    <div className="column-filter-options-body" onScroll={handleScrollFilterBox} ref={columnFilterBody}>
                        {isNullable && !filterText && getCheckBoxes([blankOption])}
                        {filtersListPreset.length !== 0 && getCheckBoxes(filtersListPreset)}
                        {filtersList.length !== 0 && getCheckBoxes(filtersList)}
                    </div>
                </div>
            </div>
        </div>
    );

    return (
        <>
            <div className={`filter-set`}>
                {!disableSearch && (
                    <div ref={inputWrapper} className="custom-column-filter-input">
                        <BaseCustomInput
                            inputId={`${colId}MainFilterInput`}
                            labelId={`${colId}MainFilterLabel`}
                            inputName="columnSearchInput"
                            inputType="text"
                            fieldType="input"
                            placeholderText={`${colDef.headerName}`}
                            inputValue={filterText}
                            isWithIcon={false}
                            onInputChange={handleSearchInputChange}
                        />
                    </div>
                )}
                {filterContent}
            </div>
        </>
    );
});

export default AgCustomSetColumnFilter;
