import React from 'react';
import RightSideBarHeaderEditMode from './right-side-bar-header-edit-mode';
import RightSideBarHeaderViewMode from './right-side-bar-header-view-mode';

export const SideBarMode = {
    EDIT: 'Edit',
    VIEW: 'View',
};

class RightSideBar extends React.Component {
    renderHeader() {
        if (this.props.mode === SideBarMode.VIEW) {
            return <RightSideBarHeaderViewMode {...this.props}></RightSideBarHeaderViewMode>;
        }

        return <RightSideBarHeaderEditMode {...this.props}></RightSideBarHeaderEditMode>;
    }

    render() {
        return (
            <React.Fragment>
                <div className={`right-side-bar ${this.props.className ?? ''}`}>
                    {this.renderHeader()}
                    <div className="right-side-bar-body">{this.props.children}</div>
                </div>
            </React.Fragment>
        );
    }
}

export default RightSideBar;
