import React from 'react';
import RightSideBar, { SideBarMode } from 'common/components/right-side-bar/right-side-bar';
import DisplayValue from 'common/components/display-value/display-value';
import { DisplayValueHelper } from 'common/helpers/display-value-helper';
import NavigationSideBar from 'common/components/navigation-side-bar/navigation-side-bar';
import { DocumentDetailsDialogProps } from './types';

const DocumentDetailsDialog: React.FC<DocumentDetailsDialogProps> = ({ document, onCancel, prevButton, nextButton, actions }) => {
    return (
        <div className="document-details-wrapp">
            <RightSideBar title={document.name} onCancel={onCancel} mode={SideBarMode.VIEW} actions={actions}>
                <div className="details no-flex">
                    <div className="field-group-wrapper">
                        <div className="field-group">
                            <DisplayValue id="documentName" placeholder="Document Name" value={document.name} />
                            <DisplayValue id="documentType" placeholder="Type" value={document.documentType} />
                            <DisplayValue
                                id="documentExpire"
                                placeholder="Expiration Date Required"
                                value={DisplayValueHelper.convertToYesOrNo(document.isExpirationDateRequired)}
                            />
                            <DisplayValue
                                id="documentExpirationPeriod"
                                placeholder="Expiration Period"
                                value={DisplayValueHelper.monthsPeriod(document.expirationPeriod)}
                            />
                            <DisplayValue
                                id="documentIssues"
                                placeholder="Issue Date Required"
                                value={DisplayValueHelper.convertToYesOrNo(document.isIssueDateRequired)}
                            />
                            <DisplayValue
                                id="documentShouldBeApproved"
                                placeholder="Approval Required"
                                value={DisplayValueHelper.convertToYesOrNo(document.needsApprove)}
                            />
                            <DisplayValue id="documentInternalComments" placeholder="Internal Comments" value={document.comment} />
                            <DisplayValue id="documentInstructions" placeholder="Instructions" value={document.instructions} />
                        </div>
                    </div>
                    <div className="navigation-buttons">
                        <NavigationSideBar prevButton={prevButton} nextButton={nextButton} />
                    </div>
                </div>
            </RightSideBar>
            <div className="right-sidebar-overlay"></div>
        </div>
    );
};

export default DocumentDetailsDialog;
