import React, { useEffect } from 'react';
import { useState } from 'react';
import Field from '../field';
import { NativeTimePickerProps } from './types';
import './native-time-picker.scss';

const NativeTimePicker: React.FC<NativeTimePickerProps> = ({ hasError = false, ...props }) => {
    const [isFocused, toggleFocus] = useState(false);
    const [value, setValue] = useState<string>((props.value as string) || '');

    useEffect(() => {
        setValue(props.value);
    }, [props.value]);

    const handleOnFocus = () => {
        toggleFocus(true);
    };

    const handleOnBlur = () => {
        toggleFocus(false);
    };

    const handleChange = (currentValue: string) => {
        setValue(currentValue);

        props.onChange({ value: currentValue, name: props.name });
    };

    const isNoValue = !isFocused && (!value || value?.length === 0);

    return (
        <Field
            {...props}
            customClass={'native-time-picker ' + props.className + `${isNoValue ? ' no-value' : ''}`}
            isFocused={isFocused}
            hasError={hasError}
        >
            <input
                type="time"
                value={value}
                onFocus={handleOnFocus}
                onBlur={handleOnBlur}
                onChange={(event) => handleChange(event.target.value)}
            />
            <span className={`icon ${props.iconClass || ''}`}></span>
        </Field>
    );
};

export default NativeTimePicker;
