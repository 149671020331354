import React from 'react';
import { IForm } from 'common/models/types';
import FieldSet from 'common/components/field-set/field-set';
import InputField from 'common/components/field/input-field';
import DropdownOption from 'common/models/dropdown-option';
import InfiniteSelect from 'common/components/infinite-select/infinite-select';
import { ICreateUpdateRoleRequest } from 'common/services/api/role/types';
import { useSelector } from 'react-redux';

// TO-DO: Set tab indexes on the form
const AddEditRoleForm = ({ data: userRequest, errors, onChange }: IForm<ICreateUpdateRoleRequest>) => {
    const permissions = useSelector((state: any) => state.siteSettings?.settings?.permissions || []);
    const permissionsOptions = permissions.map((i: string) => new DropdownOption(i, i));

    return (
        <>
            <div className="edit-form">
                <FieldSet errors={errors} name="name" customClass="edit-field required-field-star">
                    <InputField id="name" placeholder="Role" name="name" value={userRequest.name} onChange={onChange} />
                </FieldSet>
                <FieldSet errors={errors} name="permissions" customClass="edit-field required-field-star">
                    <InfiniteSelect
                        items={permissionsOptions}
                        multiselect={true}
                        label="Privileges"
                        value={permissionsOptions.filter((item: DropdownOption) => userRequest.permissions.some((id) => id === item.value))}
                        onChange={(item) => onChange({ name: 'permissions', value: item.map((i: DropdownOption) => i.value) })}
                    ></InfiniteSelect>
                </FieldSet>
            </div>
        </>
    );
};

export default React.memo(AddEditRoleForm);
