import React from 'react';
import moment from 'moment';
import { ReminderDateLabelProps } from './types';

const ReminderDateLabel: React.FC<ReminderDateLabelProps> = ({ date, isExtended, isCompleted, onClick }) => {
    const determineLabelColor = (): string => {
        if (isCompleted) {
            return 'green';
        }

        const reminderDate = moment(date).local();
        const currentDate = moment().local();

        return reminderDate < currentDate ? 'red' : 'blue';
    };

    return (
        <div className={`reminder-date-block ${isExtended ? 'extended' : ''} ${determineLabelColor()}`} onClick={onClick}>
            <span className="date">{moment(date).local().format('MMM DD, hh:mm A')}</span>
        </div>
    );
};

export default ReminderDateLabel;
