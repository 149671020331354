import { IUser } from 'common/services/api/user/types';
import { GridReadyEvent } from 'ag-grid-community';

export type UsersListProps = {
    onGridReady?: (params: GridReadyEvent) => void;
    onEditUserClick: (user: IUser) => void;
    onResetPasswordClick: (user: IUser) => void;
};

export interface IUsersListFilter {
    search: string;
}

export enum UserGridActions {
    Edit = 'Edit',
    ResetPassword = 'ResetPassword',
}
