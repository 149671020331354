import OpenProfileButton from 'common/components/open-profile-button/open-page-button';
import { NavigationRoutes } from 'models/routes/navigation-routes';
import React from 'react';
import { PlacementInfoProps } from './types';
import './style.scss';
import { iconMap } from 'common/constants/map/map';

export const PlacementInfo: React.FC<PlacementInfoProps> = ({ placement, type }) => {
    return (
        <>
            <div className="info-wrapper placement-info">
                <div className="placement-info-data">
                    <div className="placement-therapist-name">{placement?.therapistName}</div>
                    <div className="placement-address">
                        {placement?.address
                            ? `${placement.address.address1 ?? ''} ${placement.address.city ?? ''}  ${placement.address.state ?? ''}  ${
                                  placement.address?.zip ?? ''
                              }`
                            : null}
                    </div>
                    <div className="placement-department">{iconMap[type]?.name}</div>
                    <div className="placement-name">{placement?.positionName}</div>
                </div>
            </div>
            <div className="placement-info-actions">
                <OpenProfileButton label={'Open Therapist'} url={NavigationRoutes.therapistDetailsRoute(placement?.therapistId)} />
            </div>
        </>
    );
};

export default React.memo(PlacementInfo);
