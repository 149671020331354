import { CellClickedEvent, GridApi, GridReadyEvent, SelectionChangedEvent } from 'ag-grid-community';
import AgTable from 'common/components/ag-table/ag-table';
import { filterParamsBoolean } from 'common/components/ag-table/filters/constants/filter-params-boolean';
import { IParamsWatcher } from 'common/components/ag-table/types';
import Button from 'common/components/Button/button';
import AddButton from 'common/components/header/add-button/add-button';
import ClearFiltersButton from 'common/components/header/clear-filters-button/clear-filters-button';
import DownloadSeveralFilesButton from 'common/components/header/download-several-files-button/download-several-files-button';
import ExportButton from 'common/components/header/export-button/export-button';
import HeaderLeftSide from 'common/components/header/header-left-side';
import HeaderRightSide from 'common/components/header/header-right-side';
import HeaderWrapper from 'common/components/header/header-wrapper';
import SearchFilterInput from 'common/components/header/search-filter-input/search-filter-input';
import InfiniteSelect from 'common/components/infinite-select/infinite-select';
import { dateTimeConstants } from 'common/constants/common';
import { ExportType } from 'common/constants/export-options';
import { DateTimeHelper } from 'common/helpers/date-time-helper';
import { DisplayValueHelper } from 'common/helpers/display-value-helper';
import { FileHelper } from 'common/helpers/file-helper';
import { LocalStoreRepository } from 'common/helpers/repository/local-store-repository';
import { LocalTableRepository } from 'common/helpers/repository/local-table-repository';
import { useHasPermission } from 'common/hooks/use-has-permission';
import { useSearchInput } from 'common/hooks/use-serch-input';
import DropdownOption from 'common/models/dropdown-option';
import { PermissionType } from 'common/models/permission-type';
import { getFilterData, getTableData } from 'common/services/api/tabel-fetch-service';
import therapistService from 'common/services/api/therapist/therapist-service';
import { getFileActionCreator } from 'features/document-viewer/store/document-viewer-action-creators';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Action } from 'redux';
import { ThunkDispatch } from 'redux-thunk';
import { documentActions } from './constants';
import DocumentCategoryFilter from './document-category-filter/document-category-filter';
import { TherapistDocumentState } from './models/therapist-document-state';
import { TherapistDocumentStatus } from './models/therapist-document-status';
import { TherapistDocumentCustomFilters, TherapistDocumentTableProps } from './type';

const TherapistDocumentTable: React.FC<TherapistDocumentTableProps> = ({
    therapistId,
    therapist,
    onUploadFileClick,
    onEditFileClick,
    onEditFileHistoryClick,
    onAddButtonClick,
    onApproveTherapistDocumentClick,
    onApproveTherapistDocumentsClick,
    onRejectTherapistDocumentClick,
    onInitialUploadFileClick,
    onOverrideDocumentClick,
    onAddDocumentClick,
    updateTable,
    storePrefix = '',
    extendGridOptions,
    extendDefaultCustomFilters,
    serachTitle = 'Documents',
}) => {
    const therapistName = `${therapist.generalInfo.firstName}${therapist.generalInfo.middleName || ''}${therapist.generalInfo.lastName}`;
    const hasAccessPrivateDocument = useHasPermission(PermissionType.AccessPrivateDocument);
    const userPermissions = useSelector((state: any) => state.auth.user.permissions || []);
    const dispatch = useDispatch<ThunkDispatch<any, any, Action>>();
    const dataWithHistoryItems = useRef([]);

    const handleFetchData = getTableData(`therapists/${therapistId}/documents`);
    const handleFetchFilters = getFilterData(`therapists/${therapistId}/documents/filter/source`);

    const tableRepository = useMemo(() => new LocalTableRepository(`${storePrefix}-therapist-document-table`, 'v6'), [storePrefix]);
    const customFiltersRepository = useMemo(() => new LocalStoreRepository(`${storePrefix}-therapist-document-table-custom-filters`), [
        storePrefix,
    ]);
    const loadedFilters = useRef<TherapistDocumentCustomFilters>(customFiltersRepository.load());
    const defaultFilters: TherapistDocumentCustomFilters = {
        search: null,
        category: [],
    };

    if (extendDefaultCustomFilters) {
        Object.keys(extendDefaultCustomFilters).forEach((key) => {
            defaultFilters[key] = extendDefaultCustomFilters[key];
            if (loadedFilters.current) {
                loadedFilters.current[key] = extendDefaultCustomFilters[key];
            }
        });
    }

    const [gridApi, setGridApi] = useState<GridApi>(null);
    const [gridColumnApi, setColumnApi] = useState(null);
    const [filters, setFilters] = useState(loadedFilters.current ?? defaultFilters);
    const { search, handleSearchInputChange, setSearch } = useSearchInput(loadedFilters.current?.search, (value: string) => {
        setFilters((prev) => ({ ...prev, search: value }));
    });
    const paramsWatcher = useRef<IParamsWatcher>();

    const [selectedRows, setSelectedRows] = useState(null);

    useEffect(() => {
        customFiltersRepository.save(filters);
        loadedFilters.current = filters;
    }, [filters, customFiltersRepository]);

    const handleOnDataChanged = (data: any, params?: IParamsWatcher) => {
        if (Array.isArray(data)) {
            const dataWithChildren = data.filter(
                (d: any) => d?.historyItems?.length > 0 && !dataWithHistoryItems.current.some((item: any) => item.id === d.id)
            );

            if (dataWithChildren.length > 0) {
                dataWithHistoryItems.current = dataWithHistoryItems.current.concat(dataWithChildren);
            }

            const selectedIds = params.gridApi.getSelectedRows()?.map((row: any) => row.id);

            setSelectedRows(data.filter((d: any) => selectedIds.includes(d.id)));
        }
    };

    const onOptionClick = (action: any, document: any) => {
        switch (action) {
            case documentActions.Edit:
                if (!document.historyItems) {
                    const acceptableDocuments = dataWithHistoryItems.current.find((item: any) =>
                        item.historyItems.some((historyItem: any) => historyItem.id === document.id)
                    )?.acceptableDocuments;

                    return onEditFileHistoryClick({ ...document, acceptableDocuments: acceptableDocuments });
                }

                return onEditFileClick(document);
            case documentActions.Add:
                return onUploadFileClick(document);
            case documentActions.Upload:
                return onInitialUploadFileClick(document);
            case documentActions.Override:
                return onOverrideDocumentClick(document);
            case documentActions.Approve:
                onApproveTherapistDocumentClick(document.id);
                break;
            case documentActions.Reject:
                onRejectTherapistDocumentClick(document);
                break;
            default:
                return;
        }
    };

    const getOptionList = (document: any): DropdownOption[] => {
        const itemOptions = [];

        if (!document.historyItems) {
            return dataWithHistoryItems && userPermissions.some((i: any) => i === PermissionType.AccessTherapistSecureData)
                ? [new DropdownOption(documentActions.Edit, documentActions.Edit)]
                : [];
        }

        if (document.status === TherapistDocumentStatus.UnVerified || document.status === TherapistDocumentStatus.Rejected) {
            itemOptions.push(new DropdownOption(documentActions.Approve, documentActions.Approve));
        }

        if (document.status === TherapistDocumentStatus.UnVerified || document.status === TherapistDocumentStatus.Approved) {
            itemOptions.push(new DropdownOption(documentActions.Reject, documentActions.Reject));
        }

        if (document.state === TherapistDocumentState.Missing || document.state === TherapistDocumentState.Overridden) {
            itemOptions.push(new DropdownOption(documentActions.Upload, documentActions.Upload));
        }

        if (document.state === TherapistDocumentState.Uploaded || document.state === TherapistDocumentState.Expired) {
            itemOptions.push(new DropdownOption(documentActions.Add, documentActions.Add));
        }

        if (document.state === TherapistDocumentState.Uploaded || document.state === TherapistDocumentState.Expired) {
            itemOptions.push(new DropdownOption(documentActions.Edit, documentActions.Edit));
        }

        if (
            document.isOverrideAllow &&
            (document.state === TherapistDocumentState.Missing || document.state === TherapistDocumentState.Expired)
        ) {
            itemOptions.push(new DropdownOption(documentActions.Override, documentActions.Override));
        }

        return itemOptions.sort((option) => option.value);
    };

    const handleSelectionChanged = (_event: SelectionChangedEvent) => {
        setSelectedRows(gridApi.getSelectedRows());
    };

    const gridOptions = useMemo(
        () => ({
            blockLoadDebounceMillis: 600,
            ...extendGridOptions,
            onCellClicked: (event: CellClickedEvent) => {
                if (event.column.getColId() === 'fileOriginalName') {
                    dispatch(getFileActionCreator(event.data.fileId)).then((response: any) => {
                        FileHelper.downloadOrOpen(response.file);
                    });
                }

                if (extendGridOptions?.onCellClicked) extendGridOptions?.onCellClicked(event);
            },
            columnDefs: extendGridOptions?.columnDefs ?? therapistDocumentColumnDef,
            defaultColDef: {
                resizable: true,
                filterParams: {
                    onFetchFilters: handleFetchFilters,
                },
            },
            rowSelection: 'multiple',
            suppressRowClickSelection: true,
            treeData: true,
            isServerSideGroup: (dataItem: any) => {
                return dataItem?.historyItems?.length > 0;
            },
            getServerSideGroupKey: function (dataItem: any) {
                return dataItem?.historyItems;
            },
            autoGroupColumnDef: {
                headerName: '',
                field: 'ruleName',
                sortable: false,
                lockPosition: true,
                maxWidth: 50,
                minWidth: 50,

                cellRendererParams: {
                    innerRenderer: function (params: any) {
                        return params.data.ruleName;
                    },
                },
            },
            frameworkComponents: {
                doteRender: (props: any) => {
                    const itemOptions = getOptionList(props.data);

                    return (
                        itemOptions.length > 0 && (
                            <InfiniteSelect
                                id="therapistDocumentMenu"
                                popper
                                items={itemOptions}
                                className="no-select-border"
                                icon={<i className="icon icon-options" />}
                                onChange={(item: DropdownOption) => {
                                    onOptionClick(item?.value, props.data);
                                }}
                            />
                        )
                    );
                },
                cellLinkRender: (props: any) => {
                    const cellValue = props.valueFormatted ? props.valueFormatted : props.value;

                    return (
                        !!cellValue && (
                            <Button className="link-button">
                                <span className="icon icon-export" />
                                View
                            </Button>
                        )
                    );
                },
                ...extendGridOptions?.frameworkComponents,
            },
        }),
        [dispatch, extendGridOptions, handleFetchFilters]
    );

    useEffect(() => {
        if (updateTable && gridApi) {
            gridApi.onFilterChanged();
        }
    }, [updateTable, gridApi]);

    // Clear
    const handleFiltersClear = () => {
        setSearch('');
        setFilters({ ...defaultFilters });
    };

    const handleGridReady = (params: GridReadyEvent) => {
        setGridApi(params.api);
        setColumnApi(params.columnApi);
    };

    const onApproveDocumentsClick = () => {
        onApproveTherapistDocumentsClick(
            gridApi
                .getSelectedRows()
                .filter(
                    (document) =>
                        document.status === TherapistDocumentStatus.UnVerified || document.status === TherapistDocumentStatus.Rejected
                )
                .map((document) => document.id)
        );
    };

    const handleFiltersChange = (filterValues: any) => {
        setFilters((prev) => ({ ...prev, ...filterValues }));
    };

    const GetSelectedFileIds = (): number[] => {
        const selectedRows = gridApi?.getSelectedRows();

        return selectedRows?.filter((row) => !!row.fileId)?.map((row) => row.fileId);
    };

    const handleExport = (type: string, _watcher: IParamsWatcher) => {
        switch (type?.toLowerCase()) {
            case ExportType.XLSX:
                return therapistService.exportExpiredDocuments(ExportType.XLSX, therapistId);
            default:
                throw new Error('Not supported');
        }
    };

    const documentExportFileNameGenerator = (_type: string) => {
        return `${therapist.generalInfo.lastName}_${therapist.generalInfo.firstName}_Documents.${ExportType.XLSX.toLowerCase()}`;
    };

    return (
        <>
            <div className="main-info-wrapper ag-table-wrapper">
                <HeaderWrapper>
                    <HeaderLeftSide>
                        <SearchFilterInput title={serachTitle} value={search} onInput={handleSearchInputChange} />
                        <DocumentCategoryFilter filter={filters} onFiltersChange={handleFiltersChange} />
                        <ExportButton
                            title="Expired Document Report"
                            options={exportOptions}
                            classNameWrapper="document-export-wrapper"
                            classNameList="document-export-list"
                            paramsWatcher={paramsWatcher}
                            fileNameGenerator={documentExportFileNameGenerator}
                            onExportData={handleExport}
                        />
                    </HeaderLeftSide>
                    <HeaderRightSide>
                        <DownloadSeveralFilesButton
                            title="Provider Documents"
                            fileIds={GetSelectedFileIds()}
                            customerArchiveName={therapistName}
                        />
                        <ClearFiltersButton
                            title="Provider Documents"
                            gridApi={gridApi}
                            gridColumnApi={gridColumnApi}
                            onClick={handleFiltersClear}
                        />
                        {hasAccessPrivateDocument && <AddButton title="Add Custom Rule" onClick={onAddButtonClick} />}
                        <Button className="base-text-btn blue" onClick={onAddDocumentClick}>
                            Add document
                        </Button>
                        {selectedRows?.some(
                            (row: any) =>
                                row.status === TherapistDocumentStatus.UnVerified || row.status === TherapistDocumentStatus.Rejected
                        ) && (
                            <Button className="base-text-btn blue" onClick={onApproveDocumentsClick}>
                                Approve document
                            </Button>
                        )}
                    </HeaderRightSide>
                </HeaderWrapper>
                <AgTable
                    onGridReady={handleGridReady}
                    gridOptions={gridOptions}
                    customFilters={filters}
                    onFiltersLoadedFromUrl={handleFiltersClear}
                    repository={tableRepository}
                    paramsWatcher={paramsWatcher}
                    onFetchData={handleFetchData}
                    onDataChange={handleOnDataChanged}
                    onSelectionChanged={handleSelectionChanged}
                />
            </div>
        </>
    );
};

const exportOptions = [
    {
        value: 'Xlsx',
        title: 'Expired Document Report',
    },
];

export const therapistDocumentColumnDef = [
    {
        field: 'therapistDocumentCheckbox',
        maxWidth: 50,
        minWidth: 50,
        hideForExport: true,
        sortable: false,
        lockPosition: true,
        selectAllCheckbox: true,
        checkboxSelection: true,
    },
    {
        field: 'ruleName',
        headerName: 'Rule Name',
        filter: 'agCustomFilter',
        width: 350,
    },
    {
        field: 'isCustomRule',
        headerName: 'Custom Document',
        filter: 'agCustomFilter',
        valueFormatter: (props: any) => DisplayValueHelper.convertToYesOrNo(props.value),
        width: 170,
        filterParams: filterParamsBoolean,
    },
    {
        field: 'document',
        headerName: 'Document',
        filter: 'agCustomFilter',
        filterParams: {
            isNullable: true,
        },
        width: 154,
    },
    {
        field: 'issuedDate',
        valueFormatter: (props: any) => DateTimeHelper.format(props.value, dateTimeConstants.MM_DD_YYYY, true),
        headerName: 'Issue Date',
        filter: 'agDateColumnFilter',
        width: 154,
    },
    {
        field: 'expirationDate',
        valueFormatter: (props: any) => DateTimeHelper.format(props.value, dateTimeConstants.MM_DD_YYYY, true),
        headerName: 'Expiration Date',
        filter: 'agDateColumnFilter',
        width: 170,
    },
    {
        field: 'state',
        headerName: 'State',
        filter: 'agCustomFilter',
        width: 168,
        sortable: false,
    },
    {
        field: 'status',
        headerName: 'Status',
        filter: 'agCustomFilter',
        width: 140,
        filterParams: {
            isNullable: true,
        },
    },
    {
        field: 'fileOriginalName',
        headerName: 'File',
        cellRenderer: 'cellLinkRender',
        width: 140,
        sortable: false,
    },
    {
        field: 'comment',
        headerName: 'Internal Comments',
        width: 420,
        sortable: false,
    },
    {
        field: 'uploadedAt',
        valueFormatter: (props: any) => DateTimeHelper.format(props.value, dateTimeConstants.MM_DD_YYYY),
        headerName: 'Date Uploaded',
        filter: 'agDateColumnFilter',
        filterParams: {
            isDateTime: true,
        },
        width: 170,
    },
    {
        field: 'uploadedByUser',
        headerName: 'Uploaded by',
        filter: 'agCustomFilter',
        filterParams: {
            isNullable: true,
        },
        width: 350,
    },
    {
        field: 'reason',
        headerName: 'Reason',
        width: 420,
        sortable: false,
    },
    {
        headerName: '',
        colId: 'doteMenu',
        pinned: 'right',
        cellRenderer: 'doteRender',
        cellClass: ['dote-cell'],
        width: 40,
        hideForExport: true,
    },
];

export default TherapistDocumentTable;
