import React, { useEffect, useMemo, useRef, useState } from 'react';
import { CellClickedEvent, GridReadyEvent } from 'ag-grid-community/dist/lib/events';
import { IParamsWatcher } from 'common/components/ag-table/types';
import { getTableData, getFilterData, getExportData } from 'common/services/api/tabel-fetch-service';
import HeaderWrapper from 'common/components/header/header-wrapper';
import HeaderLeftSide from 'common/components/header/header-left-side';
import SearchFilterInput from 'common/components/header/search-filter-input/search-filter-input';
import ColumnsVisibleFilterSelect from 'common/components/header/сolumns-visible-filter-select/columns-visible-filter-select';
import HeaderRightSide from 'common/components/header/header-right-side';
import ExportButton from 'common/components/header/export-button/export-button';
import ClearFiltersButton from 'common/components/header/clear-filters-button/clear-filters-button';
import AgTable from 'common/components/ag-table/ag-table';
import { LocalStoreRepository } from 'common/helpers/repository/local-store-repository';
import { useSearchInput } from 'common/hooks/use-serch-input';
import { RequestTableProps, ILoggingFilters } from './types';
import { DateTimeHelper } from 'common/helpers/date-time-helper';
import { NavigationRoutes } from 'models/routes/navigation-routes';
import { ICellRendererParams } from 'ag-grid-community';
import { LocalTableRepository } from 'common/helpers/repository/local-table-repository';

const handleFetchData = getTableData('audits');
const handleFetchFilters = getFilterData('audits/filter/source');
const handleExportData = getExportData('audits/export');
const localStoreRepository = new LocalTableRepository('logging', 'v2');
const customFiltersRepository = new LocalStoreRepository('logging-table-custom-filters');
let loadedFilters: ILoggingFilters = customFiltersRepository.load();

const defaultFilters: ILoggingFilters = {
    search: null,
};
const PARENT_ID = 'parentId';

const LoggingTable = ({ onCellClick }: RequestTableProps) => {
    const [gridApi, setGridApi] = useState(null);
    const [gridColumnApi, setColumnApi] = useState(null);
    const [filters, setFilters] = useState(loadedFilters ?? defaultFilters);
    const { search, handleSearchInputChange, setSearch } = useSearchInput(loadedFilters?.search, (value: string) => {
        setFilters((prev) => ({ ...prev, search: value }));
    });
    const paramsWatcher = useRef<IParamsWatcher>();

    useEffect(() => {
        customFiltersRepository.save(filters);
        loadedFilters = filters;
    }, [filters]);

    const gridOptions = useMemo(
        () => ({
            onCellClicked: (event: CellClickedEvent) => {
                const { parent, parentId } = event.data ?? {};
                const isParentIdColumn = parentId && event.column.getColId() === PARENT_ID;

                if (isParentIdColumn && NavigationRoutes.isDetailsRoute(parent)) {
                    NavigationRoutes.openDetailsRoute(parent, parentId);
                } else {
                    onCellClick(event.data.id);
                }
            },
            columnDefs: [
                {
                    field: 'timeStamp',
                    headerName: 'Timestamp',
                    valueFormatter: (props: any) => DateTimeHelper.format(props.value, 'MM/DD/YYYY | hh:mm A'),
                    filter: 'agDateColumnFilter',
                    width: 280,
                    filterParams: {
                        isDateTime: true,
                    },
                    initialSort: 'desc',
                },
                {
                    field: 'user',
                    headerName: 'User',
                    filter: 'agCustomFilter',
                    width: 350,
                },
                {
                    field: 'parent',
                    headerName: 'Parent',
                    filter: 'agCustomFilter',
                    width: 210,
                },
                {
                    field: 'parentId',
                    headerName: 'Parent ID',
                    cellRenderer: 'objectLinkValueRender',
                    filter: 'agCustomFilter',
                    width: 126,
                },
                {
                    field: 'object',
                    headerName: 'Object',
                    filter: 'agCustomFilter',
                    width: 350,
                    filterParams: {
                        connectWithUrl: true,
                    },
                },
                {
                    field: 'objectId',
                    headerName: 'Object ID',
                    filter: 'agCustomFilter',
                    width: 126,
                },
                {
                    field: 'actionType',
                    headerName: 'Action',
                    filter: 'agCustomFilter',
                    width: 100,
                },
            ],
            defaultColDef: {
                resizable: true,
                filterParams: {
                    onFetchFilters: handleFetchFilters,
                },
            },
            frameworkComponents: {
                objectLinkValueRender: (props: ICellRendererParams) => {
                    const cellValue = (props.valueFormatted ? props.valueFormatted : props.value) || '';

                    if (NavigationRoutes.isDetailsRoute(props?.data?.parent)) {
                        return <span className="object-link">{cellValue}</span>;
                    } else {
                        return cellValue;
                    }
                },
            },
        }),
        [onCellClick]
    );

    // Clear
    const handleFiltersClear = () => {
        setSearch('');
        setFilters({ ...defaultFilters });
    };

    const handleGridReady = (params: GridReadyEvent) => {
        setGridApi(params.api);
        setColumnApi(params.columnApi);
    };

    return (
        <>
            <div className="main-info-wrapper ag-table-wrapper">
                <HeaderWrapper>
                    <HeaderLeftSide>
                        <SearchFilterInput title="Logging" value={search} onInput={handleSearchInputChange} />
                        <ColumnsVisibleFilterSelect title="Logging" gridApi={gridApi} gridColumnApi={gridColumnApi} />
                    </HeaderLeftSide>
                    <HeaderRightSide>
                        <ExportButton title="Logging" paramsWatcher={paramsWatcher} onExportData={handleExportData} />
                        <ClearFiltersButton title="Logging" gridApi={gridApi} gridColumnApi={gridColumnApi} onClick={handleFiltersClear} />
                    </HeaderRightSide>
                </HeaderWrapper>
                <AgTable
                    onGridReady={handleGridReady}
                    gridOptions={gridOptions}
                    onFiltersLoadedFromUrl={handleFiltersClear}
                    customFilters={filters}
                    repository={localStoreRepository}
                    paramsWatcher={paramsWatcher}
                    onFetchData={handleFetchData}
                />
            </div>
        </>
    );
};

export default LoggingTable;
