import DropdownOption from 'common/models/dropdown-option';
import React, { useEffect, useState } from 'react';
import InfiniteSelect from 'common/components/infinite-select/infinite-select';
import Tooltip from 'react-tooltip-lite';
import './_status-dropdown.scss';
import { IEmploymentOption } from '../../services/api/therapist/types';
import { IOption } from '../../models/types';

type StatusDropdownProps = {
    currentStatus: string;
    onChange: (item: DropdownOption) => void;
    inactiveReason?: string;
    inactiveValue?: string;
    options?: DropdownOption[];
    convertFunction?: (option: IOption) => DropdownOption;
    fetchData?: () => Promise<IEmploymentOption[]>;
    getClass?: (currentValue: DropdownOption) => string;
};

const StatusDropdown: React.FC<StatusDropdownProps> = ({
    currentStatus,
    onChange,
    inactiveReason,
    inactiveValue,
    fetchData,
    getClass,
    convertFunction,
    options,
}) => {
    const [isDropdownOpen, setIsDropdownOpen] = useState(false);
    const [innerOptions, setInnerOptions] = useState(options);

    useEffect(() => {
        if (fetchData) {
            fetchData().then((data) => {
                setInnerOptions(data.map(convertFunction));
            });
        }
    }, []);

    const handleDropdownOpen = () => {
        setIsDropdownOpen(true);
    };

    const handleDropdownClose = () => {
        setIsDropdownOpen(false);
    };

    const currentStatusOption = innerOptions?.find((i) => i.value === currentStatus || i.label === currentStatus);

    const dropdownStatus = getClass ? getClass(currentStatusOption) : currentStatusOption?.value?.toLowerCase();

    const renderDropDownStatus = () => {
        return (
            <div className={`status-dropdown-wrapper ${dropdownStatus ?? ''}`}>
                <InfiniteSelect
                    items={innerOptions}
                    label="Status"
                    popper
                    value={currentStatusOption}
                    onDropdownOpen={handleDropdownOpen}
                    onDropdownClose={handleDropdownClose}
                    onChange={onChange}
                />
            </div>
        );
    };

    const className = !isDropdownOpen ? 'show tooltip-content-status' : 'hide tooltip-content-status';

    if (inactiveReason && currentStatus === inactiveValue) {
        return (
            <Tooltip
                content={
                    <div className="tooltip-content-status">
                        <span className="tooltip-content-status">{inactiveReason}</span>
                    </div>
                }
                direction="down"
                tipContentClassName={className}
            >
                {renderDropDownStatus()}
            </Tooltip>
        );
    } else {
        return renderDropDownStatus();
    }
};

export default StatusDropdown;
