import DropdownOption from 'common/models/dropdown-option';
import { IUserBaseInfo } from 'common/services/api/user/types';
import userService from 'common/services/api/user/user-service';
import React from 'react';
import PaginationAutocomplete from '../field/pagination-autocomplete/pagination-autocomplete';
import { AssingToDropdownProps } from './types';

import './_styles.scss';

const AssignToDropdown: React.FC<AssingToDropdownProps> = ({ onChange, defaultValue, cleanable = true, disabled }) => {
    const getUserStaffOptions = (term: string, skip: number, take: number): Promise<IUserBaseInfo[]> => {
        return userService.getUserStaffOptions(term, skip, take, true);
    };

    return (
        <div className="general-info-actions-wrapp">
            <span className="label">Assigned to</span>
            <PaginationAutocomplete
                id="assigned-to"
                name="assignedTo"
                onDelete={null}
                placeholder={null}
                cleanable={cleanable}
                tabIndex={null}
                defaultValue={defaultValue}
                onSelect={onChange}
                convertFunction={(item: IUserBaseInfo) => new DropdownOption(item.id, item.fullName)}
                fetchData={getUserStaffOptions}
                className="assign-to-user-dropdown"
                disabled={disabled}
            ></PaginationAutocomplete>
        </div>
    );
};

export default React.memo(AssignToDropdown);
