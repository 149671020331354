import React from 'react';
import PropTypes from 'prop-types';
import InfiniteInput from 'common/components/infinite-input/infinite-input';
import { Link } from 'react-router-dom';

class LoginForm extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            username: '',
            password: '',
            currentErrorMessage: null,
        };

        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    handleChange(e) {
        const { name, value } = e.target;
        this.setState({ [name]: value });

        if (this.props.errorMessage) {
            this.props.onResetFormErrors();
        }
    }

    handleSubmit(e) {
        e.preventDefault();
        this.props.onLogin(this.state.username, this.state.password);
    }

    render() {
        const { username, password } = this.state;
        const { errorMessage } = this.props;

        return (
            <form name="form" className="login-form" onSubmit={this.handleSubmit}>
                <span className="infinite-logo"></span>
                <h4>Login to your Infinite account</h4>
                {errorMessage && <span className="login-error">{errorMessage}</span>}
                <div className="login-input-wrapper">
                    <InfiniteInput
                        placeholder="Username"
                        required={true}
                        name="username"
                        value={username}
                        hasError={errorMessage}
                        autocomplete
                        onChange={this.handleChange}
                    ></InfiniteInput>
                </div>
                <div className="login-input-wrapper">
                    <InfiniteInput
                        type="password"
                        placeholder="Password"
                        required={true}
                        name="password"
                        value={password}
                        hasError={errorMessage}
                        onChange={this.handleChange}
                        autocomplete
                    ></InfiniteInput>
                </div>
                <div>
                    <Link to="/forgot-password" class="form-label">
                        <span className="icon icon-info"></span>
                        <span className="form-label__content">Forgot my password</span>
                    </Link>
                </div>
                <div>
                    <button className="text-button blue login-btn">Login</button>
                </div>
            </form>
        );
    }
}

LoginForm.propTypes = {
    onLogin: PropTypes.func.isRequired,
    errorMessage: PropTypes.string,
    onResetFormErrors: PropTypes.func.isRequired,
};

export default LoginForm;
