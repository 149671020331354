import React from 'react';
import FollowUpsList from './components/follow-ups-list';
import './follow-ups-page.scss';

const FollowUpsPage = () => {
    return (
        <div className="therapist-page request-page">
            <header>
                <div className="header-wrapper">
                    <div className="title-wrapper">
                        <h1>Placement Follow-Ups</h1>
                    </div>
                </div>
            </header>
            <FollowUpsList />
        </div>
    );
};

export default FollowUpsPage;
