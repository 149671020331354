import React, { useContext, useMemo, useState } from 'react';
import { RequestBasicInfoTabProp } from '../../types';
import { Card, CardWrapper } from 'common/components/card';
import { ICardConfig } from 'common/components/card/types';
import { IRequestAuthorization, IRequestCaseDetails, IRequestEditPatient } from '../../../request-forms/types';
import RequestAuthorizationSidebar from '../../../request-forms/request-authorization-sidebar';
import { RequestDetailsContext } from '../../request-details-page';
import RequestService from 'common/services/api/requests/request-service';
import { trackPromise } from 'react-promise-tracker';
import { IAuthorization, IFile, RequestStatus } from 'common/services/api/requests/types';
import { useDispatch } from 'react-redux';
import { getFileActionCreator } from 'features/document-viewer/store/document-viewer-action-creators';
import { FileHelper } from 'common/helpers/file-helper';
import { ThunkDispatch } from 'redux-thunk';
import { Action } from '@reduxjs/toolkit';
import moment from 'moment';
import { dateTimeConstants, getEnumOptionTitle } from 'common/constants/common';
import RequestCaseSidebar from '../../../request-forms/request-case-sidebar';
import RequestPatientSidebar from '../../../request-forms/request-patient/request-patient-sidebar';
import { NavigationRoutes } from 'models/routes/navigation-routes';
import TherapistAssignDialog from './thrapist-assign-dialog/thrapist-assign-dialog';
import { RequestType } from 'common/constants/types';
import AssignedTherapistTable from 'common/components/assigned-therapist-list/assigned-therapist-table';
import './request-basic-info-tab.scss';
import ActionButton from 'common/components/card/parts/action-button/action-button';
import { toast } from 'react-toastify';
import { useIsUserType } from 'common/hooks/use-is-user-type';
import { getTableData } from 'common/services/api/tabel-fetch-service';
import { TherapistRequestStatus } from 'common/components/assigned-therapist-list/types';
import { homeCareIconArray } from 'common/constants/map/map';
import { departmentKey } from 'common/constants/department-key';
import { ErrorCodes } from 'common/constants/error-codes';
import { hasAddress } from 'common/helpers/address-helper';
import { IEntityAddress } from 'common/services/api/entity/types';
import DisplayValue from 'common/components/display-value/display-value';
import { useResponsiveLayout } from 'common/hooks/use-responsive-layout';

const convertDateToFormat = (date: string) => moment(date).format(dateTimeConstants.MM_DD_YYYY);

const RequestBasicInfoTab: React.FC<RequestBasicInfoTabProp> = ({ requestId, requestDetails }) => {
    const { patientDetails } = requestDetails;
    const [isAuthorizationBarOpen, toggleAuthorizationBar] = useState(false);
    const [isCaseBarOpen, toggleCaseBar] = useState(false);
    const [isPatientBarOpen, togglePatientBar] = useState(false);
    const [isTherapistAssignOpen, toggleTherapistAssign] = useState(false);
    const { setRequestDetails, updateDetailsPage } = useContext(RequestDetailsContext);
    const dispatch = useDispatch<ThunkDispatch<any, any, Action>>();
    const { isExternal } = useIsUserType();
    const isMedA = departmentKey.HCA === requestDetails?.department?.key;
    const isMedB = departmentKey.HCB === requestDetails?.department?.key;
    const isHold = requestDetails?.status === RequestStatus.Hold;

    const renderFiles = (value: any, _data: IAuthorization) => {
        return (
            <>
                {value.map((file: IFile) => {
                    return (
                        <div className={'card-item'}>
                            <div className={`card__text card-files`}>{file?.originalName}</div>
                            <div
                                className="card__item-icon"
                                onClick={() => {
                                    dispatch(getFileActionCreator(file?.id)).then((response) => {
                                        FileHelper.downloadOrOpen(response.file);
                                    });
                                }}
                            >
                                <i className={`icon icon-file`} />
                            </div>
                        </div>
                    );
                })}
            </>
        );
    };

    const authorizationConfig: ICardConfig[] = [
        { title: 'Authorization Start Date', field: 'startDate', render: convertDateToFormat, hide: isMedB },
        { title: 'Authorization End Date', field: 'endDate', render: convertDateToFormat, hide: isMedB },
        { field: 'files', title: 'Authorization Form', className: 'authorization-files', render: renderFiles, hide: isMedB },
        { title: 'Authorized', field: 'isAuthorized', render: (isAuthorized) => (isAuthorized ? 'Yes' : 'No'), hide: isMedB },
        { title: 'Eval ID', field: 'evalId', hide: isExternal },
    ];

    const facilityAddress: IEntityAddress = requestDetails?.facility?.address;
    const patientDetailsConfig: ICardConfig[] = [
        {
            title: 'Patient Name',
            field: 'fullName',
            className: 'object-link-with-icon',
            onClick: (value: any, data: any) =>
                data.id ? window.open(NavigationRoutes.patientDetailsRoute(data.id), '_blank').focus() : null,
        },
        { title: 'Patient ID', field: 'patientAgencyCode' },
        { title: 'Legacy Patient ID', field: 'legacyId' },
        {
            title: 'Address 1',
            field: 'address1',
            rightIcon: 'icon-map',
            renderEmpty: true,
            render: (value) =>
                (hasAddress(patientDetails)
                    ? value
                    : hasAddress(patientFacilityAddress)
                    ? patientFacilityAddress?.address1
                    : facilityAddress?.address1) ?? '-',
        },
        {
            title: 'Address 2',
            field: 'address2',
            width: '50%',
            renderEmpty: true,
            render: (value) =>
                (hasAddress(patientDetails)
                    ? value
                    : hasAddress(patientFacilityAddress)
                    ? patientFacilityAddress?.address2
                    : facilityAddress?.address2) ?? '-',
        },
        {
            title: 'City',
            field: 'city',
            width: '50%',
            renderEmpty: true,
            render: (value) =>
                (hasAddress(patientDetails)
                    ? value
                    : hasAddress(patientFacilityAddress)
                    ? patientFacilityAddress?.city
                    : facilityAddress?.city) ?? '-',
        },
        {
            title: 'State',
            field: 'state',
            width: '50%',
            renderEmpty: true,
            render: (state) =>
                (hasAddress(patientDetails)
                    ? state?.name
                    : hasAddress(patientFacilityAddress)
                    ? patientFacilityAddress?.state?.name
                    : facilityAddress?.state?.name) ?? '-',
        },
        {
            title: 'Zip',
            field: 'zip',
            width: '50%',
            renderEmpty: true,
            render: (zip) =>
                (hasAddress(patientDetails)
                    ? zip
                    : hasAddress(patientFacilityAddress)
                    ? patientFacilityAddress?.postalCode
                    : facilityAddress?.postalCode) ?? '-',
        },
    ];

    const caseDetailsConfig: ICardConfig[] = [
        { title: 'Department', field: 'department', render: (value) => value?.name },
        { title: 'Therapy Type', field: 'therapyType', render: getEnumOptionTitle },
        { title: 'Special Instructions', field: 'specialInstructions' },
        { title: 'Source', field: 'source' },
        {
            title: 'Customer',
            hideIfEmpty: isExternal,
            field: 'customer',
            className: 'object-link-with-icon',
            render: getEnumOptionTitle,
            onClick: (value: any, data: any) => {
                return data.customer ? window.open(NavigationRoutes.entityDetailsRoute(data.customer.id), '_blank').focus() : null;
            },
        },
        {
            title: 'Facility',
            field: 'facility',
            className: 'object-link-with-icon',
            render: getEnumOptionTitle,
            onClick: (value: any, data: any) =>
                data.facility ? window.open(NavigationRoutes.entityDetailsRoute(data.facility.id), '_blank').focus() : null,
        },
        { title: 'Population', field: 'population', render: getEnumOptionTitle },
        { title: 'Language', field: 'language', render: getEnumOptionTitle },
        {
            title: 'Primary Insurance',
            hideIfEmpty: isExternal,
            field: 'primaryInsurance',
            render: (value) => value.name,
        },
        {
            title: 'Secondary Insurance',
            hideIfEmpty: isExternal,
            field: 'secondaryInsurance',
            render: (value) => value.name,
        },
        // {
        //     title: 'Medical Assistance Program',
        //     hideIfEmpty: isExternal,
        //     field: 'medicalAssistanceProgram',
        //     render: (value) => medicalAssistanceProgram.find((item) => item.value === value.id).label,
        // },
        {
            title: 'Internal Referral Source',
            hideIfEmpty: isExternal,
            field: 'internalReferralSource',
            render: (value) => value.fullName ?? value,
        },
        {
            title: 'External Referral Source',
            hideIfEmpty: isExternal,
            field: 'externalReferralSource',
            render: getEnumOptionTitle,
        },
        { title: 'Case manager', hideIfEmpty: isExternal, field: 'caseManager', render: getEnumOptionTitle },
    ];

    const openAvailableTherapistList = () => {
        toggleTherapistAssign(true);
    };

    // Authorization Bar
    const handleClickOnAuthorizationEdit = () => {
        toggleAuthorizationBar(true);
    };

    const handleClickAuthorizationClose = () => {
        toggleAuthorizationBar(false);
    };

    const handleClickAuthorizationSave = (data: IRequestAuthorization) => {
        return trackPromise(RequestService.updateAuthorization(requestId, data)).then((data) => {
            toggleAuthorizationBar(false);
            if (setRequestDetails) {
                setRequestDetails(data);
            }
        });
    };

    // Case Details Bar
    const handleClickOnCaseEdit = () => {
        toggleCaseBar(true);
    };

    const handleClickCaseClose = () => {
        toggleCaseBar(false);
    };

    const handleClickCaseSave = async (data: IRequestCaseDetails) => {
        try {
            return RequestService.updateCaseDetails(requestId, data).then(() => {
                toggleCaseBar(false);
                if (setRequestDetails) {
                    setRequestDetails(null);
                }
            });
        } catch (errorMessage) {
            toast.error(errorMessage);
        }
    };

    // Patient Details Bar
    const handleClickOnPatientEdit = () => {
        togglePatientBar(true);
    };

    const handleClickPatientEditClose = () => {
        togglePatientBar(false);
    };

    const handleClickPatientEditSave = (data: IRequestEditPatient) => {
        return RequestService.attachPatient(requestId, data.patientId, data.patientAgencyCode).then(() => {
            togglePatientBar(false);
            if (setRequestDetails) {
                setRequestDetails(null);
            }
        });
    };

    const handleAddTherapistToRequest = (requestId: number, therapistIds: number[]) => {
        RequestService.addTherapistToRequest({
            requestId,
            therapistIds,
        }).then(() => {
            setRequestDetails(null);
            toggleTherapistAssign(false);
        });
    };

    const handleFetchData = useMemo(() => {
        const requestUrl: string = `requests/${requestId}/therapists`;

        return getTableData(isExternal ? `${requestUrl}?status=${TherapistRequestStatus.Assigned}` : requestUrl);
    }, [isExternal, requestId]);

    // Facility address
    const requestFacilityAddress = requestDetails?.facility?.address;
    const facilityHeaderInfo = () => {
        return (
            <>
                <span className="rh-info__caption">Facility: </span> {requestFacilityAddress.address1} {requestFacilityAddress.address2}{' '}
                {requestFacilityAddress.city}, {requestFacilityAddress.state?.name} {requestFacilityAddress.postalCode}
            </>
        );
    };
    const transformFacilityAddress = (facilityAddress: IEntityAddress) => {
        return (
            (facilityAddress?.address1 || facilityAddress?.postalCode) && {
                address1: facilityAddress.address1,
                city: facilityAddress.city,
                state: facilityAddress.state,
                zip: facilityAddress.postalCode,
            }
        );
    };

    // Request Address
    const requestHeaderInfo = () => {
        const { address1, address2, city, state, postalCode } = requestDetails?.address ?? {};
        const textBeforeState = address1?.length | address2?.length | city?.length ? ', ' : ' ';

        return (
            <>
                <span className="rh-info__caption">Request Address: </span>
                {address1 ?? ''} {address2 ?? ''} {city ?? ''}
                {textBeforeState}
                {state?.name ?? ''} {postalCode ?? ''}
            </>
        );
    };

    const specialInstractionHeadrerInfo = () => {
        const { specialInstructions } = requestDetails ?? {};

        return (
            <>
                <span className="rh-info__instructionBody">
                    <span className="rh-info__instructionTitle">Special Instructions </span>
                    <div>{specialInstructions}</div>
                </span>
            </>
        );
    };

    const renderHeaderInfo = () => {
        const addressHeaderInfo =
            (requestDetails?.facility?.id && facilityHeaderInfo) ||
            (hasAddress(patientDetails) && patientHeaderInfo) ||
            (patientDetails?.facility?.id && patientFacilityHeaderInfo) ||
            (hasAddress(requestDetails?.address) && requestHeaderInfo);

        return (
            <>
                <span>{addressHeaderInfo()}</span>
                {specialInstractionHeadrerInfo()}
            </>
        );
    };

    const transformRequestAddress = () => {
        if (!hasAddress(requestDetails?.address)) {
            return null;
        }

        return {
            address1: requestDetails?.address?.address1,
            city: requestDetails?.address?.city,
            state: requestDetails?.address?.state,
            zip: requestDetails?.address?.postalCode ?? requestDetails?.address?.zip,
        };
    };

    // Patient Address
    const patientHeaderInfo = () => {
        return (
            <>
                <span className="rh-info__caption">Patient ID: </span> {patientDetails.patientAgencyCode}
                <span className="rh-info__caption">Name: </span> {patientDetails.fullName}
                <span className="rh-info__caption">Address: </span>
                {patientDetails.address1} {patientDetails.address2} {patientDetails.city}, {patientDetails.state?.name} {patientDetails.zip}
            </>
        );
    };

    const transformPatientAddress = () => {
        if (!hasAddress(patientDetails)) {
            return null;
        }

        return (
            patientDetails?.id && {
                address1: patientDetails.address1,
                city: patientDetails.city,
                state: patientDetails.state,
                zip: patientDetails.zip,
            }
        );
    };

    // Patient facility address
    const patientFacilityAddress = patientDetails?.facility?.address;
    const patientFacilityHeaderInfo = () => {
        return (
            <>
                <span className="rh-info__caption">Patient ID: </span> {patientDetails.patientAgencyCode}
                <span className="rh-info__caption">Name: </span> {patientDetails.fullName}
                <span className="rh-info__caption">Address: </span>
                {patientFacilityAddress?.address1} {patientFacilityAddress?.address2} {patientFacilityAddress?.city},{' '}
                {patientFacilityAddress?.state?.name} {patientFacilityAddress?.postalCode}
            </>
        );
    };

    const handleChangeStatusError = (error: any) => {
        const errorCode = error?.response?.data?.errorCode;

        if (errorCode === ErrorCodes.THERAPIST_NOT_AUTHORIZED_FOR_DEPARTMENT) {
            toast.error(`Provider is not authorized for ${requestDetails?.department?.name ?? '"undefined department"'}`);
        } else {
            toast.error(error?.response?.data?.errorMessage || 'Some error occurred');
        }
    };

    // Layout Calculation
    const { mediaClass } = useResponsiveLayout({
        contentWrapper: '.request-details-base-info-tab',
        onResize: (width, contentWidth) => {
            let className = '';
            if (width <= 1890 || contentWidth <= 1619) {
                className = 'w-1890';
            }
            if (width <= 1426 || contentWidth <= 1155) {
                className = 'w-1426';
            }
            if (width <= 1260 || contentWidth <= 989) {
                className = 'w-1260';
            }
            if (width <= 850 || contentWidth <= 579) {
                className = 'w-850';
            }

            return className;
        },
    });

    return (
        <div className="request-details-base-info-tab">
            {isTherapistAssignOpen && (
                <TherapistAssignDialog
                    toggleTherapistAssign={toggleTherapistAssign}
                    headerInfo={renderHeaderInfo}
                    iconArray={homeCareIconArray}
                    addressDetails={
                        transformFacilityAddress(requestFacilityAddress) ||
                        transformPatientAddress() ||
                        transformFacilityAddress(patientFacilityAddress) ||
                        transformRequestAddress()
                    }
                    requestDetails={requestDetails}
                    requestType={RequestType.HomeCare}
                    onAddTherapistToRequest={handleAddTherapistToRequest}
                />
            )}
            <CardWrapper className={`request-grid-wrapper ${mediaClass}`}>
                <Card
                    titleIcon="icon-job"
                    title="Case Details"
                    className="request-case-details-card box-1"
                    defaultValue="-"
                    config={caseDetailsConfig}
                    onEdit={isHold || isExternal || !isMedA ? null : handleClickOnCaseEdit}
                    data={requestDetails}
                >
                    <DisplayValue id="request-address1" placeholder="Address1" value={requestDetails.address?.address1 ?? '-'} />
                    <DisplayValue id="request-address2" placeholder="Address2" value={requestDetails.address?.address2 ?? '-'} />
                    <div className="two-column">
                        <DisplayValue id="request-city" placeholder="City" value={requestDetails.address?.city ?? '-'} />
                        <DisplayValue id="request-county" placeholder="County" value={requestDetails.address?.county?.name ?? '-'} />
                    </div>
                    <div className="two-column">
                        <DisplayValue id="request-state" placeholder="State" value={requestDetails.address?.state?.name ?? '-'} />
                        <DisplayValue id="request-zip" placeholder="Zip" value={requestDetails.address?.postalCode ?? '-'} />
                    </div>
                </Card>
                <Card
                    className="request-authorization-details-card box-2"
                    titleIcon="icon-authorization"
                    title="Authorization"
                    data={requestDetails?.authorization}
                    defaultValue="-"
                    config={authorizationConfig}
                    iconText={isExternal ? 'Create' : 'Edit'}
                    onEdit={isHold || (isExternal && requestDetails?.authorization) ? null : handleClickOnAuthorizationEdit}
                />
                <Card
                    className="request-patient-details-card box-3"
                    titleIcon="icon-person"
                    title="Patient Details"
                    defaultValue="-"
                    config={patientDetailsConfig}
                    onEdit={isHold || isExternal || isMedB ? null : handleClickOnPatientEdit}
                    data={patientDetails}
                />
                <Card
                    className="request-therapist-details-card assigned-therapist-table box-4"
                    titleIcon="icon-technician"
                    title="Provider Assignment"
                    defaultValue="-"
                    actionButtons={<>{!isHold && !isExternal && <ActionButton icon="icon-add" onClick={openAvailableTherapistList} />}</>}
                >
                    <AssignedTherapistTable
                        requestId={requestId}
                        hideDoteMenu={isExternal || isHold}
                        onFetchData={handleFetchData}
                        onChangeStatus={(requestId, therapistRequestId, statusForm) =>
                            RequestService.setStatusToTherapistRequest(requestId, therapistRequestId, statusForm)
                                .then(updateDetailsPage)
                                .catch(handleChangeStatusError)
                        }
                        onSaveNote={RequestService.addNoteToTherapistRequest}
                    />
                </Card>
            </CardWrapper>
            {isAuthorizationBarOpen && (
                <RequestAuthorizationSidebar
                    data={requestDetails}
                    onClose={handleClickAuthorizationClose}
                    onSave={handleClickAuthorizationSave}
                />
            )}
            {isCaseBarOpen && <RequestCaseSidebar data={requestDetails} onClose={handleClickCaseClose} onSave={handleClickCaseSave} />}
            {isPatientBarOpen && (
                <RequestPatientSidebar data={requestDetails} onClose={handleClickPatientEditClose} onSave={handleClickPatientEditSave} />
            )}
        </div>
    );
};

export default RequestBasicInfoTab;
