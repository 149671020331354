import React, { useState } from 'react';
import NoteList from 'common/components/note-list/note-list';
import { NoteTabProps } from './types';
import CreateNoteDialog from 'common/components/create-note-dialog/create-note-dialog';
import { ICreateNoteRequest } from 'common/models/create-note-request';
import patientNoteService from 'common/services/api/patient-note/patient-note-service';
import noteService from 'common/services/api/note/note-service';
import { useDispatch } from 'react-redux';
import { refreshNoteStatus } from 'app/store/notes/action-creators';

const NoteTab: React.FC<NoteTabProps> = ({ patientId }) => {
    const dispach = useDispatch();
    const [isNewNoteDialogOpen, setIsNewNoteDialogOpen] = useState(false);
    const [forceUpdate, setForceUpdate] = useState(0);

    const handleClickOnNewNoteButton = () => {
        setIsNewNoteDialogOpen(true);
    };

    const handleClickOnCloseNewNoteDialog = () => {
        setIsNewNoteDialogOpen(false);
    };

    const handleClickOnSaveNote = (request: ICreateNoteRequest) => {
        return patientNoteService.create(patientId, request).then((_) => {
            handleClickOnCloseNewNoteDialog();
            setForceUpdate((prev) => prev + 1);
            dispach(refreshNoteStatus());
        });
    };

    const handleClickOnCompleteNote = (id: number) => {
        noteService.complete(id).then(() => {
            setForceUpdate((prev) => prev + 1);
            dispach(refreshNoteStatus());
        });
    };

    return (
        <div className="notes-tab content-flex">
            <NoteList
                absoluteShadow
                url={`patients/${patientId}/notes`}
                onCompleteNoteClick={handleClickOnCompleteNote}
                forceUpdate={forceUpdate}
                onNewClick={handleClickOnNewNoteButton}
            />
            {isNewNoteDialogOpen && <CreateNoteDialog onCancel={handleClickOnCloseNewNoteDialog} onSave={handleClickOnSaveNote} />}
        </div>
    );
};

export default NoteTab;
