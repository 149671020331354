import React, { useEffect, useMemo, useRef, useState } from 'react';
import { DisplayValueHelper } from 'common/helpers/display-value-helper';
import { filterParamsBoolean } from 'common/components/ag-table/filters/constants/filter-params-boolean';
import { DocumentsTableProps } from './types';
import { getTableData, getFilterData, getExportData } from 'common/services/api/tabel-fetch-service';
import { GridApi, ColumnApi, GridReadyEvent, CellClickedEvent } from 'ag-grid-community';
import { IDocumentsFilters } from '../../types';
import { IParamsWatcher } from 'common/components/ag-table/types';
import { useSearchInput } from 'common/hooks/use-serch-input';
import moment from 'moment';
import HeaderLeftSide from 'common/components/header/header-left-side';
import HeaderRightSide from 'common/components/header/header-right-side';
import HeaderWrapper from 'common/components/header/header-wrapper';
import SearchFilterInput from 'common/components/header/search-filter-input/search-filter-input';
import ColumnsVisibleFilterSelect from 'common/components/header/сolumns-visible-filter-select/columns-visible-filter-select';
import ExportButton from 'common/components/header/export-button/export-button';
import ClearFiltersButton from 'common/components/header/clear-filters-button/clear-filters-button';
import AgTable from 'common/components/ag-table/ag-table';
import SortFilterSelect from 'common/components/header/sort-filter-select/sort-filter-select';
import { LocalStoreRepository } from 'common/helpers/repository/local-store-repository';
import { ILoggingFilters } from 'features/logging-page/components/logging-table/types';
import { LocalTableRepository } from 'common/helpers/repository/local-table-repository';

const handleFetchData = getTableData('documents');
const handleFetchFilters = getFilterData('documents/filter/source');
const handleExportData = getExportData('documents/export');
const convertDateToFormat = (data: string, format: string): string => moment(data).local().format(format);

const localStoreRepository = new LocalTableRepository('documents-table', 'v2');
const customFiltersRepository = new LocalStoreRepository('documents-table-custom-filters');
let loadedFilters: ILoggingFilters = customFiltersRepository.load();

const defaultFilters: IDocumentsFilters = {
    search: null,
};

const DocumentsTable: React.FC<DocumentsTableProps> = ({ onDocumentClick, updateDocuments, onDataLoaded }) => {
    const [gridApi, setGridApi] = useState<GridApi>(null);
    const [gridColumnApi, setColumnApi] = useState<ColumnApi>(null);
    const [filters, setFilters] = useState(loadedFilters ?? defaultFilters);
    const { search, handleSearchInputChange, setSearch } = useSearchInput(loadedFilters?.search, (value: string) => {
        setFilters((prev) => ({ ...prev, search: value }));
    });
    const paramsWatcher = useRef<IParamsWatcher>();
    const resetSortByFilter = useRef<Function>(null);

    useEffect(() => {
        if (updateDocuments && gridApi) {
            gridApi.onFilterChanged();
        }
    }, [updateDocuments, gridApi]);

    useEffect(() => {
        customFiltersRepository.save(filters);
        loadedFilters = filters;
    }, [filters]);

    const getRows = (api: GridApi) => {
        const rows: Array<any> = [];
        api?.forEachNode((node) => rows.push(node.data));

        return rows;
    };

    const gridOptions = useMemo(
        () => ({
            onCellClicked: (event: CellClickedEvent) => {
                onDataLoaded(getRows(event.api));
                onDocumentClick(event.data, event.rowIndex);
            },
            columnDefs: [
                {
                    field: 'name',
                    headerName: 'Name',
                    filter: 'agCustomFilter',
                    initialSort: 'asc',
                    width: 350,
                },
                {
                    field: 'documentType',
                    headerName: 'Type',
                    accessor: 'documentType',
                    width: 154,
                    filter: 'agCustomFilter',
                },
                {
                    field: 'isExpirationDateRequired',
                    headerName: 'Expiration Date',
                    filter: 'agCustomFilter',
                    width: 170,
                    valueFormatter: (props: any) => DisplayValueHelper.convertToYesOrNo(props.value),
                    filterParams: filterParamsBoolean,
                },
                {
                    field: 'expirationPeriod',
                    headerName: 'Expiration Period',
                    width: 180,
                    valueFormatter: (props: any) => DisplayValueHelper.monthsPeriod(props.value),
                    filter: 'agCustomFilter',
                    filterParams: {
                        isNullable: true,
                    },
                },
                {
                    field: 'isIssueDateRequired',
                    headerName: 'Issue Date',
                    filter: 'agCustomFilter',
                    width: 130,
                    valueFormatter: (props: any) => DisplayValueHelper.convertToYesOrNo(props.value),
                    filterParams: filterParamsBoolean,
                },
                {
                    field: 'needsApprove',
                    headerName: 'Should be Approved',
                    filter: 'agCustomFilter',
                    width: 209,
                    valueFormatter: (props: any) => DisplayValueHelper.convertToYesOrNo(props.value),
                    filterParams: filterParamsBoolean,
                },
                {
                    field: 'comment',
                    headerName: 'Internal Comments',
                    width: 420,
                },
                {
                    field: 'createdAt',
                    headerName: 'Date Created',
                    width: 155,
                    valueFormatter: (props: any) => convertDateToFormat(props.value, 'MM/DD/YYYY'),
                    filter: 'agDateColumnFilter',
                    filterParams: {
                        isDateTime: true,
                    },
                },
                {
                    field: 'createdBy',
                    headerName: 'User',
                    width: 350,
                    filter: 'agCustomFilter',
                    filterParams: {
                        isNullable: true,
                    },
                },
            ],
            defaultColDef: {
                resizable: true,
                filterParams: {
                    onFetchFilters: handleFetchFilters,
                },
            },
        }),
        [onDocumentClick, onDataLoaded]
    );

    // Clear
    const onFiltersClear = () => {
        setSearch('');
        setFilters({ ...defaultFilters });
        if (resetSortByFilter.current) {
            resetSortByFilter.current();
        }
    };

    const onGridReady = (params: GridReadyEvent) => {
        setGridApi(params.api);
        setColumnApi(params.columnApi);
    };

    return (
        <>
            <div className="main-info-wrapper ag-table-wrapper">
                <HeaderWrapper>
                    <HeaderLeftSide>
                        <SearchFilterInput title="Documents" value={search} onInput={handleSearchInputChange} />
                        <SortFilterSelect
                            title="Documents"
                            gridApi={gridApi}
                            gridColumnApi={gridColumnApi}
                            resetFilter={resetSortByFilter}
                        />
                        <ColumnsVisibleFilterSelect title="Documents" gridApi={gridApi} gridColumnApi={gridColumnApi} />
                    </HeaderLeftSide>
                    <HeaderRightSide>
                        <ExportButton title="Documents" paramsWatcher={paramsWatcher} onExportData={handleExportData} />
                        <ClearFiltersButton title="Documents" gridApi={gridApi} gridColumnApi={gridColumnApi} onClick={onFiltersClear} />
                    </HeaderRightSide>
                </HeaderWrapper>
                <AgTable
                    onGridReady={onGridReady}
                    gridOptions={gridOptions}
                    customFilters={filters}
                    paramsWatcher={paramsWatcher}
                    onFetchData={handleFetchData}
                    repository={localStoreRepository}
                />
            </div>
        </>
    );
};

export default DocumentsTable;
