import NotePage from 'common/components/note-page/note-page';
import Tab from 'common/components/tabs/tab';
import Tabs from 'common/components/tabs/tabs';
import { ICreateNoteRequest } from 'common/models/create-note-request';
import leadService from 'common/services/api/lead/lead-service';
import noteService from 'common/services/api/note/note-service';
import React from 'react';
import { GeneralInfoTabProps } from './types';

const GeneralInfoTab: React.FC<GeneralInfoTabProps> = ({ lead }) => {
    const handleClickOnSaveNote = (request: ICreateNoteRequest) => {
        return leadService.createNote(lead.id, request);
    };

    const handleClickOnCompleteNote = (id: number) => {
        return noteService.complete(id);
    };

    return (
        <>
            <Tabs orientation="vertical" parentTabUrl="general-info">
                <Tab id="notes" linkClassName={'custom-link'} text="Notes">
                    <NotePage
                        url={`leads/${lead.id}/notes`}
                        onCompleteNoteClick={handleClickOnCompleteNote}
                        onSave={handleClickOnSaveNote}
                    />
                </Tab>
            </Tabs>
        </>
    );
};

export default React.memo(GeneralInfoTab);
