import FieldSet from 'common/components/field-set/field-set';
import CheckBoxField from 'common/components/field/checkbox-field';
import DateField from 'common/components/field/date-field';
import DropdownAutocomplete from 'common/components/field/dropdown/dropdown-autocomplete';
import InputField from 'common/components/field/input-field';
import PaginationAutocomplete from 'common/components/field/pagination-autocomplete/pagination-autocomplete';
import InfiniteSelect from 'common/components/infinite-select/infinite-select';
import RightSideBar from 'common/components/right-side-bar/right-side-bar';
import { OptionField } from 'common/constants/option-fields';
import { ConverterHelper } from 'common/helpers/converter-helper';
import { DateTimeHelper } from 'common/helpers/date-time-helper';
import DropdownOption from 'common/models/dropdown-option';
import { FieldValue } from 'common/models/field-value';
import { IOption } from 'common/models/types';
import BaseService from 'common/services/api/base/base-service';
import DepartmentService from 'common/services/api/department/department-service';
import { IAddEditDocumentRuleRequest } from 'common/services/api/document-rule/types';
import { DocumentService } from 'common/services/api/document/document-service';
import EntityService from 'common/services/api/entity/entity-service';
import { IEntityOption } from 'common/services/api/entity/types';
import { PayTypes } from 'models/enums/pay-types';
import React, { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { AddEditDocumentRuleDialogProps, ErrorCodes } from './types';

// TO-DO: Set tab indexes on the form
const AddEditDocumentRuleDialog: React.FC<AddEditDocumentRuleDialogProps> = ({ title, defaultDocumentRule, onCancel, onSave }) => {
    const [documentRule, setDocumentRule] = useState<IAddEditDocumentRuleRequest>({
        name: defaultDocumentRule?.name,
        effectiveDate: defaultDocumentRule?.effectiveDate ? defaultDocumentRule.effectiveDate : undefined,
        endDate: defaultDocumentRule?.endDate ? new Date(defaultDocumentRule.endDate) : DateTimeHelper.getDefaultEndDate(),
        payType: defaultDocumentRule?.payType,
        isOverrideAllow: defaultDocumentRule?.isOverrideAllow,
        isOrientationRelated: defaultDocumentRule?.isOrientationRelated,
        isComplianceRelated: defaultDocumentRule?.isComplianceRelated,
        departmentId: defaultDocumentRule?.department?.id,
        department: defaultDocumentRule?.department ? ConverterHelper.departmentConvert(defaultDocumentRule?.department) : undefined,
        subDepartmentId: defaultDocumentRule?.subDepartment?.id,
        subDepartment: defaultDocumentRule?.subDepartment ? ConverterHelper.optionConvert(defaultDocumentRule?.subDepartment) : undefined,
        disciplineId: defaultDocumentRule?.discipline?.id,
        customerId: defaultDocumentRule?.customer?.id,
        documentOptions: defaultDocumentRule?.acceptableDocuments?.map((item) => ConverterHelper.optionConvert(item)) || [],
        isPublic: defaultDocumentRule?.isPublic,
    });

    const pageSize = 20;
    const [errorData, setErrorData] = useState({});

    const [documentOptions, setDocumentOptions] = useState([]);
    const [documentsPageIndex, setDocumentsPageIndex] = useState(1);
    const [documentTerm, setDocumentTerm] = useState('');

    const payTypeOptions = Object.values(PayTypes).map((i) => new DropdownOption(i.value, i.label));

    const handleScrollDocumentsSelect = (): void => {
        const index = documentsPageIndex + 1;
        setDocumentsPageIndex(index);
        new DocumentService().getAll({ search: documentTerm, pageIndex: index, limit: pageSize }).then((documents) => {
            setDocumentOptions([...documentOptions, ...documents.map((item) => new DropdownOption(item.id, item.name))]);
        });
    };

    useEffect(() => {
        new DocumentService().getAll({ search: documentTerm, pageIndex: 1, limit: pageSize }).then((documents) => {
            setDocumentOptions([...documents.map((item) => new DropdownOption(item.id, item.name))]);
        });
    }, [documentTerm]);

    const handleFieldChange = (data: FieldValue) => {
        setDocumentRule((prevDocument) => ({ ...(prevDocument as IAddEditDocumentRuleRequest), [data.name]: data.value }));
        setErrorData((prevError) => ({ ...prevError, [data.name]: undefined }));
    };

    const handleClickOnSave = () => {
        onSave({
            ...documentRule,
            documentIds: documentRule.documentOptions?.map((item) => item.value),
            departmentId: documentRule.department?.value,
            subDepartmentId: documentRule.subDepartment?.value,
        }).catch((error) => {
            switch (error?.response?.data?.errorCode) {
                case ErrorCodes.InvalidDisciplineOrDepartment:
                    toast.error('DEPARTMENT OR DISCIPLINE MUST NOT BE EMPTY.');
                    break;
                default:
                    setErrorData(error.response.data);
            }
        });
    };

    const getSubDepartmentOptions = (term: string, skip: number, take: number, fieldId: string): Promise<IOption[]> => {
        return BaseService.getOptions(term, skip, take, fieldId, [documentRule.department.value]);
    };

    const getCustomerOptions = (term: string, skip: number, take: number): Promise<IEntityOption[]> => {
        return EntityService.getEntityOptions(term, skip, take);
    };

    return (
        <div>
            <RightSideBar title={title} onCancel={onCancel} onSave={handleClickOnSave}>
                <div className="edit-therapist-form">
                    <FieldSet name="name" errors={errorData} customClass="edit-therapist-field required-field-star">
                        <InputField placeholder="Name" name="name" onChange={handleFieldChange} value={documentRule?.name}></InputField>
                    </FieldSet>
                    <FieldSet errors={errorData} name="documentIds" customClass="edit-therapist-field document-field required-field-star">
                        <DropdownAutocomplete
                            id="documentIds"
                            items={documentOptions}
                            label="Documents"
                            multiselect={true}
                            cleanable={false}
                            searchPlaceholder={'Search Documents'}
                            value={documentRule.documentOptions}
                            onChange={setDocumentTerm}
                            onSelect={(items: DropdownOption) => handleFieldChange({ name: 'documentOptions', value: items })}
                            onScrollEnd={handleScrollDocumentsSelect}
                        ></DropdownAutocomplete>
                    </FieldSet>
                    <FieldSet
                        name="effectiveDate"
                        errors={errorData}
                        customClass="edit-therapist-field date-picker-field required-field-star"
                    >
                        <DateField
                            id="effectiveDate"
                            placeholder="Effective Date"
                            name="effectiveDate"
                            onChange={handleFieldChange}
                            value={documentRule?.effectiveDate}
                        ></DateField>
                    </FieldSet>
                    <FieldSet name="endDate" errors={errorData} customClass="edit-therapist-field date-picker-field required-field-star">
                        <DateField
                            id="endDate"
                            placeholder="End Date"
                            name="endDate"
                            onChange={handleFieldChange}
                            value={documentRule?.endDate}
                        ></DateField>
                    </FieldSet>
                    <FieldSet name="payType" errors={errorData} customClass="edit-therapist-field">
                        <InfiniteSelect
                            id="payTypeSelect"
                            items={payTypeOptions}
                            label="Pay Type"
                            value={payTypeOptions.find((item) => documentRule?.payType === item.value)}
                            onChange={(item) => handleFieldChange({ name: 'payType', value: item.value })}
                        />
                    </FieldSet>
                    <FieldSet errors={errorData} name="departmentId" customClass="edit-therapist-field">
                        <PaginationAutocomplete
                            fetchData={DepartmentService.getDepartmentOptions}
                            convertFunction={ConverterHelper.departmentConvert}
                            id="departmentSelect"
                            defaultValue={defaultDocumentRule?.department?.name}
                            placeholder="Department"
                            name="department"
                            tabIndex=""
                            onDelete={() => {
                                handleFieldChange({ name: 'department', value: undefined });
                                handleFieldChange({ name: 'subDepartment', value: undefined });
                            }}
                            onSelect={(item) => {
                                handleFieldChange({ name: 'department', value: item });
                                handleFieldChange({ name: 'subDepartment', value: undefined });
                            }}
                        />
                    </FieldSet>
                    {documentRule?.department?.hasSubDepartments && (
                        <FieldSet errors={errorData} name="subDepartmentId" customClass="edit-therapist-field">
                            <PaginationAutocomplete
                                fetchData={getSubDepartmentOptions}
                                convertFunction={ConverterHelper.optionConvert}
                                fetchTrigger={documentRule.department}
                                id="subDepartmentId"
                                defaultValue={documentRule?.subDepartment?.label}
                                placeholder="Sub Department"
                                name={OptionField.subDepartment}
                                tabIndex=""
                                onDelete={() => handleFieldChange({ name: 'subDepartment', value: undefined })}
                                onSelect={(item) => handleFieldChange({ name: 'subDepartment', value: item })}
                            />
                        </FieldSet>
                    )}
                    <FieldSet name="customerId" errors={errorData} customClass="edit-therapist-field">
                        <PaginationAutocomplete
                            fetchData={getCustomerOptions}
                            convertFunction={ConverterHelper.optionConvert}
                            id="customerId"
                            defaultValue={defaultDocumentRule?.customer?.name}
                            placeholder="Customer"
                            name="customer"
                            tabIndex=""
                            onDelete={() => handleFieldChange({ name: 'customerId', value: undefined })}
                            onSelect={(item) => handleFieldChange({ name: 'customerId', value: item.value })}
                            fetchTrigger={documentRule.department}
                        />
                    </FieldSet>
                    <FieldSet name="disciplineId" errors={errorData} customClass="edit-therapist-field">
                        <PaginationAutocomplete
                            fetchData={BaseService.getOptions}
                            convertFunction={ConverterHelper.optionConvert}
                            id="disciplineId"
                            defaultValue={defaultDocumentRule?.discipline?.name}
                            placeholder="Discipline"
                            name={OptionField.discipline}
                            tabIndex=""
                            onDelete={() => handleFieldChange({ name: 'disciplineId', value: undefined })}
                            onSelect={(item) => handleFieldChange({ name: 'disciplineId', value: item.value })}
                        />
                    </FieldSet>
                    <FieldSet name="isOrientationRelated" errors={errorData} customClass="edit-therapist-field">
                        <CheckBoxField
                            id="isOrientationRelated"
                            placeholder="Orientation Related"
                            name="isOrientationRelated"
                            onChange={handleFieldChange}
                            value={documentRule?.isOrientationRelated}
                        ></CheckBoxField>
                    </FieldSet>
                    <FieldSet name="isComplianceRelated" errors={errorData} customClass="edit-therapist-field">
                        <CheckBoxField
                            id="isComplianceRelated"
                            placeholder="Compliance Related"
                            name="isComplianceRelated"
                            onChange={handleFieldChange}
                            value={documentRule?.isComplianceRelated}
                        ></CheckBoxField>
                    </FieldSet>
                    <FieldSet name="isOverrideAllow" errors={errorData} customClass="edit-therapist-field">
                        <CheckBoxField
                            id="isOverrideAllow"
                            placeholder="Allow Override"
                            name="isOverrideAllow"
                            onChange={handleFieldChange}
                            value={documentRule?.isOverrideAllow}
                        ></CheckBoxField>
                    </FieldSet>
                    <FieldSet name="isPublic" errors={errorData} customClass="edit-therapist-field">
                        <CheckBoxField
                            id="isPublic"
                            placeholder="Public"
                            name="isPublic"
                            onChange={handleFieldChange}
                            value={documentRule?.isPublic}
                        ></CheckBoxField>
                    </FieldSet>
                </div>
            </RightSideBar>
            <div className="right-sidebar-overlay"></div>
        </div>
    );
};

export default AddEditDocumentRuleDialog;
