export interface IMSLoginParams {
    client_id?: string;
    response_type?: string;
    response_mode?: string;
    scope?: string;
    redirect_uri?: string;
}

export interface IMSLoginResponse {
    type: string;
    code: string;
    href: string;
}

export enum AuthErrorCodes {
    InvalidMSAuthCode = 'AUTHORIZATION_REQUIRED',
}
