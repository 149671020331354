import React, { useState } from 'react';
import Chip from '../../../common/components/chip/chip';
import './general-info.scss';
import Moment from 'moment';
import SetInactiveReasonDialog from 'features/therapist-details-page/set-inactive-reason/set-inactive-reason-dialog';
import { TherapistStatus } from '../shared/constants';
import { useDispatch, useSelector } from 'react-redux';
import { ThunkDispatch } from 'redux-thunk';
import { trackPromise } from 'react-promise-tracker';
import DropdownOption from 'common/models/dropdown-option';
import { getInactiveReasonText } from '../set-inactive-reason/constants';
import Avatar from 'common/components/Avatar/Avatar';
import StatusDropdown from 'common/components/status-dropdown/status-dropdown';
import communcationService from 'common/services/api/communication/communcation-service';
import NewSmsDialog from 'common/components/new-sms-dialog/new-sms-dialog';
import OptionsButton from 'common/components/options-button/options-button';
import therapistService from 'common/services/api/therapist/therapist-service';
import { GeneralInfoProps, MoreOption } from './types';
import AssignToDropdown from 'common/components/assign-to-dropdown/assign-to-dropdown';
import { Action } from '@reduxjs/toolkit';
import { INewSmsModel } from 'common/services/api/communication/types';
import { ITherapistDetailsModel } from 'common/services/api/therapist/types';
import { updateTherapistAssignedTo, updateTherapistStatus } from 'app/store/therapist/action-creators';
import { UPDATE_THERAPIST_STATUS } from 'app/store/therapist/types';

const fileDownload = require('js-file-download');

const GeneralInfo: React.FC<GeneralInfoProps> = ({ therapist }) => {
    const { generalInfo, contactInfo } = therapist;
    const [isNewSmsDialogOpen, toggleNewSmsDialog] = useState(false);

    const fullName = `${generalInfo.firstName} ${generalInfo.middleName || ''} ${generalInfo.lastName}`;
    const disciplines = generalInfo.disciplines.map((item: any) => item.name).join(', ');

    const featureList = useSelector((state: any) => state.siteSettings?.settings?.features || {});

    const [isSetInactiveReasonDialogOpen, toggleSetInactiveDialog] = useState(false);

    const handleCloseInactiveReasonDialog = () => {
        toggleSetInactiveDialog(false);
    };

    const dispatch = useDispatch<ThunkDispatch<any, any, Action>>();

    const handleChangeStatus = (status: DropdownOption) => {
        if (status.value === TherapistStatus.INACTIVE.value) {
            toggleSetInactiveDialog(true);
        } else {
            trackPromise(dispatch(updateTherapistStatus(therapist.id, status.value))).catch(() => {
                dispatch({
                    type: UPDATE_THERAPIST_STATUS,
                    status: therapist.status,
                });
            });
        }
    };

    const handleChangeAssignedTo = (item: DropdownOption) => {
        therapistService.assignUserToTherapist(therapist.id, item.value);
        dispatch(updateTherapistAssignedTo(item.label));
    };

    const displayInactiveReason = (therapist: ITherapistDetailsModel) => {
        if (!therapist.inactiveReasons) {
            return '';
        }

        return therapist.inactiveReasons.map((x) => getInactiveReasonText(x)).join(',');
    };

    const statusOptions = [
        new DropdownOption(TherapistStatus.NEW.value, TherapistStatus.NEW.label, 'new'),
        new DropdownOption(TherapistStatus.SCHEDULE_INTERVIEW.value, TherapistStatus.SCHEDULE_INTERVIEW.label, 'schedule-interview'),
        new DropdownOption(TherapistStatus.INTERVIEW_SCHEDULED.value, TherapistStatus.INTERVIEW_SCHEDULED.label, 'interview-scheduled'),
        new DropdownOption(TherapistStatus.ONBOARDING.value, TherapistStatus.ONBOARDING.label, 'onboarding'),
        new DropdownOption(TherapistStatus.IN_PROCESS.value, TherapistStatus.IN_PROCESS.label, 'in-process'),
        new DropdownOption(TherapistStatus.ACTIVE.value, TherapistStatus.ACTIVE.label, 'active'),
        new DropdownOption(TherapistStatus.INACTIVE.value, TherapistStatus.INACTIVE.label, 'inactive'),
        new DropdownOption(TherapistStatus.READY_TO_WORK.value, TherapistStatus.READY_TO_WORK.label, 'ready_to_work'),
        new DropdownOption(TherapistStatus.UNKNOWN.value, TherapistStatus.UNKNOWN.label, 'unknown'),
        new DropdownOption(TherapistStatus.PAUSE.value, TherapistStatus.PAUSE.label, 'pause'),
    ];

    const handleClickOnOpenNewSmsDialog = () => {
        toggleNewSmsDialog(true);
    };

    const handleClickOnCancelNewSmsDialog = () => {
        toggleNewSmsDialog(false);
    };

    const handleClickOnSaveSms = (sms: INewSmsModel) => {
        return trackPromise(
            communcationService.sendSms(therapist.id, sms).then(() => {
                handleClickOnCancelNewSmsDialog();
            })
        );
    };

    const moreOptions = [{ value: MoreOption.PrintIdCard, title: 'Print ID Card letter' }];

    const handleMoreOptionClick = (value: MoreOption) => {
        switch (value) {
            case MoreOption.PrintIdCard:
                trackPromise(
                    therapistService.generateIdCardLetterPDF(therapist.id).then((data) => {
                        fileDownload(data, `ID CARD LETTER-${therapist.id}.pdf`, 'application/pdf');
                    })
                );
                break;
            default:
                break;
        }
    };

    return (
        <div className="general-info">
            <div className="bio">
                {generalInfo.profilePhoto && <Avatar imageData={generalInfo.profilePhoto} />}
                <div className="personal-data">
                    <div className="fullname">{fullName}</div>
                    <div className="note">{disciplines}</div>
                    <div className="bio-footer">
                        <span className="description">Last updated on {Moment(therapist.updatedAt).format('MMM D, YYYY')}</span>
                        <span className="dot" />
                        <span className="description">Created by {therapist.createdBy}</span>
                    </div>
                </div>
            </div>
            <div className="contact-info">
                <div className="contact-info-wrapper">
                    {(contactInfo.phones && contactInfo.phones.length) > 0 && (
                        <Chip
                            onClick={handleClickOnOpenNewSmsDialog}
                            iconClass="icon-phone"
                            content={contactInfo.phones[0].number}
                            isPhoneNumber
                        />
                    )}
                    <Chip iconClass="icon-email" content={contactInfo.email} />
                </div>
                <div className="general-info-actions">
                    <div className="general-info-actions-wrapp">
                        <span className="label">Employment status</span>
                        <StatusDropdown
                            inactiveReason={displayInactiveReason(therapist)}
                            currentStatus={therapist.status}
                            onChange={handleChangeStatus}
                            options={statusOptions}
                            inactiveValue={TherapistStatus.INACTIVE.value}
                        />
                    </div>
                    <div className="general-info-actions-wrapp">
                        <AssignToDropdown cleanable={false} defaultValue={therapist.assignedTo} onChange={handleChangeAssignedTo} />
                    </div>
                    {featureList.isPrintIdCardLetterButtonsEnabled && (
                        <OptionsButton
                            className="therapist-more-button base-text-btn white"
                            id="therapist-more-button"
                            optionsList={moreOptions}
                            title="More"
                            onOptionClick={handleMoreOptionClick}
                        />
                    )}
                </div>
            </div>
            {isSetInactiveReasonDialogOpen && (
                <SetInactiveReasonDialog therapistId={therapist.id} onCancel={handleCloseInactiveReasonDialog}></SetInactiveReasonDialog>
            )}

            {isNewSmsDialogOpen && (
                <NewSmsDialog phones={contactInfo.phones} onCancel={handleClickOnCancelNewSmsDialog} onSave={handleClickOnSaveSms} />
            )}
        </div>
    );
};

export default GeneralInfo;
