import { InfiniteServerClient } from 'common/helpers/http-clients';
import { IPatientGridItem, ICreatePatientRequest, IPatientOption, IPatientDetails, IPatientRequest, IPatientAddEditForm } from './types';
import { SequenceHelper } from 'common/helpers/sequence-helper';

class PatientService {
    create(patient: ICreatePatientRequest): Promise<IPatientDetails> {
        return InfiniteServerClient.post('/patients', patient).then(
            (response) => {
                return response.data;
            },
            (error) => {
                throw error;
            }
        );
    }

    getAll(): Promise<IPatientGridItem[]> {
        return InfiniteServerClient.get('/patients').then(
            (response) => {
                return response.data;
            },
            (error) => {
                throw error;
            }
        );
    }

    getPatientsOptions(term?: string, skip?: number, take?: number, customerId?: number): Promise<IPatientOption[]> {
        return InfiniteServerClient.get('patients/options', { params: { term, skip, take, customerId } }).then(
            (response) => {
                return response.data;
            },
            (error) => {
                throw error;
            }
        );
    }

    getPatientDetails(id: number): Promise<IPatientDetails> {
        return InfiniteServerClient.get(`patients/${id}/details`).then(
            (response) => {
                return {
                    ...response.data,
                    emails: SequenceHelper.getSequenceItems(response.data?.emails),
                    phones: SequenceHelper.getSequenceItems(response.data?.phones),
                    physicians: SequenceHelper.getSequenceItems(response.data?.physicians),
                };
            },
            (error) => {
                throw error;
            }
        );
    }

    editBasicInfo(patient: IPatientAddEditForm): Promise<IPatientGridItem> {
        return InfiniteServerClient.put(`/patients/${patient.id}/basic-info`, patient).then(
            (response) => {
                return response.data;
            },
            (error) => {
                throw error;
            }
        );
    }

    setPatientStatus(id: number, status: string) {
        return InfiniteServerClient.put(`/patients/${id}/statuses`, { status: status }).then(
            (response) => {
                return response.data;
            },
            (error) => {
                throw error;
            }
        );
    }

    getPatientRequestDetails(patientId: number): Promise<IPatientRequest> {
        return InfiniteServerClient.get(`/patients/${patientId}/request-details`).then(
            (response) => {
                return response.data;
            },
            (error) => {
                throw error;
            }
        );
    }
}

export default new PatientService();
