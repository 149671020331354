import { ErrorCodes } from 'common/constants/error-codes';
import { ExportType } from 'common/constants/export-options';
import { FileHelper } from 'common/helpers/file-helper';
import { IPoint } from 'common/models/point';
import { IAddressSearchModel, IMapPointResponse, DisplayAddressType, ITherapistMapFilter } from 'common/services/api/map/types';
import { useCallback, useEffect, useRef, useState } from 'react';
import { trackPromise } from 'react-promise-tracker';
import { toast } from 'react-toastify';
import { ITherapistMapConfig, TherapistMapResult } from './types';
import { debounce } from 'lodash';

const DEFAULT_RADIUS: number = 2;

const useTherapistMap = ({ fetchFunction, exportFunction, mapType, defaultFilter = {} }: ITherapistMapConfig): TherapistMapResult => {
    const [searchModel, setSearchModel] = useState<IAddressSearchModel>({
        radius: DEFAULT_RADIUS,
        center: undefined,
        filter: defaultFilter,
        mapType: mapType,
    });

    const resetLegendRef = useRef(null);

    const [formattedAddress, setFormatedAddress] = useState<string>();

    const [result, setResult] = useState<IMapPointResponse[]>([]);

    const handleCenterChange = useCallback(
        (center: IPoint, formattedAddress: string) => {
            setSearchModel((prev) => ({ ...prev, center: center }));
            setFormatedAddress(formattedAddress);
        },
        [setSearchModel]
    );

    const handleMilesChange = useCallback(
        (miles: number) => {
            setSearchModel((prev) => ({ ...prev, radius: miles }));
        },
        [setSearchModel]
    );

    const handleDisplayTypeChange = useCallback(
        (types: DisplayAddressType[], resetLegend: () => void) => {
            setSearchModel((prev) => {
                resetLegendRef.current = resetLegend;

                return { ...prev, addressTypes: types };
            });
        },
        [setSearchModel]
    );

    const handleClearFilters = useCallback(() => {
        setSearchModel((prev) => ({ ...prev, addressTypes: [], filter: defaultFilter, radius: 2 }));
        resetLegendRef.current();
    }, [setSearchModel, defaultFilter]);

    const handleExport = useCallback(
        (type: ExportType) => {
            if (!searchModel?.center) {
                toast.error('In order to perform the export, you need to choose something from the Zip or Address field.');

                return;
            }

            trackPromise(
                exportFunction(type, searchModel)
                    .then((data: any) => {
                        FileHelper.downloadBlob(data, `${formattedAddress} Map Therapists.${type.toLowerCase()}`);
                    })
                    .catch((error) => {
                        if (error?.errorCode === ErrorCodes.NO_RECORDS_TO_EXPORT) {
                            toast.error('No records to export');
                        }
                    })
            );
        },
        [exportFunction, formattedAddress, searchModel]
    );

    const handleTherapistFilterChange = useCallback(
        (filter: ITherapistMapFilter) => {
            setSearchModel((prev) => ({ ...prev, filter: filter }));
        },
        [setSearchModel]
    );

    const fetchFunctionRef = useRef(null);
    fetchFunctionRef.current = () => {
        if (searchModel.center && searchModel.addressTypes?.length) {
            trackPromise(fetchFunction(searchModel).then((response: any) => setResult(response)));
        }
    };

    const fetchDebounce = useCallback(
        debounce(() => fetchFunctionRef.current(), 100),
        []
    );

    useEffect(() => {
        fetchDebounce();
    }, [searchModel, fetchDebounce]);

    return [
        result,
        searchModel,
        handleCenterChange,
        handleMilesChange,
        handleDisplayTypeChange,
        handleTherapistFilterChange,
        handleExport,
        handleClearFilters,
    ];
};

export default useTherapistMap;
