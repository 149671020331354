import React from 'react';
import { ReminderCheckboxProps } from './types';

const ReminderCheckbox: React.FC<ReminderCheckboxProps> = ({ id, value, className, name, onChange }) => {
    const handleClick = () => {
        onChange(!value);
    };

    return (
        <div className={`check-box-field ${className}`} onClick={handleClick}>
            <input id={id} name={name} type="checkbox" className="nps-checkbox" checked={value} readOnly />
            <label htmlFor={id}></label>
            <span>Reminder</span>
        </div>
    );
};
export default ReminderCheckbox;
