import { GridOptions, GridReadyEvent } from 'ag-grid-community';
import AgTable from 'common/components/ag-table/ag-table';
import HeaderLeftSide from 'common/components/header/header-left-side';
import HeaderWrapper from 'common/components/header/header-wrapper';
import { useSearchInput } from 'common/hooks/use-serch-input';
import { IUser, UserStatus } from 'common/services/api/user/types';
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { IUsersListFilter, UserGridActions, UsersListProps } from './types';
import SearchFilterInput from 'common/components/header/search-filter-input/search-filter-input';
import DropdownOption from 'common/models/dropdown-option';
import InfiniteSelect from 'common/components/infinite-select/infinite-select';
import { useHasPermission } from 'common/hooks/use-has-permission';
import { PermissionType } from 'common/models/permission-type';
import { LocalTableRepository } from 'common/helpers/repository/local-table-repository';
import { getTableData } from 'common/services/api/tabel-fetch-service';
import { LocalStoreRepository } from 'common/helpers/repository/local-store-repository';

const localStoreRepository = new LocalTableRepository('users-external-list', 'v2');
const customFiltersRepository = new LocalStoreRepository('users-external-list');
const handleFetchData = getTableData('entities/contacts/users');
const defaultFilters: IUsersListFilter = {
    search: null,
};

const UsersExternalList: React.FC<UsersListProps> = ({ onGridReady, onEditUserClick, onResetPasswordClick }) => {
    const loadedFilters = useRef<IUsersListFilter>(customFiltersRepository.load());
    const [filter, setFilters] = useState<IUsersListFilter>(loadedFilters.current ?? defaultFilters);
    const hasCanUpdateExternalUsersPermission = useHasPermission(PermissionType.CanUpdateExternalUsers);

    const { search, handleSearchInputChange } = useSearchInput(filter?.search || '', (value: string) => {
        setFilters((prev) => ({ ...prev, search: value }));
    });

    useEffect(() => {
        customFiltersRepository.save(filter);
        loadedFilters.current = filter;
    }, [filter]);

    const handleClickOnAction = useCallback(
        (action: UserGridActions, user: IUser) => {
            switch (action) {
                case UserGridActions.Edit:
                    onEditUserClick(user);
                    break;
                case UserGridActions.ResetPassword:
                    onResetPasswordClick(user);
                    break;
            }
        },
        [onEditUserClick, onResetPasswordClick]
    );

    const gridOptions: GridOptions = useMemo(
        () => ({
            frameworkComponents: {
                doteRender: (props: any) => {
                    const options = [];
                    if (hasCanUpdateExternalUsersPermission) {
                        options.push(new DropdownOption(UserGridActions.Edit, 'Edit'));
                    }

                    options.push(new DropdownOption(UserGridActions.ResetPassword, 'Reset password'));

                    return (
                        options.length > 0 && (
                            <InfiniteSelect
                                id="users-actions-menu"
                                popper
                                items={options}
                                className="no-select-border"
                                icon={<i className="icon icon-options" />}
                                onChange={(item: DropdownOption) => {
                                    handleClickOnAction(item?.value, props.data);
                                }}
                            />
                        )
                    );
                },
            },
            columnDefs: [
                {
                    field: 'firstName',
                    headerName: 'First Name',
                    sortable: false,
                    width: 250,
                },
                {
                    field: 'lastName',
                    headerName: 'Last Name',
                    sortable: false,
                    width: 250,
                },
                {
                    field: 'email',
                    headerName: 'Email address',
                    sortable: false,
                    width: 300,
                },
                {
                    field: 'entity',
                    headerName: 'Entity',
                    sortable: false,
                    width: 200,
                },
                {
                    field: 'status',
                    headerName: 'Status',
                    valueFormatter: (props: any) => {
                        const value: UserStatus = props.value as UserStatus;

                        if (value === null || value === undefined) {
                            return 'Inactive';
                        }

                        switch (value) {
                            case UserStatus.Undefined:
                                return 'Undefined';
                            case UserStatus.Active:
                                return 'Active';
                            case UserStatus.Inactive:
                                return 'Inactive';
                            case UserStatus.InvitationSent:
                                return 'Invitation Sent';
                            default:
                                return 'Undefined';
                        }
                    },
                    sortable: false,
                    width: 94,
                },
                {
                    headerName: '',
                    colId: 'doteMenu',
                    pinned: 'right',
                    cellRenderer: 'doteRender',
                    cellClass: ['dote-cell'],
                    width: 40,
                    sortable: false,
                    hideForExport: true,
                },
            ],
            defaultColDef: {},
        }),
        [handleClickOnAction, hasCanUpdateExternalUsersPermission]
    );

    const handleGridReady = (params: GridReadyEvent) => {
        if (onGridReady) {
            onGridReady(params);
        }
    };

    return (
        <>
            <div className="main-info-wrapper ag-table-wrapper">
                <HeaderWrapper>
                    <HeaderLeftSide>
                        <SearchFilterInput title="Users" value={search} onInput={handleSearchInputChange} />
                    </HeaderLeftSide>
                </HeaderWrapper>
                {hasCanUpdateExternalUsersPermission !== null && (
                    <AgTable
                        repository={localStoreRepository}
                        onGridReady={handleGridReady}
                        customFilters={filter}
                        gridOptions={gridOptions}
                        onFetchData={handleFetchData}
                    />
                )}
            </div>
        </>
    );
};

export default UsersExternalList;
