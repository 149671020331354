import FieldSet from 'common/components/field-set/field-set';
import DateField from 'common/components/field/date-field';
import MoneyField from 'common/components/field/money-field';
import { dateTimeConstants } from 'common/constants/common';
import React from 'react';
import { ITherapistRateFormBaseProps } from './types';

const TherapistRateFormBase = ({
    data,
    errors,
    className = 'therapist-rate-base-form',
    onChange,
    isEdit,
    isUpdate,
}: ITherapistRateFormBaseProps) => {
    const { rate, startDate, endDate } = data;

    return (
        <div className={`edit-form ${className}`}>
            <FieldSet errors={errors} name="rate" customClass={`therapist-rate-field ${isUpdate ? '' : 'required-field-star'}`}>
                <MoneyField
                    id="moneyRate"
                    placeholder="Rate"
                    name="rate"
                    onChange={onChange}
                    value={rate || 0}
                    disabled={isEdit || isUpdate}
                ></MoneyField>
            </FieldSet>

            <FieldSet errors={errors} name="startDate" customClass={`therapist-rate-field ${isUpdate ? '' : 'required-field-star'}`}>
                <DateField
                    id="startDate"
                    placeholder="Start Date"
                    name="startDate"
                    disabled={isEdit || isUpdate}
                    onChange={onChange}
                    value={startDate}
                    dateFormat={dateTimeConstants.MM_DD_YYYY}
                    maskFormat="##/##/####"
                ></DateField>
            </FieldSet>

            <FieldSet errors={errors} name="endDate" customClass="therapist-rate-field required-field-star">
                <DateField
                    id="endDate"
                    placeholder="End Date"
                    name="endDate"
                    onChange={onChange}
                    value={endDate}
                    dateFormat={dateTimeConstants.MM_DD_YYYY}
                    maskFormat="##/##/####"
                ></DateField>
            </FieldSet>
        </div>
    );
};

export default TherapistRateFormBase;
