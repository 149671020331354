import { Reducer } from '@reduxjs/toolkit';
import { ZipCodesActionTypes, IZipCodeState } from './types';

const initialState: IZipCodeState = {
    data: undefined,
};

export const zipCodeReducer: Reducer<IZipCodeState, ZipCodesActionTypes> = (state = initialState, action) => {
    switch (action.type) {
        case 'SET_ZIP_CODES':
            return { ...state, data: action.zipCodes };
        default:
            return state;
    }
};
