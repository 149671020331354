import React from 'react';
import { IFieldSetProps } from './types';

const FieldSet = ({
    name = undefined,
    children,
    errors = undefined,
    customClass = undefined,
    renderError = undefined,
    requiredFields = [],
}: IFieldSetProps) => {
    const isError = (name: string) => {
        if (!name || !errors || !errors[name]) {
            return false;
        }

        const errorList = errors[name] || [];

        return errorList.length > 0;
    };

    const renderErrorMessage = () => {
        if (!isError(name)) {
            return '';
        }

        return <span className="error-message-for-input">{renderError ? renderError(errors[name]) : errors[name][0]}</span>;
    };

    const childrenData = React.Children.map(children, (child) => {
        return React.cloneElement(child, { hasError: child.props.hasError === undefined && isError(name) });
    });

    const isRequired = requiredFields.indexOf(name) !== -1 ? 'required-field-star' : '';

    return (
        <>
            <div className={`field-set ${isRequired} ${customClass || ''}`}>
                {childrenData}
                {renderErrorMessage()}
            </div>
        </>
    );
};

export default FieldSet;
