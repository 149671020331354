import { dateTimeConstants } from 'common/constants/common';
import Moment from 'moment';
import React from 'react';
import { DetailsHeaderInfoProp } from './types';
import './details-header-info.scss';
import AssignToDropdown from '../assign-to-dropdown/assign-to-dropdown';
import DropdownOption from 'common/models/dropdown-option';

const formatDate = (date: Date) => {
    return Moment(date).local().format(dateTimeConstants.MM_DD_YYYY);
};

// TODO Must be moved to Nursing Home folder
const DetailsHeaderInfo: React.FC<DetailsHeaderInfoProp> = ({ details, handleSeeActivityButton, onAssignUser }) => {
    const statusClass = details.status.toLowerCase();

    const handleChangeAssignTo = (option: DropdownOption) => {
        onAssignUser(option.value);
    };

    return (
        <div className="rh-info">
            <div className="rh-info__title">{details.name}</div>
            <div className="rh-info__body">
                <span>
                    {details?.id} <i className="rh-info__dote" />
                </span>
                <span>
                    Created {formatDate(details.createdAt)} by {details.createdBy}
                </span>
                {details.updatedAt && (
                    <span>
                        <i className="rh-info__dote" /> Last updated {formatDate(details.updatedAt)} by {details.updatedBy}
                    </span>
                )}
            </div>
            <div className="rh-info__footer">
                <span className={`request-status ${statusClass}`} onClick={handleSeeActivityButton}>
                    {details.status}
                </span>
                <div className="rh-info__actions">
                    <AssignToDropdown defaultValue={details.assignedTo?.fullName} onChange={handleChangeAssignTo} />
                    <button className="base-text-btn white" onClick={handleSeeActivityButton}>
                        See Activity
                    </button>
                </div>
            </div>
        </div>
    );
};

export default React.memo(DetailsHeaderInfo, function areEqual(prevProps, nextProps) {
    return prevProps.details === nextProps.details;
});
