import React from 'react';
import FieldSet from 'common/components/field-set/field-set';
import { IRequestAuthorizationFormProps } from './types';
import DateField from 'common/components/field/date-field';
import CheckboxField from 'common/components/field/checkbox-field';
import { useIsUserType } from 'common/hooks/use-is-user-type';
import NumberField from 'common/components/field/number-field';
import { validationRules } from '../../validation-rules';
import DocumentAttachmentsForm from 'common/components/document-attachments/ducument-attachments-form';

const RequestAuthorizationForm = ({ data, errors, onChange, isMedBDepartment }: IRequestAuthorizationFormProps) => {
    const { isExternal } = useIsUserType();
    const requiredClass = isExternal ? 'required-field-star' : '';

    return (
        <>
            <div className="edit-form outline-none" tabIndex={1}>
                {!isMedBDepartment && (
                    <FieldSet name="startDate" errors={errors} customClass={`edit-request-authorization-field ${requiredClass}`}>
                        <DateField
                            placeholder="Authorization Start Date"
                            name="startDate"
                            onChange={onChange}
                            value={data.startDate}
                            tabIndex="1"
                        />
                    </FieldSet>
                )}
                {!isMedBDepartment && (
                    <FieldSet name="endDate" errors={errors} customClass={`edit-request-authorization-field ${requiredClass}`}>
                        <DateField
                            placeholder="Authorization End Date"
                            name="endDate"
                            onChange={onChange}
                            value={data.endDate}
                            tabIndex="2"
                        />
                    </FieldSet>
                )}
                {!isMedBDepartment && (
                    <DocumentAttachmentsForm
                        fieldName="files"
                        addButtonText="Attachments"
                        values={data.files}
                        errors={errors}
                        onChange={onChange}
                        disabledPlaceholder="Attached document"
                        customClass={`edit-request-authorization-field ${requiredClass}`}
                    />
                )}
                {!isExternal && !isMedBDepartment && (
                    <FieldSet name="isAuthorized" errors={errors} customClass="edit-therapist-field">
                        <CheckboxField
                            id="isAuthorized"
                            placeholder="Authorized"
                            name="isAuthorized"
                            onChange={onChange}
                            value={data.isAuthorized}
                            tabIndex="4"
                        />
                    </FieldSet>
                )}
                {!isExternal && (
                    <FieldSet name="evalId" errors={errors} customClass="edit-therapist-field">
                        <NumberField
                            id="evalId"
                            maxLength={validationRules.evalId.maxLength}
                            placeholder="Eval ID"
                            name="evalId"
                            onChange={onChange}
                            value={data.evalId}
                            tabIndex={5}
                        />
                    </FieldSet>
                )}
            </div>
        </>
    );
};

export default RequestAuthorizationForm;
