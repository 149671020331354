import Tab from 'common/components/tabs/tab';
import Tabs from 'common/components/tabs/tabs';
import React from 'react';
import { RequestGeneralInfoTabProp } from '../types';
import RequestBasicInfoTab from './basic-info-tab/request-basic-info-tab';
import { useIsUserType } from 'common/hooks/use-is-user-type';
import { ICreateNoteRequest } from 'common/models/create-note-request';
import requestNoteService from 'common/services/api/request-note/request-note-service';
import noteService from 'common/services/api/note/note-service';
import NotePage from 'common/components/note-page/note-page';
import { RequestStatus } from 'common/services/api/requests/types';

const RequestGeneralInfoTab: React.FC<RequestGeneralInfoTabProp> = ({ requestId, requestDetails }) => {
    const { isExternal } = useIsUserType();
    const isHold = requestDetails?.status === RequestStatus.Hold;

    if (!requestDetails) {
        return null;
    }

    if (!requestDetails.patientDetails) {
        requestDetails.patientDetails = {
            address1: null,
            address2: null,
            city: null,
            fullName: null,
            id: null,
            patientAgencyCode: requestDetails.patientAgencyCode ?? null,
            state: null,
            zip: requestDetails.zipCode ?? null,
        };
    }

    const handleClickOnSaveNote = (request: ICreateNoteRequest) => {
        return requestNoteService.create(requestId, request);
    };

    const handleClickOnCompleteNote = (id: number) => {
        noteService.complete(id);
    };

    return (
        <Tabs orientation="vertical" parentTabUrl="general-info" className="general-tab">
            <Tab id="basicInfo" linkClassName={'custom-link'} text="Basic Info">
                <RequestBasicInfoTab requestId={requestId} requestDetails={requestDetails} />
            </Tab>
            {!isExternal && (
                <Tab id="notes" linkClassName={'custom-link'} text="Notes">
                    <NotePage
                        url={`requests/${requestId}/notes`}
                        onCompleteNoteClick={handleClickOnCompleteNote}
                        onSave={handleClickOnSaveNote}
                        viewOnly={isHold}
                    />
                </Tab>
            )}
        </Tabs>
    );
};

export default RequestGeneralInfoTab;
