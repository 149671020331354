import PropTypes from 'prop-types';
import React from 'react';
import { IField } from './types';

const Field = ({
    value,
    id,
    hasError = false,
    children,
    placeholder,
    isFocused,
    customClass = '',
    withSameValue = false,
    uniqueValue = false,
}: IField) => {
    return (
        <div id={`${id}Div`} className="custom-field-outer">
            <div className={`base-input-wrapper ${isFocused ? 'focused' : ''} ${hasError ? 'has-error' : ''} ${customClass}`}>
                {!withSameValue && (
                    <span
                        id={`${id}Span`}
                        className={`base-input-label ${
                            isFocused || (value && !Array.isArray(value)) || (Array.isArray(value) && value.length > 0) || value === 0
                                ? 'floated'
                                : ''
                        } ${customClass}`}
                    >
                        {placeholder}
                    </span>
                )}
                {withSameValue && (
                    <span
                        id={`${id}Span`}
                        className={`base-input-label ${isFocused || uniqueValue || uniqueValue === 0 ? 'floated' : ''} ${customClass}`}
                    >
                        {placeholder}
                    </span>
                )}
                {children}
            </div>
        </div>
    );
};

Field.propTypes = {
    placeholder: PropTypes.string,
    value: PropTypes.any.isRequired,
    isFocused: PropTypes.bool,
};

export default Field;
