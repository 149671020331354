import React, { useEffect, useState } from 'react';
import SideBar from '../side-bar';
import SingleNavItem from '../single-nav-item';
import { LeftNavigationBarExternalUserProps } from './types';
import RequestService from 'common/services/api/requests/request-service';
import { useSelector } from 'react-redux';
import { Counter } from 'common/components/counter/counter';

export const LeftNavigationBarExternalUser = (props: LeftNavigationBarExternalUserProps) => {
    const { expanded, toggle, onLogOut } = props;
    const entityId = useSelector((state: any) => state.auth.entity?.id);
    const [acceptedTodayCount, setAcceptedTodayCount] = useState(null);

    useEffect(() => {
        const updateRequestsStatus = () => {
            RequestService.getCountGroupStatuses(`entityId=${entityId}`).then((data) => {
                setAcceptedTodayCount(data?.awaitingAuthCount);
            });
        };

        updateRequestsStatus();
    }, [entityId]);

    return (
        <div className="left-sidebar-menu">
            <SideBar onLogOut={() => onLogOut()} expanded={expanded} toggle={toggle}>
                <SingleNavItem
                    routePath="/requests"
                    classIcon="icon-data"
                    expanded={expanded}
                    itemName="Requests"
                    additionalContent={<Counter className="ml-64">{acceptedTodayCount}</Counter>}
                />
                <SingleNavItem routePath="/patients" classIcon="icon-people" expanded={expanded} itemName="Patients" />
            </SideBar>
        </div>
    );
};

export default LeftNavigationBarExternalUser;
