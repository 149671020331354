import { IWidgetOptions } from 'common/services/api/therapist-dahsboard/types';
import React, { useMemo } from 'react';

export const useAssignToColumn = (optionsRef: React.MutableRefObject<IWidgetOptions>) => {
    return useMemo(
        () => ({
            field: 'assignedTo',
            headerName: 'Assigned To',
            sortable: true,
            filter: 'agCustomFilter',
            width: 270,
            filterParams: {
                onFetchFilters: (value: any) => {
                    const allOptions = optionsRef?.current?.userOptions || [];
                    let options = allOptions || [];
                    if (value?.search) {
                        options = options.filter((item) => item?.name && item.name.toLowerCase().includes(value?.search?.toLowerCase()));
                    }

                    return Promise.resolve({ data: options.map((i) => i.id) });
                },
                transformFromServer: (value: any) => {
                    const allOptions = optionsRef?.current?.userOptions || [];

                    return allOptions?.find((i) => i?.id === value)?.name;
                },
                transformToServer: (value: any) => {
                    const allOptions = optionsRef?.current?.userOptions || [];

                    return allOptions?.find((i) => i?.id === value)?.id;
                },
            },
        }),
        [optionsRef.current]
    );
};
