import React from 'react';
import { ActionButtonProps } from './types';
import './action-button.scss';

const ActionButton = ({ onClick, icon, text, children }: ActionButtonProps) => {
    return (
        <div className="action-button" onClick={onClick}>
            {children || (
                <>
                    <i className={`icon ${icon}`} /> {text}
                </>
            )}
        </div>
    );
};

export default ActionButton;
