export enum PermissionType {
    AccessTherapistSecureData = 'AccessProviderSecureInfo',
    CanViewTherapistDepartmentWorkflow = 'CanViewProviderDepartmentWorkflow',
    DocumentManagement = 'DocumentManagement',
    AccessPrivateDocument = 'AccessPrivateDocument',
    SettingsEdit = 'SettingsEdit',
    CanManageRoles = 'CanManageRoles',
    CanViewTherapistRates = 'CanViewProviderRates',
    CanAddEditTherapistRates = 'CanAddEditProviderRates',
    CanDeleteTherapistRates = 'CanDeleteProviderRates',
    CanViewInternalUsers = 'CanViewInternalUsers',
    CanUpdateInternalUsers = 'CanUpdateInternalUsers',
    CanViewExternalUsers = 'CanViewExternalUsers',
    CanUpdateExternalUsers = 'CanUpdateExternalUsers',
    CanAddEditNursingHomeRates = 'CanAddEditNursingHomeRates',
    CanViewNursingHomeRates = 'CanViewNursingHomeRates',

    AccessMyFollowUpWidget = 'AccessMyFollowUpWidget',
    AccessNewWidget = 'AccessNewWidget',
    AccessInProcessWidget = 'AccessInProcessWidget',
    AccessInterviewScheduledWidget = 'AccessInterviewScheduledWidget',
    AccessOnboardingWidget = 'AccessOnboardingWidget',
    AccessOnboardingWithDocumentsWidget = 'AccessOnboardingWithDocumentsWidget',
    AccessActiveWithDocumentsWidget = 'AccessActiveWithDocumentsWidget',
    AccessInProcessSupervisorWidget = 'AccessInProcessSupervisorWidget',
    AccessNewSupervisorWidget = 'AccessNewSupervisorWidget',
    AccessInterviewScheduledSupervisorWidget = 'AccessInterviewScheduledSupervisorWidget',
    AccessOnboardingSupervisorWidget = 'AccessOnboardingSupervisorWidget',
    AccessOnboardingWithDocumentsSupervisorWidget = 'AccessOnboardingWithDocumentsSupervisorWidget',
    AccessActiveWithDocumentsSupervisorWidget = 'AccessActiveWithDocumentsSupervisorWidget',
    AccessMissingDataWidget = 'AccessMissingDataWidget',
    AccessMissingDataSupervisorWidget = 'AccessMissingDataSupervisorWidget',
}

export const getWidgetPermissions = (): string[] => {
    return [
        PermissionType.AccessMyFollowUpWidget,
        PermissionType.AccessNewWidget,
        PermissionType.AccessInProcessWidget,
        PermissionType.AccessInterviewScheduledWidget,
        PermissionType.AccessOnboardingWidget,
        PermissionType.AccessOnboardingWithDocumentsWidget,
        PermissionType.AccessActiveWithDocumentsWidget,
        PermissionType.AccessInProcessSupervisorWidget,
        PermissionType.AccessNewSupervisorWidget,
        PermissionType.AccessInterviewScheduledSupervisorWidget,
        PermissionType.AccessOnboardingSupervisorWidget,
        PermissionType.AccessOnboardingWithDocumentsSupervisorWidget,
        PermissionType.AccessActiveWithDocumentsSupervisorWidget,
    ];
};
