import React, { Dispatch, useState } from 'react';
import { getUniqueName } from '../../helpers/string-helper';
import { BaseCustomInputProps } from './types';
const isFunction = require('lodash.isfunction');

function BaseCustomInput(props: BaseCustomInputProps) {
    const [isInputFocused, changeInputFocused] = useState<boolean>(false);
    const [defaultInputValue, changeDefaultInputValue] = useState<string>('');

    const inputId = props.inputId;
    const labelId = props.labelId;
    const error = props.error;
    const placeholderText = props.placeholderText;
    const inputName = props.inputName;
    const inputType = props.inputType;
    const isRequired = props.isRequired;
    let inputValue = props.inputValue;
    const inputValueForTemplates = props.inputValueForTemplates;
    const isTemplates = props.isTemplates;
    const inputRef = props.inputRef;
    const isWithIcon = props.isWithIcon;
    const iconClass = props.iconClass;
    const fieldType = props.fieldType;
    const errorMessage = props.errorMessage;
    const maxLength = props.maxLength;
    const inputPattern = props.inputPattern;
    const onFocusedCallBack = props.onFocusedCallBack;
    const inputClass = props.inputClass ? props.inputClass : '';
    const className = props.className ? props.className : '';

    const defaultOnChange = (e: React.ChangeEvent<HTMLInputElement> | React.ChangeEvent<HTMLTextAreaElement>) => {
        changeDefaultInputValue(e.target.value);
    };

    const onInputChange = (e: React.ChangeEvent<HTMLInputElement> | React.ChangeEvent<HTMLTextAreaElement>) => {
        if (props.onInputChange) {
            props.onInputChange(e);
            if (props.isTableHeaderFilterInput) {
                defaultOnChange(e);
            }
        } else {
            defaultOnChange(e);
        }

        if (fieldType === 'date-picker') {
            changeInputFocused(false);
        }
    };

    if (isTemplates) {
        inputValue = inputValueForTemplates;
    }

    const setFloatedClassOnLabel = (value: string, defaultInputValue: string, isFieldFocused: boolean) => {
        if (value || defaultInputValue || isFieldFocused) {
            return `base-${fieldType}-label floated`;
        } else {
            return `base-${fieldType}-label`;
        }
    };

    const setClassOnWrapper = () => {
        if (isWithIcon) {
            if (error || (isInputFocused && error)) {
                return `base-${fieldType}-wrapper-with-ico with-error`;
            } else if (isInputFocused) {
                return `base-${fieldType}-wrapper-with-ico focused`;
            } else {
                return `base-${fieldType}-wrapper-with-ico`;
            }
        } else {
            if (error || (isInputFocused && error)) {
                return `base-${fieldType}-wrapper with-error`;
            } else if (isInputFocused) {
                return `base-${fieldType}-wrapper focused`;
            } else {
                return `base-${fieldType}-wrapper`;
            }
        }
    };

    const onFieldFocus = (labelId: string, changeFocused: Dispatch<boolean>) => {
        changeFocused(true);
        document.getElementById(labelId).classList.add('floated');
        if (isFunction(onFocusedCallBack)) onFocusedCallBack();
    };

    const onFieldBlur = (labelId: string, value: string, defaultValue: string, changeFocused: Dispatch<boolean>) => {
        if (value === '' && defaultValue === '') {
            document.getElementById(labelId).classList.remove('floated');
        }
        changeFocused(false);
    };

    return (
        <div className={`custom-field-outer ${className}`}>
            <div className={setClassOnWrapper()}>
                {isWithIcon && <span className={`icon ${iconClass}`} onClick={() => document.getElementById(inputId).focus()}></span>}

                <span className={setFloatedClassOnLabel(inputValue, defaultInputValue, isInputFocused)} id={labelId}>
                    {placeholderText}
                </span>

                {fieldType === 'input' && (
                    <input
                        id={inputId + getUniqueName('.')}
                        type={inputType}
                        className={`${inputClass} ${inputName ?? ''} ${inputId ?? ''}`}
                        onFocus={() => onFieldFocus(labelId, changeInputFocused)}
                        onBlur={() => onFieldBlur(labelId, inputValue, defaultInputValue, changeInputFocused)}
                        required={isRequired}
                        ref={inputRef}
                        value={inputValue ? inputValue : defaultInputValue}
                        onChange={onInputChange}
                        maxLength={maxLength}
                        autoComplete={getUniqueName()}
                        pattern={inputPattern}
                        placeholder={placeholderText}
                    />
                )}

                {fieldType === 'textarea' && (
                    <textarea
                        id={inputId + getUniqueName('.')}
                        className={`${inputName ?? ''} ${inputId ?? ''}`}
                        onFocus={() => onFieldFocus(labelId, changeInputFocused)}
                        onBlur={() => onFieldBlur(labelId, inputValue, defaultInputValue, changeInputFocused)}
                        required={isRequired}
                        ref={inputRef}
                        value={inputValue ? inputValue : defaultInputValue}
                        onChange={onInputChange}
                    />
                )}

                {props.onClearClick && <span className="clear-filter icon-close" onClick={props.onClearClick}></span>}
            </div>

            {error && <span className="error">{errorMessage}</span>}
        </div>
    );
}

export default BaseCustomInput;
