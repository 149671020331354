import React, { useCallback, useEffect, useState } from 'react';
import './badges.scss';
import { BadgesComponentProps, IBadge } from './types';

const MultipleBadges = ({
    items = [],
    defaultKey,
    onChange,
    badgeComponent: BadgeComponent,
    className = '',
    onItemChanged,
    repository,
    children,
}: BadgesComponentProps) => {
    const [selectedBadges, setSelectedBadges] = useState((repository?.load() as IBadge[]) ?? []);

    // set default value
    useEffect(() => {
        if (!defaultKey) {
            return;
        }
        const newValues = items.filter((item) => defaultKey.some((key) => key === item.value));

        setSelectedBadges(newValues);
        repository?.save(newValues);
        onChange(newValues);
    }, []);

    //  for clear or reselect
    useEffect(() => {
        if (!defaultKey) {
            return;
        }

        const newValues = items.filter((item) => defaultKey.some((key) => key === item.value));

        setSelectedBadges(newValues);
        repository?.save(newValues);
    }, [defaultKey]);

    const handleOnClickLabel = useCallback(
        (badge: IBadge) => {
            const currentIndex = selectedBadges.findIndex((item) => item.value === badge.value);
            const selectedValues: any = {
                data: [],
            };

            if (currentIndex === -1) {
                selectedValues.data = [...selectedBadges, badge]; // select
            } else {
                selectedValues.data = selectedBadges.filter((item) => item.value !== badge.value); // unselect
            }
            setSelectedBadges(selectedValues.data);

            if (currentIndex === -1) {
                if (onItemChanged) {
                    onItemChanged(badge, true);
                }
            } else {
                if (onItemChanged) {
                    onItemChanged(badge, false);
                }
            }

            repository.save(selectedValues.data);

            if (onChange) {
                onChange(selectedValues.data);
            }
        },
        [onItemChanged, onChange, selectedBadges, repository]
    );

    const badges = (items ?? []).map((badge: any) => {
        return (
            <BadgeComponent
                badgeValue={badge}
                active={selectedBadges.some((item) => item.value === badge.value)}
                onClick={handleOnClickLabel}
                key={badge.value}
            />
        );
    });

    return (
        <div className={`badge-wrapper ${className}`}>
            {badges}
            {children}
        </div>
    );
};

export default React.memo(MultipleBadges);
