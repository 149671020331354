export enum CurrentNoteStatus {
    Empty = 'Empty',
    Today = 'Today',
    Overdue = 'Overdue',
    ScheduledForTomorrow = 'ScheduledForTomorrow',
}

export interface INoteStatusReponse {
    status: CurrentNoteStatus;
}
