import { InfiniteServerClient } from 'common/helpers/http-clients';
import { IReferralSource } from './types';
export class ReferralSourceService {
    getAll(): Promise<IReferralSource[]> {
        return InfiniteServerClient.get<IReferralSource[]>('/referral/sources').then(
            (response) => {
                return response.data;
            },
            (error) => {
                throw error;
            }
        );
    }
    getExternalReferalSourceOptions(): Promise<IReferralSource[]> {
        return InfiniteServerClient.get<IReferralSource[]>('/contacts').then(
            (response) => {
                return response.data;
            },
            (error) => {
                throw error;
            }
        );
    }

    getExternalReferalSourceAutocompleteOptions(term: string, skip: number, take: number): Promise<IReferralSource[]> {
        return InfiniteServerClient.get<IReferralSource[]>('/contacts/options', { params: { term, skip, take } }).then(
            (response) => {
                return response.data;
            },
            (error) => {
                throw error;
            }
        );
    }
}

export default new ReferralSourceService();
