import InputField from 'common/components/field/input-field';
import InfiniteInput from 'common/components/infinite-input/infinite-input';
import { FieldValue } from 'common/models/field-value';
import React, { useEffect, useState } from 'react';
import { RadiusFieldProps } from './types';

const RadiusField: React.FC<RadiusFieldProps> = ({ onChange, value, className }) => {
    const [isEdit, setEditMode] = useState<boolean>(false);
    const editFieldRef = React.createRef<HTMLInputElement>();

    const handleRadiusChange = (radius: FieldValue) => {
        onChange(radius);
    };

    const handleEditModeBlur = () => {
        setEditMode(false);
    };

    const handleClickOnViewMode = () => {
        setEditMode(true);
    };

    useEffect(() => {
        if (isEdit) {
            editFieldRef?.current?.focus();
        }
    }, [editFieldRef, isEdit]);

    return (
        <div className={className}>
            <InputField
                customClass={`${isEdit ? 'display' : 'hide'}`}
                inputRef={editFieldRef}
                placeholder="Radius"
                onBlur={handleEditModeBlur}
                allowOnlyDigits={true}
                value={value}
                maxLength={3}
                onChange={handleRadiusChange}
            />

            <InfiniteInput
                customClass={`${!isEdit ? 'display' : 'hide'}`}
                onFocus={handleClickOnViewMode}
                placeholder="Radius"
                value={`${value} Miles`}
            />
        </div>
    );
};

export default React.memo(RadiusField);
