import { Reducer } from '@reduxjs/toolkit';
import { ReferralSourceActionTypes, IReferralSourceState } from './types';

const initialState: IReferralSourceState = {
    data: undefined,
};

export const referralSourceReducer: Reducer<IReferralSourceState, ReferralSourceActionTypes> = (state = initialState, action) => {
    switch (action.type) {
        case 'SET_REFERRAL_SOURCES':
            return { ...state, data: action.referralSources };
        default:
            return state;
    }
};
