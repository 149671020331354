import therapistDashboardService from 'common/services/api/therapist-dahsboard/therapist-dashboard-service';
import { IMyFollowUpWidgetFilter, ITherapistDashboardItem, IWidgetOptions } from 'common/services/api/therapist-dahsboard/types';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { DateFilter } from '../date-filter/date-filter';
import { IDateRange } from '../date-filter/types';
import { FilterSection } from '../filter-section/filter-section';
import { OverdueFilter } from '../overdue-filter/overdue-filter';
import { WidgetHeader } from '../widget-header/widget-header';
import { WidgetTable } from '../widget-table/widget-table';
import useWidget from 'features/dashboard-page/hooks/use-widget';
import { IconButton } from '../icon-button/icon-button';
import { ExportType } from 'common/constants/export-options';
import { NavigationRoutes } from 'models/routes/navigation-routes';
import { MyFollowUpWidgetProps } from './types';
import { OverdueFilterOption } from '../overdue-filter/types';
import { trackPromise } from 'react-promise-tracker';

const defaultFilters: IMyFollowUpWidgetFilter = {
    pageIndex: 1,
    limit: 5,
    isOverdue: false,
    onlyToday: true,
    assignedTo: [],
};

const MyFollowUpWidget: React.FC<MyFollowUpWidgetProps> = ({ isSupervisorView, globalFilters }) => {
    const [filter, totalCount, repository, fetchData, handleFilterChange, , handleExport] = useWidget({
        title: 'My Follow Up',
        defaultFilter: defaultFilters,
        fetchFunction: therapistDashboardService.getMyFollowUpWidgetData,
        exportFunction: therapistDashboardService.exportMyFollowUpWidgetData,
        isSupervisorView: isSupervisorView,
    });

    useEffect(() => {
        if (globalFilters) {
            handleFilterChange({ ...filter, assignedTo: globalFilters.assignedTo });
        }
    }, [globalFilters]);

    const widgetOptionsRef = useRef<IWidgetOptions>();
    const [widgetIsReady, setWidgetReady] = useState(false);

    useEffect(() => {
        if (isSupervisorView) {
            trackPromise(
                therapistDashboardService.getMyFollowUpWidgetOptions(filter.startDate, filter.endDate).then((data) => {
                    widgetOptionsRef.current = data;
                    setWidgetReady(true);
                })
            );
        } else {
            setWidgetReady(true);
        }
    }, [filter.endDate, filter.startDate, isSupervisorView]);

    const handleDateChange = (value: IDateRange) => {
        handleFilterChange({ ...filter, startDate: value.startDate, endDate: value.endDate });
    };

    const handleClickOnExport = () => {
        handleExport(ExportType.XLSX);
    };

    const getOverdueFilterValue = useCallback(() => {
        if (filter.onlyToday) {
            return OverdueFilterOption.TODAY;
        }

        return filter.isOverdue ? OverdueFilterOption.OVERDUE : OverdueFilterOption.ALL;
    }, [filter.isOverdue, filter.onlyToday]);

    const handleOverdueFilterChange = (value: OverdueFilterOption) => {
        handleFilterChange({ ...filter, isOverdue: value === OverdueFilterOption.OVERDUE, onlyToday: value === OverdueFilterOption.TODAY });
    };

    const handleRowClick = (therapist: ITherapistDashboardItem) => {
        window.open(NavigationRoutes.therapistDetailsRoute(therapist?.id) + '/communication/notes', '_blank').focus();
    };

    return widgetIsReady ? (
        <>
            <WidgetHeader title="My Follow Up" totalCount={totalCount}>
                {/*<IconButton icon="icon-open-in" />*/}
                <IconButton icon="icon-export" onClick={handleClickOnExport} />
            </WidgetHeader>
            <FilterSection className="my-follow-up-filters">
                <OverdueFilter
                    value={getOverdueFilterValue()}
                    onChange={handleOverdueFilterChange}
                    options={[OverdueFilterOption.ALL, OverdueFilterOption.OVERDUE, OverdueFilterOption.TODAY]}
                />
                <DateFilter
                    className="small-size"
                    value={{ startDate: filter.startDate, endDate: filter.endDate }}
                    onChange={handleDateChange}
                />
            </FilterSection>
            <WidgetTable
                filter={filter}
                fetchFunction={fetchData}
                openTherapist={false}
                widgetOptions={widgetOptionsRef}
                repository={repository}
                onRowClick={handleRowClick}
                supervisorView={isSupervisorView}
            />
        </>
    ) : (
        <></>
    );
};

export default React.memo(MyFollowUpWidget);
