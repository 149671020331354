import {
    ColDef,
    ColumnApi,
    FirstDataRenderedEvent,
    GridApi,
    GridOptions,
    IServerSideGetRowsParams,
    IServerSideGetRowsRequest,
    PostProcessPopupParams,
    ServerSideStoreType,
} from 'ag-grid-community';
import { GridReadyEvent, RowSelectedEvent, SelectionChangedEvent } from 'ag-grid-community/dist/lib/events';
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-alpine.css';
import 'ag-grid-enterprise';
import { AgGridReact } from 'ag-grid-react';
import { IRepository } from 'common/helpers/repository/types';
import { transformDateToBase64 } from 'common/helpers/transform-date-to-base64';
import queryString from 'query-string';
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { trackPromise } from 'react-promise-tracker';
import { useLocation } from 'react-router';
import CustomHeader from './custom-header/custom-header';
import AgCustomSetColumnFilter from './filters/ag-custom-set-column-filter/ag-custom-set-column-filter';
import PersonFilter from './filters/partial-match-filter';
import TotalRowBar from './status-bars/total-row-bar/total-row-bar';
import './old-table.scss';
import './table.scss';
import { IParamsWatcher, ISortParams } from './types';
import { LicenseManager } from 'ag-grid-enterprise';
import { checkResize } from 'common/helpers/dom-helper';
import { useCustomColumnMove } from './hooks/use-custom-column-move';
const debounce = require('lodash.debounce');

type AgAPIObject = {
    gridApi: GridApi;
    gridColumnApi: ColumnApi;
};

type AgTableComponentProps = {
    globalFilterPosition?: boolean;
    gridOptions: GridOptions;
    onFetchData?(event: IServerSideGetRowsParams, props: any): Promise<any>;
    preloader?: boolean;
    customFilters?: any;
    absoluteShadow?: boolean;
    disableShadow?: boolean;
    onGridReady?(event: GridReadyEvent): void;
    paramsWatcher?: React.MutableRefObject<IParamsWatcher>;
    repository?: IRepository<any>;
    onDataChange?(data: any, params?: IParamsWatcher): void;
    onFiltersLoadedFromUrl?(params?: FirstDataRenderedEvent): void;
    autoResize?: boolean;
    onSelectionChanged?(event: SelectionChangedEvent): void;
    onRowSelected?(event: RowSelectedEvent): void;
    getRowNodeId?(data: any): string;
    adaptiveHeight?: boolean;
    getAllData?: boolean;
    rowData?: any[];
    clientOptions?: {
        rowData: any[];
    };
    clientSide?: boolean;
    gridRef?: any;
    // Ease way to hide a columns dynamic
    hideColumns?: {
        [key: string]: boolean;
    };
    // When you need to manage multiple states
    agAPIs?: AgAPIObject[];
};

const AgTable = ({
    globalFilterPosition = false,
    gridOptions = {},
    preloader = false,
    customFilters,
    onGridReady,
    onFetchData,
    absoluteShadow,
    onDataChange,
    repository,
    paramsWatcher,
    autoResize = false,
    onFiltersLoadedFromUrl,
    disableShadow,
    onSelectionChanged,
    onRowSelected,
    getRowNodeId,
    getAllData,
    adaptiveHeight,
    clientOptions,
    gridRef,
    hideColumns,
    agAPIs,
}: AgTableComponentProps) => {
    const gridRefDefault = useRef<any>();
    const gridRefWrapper = useRef<HTMLDivElement>();
    const [loading, setLoading] = useState(preloader);
    const customFiltersParams = useRef(customFilters);
    const location = useLocation();
    let clientSide = false;
    if (clientOptions) {
        clientSide = true;
    }
    useEffect(() => {
        if (gridRefDefault?.current?.api) {
            gridRefDefault.current.api.onFilterChanged();
        }
        customFiltersParams.current = customFilters;
    }, [customFilters]);

    // shortcut for hide a column
    useEffect(() => {
        if (gridRefDefault.current.columnApi && hideColumns) {
            setTimeout(() => {
                Object.keys(hideColumns).forEach((field) => {
                    gridRefDefault.current.columnApi.setColumnVisible(field, hideColumns[field]);
                });
            }, 100);
        }
    }, [hideColumns]);

    useEffect(() => {
        if (gridRefDefault?.current?.api && agAPIs) {
            agAPIs.push({ gridApi: gridRefDefault?.current?.api, gridColumnApi: gridRefDefault?.current?.columnApi });
        }
    }, [gridRefDefault?.current?.api, gridRefDefault?.current?.columnApi, agAPIs]);

    useCustomColumnMove(gridRefDefault, gridRefWrapper);

    const isFirstRender = useRef(true);
    const handleSaveGridColumnState = useCallback(
        debounce(
            (e: any) => {
                if (isFirstRender.current) {
                    return;
                }

                if (!repository || !e?.columnApi) {
                    return;
                }

                const gridApi = e?.api;
                const gridColumnApi = e?.columnApi;
                const columnState = gridColumnApi?.getColumnState();
                const columnGroupState = gridColumnApi?.getColumnGroupState();
                repository.add({
                    filterModel: gridApi.getFilterModel(),
                    sortModel: gridApi.getSortModel(),
                    columnState,
                    columnGroupState,
                });
            },
            clientSide ? 10 : 200
        ),
        []
    );

    const pinToggleLastWidth = useRef<boolean>(null);
    useEffect(() => {
        const handleResize = () => {
            pinToggle(gridRefDefault, gridRefWrapper, pinToggleLastWidth);
        };
        const content = gridRefWrapper.current.querySelector('.ag-root');
        content.addEventListener('resize', handleResize);

        const observer = new MutationObserver(checkResize);
        observer.observe(content, { attributes: true, attributeOldValue: true });

        return () => {
            observer.disconnect();
            content.removeEventListener('resize', handleResize);
        };
    }, []);

    const configGrid: GridOptions = useMemo<GridOptions>(
        () => ({
            rowBuffer: 0,
            serverSideStoreType: ServerSideStoreType.Partial,
            cacheBlockSize: getAllData ? Number.MAX_SAFE_INTEGER : 20,
            maxBlocksInCache: 1, // max sum of cacheBlockSize  // https://www.ag-grid.com/react-grid/server-side-model-configuration/
            serverSideFilteringAlwaysResets: true,
            maxConcurrentDatasourceRequests: 1, // default 1
            blockLoadDebounceMillis: 200,
            rowSelection: 'single', // https://www.ag-grid.com/react-grid/row-selection/
            rowModelType: clientSide ? 'clientSide' : 'serverSide', // https://www.ag-grid.com/react-
            paginationPageSize: getAllData || clientSide ? Number.MAX_SAFE_INTEGER : 20,
            cacheOverflowSize: 2,
            infiniteInitialRowCount: 100,
            sortingOrder: ['desc', null], //'asc',
            headerHeight: 50,
            rowHeight: 40,
            colResizeDefault: '',
            suppressContextMenu: true,
            popupParent: globalFilterPosition ? document.querySelector('#popups') : null,
            postProcessPopup: function (params: PostProcessPopupParams) {
                if (params.type !== 'columnMenu') {
                    return;
                }

                const colDef = params.column.getColDef();
                const ePopup = params.ePopup;
                ePopup.style.top = 56 + 'px';

                const filterHeader: HTMLDivElement = ePopup.querySelector('.ag-menu-header');
                if (filterHeader) {
                    filterHeader.style.display = 'none';
                }

                if (globalFilterPosition) {
                    document.body.classList.add('no-scroll');
                    const x = new MutationObserver(function (e, d) {
                        if (e[0].removedNodes) {
                            d.disconnect();
                            document.body.classList.remove('no-scroll');
                        }
                    });

                    x.observe(document.getElementById('popups'), { childList: true });
                }

                const getPositionAndWidth = (globalFilterPosition: boolean = false) => {
                    const headerCell = params.eventSource.getBoundingClientRect();
                    const table = params.eventSource.closest('.ag-table-main').getBoundingClientRect();
                    const minOverflowWidth = 270;
                    const minWidth = 150;
                    const start = globalFilterPosition ? Math.max(headerCell.left, table.left) : headerCell.left - table.left;
                    const leftOverflow = headerCell.left < table.left;
                    const tableWidth = globalFilterPosition ? table.left + table.width : table.width;
                    const rightOverflow =
                        start + headerCell.width >= tableWidth || start + minWidth >= tableWidth || start + minOverflowWidth >= tableWidth;

                    let width: number, startPosition: number;
                    if (!leftOverflow && !rightOverflow) {
                        startPosition = globalFilterPosition ? headerCell.left : headerCell.left - table.left;
                        width = headerCell.width < minWidth ? minOverflowWidth : headerCell.width;
                    } else if (leftOverflow) {
                        startPosition = globalFilterPosition ? table.left : 0;
                        width = minOverflowWidth;
                    } else if (rightOverflow) {
                        width = Math.max(minOverflowWidth, tableWidth - (headerCell.left - (globalFilterPosition ? 0 : table.left)));
                        startPosition = tableWidth - width - 3;
                        width -= 3;
                    }

                    return { startPosition, width };
                };

                if (colDef.filter === 'agCustomFilter' || colDef.filter === 'agSetColumnFilter') {
                    const header = params.eventSource.getBoundingClientRect();
                    const popupConfig = getPositionAndWidth(globalFilterPosition);
                    ePopup.classList.add('ag-set-filter-popup');
                    ePopup.classList.add('ag-custom-filter-popup');
                    ePopup.style.top = globalFilterPosition ? header.top + 'px' : '0px';
                    ePopup.style.left = popupConfig.startPosition + 'px';

                    const resetPlaceHolder = () => {
                        if (ePopup.querySelector('.ag-text-field-input-wrapper input'))
                            ePopup.querySelector('.ag-text-field-input-wrapper input').setAttribute('placeholder', ' ');
                    };

                    const addLabel = () => {
                        if (
                            !ePopup.querySelector('.ag-text-field-input-wrapper label') &&
                            ePopup.querySelector('.ag-text-field-input-wrapper')
                        ) {
                            const label = document.createElement('label');
                            label.innerText = colDef.headerName;
                            label.classList.add('placeholder');
                            ePopup.querySelector('.ag-text-field-input-wrapper').append(label);
                            const input: HTMLInputElement = ePopup.querySelector('.ag-text-field-input-wrapper input');
                            const cancelBtn = ePopup.querySelector('button[ref=cancelFilterButton]');

                            const fixFocusPlaceholder = () => {
                                input.classList.add('focus-input');
                                ePopup.querySelector('.ag-text-field-input-wrapper input').addEventListener('focus', () => {
                                    input.classList.add('focus-input');
                                });
                                ePopup.querySelector('.ag-text-field-input-wrapper input').addEventListener('blur', () => {
                                    if (input?.value?.length > 0) {
                                        return;
                                    }
                                    input.classList.remove('focus-input');
                                });
                            };
                            fixFocusPlaceholder();

                            const fixClickOutside = () => {
                                ePopup.querySelector('.ag-wrapper.ag-input-wrapper').addEventListener('click', (e: any) => {
                                    const inputBounding = input.getBoundingClientRect();

                                    if (inputBounding.left + inputBounding.width < e?.clientX && cancelBtn) {
                                        cancelBtn.dispatchEvent(new CustomEvent('click'));
                                    }
                                });
                            };
                            fixClickOutside();
                        }
                        resetPlaceHolder();
                    };

                    const x = new MutationObserver(function (e, d) {
                        if (e[0].addedNodes.length) {
                            addLabel();
                            if (ePopup.querySelector('input')) {
                                const input: HTMLInputElement = ePopup.querySelector('input');
                                input.style.width = input.style.maxWidth = input.style.minWidth = popupConfig.width + 'px';
                            }
                            d.disconnect();
                        }
                    });

                    x.observe(ePopup, { childList: true, subtree: true });
                } else {
                    if (globalFilterPosition) {
                        ePopup.style.top = params.eventSource.getBoundingClientRect().top + 56 + 'px';
                    }
                }
            },
            ...gridOptions,
            onColumnVisible: (params) => {
                handleSaveGridColumnState(params);
                pinToggle(gridRefDefault, gridRefWrapper, pinToggleLastWidth);

                if (gridOptions?.onColumnVisible) {
                    gridOptions.onColumnVisible(params);
                }
            },
            onColumnPinned: (params) => {
                handleSaveGridColumnState(params);

                if (gridOptions?.onColumnPinned) {
                    gridOptions.onColumnPinned(params);
                }
            },
            onColumnResized: (params) => {
                handleSaveGridColumnState(params);
                pinToggle(gridRefDefault, gridRefWrapper, pinToggleLastWidth);

                if (gridOptions?.onColumnResized) {
                    gridOptions.onColumnResized(params);
                }
            },
            onColumnMoved: (params) => {
                handleSaveGridColumnState(params);
                pinToggle(gridRefDefault, gridRefWrapper, pinToggleLastWidth);

                if (gridOptions?.onColumnMoved) {
                    gridOptions.onColumnMoved(params);
                }
            },
            onColumnRowGroupChanged: (params) => {
                handleSaveGridColumnState(params);

                if (gridOptions?.onColumnRowGroupChanged) {
                    gridOptions.onColumnRowGroupChanged(params);
                }
            },
            onColumnValueChanged: (params) => {
                handleSaveGridColumnState(params);

                if (gridOptions?.onColumnValueChanged) {
                    gridOptions.onColumnValueChanged(params);
                }
            },
            onColumnPivotChanged: (params) => {
                handleSaveGridColumnState(params);

                if (gridOptions?.onColumnPivotChanged) {
                    gridOptions.onColumnPivotChanged(params);
                }
            },
            onFilterChanged: (params) => {
                handleSaveGridColumnState(params);

                if (gridOptions?.onFilterChanged) {
                    gridOptions.onFilterChanged(params);
                }
            },
            onFirstDataRendered: (params) => {
                if (!repository) {
                    return;
                }
                const queryObject = queryString.parse(location.search);
                let blockRepositoryLoader = false;
                const gridApi = params.api;
                const gridColumnApi = params.columnApi;

                // Apply filters from URL
                configGrid.columnDefs.forEach((column: ColDef) => {
                    const field = column?.field;
                    if (field && queryObject[field] !== undefined && column.filter === 'agCustomFilter') {
                        blockRepositoryLoader = true;
                        gridApi.getFilterInstance(field, (filterApi) => {
                            filterApi.setModel({
                                exclude:
                                    typeof queryObject['SelectAllColumns'] === 'string'
                                        ? queryObject['SelectAllColumns'] === field
                                        : queryObject['SelectAllColumns']?.includes(field),
                                filterType: 'customSet',
                                values:
                                    typeof queryObject[column?.field] === 'string'
                                        ? [queryObject[column?.field]]
                                        : queryObject[column?.field],
                            });
                        });
                    }
                });

                // Load model from repository
                if (!blockRepositoryLoader) {
                    const savedSate = repository.load();

                    if (savedSate?.columnState) {
                        // fix for flex column
                        const columns: ColDef[] = gridApi.getColumnDefs();
                        for (let i = 0; i < columns?.length; i++) {
                            const indexOfFlexColumn = savedSate?.columnState.findIndex((column: any) => columns[i].colId === column.colId);

                            if (indexOfFlexColumn > -1 && columns[indexOfFlexColumn]?.flex && savedSate?.columnState[indexOfFlexColumn]) {
                                savedSate.columnState[indexOfFlexColumn].flex = columns[indexOfFlexColumn]?.flex;
                                delete savedSate.columnState[indexOfFlexColumn].width;
                            }
                        }

                        gridColumnApi.setColumnState(savedSate.columnState);
                    }

                    if (savedSate?.columnGroupState) {
                        gridColumnApi.setColumnGroupState(savedSate.columnGroupState);
                    }

                    if (savedSate?.filterModel) {
                        gridApi.setFilterModel(savedSate.filterModel);
                    }

                    // Set a default sort form store
                    if (savedSate?.sortModel?.length === 0) {
                        const sortableColumn: ColDef = gridApi.getColumnDefs().find((column: ColDef) => column?.initialSort);
                        if (sortableColumn) {
                            gridApi.setSortModel([
                                {
                                    colId: sortableColumn.colId,
                                    sort: sortableColumn.initialSort,
                                },
                            ]);
                        }
                    } else if (savedSate?.sortModel) {
                        gridApi.setSortModel(savedSate.sortModel);
                    }
                } else if (onFiltersLoadedFromUrl) {
                    onFiltersLoadedFromUrl(params);
                }

                if (gridOptions?.onFirstDataRendered) {
                    gridOptions.onFirstDataRendered(params);
                }
                pinToggle(gridRefDefault, gridRefWrapper, pinToggleLastWidth);
                isFirstRender.current = false;
            },
            defaultColDef: {
                enableRangeSelection: true,
                resizable: true,
                sortable: true,
                suppressMovable: true,
                menuTabs: ['filterMenuTab'], //'generalMenuTab', 'columnsMenuTab' //https://www.ag-grid.com/react-grid/column-menu/#example-column-menu
                ...gridOptions.defaultColDef,
            },
            components: {
                ...gridOptions.components,
            },
            frameworkComponents: {
                personFilter: PersonFilter,
                agColumnHeader: CustomHeader,
                agCustomFilter: AgCustomSetColumnFilter,
                totalRowBar: TotalRowBar,
                ...gridOptions.frameworkComponents,
            },
            statusBar: {
                statusPanels: [
                    {
                        statusPanel: 'totalRowBar',
                        key: 'totalRowBarKey',
                        align: 'left',
                    },
                ],
                ...gridOptions.statusBar,
            },
        }),
        [gridOptions]
    );

    const getSortParams = useCallback((request: IServerSideGetRowsRequest) => {
        const sortParams: ISortParams = {};
        const sortModel = request.sortModel;
        if (sortModel.length > 0) {
            sortParams.orderBy = sortModel[0]?.colId;
            sortParams.byDescending = sortModel[0].sort === 'desc';
        }

        return sortParams;
    }, []);

    const getFilterModelParams = useCallback(
        (request: IServerSideGetRowsRequest) => {
            const filterParams: any = {
                SelectAllColumns: [],
                IncludeEmpty: [],
            };

            const filterModel = request.filterModel;
            if (!filterModel) return '';

            Object.keys(filterModel).forEach((key) => {
                const filter = filterModel[key];
                switch (filter.filterType) {
                    case 'customSet':
                        if (filter.exclude) filterParams.SelectAllColumns.push(key);
                        if (filter.isNullable) filterParams.IncludeEmpty.push(key);
                        filterParams[key] = filter.values;
                        break;
                    case 'date': {
                        const dateColDef: any = gridOptions.columnDefs.find((i: any) => i.field === key);

                        filterParams[key] = btoa(
                            JSON.stringify({
                                firstCondition: transformDateToBase64(filter.condition1 || filter, dateColDef?.filterParams?.isDateTime),
                                secondCondition: transformDateToBase64(filter.condition2, dateColDef?.filterParams?.isDateTime),
                                operator: filter.operator,
                            })
                        );
                        break;
                    }
                    default:
                        filterParams[key] = filter.value ?? filter.values;
                }
            });

            return filterParams;
        },
        [gridOptions.columnDefs]
    );

    const getLastRowIndex = useCallback(
        (request: IServerSideGetRowsRequest, results: any[]) => {
            if (!results) return undefined;
            const currentLastRow = request.startRow + results.length;

            if (gridRefWrapper.current && currentLastRow > 2 && adaptiveHeight && getAllData) {
                gridRefWrapper.current.style.height = currentLastRow * 40 + 70 + 'px';
            }

            return getAllData || currentLastRow < request.endRow ? currentLastRow : undefined;
        },
        [adaptiveHeight, getAllData]
    );

    const onGridReadyDefault = useCallback((onGridParams: GridReadyEvent) => {
        const dataSource = {
            getRows: function (params: IServerSideGetRowsParams & { request: IServerSideGetRowsRequest & { groupKeys: any[] } }) {
                const request = params.request;

                const requestProps: any = {
                    configGrid,
                    request,
                    getCustomFilterParams: customFiltersParams.current,
                    getSortParams,
                    getFilterModelParams,
                };

                if (!getAllData) {
                    requestProps['limit'] = configGrid.paginationPageSize;
                    requestProps['pageIndex'] = request.endRow / configGrid.paginationPageSize;
                }

                if (paramsWatcher) {
                    paramsWatcher.current = {
                        gridApi: onGridParams.api,
                        gridColumnApi: onGridParams.columnApi,
                        ...requestProps,
                    };
                }

                // Save in repository
                if (repository) {
                    repository.add({
                        filterModel: request.filterModel,
                        sortModel: onGridParams.api.getSortModel(), //request.sortModel, : onGridParams.api.getSortModel(),
                    });
                }

                const fetchCallBack = ({ data }: any) => {
                    if (onDataChange && paramsWatcher) {
                        onDataChange(data, paramsWatcher.current);
                    }
                    params.success({
                        rowData: data,
                        rowCount: getLastRowIndex(request, data),
                    });
                    if (autoResize && paramsWatcher) {
                        setTimeout(() => {
                            autoSizeAll(paramsWatcher.current.gridColumnApi, false);
                        }, 300);
                    }
                };

                if (request.groupKeys?.length > 0) {
                    const data = request.groupKeys[0];
                    params.success({
                        rowData: data,
                        rowCount: getLastRowIndex(request, data),
                    });
                } else {
                    const requestPromise = onFetchData(params, requestProps).then(fetchCallBack);
                    if (preloader && loading) {
                        setLoading(false);
                        trackPromise(requestPromise);
                    }
                }
            },
        };
        onGridParams.api.setServerSideDatasource(dataSource);
    }, []);

    // Left Scroll
    const [isShadowClassEnabled, setShadowClassEnabled] = useState(false);

    const isHorizontalScrollGetStart = (tableBody: any) => {
        setShadowClassEnabled(tableBody.scrollLeft !== 0);
    };

    useEffect(() => {
        const tableBody = document.getElementsByClassName('ag-body-horizontal-scroll-viewport')[0];
        if (tableBody && !disableShadow)
            tableBody.addEventListener('scroll', () => {
                isHorizontalScrollGetStart(tableBody);
            });
    }, [disableShadow]);

    const shadow = absoluteShadow ? 'left-shadow-absolute' : 'left-shadow';

    useEffect(() => {
        if (gridRefDefault.current.api) {
            if (clientSide) {
                const requestProps: any = {
                    configGrid,
                    request: {
                        filterModel: gridRefDefault.current.api.getFilterModel(),
                        sortModel: gridRefDefault.current.api.getSortModel(),
                    },
                    getCustomFilterParams: customFiltersParams.current,
                    getSortParams,
                    getFilterModelParams,
                };

                if (paramsWatcher) {
                    paramsWatcher.current = {
                        gridApi: gridRefDefault.current.api,
                        gridColumnApi: gridRefDefault.current.columnApi,
                        ...requestProps,
                    };
                }
            }

            if (onGridReady) {
                onGridReady({
                    api: gridRefDefault.current.api,
                    columnApi: gridRefDefault.current.columnApi,
                } as GridReadyEvent);
            }

            if (gridRef) {
                gridRef.current = gridRefDefault.current;
            }
        }
    }, [gridRefDefault.current]);

    return (
        <>
            <div className={isShadowClassEnabled ? `${shadow} active` : `${shadow}`}></div>
            <div className="ag-table-main">
                <div id="myGrid" className="ag-theme-alpine ag-table" ref={gridRefWrapper}>
                    <AgGridReact
                        ref={gridRefDefault}
                        rowData={clientOptions?.rowData}
                        gridOptions={configGrid}
                        onGridReady={!clientSide ? onGridReadyDefault : null}
                        onSelectionChanged={onSelectionChanged}
                        onRowSelected={onRowSelected}
                        getRowNodeId={getRowNodeId}
                    />
                </div>
            </div>
        </>
    );
};

const autoSizeAll = (gridColumnApi: any, skipHeader: boolean) => {
    if (!gridColumnApi) {
        return;
    }
    const allColumnIds: any = [];
    gridColumnApi.getAllColumns()?.forEach(function (column: any) {
        allColumnIds.push(column.getColId());
    });
    gridColumnApi.autoSizeColumns(allColumnIds, skipHeader);
};

const pinToggle = (gridRefDefault: any, gridRefWrapper: any, pinToggleLastWidth: any) => {
    if (!gridRefDefault.current) {
        return;
    }
    const colDef: ColDef[] = gridRefDefault.current.gridOptions.columnDefs;
    const columnApi: ColumnApi = gridRefDefault.current.columnApi;

    let sumWidthVisibleColumns = 0;
    columnApi.getAllDisplayedColumns().forEach((column) => {
        sumWidthVisibleColumns += column.getActualWidth();
    });

    const agHeaderContainer = document.querySelector('.ag-header-container');
    if (!agHeaderContainer) {
        return;
    }
    const rowPadding = parseInt(window.getComputedStyle(agHeaderContainer, null).paddingLeft);
    const tableWidth = gridRefWrapper.current.clientWidth - rowPadding;
    const visableColumnWidth = sumWidthVisibleColumns <= tableWidth;

    // eslint-disable-next-line prettier/prettier
    if (visableColumnWidth !== pinToggleLastWidth.current) {
        pinToggleLastWidth.current = sumWidthVisibleColumns <= tableWidth;
        const pinned = colDef
            .filter((column) => column.pinned)
            .map((column) => ({
                colId: column.colId || column.field,
                pinned: sumWidthVisibleColumns <= tableWidth ? undefined : column.pinned,
            }));

        columnApi.applyColumnState({
            state: pinned,
            defaultState: { pinned: null },
        });
    }
};

if (process.env.REACT_APP_AG_GRID_LICENSE_KEY?.length > 0) {
    LicenseManager.setLicenseKey(process.env.REACT_APP_AG_GRID_LICENSE_KEY);
}

export default React.memo(AgTable);
