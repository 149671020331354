import React, { useCallback, useEffect, useRef, useState } from 'react';
import { IFontSizeMap, IIcon, TherapistMapWrapperProps } from './types';
import Map from 'common/components/map/google-map/google-map';
import { Marker } from '@react-google-maps/api';
import MapLegend from '../map-legend/map-legend';
import { defaultIconArray, iconMap } from 'common/constants/map/map';

import './styles.scss';
import { DisplayAddressType, IMapPoint } from 'common/services/api/map/types';
import { toast } from 'react-toastify';
import { FilterHelper } from '../../../helpers/filter-helper';
import { debounce } from 'lodash';

const fontSizeMap: { [key: number]: IFontSizeMap } = {
    1: {
        size: '15px',
        point: { x: 14, y: 16 },
    },
    2: {
        size: '14px',
    },
    3: {
        size: '12px',
    },
    4: {
        size: '10px',
    },
};

const getSymbol = (props: any) => {
    return {
        path: 'M 0,0 C -2,-20 -10,-22 -10,-30 A 10,10 0 1,1 10,-30 C 10,-22 2,-20 0,0 z M -2,-30 a 2,2 0 1,1 4,0 2,2 0 1,1 -4,0',
        fillColor: props.color ?? 'grey',
        fillOpacity: 1,
        strokeColor: props.strokeColor ?? '#FFF',
        strokeWeight: props.strokeWeight ?? 2,
        scale: props.scale ?? 0.77,
    };
};

export const MapWrapper: React.FC<TherapistMapWrapperProps> = ({
    points,
    center,
    radius,
    children,
    onMarkerClick,
    onDisplayTypeChange,
    iconArray = defaultIconArray,
    onManualDisplayTypeChange,
}) => {
    const [selectedMarkers, setSelectedMarkers] = useState([]);

    const getMarkerType = useCallback((item: IMapPoint) => {
        if (item.isCluster) {
            return item.data.some((i) => i.type === DisplayAddressType.Request)
                ? DisplayAddressType.ClusterSearch
                : DisplayAddressType.Cluster;
        }

        return item.data[0].type;
    }, []);

    const createMarker = (item: IMapPoint, index: number) => {
        const type = getMarkerType(item);
        const iconProps: IIcon = iconMap[type];
        const count = '' + item.data.length;
        const label: google.maps.MarkerLabel =
            type === DisplayAddressType.Preference && item
                ? {
                      text: count,
                      color: '#FFFFFF',
                      fontSize: fontSizeMap[count.length]?.size || '10px',
                      fontWeight: 'bold',
                  }
                : null;
        const point = fontSizeMap[count.length]?.point;
        const position: google.maps.LatLng = new window.google.maps.LatLng(item.latitude, item.longitude);

        const isSelected = () => {
            return selectedMarkers.find((selectedMarker) => selectedMarker.lat === item.latitude && selectedMarker.lng === item.longitude);
        };

        const handleSelectMarker = () => {
            if (!isSelected()) {
                if (selectedMarkers.length >= 10) {
                    toast.error('The maximum number of destinations has been reached');

                    return;
                }

                new google.maps.Geocoder().geocode({ location: position }, (result, status) => {
                    if (status !== google.maps.GeocoderStatus.OK) {
                        console.error(`Google geocoding API returned '${status} status'`);
                    } else {
                        setSelectedMarkers((prev) => {
                            return [
                                ...prev,
                                {
                                    lat: item.latitude,
                                    lng: item.longitude,
                                    place_id: result[0].place_id,
                                    address: result[0].formatted_address,
                                },
                            ];
                        });
                    }
                });
            } else {
                setSelectedMarkers((prev) => {
                    return prev.filter((selectedMarker) => selectedMarker.lat !== item.latitude && selectedMarker.lng !== item.longitude);
                });
            }
        };

        const handleOnMarkerClick = (event: any) => {
            if (event.domEvent.ctrlKey) {
                handleSelectMarker();
            } else if (getMarkerType(item) !== DisplayAddressType.Request) {
                onMarkerClick(item);
            }
        };

        return (
            <Marker
                onClick={handleOnMarkerClick}
                key={index}
                position={position}
                icon={
                    isSelected()
                        ? getSymbol(selectedIcon)
                        : iconProps?.icon
                        ? {
                              url: iconProps?.icon,
                              labelOrigin: point ? new google.maps.Point(point.x, point.y) : new google.maps.Point(13, 16),
                          }
                        : getSymbol(iconProps)
                }
                label={label}
            />
        );
    };

    const [customCenter, setCustomCenter] = useState(null);
    const previewCenter = useRef(null);

    useEffect(() => {
        setCustomCenter(center);
        previewCenter.current = center;
    }, [center]);

    const legendRef = React.createRef();
    const mapRef = useRef<google.maps.Map>();
    const handleMapLoaded = (map: any) => {
        map.controls[google.maps.ControlPosition.RIGHT_BOTTOM].push(legendRef.current);

        const handleCenterChanged = debounce(() => {
            console.log('center_changed');
            if (previewCenter.current.latitude !== map.center.lat() || previewCenter.current.longitude !== map.center.lng()) {
                previewCenter.current = {
                    latitude: map.center.lat(),
                    longitude: map.center.lng(),
                };
                setCustomCenter(previewCenter.current);
            }
        }, 500);

        google.maps.event.addListener(map, 'center_changed', handleCenterChanged);
        mapRef.current = map;
    };

    const [zoom, setZoom] = useState(13);
    useEffect(() => {
        const getZoomLevel = () => {
            let zoomLevel = 13;
            if (radius) {
                const scale = (radius * 1000) / 500;
                zoomLevel = 16 - Math.log(scale) / Math.log(2);
            }

            return zoomLevel;
        };
        setZoom(getZoomLevel());
    }, [radius, mapRef.current]);

    const handleCreateRoute = () => {
        const url = 'https://www.google.com/maps/dir/?api=1&';

        if (selectedMarkers.length <= 1) {
            toast.error('Should select at least two waypoints for forming directions');

            return;
        }

        // Generate URL
        let waypoint_place_ids = '',
            waypoints = '';
        for (let i = 1; i < selectedMarkers.length - 1; ++i) {
            waypoint_place_ids += (waypoints === '' ? '' : '|') + selectedMarkers[i].place_id;
            waypoints += (waypoints === '' ? '' : '|') + encodeURIComponent(selectedMarkers[i].address);
        }

        const urlParams = {
            origin: encodeURIComponent(selectedMarkers[0].address),
            origin_place_id: selectedMarkers[0].place_id,
            waypoints,
            waypoint_place_ids,
            destination: encodeURIComponent(selectedMarkers[selectedMarkers.length - 1].address),
            destination_place_id: selectedMarkers[selectedMarkers.length - 1].place_id,
        };

        window.open(encodeURI(url + FilterHelper.toURLQueryParams(urlParams).join('&')), '_blank').focus();
    };

    const handleClearRoute = () => {
        setSelectedMarkers([]);
    };

    useEffect(() => {
        if (selectedMarkers.length > 0) {
            setSelectedMarkers([]);
        }
    }, [points]);

    return (
        <>
            <div className="map-body">
                {customCenter && (
                    <>
                        <Map center={customCenter} className="therapists-on-map" zoom={zoom} onMapLoaded={handleMapLoaded}>
                            {children}
                            {points.map(createMarker)}
                        </Map>
                        <MapLegend
                            id="map-legend"
                            mapRef={legendRef}
                            items={iconArray}
                            onDisplayTypeChange={onDisplayTypeChange}
                            onManualDisplayTypeChange={onManualDisplayTypeChange}
                        />
                        <button className="base-text-btn white create-direction-btn" onClick={handleCreateRoute}>
                            Create a route
                        </button>
                        <button className="base-text-btn white clear-direction-btn" onClick={handleClearRoute}>
                            Clear
                        </button>
                    </>
                )}
            </div>
        </>
    );
};

const selectedIcon = {
    color: '#087fff',
    name: 'Selected Marker',
    strokeWeight: 2.5,
    manualHideOnMap: true,
};

export default React.memo(MapWrapper);
