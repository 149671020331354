export const checkResize = (mutations: any) => {
    const el = mutations[0].target;
    const w = el.clientWidth;
    const h = el.clientHeight;

    const isChange = mutations
        .map((m: any) => m.oldValue + '')
        .some((prev: any) => prev.indexOf('width: ' + w + 'px') === -1 || prev.indexOf('height: ' + h + 'px') === -1);

    if (!isChange) return;

    const event = new CustomEvent('resize', { detail: { width: w, height: h } });
    el.dispatchEvent(event);
};
