import DropdownOption from './dropdown-option';

class DocumentDropdownOption extends DropdownOption {
    isExpirationDateRequired: boolean;
    isIssueDateRequired: boolean;
    comment: string;
    instructions: string;

    constructor(
        value: number,
        label: string,
        isExpirationDateRequired: boolean,
        isIssueDateRequired: boolean,
        comment: string,
        instructions: string,
        className: string = undefined
    ) {
        super(value, label, className);
        this.isExpirationDateRequired = isExpirationDateRequired;
        this.isIssueDateRequired = isIssueDateRequired;
        this.comment = comment;
        this.instructions = instructions;
    }
}

export default DocumentDropdownOption;
