import React from 'react';
import { useState } from 'react';
import MarkAsDoneButton from './mark-as-done-button';
import ReminderDateLabel from './reminder-date-label';
import { NoteCellProps } from './types';
import Avatar from '../Avatar/Avatar';
import moment from 'moment';
import { useCallback } from 'react';

const NoteCell: React.FC<NoteCellProps> = ({ note, onCompleteNoteClick, onNoteClick }) => {
    const [displayAdditionalData, setDisplayAdditionalData] = useState(false);

    const renderOnHover = useCallback(() => {
        const handleClickOnMarkAsDone = (event: React.MouseEvent<HTMLButtonElement>) => {
            onCompleteNoteClick(note.id);
            event.stopPropagation();
        };

        return (
            <div className="extended-info-panel">
                {note.reminderDate && (
                    <ReminderDateLabel
                        isCompleted={note.completeDate !== null}
                        date={note.reminderDate}
                        isExtended={true}
                        onClick={(e) => {
                            e.stopPropagation();
                        }}
                    />
                )}
                {!note.completeDate && note.reminderDate && <MarkAsDoneButton onClick={handleClickOnMarkAsDone} />}
            </div>
        );
    }, [note, onCompleteNoteClick]);

    const renderInfoPanel = useCallback(() => {
        return (
            <div className="info-pannel">
                {note.reminderDate && !displayAdditionalData && (
                    <ReminderDateLabel isCompleted={note.completeDate !== null} date={note.reminderDate} isExtended={false} />
                )}
            </div>
        );
    }, [note, displayAdditionalData]);

    const formatName = useCallback(() => {
        if (note.entity) {
            return `${note.createdBy?.fullName || ''}/${note.entity.name}`;
        }

        return note.createdBy?.fullName || '';
    }, [note]);

    return (
        <div className="note-cell-wrapper">
            <div
                className="note-cell"
                onClick={() => onNoteClick(note)}
                onMouseOver={() => setDisplayAdditionalData(true)}
                onMouseLeave={() => setDisplayAdditionalData(false)}
            >
                <div className="note-created-by-avatar">
                    <Avatar user={note.createdBy} />
                    <div className="note-title">
                        <div className="note-cell-created-by">{formatName()}</div>
                        <div className="note-cell-created-at">{moment(note.createdAt).local().format('MMM DD, YYYY hh:mm a')}</div>
                    </div>
                </div>

                <div className="note-cell-content">
                    <div className="note-cell-content-wrapper">
                        {!displayAdditionalData && renderInfoPanel()}
                        {displayAdditionalData && renderOnHover()}
                    </div>
                    <div className="note-content">{note.note}</div>
                </div>
            </div>
            <div className="note-cell-border"></div>
        </div>
    );
};

export default NoteCell;
