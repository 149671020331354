import React from 'react';
import { IRequestDetails } from 'common/services/api/requests/types';
import useFormSidebar from 'common/hooks/use-form-sidebar';
import { IFormSidebar } from 'common/models/types';
import RightSideBarForm from 'common/components/right-side-bar/right-side-bar-form';
import RequestPatientForm from './request-patient-form';
import { IRequestEditPatient } from '../types';
import { ErrorCodes } from 'common/constants/error-codes';
import { PATIENT_ID_POPULATED, PATIENT_ALREADY_EXIST_FOR_CUSTOMER } from 'features/request-page/request-validation-messages';

const errorConfigs = [
    { errorCode: ErrorCodes.CUSTOMER_DOES_NOT_SET, error: PATIENT_ID_POPULATED, field: 'patientId' },
    { errorCode: ErrorCodes.PATIENT_ALREADY_EXISTS_FOR_CUSTOMER, error: PATIENT_ALREADY_EXIST_FOR_CUSTOMER, field: 'patientId' },
];

const RequestPatientSidebar = ({ data: patientMainInfo, onSave, onClose }: IFormSidebar<IRequestEditPatient, IRequestDetails>) => {
    const [data, error, handleChangeData, handleCancelClick, handleSaveClick] = useFormSidebar<IRequestEditPatient>(
        {
            patientDetails: patientMainInfo?.patientDetails,
            facilityId: patientMainInfo?.facility?.id,
            customerId: patientMainInfo?.customer?.id,
            patientId: patientMainInfo?.patientDetails?.id,
            patientAgencyCode: patientMainInfo?.patientDetails?.patientAgencyCode || patientMainInfo?.patientAgencyCode,
        },
        { onClose, onSave, errorConfigs: errorConfigs }
    );

    return (
        <div className="create-request">
            <RightSideBarForm title="Edit Patient Details" onCancel={handleCancelClick} onSave={handleSaveClick}>
                <RequestPatientForm data={data} errors={error} onChange={handleChangeData} options={{ requestDetails: patientMainInfo }} />
            </RightSideBarForm>
        </div>
    );
};

export default RequestPatientSidebar;
