import React, { useState } from 'react';
import RightSideBar from 'common/components/right-side-bar/right-side-bar';
import FieldSet from 'common/components/field-set/field-set';
import DateField from 'common/components/field/date-field';
import TextAreaField from 'common/components/field/text-area-field';
import { overrideDocumentRules } from 'common/validation/therapist-document-validation-rules';
import { useDispatch } from 'react-redux';
import { trackPromise } from 'react-promise-tracker';
import { OverrideDocumentDialogProps } from './type';
import { FieldValue } from 'common/models/field-value';
import { ThunkDispatch } from 'redux-thunk';
import { Action } from '@reduxjs/toolkit';
import { ITherapistDocumentOverrideInfo } from 'common/services/api/therapist-document/types';
import { getDocumentsRequest, getTherapistDetailsRequest } from 'app/store/therapist/action-creators';
import therapistService from 'common/services/api/therapist/therapist-service';

// TO-DO: Set tab indexes on the form
const OverrideDocumentDialog: React.FC<OverrideDocumentDialogProps> = ({ therapistId, document, onCancel }) => {
    const [errorData, setErrorData] = useState({});

    const [overrideInfo, setOverrideInfo] = useState<ITherapistDocumentOverrideInfo>({
        id: document.id,
        endDate: document.expirationDate,
        comment: document.comment,
    });

    const dispatch = useDispatch<ThunkDispatch<any, any, Action>>();

    const handleSave = () => {
        const saveRequest = therapistService.overrideTherapistDocument;

        trackPromise(
            saveRequest(therapistId, overrideInfo)
                .then(() => {
                    dispatch(getDocumentsRequest(therapistId));
                    trackPromise(dispatch(getTherapistDetailsRequest(therapistId)));
                    onCancel();
                })
                .catch(setErrorData)
        );
    };

    const handleFieldChange = (data: FieldValue) => {
        setOverrideInfo((prevInfo) => ({ ...(prevInfo as ITherapistDocumentOverrideInfo), [data.name]: data.value }));
        setErrorData((prevError) => ({ ...prevError, [data.name]: undefined }));
    };

    return (
        <div>
            <RightSideBar title={`Override ${document.document}`} onCancel={onCancel} onSave={handleSave}>
                <div className="edit-therapist-form">
                    <FieldSet name="endDate" errors={errorData} customClass="edit-therapist-field">
                        <DateField
                            placeholder="End Date"
                            name="endDate"
                            onChange={handleFieldChange}
                            value={overrideInfo.endDate}
                        ></DateField>
                    </FieldSet>
                    <FieldSet name="comment" errors={errorData} customClass="edit-therapist-field">
                        <TextAreaField
                            placeholder="Comment"
                            name="comment"
                            maxLength={overrideDocumentRules.comment.maxLength}
                            onChange={(data: FieldValue) => handleFieldChange({ name: data.name, value: data.value })}
                            value={overrideInfo.comment}
                        ></TextAreaField>
                    </FieldSet>
                    <br />
                </div>
            </RightSideBar>
            <div className="right-sidebar-overlay"></div>
        </div>
    );
};

export default OverrideDocumentDialog;
