import Axios from 'axios';
import { ACCESS_TOKEN_KEY, REFRESH_TOKEN_KEY } from '../constants/local-storage-keys';
import { refreshToken, logOut, setUser } from '../../features/authentication/auth.actions';
import store from '../../app/store';
import { toast } from 'react-toastify';
import jwt from 'jsonwebtoken';
import { setVersionModalStatus } from '../../app/store/version-modal/action-creators';

const checkNewBuildVersion = (serverBuildVersion) => {
    const version = document.querySelector('meta[build-version]')?.getAttribute('build-version');
    const isNewBuildVersionAvailable = version !== undefined && version !== '%REACT_BUILD_VERSION%' && version !== serverBuildVersion;

    if (isNewBuildVersionAvailable) {
        console.log('client version: ' + version);
        console.log('server version: ' + serverBuildVersion);
        openVersionModal();
    }
};

const openVersionModal = () => {
    const versionModalUpdateAt = parseFloat(localStorage.getItem('versionModalUpdateAt'));
    const currentTime = new Date().getTime() / 1000;
    const oldTime = versionModalUpdateAt;

    if (Number.isNaN(oldTime) || currentTime - oldTime > 10 * 60) {
        store.dispatch(setVersionModalStatus(true));
        localStorage.setItem('versionModalUpdateAt', currentTime);
    }
};

export class HttpClientBuilder {
    create(baseURL) {
        const client = Axios.create({ baseURL: baseURL });

        client.interceptors.request.use((config) => {
            const accessToken = localStorage.getItem(ACCESS_TOKEN_KEY);
            if (accessToken) {
                config.headers.Authorization = `Bearer ${accessToken}`;
            }

            return config;
        });

        client.interceptors.response.use(
            (response) => {
                checkNewBuildVersion(response.headers['build-version']);

                return response;
            },
            (error) => {
                const status = error.response ? error.response.status : null;
                const originalRequest = error.config;
                checkNewBuildVersion(error.response?.headers?.['build-version']);

                switch (status) {
                    case 401:
                        if (!originalRequest._retry || !originalRequest._refreshRetry) {
                            originalRequest._retry = true;

                            return refreshToken().then(
                                (refreshResponse) => {
                                    const token = refreshResponse.data;

                                    localStorage.setItem(ACCESS_TOKEN_KEY, token.access_token);
                                    localStorage.setItem(REFRESH_TOKEN_KEY, token.refresh_token);
                                    const decodedToken = jwt.decode(token.access_token);
                                    store.dispatch(
                                        setUser({
                                            permissions: Array.isArray(decodedToken.permission)
                                                ? decodedToken.permission
                                                : [decodedToken.permission],
                                        })
                                    );

                                    client.defaults.headers.common.Authorization = `Bearer ${token.access_token}`;

                                    return client.request(originalRequest);
                                },
                                (error) => {
                                    if (!originalRequest._refreshRetry) {
                                        originalRequest._retry = false;
                                        originalRequest._refreshRetry = true;
                                        client.defaults.headers.common.Authorization = `Bearer ${localStorage.getItem(ACCESS_TOKEN_KEY)}`;

                                        return client.request(originalRequest);
                                    } else {
                                        store.dispatch(logOut());

                                        return Promise.reject(error);
                                    }
                                }
                            );
                        } else {
                            store.dispatch(logOut());
                        }
                        break;
                    case 500: {
                        const errorMessage = error.response.data.errorMessage || 'Some error occurred';
                        toast.error(errorMessage);

                        return Promise.reject(error);
                    }
                    case 400: {
                        const errorData = error.response.data;

                        if (errorData && errorData.errorMessage) {
                            toast.error(errorData.errorMessage);
                        } else {
                            return Promise.reject(error);
                        }
                        break;
                    }
                    default:
                        return Promise.reject(error);
                }
            }
        );

        return client;
    }
}
