import { ICellRendererParams } from 'ag-grid-community';
import React from 'react';

const ObjectLinkValueRender = (props: ICellRendererParams) => {
    const cellValue = (props.valueFormatted ? props.valueFormatted : props.value) || '';

    return <span className="object-link">{cellValue}</span>;
};

export default ObjectLinkValueRender;
