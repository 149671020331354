import React from 'react';
import FieldList from './field-list';
import FieldSet from '../field-set/field-set';
import InputField from '../field/input-field';
import { FieldListWrapperProps } from './types';
import { FieldValue } from '../../models/field-value';

const FieldListOtherRoles = ({ data: otherRoles, errors, name = 'otherContactRoles', onChange }: FieldListWrapperProps) => {
    return (
        <FieldList
            name={name}
            isDragNDrop={false}
            values={otherRoles}
            defaultValue={{ name: '' }}
            addButtonText="Add Other Role"
            onChange={(values: any[]) => {
                onChange({ name: name, value: values });
            }}
            renderInput={(value: any, index: number) => (
                <FieldSet
                    name={name}
                    errors={errors}
                    customClass="edit-field other-role required-field-star"
                    renderError={(error: any) => error[index]?.name && error[index]?.name[0]}
                >
                    <InputField
                        placeholder="Other role"
                        onChange={(data: FieldValue) => {
                            otherRoles[index] = { ...otherRoles[index], name: data.value };
                            onChange({ name: name, value: [...otherRoles] });
                        }}
                        value={value.name}
                    />
                </FieldSet>
            )}
        />
    );
};

export default FieldListOtherRoles;
