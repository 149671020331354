import React, { useState } from 'react';
import RequestCreateSidebar from './components/request-forms/request-create-sidebar';
import RequestService from 'common/services/api/requests/request-service';
import { ICreateRequestInfo } from 'common/services/api/requests/types';
import RequestTable from './components/requests-table';
import './requests-page.scss';
import { ITablePageSetting } from 'common/components/ag-table/types';
import { toast } from 'react-toastify';

const RequestPageExternal = () => {
    const [requestPageSettings, setRequestPageSettings] = useState<ITablePageSetting>({
        isCreateDialogOpen: false,
        updateTable: false,
    });

    const saveRequest = async (request: ICreateRequestInfo) => {
        try {
            return RequestService.create(request).then(() => {
                setRequestPageSettings({ isCreateDialogOpen: false, updateTable: true });
            });
        } catch (errorMessage) {
            toast.error(errorMessage);
        }
    };

    return (
        <div className="therapist-page request-page">
            <header>
                <div className="header-wrapper">
                    <div className="title-wrapper">
                        <h1>Requests</h1>
                    </div>
                    <button
                        className="create-therapist-btn base-text-btn blue"
                        id="createNewRequestBtn"
                        onClick={() => setRequestPageSettings({ isCreateDialogOpen: true, updateTable: false })}
                    >
                        New Request
                    </button>
                </div>
            </header>
            {requestPageSettings.isCreateDialogOpen && (
                <RequestCreateSidebar
                    onClose={() => setRequestPageSettings({ isCreateDialogOpen: false, updateTable: false })}
                    onSave={saveRequest}
                />
            )}
            <RequestTable updateTable={requestPageSettings.updateTable} />
        </div>
    );
};

export default RequestPageExternal;
