import React, { useEffect, useRef, useState } from 'react';
import DatePicker from 'react-datepicker';
import NumberFormat from 'react-number-format';
import 'react-datepicker/dist/react-datepicker.css';
import Field from './field';
import { ValidationOptions } from 'settings.json';
import moment from 'moment-timezone';
import './data-field.scss';
import ReactDOM from 'react-dom';
import { getUniqueName } from '../../helpers/string-helper';
import { IDataField } from './types';

export const CalendarContainer = ({ children }: { children: React.ReactNode[] }) => {
    return ReactDOM.createPortal(children, document.body);
};

const DateField = (props: IDataField) => {
    const [isFocused, toggleFocus] = useState(false);
    const [value, setValue] = useState<any>(props.value ? moment(props.value) : undefined);
    const [format] = useState(props.dateFormat || ValidationOptions.DateFormat);
    const calendar = useRef<any>();

    const handleOnFocus = () => {
        toggleFocus(true);
    };

    const handleOnBlur = () => {
        toggleFocus(false);
    };

    const handleChange = (date: any) => {
        setValue(date);
        toggleFocus(false);

        props.onChange({ value: date, name: props.name });
    };

    const handleSelect = () => {
        toggleFocus(false);
    };

    useEffect(() => {
        setValue(props.value ? moment(props.value) : undefined);
    }, [props.value]);

    return (
        <Field {...props} value={value ?? ''} isFocused={isFocused} uniqueValue={props.uniqueValue} withSameValue={props.withSameValue}>
            <DatePicker
                popperContainer={props.popperContainer}
                tabIndex={props.tabIndex as number}
                disabled={props.disabled}
                ref={calendar}
                dateFormat={format}
                showPopperArrow={false}
                selected={value}
                className={`${props.id}`}
                onFocus={handleOnFocus}
                onBlur={handleOnBlur}
                onChange={handleChange}
                onSelect={handleSelect}
                autoComplete={getUniqueName()}
                customInput={<NumberFormat format={props.maskFormat || '##/##/####'} mask="_"></NumberFormat>}
            />
            {!props.disabled && (
                <span onClick={() => calendar.current.setOpen(true)} className={`icon ${props.iconClass || 'icon-calendar'}`}></span>
            )}
        </Field>
    );
};

export default DateField;
