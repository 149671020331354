import React, { useState } from 'react';
import VerticalTabWrapper from '../vertical-tab-wrapper';
import { useDispatch, useSelector } from 'react-redux';
import PreferencesDisplaySection from './preferences-display-section';
import PreferencesEditForm from './preferences-edit-form';
import { trackPromise } from 'react-promise-tracker';
import { IEditPreferences } from 'common/services/api/therapist/types';
import { ThunkDispatch } from 'redux-thunk';
import { Action } from '@reduxjs/toolkit';
import { getTherapistDetailsRequest, updateTherapistPreferencesRequest } from 'app/store/therapist/action-creators';

const PreferencesTab = () => {
    const [isEditDialogOpen, toggleEditDialog] = useState(false);

    const dispatch = useDispatch<ThunkDispatch<any, any, Action>>();

    const therapist = useSelector((state: any) => state.therapistDetails.therapist);
    const handleClickOnEdit = () => {
        toggleEditDialog(true);
    };

    const handleCloseEditDialog = () => {
        toggleEditDialog(false);
    };

    const savePreferences = (preferences: IEditPreferences) => {
        return trackPromise(dispatch(updateTherapistPreferencesRequest(therapist.id, preferences))).then((response) => {
            trackPromise(dispatch(getTherapistDetailsRequest(therapist.id)));
            handleCloseEditDialog();

            return response;
        });
    };

    return (
        <VerticalTabWrapper onEdit={handleClickOnEdit}>
            <PreferencesDisplaySection preferences={therapist.preferences}></PreferencesDisplaySection>
            {isEditDialogOpen && (
                <PreferencesEditForm
                    therapist={therapist.preferences}
                    onSave={savePreferences}
                    onCancel={handleCloseEditDialog}
                ></PreferencesEditForm>
            )}
        </VerticalTabWrapper>
    );
};

export default PreferencesTab;
