import React from 'react';
import FieldSet from 'common/components/field-set/field-set';
import DropdownOption from 'common/models/dropdown-option';
import { IOption } from 'common/models/types';
import EntityService from 'common/services/api/entity/entity-service';
import PaginationAutocomplete from 'common/components/field/pagination-autocomplete/pagination-autocomplete';
import { ConverterHelper } from 'common/helpers/converter-helper';

type ExistingContactsListFiltersProps = {
    entityId: number;
    filters: ExistingContactsListFilter;
    onFiltersChange: (filters: ExistingContactsListFilter) => void;
};

type ExistingContactsListFilter = {
    entityFilterId: number | null;
};

export const ExistingContactsListFilters = ({ entityId, filters, onFiltersChange }: ExistingContactsListFiltersProps) => {
    const handleFieldChange = (data: any) => {
        onFiltersChange({ ...filters, [data.name]: data.value });
    };

    const getEntitiesTreeOptions = (term: string, skip: number, take: number): Promise<IOption[]> => {
        return EntityService.getEntitiesTreeOptions(entityId, term, skip, take);
    };

    return (
        <>
            <FieldSet name="entityFilter">
                <PaginationAutocomplete
                    fetchData={getEntitiesTreeOptions}
                    convertFunction={ConverterHelper.optionConvert}
                    id="entityFilter"
                    placeholder="Entity"
                    name="entityFilter"
                    tabIndex=""
                    onDelete={() => handleFieldChange({ name: 'entityFilterId', value: undefined })}
                    onSelect={(item: DropdownOption) => handleFieldChange({ name: 'entityFilterId', value: item.value })}
                />
            </FieldSet>
        </>
    );
};
