import React from 'react';
import FieldList from './field-list';
import FieldSet from '../field-set/field-set';
import { FieldListWrapperProps } from './types';
import PaginationAutocomplete from '../field/pagination-autocomplete/pagination-autocomplete';
import physiciansService from '../../services/api/physicians/physicians-service';
import { ConverterHelper } from '../../helpers/converter-helper';
import DropdownOption from '../../models/dropdown-option';

const defaultValue = { physician: { firstName: '', lastName: '', label: '' } };

const FieldListPhysicians = ({ data: physicians, errors, onChange, name = 'physicians', tabIndex }: FieldListWrapperProps) => {
    const handleChangeList = (index: number, object: any) => {
        const newPhysician = [...physicians];
        newPhysician[index] = { ...physicians[index], ...object };
        onChange({ name: name, value: newPhysician });
    };

    return (
        <FieldList
            name={name}
            isDragNDrop
            values={physicians}
            defaultValue={defaultValue}
            addButtonText="Add Physician"
            className={`physician-list`}
            onChange={(values: any[]) => {
                onChange({ name: name, value: values });
            }}
            renderInput={(currentValue: any, index: number) => {
                const { firstName, lastName } = physicians[index]?.physician ?? {};

                return (
                    <>
                        <FieldSet
                            name={name}
                            errors={errors}
                            customClass={`edit-phone ${index === 0 ? 'active-item' : ''}`}
                            renderError={(error: any) => error?.[index]?.physicianId?.[0]}
                        >
                            <PaginationAutocomplete
                                fetchData={physiciansService?.getPhysicianOptions}
                                convertFunction={ConverterHelper.physicianConvert}
                                defaultValue={firstName && lastName ? firstName + ' ' + lastName : ''}
                                id="physician"
                                placeholder="Physician"
                                name="physician"
                                tabIndex={tabIndex}
                                onDelete={() => {
                                    const newPhysician = [...physicians];
                                    newPhysician[index] = { ...defaultValue };
                                    onChange({ name: name, value: newPhysician });
                                }}
                                onSelect={(option: DropdownOption) =>
                                    handleChangeList(index, { physician: { ...option?.value }, practice: null })
                                }
                                onFormatOption={(item: any) => {
                                    const physician = item?.value ?? {};
                                    const { address1, address2, city, state, postalCode } = physician?.address ?? {};
                                    const address = `${address1 ?? ''} ${address2 ?? ''} ${city ?? ''} ${state?.name ?? ''} ${
                                        postalCode ?? ''
                                    }`.trim();

                                    return (
                                        <>
                                            <span>{physician?.firstName ? physician.firstName + ' ' + physician.lastName : ''}</span>
                                            <div className="option-roles">
                                                <span>Address: </span> {address || '-'}
                                                <div>
                                                    <span>NPI:</span> {physician?.npiNumber || '-'}
                                                </div>
                                            </div>
                                        </>
                                    );
                                }}
                            />

                            <div className={`patient-practice ${'patient-practice-' + index}`}>
                                <PaginationAutocomplete
                                    clientSide
                                    fetchData={() => physiciansService?.getPracticeOptions(physicians[index]?.physician?.id)}
                                    fetchTrigger={physicians[index]?.physician?.id}
                                    onDataLoaded={(options) => {
                                        if (options?.length > 0) {
                                            const patientBlock: HTMLInputElement = document.querySelector('.patient-practice-' + index);
                                            patientBlock.style.display = 'block';
                                        } else {
                                            const patientBlock: HTMLInputElement = document.querySelector('.patient-practice-' + index);
                                            patientBlock.style.display = 'none';
                                        }
                                    }}
                                    convertFunction={ConverterHelper.entityConvert}
                                    defaultValue={ConverterHelper.entityConvert(physicians[index]?.practice)}
                                    id="practice"
                                    placeholder="Practice"
                                    name="practice"
                                    tabIndex={tabIndex}
                                    onDelete={() => handleChangeList(index, { practice: null })}
                                    onSelect={(option: DropdownOption) =>
                                        handleChangeList(index, { practice: { id: option?.value, name: option?.label } })
                                    }
                                />
                            </div>
                        </FieldSet>
                    </>
                );
            }}
        />
    );
};

export default FieldListPhysicians;
