import { InfiniteServerClient } from 'common/helpers/http-clients';
import { ICreateNoteRequest } from 'common/models/create-note-request';
import { INote } from 'common/models/note';

export class TherapistNoteService {
    create(therapistId: number, request: ICreateNoteRequest): Promise<INote> {
        return InfiniteServerClient.post<INote>(`/therapists/${therapistId}/notes`, request || {}).then(
            (response) => {
                return response.data;
            },
            (error) => {
                throw error;
            }
        );
    }
}

export default new TherapistNoteService();
