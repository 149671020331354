import React, { useContext, useState } from 'react';
import { IEntityDetailsBasicInfo, IUpdateEntityRequest } from 'common/services/api/entity/types';
import entityService from 'common/services/api/entity/entity-service';
import VerticalTabWrapper from 'features/therapist-details-page/basic-info-tab/vertical-tab-wrapper';
import { EntityBasicInfoTabProp } from '../../types';
import EntityBasicInfoDisplaySection from './entity-basic-info-display-section';
import { EntityDetailsContext } from '../../entity-details-page';
import EntityBasicInfoSidebar from '../../../entities-forms/entity-basic-info-form/entity-basic-info-sidebar';
import { ConverterHelper } from 'common/helpers/converter-helper';

const EntityBasicInfoTab: React.FC<EntityBasicInfoTabProp> = ({ entityBasicInfo }) => {
    const [isEditDialogOpen, toggleEditDialog] = useState(false);
    const { setEntityDetails } = useContext(EntityDetailsContext);
    const handleClickOnEdit = () => toggleEditDialog(true);
    const handleCancelDialog = () => toggleEditDialog(false);

    const updateBasicInfo = (entity: IEntityDetailsBasicInfo) => {
        const request: IUpdateEntityRequest = ConverterHelper.entityDetailsBasicInfoToIUpdateEntityRequest(entity);

        return entityService.updateEntity(request).then(() => {
            handleCancelDialog();
            setEntityDetails(null);
        });
    };

    return (
        <VerticalTabWrapper onEdit={handleClickOnEdit}>
            <EntityBasicInfoDisplaySection entityBasicInfo={entityBasicInfo} />
            {isEditDialogOpen && (
                <EntityBasicInfoSidebar entityBasicInfo={entityBasicInfo} onSave={updateBasicInfo} onCancel={handleCancelDialog} />
            )}
        </VerticalTabWrapper>
    );
};

export default EntityBasicInfoTab;
