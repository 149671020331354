class MenuItem {
    public key: string;
    public name: string;
    public route: string;
    public render: boolean;

    constructor(key: string, name: string, route: string, render = true) {
        this.key = key;
        this.name = name;
        this.route = route;
        this.render = render;
    }
}

export default MenuItem;
