import { InfiniteServerClient } from 'common/helpers/http-clients';
import { ISiteSettings } from './types';

export class SiteSettingsService {
    getSettings(): Promise<ISiteSettings> {
        return InfiniteServerClient.get<ISiteSettings>('/settings/infinite-admin').then(
            (response) => {
                return response.data;
            },
            (error) => {
                throw error;
            }
        );
    }
}

export default new SiteSettingsService();
