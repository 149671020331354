import { IPatientGridItem } from 'common/services/api/patient/types';
import { Action, AnyAction, ThunkAction } from '@reduxjs/toolkit';

export const SET_PATIENTS = 'SET_PATIENTS';

export type ThunkResult<R> = ThunkAction<R, IPatientState, null, AnyAction>;

export interface IPatientState {
    readonly data: IPatientGridItem[];
}
export interface ISetPatientsAction extends Action<'SET_PATIENTS'> {
    patients: IPatientGridItem[];
}

export type PatientStateActionTypes = ISetPatientsAction;
