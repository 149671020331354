import { Link } from 'react-router-dom';
import Breadcrumb from './breadcrumb';
import React from 'react';
import { BreadcrumbLinksProps } from './types';

const BreadcrumbLinks = ({ items }: BreadcrumbLinksProps) => {
    return (
        <Breadcrumb>
            {items.map(({ to, label }) => (
                <Link key={to} to={to}>
                    {' '}
                    {label}{' '}
                </Link>
            ))}
        </Breadcrumb>
    );
};

export default React.memo(BreadcrumbLinks);
