import { set } from 'lodash';

type Phone = {
    type: string;
    number: string;
};

type PhoneError = {
    type: string[];
    number: string[];
};

const MAX_FILE_SIZE = 28 * 1024 * 1024;

export class ValidationHelper {
    static handleErrorResponse = (errorsResponse: any): any => {
        const errors = {};
        Object.keys(errorsResponse ?? {}).forEach((key) => {
            //TODO: find solution on backend
            const camelCaseKey = key
                .split('.')
                .map((x) => toLowerFirstSymbol(x))
                .join('.');
            set(errors, camelCaseKey, errorsResponse[key]);
        });

        return errors;
    };

    static validateFile = (params: any, formatError?: (errors: string[]) => any) => {
        if (!params) {
            return;
        }

        const errors: any = {};
        for (const [key, value] of params.entries()) {
            if (value?.size > MAX_FILE_SIZE) {
                if (!Array.isArray(errors[key])) {
                    errors[key] = [];
                }
                errors[key].push(`The file can’t be bigger than ${MAX_FILE_SIZE / 1024 / 1024} MB. Please reduce the size and try again`);
            }
        }

        return Object.keys(errors).length > 0 ? Promise.reject(formatError ? formatError(errors) : errors) : null;
    };

    static validatePhones = (phones: Phone[], onValidate: (errors: PhoneError[]) => void) => {
        const errors = phones.map(() => ({})) as PhoneError[];
        let isValid = true;

        phones.forEach((phone, index) => {
            const numberErrors = [];
            if (phone.number && phones.filter((p) => p.number === phone.number).length > 1) {
                numberErrors.push('Number already exists');
                isValid = false;
            }
            if (!phone.number) {
                numberErrors.push("Number can't be empty");
                isValid = false;
            }

            if (!isValid) {
                errors[index].number = numberErrors;
            }
        });

        onValidate(errors);

        return isValid;
    };
}

export const toLowerFirstSymbol = (value: string) => {
    if (!value) {
        return value;
    }

    if (value.length < 2) {
        return value[0].toLowerCase();
    }

    return `${value[0].toLowerCase()}${value.substr(1)}`;
};
