import { GoogleMap as GMap, useJsApiLoader } from '@react-google-maps/api';
import React from 'react';
import { GoogleMapProps } from './types';

const GoogleMap: React.FC<GoogleMapProps> = ({ center, className, onMapLoaded, zoom = 10, children }) => {
    const { isLoaded } = useJsApiLoader({
        id: 'google-map-script',
        googleMapsApiKey: process.env.REACT_APP_GOOGLE_KEY,
    });

    const handleOnMapLoad = (map: google.maps.Map) => {
        if (onMapLoaded) {
            onMapLoaded(map);
        }
    };

    return isLoaded ? (
        <GMap
            onLoad={handleOnMapLoad}
            zoom={zoom}
            options={{
                gestureHandling: 'greedy',
                streetViewControl: false,
                mapTypeControl: false,
                fullscreenControl: false,
                zoomControl: false,
                styles: [
                    {
                        featureType: 'poi',
                        elementType: 'labels',
                        stylers: [{ visibility: 'off' }],
                    },
                    {
                        featureType: 'transit',
                        elementType: 'labels',
                        stylers: [{ visibility: 'off' }],
                    },
                    {
                        featureType: 'administrative',
                        elementType: 'labels',
                        stylers: [{ visibility: 'off' }],
                    },
                ],
            }}
            center={new window.google.maps.LatLng(center.latitude, center.longitude)}
            mapContainerClassName={className}
        >
            {children}
        </GMap>
    ) : (
        <></>
    );
};

export default React.memo(GoogleMap);
