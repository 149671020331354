import { Action } from '@reduxjs/toolkit';
import { FileHelper } from 'common/helpers/file-helper';
import { getSeveralFilesInZipFileActionCreator } from 'features/document-viewer/store/document-viewer-action-creators';
import { IGetFileAction } from 'features/document-viewer/store/document-viewer-action-types';
import React from 'react';
import { useDispatch } from 'react-redux';
import { ThunkDispatch } from 'redux-thunk';
import { DownloadSeveralFilesButtonProps } from '../type';

const DownloadSeveralFilesButton = ({ title, customerArchiveName, fileIds }: DownloadSeveralFilesButtonProps) => {
    const dispatch = useDispatch<ThunkDispatch<Blob, number, Action<IGetFileAction>>>();

    const onDownloadButtonClick = () => {
        dispatch(getSeveralFilesInZipFileActionCreator(fileIds, customerArchiveName)).then((response: any) => {
            FileHelper.downloadOrOpen(response.file);
        });
    };

    return (
        <>
            {fileIds && fileIds?.length > 0 && (
                <button id={`${title}_downloadSeveralFilesButton`} className="base-text-btn blue" onClick={onDownloadButtonClick}>
                    Download files <span style={{ marginLeft: '5px' }}> {fileIds.length}</span>
                </button>
            )}
        </>
    );
};

export default React.memo(DownloadSeveralFilesButton);
