import { ColDef } from 'ag-grid-community';
import Button from 'common/components/Button/button';
import React, { useCallback } from 'react';
import { ClearFiltersButtonProps } from '../type';
const isFunction = require('lodash.isfunction');

const ClearFiltersButton = ({ title = '', children = 'Clear Filters', onClick, gridApi, gridColumnApi }: ClearFiltersButtonProps) => {
    const handleClickOnClearFilters = useCallback(() => {
        if (!gridApi && !gridColumnApi) return;

        gridColumnApi.resetColumnState();
        gridApi.setFilterModel(null);

        // Restore sort
        const sortableColumn: ColDef = gridApi.getColumnDefs().find((column: ColDef) => column?.initialSort);
        if (sortableColumn) {
            gridApi.setSortModel([
                {
                    colId: sortableColumn.colId,
                    sort: sortableColumn.initialSort,
                },
            ]);
        }

        if (isFunction(onClick)) onClick();
    }, [onClick, gridApi, gridColumnApi]);

    return (
        <Button id={`${title.replace(/\\s/g, '')}ClearFilters`} onClick={handleClickOnClearFilters} className="base-text-btn white">
            {children}
        </Button>
    );
};

export default React.memo(ClearFiltersButton);
