import React from 'react';
import { PatientsInZipDialogHeaderProp } from './types';
import './patients-in-zip.scss';

const PatientsInZipDialogHeader: React.FC<PatientsInZipDialogHeaderProp> = ({ therapistName, patientsCount, handleOnCloseClick }) => {
    return (
        <>
            <header>
                <div className="patients-zip-dialog-header">
                    <div className="patients-zip-dialog-header__left">
                        <span className="patients-zip-dialog-header__icon icon icon-people"></span>
                        <div className="patients-zip-dialog-header__title">
                            {patientsCount} Patients for {therapistName}
                        </div>
                    </div>
                    <div className="patients-zip-dialog-header__right">
                        <span
                            className="icon icon-close patients-zip-dialog-header__close"
                            id="closePatiestsInZipDialog"
                            onClick={handleOnCloseClick}
                        ></span>
                    </div>
                </div>
            </header>
        </>
    );
};

export default PatientsInZipDialogHeader;
