import React, { useState } from 'react';
import RightSideBar from 'common/components/right-side-bar/right-side-bar';
import FieldSet from 'common/components/field-set/field-set';
import DateField from 'common/components/field/date-field';
import { useDispatch } from 'react-redux';
import FileField from 'common/components/field/file-field/file-field';
import { trackPromise } from 'react-promise-tracker';
import { DateTimeHelper } from 'common/helpers/date-time-helper';
import { dateTimeConstants } from 'common/constants/common';
import PaginationAutocomplete from 'common/components/field/pagination-autocomplete/pagination-autocomplete';
import { ConverterHelper } from 'common/helpers/converter-helper';
import DocumentService from 'common/services/api/document/document-service';
import { UploadAdditionalTherapistDocumentDialogProps } from './type';
import { FieldValue } from 'common/models/field-value';
import { ThunkDispatch } from 'redux-thunk';
import { Action } from '@reduxjs/toolkit';
import { ICreateTherapistAdditionalDocumentInfo } from 'common/services/api/therapist-document/types';
import therapistDocumentService from 'common/services/api/therapist-document/therapist-document-service';
import { getDocumentsRequest, getTherapistDetailsRequest } from 'app/store/therapist/action-creators';

// TO-DO: Set tab indexes on the form
const UploadAdditionalTherapistDocumentDialog: React.FC<UploadAdditionalTherapistDocumentDialogProps> = ({ therapistId, onCancel }) => {
    const [currentDocumentType, setCurrentDocumentType] = useState(null);

    const [therapistDocument, setTherapistDocument] = useState<ICreateTherapistAdditionalDocumentInfo>({
        file: undefined,
        document: undefined,
        documentId: undefined,
        issuedDate: undefined,
        expirationDate: undefined,
        comment: undefined,
        instructions: undefined,
    });

    const [errorData, setErrorData] = useState<any>({});
    const dispatch = useDispatch<ThunkDispatch<any, any, Action>>();

    const handleFieldChange = (data: FieldValue) => {
        setTherapistDocument((prevInfo) => ({ ...prevInfo, [data.name]: data.value }));
        setErrorData((prevError: any) => ({ ...prevError, [data.name]: undefined }));
    };

    const handleDocumentTypeFieldChange = (item: any) => {
        setCurrentDocumentType(item.value);

        setTherapistDocument((prevInfo) => ({
            ...prevInfo,
            document: item.value,
            comment: item.value?.comment || undefined,
            instructions: item.value?.instructions || undefined,
        }));

        setErrorData((prevError: any) => ({
            ...prevError,
            document: undefined,
            comment: undefined,
            instructions: undefined,
        }));
    };

    const handleSave = () => {
        trackPromise(
            therapistDocumentService
                .addAdditionalTherapistDocument(therapistId, {
                    ...therapistDocument,
                    documentId: therapistDocument.document?.value,
                })
                .then(() => {
                    dispatch(getDocumentsRequest(therapistId));
                    trackPromise(dispatch(getTherapistDetailsRequest(therapistId)));
                    onCancel();
                })
                .catch(setErrorData)
        );
    };

    return (
        <div>
            <RightSideBar title={`Upload document`} onCancel={onCancel} onSave={handleSave}>
                <div className="edit-therapist-form">
                    <FieldSet errors={errorData} name="file" customClass="edit-therapist-field">
                        <FileField
                            name="file"
                            onChange={handleFieldChange}
                            placeholder="Upload or Drop File"
                            iconClass="icon-upload-file"
                            uploadedFileName={therapistDocument.file?.name}
                            isError={errorData.file}
                        />
                    </FieldSet>
                    <FieldSet errors={errorData} name="documentId" customClass="edit-therapist-field">
                        <PaginationAutocomplete
                            fetchData={DocumentService.getDocumentOptions}
                            convertFunction={ConverterHelper.documentConvert}
                            id="documentSelect"
                            defaultValue=""
                            placeholder="Type"
                            name="document"
                            tabIndex=""
                            onDelete={() => handleDocumentTypeFieldChange({ name: 'document', value: undefined })}
                            onSelect={(item) => handleDocumentTypeFieldChange({ name: 'document', value: item })}
                        />
                    </FieldSet>
                    {currentDocumentType?.isIssueDateRequired && (
                        <FieldSet name="issuedDate" errors={errorData} customClass="edit-therapist-field">
                            <DateField
                                placeholder="Issue Date"
                                name="issuedDate"
                                onChange={(data: any) =>
                                    handleFieldChange({
                                        name: data.name,
                                        value: DateTimeHelper.format(data.value.toDate(), dateTimeConstants.MM_DD_YYYY, true),
                                    })
                                }
                                value={therapistDocument.issuedDate}
                                iconClass="icon-calendar"
                            />
                        </FieldSet>
                    )}
                    {currentDocumentType?.isExpirationDateRequired && (
                        <FieldSet name="expirationDate" errors={errorData} customClass="edit-therapist-field">
                            <DateField
                                placeholder="Expiration Date"
                                name="expirationDate"
                                onChange={(data: any) =>
                                    handleFieldChange({
                                        name: data.name,
                                        value: DateTimeHelper.format(data.value.toDate(), dateTimeConstants.MM_DD_YYYY, true),
                                    })
                                }
                                value={therapistDocument.expirationDate}
                                iconClass="icon-calendar"
                            />
                        </FieldSet>
                    )}
                    {therapistDocument.comment && (
                        <div className="document-notes">
                            <div className="col icon icon-info notes-icon"></div>
                            <div className="col">
                                <h4 className="notes-label">Internal Comment</h4>
                                <div className="notes-content" title={therapistDocument.comment}>
                                    {therapistDocument.comment}
                                </div>
                            </div>
                        </div>
                    )}
                    <br />
                    {therapistDocument.instructions && (
                        <div className="document-notes">
                            <div className="col icon icon-info notes-icon"></div>
                            <div className="col">
                                <h4 className="notes-label">Instruction</h4>
                                <div className="notes-content" title={therapistDocument.instructions}>
                                    {therapistDocument.instructions}
                                </div>
                            </div>
                        </div>
                    )}
                </div>
            </RightSideBar>
            <div className="right-sidebar-overlay"></div>
        </div>
    );
};

export default UploadAdditionalTherapistDocumentDialog;
