import { FilterHelper } from 'common/helpers/filter-helper';
import { InfiniteServerClient } from 'common/helpers/http-clients';
import { IDetailsNavigationData } from './type';

export class DetailsNavigationService {
    getData(entityApiBaseUrl: string, entityIndex: number, filterQueryParams: any): Promise<IDetailsNavigationData> {
        const queryParams = FilterHelper.toURLQueryParams(filterQueryParams).join('&');

        return InfiniteServerClient.get<IDetailsNavigationData>(`/${entityApiBaseUrl}/navigation/${entityIndex}?${queryParams}`).then(
            (response) => {
                return response.data;
            },
            (error) => {
                throw error;
            }
        );
    }
}

export default new DetailsNavigationService();
