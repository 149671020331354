import { Reducer } from '@reduxjs/toolkit';
import { LanguageActionTypes, ILanguageState } from './types';

const initialState: ILanguageState = {
    data: undefined,
};

export const languageReducer: Reducer<ILanguageState, LanguageActionTypes> = (state = initialState, action) => {
    switch (action.type) {
        case 'SET_LANGUAGES':
            return { ...state, data: action.languages };
        default:
            return state;
    }
};
