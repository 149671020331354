import Button from 'common/components/Button/button';
import InputField from 'common/components/field/input-field';
import OptionsButton from 'common/components/options-button/options-button';
import { FieldValue } from 'common/models/field-value';
import React, { useEffect } from 'react';
import { usePlacesWidget } from 'react-google-autocomplete';
import RadiusField from '../radius-field/radius-field';
import { MapTopFilterProps } from './types';
import { ExportOptions } from 'settings.json';

import './_styles.scss';

const MapTopFilter: React.FC<MapTopFilterProps> = ({
    radius,
    address,
    onCenterChange,
    onRadiusChange,
    onClearFilterClick,
    onExportClick,
    children,
}) => {
    const handleSearchChange = (place: google.maps.places.PlaceResult, ref: any) => {
        const placeId: string = place.place_id;

        if (!place.formatted_address) {
            return;
        }

        ref.value = place.formatted_address;

        const request: google.maps.GeocoderRequest = { placeId: placeId };

        new google.maps.Geocoder().geocode(request, (result, status) => {
            if (status !== google.maps.GeocoderStatus.OK) {
                console.error(`Google geocoding API returned '${status} status'`);
            } else {
                const lat: number = result[0].geometry.location.lat();
                const lng: number = result[0].geometry.location.lng();

                onCenterChange({ latitude: lat, longitude: lng }, place.formatted_address);
            }
        });
    };

    const { ref } = usePlacesWidget<HTMLInputElement>({
        onPlaceSelected: handleSearchChange,
        options: { types: [], componentRestrictions: { country: 'us' } },
    });

    useEffect(() => {
        if (address) {
            ref.current.value = address;
        }
    }, [ref.current]);

    const handleMilesChange = (miles: FieldValue) => {
        if (miles.value) {
            onRadiusChange(miles.value);
        }
    };

    return (
        <div className="search-area">
            <div className="search-area-wrapper">
                <InputField
                    stateless
                    value={ref?.current?.value}
                    placeholder="Zip or Address"
                    inputRef={ref}
                    inputClass="search-area-zip-address"
                    customClass="zip-address-icon-wrapper"
                />
                <RadiusField onChange={handleMilesChange} value={radius} className="search-area-radius" />
            </div>
            {children && children}
            <div className="actions-left-group">
                <OptionsButton
                    className={'export-button base-text-btn export-btn white'}
                    id="export-map-btn"
                    optionsList={Object.values(ExportOptions)}
                    onOptionClick={onExportClick}
                />
                <Button id="map-clear-filters-button" onClick={onClearFilterClick} className="base-text-btn white search-area-clear-filter">
                    Clear Filters
                </Button>
            </div>
        </div>
    );
};

export default React.memo(MapTopFilter);
