import DateField, { CalendarContainer } from 'common/components/field/date-field';
import { dateTimeConstants } from 'common/constants/common';
import React from 'react';
import { DateFilterProps } from './types';
import { FieldValue } from 'common/models/field-value';
import './date-filter.scss';

export const DateFilter: React.FC<DateFilterProps> = ({ value, onChange, className = '' }) => {
    const handleStartDateChange = (fieldValue?: FieldValue) => {
        onChange({ ...value, startDate: fieldValue.value?.format(dateTimeConstants.MM_DD_YYYY) });
    };

    const handleEndDateChange = (fieldValue?: FieldValue) => {
        onChange({ ...value, endDate: fieldValue.value?.format(dateTimeConstants.MM_DD_YYYY) });
    };

    return (
        <div className={`date-filter ${className}`}>
            <div className="date-filter-field">
                <DateField
                    popperContainer={CalendarContainer}
                    value={value?.startDate}
                    onChange={handleStartDateChange}
                    placeholder="Start Date"
                />
            </div>
            {value.startDate && (
                <div className="date-filter-field">
                    <DateField
                        popperContainer={CalendarContainer}
                        value={value?.endDate}
                        onChange={handleEndDateChange}
                        placeholder="End Date"
                    />
                </div>
            )}
        </div>
    );
};

export default React.memo(DateFilter);
