import { useRef } from 'react';
import { ITabIndex } from './types';

export function useTabIndex(): ITabIndex {
    const tabIndex = useRef(0);
    tabIndex.current = 0;

    return {
        getTabIndex: (skip?: number) => {
            tabIndex.current = skip ? tabIndex.current + skip + 1 : tabIndex.current + 1;

            return tabIndex.current;
        },
    };
}
