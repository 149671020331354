import { IEntityOption } from './../services/api/entity/types';
import { IEntityContactOption, IEntityContactWithRoleOption } from './../services/api/entity-contact/type';
import DropdownOption from 'common/models/dropdown-option';
import { IOption } from 'common/models/types';
import { ICounty } from 'common/services/api/county/types';
import { IDepartment, ISubDepartment } from 'common/services/api/department/types';
import { IDiscipline } from 'common/services/api/disciplince/types';
import { IEntityBasicInfo, IEntityDetailsBasicInfo, IFunction, IUpdateEntityRequest } from 'common/services/api/entity/types';
import { ILanguage } from 'common/services/api/language/types';
import { IReferralSource } from 'common/services/api/referral-source/types';
import { IState } from 'common/services/api/state/types';
import { ITherapyTypeOption } from 'common/services/api/therapy/types';
import { IUserBaseInfo } from 'common/services/api/user/types';
import { FunctionDropdownOption } from 'features/entities-page/components/entities-forms/entity-basic-info-form/types';
import DepartmentDropdownOption from 'common/models/department-dropdown-option';
import DocumentDropdownOption from 'common/models/document-dropdown-option';
import { IDocumentShortInfo } from 'common/services/api/document/types';
import { IRateOption } from 'common/services/api/rate/types';
import { IPhysiciansOptionModel } from '../services/api/physicians/types';
import { IPOSContactOption, ISchoolName, ISchoolOption } from '../services/api/school/types';
import { IStaffingAgency } from 'common/services/api/staffing-agency/types';

export class ConverterHelper {
    static optionConvert = (option: IOption) => {
        return new DropdownOption(option.id, option.name);
    };

    static posContactConvert = (option: IPOSContactOption) => {
        return new DropdownOption(option.id, option.fullName);
    };

    static rateConvert = (option: IRateOption) => {
        return new DropdownOption(option.id, option.rate);
    };

    static typeOfLicenseConvert = (option: IOption) => {
        return new DropdownOption(option.id, option.name);
    };

    static specialtyConvert = (option: IOption) => {
        return new DropdownOption(option.id, option.name);
    };

    static doctorOfficeConvert = (option: IOption) => {
        return new DropdownOption(option.id, option.name);
    };

    static coverageTypeConvert = (customer: IOption) => {
        return new DropdownOption(customer.id, customer.name);
    };

    static entityConvert = (customer: IEntityOption) => {
        if (customer) {
            return new DropdownOption(customer?.id, customer?.name);
        }
    };

    static physicianConvert = (physician: IPhysiciansOptionModel) => {
        return new DropdownOption(physician, physician.firstName + ' ' + physician.lastName);
    };

    static languageConvert = (language: ILanguage) => {
        return new DropdownOption(language.id, language.name);
    };

    static internalReferralSourceConvert = (internalReferral: IUserBaseInfo) => {
        return new DropdownOption(internalReferral.id, internalReferral.fullName);
    };

    static referralSourceConvert = (referral: IReferralSource) => {
        return new DropdownOption(referral.id, referral.name);
    };

    static externalReferralSourceConvert = (externalReferral: IEntityContactOption) => {
        return new DropdownOption(externalReferral.id, externalReferral.name);
    };

    static caseManagerConvert = (caseManager: IEntityContactOption) => {
        return new DropdownOption(caseManager.id, caseManager.name);
    };

    static sourceConvert = (entityWithRole: IEntityContactWithRoleOption) => {
        return new DropdownOption(entityWithRole, entityWithRole.fullName);
    };

    static schoolNameConvert = (entityWithRole: ISchoolName) => {
        return new DropdownOption(entityWithRole, entityWithRole.name);
    };

    static therapyTypeConvert = (therapyType: ITherapyTypeOption) => {
        return new DropdownOption(therapyType.id, therapyType.name);
    };

    static functionConvert = (entityFunction: IFunction) => {
        return new FunctionDropdownOption(
            entityFunction.id,
            entityFunction.name,
            entityFunction.isNumberOfBedsRequired,
            entityFunction.key
        );
    };

    static countyConvert = (county: ICounty) => {
        return new DropdownOption(county.id, county.name);
    };

    static stateConvert = (state: IState) => {
        return new DropdownOption(state.id, state.name);
    };

    static staffingAgencyConvert = (agency: IStaffingAgency) => {
        return new DropdownOption(agency.id, agency.name);
    };

    static userConvert = (customer: any) => {
        return new DropdownOption(customer?.id, customer?.fullName);
    };

    static schoolConvert = (option: ISchoolOption) => {
        return new DropdownOption(option, option.year);
    };

    static departmentConvert = (department: IDepartment) => {
        return new DepartmentDropdownOption(
            department.id,
            department.name,
            department.isOrientationRequired,
            department?.subDepartments?.length > 0
        );
    };

    static subDepartmentConvert = (subDepartment: ISubDepartment, departmentName: string) => {
        const subDepartmentName = subDepartment.name.concat(` (${departmentName})`);

        return new DropdownOption(subDepartment.id, subDepartmentName);
    };

    static subDepartmentFromOptionConvert = (subDepartment: IOption) => {
        return new DropdownOption(subDepartment.id, subDepartment.name);
    };

    static disciplineConvert = (discipline: IDiscipline) => {
        return new DropdownOption(discipline.id, discipline.name);
    };

    static documentConvert = (document: IDocumentShortInfo) => {
        return new DocumentDropdownOption(
            document.id,
            document.name,
            document.isExpirationDateRequired,
            document.isIssueDateRequired,
            document.comment,
            document.instructions
        );
    };

    static entityDetailsBasicInfoToEntityBasicInfo(entity: IEntityDetailsBasicInfo): IEntityBasicInfo {
        return {
            ...entity,
            parentId: entity.parent?.id,
            functionLinks: entity.functionLinks.map((link) => {
                return {
                    id: link.id,
                    functionId: link.legalFunction?.id,
                    linkNumberOfBeds: link.linkNumberOfBeds,
                };
            }),
            stateId: entity.state?.id,
        };
    }

    static entityDetailsBasicInfoToIUpdateEntityRequest(entity: IEntityDetailsBasicInfo): IUpdateEntityRequest {
        const entityBasicInfo = this.entityDetailsBasicInfoToEntityBasicInfo(entity);

        return {
            ...entityBasicInfo,
            id: entity.id,
        };
    }
}
