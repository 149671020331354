import React, { useState } from 'react';
import PatientsList from './components/patients-list/patients-list';
import CreatePatientForm from './components/create-patients-form/create-patients-form';
import PatientService from 'common/services/api/patient/patient-service';
import { ICreatePatientRequest } from 'common/services/api/patient/types';
import { IState } from 'common/services/api/state/types';
import { useHistory } from 'react-router-dom';
import { NavigationRoutes } from 'models/routes/navigation-routes';
import { ITablePageSetting } from 'common/components/ag-table/types';

const PatientsPage = () => {
    const [patientPageSettings, setPatientPageSettings] = useState<ITablePageSetting>({
        isCreateDialogOpen: false,
        updateTable: false,
    });
    const history = useHistory();
    const [isCreateDialogOpen, setIsCreateDialogOpen] = useState(false);
    const [defaultState] = useState<IState>(null);

    const savePatient = (patient: ICreatePatientRequest) => {
        return PatientService.create(patient).then((patient) => {
            history.push({ pathname: NavigationRoutes.patientDetailsRoute(patient.id) });
            setPatientPageSettings({ isCreateDialogOpen: false, updateTable: true });
        });
    };

    return (
        <div className="table-page">
            <header>
                <div className="header-wrapper">
                    <div className="title-wrapper">
                        <h1>Patients</h1>
                    </div>
                    <button
                        className="create-patients-btn base-text-btn blue"
                        id="createPatientsBtn"
                        onClick={() => setIsCreateDialogOpen(true)}
                    >
                        New Patient
                    </button>
                </div>
            </header>
            <PatientsList updateTable={patientPageSettings.updateTable} />
            {isCreateDialogOpen && (
                <CreatePatientForm onClose={() => setIsCreateDialogOpen(false)} onSave={savePatient} defaultState={defaultState} />
            )}
        </div>
    );
};

export default PatientsPage;
