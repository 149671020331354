import RightSideBarForm from 'common/components/right-side-bar/right-side-bar-form';
import { ErrorCodes } from 'common/constants/error-codes';
import useFormSidebar from 'common/hooks/use-form-sidebar';
import { IFormSidebar } from 'common/models/types';
import { INursingHomeRequestCloseReason } from 'common/services/api/nursing-home-requests/types';
import { PLEASE_CHOOSE_A_REASON, PLEASE_EXPLAIN_THE_CLOSING_REASON } from 'features/nursing-home-requests-page/request-validation-messages';
import React from 'react';
import NursingHomeRequestCloseForm from './nursing-home-request-close-form';

const errorConfigs = [
    { errorCode: ErrorCodes.INVALID_REASON, error: PLEASE_CHOOSE_A_REASON, field: 'reasonId' },
    { errorCode: ErrorCodes.REASON_COMMENT_IS_EMPTY, error: PLEASE_EXPLAIN_THE_CLOSING_REASON, field: 'comment' },
];

const NursingHomeRequestCloseSidebar = ({ onClose, onSave }: IFormSidebar<INursingHomeRequestCloseReason, null>) => {
    const [data, error, handleChangeData, handleCancelClick, handleSaveClick] = useFormSidebar<INursingHomeRequestCloseReason>(
        {
            reasonId: 0,
            comment: null,
        },
        { onClose, onSave, errorConfigs: errorConfigs }
    );

    return (
        <RightSideBarForm saveText="Close Request" title="Closing reason" onCancel={handleCancelClick} onSave={handleSaveClick}>
            <NursingHomeRequestCloseForm data={data} errors={error} onChange={handleChangeData} />
        </RightSideBarForm>
    );
};

export default NursingHomeRequestCloseSidebar;
