import Avatar from 'common/components/Avatar/Avatar';
import React, { useEffect, useState } from 'react';
import StatusTherapistLabel from './status-therapist-label';
import { NavigationRoutes } from 'models/routes/navigation-routes';
import { AvailableTherapistInfoProps } from './types';
import { convertTherapistToUserInfo } from 'common/components/map/map-wrapper/types';
import TherapistGeneralInfo from 'common/components/map/therapist-general-info/therapist-general-info';
import OpenProfileButton from 'common/components/open-profile-button/open-page-button';
import { ITherapistOnMapModel } from 'common/services/api/therapist/types';
import therapistService from 'common/services/api/therapist/therapist-service';

export const AvailableTherapistInfo: React.FC<AvailableTherapistInfoProps> = ({ id, onAddClick, requestStatus }) => {
    const [therapist, setTherapist] = useState<ITherapistOnMapModel>(null);

    useEffect(() => {
        therapistService.getTherapistForMap(id).then((data) => setTherapist(data));
    }, [id]);

    return therapist !== null ? (
        <>
            <div className="therapist-info">
                <Avatar imageData={therapist.avatar} user={convertTherapistToUserInfo(therapist)} />
                <TherapistGeneralInfo therapist={therapist} />
            </div>
            <div className="therapist-info-footer">
                {requestStatus && <StatusTherapistLabel onClick={onAddClick} status={requestStatus} />}
                <OpenProfileButton url={NavigationRoutes.therapistDetailsRoute(therapist.id)} />
            </div>
        </>
    ) : (
        <></>
    );
};

export default React.memo(AvailableTherapistInfo);
