import { IDetailsNavigationData } from 'common/services/api/details-navigation-serivce/type';
import React, { useEffect, useMemo, useState } from 'react';
import { useHistory } from 'react-router';
import DetailsNavigation from './details-navigation';
import { ClientNavigationProps } from './types';

const ClientNavigation: React.FC<ClientNavigationProps> = ({ entityId, navigationRoute }) => {
    const history = useHistory<any>();
    const [navigationData, setNavigationData] = useState<IDetailsNavigationData>(null);
    const historyState = useMemo(() => history.location.state, [entityId]);

    useEffect(() => {
        getNavigationData();
    }, [entityId]);

    const getNavigationData = () => {
        const requestsIds: number[] = historyState?.requestsIds;
        if (!requestsIds) {
            return;
        }

        const currentEntityIndex = requestsIds.indexOf(entityId);
        setNavigationData({
            previousId: requestsIds[currentEntityIndex - 1],
            nextId: requestsIds[currentEntityIndex + 1],
        });
    };

    const handleClickPreviousEntity = () => {
        const requestsIds: number[] = historyState?.requestsIds;
        if (!requestsIds || !navigationData.previousId) {
            return;
        }

        history.push({ pathname: navigationRoute(navigationData.previousId) }, { requestsIds });
    };

    const handleClickNextEntity = () => {
        const requestsIds: number[] = historyState?.requestsIds;
        if (!requestsIds || !navigationData.nextId) {
            return;
        }

        history.push({ pathname: navigationRoute(navigationData.nextId) }, { requestsIds });
    };

    return (
        <DetailsNavigation
            navigationData={navigationData}
            onPreviousClick={handleClickPreviousEntity}
            onNextClick={handleClickNextEntity}
        />
    );
};

export default ClientNavigation;
