import React, { useMemo, useRef, useState } from 'react';
import { GeneralContactTabProps } from '../../types';
import './entity-contacts-list-tab.scss';
import EntityContactSidebarCreate from './entity-contact-forms/entity-contact-sidebar-create';
import EntityContactService from 'common/services/api/entity-contact/entity-contact-service';
import { IEntityContactForm } from 'common/services/api/entity-contact/type';
import { CellClickedEvent, GridReadyEvent } from 'ag-grid-community/dist/lib/events';
import { IParamsWatcher } from 'common/components/ag-table/types';
import { getTableData, getFilterData, getExportData } from 'common/services/api/tabel-fetch-service';
import HeaderWrapper from 'common/components/header/header-wrapper';
import HeaderLeftSide from 'common/components/header/header-left-side';
import SearchFilterInput from 'common/components/header/search-filter-input/search-filter-input';
import HeaderRightSide from 'common/components/header/header-right-side';
import ExportButton from 'common/components/header/export-button/export-button';
import ClearFiltersButton from 'common/components/header/clear-filters-button/clear-filters-button';
import AgTable from 'common/components/ag-table/ag-table';
import { useSearchInput } from 'common/hooks/use-serch-input';
import { IDefaultFilters } from 'common/constants/types';
import PhoneRender from 'common/components/ag-table/render-components/phone-render';
import InfiniteSelect from 'common/components/infinite-select/infinite-select';
import DropdownOption from 'common/models/dropdown-option';
import AddButton from 'common/components/header/add-button/add-button';
import EntityContactSidebarInfo from './entity-contact-forms/entity-contact-sidebar-info';
import { trackPromise } from 'react-promise-tracker';
import EntityContactSidebarEdit from './entity-contact-forms/entity-contact-sidebar-edit';
import Popper from 'common/components/infinite-popper/infinite-popper';
import ConfirmPopup from 'common/components/inifinite-popups/confirm-popup';
import { ContactActionType } from 'common/constants/entity-type';
import EmailRender from 'common/components/ag-table/render-components/email-render';

const defaultFilters: IDefaultFilters = {
    search: null,
};

const EntityContactListTab: React.FC<GeneralContactTabProps> = ({ entityId }) => {
    const [gridApi, setGridApi] = useState(null);
    const [gridColumnApi, setColumnApi] = useState(null);
    const [filters, setFilters] = useState(defaultFilters);
    const { search, handleSearchInputChange } = useSearchInput('', (value: string) => {
        setFilters((prev) => ({ ...prev, search: value }));
    });
    const paramsWatcher = useRef<IParamsWatcher>();
    const [contactDetails, setContactDetails] = useState(null);
    const options = useMemo(() => [new DropdownOption({ target: null, value: 'delete' }, 'Delete')], []);

    const fetchOption = useMemo(
        () => ({
            handleFetchData: getTableData(`entities/${entityId}/contacts`),
            handleFetchFilters: getFilterData(`entities/${entityId}/contacts/filter/source`),
            handleExportData: getExportData(`entities/${entityId}/contacts/export`),
        }),
        [entityId]
    );

    const gridOptions = useMemo(
        () => ({
            onCellClicked: (event: CellClickedEvent) => {
                if (event.column.getColId() === 'doteMenu') {
                    return;
                }
                trackPromise(EntityContactService.getContactDetails(entityId, event.data.id)).then((data) => {
                    toggleEntityViewDialog(true);
                    setContactDetails(data);
                });
            },
            columnDefs: [
                {
                    field: 'fullName',
                    headerName: 'Name',
                    filter: 'agCustomFilter',
                    initialSort: 'desc',
                    width: 350,
                    resizable: true,
                },
                {
                    field: 'roles',
                    wrapText: true,
                    headerName: 'Role',
                    filter: 'agCustomFilter',
                    cellRenderer: 'roleRender',
                    sortable: false,
                    resizable: true,
                    flex: 1,
                    width: 200,
                },
                {
                    field: 'email',
                    headerName: 'Email Address',
                    cellRenderer: 'emailRender',
                    sortable: false,
                    resizable: true,
                    width: 350,
                },
                {
                    field: 'phone',
                    headerName: 'Phone',
                    cellRenderer: 'phoneRender',
                    sortable: false,
                    resizable: true,
                    width: 180,
                },
                {
                    headerName: '',
                    colId: 'doteMenu',
                    pinned: 'right',
                    cellRenderer: 'doteRender',
                    cellClass: ['dote-cell'],
                    width: 40,
                    hideForExport: true,
                    resizable: true,
                },
            ],
            defaultColDef: {
                filterParams: {
                    onFetchFilters: fetchOption.handleFetchFilters,
                },
            },
            frameworkComponents: {
                phoneRender: PhoneRender,
                emailRender: EmailRender,
                roleRender: (props: any) => {
                    const otherRoles = props.data?.otherRoles?.length ? ', ' + props.data.otherRoles.join(', ') : '';

                    return props.valueFormatted + otherRoles;
                },
                doteRender: () => {
                    return (
                        <InfiniteSelect
                            id="entityContactMenu"
                            popper
                            items={options}
                            className="no-select-border"
                            onlyIcon={<i className="icon icon-options" />}
                            onChange={(item: DropdownOption) => {
                                setTargetConfirm(item?.value?.target);
                            }}
                        />
                    );
                },
            },
        }),
        [entityId, fetchOption.handleFetchFilters, options]
    );

    // Clear
    const onFiltersClear = () => {
        //setFilters({ ...defaultFilters });
        handleSearchInputChange('');
    };

    const onGridReady = (params: GridReadyEvent) => {
        setGridApi(params.api);
        setColumnApi(params.columnApi);
    };

    // Sidebar view
    const [isEntityViewDialogOpen, toggleEntityViewDialog] = useState(false);
    const handleCancelEntityViewDialog = () => toggleEntityViewDialog(false);
    const sidebarViewActions = [
        {
            title: 'Edit',
            handler: () => {
                toggleEntityViewDialog(false);
                toggleEntityEditDialog(true);
            },
        },
    ];

    // Sidebar Edit
    const [isEntityEditDialogOpen, toggleEntityEditDialog] = useState(false);
    const handleCancelEntityEditDialog = () => toggleEntityEditDialog(false);

    const handleSaveEntityEditDialog = (entityContactForm: IEntityContactForm) => {
        return trackPromise(EntityContactService.editContact(entityId, contactDetails?.id, entityContactForm)).then(() => {
            gridApi.onFilterChanged();
            handleCancelEntityEditDialog();
        });
    };

    // Sidebar Create
    const [isEntityAddDialogOpen, toggleEntityAddDialog] = useState(false);
    const handleCancelEntityAddDialog = () => toggleEntityAddDialog(false);
    const handleOpenEntityAddDialog = () => toggleEntityAddDialog(true);

    const handleSaveEntityAddDialog = (formData: IEntityContactForm, contactActionType: ContactActionType) => {
        switch (contactActionType) {
            case ContactActionType.ExistingContact:
                return EntityContactService.addExistingContacts(entityId, formData.existingContacts).then(() => {
                    gridApi.onFilterChanged();
                    handleCancelEntityAddDialog();
                });

            default:
                return EntityContactService.createContact(entityId, formData).then(() => {
                    gridApi.onFilterChanged();
                    handleCancelEntityAddDialog();
                });
        }
    };

    // Popup confirm
    const [targetConfirm, setTargetConfirm] = useState(null);
    const handleOnClickConfirm = () => {
        trackPromise(EntityContactService.archive(entityId, gridApi.getSelectedRows()[0].id)).then(() => {
            gridApi.onFilterChanged();
            setTargetConfirm(null);
        });
    };

    return (
        <div className="entity-contacts content-flex">
            <div className="main-info-wrapper ag-table-wrapper">
                <HeaderWrapper>
                    <HeaderLeftSide>
                        <SearchFilterInput title="Contacts" value={search} onInput={handleSearchInputChange} />
                    </HeaderLeftSide>
                    <HeaderRightSide>
                        <ExportButton title="Entity Contacts" paramsWatcher={paramsWatcher} onExportData={fetchOption.handleExportData} />
                        <ClearFiltersButton
                            title="Entity Contacts"
                            gridApi={gridApi}
                            gridColumnApi={gridColumnApi}
                            onClick={onFiltersClear}
                        />
                        <AddButton title="Add Contact" onClick={handleOpenEntityAddDialog} />
                    </HeaderRightSide>
                </HeaderWrapper>
                <AgTable
                    absoluteShadow
                    onGridReady={onGridReady}
                    gridOptions={gridOptions}
                    customFilters={filters}
                    paramsWatcher={paramsWatcher}
                    onFetchData={fetchOption.handleFetchData}
                />
            </div>
            {isEntityViewDialogOpen && (
                <EntityContactSidebarInfo data={contactDetails} actions={sidebarViewActions} onClose={handleCancelEntityViewDialog} />
            )}
            {isEntityEditDialogOpen && (
                <EntityContactSidebarEdit
                    data={contactDetails}
                    onSave={handleSaveEntityEditDialog}
                    onClose={handleCancelEntityEditDialog}
                />
            )}
            {isEntityAddDialogOpen && (
                <EntityContactSidebarCreate
                    options={{ entityId: entityId }}
                    onSave={handleSaveEntityAddDialog}
                    onClose={handleCancelEntityAddDialog}
                />
            )}
            {targetConfirm && (
                <Popper target={targetConfirm} width={271} onClickOutside={() => setTargetConfirm(null)}>
                    <ConfirmPopup onCancel={() => setTargetConfirm(null)} onConfirm={handleOnClickConfirm}>
                        Are you sure you want to delete this contact?
                    </ConfirmPopup>
                </Popper>
            )}
        </div>
    );
};

export default EntityContactListTab;
