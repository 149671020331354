import DisplayValue from 'common/components/display-value/display-value';
import DocumentAttachmentsForm from 'common/components/document-attachments/ducument-attachments-form';
import FieldSet from 'common/components/field-set/field-set';
import DateField from 'common/components/field/date-field';
import MoneyField from 'common/components/field/money-field';
import { dateTimeConstants } from 'common/constants/common';
import moment from 'moment';
import React from 'react';
import { RateBaseFormProps } from './types';
import { DisplayValueHelper } from 'common/helpers/display-value-helper';

const RateBaseForm: React.FC<RateBaseFormProps> = ({ rate, errors, onChange }) => {
    return (
        <div className="edit-rate-form edit-form">
            {rate?.rateId && (
                <div>
                    <DisplayValue id="rateView" placeholder="Rate" value={DisplayValueHelper.rateEmptyOrIsSet(rate?.rate, '')} />
                </div>
            )}
            {!rate?.rateId && (
                <FieldSet errors={errors} name="rate" customClass="edit-rate-field required-field-star">
                    <MoneyField id="moneyRate" placeholder="Rate" name="rate" onChange={onChange} value={rate?.rate ?? 0}></MoneyField>
                </FieldSet>
            )}

            {rate?.rateId && (
                <div>
                    <DisplayValue
                        id="startDateView"
                        placeholder="Start Date"
                        value={`${moment(rate?.startDate).format(dateTimeConstants.MM_DOT_DD_DOT_YYYY)}`}
                    />
                </div>
            )}
            {!rate?.rateId && (
                <FieldSet errors={errors} name="startDate" customClass="edit-start-date-field required-field-star">
                    <DateField
                        id="startDate"
                        placeholder="Start Date"
                        name="startDate"
                        onChange={onChange}
                        value={rate?.startDate}
                        dateFormat={dateTimeConstants.MM_DOT_DD_DOT_YYYY}
                        maskFormat="##.##.####"
                    ></DateField>
                </FieldSet>
            )}

            <FieldSet errors={errors} name="endDate" customClass="edit-end-date-field required-field-star">
                <DateField
                    id="endDate"
                    placeholder="End Date"
                    name="endDate"
                    onChange={onChange}
                    value={rate?.endDate}
                    dateFormat={dateTimeConstants.MM_DOT_DD_DOT_YYYY}
                    maskFormat="##.##.####"
                ></DateField>
            </FieldSet>

            <DocumentAttachmentsForm
                fieldName="documents"
                addButtonText="Attachments"
                values={rate?.documents}
                errors={errors}
                onChange={onChange}
                customClass={rate?.documents.some((doc) => doc.fileId != null) ? '' : 'required-field-star'}
                disabledPlaceholder="Attached document"
            />
        </div>
    );
};

export default RateBaseForm;
