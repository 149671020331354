export enum DocumentCategoryFilterType {
    Additional = 'Additional',
    Expired = 'Expired',
    Inactive = 'Inactive',
    ToApprove = 'ToApprove',
}

export type DocumentCategoryFilterProps = {
    filter: IDocumentCategoryFilter;
    onFiltersChange: (filter: IDocumentCategoryFilter) => void;
};

export interface IDocumentCategoryFilter {
    category: DocumentCategoryFilterType[];
}
