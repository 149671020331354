import React from 'react';
import Tab from 'common/components/tabs/tab';
import Tabs from 'common/components/tabs/tabs';
import RequestsTab from './components/requests-tab';
import RequestService from 'common/services/api/requests/request-service';
import { IRequestSetting } from 'common/services/api/requests/types';

const onSaveRequestSettings = (requestSettingsModel: IRequestSetting, isRequiredFields: boolean) => {
    const { daysForReopenDeclinedRequest, requiredFieldKeys } = requestSettingsModel.setting;

    if (isRequiredFields) {
        return RequestService.updateSettings(requestSettingsModel.id, { requiredFieldKeys });
    }

    return RequestService.updateSettings(requestSettingsModel.id, { daysForReopenDeclinedRequest });
};

export const HomeCareTab = () => {
    return (
        <div className="settings-tab home-care-tab">
            <Tabs orientation="horizontal" parentTabUrl="home-care">
                <Tab linkClassName={'custom-link'} text="Agencies">
                    Agencies
                </Tab>
                <Tab linkClassName={'custom-link'} text="Facilities">
                    Facilities
                </Tab>
                <Tab linkClassName={'custom-link'} text="Patients">
                    Patients
                </Tab>
                <Tab linkClassName={'custom-link'} text="Requests">
                    <RequestsTab onSaveSettings={onSaveRequestSettings} />
                </Tab>
            </Tabs>
        </div>
    );
};

export const HRTab = () => {
    return (
        <div>
            <Tabs orientation="horizontal" parentTabUrl="hr">
                <Tab linkClassName={'custom-link'} text=""></Tab>
                <Tab linkClassName={'custom-link'} text=""></Tab>
                <Tab linkClassName={'custom-link'} text=""></Tab>
            </Tabs>
        </div>
    );
};
