import React, { useContext, useMemo, useState } from 'react';
import { Card, CardWrapper } from 'common/components/card';
import { ICardConfig } from 'common/components/card/types';
import { dateTimeConstants, getEnumOptionTitle, getRate } from 'common/constants/common';
import { RequestType } from 'common/constants/types';
import { DateTimeHelper } from 'common/helpers/date-time-helper';
import NursingHomeRequestService from 'common/services/api/nursing-home-requests/nursing-home-request-service';
import { IWeekDay, NursingHomeRequestStatus } from 'common/services/api/nursing-home-requests/types';
import TherapistAssignDialog from 'features/request-page/components/request-details/general-info-tab/basic-info-tab/thrapist-assign-dialog/thrapist-assign-dialog';
import { NavigationRoutes } from 'models/routes/navigation-routes';
import moment from 'moment';
import { NursingHomeRequestDetailsContext } from '../../nursing-home-request-details-page';
import { NursingHomeRequestBasicInfoTabProp } from '../../types';
import NursingHomeBaseSidebar from '../../../nursing-home-request-form/nursing-home-basic-sidebar';
import { INursingHomeBasicForm } from '../../../nursing-home-request-form/types';
import { trackPromise } from 'react-promise-tracker';
import nursingHomeService from 'common/services/api/nursing-home/nurcing-home-service';
import AssignedTherapistTable from 'common/components/assigned-therapist-list/assigned-therapist-table';
import { useIsUserType } from 'common/hooks/use-is-user-type';
import ActionButton from 'common/components/card/parts/action-button/action-button';
import { getTableData } from 'common/services/api/tabel-fetch-service';
import { TherapistRequestStatus } from 'common/components/assigned-therapist-list/types';
import { nursingHomeIconArray } from 'common/constants/map/map';
import { useResponsiveLayout } from 'common/hooks/use-responsive-layout';
import './nursing-home-request-basic-info-tab.scss';

const convertDateToFormat = (date: string) => moment(date).local().format(dateTimeConstants.MM_DD_YYYY);
const renderRequestNumberFormat = (value: number) => value.toString().padStart(10, '0');

const NursingHomeRequestBasicInfoTab: React.FC<NursingHomeRequestBasicInfoTabProp> = ({ requestId, requestDetails }) => {
    const [isSpecialInstruction, isSpecialInstructions] = useState(false);
    const [isCaseBarOpen, toggleCaseBar] = useState(false);
    const [isTherapistAssignOpen, toggleTherapistAssign] = useState(false);
    const { setRequestDetails, updateDetailsPage } = useContext(NursingHomeRequestDetailsContext);
    const { isExternal } = useIsUserType();
    const isClosed = () => requestDetails?.status === NursingHomeRequestStatus.Closed;

    const renderDays = (weekDays: IWeekDay[]) => {
        if (weekDays?.length === 0) {
            return;
        }

        return (
            <div className="card-days-wrapper">
                <div className="days-wrapper-header">
                    <div className="days-wrapper-header-title">Days</div>
                </div>
                <div className="days-items-wrapper">
                    {weekDays?.map((item, index) => (
                        <div key={`${item.day}_${index}`} className={`day-item`}>
                            <div className="day-checkbox-wrapper">
                                <span className="day-label">{item.day}</span>
                            </div>
                            <div className="time-ranges-wrapper">
                                <span id={`startTime${index}`} className="time-label">
                                    {DateTimeHelper.timespanToUTCTime(item.startTime, dateTimeConstants.HH_MM_A)}
                                </span>
                                <span id={`endTime${index}`} className="time-label">
                                    {DateTimeHelper.timespanToUTCTime(item.endTime, dateTimeConstants.HH_MM_A)}
                                </span>
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        );
    };

    const caseDetailsConfig: ICardConfig[] = [
        { title: 'Discipline', field: 'therapyType', render: getEnumOptionTitle },
        {
            title: 'Entity',
            field: 'entity',
            className: 'object-link-with-icon',
            render: getEnumOptionTitle,
            onClick: (value: any, data: any) => {
                return data.entity ? window.open(NavigationRoutes.entityDetailsRoute(data.entity.id), '_blank').focus() : null;
            },
        },
        {
            title: 'Facility',
            field: 'facility',
            className: 'object-link-with-icon',
            render: getEnumOptionTitle,
            onClick: (value: any, data: any) =>
                data.facility ? window.open(NavigationRoutes.entityDetailsRoute(data.facility.id), '_blank').focus() : null,
        },
        { title: 'Placement Type', field: 'placementType', render: getEnumOptionTitle },
        { title: 'Rate', field: requestDetails.customRate ? 'customRate' : 'rate', render: getRate },
        { title: 'Coverage Type', field: 'coverageType', render: getEnumOptionTitle },
        { title: 'Start Date', field: 'startDate', render: (value) => DateTimeHelper.format(value, dateTimeConstants.MM_DD_YYYY, true) },
        { title: 'End Date', field: 'endDate', render: (value) => DateTimeHelper.format(value, dateTimeConstants.MM_DD_YYYY, true) },
        { hideTitle: true, field: 'weekDays', className: 'week-days', render: renderDays, hideIfEmpty: true },
        {
            title: 'Flex Days',
            field: 'flexDate',
            className: `two-column-card`,
            hideIfEmpty: true,
            render: (value) => (value ? 'true' : null),
        },
        {
            title: 'Flex Time',
            field: 'flexTime',
            className: `two-column-card ${requestDetails.flexDate ? 'pl-0' : ''}`,
            hideIfEmpty: true,
            render: (value) => (value ? 'true' : null),
        },
        { title: 'Source', field: 'source', render: (value) => value?.contact?.fullName ?? value },
        { title: 'Date Request Came In', field: 'cameInDate', render: convertDateToFormat },
        { title: 'Request Number', field: 'id', render: renderRequestNumberFormat },
    ];

    const specialInstructionsConfig: ICardConfig[] = [{ hideTitle: true, field: 'specialInstructions' }];

    const openAvailableTherapistList = () => {
        toggleTherapistAssign(true);
    };

    // Case Details Bar
    const handleClickOnCaseEdit = () => {
        toggleCaseBar(true);
    };

    const handleSpecialInstuctionsPencilClick = () => {
        isSpecialInstructions(true);
        toggleCaseBar(true);
    };

    const handleClickCaseSave = (nursingHomeBasicForm: INursingHomeBasicForm) => {
        return trackPromise(nursingHomeService.saveRequest(nursingHomeBasicForm)).then(() => {
            toggleCaseBar(false);
            setRequestDetails(null);
        });
    };

    const handleClickCaseClose = () => {
        toggleCaseBar(false);
        isSpecialInstructions(false);
    };

    const handleAddTherapistToRequest = (requestId: number, therapistIds: number[]) => {
        NursingHomeRequestService.addTherapistToRequest({
            requestId,
            therapistIds,
        }).then(() => {
            setRequestDetails(null);
            toggleTherapistAssign(false);
        });
    };

    const handleFetchData = useMemo(() => {
        const requestUrl: string = `nursing-home/requests/${requestId}/therapists`;

        return getTableData(isExternal ? `${requestUrl}?status=${TherapistRequestStatus.Assigned}` : requestUrl);
    }, [isExternal, requestId]);

    const facilityDetails = requestDetails?.facility?.address;
    const specialInstructions = requestDetails?.specialInstructions;

    const headerInfo = () => (
        <>
            <span>
                <span className="rh-info__caption">Facility: </span> {facilityDetails.address1} {facilityDetails.address2}{' '}
                {facilityDetails.city}, {facilityDetails.state?.name} {facilityDetails.postalCode}
            </span>
            <span className="rh-info__instructionBody">
                <span className="rh-info__instructionTitle">Special Instructions </span>
                <div>{specialInstructions}</div>
            </span>
        </>
    );
    const transformFacilityAddress = () =>
        (facilityDetails?.address1 || facilityDetails?.postalCode) && {
            address1: facilityDetails.address1,
            city: facilityDetails.city,
            state: facilityDetails.state,
            zip: facilityDetails.postalCode,
        };

    const { mediaClass } = useResponsiveLayout({
        contentWrapper: '.nh-request-details-base-info-tab',
        onResize: (width, contentWidth) => {
            let className = '';
            if (width <= 1600 || contentWidth <= 1329) {
                className = 'lg-card';
            }
            if (width <= 1426 || contentWidth <= 1155) {
                className = 'md-card';
            }
            if (width <= 1260 || contentWidth <= 989) {
                className = 'sm-card';
            }
            if (width <= 1024 || contentWidth <= 754) {
                className = 'extra-sm-card';
            }

            return className;
        },
    });

    return (
        <div className="nh-request-details-base-info-tab">
            {isTherapistAssignOpen && (
                <TherapistAssignDialog
                    toggleTherapistAssign={toggleTherapistAssign}
                    headerInfo={requestDetails?.facility?.id && headerInfo}
                    iconArray={nursingHomeIconArray}
                    addressDetails={transformFacilityAddress()}
                    requestDetails={requestDetails}
                    requestType={RequestType.NursingHome}
                    onAddTherapistToRequest={handleAddTherapistToRequest}
                />
            )}
            {isCaseBarOpen && (
                <NursingHomeBaseSidebar
                    onClose={handleClickCaseClose}
                    onSave={handleClickCaseSave}
                    data={requestDetails}
                    options={{ isEdit: true, title: 'Edit Request' }}
                    isFocused={isSpecialInstruction}
                />
            )}
            <CardWrapper className={`nh-request-grid-wrapper ${mediaClass}`}>
                <Card
                    titleIcon="icon-job"
                    title="Case Details"
                    className="request-case-details-card box-1"
                    defaultValue="-"
                    config={caseDetailsConfig}
                    onEdit={!isClosed() ? handleClickOnCaseEdit : null}
                    data={requestDetails}
                    iconText={null}
                />
                <Card
                    className="request-special-instructions-details-card box-2"
                    titleIcon="icon-info"
                    title="Special Instructions"
                    data={requestDetails}
                    defaultValue="-"
                    config={specialInstructionsConfig}
                    onEdit={!isClosed() ? handleSpecialInstuctionsPencilClick : null}
                    iconText={null}
                />
                <Card
                    className="request-therapist-details-card assigned-therapist-table box-4"
                    titleIcon="icon-technician"
                    title="Provider Assignment"
                    defaultValue="-"
                    iconInCard="icon-search"
                    actionButtons={<>{!isExternal && <ActionButton icon="icon-add" onClick={openAvailableTherapistList} />}</>}
                >
                    <AssignedTherapistTable
                        requestId={requestId}
                        hideDoteMenu={isExternal}
                        onFetchData={handleFetchData}
                        onChangeStatus={(requestId, therapistRequestId, statusForm) =>
                            NursingHomeRequestService.setStatusToTherapistRequest(requestId, therapistRequestId, statusForm).then(
                                updateDetailsPage
                            )
                        }
                        onSaveNote={NursingHomeRequestService.addNoteToTherapistRequest}
                    />
                </Card>
            </CardWrapper>
        </div>
    );
};

export default NursingHomeRequestBasicInfoTab;
