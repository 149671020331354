import React, { useCallback, useState } from 'react';
import CreateTherapistForm from '../create-therapist/create-therapist-form';
import TherapistListPage from '../therapist-list-page/therapist-list-page';
import { trackPromise } from 'react-promise-tracker';
import { useHistory } from 'react-router';
import { NavigationRoutes } from 'models/routes/navigation-routes';
import { DisplayValueHelper } from 'common/helpers/display-value-helper';
import { CommunicationHelper } from 'common/helpers/communication-helper';
import TherapistsMapPage from 'features/therapists-map-page/therapists-map-page';
import { ITherapistCustomFilters } from 'features/therapist-list-page/type';
import { ICreateTherapistInfo } from 'features/create-therapist/types';
import therapistService from 'common/services/api/therapist/therapist-service';

const TherapistPage: React.FC = () => {
    const history = useHistory();

    const [isCreateTherapistDialogOpen, setIsCreateTherapistDialogOpen] = useState(false);
    const [commonTherapistFilters, setCommonTherapistFilters] = useState<ITherapistCustomFilters>();
    const [defaultMapZip, setDefaultMapZip] = useState<string>();
    const [isMapOpen, setIsMapOpen] = useState(false);

    const handleClickOnNewTherapist = () => setIsCreateTherapistDialogOpen(true);
    const handleCloseCreateDialog = () => setIsCreateTherapistDialogOpen(false);

    const onListClick = () => setIsMapOpen(false);
    const onMapClick = useCallback((filters: ITherapistCustomFilters, zip: string) => {
        setCommonTherapistFilters(filters);
        setDefaultMapZip(zip);
        setIsMapOpen(true);
    }, []);

    const saveTherapist = (therapist: ICreateTherapistInfo) => {
        therapist.phones.forEach((phone) => {
            if (phone.number) {
                phone.number = DisplayValueHelper.unmaskPhone(phone.number);
            }
        });

        const therapistValidCommunicationInfo = CommunicationHelper.Validate({
            phones: therapist.phones,
            isPhonesRequiered: true,
        });

        therapist.phones = therapistValidCommunicationInfo.phones;

        return trackPromise(
            therapistService.addTherapist(therapist).then((response: any) => {
                const createdTherapist = response;
                handleCloseCreateDialog();
                history.push({ pathname: NavigationRoutes.therapistDetailsRoute(createdTherapist.id) });

                return createdTherapist;
            })
        );
    };

    if (isMapOpen) {
        return <TherapistsMapPage onListClick={onListClick} defaultFilters={commonTherapistFilters} defaultAddress={defaultMapZip} />;
    }

    return (
        <div className="therapist-page table-page">
            <header>
                <div className="header-wrapper">
                    <div className="title-wrapper">
                        <h1>Providers</h1>
                    </div>
                    <button className="create-therapist-btn base-text-btn blue" id="createTherapistBtn" onClick={handleClickOnNewTherapist}>
                        New Provider
                    </button>
                </div>
            </header>
            <TherapistListPage onMapClick={onMapClick} />
            {isCreateTherapistDialogOpen && (
                <CreateTherapistForm onCancel={handleCloseCreateDialog} onSave={saveTherapist}></CreateTherapistForm>
            )}
        </div>
    );
};

export default TherapistPage;
