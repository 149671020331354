import { InfiniteServerClient } from 'common/helpers/http-clients';
import { INursingHomeBasicForm } from 'features/nursing-home-requests-page/components/nursing-home-request-form/types';

export class NursingHomeService {
    saveRequest(request: INursingHomeBasicForm): Promise<void> {
        const {
            id,
            rateId,
            customRate,
            isCustomRate,
            startDate,
            endDate,
            specialInstructions,
            weekDays,
            individualDays,
            flexTime,
            flexDate,
        } = request;

        return InfiniteServerClient.put(`nursing-home/requests/${id}/edit`, {
            id,
            rateId: customRate ? null : rateId,
            customRate,
            isCustomRate,
            startDate,
            endDate,
            specialInstructions,
            weekDays,
            flexDate,
            flexTime,
            individualDays: individualDays?.length > 0 ? individualDays : null,
        }).then(
            (response) => {
                return response.data;
            },
            (error) => {
                throw error;
            }
        );
    }

    createRequest(request: INursingHomeBasicForm): Promise<void> {
        return InfiniteServerClient.post(`/nursing-home/requests`, {
            ...request,
            individualDays: request?.individualDays?.length > 0 ? request?.individualDays : null,
        }).then(
            (response) => {
                return response.data;
            },
            (error) => {
                throw error;
            }
        );
    }
}

export default new NursingHomeService();
