import { IDepartment, ISubDepartment } from 'common/services/api/department/types';
import { IPopulation } from 'common/services/api/population/types';
import { EmploymentStatuses } from 'models/enums/employment-statuses';
import { PayTypes } from 'models/enums/pay-types';

export const AssignmentListColumnsDefenitions = [
    {
        field: 'department',
        headerName: 'Department',
        valueFormatter: (props: any) => (props.value as IDepartment).name,
        filter: 'agCustomFilter',
        width: 180,
    },
    {
        field: 'subDepartments',
        headerName: 'Sub Department',
        width: 180,
        sortable: false,
        filter: 'agCustomFilter',
        valueFormatter: (props: any) => (props.value as ISubDepartment[]).map((i) => i.name).join(', '),
    },
    {
        field: 'customers',
        headerName: 'Customers',
        sortable: false,
        width: 350,
        filter: 'agCustomFilter',
        cellRenderer: 'customersLinkRenderer',
    },
    {
        field: 'effectiveDates',
        headerName: 'Effective Dates',
        sortable: false,
        width: 250,
        filter: false,
        cellRenderer: 'effectiveDatesRenderer',
    },
    {
        field: 'rate',
        headerName: 'Rate',
        width: 112,
        filter: 'agCustomFilter',
        cellRenderer: 'rateRenderer',
        filterParams: {
            transformFromServer: (value: string) => {
                return value ? parseFloat(value).toFixed(2) : '';
            },
        },
    },
    {
        field: 'payType',
        headerName: 'Pay Type',
        filter: 'agCustomFilter',
        width: 115,
        valueFormatter: (props: any) => {
            const payType = props.value as string;

            return ((PayTypes as any)[payType] as any)?.label;
        },
    },
    {
        field: 'employmentStatus',
        headerName: 'Employment Status',
        filter: 'agCustomFilter',
        width: 244,
        valueFormatter: (props: any) => {
            const status = props.value as string;

            return ((EmploymentStatuses as any)[status] as any)?.label;
        },
    },
    {
        field: 'populations',
        headerName: 'Population',
        sortable: false,
        filter: 'agCustomFilter',
        width: 140,
        valueFormatter: (props: any) => (props.value as IPopulation[]).map((i) => i.name).join(', '),
    },
    {
        field: 'orientation',
        headerName: 'Orientation',
        filter: 'agCustomFilter',
        width: 170,
        valueFormatter: (props: any) => (!props.value ? 'Do not need' : props.value),
    },
    {
        field: 'complianceStatus',
        headerName: 'Assignment status',
        filter: 'agCustomFilter',
        width: 294,
    },
    {
        headerName: '',
        colId: 'doteMenu',
        pinned: 'right',
        cellRenderer: 'doteRender',
        cellClass: ['dote-cell'],
        width: 40,
        hideForExport: true,
    },
];
