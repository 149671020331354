import { DateTimeHelper } from 'common/helpers/date-time-helper';
import { InfiniteServerClient } from 'common/helpers/http-clients';
import { ValidationHelper } from 'common/helpers/validation-helper';
import { IRejectRequest } from 'common/models/reject-request';
import { IRequsetTherapistDocumentInfo, ITherapistDocumentInfo } from './types';

export class TherapistDocumentService {
    approveTherapistDocument(therapistId: number, therapistDocumentId: number): Promise<void> {
        return InfiniteServerClient.put<void>(`/therapists/${therapistId}/documents/${therapistDocumentId}/status/approval`).then(
            (response) => {
                return response.data;
            }
        );
    }

    approveTherapistDocuments(therapistId: number, therapistDocumentIds: number[]): Promise<void> {
        return InfiniteServerClient.put<void>(`/therapists/${therapistId}/documents/status/approval`, therapistDocumentIds).then(
            (response) => {
                return response.data;
            }
        );
    }

    createRequestTherapistDocument(therapistId: number, document: IRequsetTherapistDocumentInfo) {
        return InfiniteServerClient.post(`/therapists/${therapistId}/documents/requests`, document).then(
            (response) => {
                return response.data;
            },
            (error) => {
                throw error.response.data;
            }
        );
    }

    addAdditionalTherapistDocument(therapistId: number, therapistDocument: any) {
        const bodyFormData = new FormData();
        for (const key in therapistDocument) {
            if (therapistDocument[key]) {
                bodyFormData.append(key, therapistDocument[key]);
            }
        }

        return (
            ValidationHelper.validateFile(bodyFormData) ??
            InfiniteServerClient.post(`/therapists/${therapistId}/documents/additional`, bodyFormData).then(
                (response) => {
                    const data = response.data;
                    if (data) {
                        return data;
                    }
                },
                (error) => {
                    throw error.response.data;
                }
            )
        );
    }

    addTherapistDocument(therapistId: number, therapistDocument: any) {
        const bodyFormData = getFormData(therapistDocument);

        return (
            ValidationHelper.validateFile(bodyFormData) ??
            InfiniteServerClient.post(`/therapists/${therapistId}/documents`, bodyFormData).then(
                (response) => {
                    const data = response.data;
                    if (data) {
                        return data;
                    }
                },
                (error) => {
                    throw error.response.data;
                }
            )
        );
    }

    updateTherapistDocument = (therapistId: number, updatedTherapistDocument: ITherapistDocumentInfo) => {
        const bodyFormData = getFormData(updatedTherapistDocument);

        return (
            ValidationHelper.validateFile(bodyFormData) ??
            InfiniteServerClient.put(`/therapists/${therapistId}/documents/${updatedTherapistDocument.id}`, bodyFormData).then(
                (response) => {
                    const data = response.data;
                    if (data) {
                        return data;
                    }
                },
                (error) => {
                    throw error.response.data;
                }
            )
        );
    };

    updateTherapistDocumentHistory(therapistId: number, updatedTherapistDocumentHistory: ITherapistDocumentInfo) {
        const bodyFormData = getFormData(updatedTherapistDocumentHistory);

        return (
            ValidationHelper.validateFile(bodyFormData) ??
            InfiniteServerClient.put(
                `/therapists/${therapistId}/documents/history/${updatedTherapistDocumentHistory.id}`,
                bodyFormData
            ).then(
                (response) => {
                    const data = response.data;
                    if (data) {
                        return data;
                    }
                },
                (error) => {
                    throw error.response.data;
                }
            )
        );
    }

    rejectDocument(therapistId: number, documentId: number, request: IRejectRequest): Promise<void> {
        return InfiniteServerClient.put<void>(`/therapists/${therapistId}/documents/${documentId}/status/reject`, request || {}).then(
            (response) => {
                return response.data;
            },
            (error) => {
                throw error;
            }
        );
    }
}

const getFormData = (object: any) => {
    const bodyFormData = new FormData();
    for (const key in object) {
        let value = object[key];
        if (value) {
            if (value._isAMomentObject) {
                value = DateTimeHelper.format(value.toDate(), null, true);
            }

            if (value instanceof Date) {
                value = DateTimeHelper.format(value, null, true);
            }

            bodyFormData.append(key, value);
        }
    }

    return bodyFormData;
};

export default new TherapistDocumentService();
