import React, { useCallback, useEffect, useState } from 'react';
import DropdownOption from '../../common/models/dropdown-option';
import FieldSet from 'common/components/field-set/field-set';
import FileField from 'common/components/field/file-field/file-field';
import InputField from 'common/components/field/input-field';
import RightSideBar from 'common/components/right-side-bar/right-side-bar';
import FocusTrap from 'focus-trap-react';
import SimilarDialog from '../../common/components/similar-dialog/similar-dialog';
import { HttpStatusCode } from 'common/constants/http-status-codes';
import PaginationAutocomplete from 'common/components/field/pagination-autocomplete/pagination-autocomplete';
import { ValidationHelper } from 'common/helpers/validation-helper';
import { StateService } from 'common/services/api/state/state-service';
import { therapistValidationRules } from '../../common/validation/therapist-validation-rules';
import { generalValidationRules } from '../../common/validation/general-validation-rules';
import FieldList from 'common/components/field-list/field-list';
import PhoneTypeInput from 'common/components/phone-type-input/phone-type-input';
import { SequenceHelper } from 'common/helpers/sequence-helper';
import { labels } from 'common/constants/labels';
import { ConverterHelper } from 'common/helpers/converter-helper';
import { OptionField } from 'common/constants/option-fields';
import DisciplineAutocomplete from 'common/components/discipline-autocomplete/discipline-autocomplete';
import { NavigationRoutes } from 'models/routes/navigation-routes';
import { ICreateTherapistInfo } from './types';
import ReferralSourceService from 'common/services/api/referral-source/referral-source-service';

type CreateTherapistFormProps = {
    onSave: any;
    onCancel: any;
};

const CreateTherapistForm: React.FC<CreateTherapistFormProps> = ({ onSave, onCancel }) => {
    const [therapist, setTherapist] = useState<ICreateTherapistInfo>({
        firstName: '',
        lastName: '',
        middleName: '',
        address1: '',
        address2: '',
        city: '',
        stateId: null,
        zip: '',
        email: '',
        referralSourceId: 0,
        resume: undefined,
        position: null,
        disciplineOptions: [],
        phones: [],
        saveDespiteUniqueCheck: false,
    });

    const [errorData, setErrorData] = useState<any>({});
    const [similarTherapistsDialogData, setsimilarTherapistsDialogData] = useState({
        isOpen: false,
        existedEntities: [],
        allowSave: false,
    });

    const isAddressTouched = useCallback(() => {
        return therapist.zip || therapist.address1 || therapist.city || therapist.stateId;
    }, [therapist.address1, therapist.city, therapist.stateId, therapist.zip]);

    const handleFieldChange = (data: any) => {
        setTherapist((prevInfo) => ({ ...prevInfo, [data.name]: data.value }));
        setErrorData((prevError: any) => ({ ...prevError, [data.name]: undefined }));
    };

    const phones = [new DropdownOption('Home', 'Home'), new DropdownOption('CellPhone', 'Cell')];

    const handleSave = () => {
        SequenceHelper.setSequenceItems(therapist?.phones);

        onSave({
            ...therapist,
            disciplines: therapist.disciplineOptions.map((item) => item.value),
        }).catch(handleOnError);
    };

    const handleCloseSimilarTherapistsDialog = () => {
        setsimilarTherapistsDialogData({ isOpen: false, existedEntities: [], allowSave: false });
    };

    const handleClickOnSaveAnywayButton = () => {
        setTherapist((prevInfo) => ({ ...prevInfo, saveDespiteUniqueCheck: true }));
    };

    useEffect(() => {
        if (therapist.saveDespiteUniqueCheck) {
            handleSave();
        }
    }, [therapist.saveDespiteUniqueCheck]);

    const stateConvertFunction = (state: any) => {
        return new DropdownOption(state.id, state.name);
    };

    const handleOnError = (error: any) => {
        switch (error.status) {
            case HttpStatusCode.CONFLICT:
                setsimilarTherapistsDialogData({
                    isOpen: true,
                    existedEntities: error.data.details.existedEntities,
                    allowSave: error.data.details.allowSave,
                });
                break;
            case HttpStatusCode.BAD_REQUEST: {
                const errors = ValidationHelper.handleErrorResponse(error.data);
                setErrorData(errors);
                break;
            }
            default:
                setErrorData(error.data);
                break;
        }
    };

    const generateAddressClassName = useCallback(() => {
        return isAddressTouched() ? 'edit-therapist-field required-field-star' : 'edit-therapist-field';
    }, [isAddressTouched]);

    return (
        <div>
            <FocusTrap>
                <RightSideBar
                    disableSave={similarTherapistsDialogData.isOpen}
                    title="Create Provider"
                    onCancel={onCancel}
                    onSave={handleSave}
                >
                    <div className="create-therapist-form outline-none" tabIndex={1}>
                        <FieldSet name="firstName" errors={errorData} customClass="edit-therapist-field required-field-star">
                            <InputField
                                id="firstName"
                                placeholder="First Name"
                                name="firstName"
                                onChange={handleFieldChange}
                                value={therapist.firstName}
                                tabIndex="1"
                            />
                        </FieldSet>
                        <FieldSet name="lastName" errors={errorData} customClass="edit-therapist-field required-field-star">
                            <InputField
                                id="lastName"
                                placeholder="Last Name"
                                name="lastName"
                                onChange={handleFieldChange}
                                value={therapist.lastName}
                                tabIndex="2"
                            />
                        </FieldSet>
                        <FieldSet name="middleName" errors={errorData} customClass="edit-therapist-field">
                            <InputField
                                id="middleInitial"
                                placeholder={labels.middleNameInitial}
                                maxLength={therapistValidationRules.middleName.maxLength}
                                name="middleName"
                                onChange={handleFieldChange}
                                value={therapist.middleName}
                                tabIndex="3"
                            />
                        </FieldSet>
                        <FieldSet name="address1" errors={errorData} customClass={generateAddressClassName()}>
                            <InputField
                                id="address1"
                                placeholder="Address 1"
                                name="address1"
                                onChange={handleFieldChange}
                                value={therapist.address1}
                                tabIndex="4"
                            />
                        </FieldSet>
                        <FieldSet name="address2" errors={errorData} customClass="edit-therapist-field">
                            <InputField
                                id="address2"
                                placeholder="Address 2"
                                name="address2"
                                onChange={handleFieldChange}
                                value={therapist.address2}
                                tabIndex="5"
                            />
                        </FieldSet>
                        <FieldSet name="city" errors={errorData} customClass={generateAddressClassName()}>
                            <InputField
                                id="city"
                                placeholder="City"
                                name="city"
                                maxLength={generalValidationRules.city.maxLength}
                                onChange={handleFieldChange}
                                value={therapist.city}
                                tabIndex="6"
                            />
                        </FieldSet>
                        <FieldSet name="stateId" errors={errorData} customClass={`${generateAddressClassName()} state-autocomplete`}>
                            <PaginationAutocomplete
                                fetchData={new StateService().getAll}
                                convertFunction={stateConvertFunction}
                                id="states"
                                defaultValue=""
                                placeholder="State"
                                name="stateId"
                                tabIndex="7"
                                onDelete={() => handleFieldChange({ name: 'stateId', value: undefined })}
                                onSelect={(item) => handleFieldChange({ name: 'stateId', value: item.value })}
                            />
                        </FieldSet>
                        <FieldSet name="zip" errors={errorData} customClass={generateAddressClassName()}>
                            <InputField
                                id="zip"
                                allowOnlyDigits={true}
                                placeholder="Zip"
                                name="zip"
                                maxLength={generalValidationRules.zip.maxLength}
                                onChange={handleFieldChange}
                                value={therapist.zip}
                                tabIndex="8"
                            ></InputField>
                        </FieldSet>
                        <FieldList
                            name="phones"
                            isDragNDrop
                            values={[...therapist.phones]}
                            defaultValue={{ type: phones[0].value, number: '' }}
                            addButtonText="Add Phone"
                            onChange={(values) => handleFieldChange({ name: 'phones', value: values })}
                            renderInput={(value, index) => (
                                <FieldSet
                                    name="phones"
                                    errors={errorData}
                                    customClass={`edit-phone ${index === 0 ? 'main-item' : ''} required-field-star`}
                                    renderError={(error: any) => error[index]?.number[0]}
                                >
                                    <PhoneTypeInput
                                        errors={errorData?.['phones']?.[index]}
                                        phoneTypes={phones}
                                        onChange={(value) => {
                                            therapist.phones[index] = { ...value };
                                            handleFieldChange({ name: 'phones', value: [...therapist.phones] });
                                        }}
                                        value={value}
                                    />
                                </FieldSet>
                            )}
                        />
                        <FieldSet name="email" errors={errorData} customClass="edit-therapist-field required-field-star">
                            <InputField
                                id="emailAddress"
                                placeholder="Email Address"
                                name="email"
                                onChange={handleFieldChange}
                                value={therapist.email}
                                tabIndex="11"
                            />
                        </FieldSet>
                        <FieldSet name="resume" errors={errorData} customClass="edit-therapist-field">
                            <FileField
                                name="resume"
                                onChange={handleFieldChange}
                                placeholder="Resume"
                                iconClass="icon-upload-file"
                                uploadedFileName={therapist.resume?.name}
                                isError={errorData?.resume}
                            />
                        </FieldSet>

                        <FieldSet name="disciplines" errors={errorData} customClass="edit-therapist-field required-field-star">
                            <DisciplineAutocomplete
                                defaultSelectedLabel={null}
                                cleanable={false}
                                onChange={(items) => {
                                    handleFieldChange({ name: 'disciplineOptions', value: items });
                                    setErrorData((prevError: any) => ({ ...prevError, disciplines: undefined }));
                                }}
                                value={therapist.disciplineOptions.map((i) => i.value)}
                            />
                        </FieldSet>
                        <FieldSet name="referralSourceId" errors={errorData} customClass="edit-therapist-field required-field-star">
                            <PaginationAutocomplete
                                fetchData={ReferralSourceService.getAll}
                                convertFunction={ConverterHelper.referralSourceConvert}
                                id="referralSourceId"
                                cleanable={false}
                                defaultValue=""
                                placeholder="Referral Source"
                                name={OptionField.referralSource}
                                tabIndex="13"
                                onDelete={() => handleFieldChange({ name: 'referralSourceId', value: undefined })}
                                onSelect={(item) => handleFieldChange({ name: 'referralSourceId', value: item.value })}
                            />
                        </FieldSet>
                    </div>
                    {similarTherapistsDialogData.isOpen && (
                        <div className="similar-existing-entity-dialog-wrapper">
                            <SimilarDialog
                                transformer={handleTransformation}
                                existedEntities={similarTherapistsDialogData.existedEntities}
                                allowSave={similarTherapistsDialogData.allowSave}
                                onCancel={handleCloseSimilarTherapistsDialog}
                                onSave={handleClickOnSaveAnywayButton}
                            />
                        </div>
                    )}
                </RightSideBar>
            </FocusTrap>
            <div className="right-sidebar-overlay"></div>
        </div>
    );
};

const handleTransformation = (therapist: any) => ({
    title: therapist?.fullName,
    body: therapist.email,
    url: NavigationRoutes.therapistDetailsRoute(therapist.id),
});

export default CreateTherapistForm;
