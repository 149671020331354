import { useState } from 'react';
import SimpleTimePicker from 'common/components/field/simple-time-picker/simple-time-picker';
import moment from 'moment';
import React from 'react';
import { FlexValues, IWeekDayState, OnChangeTimeModel, WeekDaysProps } from './type';
import './week-days.scss';
import FieldSet from '../field-set/field-set';

const defaultStartValue = '09:00';
const defaultEndDate = '17:00';
const defaultValues = moment
    .weekdays()
    .filter((item) => item !== 'Saturday')
    .map((item) => {
        return {
            day: item,
            startTime: defaultStartValue,
            endTime: defaultEndDate,
            selected: false,
        };
    });

const WeekDays = ({ title, icon, selected, flexDays, flexTimes, defaultList, name, errors, onChange }: WeekDaysProps) => {
    const [weekdays] = useState<IWeekDayState[]>(
        (defaultList ?? defaultValues)?.map((item) => {
            const found = selected?.find((seletedItem) => seletedItem.day === item.day);

            return {
                day: item?.day,
                startTime: defaultStartValue,
                endTime: defaultEndDate,
                ...found,
                selected: !!found,
            };
        })
    );

    const onCheckBoxChange = (index: number) => {
        const changedDay = weekdays[index];
        changedDay.selected = !changedDay.selected;
        onChange({
            weekdays: weekdays.filter((item) => item.selected),
            flexDays,
            flexTimes,
        });
    };

    const onTimeChange = (data: OnChangeTimeModel, index: number) => {
        const changedDay = weekdays[index];
        changedDay[data.name] = data.value;
        onChange({
            weekdays: weekdays.filter((item) => item.selected),
            flexDays,
            flexTimes,
        });
    };

    const onFlexValuesChange = (field: FlexValues, value: boolean) => {
        onChange({
            weekdays: weekdays.filter((item) => item.selected),
            flexDays,
            flexTimes,
            [field]: value,
        });
    };
    const list = weekdays.map((item: any, index) => {
        const weekError = errors?.[name]?.[index];
        const errorObject = { [name]: weekError?.startTime || weekError?.endTime };

        return (
            <FieldSet errors={errorObject} name={name} customClass="edit-field">
                <div key={`${item.day}_${index}`} className={`day-item ${!item.selected && 'unchecked'}`}>
                    <div className="day-checkbox-wrapper" onClick={() => onCheckBoxChange(index)}>
                        <input id={item.day} name={item.day} type="checkbox" className="nps-checkbox" checked={item.selected} readOnly />
                        <label htmlFor={item.day}></label>
                        <span className="day-label">{item.day}</span>
                    </div>
                    <div className="time-ranges-wrapper">
                        <SimpleTimePicker
                            id="ReminderTime"
                            placeholder="From"
                            label="From"
                            name="startTime"
                            onChange={(data) => onTimeChange(data as OnChangeTimeModel, index)}
                            value={item.startTime}
                            className="from-time"
                            iconClass="icon-dropdown"
                        />
                        <SimpleTimePicker
                            id="ReminderTime"
                            label="To"
                            placeholder="To"
                            name="endTime"
                            onChange={(data) => onTimeChange(data as OnChangeTimeModel, index)}
                            value={item.endTime}
                            iconClass="icon-dropdown"
                        />
                    </div>
                </div>
            </FieldSet>
        );
    });
    const errorObject = typeof errors?.[name]?.[0] === 'string' ? errors : undefined;

    return (
        <FieldSet errors={errorObject} name={name} customClass="edit-field required-field-star">
            <div className={`days-wrapper ${errors?.[name] ? 'has-error' : ''}`}>
                <div className="days-wrapper-header">
                    <div className="days-wrapper-header-title">{title}</div>
                    <i className={`icon ${icon}`}></i>
                </div>
                <div className="days-items-wrapper">
                    {list}
                    <div className="flex-values-wrapper">
                        <div onClick={() => onFlexValuesChange(FlexValues.flexDays, !flexDays)} className="flex-days-checkbox">
                            <input
                                id={'flexDaysCheckbox'}
                                name={'flexDays'}
                                type="checkbox"
                                className="nps-checkbox"
                                checked={flexDays}
                                readOnly
                            />
                            <label htmlFor={'flexDaysCheckbox'}></label>
                            <span>Flex.Days</span>
                        </div>
                        <div onClick={() => onFlexValuesChange(FlexValues.flexTimes, !flexTimes)} className="flex-times-checkbox">
                            <input
                                id={'flexTimesCheckbox'}
                                name={'flexTimes'}
                                type="checkbox"
                                className="nps-checkbox"
                                checked={flexTimes}
                                readOnly
                            />
                            <label htmlFor={'flexTimesCheckbox'}></label>
                            <span>Flex.Times</span>
                        </div>
                    </div>
                </div>
            </div>
        </FieldSet>
    );
};

export default WeekDays;
