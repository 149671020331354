import { StorageRepository } from './storage-repository';

export class StorageRepositoryVersion<T> extends StorageRepository<T> {
    protected versionObject: {
        [key: string]: string;
    };
    versionObjectName: string;

    constructor(name: string, storage: Storage, version: string, versionObjectName = 'storageVersion') {
        super(name, localStorage);
        this.versionObjectName = versionObjectName;

        this.loadVersions();
        this.presetVersions();
        if (this.versionObject?.[name] !== version) {
            this.versionObject[name] = version;
            this.updateVersions();
            this.reset();
        }
    }

    loadVersions() {
        const value = this.storage.getItem(this.versionObjectName);

        return (this.versionObject = value === undefined || value === 'undefined' ? undefined : JSON.parse(value));
    }

    presetVersions() {
        if (!this.versionObject) {
            this.storage.setItem(this.versionObjectName, JSON.stringify((this.versionObject = {})));
        }
    }

    updateVersions() {
        this.storage.setItem(this.versionObjectName, JSON.stringify(this.versionObject));
    }

    reset() {
        this.save(undefined);
    }
}
