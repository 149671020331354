import { GridOptions, GridReadyEvent, IServerSideGetRowsParams } from 'ag-grid-community';
import AgTable from 'common/components/ag-table/ag-table';
import HeaderLeftSide from 'common/components/header/header-left-side';
import HeaderWrapper from 'common/components/header/header-wrapper';
import { DisplayValueHelper } from 'common/helpers/display-value-helper';
import { useSearchInput } from 'common/hooks/use-serch-input';
import { IUser, IUserRoleModel } from 'common/services/api/user/types';
import userService from 'common/services/api/user/user-service';
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { IUsersListFilter, UserGridActions, UsersListProps } from './types';
import SearchFilterInput from 'common/components/header/search-filter-input/search-filter-input';
import HeaderRightSide from 'common/components/header/header-right-side';
import AddButton from 'common/components/header/add-button/add-button';
import DropdownOption from 'common/models/dropdown-option';
import InfiniteSelect from 'common/components/infinite-select/infinite-select';
import { useHasPermission } from 'common/hooks/use-has-permission';
import { PermissionType } from 'common/models/permission-type';
import { LocalTableRepository } from 'common/helpers/repository/local-table-repository';
import { LocalStoreRepository } from 'common/helpers/repository/local-store-repository';

const localStoreRepository = new LocalTableRepository('users-internal-list', 'v2');
const customFiltersRepository = new LocalStoreRepository('users-internal-list');
const defaultFilters: IUsersListFilter = {
    term: null,
};

export const getUsers = () => {
    return (event: IServerSideGetRowsParams, { pageIndex, limit, getCustomFilterParams }: any) => {
        const term: string = (getCustomFilterParams as IUsersListFilter).term;

        return userService.getAll(term, (pageIndex - 1) * limit, limit).then((users) => ({
            data: users,
        }));
    };
};

const handleFetchData = getUsers();

const UsersList: React.FC<UsersListProps> = ({ onGridReady, onEditUserClick, onNewUserClick, onResetPasswordClick }) => {
    const loadedFilters = useRef<IUsersListFilter>(customFiltersRepository.load());
    const [filter, setFilters] = useState<IUsersListFilter>(loadedFilters.current ?? defaultFilters);
    const hasCanUpdateInternalUsersPermission = useHasPermission(PermissionType.CanUpdateInternalUsers);

    const { search, handleSearchInputChange } = useSearchInput(loadedFilters.current?.term || '', (value: string) => {
        setFilters((prev) => ({ ...prev, term: value }));
    });

    useEffect(() => {
        customFiltersRepository.save(filter);
        loadedFilters.current = filter;
    }, [filter]);

    const handleClickOnAction = useCallback(
        (action: UserGridActions, user: IUser) => {
            switch (action) {
                case UserGridActions.Edit:
                    onEditUserClick(user);
                    break;
                case UserGridActions.ResetPassword:
                    onResetPasswordClick(user);
                    break;
            }
        },
        [onEditUserClick, onResetPasswordClick]
    );

    const gridOptions: GridOptions = useMemo(
        () => ({
            frameworkComponents: {
                doteRender: (props: any) => {
                    const options = [
                        new DropdownOption(UserGridActions.Edit, 'Edit'),
                        new DropdownOption(UserGridActions.ResetPassword, 'Reset password'),
                    ];

                    return (
                        options.length > 0 && (
                            <InfiniteSelect
                                id="users-actions-menu"
                                popper
                                items={options}
                                className="no-select-border"
                                icon={<i className="icon icon-options" />}
                                onChange={(item: DropdownOption) => {
                                    handleClickOnAction(item?.value, props.data);
                                }}
                            />
                        )
                    );
                },
            },
            columnDefs: [
                {
                    field: 'firstName',
                    headerName: 'First Name',
                    sortable: false,
                    width: 250,
                },
                {
                    field: 'lastName',
                    headerName: 'Last Name',
                    sortable: false,
                    width: 250,
                },
                {
                    field: 'email',
                    headerName: 'Email address',
                    sortable: false,
                    width: 300,
                },
                {
                    field: 'username',
                    headerName: 'Login',
                    sortable: false,
                    width: 250,
                },
                {
                    field: 'legacyId',
                    headerName: 'Legacy ID',
                    sortable: false,
                    width: 120,
                },
                {
                    field: 'lockoutEnabled',
                    headerName: 'Lockout Enabled',
                    valueFormatter: (props: any) => DisplayValueHelper.convertToYesOrNo(props.value),
                    sortable: false,
                    width: 177,
                },
                {
                    field: 'roles',
                    headerName: 'Roles',
                    valueFormatter: (props: any) => (props.value as IUserRoleModel[]).map((i) => i.name).join(', ') || '',
                    sortable: false,
                    width: 200,
                },
                {
                    field: 'type',
                    headerName: 'Type',
                    sortable: false,
                    width: 94,
                },
                {
                    headerName: '',
                    colId: 'doteMenu',
                    pinned: 'right',
                    cellRenderer: 'doteRender',
                    cellClass: ['dote-cell'],
                    width: 40,
                    sortable: false,
                    hideForExport: true,
                },
            ],
            defaultColDef: {},
        }),
        [handleClickOnAction]
    );

    const handleGridReady = (params: GridReadyEvent) => {
        if (onGridReady) {
            onGridReady(params);
        }
    };

    return (
        <>
            <div className="main-info-wrapper ag-table-wrapper">
                <HeaderWrapper>
                    <HeaderLeftSide>
                        <SearchFilterInput title="Users" value={search} onInput={handleSearchInputChange} />
                    </HeaderLeftSide>
                    <HeaderRightSide>
                        {hasCanUpdateInternalUsersPermission && <AddButton title="Add Internal User" onClick={onNewUserClick} />}
                    </HeaderRightSide>
                </HeaderWrapper>
                <AgTable
                    repository={localStoreRepository}
                    onGridReady={handleGridReady}
                    customFilters={filter}
                    gridOptions={gridOptions}
                    onFetchData={handleFetchData}
                />
            </div>
        </>
    );
};

export default UsersList;
