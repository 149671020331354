import React, { useEffect } from 'react';
import TabItem from './tab-item/tab-item';

const Tab = (props) => {
    const {
        text,
        id,
        onTabClick,
        iconName,
        isActive,
        isHide,
        onActive,
        onClick,
        tabIndex,
        tabRoute,
        linkClassName,
        titleRenderer,
        count,
    } = props;

    const handleOnTabClick = (event) => {
        event.preventDefault();
        onClick(tabIndex, tabRoute);

        if (onTabClick) {
            onTabClick(tabIndex, tabRoute);
        }

        if (onActive) {
            onActive();
        }
    };

    useEffect(() => {
        if (isActive) {
            if (onActive) {
                onActive();
            }
        }
    }, []);

    const label = !titleRenderer && count ? <TabItem count={count} label={text} /> : text;

    return (
        <li className={`tab ${isActive ? 'active' : ''} ${iconName ? 'has-icon' : ''}${isHide ? ' hide' : ''}`} onClick={handleOnTabClick}>
            <span className={`${iconName ? 'icon ' + iconName : ''}`}></span>
            <button id={id} className={`tab-link ${linkClassName}`}>
                {titleRenderer ? titleRenderer() : label}
            </button>
        </li>
    );
};

export default Tab;
