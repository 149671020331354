import { AxiosRequestConfig } from 'axios';
import { ExportType } from 'common/constants/export-options';
import { InfiniteServerClient } from 'common/helpers/http-clients';
import { ValidationHelper } from 'common/helpers/validation-helper';
import { IRejectRequest } from 'common/models/reject-request';
import { ICreateTherapistInfo } from 'features/create-therapist/types';
import { ITherapistDocumentOverrideInfo } from '../therapist-document/types';
import {
    ICreateEditTherapistAssignment,
    IDepartmentTherapistLink,
    IEditContactInfo,
    IEditGeneralInfo,
    IEditPreferences,
    IEmploymentOption,
    IInactiveReason,
    ITherapistDepartments,
    ITherapistDetailsModel,
    ITherapistOnMapModel,
} from './types';

export class TherapistService {
    getTherapistDetails(id: number): Promise<ITherapistDetailsModel> {
        return InfiniteServerClient.get(`/therapists/${id}/details`).then(
            (response) => {
                return response.data;
            },
            (error) => {
                console.log(error);
            }
        );
    }

    addTherapist(therapist: ICreateTherapistInfo): Promise<any> {
        const bodyFormData = new FormData();
        buildFormData(bodyFormData, therapist);
        const errorFormatter = (errors: any) => ({
            data: errors,
        });

        return (
            ValidationHelper.validateFile(bodyFormData, errorFormatter) ??
            InfiniteServerClient.post('/therapists', bodyFormData).then(
                (response) => {
                    return response.data;
                },
                (error) => {
                    throw error.response;
                }
            )
        );
    }

    assignUserToTherapist(therapistId: number, userId: number) {
        const body = {
            userId,
        };

        return InfiniteServerClient.put(`/therapists/${therapistId}/assigns`, body).then(
            (response) => {
                return response;
            },
            (error) => {
                throw error.response.data;
            }
        );
    }

    initiateStartProcess(therapistId: number) {
        return InfiniteServerClient.post(`/therapists/${therapistId}/profile/actions/start-process`).then(
            (response) => {
                return response;
            },
            (error) => {
                const errorData = error.response.data;
                throw errorData;
            }
        );
    }

    approveProfile(therapistId: number) {
        return InfiniteServerClient.post(`/therapists/${therapistId}/profile/actions/approve`).then(
            (response) => {
                return response;
            },
            (error) => {
                const errorData = error.response.data;
                throw errorData;
            }
        );
    }

    rejectProfile(therapistId: number, request: IRejectRequest) {
        return InfiniteServerClient.post(`/therapists/${therapistId}/profile/actions/reject`, request || {}).then(
            (response) => {
                return response;
            },
            (error) => {
                const errorData = error.response.data;
                throw errorData;
            }
        );
    }

    updateTherapistGeneralInfo(id: number, generalInfo: IEditGeneralInfo) {
        return InfiniteServerClient.put(`/therapists/${id}/general-info`, generalInfo).then(
            (response) => {
                return response;
            },
            (error) => {
                const errorData = error.response.data;
                throw errorData;
            }
        );
    }

    updateTherapistContactInfo(id: number, contactInfo: IEditContactInfo) {
        return InfiniteServerClient.put(`/therapists/${id}/contact-info`, contactInfo).then(
            (response) => {
                return response;
            },
            (error) => {
                const errorData = error.response.data;
                throw errorData;
            }
        );
    }

    updateTherapistPreferences(id: number, preferences: IEditPreferences) {
        return InfiniteServerClient.put(`/therapists/${id}/preferences`, preferences).then(
            (response) => {
                return response;
            },
            (error) => {
                const errorData = error.response.data;
                throw errorData;
            }
        );
    }

    updateTherapistProflePhoto(id: number, photo: File) {
        const bodyFormData = new FormData();
        bodyFormData.append('photo', photo);

        return (
            ValidationHelper.validateFile(bodyFormData) ??
            InfiniteServerClient.post(`/therapists/${id}/profile-photos`, bodyFormData).then(
                (response) => {
                    return response;
                },
                (error) => {
                    const errorData = error.response.data;
                    throw errorData;
                }
            )
        );
    }

    addAssignment(therapistId: number, assignment: ICreateEditTherapistAssignment) {
        return InfiniteServerClient.post(`/therapists/${therapistId}/assignments`, assignment).then(
            (response) => {
                return response;
            },
            (error) => {
                const errorData = error.response.data;
                throw errorData;
            }
        );
    }

    editAssignment(therapistId: number, assignment: ICreateEditTherapistAssignment) {
        return InfiniteServerClient.put(`/therapists/${therapistId}/assignments/${assignment.id}`, assignment).then(
            (response) => {
                return response.data;
            },
            (error) => {
                const errorData = error.response.data;
                throw errorData;
            }
        );
    }

    deactivateTherapist(therapistId: number, data: IInactiveReason) {
        return InfiniteServerClient.put(`/therapists/${therapistId}/deactivation`, data).then(
            (response) => {
                return response.data;
            },
            (error) => {
                throw error.response.data;
            }
        );
    }

    updateTherapistStatus(therapistId: number, status: string) {
        return InfiniteServerClient.put(`/therapists/${therapistId}/statuses`, { status }).then(
            (response) => {
                return response.data;
            },
            (error) => {
                throw error.response.data;
            }
        );
    }

    getDocuments(therapistId: number) {
        return InfiniteServerClient.get(`/therapists/${therapistId}/documents`).then(
            (response) => {
                return response.data;
            },
            (error) => {
                throw error.response.data;
            }
        );
    }

    overrideTherapistDocument(therapistId: number, therapistDocument: ITherapistDocumentOverrideInfo) {
        return InfiniteServerClient.post(`/therapists/${therapistId}/documents/${therapistDocument.id}/overrides`, therapistDocument).then(
            (response) => {
                const data = response.data;
                if (data) {
                    return data;
                }
            },
            (error) => {
                throw error.response.data;
            }
        );
    }

    linkDepartmentWithTherapist(
        departmentId: number,
        therapistId: number,
        options: IDepartmentTherapistLink
    ): Promise<ITherapistDepartments> {
        return InfiniteServerClient.put<ITherapistDepartments>(`/therapists/${therapistId}/departments/${departmentId}`, options).then(
            (response) => {
                return response.data;
            },
            (error) => {
                throw error;
            }
        );
    }

    getLinkedDepartments(therapistId: number): Promise<ITherapistDepartments[]> {
        return InfiniteServerClient.get<ITherapistDepartments[]>(`/therapists/${therapistId}/departments`).then(
            (response) => {
                return response.data;
            },
            (error) => {
                throw error;
            }
        );
    }

    getEmploymentStatusOptions(departmentId: number): Promise<IEmploymentOption[]> {
        return InfiniteServerClient.get<IEmploymentOption[]>(`/therapist-statuses/departments/${departmentId}`).then(
            (response) => {
                return response.data;
            },
            (error) => {
                throw error;
            }
        );
    }

    generateIdCardLetterPDF(therapistId: number): Promise<ArrayBuffer> {
        const config: AxiosRequestConfig = { responseType: 'arraybuffer' };

        return InfiniteServerClient.get<ArrayBuffer>(`/therapists/${therapistId}/pdf/id-card-letter`, config).then(
            (response) => {
                return response.data;
            },
            (error) => {
                throw error;
            }
        );
    }

    exportExpiredDocuments(type: ExportType, therapstId?: number): Promise<Blob> {
        return InfiniteServerClient.get<Blob>(`/therapists/documents/exports/${type}?therapistId=${therapstId}`, {
            responseType: 'blob',
        }).then(
            (response) => {
                return response.data;
            },
            (error) => {
                throw error;
            }
        );
    }

    getTherapistForMap(id: number): Promise<ITherapistOnMapModel> {
        return InfiniteServerClient.get<ITherapistOnMapModel>(`/therapists/${id}/map`).then(
            (response) => {
                return response.data;
            },
            (error) => {
                throw error;
            }
        );
    }
}

function buildFormData(formData: any, data: { [x: string]: any }, parentKey?: string) {
    if (data && typeof data === 'object' && !(data instanceof Date) && !(data instanceof File)) {
        Object.keys(data).forEach((key) => {
            buildFormData(formData, data[key], parentKey ? `${parentKey}[${key}]` : key);
        });
    } else {
        const value = data == null ? '' : data;

        if (value) {
            formData.append(parentKey, value);
        }
    }
}

export default new TherapistService();
