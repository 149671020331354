import Breadcrumb from 'common/components/Breadcrumb/breadcrumb';
import TooltipButton from 'common/components/reopened-button/tooltip-button';
import Tab from 'common/components/tabs/tab';
import Tabs from 'common/components/tabs/tabs';

import RequestService from 'common/services/api/requests/request-service';
import { IRequestDetails, IRequestSetting, RequestStatus } from 'common/services/api/requests/types';
import React, { useCallback, useEffect, useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import RequestGeneralInfoTab from './general-info-tab/request-general-info-tab';
import './request-details.scss';
import RequestHeaderInfo from './request-header-info/request-header-info';
import { IRequestParamType } from './types';
import { IRequestDeclineReason, IRequestDetailsContext, IRequestReopenReason } from '../request-forms/types';
import RequestDeclineSidebar from '../request-forms/request-decline-sidebar';
import RequestReopenSidebar from '../request-forms/request-reopen-sidebar';
import moment from 'moment';
import { trackPromise } from 'react-promise-tracker';
import { useIsUserType } from 'common/hooks/use-is-user-type';
import { departmentKey } from 'common/constants/department-key';
import { NavigationRoutes } from 'models/routes/navigation-routes';
import ServerNavigation from 'common/components/details-navigation/server-navigation';
import { useSelector } from 'react-redux';
import { IToggleFeatureList } from 'common/services/api/settings/types';

export const RequestDetailsContext = React.createContext<IRequestDetailsContext>(null);

const RequestDetailsPage = () => {
    const [requestDetails, setRequestDetails] = useState<IRequestDetails>();
    const [isDeclineBarOpen, toggleDeclineBar] = useState(false);
    const [isReopenBarOpen, toggleReopenBar] = useState(false);
    const [reopenDisable, setReopenDisable] = useState(true);
    const { reason = null, reasonComment = null, lastDeclineDate = null } = requestDetails || {};
    const { isExternal } = useIsUserType();

    const items = [
        { to: '/requests', label: 'Requests' },
        { to: '', label: 'Request Details' },
    ];

    const params = useParams<IRequestParamType>();
    const requestId = Number.parseInt(params?.requestId);

    const featureList: IToggleFeatureList = useSelector((state: any) => state.siteSettings?.settings?.features || {});

    const updateDetailsPage = useCallback(
        () => trackPromise(RequestService.getDetails(requestId)).then((detailsData) => setRequestDetails(detailsData)),
        [requestId]
    );

    useEffect(() => {
        if ((!requestDetails && requestId) || requestDetails?.id !== requestId) {
            updateDetailsPage();
        }
    }, [requestDetails, requestId, updateDetailsPage]);

    const handleSeeActivityButton = () => {
        window.open(`/logging?object=Requests&objectId=${requestId}`, '_blank').focus();
    };

    // Decline
    const handleClickOnDecline = () => {
        toggleDeclineBar(true);
    };

    const handleClickDeclineClose = () => {
        toggleDeclineBar(false);
    };

    const handleClickAcceptRequest = () => {
        if (!requestDetails.hasTherapists) {
            return;
        }

        return trackPromise(RequestService.setAcceptStatus(requestId)).then(() => {
            setRequestDetails(null);
        });
    };

    const handleClickSaveDecline = (requestReason: IRequestDeclineReason) => {
        return trackPromise(RequestService.setDeclineStatus(requestId, requestReason)).then(() => {
            toggleDeclineBar(false);
            setRequestDetails(null);
        });
    };

    const handleAssignUser = (userId: string) => {
        RequestService.assignToUser(requestId, userId).then(() => {
            updateDetailsPage();
        });
    };

    // Reopen
    const handleClickOnReopen = () => {
        toggleReopenBar(true);
    };

    const handleClickReopenClose = () => {
        toggleReopenBar(false);
    };

    const handleClickSaveReopen = (requestReason: IRequestReopenReason) => {
        return trackPromise(RequestService.setReopenStatus(requestId, requestReason)).then(() => {
            toggleReopenBar(false);
            setRequestDetails(null);
        });
    };

    const isHold = () => requestDetails?.status === RequestStatus.Hold;

    const isAcceptable = () =>
        !isHold() &&
        requestDetails?.department?.key === departmentKey.HCA &&
        (requestDetails?.status === RequestStatus.New ||
            requestDetails?.status === RequestStatus.InProgress ||
            requestDetails?.status === RequestStatus.Assigned);

    const isDeclinable = () => !isHold() && requestDetails?.status !== RequestStatus.Complete;
    const isDeclined = () => !isHold() && requestDetails?.status === RequestStatus.Declined;
    const isReopened = () => !isHold() && requestDetails?.status === RequestStatus.New && requestDetails?.reason?.type === 'ReopenRequest';

    useEffect(() => {
        if (isDeclined()) {
            RequestService.getSettings().then((res: IRequestSetting) => {
                const declinedDate = moment(lastDeclineDate).startOf('day');
                const currentDate = moment().utc().startOf('day');
                const diff = currentDate.diff(declinedDate, 'days');
                const daysForReopen = res.setting.daysForReopenDeclinedRequest;
                setReopenDisable(diff > daysForReopen || daysForReopen === 0);
            });
        }
    }, [requestDetails?.status]);

    return (
        <RequestDetailsContext.Provider value={{ setRequestDetails, updateDetailsPage }}>
            <div className="details request">
                <header>
                    {featureList.isDetailsNavigationEnabled && (
                        <ServerNavigation
                            entityApiBaseUrl="requests"
                            entityId={requestId}
                            navigationRoute={NavigationRoutes.requestDetailsRoute}
                        />
                    )}
                    <div className="header-mock">
                        <Breadcrumb>
                            {items.map(({ to, label }) => (
                                <Link key={to} to={to}>
                                    {label}
                                </Link>
                            ))}
                        </Breadcrumb>
                        <div className="button-block">
                            {!isExternal && isDeclined() && !reopenDisable && (
                                <TooltipButton
                                    id="reopenRequestBtn"
                                    tooltipText="This is the reason specified when user has reopened"
                                    disabled={reopenDisable}
                                    onClick={handleClickOnReopen}
                                >
                                    Reopen
                                </TooltipButton>
                            )}
                            {!isExternal && isReopened() && (
                                <TooltipButton
                                    id="reopenRequestBtn"
                                    tooltipText={reasonComment || reason?.name}
                                    disabled={isReopened()}
                                    onClick={handleClickOnReopen}
                                >
                                    Reopened
                                </TooltipButton>
                            )}
                            {!isExternal && isAcceptable() && (
                                <TooltipButton
                                    id="acceptRequestBtn"
                                    visible={!requestDetails.hasTherapists}
                                    disabled={!requestDetails.hasTherapists}
                                    tooltipText="A therapist must be selected"
                                    onClick={handleClickAcceptRequest}
                                    className="blue ml-5"
                                >
                                    Accept
                                </TooltipButton>
                            )}
                            {!isExternal && (isDeclinable() || isDeclined()) && (
                                <TooltipButton
                                    id="declineRequestBtn"
                                    disabled={isDeclined()}
                                    tooltipText={reasonComment || reason?.name}
                                    onClick={handleClickOnDecline}
                                >
                                    {isDeclined() ? 'Declined' : 'Decline'}
                                </TooltipButton>
                            )}
                        </div>
                    </div>
                    {requestDetails && (
                        <RequestHeaderInfo
                            details={requestDetails}
                            handleSeeActivityButton={handleSeeActivityButton}
                            onAssignUser={handleAssignUser}
                        />
                    )}
                </header>
                <Tabs orientation="horizontal">
                    <Tab linkClassName={'custom-link'} text="General Info">
                        <RequestGeneralInfoTab requestId={requestId} requestDetails={requestDetails} />
                    </Tab>
                </Tabs>
                {isDeclineBarOpen && <RequestDeclineSidebar onClose={handleClickDeclineClose} onSave={handleClickSaveDecline} />}
                {isReopenBarOpen && <RequestReopenSidebar onClose={handleClickReopenClose} onSave={handleClickSaveReopen} />}
            </div>
        </RequestDetailsContext.Provider>
    );
};

export default RequestDetailsPage;
