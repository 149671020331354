import FieldSet from 'common/components/field-set/field-set';
import NumberField from 'common/components/field/number-field';
import InfiniteSelect from 'common/components/infinite-select/infinite-select';
import DropdownOption from 'common/models/dropdown-option';
import { FieldValue } from 'common/models/field-value';
import { TherapistFilterGroup } from 'common/components/map/therapist-filter-group/therapist-filter-group';
import TherapistMapFilter from 'common/components/map/therapist-map-filter/therapist-map-filter';
import React from 'react';
import { TherapistAvailabilityMapFilterProps } from './types';
import { RateLevelSelect } from 'common/components/infinite-select/custom-selects/rate-level-select/rate-level-select';
import { ITherapistMapFilter } from 'common/services/api/map/types';
import { getWeekOptions } from 'common/components/availability/types';

const rateLevels = [new DropdownOption(1, 1, 'one'), new DropdownOption(2, 2, 'two'), new DropdownOption(3, 3, 'three')];

const TherapistAvailabilityMapFilter: React.FC<TherapistAvailabilityMapFilterProps> = ({ filter, onChange }) => {
    const weekOptions = getWeekOptions();

    const handleTherapistFilterChange = (mapFilter: ITherapistMapFilter) => {
        onChange({ ...filter, ...mapFilter });
    };

    const handleAvailabilityChange = (availability?: FieldValue) => {
        onChange({ ...filter, availability: availability.value });
    };

    const handleScheduledChange = (scheduled: FieldValue) => {
        onChange({ ...filter, scheduled: scheduled.value });
    };

    const handleWeekChange = (weekValue: DropdownOption) => {
        onChange({ ...filter, week: weekValue.value });
    };

    const handleRateLevelChange = (value: DropdownOption[]) => {
        onChange({ ...filter, rateLevels: value.map((i) => i.value) });
    };

    return (
        <>
            <TherapistMapFilter filter={filter} onChange={handleTherapistFilterChange} />
            <TherapistFilterGroup title="Availability Related">
                <NumberField
                    id="availability"
                    placeholder="Availability"
                    onChange={handleAvailabilityChange}
                    value={filter?.availability}
                />
                <NumberField id="scheduled" placeholder="Scheduled" onChange={handleScheduledChange} value={filter?.scheduled} />
                <FieldSet name="rateLevel">
                    <RateLevelSelect
                        items={rateLevels}
                        value={rateLevels.filter((item) => (filter?.rateLevels || []).some((rate) => rate === item.value))}
                        onChange={handleRateLevelChange}
                    />
                </FieldSet>
                <FieldSet name="week" customClass="available-therapist-header-select week">
                    <InfiniteSelect
                        id="week"
                        items={weekOptions}
                        label="Week"
                        value={weekOptions.find((item) => filter?.week === item.value)}
                        onChange={handleWeekChange}
                        iconClass="icon-calendar"
                    />
                </FieldSet>
            </TherapistFilterGroup>
        </>
    );
};

export default React.memo(TherapistAvailabilityMapFilter);
