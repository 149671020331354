import FieldSet from 'common/components/field-set/field-set';
import RightSideBar from 'common/components/right-side-bar/right-side-bar';
import DropdownOption from 'common/models/dropdown-option';
import React, { useState } from 'react';
import InfiniteSelect from 'common/components/infinite-select/infinite-select';
import TextAreaField from 'common/components/field/text-area-field';
import { FieldValue } from 'common/models/field-value';
import ChipInput from 'common/components/field/multi-email-field/chip-input';
import { INewEmailModel } from 'common/services/api/communication/types';
import { AuthErrorCodes } from 'common/helpers/ms-auth/types';
import { msAuth } from 'common/helpers/ms-auth/ms-auth';
import { toast } from 'react-toastify';
import { IMSGraphSettings } from 'common/services/api/settings/types';
import { useSelector } from 'react-redux';

type NewEmailProps = {
    personalEmail: string;
    internalEmail: string;
    onCancel: () => void;
    onSave: (email: INewEmailModel) => Promise<any>;
};

// TO-DO: Set tab indexes on the form
const NewEmailDialog = ({ personalEmail, internalEmail, onCancel, onSave }: NewEmailProps) => {
    const emailPattern = new RegExp(/[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,15}/g);

    const msSettings = useSelector<any, IMSGraphSettings>((state: any) => state?.siteSettings?.settings?.microsoftGraph);

    const handleFieldChange = (data: FieldValue) => {
        setEmail((prevEmail) => ({ ...(prevEmail as INewEmailModel), [data.name]: data.value }));
        setErrorData((prevError) => ({ ...prevError, [data.name]: undefined }));
    };

    const getEmailOptions = () => {
        const emailOptions: Array<DropdownOption> = [];

        if (personalEmail) {
            emailOptions.push(new DropdownOption(personalEmail, `${personalEmail} (personal)`));
        }

        if (internalEmail) {
            emailOptions.push(new DropdownOption(internalEmail, `${internalEmail} (internal)`));
        }

        return emailOptions;
    };

    const handleClickOnSave = () => {
        onSave(email).catch((error) => {
            if (error?.response?.data?.errorCode === AuthErrorCodes.InvalidMSAuthCode) {
                msAuth.loginPopup({ ...msSettings }).then(() => {
                    onSave(email).catch(() => {
                        if (error?.response?.data?.errorCode === AuthErrorCodes.InvalidMSAuthCode) {
                            toast.error('Please login into your Microsoft account for sending an email');
                        }
                    });
                });
            }

            if (error.response.status === 400) {
                setErrorData(error.response.data);
            }
        });
    };

    const emailOptions: Array<DropdownOption> = getEmailOptions();
    const [email, setEmail] = useState<INewEmailModel>({ to: emailOptions.length > 0 ? [emailOptions[0].value] : [] });
    const [errorData, setErrorData] = useState({});

    return (
        <div className="therapist-communications-email-new-email">
            <RightSideBar saveText="Send" title="New Email" onCancel={onCancel} onSave={handleClickOnSave}>
                <div className="edit-therapist-form">
                    <div className="edit-therapist-form-therapist-email">
                        <FieldSet errors={errorData} name="to" customClass="edit-therapist-field">
                            <InfiniteSelect
                                items={emailOptions}
                                id="toTherapist"
                                multiselect={true}
                                name="to"
                                label="To"
                                value={emailOptions.filter((item) => email?.to?.some((id) => id === item.value))}
                                onChange={(item: any) => handleFieldChange({ name: 'to', value: item.map((i: DropdownOption) => i.value) })}
                            />
                        </FieldSet>
                    </div>
                    <div className="edit-therapist-form-title">
                        <FieldSet name="subject" errors={errorData} customClass="edit-therapist-field">
                            <TextAreaField
                                id="subject"
                                placeholder="Title"
                                name="subject"
                                onChange={handleFieldChange}
                                value={email.subject}
                            ></TextAreaField>
                        </FieldSet>
                    </div>
                    <div className="edit-therapist-form-message">
                        <FieldSet name="message" errors={errorData} customClass="edit-therapist-field">
                            <TextAreaField
                                id="message"
                                placeholder="Message"
                                name="message"
                                onChange={handleFieldChange}
                                value={email.message}
                            ></TextAreaField>
                        </FieldSet>
                    </div>
                    <div className="more-options">
                        <strong>More Options</strong>
                        <br></br>
                        <div className="more-options-emails-cc-bcc">
                            <FieldSet errors={errorData} name="cc" customClass="edit-therapist-field">
                                <ChipInput
                                    chips={email.cc || []}
                                    onChange={handleFieldChange}
                                    name="cc"
                                    id="cc"
                                    pattern={emailPattern}
                                    placeholder="CC"
                                    customClass="cc-email"
                                ></ChipInput>
                            </FieldSet>
                            <FieldSet errors={errorData} name="bcc" customClass="edit-therapist-field">
                                <ChipInput
                                    chips={email.bcc || []}
                                    onChange={handleFieldChange}
                                    name="bcc"
                                    id="bcc"
                                    pattern={emailPattern}
                                    placeholder="BCC"
                                    customClass="bcc-email"
                                ></ChipInput>
                            </FieldSet>
                        </div>
                    </div>
                </div>
            </RightSideBar>
            <div className="right-sidebar-overlay"></div>
        </div>
    );
};

export default NewEmailDialog;
