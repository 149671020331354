import SimilarDialog from 'common/components/similar-dialog/similar-dialog';
import { HttpStatusCode } from 'common/constants/http-status-codes';
import { IDuplicateTherapistError } from 'common/models/duplicate-therapist-error';
import leadService from 'common/services/api/lead/lead-service';
import { NavigationRoutes } from 'models/routes/navigation-routes';
import React, { useCallback, useState } from 'react';
import { useHistory } from 'react-router';
import { toast } from 'react-toastify';
import PageHeader from 'common/components/page-header/page-header';
import { ILead, LeadStatus } from 'common/services/api/lead/types';
import RejectDialog from 'common/components/reject-dialog/reject-dialog';
import { IRejectRequest } from 'common/models/reject-request';
import { ReasonType } from 'common/services/api/reason/types';
import LeadsManagementTable from './components/leads-list/leads-management-table';
import ConfirmPopup from 'common/components/inifinite-popups/confirm-popup';
import Popper from 'common/components/infinite-popper/infinite-popper';

export interface IDuplicateTherapistDialogOptions {
    data: IDuplicateTherapistError;
    leadId: number;
    triggerOperation: 'Verification' | 'Open Details';
    detailsNavigationState?: any;
}

const LeadsPage = () => {
    const history = useHistory();

    const [updateTable, setUpdateTable] = useState(false);
    const [duplicateData, setDuplicateData] = useState<IDuplicateTherapistDialogOptions | null>(null);
    const [refusalLead, setRefusalLead] = useState<ILead | null>(null);

    const [verifyTarget, setVerifyTarget] = useState(null);
    const [refuseTarget, setRefuseTarget] = useState(null);

    const [currentLead, setCurrentLead] = useState<ILead | null>(null);

    const handleVerifyClick = (lead: ILead, target: any) => {
        setCurrentLead(lead);
        setVerifyTarget(target);
    };

    const handleRefuseLeadClick = (lead: ILead, target: any) => {
        setCurrentLead(lead);
        setRefuseTarget(target);
    };

    const handleVerifyConfirm = () => {
        setVerifyTarget(null);
        verifyLead(currentLead.id);
    };

    const handleRefuseConfirm = () => {
        setRefuseTarget(null);
        setRefusalLead(currentLead);
    };

    const handleClickOnCancelDuplicate = () => {
        setDuplicateData(null);
    };

    const handleClickSaveFromDuplicateDialog = () => {
        if (duplicateData.triggerOperation === 'Verification') {
            verifyLead(duplicateData.leadId, true);
        } else {
            openDetailsPage(duplicateData.leadId, duplicateData.detailsNavigationState);
        }
    };

    const verifyLead = (leadId: number, verifyAnyway: boolean = false) => {
        leadService
            .verify(leadId, verifyAnyway)
            .then((data) => {
                setDuplicateData(null);
                history.push({ pathname: NavigationRoutes.therapistDetailsRoute(data.therapistId) });
            })
            .catch((error: any) => handleError(error, leadId));
    };

    const refuseLead = (request: IRejectRequest) => {
        return leadService.refuse(refusalLead.id, request).then(() => {
            toast.success('Lead has been successfully refused');
            setUpdateTable(true);
            setRefusalLead(null);
            setUpdateTable(false);
        });
    };

    const handleOpenClick = (lead: ILead, detailsNavigationState: any) => {
        if (lead.status === LeadStatus.Verified || lead.status === LeadStatus.Refused) {
            openDetailsPage(lead.id, detailsNavigationState);

            return;
        }

        leadService.getSimilarTherapists(lead.id).then((data) => {
            if (data.existedEntities?.length > 0) {
                setDuplicateData({ data: data, triggerOperation: 'Open Details', leadId: lead.id, detailsNavigationState });
            } else {
                openDetailsPage(lead.id, detailsNavigationState);
            }
        });
    };

    const openDetailsPage = (leadId: number, detailsNavigationState: any) => {
        history.push({ pathname: NavigationRoutes.leadDetaisPage(leadId) }, detailsNavigationState);
    };

    const handleError = (error: any, leadId: number) => {
        switch (error.response.status) {
            case HttpStatusCode.CONFLICT: {
                const data = error.response.data.details as IDuplicateTherapistError;
                setDuplicateData({ data: data, triggerOperation: 'Verification', leadId: leadId });
                break;
            }
            default:
                break;
        }
    };

    const generalRefusalDialogTitle = useCallback(() => {
        const name = refusalLead.middleName
            ? `${refusalLead.firstName} ${refusalLead.middleName} ${refusalLead.lastName}`
            : `${refusalLead.firstName} ${refusalLead.lastName}`;

        return `Refuse ${name}`;
    }, [refusalLead]);

    const handleCloseRefuseDialog = () => {
        setRefusalLead(null);
    };

    const resetConfirmationDialog = () => {
        setVerifyTarget(null);
        setRefuseTarget(null);
        setCurrentLead(null);
    };

    const renderConfirmationPopup = useCallback((text: string, target: any, onConfirm: () => void) => {
        return (
            <Popper target={target} width={271} onClickOutside={resetConfirmationDialog}>
                <ConfirmPopup onCancel={resetConfirmationDialog} onConfirm={onConfirm}>
                    {text}
                </ConfirmPopup>
            </Popper>
        );
    }, []);

    return (
        <div className="leads-management-page">
            <PageHeader title="Leads" />

            <LeadsManagementTable
                onOpenClick={handleOpenClick}
                onRefuseClick={handleRefuseLeadClick}
                onVerifyClick={handleVerifyClick}
                updateTable={updateTable}
            />

            {duplicateData && (
                <div className="similar-leads-dialog-wrapper">
                    <SimilarDialog
                        onCancel={handleClickOnCancelDuplicate}
                        onSave={handleClickSaveFromDuplicateDialog}
                        transformer={handleTransformation}
                        existedEntities={duplicateData.data.existedEntities}
                        allowSave={duplicateData.data.allowSave}
                        saveButtonText={duplicateData.triggerOperation === 'Verification' ? 'Verify Anyway' : 'Continue'}
                    ></SimilarDialog>
                </div>
            )}
            {refusalLead && (
                <RejectDialog
                    title={generalRefusalDialogTitle()}
                    onCancel={handleCloseRefuseDialog}
                    onSave={refuseLead}
                    reasonType={ReasonType.RefuseLead}
                />
            )}
            {verifyTarget && renderConfirmationPopup('Are you sure you want to verify?', verifyTarget, handleVerifyConfirm)}
            {refuseTarget && renderConfirmationPopup('Are you sure you want to refuse?', refuseTarget, handleRefuseConfirm)}
        </div>
    );
};

const handleTransformation = (therapist: any) => ({
    title: therapist.fullName,
    body: therapist.email,
    url: NavigationRoutes.therapistDetailsRoute(therapist.id),
});

export default LeadsPage;
