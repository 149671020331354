import React from 'react';
import { TherapistFilterGroupProps } from './types';

export const TherapistFilterGroup: React.FC<TherapistFilterGroupProps> = ({ title, children }) => {
    return (
        <div className="therapist-filter-group">
            <header>{title}</header>
            <div className="body">{children}</div>
        </div>
    );
};

export default React.memo(TherapistFilterGroup);
