import React from 'react';
import NoteList from 'common/components/note-list/note-list';
import { useState } from 'react';
import noteService from 'common/services/api/note/note-service';
import { useDispatch } from 'react-redux';
import { refreshNoteStatus } from 'app/store/notes/action-creators';
import { EntityNoteType, INote } from 'common/models/note';
import { NavigationRoutes } from 'models/routes/navigation-routes';
import { useHistory } from 'react-router-dom';

import './_styles.scss';

const NotesPage: React.FC = () => {
    const dispach = useDispatch();
    const history = useHistory();

    const [forceUpdateTable, setForceUpdateTable] = useState<boolean>(false);

    const handleClickOnCompleteNote = (id: number) => {
        noteService.complete(id).then(() => {
            setForceUpdateTable(true);
            setForceUpdateTable(false);
            dispach(refreshNoteStatus());
        });
    };

    const handleClickOnNote = (note: INote) => {
        if (!note.entity) {
            throw new Error('Entity for note is not specified.');
        }

        switch (note.entity.type) {
            case EntityNoteType.Therapist:
                history.push({ pathname: NavigationRoutes.therapistDetailsRoute(note.entity.id) });
                break;
            case EntityNoteType.Patient:
                history.push({ pathname: NavigationRoutes.patientDetailsRoute(note.entity.id) });
                break;
            case EntityNoteType.Request:
                history.push({ pathname: NavigationRoutes.requestDetailsRoute(note.entity.id) });
                break;
            case EntityNoteType.NursingHomeRequest:
                history.push({ pathname: NavigationRoutes.nursinghomerequestDetailsRoute(note.entity.id) });
                break;
            case EntityNoteType.LegalEntity:
                history.push({ pathname: NavigationRoutes.entityDetailsRoute(note.entity.id) });
                break;
            case EntityNoteType.Lead:
                history.push({ pathname: NavigationRoutes.leadDetaisPage(note.entity.id) });
                break;
            default:
                throw new Error('Invalid entity type.');
        }
    };

    return (
        <div className="notes-page table-page">
            <header>
                <div className="header-wrapper">
                    <div className="title-wrapper">
                        <h1>Notes</h1>
                    </div>
                </div>
            </header>
            <NoteList
                url={`notes`}
                onNoteClick={handleClickOnNote}
                onCompleteNoteClick={handleClickOnCompleteNote}
                forceUpdate={forceUpdateTable}
            />
        </div>
    );
};

export default NotesPage;
