import React from 'react';
import { IDropdownOption } from './types';

const DropdownOption = ({ asCheckbox, item, onClick, checked, onRenderOption }: IDropdownOption) => {
    return (
        <span className="option-item" onClick={() => onClick(item)}>
            {asCheckbox && <input type="checkbox" className="nps-checkbox" id={item.label} checked={checked} readOnly />}
            <label htmlFor={item.label}>{onRenderOption ? onRenderOption(item) : <span>{item.label}</span>}</label>
        </span>
    );
};

export default DropdownOption;
