import { getScale } from '../zoom/zoom';

export const customOffsetModifier = {
    name: 'offset',
    options: {
        offset: (props: any) => {
            const { placement, reference, popper } = props;
            if (getScale() !== 1 && placement.indexOf('bottom') !== -1) {
                if (reference.x + popper.width > document.body.clientWidth) {
                    return [-(document.body.clientWidth - window.innerWidth), 0];
                } else {
                    if (placement === 'bottom') {
                        return [0, 0];
                    }
                    if (placement === 'bottom-end') {
                        return [-(document.body.clientWidth - window.innerWidth), 0];
                    }
                    if (placement === 'bottom-start') {
                        return [0, 0];
                    }

                    return [window.innerHeight - document.body.clientHeight, 0];
                }
            } else if (getScale() !== 1 && placement.indexOf('top') !== -1) {
                if (placement === 'top') {
                    return [0, -(window.innerHeight - document.body.clientHeight)];
                }
                if (placement === 'top-end') {
                    return [-(document.body.clientWidth - window.innerWidth), -(window.innerHeight - document.body.clientHeight)];
                }
                if (placement === 'top-start') {
                    return [0, -(window.innerHeight - document.body.clientHeight)];
                }

                return [-(document.body.clientWidth - window.innerWidth), -(window.innerHeight - document.body.clientHeight)];
            } else {
                return [0, 0];
            }
        },
    },
};
