import { IFilterComp } from 'ag-grid-community';
import React, { useCallback, useEffect, useState } from 'react';
import DropdownOption from '../../../models/dropdown-option';
import { ICustomFilerModel, MultipleFilterAutocompleteProps } from './types';
import PaginationAutocomplete from '../../field/pagination-autocomplete/pagination-autocomplete';
import './multiple-filter-autocomplete.scss';

const blankOption = '(blank)';

const MultipleFilterAutocomplete = ({
    id = '',
    colId,
    label,
    gridApi,
    onFetchFilters,
    className,
    isNullable,
}: MultipleFilterAutocompleteProps) => {
    const [selectedValues, setSelectedValues] = useState([]);

    const setFilter = useCallback(
        (name: string, filerModel: ICustomFilerModel) => {
            gridApi.getFilterInstance(name, (filterApi: IFilterComp) => {
                filterApi.setModel({
                    ...filterApi.getModel(),
                    isNullable: isNullable && filerModel?.values?.some((value) => value === blankOption),
                    values: filerModel.values.filter((model) => model !== blankOption),
                });
            });
            gridApi.onFilterChanged();
        },
        [gridApi, isNullable]
    );

    useEffect(() => {
        if (!gridApi) {
            return;
        }

        const handleFilterChange = (e: any) => {
            e.api.getFilterInstance(colId, (filterApi: IFilterComp) => {
                if (filterApi.getModel()?.values) {
                    if (isNullable && filterApi.getModel()?.isNullable) {
                        setSelectedValues([blankOption, ...filterApi.getModel().values]);
                    } else {
                        setSelectedValues(filterApi.getModel().values);
                    }
                } else {
                    setSelectedValues([]);
                }
            });
        };

        gridApi.addEventListener('filterChanged', handleFilterChange);

        return () => {
            gridApi.removeEventListener('filterChanged', handleFilterChange);
        };
    }, [gridApi, colId, isNullable]);

    const handleFilterFetch = (term?: string, skip?: number, _take?: number) => {
        return onFetchFilters({
            pageIndex: skip / 20 + 1,
            limit: 20,
            search: term,
            column: colId,
        }).then((res: any) => {
            return res.data;
        });
    };

    return (
        <PaginationAutocomplete
            id={id}
            name={colId}
            placeholder={label}
            tabIndex={null}
            defaultValue={selectedValues.map((item) => new DropdownOption(item, item))}
            onDelete={null}
            onSelect={(options: DropdownOption[]) => {
                setFilter(colId, { values: options.map((i: DropdownOption) => i.value) });
            }}
            defaultSelectedLabel={'All ' + label}
            convertFunction={(item: any) => new DropdownOption(item, item)}
            fetchData={handleFilterFetch}
            className={`autocomplete-filer ${className ?? ''}`}
            showSelectedCounter
            multiselect
            cleanable
            selectDebounceMs={600}
            staicItems={isNullable ? [new DropdownOption(blankOption, blankOption)] : []}
        ></PaginationAutocomplete>
    );
};

export default React.memo(MultipleFilterAutocomplete);
