import { AxiosRequestConfig } from 'axios';
import { InfiniteServerClient } from 'common/helpers/http-clients';
import {
    ICommunicationSearchResult,
    INewEmailModel,
    IAddEditInterviewModel,
    INewSmsModel,
    InterviewStatus,
    ISetStatusRequest,
} from './types';

export class CommunicationService {
    sendEmail(therapistId: number, email: INewEmailModel): Promise<void> {
        return InfiniteServerClient.post(`/therapists/${therapistId}/communication/emails`, email).then(
            (response) => {
                if (response && response.data) {
                    return response.data;
                }

                return null;
            },
            (error) => {
                throw error;
            }
        );
    }

    sendSms(therapistId: number, sms: INewSmsModel): Promise<void> {
        return InfiniteServerClient.post(`/therapists/${therapistId}/communication/sms`, sms).then(
            (response) => {
                if (response && response.data) {
                    return response.data;
                }

                return null;
            },
            (error) => {
                throw error;
            }
        );
    }

    createInterview(therapistId: number, interview: IAddEditInterviewModel): Promise<void> {
        return InfiniteServerClient.post(`/therapists/${therapistId}/interviews`, interview).then(
            (response) => {
                const data = response.data;
                if (data) {
                    return data;
                }
            },
            (error) => {
                throw error;
            }
        );
    }

    updateInterview(id: number, therapistId: number, interview: IAddEditInterviewModel): Promise<void> {
        return InfiniteServerClient.put(`/therapists/${therapistId}/interviews/${id}`, interview).then(
            (response) => {
                const data = response.data;
                if (data) {
                    return data;
                }
            },
            (error) => {
                throw error;
            }
        );
    }

    setStatus(id: number, therapistId: number, status: InterviewStatus): Promise<void> {
        const request: ISetStatusRequest = { status: status };

        return InfiniteServerClient.patch(`/therapists/${therapistId}/interviews/${id}/status`, request).then(
            (response) => {
                const data = response.data;
                if (data) {
                    return data;
                }
            },
            (error) => {
                throw error;
            }
        );
    }

    search(therapistId: number, term: string, pageIndex: number, limit: number): Promise<ICommunicationSearchResult> {
        const url = `/therapists/${therapistId}/communication/actions/search`;
        const config: AxiosRequestConfig = {
            params: { pageIndex, limit, term },
        };

        return InfiniteServerClient.get(url, config).then(
            (response) => {
                const data = response.data;
                if (data) {
                    return data;
                }
            },
            (error) => {
                throw error;
            }
        );
    }
}

export default new CommunicationService();
