import { IFormItem } from 'common/models/types';
import { ILegalEntityRate } from 'common/services/api/rate/types';
import { IRateTemplate } from '../types';

export type RateCreateFormProps = {
    rateTemplate: IRateTemplate;
    onClose?: () => void;
    onSave?: (rate: ILegalEntityRate) => Promise<void>;
};

export type RateEditFormProps = {
    rateTemplate: IRateTemplate;
    rateData: ILegalEntityRate;
    onClose?: () => void;
    onSave?: (rate: ILegalEntityRate) => Promise<void>;
};

export type RateChangeFormProps = {
    rateTemplate: IRateTemplate;
    rateData: ILegalEntityRate;
    onClose?: () => void;
    onSave?: (rate: ILegalEntityRate, newRate: ILegalEntityRate) => Promise<void>;
};

export type RateBaseFormProps = {
    rate?: ILegalEntityRate;
    errors?: any;
    onChange: (data: IFormItem) => void;
};

export type RateTemplateTypesProps = {
    rateTemplate: IRateTemplate;
};

export enum RateErrorCodes {
    AddRateActiveConstraintFailed = 'ADD_CONFLICT_WITH_CURRENT_ACTIVE_RATE',
    AddRateActiveFutureConstraintFailed = 'ADD_CONFLICT_WITH_FUTURE_ACTIVE_RATE',
    EditRateActiveConstraintFailed = 'EDIT_CONFLICT_WITH_CURRENT_ACTIVE_RATE',
    EditRateActiveFutureConstraintFailed = 'EDIT_CONFLICT_WITH_FUTURE_ACTIVE_RATE',
    AddtRatePastConstraintFailed = 'ADD_CONFLICT_WITH_PAST_RATE',
    EditRatePastConstraintFailed = 'EDIT_CONFLICT_WITH_PAST_RATE',
}
