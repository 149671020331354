import React from 'react';
import FieldSet from 'common/components/field-set/field-set';
import InfiniteSelect from 'common/components/infinite-select/infinite-select';
import { DIFFERENT_CORPORATE_NAME } from '../types';
import InputField from 'common/components/field/input-field';
import DropdownOption from 'common/models/dropdown-option';
import { CorporateNameProps } from './types';

export const CorporateName = ({
    onChange,
    data,
    errors,
    selector,
    tabIndex,
    readonly,
    corporateNameOptions,
    corporateNameValue,
}: CorporateNameProps) => {
    if (readonly) {
        return (
            <FieldSet name="corporateName" errors={errors} customClass="therapist-rate-field">
                <InputField
                    id="corporateName"
                    placeholder="Corporate Name / Tax ID"
                    name="corporateName"
                    onChange={onChange}
                    disabled={true}
                    value={corporateNameValue}
                    maxLength={100}
                    tabIndex={tabIndex + ''}
                />
            </FieldSet>
        );
    } else if (selector) {
        return (
            <FieldSet name="corporateNameSelector" errors={errors} customClass="therapist-rate-field required-field-star">
                <InfiniteSelect
                    id="corporateNameSelector"
                    items={corporateNameOptions}
                    label="Corporate Name / Tax ID"
                    value={corporateNameOptions.find((item: DropdownOption) => data.corporateNameSelector === item.value)}
                    definedTabIndex={tabIndex}
                    onChange={(item) => {
                        onChange({ name: 'corporateNameSelector', value: item.value });
                        onChange({ name: 'disableSave', value: DIFFERENT_CORPORATE_NAME === item.value });
                    }}
                ></InfiniteSelect>
            </FieldSet>
        );
    } else {
        return (
            <>
                <FieldSet name="corporateName" errors={errors} customClass="therapist-rate-field required-field-star">
                    <InputField
                        id="corporateName"
                        placeholder="Corporate Name"
                        name="corporateName"
                        onChange={onChange}
                        value={data?.corporateName}
                        maxLength={100}
                        tabIndex={tabIndex + ''}
                    />
                </FieldSet>

                <FieldSet name="taxId" errors={errors} customClass="therapist-rate-field required-field-star">
                    <InputField
                        id="taxId"
                        placeholder="Tax ID"
                        name="taxId"
                        onChange={onChange}
                        value={data?.taxId}
                        maxLength={9}
                        tabIndex={tabIndex + 1 + ''}
                    />
                </FieldSet>
            </>
        );
    }
};
