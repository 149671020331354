import React from 'react';
import ForgotPasswordForm from './components/forgot-password-form/forgot-password-form';
import './fogot-password.scss';
import userService from 'common/services/api/user/user-service';
import { IForgotPasswordRequest } from 'common/services/api/user/types';
import { trackPromise } from 'react-promise-tracker';

const ForgotPasswordPage: React.FC = () => {
    const handleSave = (request: IForgotPasswordRequest) => {
        return trackPromise(userService.sendResetPassword(request));
    };

    return <ForgotPasswordForm onSubmit={handleSave} />;
};

export default React.memo(ForgotPasswordPage);
