import React from 'react';
import './refresh-popup.scss';
import { RefreshPopupProps } from './types';
import { useDispatch } from 'react-redux';
import { setVersionModalStatus } from '../../../../app/store/version-modal/action-creators';

const RefreshPopup = ({ className = '' }: RefreshPopupProps) => {
    const dispatch = useDispatch();

    return (
        <div className="refresh-popup-wrapper">
            <div className={`refresh-popup ${className}`}>
                <div className="refresh-popup__content">
                    <div className="refresh-popup__body">A new version of application is available.</div>
                    <div className="refresh-popup__actions">
                        <button className="refresh-popup__refresh base-text-btn white" onClick={() => window.location.reload()}>
                            <span className="icon icon-loop"></span> Refresh now
                        </button>
                    </div>
                </div>
                <div className="refresh-popup__close-button" onClick={() => dispatch(setVersionModalStatus(false))}>
                    <span className="icon icon-close"></span>
                </div>
            </div>
        </div>
    );
};

export default RefreshPopup;
