import DisciplineAutocomplete from 'common/components/discipline-autocomplete/discipline-autocomplete';
import DropdownOption from 'common/models/dropdown-option';
import React from 'react';
import { DisciplineFilterProps } from './types';
import './discipline-filter.scss';

const DisciplineFilter: React.FC<DisciplineFilterProps> = ({ value, onChange, popper = true, widthOptions = 310, cleanable = true }) => {
    const handleChange = (disciplines: DropdownOption[]) => {
        const ids = disciplines.map((item) => item.value);
        onChange(ids);
    };

    return (
        <div className="discipline-filter small-size">
            <DisciplineAutocomplete
                defaultSelectedLabel="Discipline"
                popper={popper}
                cleanable={cleanable}
                widthOptions={widthOptions}
                value={value || []}
                onChange={handleChange}
            />
        </div>
    );
};

export default React.memo(DisciplineFilter);
