import { Reducer } from '@reduxjs/toolkit';
import { ContactRoleActionTypes, IContactRoleState, SET_CONTACT_ROLES } from './types';

const initialState: IContactRoleState = {
    data: undefined,
};

export const contactRoleReducer: Reducer<IContactRoleState, ContactRoleActionTypes> = (state = initialState, action) => {
    switch (action.type) {
        case SET_CONTACT_ROLES:
            return { ...state, data: action.contactRoles };
        default:
            return state;
    }
};
