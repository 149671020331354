import RightSideBar from 'common/components/right-side-bar/right-side-bar';
import DropdownOption from 'common/models/dropdown-option';
import CountyService from 'common/services/api/county/county-service';
import { IEntityDetailsBasicInfo } from 'common/services/api/entity/types';
import EntityBasicInfoForm from 'features/entities-page/components/entities-forms/entity-basic-info-form/entity-basic-info-form';
import FocusTrap from 'focus-trap-react';
import React, { useEffect, useState } from 'react';
import { EntityEditBasicInfoFormProp } from '../../entity-details/types';
import useFormSidebar from 'common/hooks/use-form-sidebar';
import { useTabIndex } from 'common/hooks/use-tab-index';

const EntityBasicInfoSidebar: React.FC<EntityEditBasicInfoFormProp> = ({ entityBasicInfo, onCancel, onSave }) => {
    const [data, error, handleChangeData, handleCancelClick, handleSaveClick] = useFormSidebar<IEntityDetailsBasicInfo>(
        {
            id: entityBasicInfo.id,
            name: entityBasicInfo.name,
            functionLinks: entityBasicInfo.functionLinks,
            address1: entityBasicInfo.address?.address1,
            address2: entityBasicInfo.address?.address2,
            city: entityBasicInfo.address?.city,
            state: entityBasicInfo.address?.state,
            zip: entityBasicInfo.address?.postalCode,
            phone: entityBasicInfo.phone,
            parent: entityBasicInfo.parent,
            npiNumber: entityBasicInfo.npiNumber,
            counties: entityBasicInfo.counties,
            dbn: entityBasicInfo.dbn,
        },
        { onClose: onCancel, onSave }
    );

    const [counties, setCounties] = useState<DropdownOption[]>([]);

    useEffect(() => {
        if (entityBasicInfo.address?.state?.id !== data.state?.id) {
            handleChangeData({ name: 'counties', value: [] });
        }

        if (data.state) {
            CountyService.getByState(data.state.id).then((counties) => {
                setCounties(counties.map((x) => new DropdownOption(x.id, x.name)));
            });
        }
    }, [entityBasicInfo.address?.state, data.state]);

    const { getTabIndex } = useTabIndex();

    return (
        <div>
            <FocusTrap>
                <RightSideBar title="Edit Entity" onCancel={handleCancelClick} onSave={handleSaveClick}>
                    <div className="create-form">
                        <EntityBasicInfoForm
                            getTabIndex={getTabIndex}
                            entityBasicInfo={data}
                            errors={error}
                            onChange={handleChangeData}
                            options={{ counties: counties }}
                            selectedState={entityBasicInfo.address?.state}
                        />
                    </div>
                </RightSideBar>
            </FocusTrap>
            <div className="right-sidebar-overlay"></div>
        </div>
    );
};

export default EntityBasicInfoSidebar;
