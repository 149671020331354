import { getTherapistDetailsRequest } from 'app/store/therapist/action-creators';
import communcationService from 'common/services/api/communication/communcation-service';
import { IInterview, IAddEditInterviewModel, InterviewStatus } from 'common/services/api/communication/types';
import React, { useState } from 'react';
import { trackPromise } from 'react-promise-tracker';
import { useDispatch } from 'react-redux';
import { toast } from 'react-toastify';
import { ICurrentSearchState } from '../../types';
import AddEditInterviewDialog from './add-edit-interview-dialog/add-edit-interview-dialog';
import TherapistInterviewList from './therapist-interview-list/therapist-interview-list';
import { OutcomeMustNotBeEmptyErrorCode } from './types';

type InterviewTabProps = {
    therapist: any;
    searchData: ICurrentSearchState<IInterview>;
    onSearchInputChange: (value: string, limit: number) => Promise<void>;
};

const InterviewTab = ({ therapist, searchData, onSearchInputChange }: InterviewTabProps) => {
    const [isAddInterviewDialogOpen, toggleNewInterviewDialog] = useState(false);
    const [forceUpdateTable, setForceUpdateTable] = useState<boolean>(false);
    const [currentInterview, setCurrentInterview] = useState<IInterview | null>(null);
    const dispatch = useDispatch<any>();

    const handleClickOnNewInterview = () => {
        openDialog();
    };

    const handleCancelAddNewInterview = () => {
        closeDialog();
    };

    const handleClickOnEditInterview = (interview: IInterview) => {
        setCurrentInterview(interview);
        openDialog();
    };

    const handleSetStatus = (interview: IInterview, status: InterviewStatus) => {
        trackPromise(communcationService.setStatus(interview.id, therapist.id, status))
            .then(() => {
                trackPromise(dispatch(getTherapistDetailsRequest(therapist.id)));
            })
            .catch((error) => {
                switch (error?.response?.data?.errorCode) {
                    case OutcomeMustNotBeEmptyErrorCode: {
                        const statusText = status === InterviewStatus.COMPLETED ? 'Complete' : 'Cancelled';
                        toast.error(`Interview Outcome is required before interview can be put in ${statusText} status`);
                        break;
                    }
                    default:
                        toast.error('Some error occured during changing status');
                        break;
                }
            })
            .finally(() => {
                setForceUpdateTable(false);
            });
    };

    const openDialog = () => {
        toggleNewInterviewDialog(true);
        setForceUpdateTable(false);
    };

    const closeDialog = () => {
        toggleNewInterviewDialog(false);
        setForceUpdateTable(false);
        setCurrentInterview(null);
    };

    const handleOnSuccess = () => {
        trackPromise(dispatch(getTherapistDetailsRequest(therapist.id)));
        setForceUpdateTable(true);
        closeDialog();
    };

    const handleClickOnSaveInterview = (interview: IAddEditInterviewModel) => {
        if (currentInterview) {
            return trackPromise(communcationService.updateInterview(currentInterview.id, therapist.id, interview)).then(handleOnSuccess);
        } else {
            return trackPromise(communcationService.createInterview(therapist.id, interview)).then(handleOnSuccess);
        }
    };

    return (
        <div className="table-page interview">
            <TherapistInterviewList
                forceUpdate={forceUpdateTable}
                therapistId={therapist.id}
                searchData={searchData}
                onSearchTermChange={onSearchInputChange}
                onNewInterviewClick={handleClickOnNewInterview}
                onEditInterviewClick={handleClickOnEditInterview}
                onStatusClick={handleSetStatus}
            />
            {isAddInterviewDialogOpen && (
                <AddEditInterviewDialog data={currentInterview} onClose={handleCancelAddNewInterview} onSave={handleClickOnSaveInterview} />
            )}
        </div>
    );
};

export default InterviewTab;
