// For case when you convert string to date in your array before set to table
import { IOption } from '../models/types';

export const dateComparator = (filterLocalDateAtMidnight: Date, cellValue: Date) => {
    if (filterLocalDateAtMidnight.getTime() === cellValue.setHours(0, 0, 0, 0)) {
        return 0;
    }
    if (cellValue < filterLocalDateAtMidnight) {
        return -1;
    }
    if (cellValue > filterLocalDateAtMidnight) {
        return 1;
    }
};

// For case with DateTime (createAt, updateAt)
export const localDateComparator = (filterLocalDateAtMidnight: Date, value: string) => {
    if (!value) {
        return -1;
    }
    const date = new Date(value);

    return dateComparator(filterLocalDateAtMidnight, new Date(date.getTime() - date.getTimezoneOffset() * 60000));
};

// For case with date from datepicker like yyyy-mm-ddT00:00:00
export const UTCDateComparator = (filterLocalDateAtMidnight: Date, value: string) => {
    if (!value) {
        return -1;
    }

    return dateComparator(filterLocalDateAtMidnight, new Date(value));
};

// Cuts
export const dateFilterParams = {
    comparator: dateComparator,
};

export const localDateFilterParams = {
    inRangeInclusive: true,
    comparator: localDateComparator,
};

export const UTCDateFilterParams = {
    inRangeInclusive: true,
    comparator: UTCDateComparator,
};

// agSetColumnFilter default params
export const setColumnFilterParams = {
    buttons: ['clear'], //, 'cancel', 'apply'
    debounceMs: 500,
    cellHeight: 43,
    refreshValuesOnOpen: true,
    closeOnApply: true,
    isNullable: true,
    defaultToNothingSelected: true,
};

export const nativeSort = (a: string, b: string) =>
    a.localeCompare(b, undefined, {
        numeric: true,
        sensitivity: 'base',
    });

export const optionsCompare = (a: string | IOption[], b: string | IOption[]) => {
    if (Array.isArray(a) && Array.isArray(b)) {
        return a
            .map((item: IOption) => item.name)
            .join(', ')
            .localeCompare(b.map((item: IOption) => item.name).join(', '), undefined, {
                numeric: true,
                sensitivity: 'base',
            });
    } else {
        if (Array.isArray(a) || Array.isArray(b)) {
            console.error('column should be only string or only IOption[]');

            return -1;
        }

        return nativeSort(a, b);
    }
};

export const optionsFormatter = (props: any) =>
    props.value
        .map((item: IOption) => item?.name)
        .sort(nativeSort)
        .join(', ');

export const optionsKeyCreator = (params: any) => {
    return params.value?.map((practices: IOption) => practices?.name);
};

export const excelStyles = [
    {
        id: 'header',
        font: {
            color: '#000000',
            weight: 800,
            bold: true,
            size: 12,
        },
        alignment: {
            horizontal: 'Center' as const,
            vertical: 'Center' as const,
        },
    },
    {
        id: 'cell',
        font: {
            color: '#000000',
        },
        alignment: {
            horizontal: 'Left' as const,
            vertical: 'Center' as const,
        },
    },
];

export const defaultColDefClient = {
    resizable: true,
    getQuickFilterText: () => {
        return '';
    },
};
