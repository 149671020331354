import { InfiniteServerClient } from 'common/helpers/http-clients';
import { IPaginationParams } from 'common/models/types';
import { trackPromise } from 'react-promise-tracker';
import { IAddEditDocumentRequest, IDocument, IDocumentShortInfo } from './types';

export class DocumentService {
    getAll(params?: IPaginationParams): Promise<IDocument[]> {
        return InfiniteServerClient.get<IDocument[]>('/documents', { params }).then(
            (response) => {
                return response.data;
            },
            (error) => {
                throw error;
            }
        );
    }

    create(document: IAddEditDocumentRequest): Promise<IDocument> {
        return trackPromise(
            InfiniteServerClient.post<IDocument>('/documents', document).then(
                (response) => {
                    return response.data;
                },
                (error) => {
                    throw error;
                }
            )
        );
    }

    edit(id: number, document: IAddEditDocumentRequest): Promise<IDocument> {
        return trackPromise(
            InfiniteServerClient.put<IDocument>(`/documents/${id}`, document).then(
                (response) => {
                    return response.data;
                },
                (error) => {
                    throw error;
                }
            )
        );
    }

    getDocumentOptions(term: string, skip: number, take: number): Promise<IDocumentShortInfo[]> {
        return InfiniteServerClient.get<IDocumentShortInfo[]>('/documents/options', {
            params: {
                term,
                skip,
                take,
            },
        }).then(
            (response) => {
                return response.data;
            },
            (error) => {
                throw error;
            }
        );
    }
}

export default new DocumentService();
