import { ColumnApi, GridApi, GridOptions, GridReadyEvent, IServerSideGetRowsParams } from 'ag-grid-community';
import AgTable from 'common/components/ag-table/ag-table';
import { IParamsWatcher } from 'common/components/ag-table/types';
import AddButton from 'common/components/header/add-button/add-button';
import ClearFiltersButton from 'common/components/header/clear-filters-button/clear-filters-button';
import HeaderLeftSide from 'common/components/header/header-left-side';
import HeaderRightSide from 'common/components/header/header-right-side';
import HeaderWrapper from 'common/components/header/header-wrapper';
import SearchFilterInput from 'common/components/header/search-filter-input/search-filter-input';
import InfiniteSelect from 'common/components/infinite-select/infinite-select';
import { useSearchInput } from 'common/hooks/use-serch-input';
import DropdownOption from 'common/models/dropdown-option';
import { IInterview, InterviewStatus } from 'common/services/api/communication/types';
import { getFilterData, getTableData } from 'common/services/api/tabel-fetch-service';
import { ICurrentSearchState } from 'features/therapist-details-page/communication-tab/types';
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { InterviewActions } from '../types';
import { InterviewListDefenition } from './interview-list-defenition';
import { LocationRenderer } from './renders/location-renderer';
import { StatusRenderer } from './renders/status-renderer';
import { ITherapistInterviewFilter, TherapistInterviewListProps } from './types';
import './therapist-interview-list.scss';
import { LocalTableRepository } from 'common/helpers/repository/local-table-repository';
import { LocalStoreRepository } from 'common/helpers/repository/local-store-repository';

const defaultFilter: ITherapistInterviewFilter = {
    search: null,
};

const tableRepository = new LocalTableRepository('therapist-interview-table', 'v2');
const customFiltersRepository = new LocalStoreRepository('therapist-interview-table-custom-filters');

let loadedFilters: ITherapistInterviewFilter = customFiltersRepository.load();

const TherapistInterviewList = ({
    therapistId,
    onNewInterviewClick,
    onEditInterviewClick,
    onStatusClick,
    searchData,
    forceUpdate,
    onSearchTermChange,
}: TherapistInterviewListProps) => {
    const ref = useRef<ICurrentSearchState<IInterview>>();

    const paramsWatcher = useRef<IParamsWatcher>();

    const { search, handleSearchInputChange } = useSearchInput(searchData?.term || '', (value: string) => {
        if (onSearchTermChange) {
            onSearchTermChange(value, paramsWatcher.current.limit);
        } else {
            setFilters((prev) => ({ ...prev, search: value }));
        }
    });

    const [gridApi, setGridApi] = useState<GridApi>(null);
    const [gridColumnApi, setColumnApi] = useState<ColumnApi>(null);
    const [filters, setFilters] = useState<ITherapistInterviewFilter>(loadedFilters ?? defaultFilter);

    useEffect(() => {
        ref.current = searchData;
    }, [searchData]);

    useEffect(() => {
        customFiltersRepository.save(filters);
        loadedFilters = filters;
    }, [filters]);

    useEffect(() => {
        if (gridApi || forceUpdate) {
            gridApi.onFilterChanged();
        }
    }, [forceUpdate, gridApi, searchData]);

    const handleFetchData = (event: IServerSideGetRowsParams, props: any) => {
        const filterData = paramsWatcher.current.getFilterModelParams(paramsWatcher.current.request);

        const isFilterApplied = () => {
            return (
                filterData.hostName ||
                filterData.participants ||
                filterData.dateTime ||
                filterData.status ||
                filterData.location ||
                filterData.duration
            );
        };

        if (ref && ref.current?.term && !isFilterApplied()) {
            return Promise.resolve({ data: ref.current.result.data });
        }

        paramsWatcher.current.getCustomFilterParams.search = ref.current.term;

        return getTableData(`therapists/${therapistId}/interviews`)(event, props);
    };

    const handleFetchFilters = getFilterData(`therapists/${therapistId}/interviews/filter/source`);

    const onGridReady = (params: GridReadyEvent) => {
        setGridApi(params.api);
        setColumnApi(params.columnApi);
    };

    const handleClickOnClearButton = () => {
        handleSearchInputChange('');
    };

    const handleOptionClick = useCallback(
        (value: string, interview: IInterview) => {
            switch (value) {
                case InterviewActions.EDIT:
                    onEditInterviewClick(interview);
                    break;
                case InterviewActions.COMPLETE:
                    onStatusClick(interview, InterviewStatus.COMPLETED);
                    break;
                case InterviewActions.CANCEL:
                    onStatusClick(interview, InterviewStatus.CANCELLED);
                    break;
            }
        },
        [onEditInterviewClick, onStatusClick]
    );

    const gridOptions: GridOptions = useMemo(
        () => ({
            columnDefs: InterviewListDefenition,
            defaultColDef: {
                resizable: true,
                filterParams: {
                    onFetchFilters: handleFetchFilters,
                },
            },
            frameworkComponents: {
                locationRenderer: LocationRenderer,
                statusRenderer: StatusRenderer,
                doteRender: (props: any) => {
                    const itemOptions = [
                        new DropdownOption(InterviewActions.EDIT, InterviewActions.EDIT),
                        new DropdownOption(InterviewActions.CANCEL, InterviewActions.CANCEL),
                        new DropdownOption(InterviewActions.COMPLETE, InterviewActions.COMPLETE),
                    ];

                    return (
                        <InfiniteSelect
                            id="therapistDocumentMenu"
                            popper
                            items={itemOptions}
                            className="no-select-border"
                            icon={<i className="icon icon-options" />}
                            onChange={(item: DropdownOption) => {
                                handleOptionClick(item?.value, props.data);
                            }}
                        />
                    );
                },
            },
        }),
        [handleFetchFilters, handleOptionClick]
    );

    const handleFiltersClear = () => {
        handleSearchInputChange('');
        setFilters({ ...defaultFilter });
    };

    return (
        <>
            <div className="main-info-wrapper ag-table-wrapper therapist-interview-list">
                <HeaderWrapper>
                    <HeaderLeftSide>
                        <SearchFilterInput
                            onClearClick={handleClickOnClearButton}
                            title="by Communication"
                            value={search}
                            onInput={handleSearchInputChange}
                        />
                    </HeaderLeftSide>
                    <HeaderRightSide>
                        <ClearFiltersButton
                            title="Providers"
                            gridApi={gridApi}
                            gridColumnApi={gridColumnApi}
                            onClick={handleFiltersClear}
                        />
                        <AddButton title="Add Interview" onClick={onNewInterviewClick}></AddButton>
                    </HeaderRightSide>
                </HeaderWrapper>
                <AgTable
                    onGridReady={onGridReady}
                    gridOptions={gridOptions}
                    customFilters={filters}
                    paramsWatcher={paramsWatcher}
                    repository={tableRepository}
                    onFetchData={handleFetchData}
                />
            </div>
        </>
    );
};

export default React.memo(TherapistInterviewList);
