import { InfiniteServerClient } from 'common/helpers/http-clients';
import { ITherapistAddRate, ITherapistEditRate } from './types';
import { DateTimeHelper } from '../../../helpers/date-time-helper';

export class TherapistRatesService {
    addRate = (therapistId: number, rate: ITherapistAddRate) => {
        return InfiniteServerClient.post(`/therapists/${therapistId}/rates`, rate).then(
            (response) => {
                return response.data;
            },
            (error) => {
                throw error;
            }
        );
    };

    editRate = (therapistId: number, rateId: number, rate: ITherapistEditRate) => {
        return InfiniteServerClient.put(`/therapists/${therapistId}/rates/${rateId}`, rate).then(
            (response) => {
                return response.data;
            },
            (error) => {
                throw error;
            }
        );
    };

    updateRate = (therapistId: number, rateId: number, editRate: ITherapistEditRate, newRate: ITherapistAddRate) => {
        const formData = {
            editRate: this.getFormDate(editRate),
            createRate: this.getFormDate(newRate),
        };

        return InfiniteServerClient.put(`/therapists/${therapistId}/rates/${rateId}/action/update`, formData).then(
            (response) => {
                return response.data;
            },
            (error) => {
                throw error.response.data;
            }
        );
    };

    getFormDate({ ...rate }: any) {
        for (const key in rate) {
            if (rate[key]) {
                if (rate[key] instanceof Date) {
                    rate[key] = DateTimeHelper.format(rate[key], null, true);
                }

                if (rate[key]._isAMomentObject) {
                    rate[key] = DateTimeHelper.format(rate[key].toDate(), null, true);
                }
            }
        }

        return rate;
    }

    validateContract(therapistId: number, rateId: number): Promise<void> {
        return InfiniteServerClient.put(`therapists/${therapistId}/rates/${rateId}/action/validate-contract`).then(
            (response) => {
                return response.data;
            },
            (error) => {
                throw error;
            }
        );
    }

    NotifyTherapist(therapistId: number, rateId: number): Promise<void> {
        return InfiniteServerClient.get(`therapists/${therapistId}/rates/${rateId}/action/notify-therapist`).then(
            (response) => {
                return response.data;
            },
            (error) => {
                throw error;
            }
        );
    }

    deleteTherapistRate(therapistId: number, rateId: number): Promise<void> {
        return InfiniteServerClient.put(`therapists/${therapistId}/rates/${rateId}/delete`).then(
            (response) => {
                return response.data;
            },
            (error) => {
                throw error;
            }
        );
    }
}

export default new TherapistRatesService();
