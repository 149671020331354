import { ExtentedDefaultTableProps, IDefaultFilters } from 'common/constants/types';
import { Guid } from 'guid-typescript';
import { ILead, LeadStatus } from 'common/services/api/lead/types';

export type LeadsListProps = {
    // this property is used to store current table stamp. Just generate a new Guid to initiate page refreshing
    currentTableStamp: Guid;

    onVerifyClick: (leadId: number) => void;
    onRefuseClick: (lead: ILead) => void;
};

export interface ICustomLeadFilter extends IDefaultFilters {
    status: Array<number>;
}

export type LeadsManagementTablesProps = ExtentedDefaultTableProps & {
    onVerifyClick: (lead: ILead, target: any) => void;
    onRefuseClick: (lead: ILead, target: any) => void;
    onOpenClick: (lead: ILead, detailsNavigationState: any) => void;
};

export const LeadStatusLabel = new Map<LeadStatus, string>([
    [LeadStatus.Undefined, 'Unknown'],
    [LeadStatus.New, 'New'],
    [LeadStatus.Verified, 'Verified'],
    [LeadStatus.InProgress, 'In Progress'],
    [LeadStatus.Refused, 'Refused'],
]);
