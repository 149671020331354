import NewSmsDialog from 'common/components/new-sms-dialog/new-sms-dialog';
import { INavigationButton } from 'common/models/navigation-button';
import communcationService from 'common/services/api/communication/communcation-service';
import { IMessageItem, INewEmailModel, INewSmsModel, TherapistCommunicationType } from 'common/services/api/communication/types';
import React, { useState } from 'react';
import { trackPromise } from 'react-promise-tracker';
import { ICurrentSearchState } from '../../types';
import NewEmailDialog from './new-email-dialog/new-email-dialog';
import TherapistMessageList from './therapist-message-list/therapist-message-list';
import ViewMessageDialog from './view-message-dialog/view-message-dialog';

type MessageTabProps = {
    therapist: any;
    searchData: ICurrentSearchState<IMessageItem>;
    onSearchInputChange: (value: string, limit: number) => Promise<void>;
};

const MessageTab = ({ therapist, searchData, onSearchInputChange }: MessageTabProps) => {
    const [messages, setMessages] = React.useState<IMessageItem[]>([]);
    const [isNewEmailDialogOpen, toggleNewEmailDialog] = useState(false);
    const [isNewSmsDialogOpen, toggleNewSmsDialog] = useState(false);
    const [isViewMessageDialogOpen, setIsViewMessageDialogOpen] = useState(false);
    const [currentMessageIndex, setCurrentMessageIndex] = useState<number>(-1);
    const [currentViewMessage, setCurrentViewMessage] = useState<IMessageItem>();
    const [forceUpdateTable, setForceUpdateTable] = useState<boolean>(false);

    const phones = therapist?.contactInfo?.phones || [];

    const handleClickOnNewButton = (type: TherapistCommunicationType) => {
        setForceUpdateTable(false);

        switch (type) {
            case TherapistCommunicationType.Email:
                toggleNewEmailDialog(true);
                break;
            case TherapistCommunicationType.Sms:
                toggleNewSmsDialog(true);
                break;
            default:
                throw new Error(`'${type}' is invalid communcation type.`);
        }
    };

    const handleTableDataChanged = (items: IMessageItem[]) => {
        setMessages(items);
    };

    const handleCancelNewEmailDialog = () => toggleNewEmailDialog(false);
    const handleClickOnCancelNewSmsDialog = () => toggleNewSmsDialog(false);

    const handleClickOnSaveEmail = (email: INewEmailModel): Promise<any> => {
        return trackPromise(
            communcationService.sendEmail(therapist.id, email).then(() => {
                handleCancelNewEmailDialog();
                setForceUpdateTable(true);
            })
        );
    };

    const handleClickOnSaveSms = (email: INewSmsModel): Promise<any> => {
        return trackPromise(
            communcationService.sendSms(therapist.id, email).then(() => {
                handleClickOnCancelNewSmsDialog();
                setForceUpdateTable(true);
            })
        );
    };

    const handleClickOnNextMessage = () => {
        setCurrentMessageIndex(currentMessageIndex + 1);
        setCurrentViewMessage(messages[currentMessageIndex + 1]);
    };

    const handleClickOnPreviousMessage = () => {
        setCurrentMessageIndex(currentMessageIndex - 1);
        setCurrentViewMessage(messages[currentMessageIndex - 1]);
    };

    const handleOnMessageClick = (rowIndex: number, email: IMessageItem) => {
        setCurrentViewMessage(email);
        setCurrentMessageIndex(rowIndex);
        setIsViewMessageDialogOpen(true);
    };

    const getPreviousMessageButton = () => {
        return currentMessageIndex > 0 ? { text: 'Previous', onClick: handleClickOnPreviousMessage } : null;
    };

    const getNextMessageButton = () => {
        const button: INavigationButton = { text: 'Next', onClick: handleClickOnNextMessage };

        return currentMessageIndex < messages.length - 1 ? button : null;
    };

    return (
        <div className="table-page">
            <TherapistMessageList
                onSearchTermChange={onSearchInputChange}
                onDataChanged={handleTableDataChanged}
                forceUpdate={forceUpdateTable}
                therapistId={therapist.id}
                onNewButtonClick={handleClickOnNewButton}
                onItemClick={handleOnMessageClick}
                searchData={searchData}
            />
            {isNewEmailDialogOpen && (
                <NewEmailDialog
                    internalEmail={therapist.contactInfo.internalEmail}
                    personalEmail={therapist.contactInfo.email}
                    onCancel={handleCancelNewEmailDialog}
                    onSave={handleClickOnSaveEmail}
                />
            )}

            {isViewMessageDialogOpen && (
                <ViewMessageDialog
                    message={currentViewMessage!}
                    onCancel={() => setIsViewMessageDialogOpen(false)}
                    previousButton={getPreviousMessageButton()}
                    nextButton={getNextMessageButton()}
                />
            )}

            {isNewSmsDialogOpen && (
                <NewSmsDialog phones={phones} onCancel={handleClickOnCancelNewSmsDialog} onSave={handleClickOnSaveSms} />
            )}
        </div>
    );
};

export default React.memo(MessageTab);
