import React, { useState, useEffect } from 'react';
import FieldSet from 'common/components/field-set/field-set';
import InfiniteSelect from 'common/components/infinite-select/infinite-select';
import RightSideBar from 'common/components/right-side-bar/right-side-bar';
import DropdownAutocomplete from 'common/components/field/dropdown/dropdown-autocomplete';
import { useCountyData } from 'common/hooks/use-county-data';
import { usePopulationData } from 'common/hooks/use-population-data';
import { useDepartmentData } from 'common/hooks/use-department-data';
import DropdownOption from 'common/models/dropdown-option';
import BaseService from 'common/services/api/base/base-service';
import { ConverterHelper } from 'common/helpers/converter-helper';
import { OptionField } from 'common/constants/option-fields';
import { IEditPreferences } from 'common/services/api/therapist/types';
import { FieldValue } from 'common/models/field-value';
import { PreferencesEditFormProps } from './types';

// TO-DO: Set tab indexes on the form
const PreferencesEditForm: React.FC<PreferencesEditFormProps> = ({ therapist, onSave, onCancel }) => {
    const [preferencesData, setPreferences] = useState<IEditPreferences>({
        rate: therapist.rate ? therapist.rate : undefined,
        departments: therapist.departments.map((i) => i.id),
        counties: therapist.counties.map((i) => i.id),
        populations: therapist.populations.map((i) => i.id),
        zipCodes: therapist.zipCodes.map((i) => i.code),
        zipCodeOptions: therapist.zipCodes.map((i) => new DropdownOption(i.id, i.code)),
        subDepartments: therapist.subDepartments.map((i) => i.id),
    });

    const departmentData = useDepartmentData();

    const countiesOptions = useCountyData().map((item) => new DropdownOption(item.id, item.name));
    const populationsOptions = usePopulationData().map((item) => new DropdownOption(item.id, item.name));

    const [zipCodeOptions, setZipCodeOptions] = useState([]);
    const [zipPageIndex, setZipPageIndex] = useState(0);
    const [zipCodeTerm, setZipCodeTerm] = useState('');

    const departmentsOptions = departmentData.map((item) => new DropdownOption(item.id, item.name));
    const subDepartments = departmentData.flatMap((i) => i.subDepartments.map((sb) => ConverterHelper.subDepartmentConvert(sb, i.name)));

    const [departmentTerm, setDepartmentTerm] = useState('');

    const [errorData, setErrorData] = useState({});
    const [subDepartmentTerm, setSubDepatmentTerm] = useState<string>();

    const avaliableSubDepartments = departmentData
        .filter((item) => preferencesData.departments.includes(item.id))
        .flatMap((i) => i.subDepartments.map((sb) => ConverterHelper.subDepartmentConvert(sb, i.name)));

    const pageSize = 20;

    const handleFieldChange = (data: FieldValue) => {
        setPreferences((prevInfo) => ({ ...(prevInfo as IEditPreferences), [data.name]: data.value }));
        setErrorData((prevError) => ({ ...prevError, [data.name]: undefined }));
    };

    const handleSave = () => {
        onSave({
            ...preferencesData,
            zipCodes: preferencesData.zipCodeOptions.map((item) => item.value),
        }).catch(setErrorData);
    };

    const handleZipCodesTermChange = (term: any) => {
        setZipCodeTerm(term);
    };

    const handleScrollZipSelect = () => {
        const index = zipPageIndex + 1;
        setZipPageIndex(index);

        BaseService.getOptions(zipCodeTerm, index * pageSize, pageSize, OptionField.zipCode).then((options) => {
            setZipCodeOptions((prevZipCodes) => [...prevZipCodes, ...options.map((item) => ConverterHelper.optionConvert(item))]);
        });
    };

    useEffect(() => {
        BaseService.getOptions(zipCodeTerm, 0, pageSize, OptionField.zipCode).then((options) => {
            setZipCodeOptions([...options.map((item) => ConverterHelper.optionConvert(item))]);
        });
    }, [zipCodeTerm]);

    useEffect(() => {
        if (departmentData?.length > 0) {
            const newPreferredSubDepartments = preferencesData.subDepartments.filter((item) =>
                avaliableSubDepartments.some((avaliable) => avaliable.value === item)
            );

            if (!preferencesData.subDepartments.every((item) => newPreferredSubDepartments.includes(item))) {
                console.log('setr');
                setPreferences((prevInfo) => ({ ...prevInfo, subDepartments: newPreferredSubDepartments }));
            }
        }
    }, [avaliableSubDepartments, preferencesData, departmentData]);

    return (
        <div>
            <RightSideBar title="Edit Preferences" onCancel={onCancel} onSave={handleSave}>
                <div className="edit-therapist-form">
                    <FieldSet errors={errorData} name="zipCodes" customClass="edit-therapist-field">
                        <DropdownAutocomplete
                            id={'zipCodesAutocomplete'}
                            items={zipCodeOptions}
                            label={'ZIP'}
                            multiselect={true}
                            searchPlaceholder={'Search Zip Code'}
                            value={preferencesData.zipCodeOptions}
                            onChange={handleZipCodesTermChange}
                            onSelect={(items: DropdownOption[]) => handleFieldChange({ name: 'zipCodeOptions', value: items })}
                            onScrollEnd={handleScrollZipSelect}
                            onClose={() => setZipPageIndex(0)}
                        ></DropdownAutocomplete>
                    </FieldSet>
                    <FieldSet errors={errorData} name="populations" customClass="edit-therapist-field">
                        <InfiniteSelect
                            items={populationsOptions}
                            multiselect={true}
                            label="Population"
                            value={populationsOptions.filter((item) => preferencesData.populations.some((id) => id === item.value))}
                            onChange={(item) => handleFieldChange({ name: 'populations', value: item.map((i: any) => i.value) })}
                        ></InfiniteSelect>
                    </FieldSet>
                    <FieldSet errors={errorData} name="counties" customClass="edit-therapist-field">
                        <InfiniteSelect
                            items={countiesOptions}
                            multiselect={true}
                            label="County"
                            value={countiesOptions.filter((item) => preferencesData.counties.some((id) => id === item.value))}
                            onChange={(item) => handleFieldChange({ name: 'counties', value: item.map((i: any) => i.value) })}
                        ></InfiniteSelect>
                    </FieldSet>
                    <FieldSet errors={errorData} name="departments" customClass="edit-therapist-field">
                        <DropdownAutocomplete
                            items={
                                departmentTerm
                                    ? departmentsOptions.filter((department) =>
                                          department.label.toLowerCase().includes(departmentTerm.toLowerCase())
                                      )
                                    : departmentsOptions
                            }
                            label="Department"
                            multiselect
                            searchPlaceholder={'Search Department'}
                            value={departmentsOptions.filter((item) => preferencesData?.departments.some((id) => id === item.value))}
                            onChange={setDepartmentTerm}
                            onSelect={(item: DropdownOption[]) =>
                                handleFieldChange({ name: 'departments', value: item.map((i) => i.value) })
                            }
                        />
                    </FieldSet>
                    {avaliableSubDepartments?.length > 0 && (
                        <FieldSet name="subDepartments" errors={errorData} customClass="edit-therapist-field">
                            <DropdownAutocomplete
                                items={
                                    subDepartmentTerm
                                        ? avaliableSubDepartments.filter((subDepartment) =>
                                              subDepartment.label.toLowerCase().includes(subDepartmentTerm.toLowerCase())
                                          )
                                        : avaliableSubDepartments
                                }
                                label="Sub Department"
                                multiselect
                                searchPlaceholder={'Search Sub Department'}
                                value={subDepartments.filter((item) => preferencesData?.subDepartments.some((id) => id === item.value))}
                                onChange={setSubDepatmentTerm}
                                onSelect={(item: DropdownOption[]) =>
                                    handleFieldChange({ name: 'subDepartments', value: item.map((i) => i.value) })
                                }
                            />
                        </FieldSet>
                    )}
                </div>
            </RightSideBar>
            <div className="right-sidebar-overlay"></div>
        </div>
    );
};

export default PreferencesEditForm;
