import { dateTimeConstants } from 'common/constants/common';
import Moment from 'moment';
import React from 'react';
import { RequestHeaderInfoProp } from '../types';
import './request-header-info.scss';
import { useIsUserType } from 'common/hooks/use-is-user-type';
import { RequestStatus, RequestStatusLabel } from 'common/services/api/requests/types';
import DropdownOption from 'common/models/dropdown-option';
import AssignToDropdown from 'common/components/assign-to-dropdown/assign-to-dropdown';

const formatDate = (date: Date) => {
    return Moment(date).format(dateTimeConstants.MM_DD_YYYY);
};

const RequestHeaderInfo: React.FC<RequestHeaderInfoProp> = ({ details, handleSeeActivityButton, onAssignUser }) => {
    const statusClass = RequestStatus[details.status].toLowerCase();
    const { isExternal } = useIsUserType();
    const isHold = details?.status === RequestStatus.Hold;

    const handleChangeAssignTo = (option: DropdownOption) => {
        onAssignUser(option.value);
    };

    return (
        <div className="rh-info">
            <div className="rh-info__title">{details.customer?.name ?? details.customer}</div>
            <div className="rh-info__body">
                <span>
                    {' '}
                    {details?.id} <i className="rh-info__dote" />{' '}
                </span>
                <span>
                    {' '}
                    Created {formatDate(details.createdAt)} by {details.createdBy}{' '}
                </span>
                {details.updatedAt && (
                    <span>
                        {' '}
                        <i className="rh-info__dote" /> Last updated {formatDate(details.updatedAt)} by {details.updatedBy}
                    </span>
                )}
            </div>
            <div className="rh-info__footer">
                <span className={`request-status ${statusClass}`} onClick={handleSeeActivityButton}>
                    {RequestStatusLabel.get(details.status)}
                </span>
                <div className="rh-info__actions">
                    <AssignToDropdown
                        defaultValue={details.assignedTo?.fullName}
                        disabled={isHold || isExternal}
                        onChange={handleChangeAssignTo}
                    />
                    {!isExternal && (
                        <button className="base-text-btn white" onClick={handleSeeActivityButton}>
                            See Activity
                        </button>
                    )}
                </div>
            </div>
        </div>
    );
};

export default React.memo(RequestHeaderInfo, function areEqual(prevProps, nextProps) {
    return prevProps.details === nextProps.details;
});
