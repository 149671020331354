import { InfiniteServerClient } from 'common/helpers/http-clients';
import { ILoggingDetailPage } from './types';
import { ObjectHelper } from 'common/helpers/object-helper';

export class LoggingService {
    getDetails(id: number): Promise<ILoggingDetailPage> {
        return InfiniteServerClient.get<ILoggingDetailPage>('/audits/details/' + id).then(
            (response) => {
                return {
                    ...response.data,
                    ...ObjectHelper.parsObjects(response.data, ['oldValues', 'newValues']),
                };
            },
            (error) => {
                throw error;
            }
        );
    }
}

export default new LoggingService();
