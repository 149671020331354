export type IBackgroundChecksTableProps = {
    therapistId: number;
    forceUpdate: boolean;

    onAddBackgroundCheckClick: (document: any) => void;
    onEditBackgroundCheckClick: (document: any) => void;
    onEditBackgroundCheckHistoryClick: (document: any) => void;
};

export enum BackgroundCheckActions {
    Add = 'Add',
    Edit = 'Edit',
}
