import { DisplayAddressType } from 'common/services/api/map/types';
import React, { useCallback } from 'react';
import { ResultCounterProps } from './types';

export const ResultCounter: React.FC<ResultCounterProps> = ({ result }) => {
    const getCountTherapists = useCallback(() => {
        if (!result) {
            return 0;
        }

        return result
            .filter((i) => i.type === DisplayAddressType.Home || i.type === DisplayAddressType.Preference)
            .map((i) => i.id)
            .filter((value: number, index: number, self: number[]) => self.indexOf(value) === index).length;
    }, [result]);

    return (
        <div className="therapist-map-bottom">
            <div className="total-row-bar">{getCountTherapists() || 0} RESULTS</div>
        </div>
    );
};

export default React.memo(ResultCounter);
