import { ITherapistAssignment } from 'common/services/api/therapist-assignment/types';

export type AssignmentsListProps = {
    therapistId: number;
    therapistStatus: string;
    forceUpdate: boolean;

    onEditClick: (assignment: ITherapistAssignment) => void;
    onAddClick: () => void;
};

export const AssignmentActions = {
    EDIT: 'Edit',
};
