import { IPatientDetails } from 'common/services/api/patient/types';
import { Action, AnyAction, ThunkAction } from '@reduxjs/toolkit';

export const SET_PATIENT_DETAILS = 'SET_PATIENT_DETAILS';
export const UPDATE_PATIENT_PROFILE_PHOTO = 'UPDATE_PATIENT_PROFILE_PHOTO';

export type ThunkResult<R> = ThunkAction<R, IPatientState, null, AnyAction>;

export interface IPatientState {
    readonly patient: IPatientDetails;
}

export interface ISetPatientDetailsAction extends Action<'SET_PATIENT_DETAILS'> {
    patient: IPatientDetails;
}

export interface IUpdatePatientProfilePhotoAction extends Action<'UPDATE_PATIENT_PROFILE_PHOTO'> {
    profilePhoto: string;
}

export type PatientDetailsStateActionTypes = ISetPatientDetailsAction | IUpdatePatientProfilePhotoAction;
