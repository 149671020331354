import { IAddEditDocumentRuleRequest, IDocumentRule } from 'common/services/api/document-rule/types';

export type AddEditDocumentRuleDialogProps = {
    title: string;
    defaultDocumentRule?: IDocumentRule;

    onCancel: () => void;
    onSave: (document: IAddEditDocumentRuleRequest) => Promise<void>;
};

export enum ErrorCodes {
    InvalidDisciplineOrDepartment = 'RULE_SHOULD_HAVE_DISCIPLINE_OR_DEPARTMENT',
}
