import DropdownOption from 'common/models/dropdown-option';

export const THERAPIST_HAS_EFFECTIVE_ASSIGNMENT = 'THERAPIST_HAS_EFFECTIVE_ASSIGNMENT';

export const reasonsDropDownOptions = [
    new DropdownOption('AcceptedAnotherOffer', 'Accepted Another Offer', undefined, 1),
    new DropdownOption('DeclinedOffer', 'Declined Offer', undefined, 3),
    new DropdownOption('NoCasesAvailable', 'No Cases Available', undefined, 3),
    new DropdownOption('NotInterested', 'Not Interested', undefined, 4),
    new DropdownOption('NotResponsive', 'Not Responsive', undefined, 5),
    new DropdownOption('Resigned', 'Resigned', undefined, 6),
    new DropdownOption('Terminated', 'Terminated', undefined, 7),
    new DropdownOption('Other', 'Other', undefined, 8),
];

export const getInactiveReasonText = (value: string) => {
    return reasonsDropDownOptions.find((x) => x.value === value)?.label || '';
};
