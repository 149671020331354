import React, { useState } from 'react';
import FieldSet from 'common/components/field-set/field-set';
import PaginationAutocomplete from 'common/components/field/pagination-autocomplete/pagination-autocomplete';
import AutoPopulateDropdownOption from 'common/models/auto-populate-dropdown-option';
import CreatePatientForm from 'features/patients-page/components/create-patients-form/create-patients-form';
import { ICreatePatientRequest, IPatientOption } from 'common/services/api/patient/types';
import PatientService from 'common/services/api/patient/patient-service';
import { IForm } from 'common/models/types';
import { IRequestEditPatient } from '../types';
import { Card } from 'common/components/card';
import { ICardConfig } from 'common/components/card/types';
import './request-patient-form.scss';
import { useHistory } from 'react-router';
import { NavigationRoutes } from 'models/routes/navigation-routes';
import { validationRules } from 'features/patients-page/validation-rules';
import { SELECT_CUSTOMER_BEFORE_TYPING } from 'features/request-page/request-validation-messages';
import { hasAddress } from 'common/helpers/address-helper';
import { departmentKey } from 'common/constants/department-key';

const RequestPatientForm = ({ data: patientRequest, errors, onChange, options }: IForm<IRequestEditPatient>) => {
    const [isCreatePatientFormOpen, setIsCreatePatientFormOpen] = useState(false);
    const history = useHistory();

    const patientIdConvertFunction = (patient: IPatientOption) => {
        return new AutoPopulateDropdownOption(patient.patientAgencyCode, patient.patientName, null, patient.id, patient.zipCode);
    };

    const openCreatePatientForm = (patientAgencyCode: string) => {
        onChange({ name: 'patientAgencyCode', value: patientAgencyCode });
        setIsCreatePatientFormOpen(true);
    };

    const closeCreatePatientForm = () => {
        setIsCreatePatientFormOpen(false);
    };

    const fetchPatientsData = (term: string, skip: number, take: number): Promise<IPatientOption[]> => {
        return PatientService.getPatientsOptions(term, skip, take, patientRequest.customerId);
    };

    const updatePatientDetails = (patientId: number) => {
        return PatientService.getPatientRequestDetails(patientId).then((data) => {
            onChange({ name: 'patientDetails', value: data });
        });
    };

    const savePatient = (patient: ICreatePatientRequest) => {
        return PatientService.create(patient).then((response) => {
            onChange({ name: 'patientAgencyCode', value: patient.basicInfo.patientAgencyCode });
            onChange({ name: 'patientId', value: response.id });
            updatePatientDetails(response.id);
            setIsCreatePatientFormOpen(false);
        });
    };

    const patientDetails = patientRequest?.patientDetails;
    const patientFacilityAddress: any = patientRequest?.patientDetails?.facility?.address;
    const facilityAddress = options.requestDetails?.facility?.address;
    const renderAddress = (value: string, property: string) => {
        return (
            (hasAddress(patientDetails)
                ? value
                : hasAddress(patientFacilityAddress)
                ? patientFacilityAddress?.[property]
                : facilityAddress?.[property]) ?? '-'
        );
    };

    const patientDetailsConfig: ICardConfig[] = [
        {
            title: 'Patient Name',
            field: 'fullName',
            rightIcon: 'icon-open-in',
            onClickRightIcon: (value: any, data) => {
                history.push({ pathname: NavigationRoutes.patientDetailsRoute(data.id) });
            },
        },
        { title: 'Legacy Patient ID', field: 'legacyId' },
        {
            title: 'Address 1',
            field: 'address1',
            rightIcon: 'icon-map',
            renderEmpty: true,
            render: (value) => renderAddress(value, 'address1'),
            onClickRightIcon: (data: any) => {
                console.log('map click', data);
            },
        },
        { title: 'Address 2', field: 'address2', renderEmpty: true, render: (value) => renderAddress(value, 'address2') },
        { title: 'City', field: 'city', width: '50%', renderEmpty: true, render: (value) => renderAddress(value, 'city') },
        {
            title: 'State',
            field: 'state',
            width: '50%',
            renderEmpty: true,
            render: (state) =>
                (hasAddress(patientDetails)
                    ? state?.name
                    : hasAddress(patientFacilityAddress)
                    ? patientFacilityAddress?.state?.name
                    : facilityAddress?.state?.name) ?? '-',
        },
        {
            title: 'Zip',
            field: 'zip',
            width: '50%',
            renderEmpty: true,
            render: (value) => renderAddress(value, 'postalCode'),
        },
    ];

    return (
        <>
            {isCreatePatientFormOpen && (
                <CreatePatientForm
                    onClose={() => closeCreatePatientForm()}
                    onSave={savePatient}
                    dataForCreation={{
                        customerIdForCreation: patientRequest?.customerId,
                        patientAgencyCodeForCreation: patientRequest.patientAgencyCode,
                        zipCodeForCreation: patientRequest?.patientDetails?.zip,
                        facilityIdForCreation: patientRequest?.facilityId,
                        addressForCreation: options?.requestDetails?.address,
                    }}
                    showCopyAddressFromRequestBtn={options?.requestDetails?.department?.key === departmentKey.HCA}
                />
            )}

            {!isCreatePatientFormOpen && (
                <>
                    <div className="edit-agency-form edit-form outline-none" tabIndex={1}>
                        <h3>Patient Details</h3>
                        <FieldSet name="patientId" errors={errors} customClass="edit-field state-autocomplete">
                            <PaginationAutocomplete
                                fetchData={fetchPatientsData}
                                fetchTrigger={patientRequest.customerId}
                                convertFunction={patientIdConvertFunction}
                                defaultTerm={patientRequest?.patientDetails?.patientAgencyCode}
                                id="patientId"
                                placeholder="Patient ID"
                                name="patientId"
                                tabIndex="1"
                                onDelete={() => onChange({ name: 'patientId', value: undefined })}
                                onSelect={(item) => {
                                    onChange({ name: 'patientId', value: item.id });
                                    updatePatientDetails(item.id);
                                }}
                                onInputChange={(value) => {
                                    onChange({ name: 'patientAgencyCode', value: value });
                                    onChange({ name: 'patientId', value: undefined });
                                }}
                                autoPopulateDropdown
                                autoPopulateDropdownCallback={openCreatePatientForm}
                                maxInputLength={validationRules.patientAgencyCode.maxLength}
                                errorMessage={!patientRequest.customerId && SELECT_CUSTOMER_BEFORE_TYPING}
                            />
                        </FieldSet>
                    </div>
                </>
            )}
            <hr className="divider" />
            <Card
                className="patient-request-details"
                noBorder
                defaultValue="-"
                config={patientDetailsConfig}
                data={patientRequest.patientDetails}
            />
        </>
    );
};

export default RequestPatientForm;
