import {
    LOGIN_FAILURE,
    LOGIN_RESET_ERROR,
    LOGIN_SUCCESS,
    LOGOUT,
    SET_ENTITY,
    SET_REDIRECT_TO,
    SET_USER,
} from './login-page/auth-constants';
import { ACCESS_TOKEN_KEY, USER_FULL_NAME } from '../../common/constants/local-storage-keys';
import jwt from 'jsonwebtoken';

const token = localStorage.getItem(ACCESS_TOKEN_KEY);

const getPermissions = () => {
    if (!token) {
        return [];
    }

    const permissions = jwt.decode(token).permission;

    return Array.isArray(permissions) ? permissions : [permissions];
};

const getType = () => {
    if (!token) {
        return null;
    }

    return jwt.decode(token).Type;
};

const getEntity = () => {
    if (!token) {
        return null;
    }
    const decodedToken = jwt.decode(token);

    return decodedToken.entity ? JSON.parse(decodedToken.entity) : null;
};

const initialState = {
    errorMessage: null,
    loggedIn: token ? true : false,
    user: {
        fullName: localStorage.getItem(USER_FULL_NAME),
        type: getType(),
        permissions: getPermissions(),
    },
    entity: getEntity(),
    redirectTo: '/',
};

export function authReducer(state = initialState, action) {
    switch (action.type) {
        case LOGIN_FAILURE:
            return { ...state, errorMessage: action.errorMessage };
        case LOGIN_RESET_ERROR:
            return { ...state, errorMessage: null };
        case LOGIN_SUCCESS:
            return { ...state, loggedIn: action.loggedIn, redirectTo: action.redirectTo };
        case LOGOUT:
            return { ...state, loggedIn: false };
        case SET_USER:
            return { ...state, user: action.user };
        case SET_ENTITY:
            return { ...state, entity: action.entity };
        case SET_REDIRECT_TO:
            return { ...state, redirectTo: action.redirectTo };
        default:
            return state;
    }
}
