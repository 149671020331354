import { Action } from '@reduxjs/toolkit';
import React from 'react';
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import { ThunkDispatch } from 'redux-thunk';
import { getFileActionCreator } from '../store/document-viewer-action-creators';
import { IFile } from 'common/models/file';

import '../_styles.scss';
import { FileHelper } from 'common/helpers/file-helper';

const DocumentViewer = () => {
    const { fileId } = useParams<any>();
    const [file] = useState<IFile>();
    const dispatch = useDispatch<ThunkDispatch<any, any, Action>>();

    useEffect(() => {
        dispatch(getFileActionCreator(fileId)).then((file) => {
            //setFile(file.file);
            FileHelper.downloadOrOpen(file.file);
        });
    }, [dispatch, fileId]);

    if (file && file?.fileBytes) {
        const src = `data:${file.contentType};base64,${file.fileBytes}`;

        return <iframe className="document-viewer" title={file.originalName} src={src} frameBorder="0" height="100%" width="100%" />;
    } else {
        return null;
    }
};

export default DocumentViewer;
