export enum OptionField {
    subDepartment = 'SubDepartment',
    staffingAgency = 'StaffingAgency',
    referralSource = 'ReferralSource',
    discipline = 'Discipline',
    zipCode = 'ZipCode',
    document = 'Document',
    relationship = 'Relationship',
    paySource = 'PaySource',
    gender = 'Gender',
}
