import React from 'react';
import PropTypes from 'prop-types';
import { useRouteMatch, useHistory, Route } from 'react-router-dom';

const Tabs = ({ orientation, children, className = '', parentTabUrl = null }) => {
    const { url } = useRouteMatch();
    const history = useHistory();

    const handleTabClick = (tabIndex, tabRoute) => {
        if (history.location.pathname.indexOf(`${parentTabUrl}`) === -1 && parentTabUrl) {
            history.push(url + `/${parentTabUrl}/` + tabRoute);
        } else {
            history.push(url + '/' + tabRoute);
        }
    };

    const getFirstActiveTabIndex = () => {
        let firstActiveIndex = -1;
        React.Children.forEach(children, (child, index) => {
            if (child && firstActiveIndex === -1) {
                firstActiveIndex = index;

                return;
            }
        });

        return firstActiveIndex;
    };

    const renderChildrenWithTabsApiAsProps = () => {
        let firstActiveIndex = getFirstActiveTabIndex();

        return React.Children.map(children, (child, index) => {
            const tabRoute = getRoute(child) ?? index;
            const isActive =
                history.location.pathname.indexOf(`${url}/${getRoute(child) ?? index}`) !== -1 ||
                (history.location.pathname === url && index === firstActiveIndex);

            return (
                child &&
                React.cloneElement(child, {
                    onClick: handleTabClick,
                    tabIndex: index,
                    tabRoute: tabRoute,
                    tabUrl: `${url}/${getRoute(child) ?? index}`,
                    isActive: isActive,
                })
            );
        });
    };

    const renderActiveTabContent = () => {
        let firstActiveIndex = getFirstActiveTabIndex();

        return (
            <>
                {React.Children.map(children, (child, index) => {
                    const TabContentComponent = () => child?.props?.children ?? null;

                    return (
                        <>
                            {index === firstActiveIndex && <Route path={`${url}`} exact render={TabContentComponent} />}
                            <Route path={`${url}/${getRoute(child) ?? index}`} render={TabContentComponent} />
                        </>
                    );
                })}
            </>
        );
    };

    return (
        <>
            <div className={`tabs ${orientation} ${className}`}>
                <ul className="tabs-nav nav navbar-nav navbar-left">{renderChildrenWithTabsApiAsProps()}</ul>
                <div className="tabs-active-content">{renderActiveTabContent()}</div>
            </div>
        </>
    );
};

const getRoute = (tab) => {
    const toUrl = (str) => {
        if (!str) return;

        return str.replace(/\s+/g, '-').toLowerCase();
    };

    return tab?.props?.routeName || toUrl(tab?.props?.text) || null;
};

Tabs.propTypes = {
    orientation: PropTypes.oneOf(['horizontal', 'vertical']).isRequired,
};

export default Tabs;
