import React from 'react';
import { displayTherapistStatus } from 'features/therapist-details-page/shared/constants';
import { TherapistGeneralInfoProps } from './types';

const TherapistGeneralInfo: React.FC<TherapistGeneralInfoProps> = ({ therapist }) => {
    const statusColor = therapist.status.toLowerCase();

    return (
        <div className="therapist-info-data">
            <div className="therapist-name">
                {therapist.firstName} {therapist.middleName} {therapist.lastName}
            </div>
            <div className="therapist-disciplines">{therapist.disciplines.map((i) => i.name).join(', ')}</div>
            <div className="therapist-populations">{therapist.preferredPopulations.map((i) => i.name).join(', ')}</div>
            <div className={`therapist-status ${statusColor}`}>{displayTherapistStatus(therapist.status)}</div>
        </div>
    );
};

export default React.memo(TherapistGeneralInfo);
