export const validationRules = {
    middleName: {
        maxLength: 100,
    },
    patientAgencyCode: {
        maxLength: 25,
    },
    specialInstructions: {
        maxLength: 250,
    },
};
