import React, { useState, useRef, useEffect, RefObject } from 'react';
import Button from 'common/components/Button/button';
import useOnClickOutside from 'react-cool-onclickoutside';

type OptionsButtonProps = {
    id: string;
    title?: string;
    className: string;
    classNameList?: string;
    classNameWrapper?: string;
    optionsList: any[];
    onOptionClick: (value: any) => void;
    initDropdownPosition?: (dropdownWrapper: any, optionsWrapper: any) => void;
};

const OptionsButton = ({
    id,
    className,
    classNameList,
    classNameWrapper,
    optionsList,
    onOptionClick,
    initDropdownPosition,
    title = 'Export',
}: OptionsButtonProps) => {
    const [isDropdownOpen, toggleDropdownState] = useState(false);
    const dropdownWrapper = useRef() as RefObject<HTMLDivElement>;
    const dropdownOptionList = useRef() as RefObject<HTMLDivElement>;

    useOnClickOutside(
        () => {
            toggleDropdownState(false);
        },
        { refs: [dropdownOptionList] }
    );

    const onOptionClickHandle = (value: any) => {
        onOptionClick(value);
        toggleDropdownState(!isDropdownOpen);
    };

    const renderOptionsList = () => {
        return optionsList.map((item, index) => (
            <span className="option-item" id={`${item}_${index}`} key={item.value} onClick={() => onOptionClickHandle(item.value)}>
                {item.title}
            </span>
        ));
    };

    useEffect(() => {
        if (isDropdownOpen && initDropdownPosition) {
            initDropdownPosition(dropdownWrapper.current, dropdownOptionList.current);
        }
    }, [isDropdownOpen]);

    return (
        <div className={`options-button-wrapper ${classNameWrapper ?? ''}`} ref={dropdownWrapper}>
            <Button
                id={id}
                onClick={() => toggleDropdownState(!isDropdownOpen)}
                className={isDropdownOpen ? `active-btn ${className}` : `${className}`}
            >
                {title}
            </Button>
            {isDropdownOpen && (
                <div className={`options-button-options-list ${classNameList ?? ''}`} ref={dropdownOptionList} id="optionsDropdownWrapper">
                    {renderOptionsList()}
                </div>
            )}
        </div>
    );
};

export default OptionsButton;
