import { InfiniteServerClient } from 'common/helpers/http-clients';
import { AxiosResponse } from 'axios';
import { IServerSideGetRowsParams } from 'ag-grid-community';
import { IParamsWatcher } from 'common/components/ag-table/types';
import { FilterHelper } from 'common/helpers/filter-helper';

export function getTableData(baseUrlPath: string) {
    return (
        event: IServerSideGetRowsParams,
        { pageIndex, limit, getFilterModelParams, getSortParams, getCustomFilterParams, request }: any
    ) => {
        const queryParams = FilterHelper.toURLQueryParams({
            ...getSortParams(request),
            ...getFilterModelParams(request),
            ...getCustomFilterParams,
        }).join('&');

        const separator: string = baseUrlPath.includes('?') ? '&' : '/?';

        return InfiniteServerClient.get(`/${baseUrlPath}${separator}${queryParams}`, {
            params: {
                pageIndex: pageIndex,
                limit: limit,
            },
        });
    };
}

export function getFilterData(baseUrlPath: string) {
    return (params: any) => {
        return InfiniteServerClient.get(baseUrlPath, {
            params: {
                ...params,
            },
        });
    };
}

export function getExportData(exportUrl: string) {
    return (exportType: string, { gridColumnApi, getFilterModelParams, getSortParams, getCustomFilterParams, request }: IParamsWatcher) => {
        const columns = gridColumnApi
            .getAllDisplayedColumns()
            .filter((x) => {
                const column: any = x.getColDef();

                return !column?.hideForExport;
            })
            .map((x) => x.getColId());
        const queryParams = FilterHelper.toURLQueryParams({
            columns,
            ...getFilterModelParams(request),
            ...getSortParams(request),
            ...getCustomFilterParams,
        }).join('&');

        return InfiniteServerClient.get(`${exportUrl}/${exportType}?${queryParams}`, {
            responseType: 'blob',
        }).then((response: AxiosResponse<Blob>) => {
            const data = response.data;
            if (data) {
                return data;
            }
        });
    };
}
