import DetailsNavigationService from 'common/services/api/details-navigation-serivce/details-navigation-service';
import { IDetailsNavigationData } from 'common/services/api/details-navigation-serivce/type';
import React, { useEffect, useMemo, useState } from 'react';
import { trackPromise } from 'react-promise-tracker';
import { useHistory } from 'react-router';
import DetailsNavigation from './details-navigation';
import { ServerNavigationProps } from './types';

const ServerNavigation: React.FC<ServerNavigationProps> = ({ entityApiBaseUrl, entityId, navigationRoute }) => {
    const history = useHistory<any>();
    const [navigationData, setNavigationData] = useState<IDetailsNavigationData>(null);
    const historyState = useMemo(() => history.location.state, [entityId]);

    useEffect(() => {
        getNavigationData();
    }, [entityId]);

    const getNavigationData = () => {
        if (!historyState?.queryParams || !historyState?.entityIndex || !entityApiBaseUrl) {
            return;
        }

        trackPromise(
            DetailsNavigationService.getData(entityApiBaseUrl, historyState.entityIndex, historyState.queryParams).then((data) =>
                setNavigationData(data)
            )
        );
    };

    const handleClickPreviousEntity = () => {
        if (!navigationData.previousId) {
            return;
        }

        history.push(
            { pathname: navigationRoute(navigationData.previousId) },
            {
                entityIndex: --historyState.entityIndex,
                queryParams: historyState.queryParams,
            }
        );
    };

    const handleClickNextEntity = () => {
        if (!navigationData.nextId) {
            return;
        }

        history.push(
            { pathname: navigationRoute(navigationData.nextId) },
            {
                entityIndex: ++historyState.entityIndex,
                queryParams: historyState.queryParams,
            }
        );
    };

    return (
        <DetailsNavigation
            navigationData={navigationData}
            onPreviousClick={handleClickPreviousEntity}
            onNextClick={handleClickNextEntity}
        />
    );
};

export default ServerNavigation;
