import FieldList from 'common/components/field-list/field-list';
import FieldSet from 'common/components/field-set/field-set';
import CheckboxField from 'common/components/field/checkbox-field';
import InputField from 'common/components/field/input-field';
import InfiniteSelect from 'common/components/infinite-select/infinite-select';
import PhoneTypeInput from 'common/components/phone-type-input/phone-type-input';
import { labels } from 'common/constants/labels';
import DropdownOption from 'common/models/dropdown-option';
import { IPhone } from 'features/shared/types';
import React from 'react';
import { validationRules } from '../../validation-rules';
import { IPatientContactInfoFormProps } from './types';

const PatientContactInfoForm: React.FC<IPatientContactInfoFormProps> = ({ contactInfo, options, errors, onChange, getTabIndex }) => {
    const { firstName, middleName, lastName, isEmergencyContact, isMainContact, phones, emails, relationship } = contactInfo;

    const isContactInfoRequired = () => {
        return !!firstName || !!middleName || !!lastName || !!phones[0]?.number || !!emails[0]?.email || !!relationship;
    };

    return (
        <>
            <h3>Contact Info</h3>
            <div className="edit-form">
                <FieldSet name="firstName" errors={errors} customClass={`edit-field ${isContactInfoRequired() && 'required-field-star'}`}>
                    <InputField
                        placeholder="First Name"
                        name="firstName"
                        onChange={onChange}
                        value={firstName}
                        tabIndex={getTabIndex()}
                    ></InputField>
                </FieldSet>
                <FieldSet name="middleName" errors={errors} customClass="edit-field ">
                    <InputField
                        maxLength={validationRules.middleName.maxLength}
                        placeholder={labels.middleNameInitial}
                        name="middleName"
                        onChange={onChange}
                        value={middleName}
                        tabIndex={getTabIndex()}
                    ></InputField>
                </FieldSet>
                <FieldSet name="lastName" errors={errors} customClass={`edit-field ${isContactInfoRequired() && 'required-field-star'}`}>
                    <InputField
                        placeholder="Last Name"
                        name="lastName"
                        onChange={onChange}
                        value={lastName}
                        tabIndex={getTabIndex()}
                    ></InputField>
                </FieldSet>
                <FieldSet name="isEmergencyContact" errors={errors} customClass="edit-therapist-field">
                    <CheckboxField
                        id="isEmergencyContact"
                        placeholder="Is Emergency Contact"
                        name="isEmergencyContact"
                        onChange={onChange}
                        value={isEmergencyContact}
                        tabIndex={getTabIndex()}
                    />
                </FieldSet>
                <FieldSet name="isMainContact" errors={errors} customClass="edit-therapist-field">
                    <CheckboxField
                        id="isMainContact"
                        placeholder="Is Main Contact"
                        name="isMainContact"
                        onChange={onChange}
                        value={isMainContact}
                        tabIndex={getTabIndex()}
                    />
                </FieldSet>
                <FieldSet errors={errors} name="relationship" customClass="edit-field">
                    <InfiniteSelect
                        id="relationship"
                        definedTabIndex={getTabIndex()}
                        label="Relationship"
                        items={options.relationshipTypes}
                        value={options.relationshipTypes.find((item: DropdownOption) => item.value === relationship)}
                        onChange={(item) => onChange({ name: 'relationship', value: item.value })}
                    />
                </FieldSet>
                <FieldList
                    name="phones"
                    isDragNDrop
                    values={phones}
                    startTabIndex={getTabIndex()}
                    defaultValue={{ type: options.phoneTypes[0].value, number: '' }}
                    addButtonText="Add Phone"
                    onChange={(values: any[]) => onChange({ name: 'phones', value: values })}
                    renderInput={(value: any, index: number, startTabIndex) => (
                        <FieldSet
                            name="phones"
                            errors={errors}
                            customClass={`edit-phone ${index === 0 ? 'main-item' : ''} ${isContactInfoRequired() && 'required-field-star'}`}
                            renderError={(error: any) => error[index]?.number[0]}
                        >
                            <PhoneTypeInput
                                tabIndex={startTabIndex + index}
                                errors={errors['phones']?.[index]}
                                phoneTypes={options.phoneTypes}
                                onChange={(value: IPhone) => {
                                    phones[index] = { ...value };
                                    onChange({ name: 'phones', value: [...phones] });
                                }}
                                value={value}
                            />
                        </FieldSet>
                    )}
                />
                <FieldList
                    name="emails"
                    isDragNDrop
                    values={emails}
                    defaultValue={{ email: '' }}
                    startTabIndex={getTabIndex()}
                    addButtonText="Add Email Address"
                    onChange={(values: any[]) => onChange({ name: 'emails', value: values })}
                    renderInput={(value: any, index: number, startTabIndex) => (
                        <FieldSet
                            name="emails"
                            errors={errors}
                            customClass={`edit-field email ${index === 0 ? 'main-item' : ''}`}
                            renderError={(error: any) => error[index]?.email && error[index]?.email[0]}
                        >
                            <InputField
                                tabIndex={startTabIndex + index}
                                placeholder="Email Address"
                                onChange={(data: any) => {
                                    emails[index] = { email: data.value };
                                    onChange({ name: 'emails', value: [...emails] });
                                }}
                                value={value.email}
                            ></InputField>
                        </FieldSet>
                    )}
                />
            </div>
        </>
    );
};

export default PatientContactInfoForm;
