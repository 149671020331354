import Breadcrumb from 'common/components/Breadcrumb/breadcrumb';
import ServerNavigation from 'common/components/details-navigation/server-navigation';
import Tab from 'common/components/tabs/tab';
import Tabs from 'common/components/tabs/tabs';
import leadService from 'common/services/api/lead/lead-service';
import { ILead } from 'common/services/api/lead/types';
import { IToggleFeatureList } from 'common/services/api/settings/types';
import { NavigationRoutes } from 'models/routes/navigation-routes';
import React, { useCallback, useEffect, useState } from 'react';
import { trackPromise } from 'react-promise-tracker';
import { useSelector } from 'react-redux';
import { Link, useParams } from 'react-router-dom';
import GeneralInfoTab from './general-info-tab/general-info-tab';
import LeadHeader from './lead-header/lead-header';
import { ILeadParamsType } from './types';

const BREADCRUMB_ITEMS = [
    { to: '/leads', label: 'Leads' },
    { to: '', label: 'Lead Details' },
];

const LeadDetailsPage: React.FC = () => {
    const [lead, setLead] = useState<ILead>();
    const params = useParams<ILeadParamsType>();
    const leadId = Number.parseInt(params?.leadId);

    const featureList: IToggleFeatureList = useSelector((state: any) => state.siteSettings?.settings?.features || {});
    const updateLeadData = useCallback(() => trackPromise(leadService.getLead(leadId)).then((data: ILead) => setLead(data)), [leadId]);

    useEffect(() => {
        if ((!lead && leadId) || lead?.id !== leadId) {
            updateLeadData();
        }
    }, [lead, leadId, updateLeadData]);

    const handleAssignUser = (userId: string) => {
        leadService.assignToUser(leadId, userId).then(() => {
            updateLeadData();
        });
    };

    return (
        <>
            <div className="lead details">
                <header>
                    {featureList.isDetailsNavigationEnabled && (
                        <ServerNavigation entityApiBaseUrl="leads" entityId={leadId} navigationRoute={NavigationRoutes.leadDetaisPage} />
                    )}
                    <div className="header-mock">
                        <Breadcrumb>
                            {BREADCRUMB_ITEMS.map(({ to, label }) => (
                                <Link key={to} to={to}>
                                    {label}
                                </Link>
                            ))}
                        </Breadcrumb>
                    </div>
                </header>
                {lead && <LeadHeader lead={lead} onAssignSelect={handleAssignUser} />}
                {lead && (
                    <Tabs orientation="horizontal">
                        <Tab linkClassName={'custom-link'} text="General Info">
                            <GeneralInfoTab lead={lead} />
                        </Tab>
                    </Tabs>
                )}
            </div>
        </>
    );
};
export default React.memo(LeadDetailsPage);
