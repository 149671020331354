import { CellClickedEvent, GridOptions } from 'ag-grid-community';
import AgTable from 'common/components/ag-table/ag-table';
import { IParamsWatcher } from 'common/components/ag-table/types';
import { ITherapistDashboardItem } from 'common/services/api/therapist-dahsboard/types';
import { NavigationRoutes } from 'models/routes/navigation-routes';
import React, { useContext, useMemo, useRef } from 'react';
import { WidgetTableProps } from './types';
import { DashboardPageContext } from '../../dashboard-page';
import { useAssignToColumn } from '../../hooks/use-assign-to-column';
import { DAYS_COLUMN, DISCIPLINE_COLUMN, PROVIDER_NAME_COLUMN } from '../../constants';

export const WidgetTable = <T extends unknown>({
    repository,
    fetchFunction,
    filter,
    openTherapist = true,
    onRowClick,
    supervisorView,
    overdueDaysSetting,
    widgetOptions,
    hideColumns,
}: WidgetTableProps<T>) => {
    const paramsWatcher = useRef<IParamsWatcher>();

    const { agAPIs } = useContext(DashboardPageContext);

    const hideColumnsDefault: any = {
        assignedTo: supervisorView,
        ...hideColumns,
    };

    const ASSIGNED_TO_COLUMN = useAssignToColumn(widgetOptions);

    const gridOptions: GridOptions = useMemo(
        () => ({
            onCellClicked: (event: CellClickedEvent) => {
                if (onRowClick) {
                    onRowClick(event.data as ITherapistDashboardItem);
                } else if (openTherapist) {
                    const therapist: ITherapistDashboardItem = event.data as ITherapistDashboardItem;
                    window.open(NavigationRoutes.therapistDetailsRoute(therapist.id), '_blank').focus();
                }
            },
            columnDefs: [PROVIDER_NAME_COLUMN, DISCIPLINE_COLUMN, DAYS_COLUMN, ASSIGNED_TO_COLUMN],
            paginationPageSize: 5,
            cacheBlockSize: 5,
            defaultColDef: { resizable: true },
            rowClassRules: {
                overdue: (params) => (params.data as ITherapistDashboardItem)?.days < overdueDaysSetting && supervisorView,
            },
        }),
        [onRowClick, openTherapist, overdueDaysSetting, supervisorView, ASSIGNED_TO_COLUMN]
    );

    return (
        <div className="ag-table-wrapper">
            <AgTable
                agAPIs={agAPIs.current}
                repository={repository}
                gridOptions={gridOptions}
                customFilters={filter}
                paramsWatcher={paramsWatcher}
                onFetchData={fetchFunction}
                globalFilterPosition={true}
                hideColumns={hideColumnsDefault}
            />
        </div>
    );
};

export default React.memo(WidgetTable);
