export class DisplayValueHelper {
    static convertToYesOrNo(value: boolean) {
        return value ? 'Yes' : 'No';
    }

    static unmaskPhone(phone: string) {
        return phone.replace(/[^0-9]/g, '').toString();
    }

    static getValue(object: any, property: string, defaultValue = '') {
        return object ? object[property] || defaultValue : defaultValue;
    }
    static monthsPeriod(value: string | number) {
        return value ? `${value} Months` : '---';
    }

    static numberToString(value: number) {
        switch (value) {
            case 1:
                return 'one';

            case 2:
                return 'two';

            case 3:
                return 'three';

            default:
                return '';
        }
    }

    static rateEmptyOrIsSet(value: string | number, placeholder = 'No Active Rate') {
        const val = typeof value === 'string' ? parseFloat(value) : value;

        return val !== null && val !== undefined && !Number.isNaN(value) ? `$${val.toFixed(2)}` : placeholder;
    }
}
