import { InfiniteServerClient } from 'common/helpers/http-clients';
import {
    ICreateUpdateUserRequest,
    IResetPasswordRequest,
    IUser,
    IUserBaseInfo,
    IVerifyResetTokenResponse,
    IVerifyResetTokenRequest,
    IForgotPasswordRequest,
    ICreateUpdateExternalUserRequest,
} from './types';

export class UserService {
    getUserStaffOptions(term?: string, skip?: number, take?: number, isActive?: boolean): Promise<IUserBaseInfo[]> {
        return InfiniteServerClient.get<IUserBaseInfo[]>('/users/options/staff', { params: { term, skip, isActive, take } }).then(
            (response) => {
                return response.data;
            },
            (error) => {
                throw error;
            }
        );
    }

    getAll(term?: string, skip?: number, take?: number, isActive?: boolean): Promise<IUser[]> {
        return InfiniteServerClient.get<IUser[]>('/users', { params: { skip, take, isActive, term } }).then(
            (response) => {
                return response.data;
            },
            (error) => {
                throw error;
            }
        );
    }

    getExternalAll(term?: string, skip?: number, take?: number): Promise<IUser[]> {
        return InfiniteServerClient.get<IUser[]>('entities/contacts/users', { params: { skip, take, search: term } }).then(
            (response) => {
                return response.data;
            },
            (error) => {
                throw error;
            }
        );
    }

    create(request: ICreateUpdateUserRequest): Promise<IUser> {
        return InfiniteServerClient.post<IUser>('/users', request).then(
            (response) => {
                return response.data;
            },
            (error) => {
                throw error;
            }
        );
    }

    update(id: string, request: ICreateUpdateExternalUserRequest): Promise<IUser> {
        return InfiniteServerClient.put<IUser>(`/users/${id}`, request).then(
            (response) => {
                return response.data;
            },
            (error) => {
                throw error;
            }
        );
    }

    createExternal(id: string, request: ICreateUpdateExternalUserRequest): Promise<IUser> {
        return InfiniteServerClient.post<IUser>(`/entities/contacts/${id}/users`, {
            lockoutEnabled: request?.lockoutEnabled,
        }).then(
            (response) => {
                return response.data;
            },
            (error) => {
                throw error;
            }
        );
    }

    updateExternal(id: string, request: ICreateUpdateUserRequest): Promise<IUser> {
        return InfiniteServerClient.put<IUser>(`/entities/contacts/${id}/users`, {
            lockoutEnabled: request?.lockoutEnabled,
        }).then(
            (response) => {
                return response.data;
            },
            (error) => {
                throw error;
            }
        );
    }

    resetPassword(request: IResetPasswordRequest): Promise<void> {
        return InfiniteServerClient.post<void>(`/users/actions/reset-password`, request).then(
            (response) => {
                return response.data;
            },
            (error) => {
                throw error;
            }
        );
    }

    verifyResetPasswordToken(request: IVerifyResetTokenRequest): Promise<IVerifyResetTokenResponse> {
        return InfiniteServerClient.post<IVerifyResetTokenResponse>(`/users/actions/verify-reset-password-token`, request).then(
            (response) => {
                return response.data;
            },
            (error) => {
                throw error;
            }
        );
    }

    sendResetPassword(request: IForgotPasswordRequest): Promise<void> {
        return InfiniteServerClient.post<void>(`/users/actions/send-reset-password`, request).then(
            (response) => {
                return response.data;
            },
            (error) => {
                throw error;
            }
        );
    }
}

export default new UserService();
