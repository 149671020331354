import { IFilterComp } from 'ag-grid-community';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import InfiniteSelect from '../../infinite-select/infinite-select';
import DropdownOption from '../../../models/dropdown-option';
import { ICustomFilerModel, MultipleFilterSelectProps } from './types';

const FilterSelect = ({
    id = '',
    items,
    colId,
    label,
    defaultMultipleSelectLabel,
    gridApi,
    multiselect = false,
    clearFilters = false,
    selectAll = false,
    isNullable = false,
}: MultipleFilterSelectProps) => {
    const [selectedValues, setSelectedValues] = useState([]);

    const setFilter = useCallback(
        (name: string, filerModel: ICustomFilerModel) => {
            gridApi.getFilterInstance(name, (filterApi: IFilterComp) => {
                filterApi.setModel({ ...filterApi.getModel(), exclude: false, ...filerModel });
                gridApi.onFilterChanged();
            });
        },
        [gridApi]
    );

    const onClearFilters = useCallback(() => {
        setFilter(colId, { values: [], isNullable: false });
    }, [setFilter]);

    const onSelectAll = useCallback(
        (itemsToSelect: DropdownOption[]) => {
            setFilter(colId, { values: itemsToSelect.map((i: DropdownOption) => i.value) });
        },
        [setFilter]
    );

    const listItems = useMemo(() => {
        return isNullable ? [DefaultDropdownOption, ...items] : items;
    }, [items, isNullable]);

    useEffect(() => {
        if (!gridApi) {
            return;
        }

        const handleFilterChange = (e: any) => {
            e.api.getFilterInstance(colId, (filterApi: IFilterComp) => {
                if (filterApi.getModel()?.values) {
                    const model = filterApi.getModel();

                    // Check filter model's isNullable property.
                    // If it exists and true - add '(Blanks)' option to selected items, because agCustomFilter is used.
                    setSelectedValues(model?.isNullable ? [...model?.values, DefaultDropdownOption.value] : model?.values);
                } else {
                    setSelectedValues([]);
                }
            });
        };

        gridApi.addEventListener('filterChanged', handleFilterChange);

        return () => {
            gridApi.removeEventListener('filterChanged', handleFilterChange);
        };
    }, [gridApi, colId]);

    // agCustomFilter and agSetColumnFilter has different behavior for isNullable option.
    // To select/unselect '(Blanks)' value in agCustomFilter we should provide isNullable property in filterModel.
    // To select/unselect '(Blanks)' values in agSetColumnFilter we should add or remove null value in filterModel.values array.
    // This method checks what filter is used on this column and provides needed properties in filterModel for both filters.
    const handleListChange = useCallback(
        (items: any) => {
            if (!multiselect) {
                setFilter(colId, { values: [items?.value] });

                return;
            }

            let filterValues = items.map((i: DropdownOption) => i.value);

            if (!isNullable) {
                setFilter(colId, { values: filterValues });

                return;
            }

            const isCustomFilter = gridApi?.getColumnDef(colId)?.filter === 'agCustomFilter';

            if (isCustomFilter) {
                // Get values without blank element
                filterValues = filterValues.filter((i: any) => i !== DefaultDropdownOption.value);
            }

            const isBlankElementSelected = items.length !== filterValues.length;

            setFilter(colId, { values: filterValues, isNullable: isBlankElementSelected });
        },
        [setFilter, gridApi]
    );

    const value = multiselect
        ? listItems.filter((item) => selectedValues.some((checkedItem) => checkedItem === item.value))
        : listItems.find((item) => selectedValues?.[0] === item.value);

    return (
        <InfiniteSelect
            id={id}
            items={listItems}
            label={label}
            defaultMultipleSelectLabel={defaultMultipleSelectLabel}
            value={value}
            onChange={handleListChange}
            onSelectAll={multiselect && selectAll ? onSelectAll : undefined}
            onClear={clearFilters ? onClearFilters : undefined}
            multiselect={multiselect}
            isDisplaySelectedCount
        />
    );
};

const DefaultDropdownOption = new DropdownOption(null, '(Blanks)');

export default React.memo(FilterSelect);
