import React, { useRef, useState } from 'react';
import PatientsInZipDialogHeader from './patients-in-zip-dialog-header';
import PatientsInZipTable from './patients-in-zip-table';
import './patients-in-zip.scss';
import { PatientOnZipDialogProp } from './types';
import InfinitePopper from 'common/components/infinite-popper/infinite-popper';

const PatientsOnZipDialog: React.FC<PatientOnZipDialogProp> = ({ therapistId, therapistName, patientsCount, week, onClickCallBack }) => {
    const refTarget = useRef();

    const [target, setTarget] = useState(null);

    return (
        <>
            <span
                className="patients-in-zip-content"
                ref={refTarget}
                onClick={() => {
                    setTarget(refTarget.current);
                    onClickCallBack();
                }}
            >
                {patientsCount}
            </span>
            {target && (
                <InfinitePopper
                    className="patients-in-zip-dialog-wrapper"
                    target={target}
                    width={845}
                    onClickOutside={() => setTarget(null)}
                    blockPageScroll={true}
                    options={{
                        placement: 'auto-start',
                    }}
                >
                    <div className="patients-in-zip-dialog-content">
                        <PatientsInZipDialogHeader
                            patientsCount={patientsCount}
                            therapistName={therapistName}
                            handleOnCloseClick={() => setTarget(null)}
                        />
                        <PatientsInZipTable extendDefaultCustomFilters={{ week: week, therapistId: therapistId }} />
                    </div>
                </InfinitePopper>
            )}
        </>
    );
};

export default PatientsOnZipDialog;
