import { GREATER_THAN, IN_RANGE } from 'common/constants/date-time-constants';
import moment from 'moment';

export const transformDateToBase64 = (
    condition: {
        dateFrom: string;
        dateTo: string;
        type: string;
    },
    isDateTime?: boolean,
    originalFormat?: string
): { from: string; to: string; type: string } => {
    if (!condition) {
        return null;
    }

    const from = moment(condition.dateFrom, originalFormat);
    const to = moment(condition.dateTo, originalFormat);

    if (isDateTime) {
        const timeZone = moment.tz.guess(true);

        if (condition.type === GREATER_THAN) {
            from.endOf('day');
        }

        if (condition.type === IN_RANGE) {
            from.startOf('day');
            to.endOf('day');
        }

        from.tz(timeZone, true).utc();
        to.tz(timeZone, true).utc();
    }

    return {
        from: condition.dateFrom ? from.toISOString() : null,
        to: condition.dateTo ? to.toISOString() : null,
        type: condition.type,
    };
};
