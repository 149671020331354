import React, { useEffect, useMemo, useRef, useState, useCallback } from 'react';
import { GridReadyEvent } from 'ag-grid-community';
import AgTable from 'common/components/ag-table/ag-table';
import HeaderRightSide from 'common/components/header/header-right-side';
import HeaderWrapper from 'common/components/header/header-wrapper';
import ClearFiltersButton from 'common/components/header/clear-filters-button/clear-filters-button';
import { getFilterData, getTableData } from 'common/services/api/tabel-fetch-service';
import { DateTimeHelper } from 'common/helpers/date-time-helper';
import { dateTimeConstants } from 'common/constants/common';
import { DisplayValueHelper } from 'common/helpers/display-value-helper';
import InfiniteSelect from 'common/components/infinite-select/infinite-select';
import DropdownOption from 'common/models/dropdown-option';
import { useDispatch } from 'react-redux';
import { ThunkDispatch } from 'redux-thunk';
import { Action } from 'redux';
import { getFileActionCreator } from 'features/document-viewer/store/document-viewer-action-creators';
import { FileHelper } from 'common/helpers/file-helper';
import { LocalStoreRepository } from 'common/helpers/repository/local-store-repository';
import './styles.scss';
import { rateActions } from './constants';
import { ILegalEntityRate, ILegalEntityRateView } from 'common/services/api/rate/types';
import { IAddRateOption, IEditRateOption } from './types';
import RateCreateForm from './rate-form/rate-create-form';
import { trackPromise } from 'react-promise-tracker';
import RateService from 'common/services/api/rate/rate-servise';
import { RateTabProps } from '../types';
import RateEditForm from './rate-form/rate-edit-form';
import RateChangeForm from './rate-form/rate-change-form';
import { LocalTableRepository } from 'common/helpers/repository/local-table-repository';
import ShowAllButton from 'common/components/header/show-all-button/show-all-button';
import { useHasPermission } from 'common/hooks/use-has-permission';
import { PermissionType } from 'common/models/permission-type';
import HeaderLeftSide from 'common/components/header/header-left-side';
import CheckBoxField from 'common/components/field/checkbox-field';
import { INursingHomeEntity } from 'common/services/api/entity/types';
import nursingHomeEntityService from 'common/services/api/entity/nursing-home-entity-service';
import { FieldValue } from 'common/models/field-value';

const convertDateToFormat = (data: string, format: string): string => DateTimeHelper.format(data, format, true);
const tableRepository = new LocalTableRepository('rates-table', 'v2');
const customFiltersRepository = new LocalStoreRepository('rates-table-custom-filters');

const defaultFilters: any = {};

const showAllButtonNames = {
    ShowAll: 'Show All',
    Hide: 'Hide',
};

const EntitiesRatesTab: React.FC<RateTabProps> = ({ entityId }) => {
    const [addRateOptions, setAddRateOptions] = useState<IAddRateOption>({ isOpen: false, rateTemplate: null });
    const [editRateOptions, setEditRateOptions] = useState<IEditRateOption>({ isOpen: false, rateTemplate: null, rateData: null });
    const [changeRateOptions, setChangeRateOptions] = useState<IEditRateOption>({ isOpen: false, rateTemplate: null, rateData: null });
    const hasCanAddEditNursingHomeRates = useHasPermission(PermissionType.CanAddEditNursingHomeRates);

    const loadedFilters = useRef(customFiltersRepository.load());

    const [nursingHomeEntity, setNursingHomeEntity] = useState<INursingHomeEntity>(null);
    const [gridApi, setGridApi] = useState(null);
    const [gridColumnApi, setColumnApi] = useState(null);
    const dispatch = useDispatch<ThunkDispatch<any, any, Action>>();
    const [filters, setFilters] = useState<any>(loadedFilters.current ?? defaultFilters);

    const [showAllButtonName, setShowAllButtonName] = useState<string>(
        filters.showAllRates ? showAllButtonNames.Hide : showAllButtonNames.ShowAll
    );

    useEffect(() => {
        nursingHomeEntityService.get(entityId).then((entity) => setNursingHomeEntity(entity));
    }, [entityId]);

    const fetchOption = useMemo(
        () => ({
            handleFetchData: getTableData(`entities/${entityId}/rates`),
            handleFetchFilters: getFilterData(`entities/${entityId}/rates/filter/source`),
        }),
        [entityId]
    );

    const handleOvertimeAgreementChange = (value: FieldValue) => {
        nursingHomeEntityService.setOvertimeAgreement(entityId, value.value);
    };

    useEffect(() => {
        customFiltersRepository.save(filters);
        loadedFilters.current = filters;
    }, [filters]);

    useEffect(() => {
        if (gridApi) {
            gridApi.onFilterChanged();
        }
    }, [gridApi]);

    const handleGridReady = (params: GridReadyEvent) => {
        setGridApi(params.api);
        setColumnApi(params.columnApi);
    };

    const handleFiltersClear = () => {
        setFilters({ ...defaultFilters });
    };

    const onOptionClick = useCallback(
        (item: any) => {
            dispatch(getFileActionCreator(item.id)).then((response: any) => {
                FileHelper.downloadOrOpen(response.file);
            });
        },
        [dispatch]
    );

    const getOptionList = useRef<(rate: ILegalEntityRateView) => DropdownOption[]>();

    getOptionList.current = (rate: ILegalEntityRateView): DropdownOption[] => {
        const itemOptions = [];
        if (hasCanAddEditNursingHomeRates && !rate.rateId) {
            itemOptions.push(new DropdownOption(rateActions.Add, rateActions.Add));
        }

        if (hasCanAddEditNursingHomeRates && rate.rateId) {
            itemOptions.push(new DropdownOption(rateActions.Edit, rateActions.Edit));
        }

        if (hasCanAddEditNursingHomeRates && rate.rateId) {
            itemOptions.push(new DropdownOption(rateActions.Change, rateActions.Change));
        }

        return itemOptions;
    };

    const onAddRateClick = useCallback((rate: ILegalEntityRateView) => {
        setAddRateOptions({
            isOpen: true,
            rateTemplate: {
                id: rate.rateTemplateId,
                placementTypeName: rate.placementTypeName,
                therapyTypeName: rate.therapyTypeName,
            },
        });
    }, []);

    const onEditRateClick = useCallback((rate: ILegalEntityRateView) => {
        setEditRateOptions({
            isOpen: true,
            rateTemplate: {
                id: rate.rateTemplateId,
                placementTypeName: rate.placementTypeName,
                therapyTypeName: rate.therapyTypeName,
            },
            rateData: rate,
        });
    }, []);

    const onChangeRateClick = useCallback((rate: ILegalEntityRateView) => {
        setChangeRateOptions({
            isOpen: true,
            rateTemplate: {
                id: rate.rateTemplateId,
                placementTypeName: rate.placementTypeName,
                therapyTypeName: rate.therapyTypeName,
            },
            rateData: rate,
        });
    }, []);

    const onMenuOptionClick = useCallback(
        (action: any, rate: any) => {
            switch (action) {
                case rateActions.Add:
                    return onAddRateClick(rate);
                case rateActions.Edit:
                    return onEditRateClick(rate);
                case rateActions.Change:
                    return onChangeRateClick(rate);
                default:
                    return;
            }
        },
        [onAddRateClick, onEditRateClick, onChangeRateClick]
    );

    const handleSaveRateAddDialog = (rate: ILegalEntityRate) => {
        return trackPromise(RateService.addRate(entityId, { ...rate })).then(() => {
            gridApi?.onFilterChanged();
            handleCancelRateAddDialog();
        });
    };

    const handleSaveRateEditDialog = (rate: ILegalEntityRate) => {
        return trackPromise(RateService.editRate(entityId, { ...rate })).then(() => {
            gridApi?.onFilterChanged();
            handleCancelRateEditDialog();
        });
    };

    const handleSaveRateChangeDialog = (editRrate: ILegalEntityRate, newRate: ILegalEntityRate) => {
        return trackPromise(RateService.changeRate(entityId, editRrate, newRate)).then(() => {
            gridApi?.onFilterChanged();
            handleCancelRateChangeDialog();
        });
    };

    const handleCancelRateAddDialog = () => {
        setAddRateOptions({ isOpen: false, rateTemplate: null });
    };

    const handleCancelRateEditDialog = () => {
        setEditRateOptions({ isOpen: false, rateTemplate: null, rateData: null });
    };

    const handleCancelRateChangeDialog = () => {
        setChangeRateOptions({ isOpen: false, rateTemplate: null, rateData: null });
    };

    const handleOnShowAllButtonClick = () => {
        if (filters.showAllRates) {
            setFilters({ ...filters, showAllRates: false });
            setShowAllButtonName(showAllButtonNames.ShowAll);
        } else {
            setFilters({ ...filters, showAllRates: true });
            setShowAllButtonName(showAllButtonNames.Hide);
        }
    };

    const gridOptions = useMemo(
        () => ({
            columnDefs: [
                {
                    field: 'therapyTypeName',
                    headerName: 'Discipline',
                    width: 120,
                    filter: 'agCustomFilter',
                    sortable: false,
                },
                {
                    field: 'placementTypeName',
                    headerName: 'Type',
                    width: 208,
                    filter: 'agCustomFilter',
                    sortable: false,
                },
                {
                    field: 'rate',
                    headerName: 'Rate',
                    width: 170,
                    valueFormatter: (props: any) => DisplayValueHelper.rateEmptyOrIsSet(props.value),
                    filter: 'agCustomFilter',
                    sortable: false,
                },
                {
                    field: 'startDate',
                    headerName: 'Start Date',
                    width: 130,
                    initialSort: 'desc',
                    filter: 'agDateColumnFilter',
                    valueFormatter: (props: any) => convertDateToFormat(props.value, dateTimeConstants.MM_DD_YYYY),
                    sortable: false,
                },
                {
                    field: 'endDate',
                    headerName: 'End Date',
                    width: 130,
                    valueFormatter: (props: any) => convertDateToFormat(props.value, dateTimeConstants.MM_DD_YYYY),
                    filter: 'agDateColumnFilter',
                    sortable: false,
                },
                {
                    field: 'fileOriginalName',
                    headerName: 'Documents',
                    colId: 'Documents',
                    cellRenderer: 'documentsRenderer',
                    cellClass: 'documents-list-cell',
                    width: 136,
                    sortable: false,
                },
                {
                    field: 'createdByUserFullName',
                    headerName: 'Created By',
                    minWidth: 150,
                    width: 320,
                    flex: 1,
                    filter: 'agCustomFilter',
                    sortable: false,
                },
                {
                    field: 'dateCreation',
                    headerName: 'Date Creation',
                    width: 154,
                    valueFormatter: (props: any) => DateTimeHelper.format(props.value, dateTimeConstants.MM_DD_YYYY),
                    filter: 'agDateColumnFilter',
                    filterParams: {
                        isDateTime: true,
                    },
                    sortable: false,
                },
                {
                    field: '',
                    headerName: '',
                    colId: 'doteMenu',
                    pinned: 'right',
                    cellRenderer: 'doteRender',
                    width: 40,
                    cellClass: ['dote-cell'],
                    hideForExport: true,
                },
            ],
            suppressRowClickSelection: true,
            treeData: true,
            isServerSideGroup: (dataItem: any) => {
                return dataItem?.rates?.length > 0;
            },
            getServerSideGroupKey: function (dataItem: any) {
                return dataItem?.rates;
            },
            defaultColDef: {
                resizable: true,
                filterParams: {
                    onFetchFilters: fetchOption.handleFetchFilters,
                },
            },
            autoGroupColumnDef: {
                headerName: '',
                field: 'therapyTypeName',
                sortable: false,
                lockPosition: true,
                maxWidth: 50,
                minWidth: 50,
                cellClass: 'arrow-cell',

                cellRendererParams: {
                    innerRenderer: function (params: any) {
                        return params.data.therapyTypeName;
                    },
                },
            },
            frameworkComponents: {
                doteRender: (props: any) => {
                    const itemOptions = getOptionList.current(props.data);

                    return (
                        itemOptions.length > 0 && (
                            <InfiniteSelect
                                id="rateMenu"
                                popper
                                items={itemOptions}
                                className="no-select-border"
                                icon={<i className="icon icon-options" />}
                                onChange={(item: DropdownOption) => {
                                    onMenuOptionClick(item?.value, props.data);
                                }}
                            />
                        )
                    );
                },
                documentsRenderer: (props: any) => {
                    const files = props.data?.documents?.map((item: any) => {
                        return {
                            id: item.fileId,
                            value: item.fileName,
                            label: item.fileOriginalName,
                        };
                    });

                    return (
                        files?.length > 0 && (
                            <InfiniteSelect
                                id="entityRatesFilesDropdown"
                                popper
                                items={files}
                                className="no-select-border entity-rates-files-list"
                                onlyIcon={<i className="icon icon-dropdown" />}
                                onChange={(item: DropdownOption) => onOptionClick(item)}
                            />
                        )
                    );
                },
            },
        }),
        [fetchOption.handleFetchFilters, onOptionClick, onMenuOptionClick]
    );

    return (
        <>
            <div className="main-info-wrapper ag-table-wrapper rates-table-wrapper">
                <HeaderWrapper>
                    <HeaderLeftSide>
                        <CheckBoxField
                            classic
                            leftSide
                            disabled={hasCanAddEditNursingHomeRates === false}
                            label="Overtime Agreement Exists"
                            value={nursingHomeEntity?.overtimeAgreement}
                            onChange={handleOvertimeAgreementChange}
                        />
                    </HeaderLeftSide>
                    <HeaderRightSide>
                        <ShowAllButton title="Entities" buttonName={showAllButtonName} onClick={handleOnShowAllButtonClick} />
                        <ClearFiltersButton title="Entities" gridApi={gridApi} gridColumnApi={gridColumnApi} onClick={handleFiltersClear} />
                    </HeaderRightSide>
                </HeaderWrapper>
                <AgTable
                    onGridReady={handleGridReady}
                    gridOptions={gridOptions}
                    onFetchData={fetchOption.handleFetchData}
                    onFiltersLoadedFromUrl={handleFiltersClear}
                    customFilters={filters}
                    repository={tableRepository}
                />
            </div>

            {addRateOptions.isOpen && (
                <RateCreateForm
                    rateTemplate={addRateOptions.rateTemplate}
                    onSave={handleSaveRateAddDialog}
                    onClose={handleCancelRateAddDialog}
                ></RateCreateForm>
            )}

            {editRateOptions.isOpen && (
                <RateEditForm
                    rateTemplate={editRateOptions.rateTemplate}
                    rateData={editRateOptions.rateData}
                    onSave={handleSaveRateEditDialog}
                    onClose={handleCancelRateEditDialog}
                ></RateEditForm>
            )}

            {changeRateOptions.isOpen && (
                <RateChangeForm
                    rateTemplate={changeRateOptions.rateTemplate}
                    rateData={changeRateOptions.rateData}
                    onSave={(rate: ILegalEntityRate, newRate: ILegalEntityRate) => handleSaveRateChangeDialog(rate, newRate)}
                    onClose={handleCancelRateChangeDialog}
                ></RateChangeForm>
            )}
        </>
    );
};

export default EntitiesRatesTab;
