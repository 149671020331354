import { maskConstants } from 'common/constants/common';

export const generalValidationRules = {
    ssn: {
        format: maskConstants.SSN,
        mask: '_',
    },
    dbn: {
        maxLength: 6,
    },
    npiNumber: {
        maxLength: 10,
    },
    medicaidId: {
        maxLength: 10,
    },
    zip: {
        maxLength: 5,
    },
    phoneExtension: {
        maxLength: 6,
    },
    comment: {
        maxLength: 1000,
    },
    note: {
        maxLength: 250,
    },
    numberOfBeds: {
        maxLength: 4,
    },
    city: {
        maxLength: 30,
    },
};
