import { useHasPermission } from 'common/hooks/use-has-permission';
import { IActionOption } from 'common/models/action-option';
import { PermissionType } from 'common/models/permission-type';
import { DocumentRuleService } from 'common/services/api/document-rule/document-rule-service';
import { IAddEditDocumentRuleRequest, IDocumentRule } from 'common/services/api/document-rule/types';
import React, { useCallback, useState } from 'react';
import DocumentRuleDetailsDialog from '../../document-management/document-rules-tab/components/document-rule-details-dialog/document-rule-details-dialog';
import DocumentRulesTable from './components/document-rules-table/document-rules-table';
import AddEditDocumentRuleDialog from './components/add-edit-document-rule-dialog/add-edit-document-rule-dialog';
import { DocumentRuleDetailsDialogOptions, DocumentRulesTabProps } from './types';

const DocumentRulesTab: React.FC<DocumentRulesTabProps> = ({ isAddDocumentRuleDialogOpen, onCloseAddDocumentRuleDialog }) => {
    const [currentTableData, setTableData] = useState<IDocumentRule[]>([]);
    const [updateDocumentRules, setupdateDocumentRules] = useState(false);

    const [documentRuleDetailsDialog, setDocumentRuleDetailsDialog] = useState<DocumentRuleDetailsDialogOptions | null>(null);
    const [editDocumentRuleModel, setEditDocumentRuleModel] = useState<IDocumentRule | null>(null);

    const handleClickOnEditDocumentRule = useCallback(() => {
        const document = documentRuleDetailsDialog.documentRule;
        setDocumentRuleDetailsDialog(null);
        setEditDocumentRuleModel(document);
    }, [documentRuleDetailsDialog]);

    const hasDocumentManagementPrivilege: boolean = useHasPermission(PermissionType.DocumentManagement);

    const getActions = useCallback(() => {
        const actions: IActionOption[] = [];

        if (hasDocumentManagementPrivilege) {
            actions.push({ title: 'Edit', handler: handleClickOnEditDocumentRule });
        }

        return actions;
    }, [hasDocumentManagementPrivilege, handleClickOnEditDocumentRule]);

    const handleCloseDetailsDialog = () => {
        setDocumentRuleDetailsDialog(null);
    };

    const getIndex = useCallback(
        (documentRule: IDocumentRule) => {
            return currentTableData.findIndex((x) => x.id === documentRule.id);
        },
        [currentTableData]
    );

    const handleClickOnDocument = useCallback(
        (documentRule: IDocumentRule) => {
            const index = getIndex(documentRule);
            setupdateDocumentRules(false);
            setDocumentRuleDetailsDialog({ documentRule, currentIndex: index });
        },
        [getIndex]
    );

    const handleClickOnNextDocument = () => {
        const index = getIndex(documentRuleDetailsDialog.documentRule) + 1;
        setDocumentRuleDetailsDialog({ documentRule: currentTableData[index], currentIndex: index });
    };

    const handleClickOnPreviousDocument = () => {
        const index = getIndex(documentRuleDetailsDialog.documentRule) - 1;
        setDocumentRuleDetailsDialog({ documentRule: currentTableData[index], currentIndex: index });
    };

    const handleClickOnSaveEditDocument = (documentRule: IAddEditDocumentRuleRequest) => {
        return new DocumentRuleService().edit(editDocumentRuleModel.id, documentRule).then(() => {
            setEditDocumentRuleModel(null);
            setupdateDocumentRules(true);
        });
    };

    const handleClickOnSaveCreateDocument = (documentRule: IAddEditDocumentRuleRequest) => {
        return new DocumentRuleService().create(documentRule).then(() => {
            onCloseAddDocumentRuleDialog();
            setupdateDocumentRules(true);
        });
    };

    const getPreviousButton = () => {
        return documentRuleDetailsDialog.currentIndex > 0 ? { text: 'Previous Document', onClick: handleClickOnPreviousDocument } : null;
    };

    const getNextButton = () => {
        return documentRuleDetailsDialog.currentIndex < currentTableData.length - 1
            ? { text: 'Next Document', onClick: handleClickOnNextDocument }
            : null;
    };

    return (
        <>
            <DocumentRulesTable
                updateDocumentRules={updateDocumentRules}
                onDocumentRuleClick={handleClickOnDocument}
                onDataLoaded={setTableData}
            />

            {documentRuleDetailsDialog?.documentRule && (
                <DocumentRuleDetailsDialog
                    actions={getActions()}
                    prevButton={getPreviousButton()}
                    nextButton={getNextButton()}
                    onCancel={handleCloseDetailsDialog}
                    documentRule={documentRuleDetailsDialog.documentRule}
                />
            )}

            {editDocumentRuleModel && (
                <AddEditDocumentRuleDialog
                    title="Edit Document Rule Details"
                    onCancel={() => setEditDocumentRuleModel(null)}
                    onSave={handleClickOnSaveEditDocument}
                    defaultDocumentRule={editDocumentRuleModel}
                />
            )}

            {isAddDocumentRuleDialogOpen && (
                <AddEditDocumentRuleDialog
                    title="Add Document Rule"
                    onCancel={onCloseAddDocumentRuleDialog}
                    onSave={handleClickOnSaveCreateDocument}
                />
            )}
        </>
    );
};

export default DocumentRulesTab;
