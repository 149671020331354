import React from 'react';
import { IViewSidebar } from 'common/models/types';
import RightSideBarForm from 'common/components/right-side-bar/right-side-bar-form';
import { IEntityContactDetails } from 'common/services/api/entity-contact/type';
import { SideBarMode } from 'common/components/right-side-bar/right-side-bar';
import Card from 'common/components/card/card';
import DisplayValue from 'common/components/display-value/display-value';
import FieldListPhonesView from 'common/components/field-list/field-list-view/field-list-phones-view';
import FieldListEmailsView from 'common/components/field-list/field-list-view/field-list-email-view';
import { labels } from 'common/constants/labels';

const EntityContactSidebarInfo = ({ data, actions, onClose }: IViewSidebar<IEntityContactDetails>) => {
    if (!data) {
        return null;
    }
    const { firstName, middleName, lastName, phones, emails } = data?.contact;
    const otherRoles = data?.otherRoles.filter((role) => role);

    return (
        <RightSideBarForm title="Contact Info" onCancel={onClose} mode={SideBarMode.VIEW} actions={actions}>
            <Card className="void-card pl-34-view" noBorder titleIcon="icon-info" title="Basic Info">
                <DisplayValue id="firstName" placeholder="First Name" value={firstName} />
                <DisplayValue id="middleName" placeholder={labels.middleNameInitial} value={middleName} />
                <DisplayValue id="lastName" placeholder="Last Name" value={lastName} />
                <DisplayValue id="roles" placeholder="Roles" value={data?.roles.map((role) => role.role).join(', ') || '-'} />
                {otherRoles?.length > 0 && (
                    <DisplayValue id="other-roles" placeholder="Other Roles" value={otherRoles.map((role) => role.name).join(', ')} />
                )}
                <DisplayValue
                    id="additionalRoleInformation"
                    placeholder="Additional Role Information"
                    value={data?.additionalRoleInformation ?? '-'}
                />
            </Card>
            <Card className="void-card pl-34-view" noBorder titleIcon="icon-phone" title="Phones">
                <FieldListPhonesView phones={phones} />
            </Card>
            <Card className="void-card pl-34-view" noBorder titleIcon="icon-email" title="Email Addresses">
                <FieldListEmailsView emails={emails} />
            </Card>
        </RightSideBarForm>
    );
};

export default EntityContactSidebarInfo;
