import { Dispatch, SetStateAction, useCallback, useEffect, useState } from 'react';
const debounce = require('lodash.debounce');

type SearchInputResult = {
    search: string;
    handleSearchInputChange(entity: string): void;
    setSearch?: Dispatch<SetStateAction<string>>;
};

export function useSearchInput(searchDefault: string, callback: (value: string) => void, sleep: number = 500): SearchInputResult {
    const [search, setSearch] = useState(searchDefault);
    const updateSearchFilter = useCallback(debounce(callback, sleep), []);
    const handleSearchInputChange = useCallback(
        (value: string) => {
            setSearch(value);
            updateSearchFilter(value);
        },
        [updateSearchFilter]
    );

    return {
        search,
        handleSearchInputChange,
        setSearch,
    };
}

export function useSearchInputWithStore(store: any, callback: (value: string) => void, sleep: number = 500): SearchInputResult {
    const { search, handleSearchInputChange, setSearch } = useSearchInput(store.load() ?? '', callback, sleep);

    useEffect(() => {
        store.save(search ?? '');
    }, [search, store]);

    useEffect(() => {
        handleSearchInputChange(search);
    }, []);

    return { search, handleSearchInputChange, setSearch };
}
