import { InfiniteServerClient } from 'common/helpers/http-clients';
import {
    IRequestsGridData,
    ICreateRequestInfo,
    IRequestSetting,
    IRequestDetails,
    ICreateRequestSettingRequestModel,
    IUpdateRequestSettingRequestModel,
    AddTherapistToRequest,
    ITherapistRequestStatusForm,
    IRequestPointModel,
    IRequestDataModel,
} from './types';
import { ITopFilter } from './types';
import {
    IRequestAuthorization,
    IRequestCaseDetails,
    IRequestDeclineReason,
    IRequestReopenReason,
} from 'features/request-page/components/request-forms/types';
import moment from 'moment';
import { IUpdateAssignedTherapistsNote } from 'common/models/update-assigned-therapists-note';
import { ICreateNoteRequest } from 'common/models/create-note-request';
import { ValidationHelper } from 'common/helpers/validation-helper';

export class RequestService {
    getSettings(customerId?: number): Promise<IRequestSetting> {
        const url = `requests/settings${customerId ? '?customerId=' + customerId : ''}`;

        return InfiniteServerClient.get(url).then(
            (response) => {
                return response.data;
            },
            (error) => {
                throw error;
            }
        );
    }

    updateSettings(id: number, updateModel: IUpdateRequestSettingRequestModel): Promise<void> {
        return InfiniteServerClient.put(`requests/settings/${id}`, updateModel).then(
            (response) => {
                return response.data;
            },
            (error) => {
                throw error;
            }
        );
    }

    createSettings(createModel: ICreateRequestSettingRequestModel): Promise<IRequestSetting> {
        return InfiniteServerClient.post(`requests/settings`, createModel).then(
            (response) => {
                return response.data;
            },
            (error) => {
                throw error;
            }
        );
    }

    create(request: ICreateRequestInfo): Promise<IRequestsGridData> {
        return InfiniteServerClient.post('/requests', request).then(
            (response) => {
                return response.data;
            },
            (error) => {
                throw error;
            }
        );
    }

    getDetails(requestId: number): Promise<IRequestDetails> {
        return InfiniteServerClient.get(`/requests/details/${requestId}`).then(
            (response) => {
                return response.data;
            },
            (error) => {
                throw error;
            }
        );
    }

    // Request Group statuses
    getCountGroupStatuses(queryParams: string = ''): Promise<ITopFilter> {
        return InfiniteServerClient.get<ITopFilter>(`/requests/status/counts?${queryParams}`).then(
            (response) => {
                return response.data;
            },
            (error) => {
                throw error;
            }
        );
    }

    getLocation(requestId: number): Promise<IRequestPointModel> {
        return InfiniteServerClient.get<IRequestPointModel>(`/requests/${requestId}/map/search`).then(
            (response) => {
                return response.data;
            },
            (error) => {
                throw error;
            }
        );
    }

    setAcceptStatus(id: number) {
        return InfiniteServerClient.put(`/requests/${id}/actions/accept-request`).then(
            (response) => {
                return response.data;
            },

            (error) => {
                throw error;
            }
        );
    }

    setDeclineStatus(id: number, { reasonId, comment }: IRequestDeclineReason) {
        return InfiniteServerClient.put(`/requests/${id}/actions/decline-request`, {
            ReasonId: reasonId,
            Comment: comment,
        }).then(
            (response) => {
                return response.data;
            },

            (error) => {
                throw error;
            }
        );
    }

    setReopenStatus(id: number, { reasonId, comment }: IRequestReopenReason) {
        return InfiniteServerClient.put(`/requests/${id}/actions/reopen-request`, {
            ReasonId: reasonId,
            Comment: comment,
        }).then(
            (response) => {
                return response.data;
            },

            (error) => {
                throw error;
            }
        );
    }

    updateAuthorization(requestId: number, authorization: IRequestAuthorization): Promise<IRequestDetails> {
        const bodyFormData = new FormData();

        if (authorization?.startDate) {
            bodyFormData.append('startDate', moment(authorization.startDate).utc().format());
        }

        if (authorization?.endDate) {
            bodyFormData.append('endDate', moment(authorization.endDate).utc().format());
        }

        if (authorization?.evalId) {
            bodyFormData.append('evalId', authorization.evalId + '');
        }

        bodyFormData.append('isAuthorized', JSON.stringify(authorization?.isAuthorized));

        authorization?.files.forEach((item: any) => {
            const file = item?.value;
            if (file instanceof File) {
                bodyFormData.append('files', file);
            }
        });

        return (
            ValidationHelper.validateFile(bodyFormData) ??
            InfiniteServerClient.post(`/requests/${requestId}/authorization`, bodyFormData).then(
                (response) => {
                    return response.data;
                },
                (error) => {
                    throw error;
                }
            )
        );
    }

    updateCaseDetails(requestId: number, caseDetailsData: IRequestCaseDetails): Promise<IRequestDetails> {
        return InfiniteServerClient.put(`/requests/${requestId}/case-details`, caseDetailsData).then(
            (response) => {
                return response.data;
            },
            (error) => {
                throw error;
            }
        );
    }

    attachPatient(requestId: number, patientId: number, patientAgencyCode: string): Promise<void> {
        return InfiniteServerClient.put(`/requests/${requestId}/actions/attach-patient`, { patientId, patientAgencyCode }).then(
            (response) => {
                return response.data;
            },
            (error) => {
                throw error;
            }
        );
    }

    setStatusToTherapistRequest(requestId: number, therapistRequestId: number, statusForm: ITherapistRequestStatusForm) {
        return InfiniteServerClient.put(`/requests/${requestId}/therapists/${therapistRequestId}/status`, statusForm).then(
            (response) => {
                return response.data;
            },

            (error) => {
                throw error;
            }
        );
    }

    addNoteToTherapistRequest(requestId: number, therapistRequestId: number, note: ICreateNoteRequest) {
        return InfiniteServerClient.put(`/requests/${requestId}/therapists/${therapistRequestId}/note`, note).then(
            (response) => {
                return response.data;
            },

            (error) => {
                throw error;
            }
        );
    }

    deleteTherapistFromRequest(requestId: number, therapistId: number) {
        return InfiniteServerClient.delete(`/requests/${requestId}/therapists/${therapistId}`).then(
            (response) => {
                return response.data;
            },

            (error) => {
                throw error;
            }
        );
    }

    addTherapistToRequest(addModel: AddTherapistToRequest): Promise<void> {
        return InfiniteServerClient.post(`/requests/${addModel?.requestId}/therapists`, addModel).then(
            (response) => {
                return response.data;
            },
            (error) => {
                throw error;
            }
        );
    }

    updateCustomNote(therapistId: number, requestId: number, request: IUpdateAssignedTherapistsNote): Promise<any> {
        return InfiniteServerClient.put<any>(`requests/${requestId}/therapists/${therapistId}/note`, request || {}).then(
            (response) => {
                return response.data;
            },
            (error) => {
                throw error;
            }
        );
    }

    assignToUser(requestId: number, userId: string): Promise<void> {
        const request = { userId: userId };

        return InfiniteServerClient.put<any>(`requests/${requestId}/assigns`, request).then(
            (response) => {
                return response.data;
            },
            (error) => {
                throw error;
            }
        );
    }

    getRequestForMap(id: number): Promise<IRequestDataModel> {
        return InfiniteServerClient.get<IRequestDataModel>(`/requests/${id}/map`).then(
            (response) => {
                return response.data;
            },
            (error) => {
                throw error;
            }
        );
    }
}

export default new RequestService();
