import DropdownOption from 'common/models/dropdown-option';
import React, { useEffect, useState } from 'react';
import DefaultOptionItem from '../default-option-item/default-option-item';
import { SelectAllOptionItemProps } from './types';

const SelectAllOptionItem: React.FC<SelectAllOptionItemProps> = ({ multiselect, items, selectedValues, onSelectAll, onFormatOption }) => {
    const [isChecked, setChecked] = useState(false);

    useEffect(() => {
        setChecked(selectedValues?.length === items?.length);
    }, [selectedValues]);

    const handleSelectAll = (_item: DropdownOption) => {
        if (selectedValues?.length === items?.length) {
            onSelectAll([]);
        } else {
            onSelectAll(items);
        }
    };

    return (
        <DefaultOptionItem
            item={new DropdownOption('selectAll', '(Select All)')}
            index={-1}
            multiselect={multiselect}
            checked={isChecked}
            handleChange={handleSelectAll}
            onFormatOption={onFormatOption}
        />
    );
};

export default SelectAllOptionItem;
