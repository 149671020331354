import React, { useState } from 'react';
import PatientsList, { patientColumnDef } from './components/patients-list/patients-list';
import CreatePatientForm from './components/create-patients-form/create-patients-form';
import PatientService from 'common/services/api/patient/patient-service';
import { ICreatePatientRequest } from 'common/services/api/patient/types';
import { IState } from 'common/services/api/state/types';
import { useHistory } from 'react-router-dom';
import { NavigationRoutes } from 'models/routes/navigation-routes';
import { ITablePageSetting } from 'common/components/ag-table/types';
import { CUSTOMER } from 'common/constants/common';
import { getExportData, getFilterData, getTableData } from 'common/services/api/tabel-fetch-service';
import { useSelector } from 'react-redux';

const PatientsExternalPage = () => {
    const [patientPageSettings, setPatientPageSettings] = useState<ITablePageSetting>({
        isCreateDialogOpen: false,
        updateTable: false,
    });
    const history = useHistory();
    const [isCreateDialogOpen, setIsCreateDialogOpen] = useState(false);
    const [defaultState] = useState<IState>(null);
    const entityId = useSelector((state: any) => state.auth.entity?.id);

    const extendFetchers = {
        handleFetchData: getTableData('patients'),
        handleFetchFilters: getFilterData(`patients/filter/source?customerId=${entityId}`),
        handleExportData: getExportData('patients/export'),
    };

    const savePatient = (patient: ICreatePatientRequest) => {
        return PatientService.create(patient).then((patient) => {
            history.push({ pathname: NavigationRoutes.patientDetailsRoute(patient.id) });
            setPatientPageSettings({ isCreateDialogOpen: false, updateTable: true });
        });
    };

    return (
        <div className="table-page">
            <header>
                <div className="header-wrapper">
                    <div className="title-wrapper">
                        <h1>Patients</h1>
                    </div>
                    <button
                        className="create-patients-btn base-text-btn blue"
                        id="createPatientsBtn"
                        onClick={() => setIsCreateDialogOpen(true)}
                    >
                        New Patient
                    </button>
                </div>
            </header>
            <PatientsList
                updateTable={patientPageSettings.updateTable}
                storePrefix="external-patients"
                extendGridOptions={{
                    columnDefs: externalPatientColumnDef,
                }}
                extendDefaultCustomFilters={{ entityId: entityId }}
                extendFetchers={extendFetchers}
                serachTitle="Entity Patients"
            />
            {isCreateDialogOpen && (
                <CreatePatientForm
                    onClose={() => setIsCreateDialogOpen(false)}
                    onSave={savePatient}
                    defaultState={defaultState}
                    hideCustomer={true}
                    dataForCreation={{
                        customerIdForCreation: entityId,
                    }}
                />
            )}
        </div>
    );
};

const externalPatientColumnDef = [...patientColumnDef];
const indexCustomerName = patientColumnDef.findIndex((columnConfig) => columnConfig.field === CUSTOMER);
if (indexCustomerName > -1) {
    externalPatientColumnDef.splice(indexCustomerName, 1);
}

export default PatientsExternalPage;
