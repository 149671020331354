import { IEntityAddress } from '../services/api/entity/types';

export const hasAddress = (addressObject: any): boolean => {
    return (
        addressObject?.address1 ||
        addressObject?.address2 ||
        addressObject?.city ||
        addressObject?.state ||
        addressObject?.zip ||
        addressObject?.postalCode ||
        addressObject?.country
    );
};

const _formatPartOfAddress = (addressPart: string) => (addressPart ? addressPart + ', ' : '');

export const facilityAddressToString = (facilityAddress: IEntityAddress) => {
    const { address1, address2, city, state, postalCode } = facilityAddress || {};
    const abbreviation = state?.abbreviation ? state?.abbreviation + ' ' : '';

    return (
        _formatPartOfAddress(address1) +
        _formatPartOfAddress(address2) +
        _formatPartOfAddress(city) +
        _formatPartOfAddress(abbreviation + (postalCode ?? ''))
    ).replace(/,\s*$/, '');
};
