import React, { useEffect, useRef, useState } from 'react';
import './zoom.scss';

const Zoom = () => {
    const [zoom, setZoom] = useState(getZoom());

    const handleMinusZoom = () => {
        setZoom((prevZoom) => {
            const newZoom = prevZoom - 5;

            return newZoom > 20 ? newZoom : 20;
        });
    };

    const handlePlusZoom = () => {
        setZoom((prevZoom) => {
            const newZoom = prevZoom + 5;

            return newZoom < 200 ? newZoom : 200;
        });
    };

    const calcPageHeight = useRef(null);
    const [zoomScale, setZoomScale] = useState(1);
    calcPageHeight.current = () => {
        let innerHeight;
        let scale = 1;

        // @ts-ignore
        document.body.style.zoom = zoom + '%';
        if (zoom !== 100) {
            scale = 1 / (zoom / 100);
            innerHeight = scale * window.innerHeight + 'px';
            document.body.style.overflow = 'hidden';
        } else {
            innerHeight = '';
            document.body.style.overflow = '';
        }
        document.body.style.height = innerHeight;
        setHeight('#root', innerHeight);

        setZoomScale(scale);
        localStorage.setItem(pageZoom, zoom + '');
        localStorage.setItem(pageScale, scale + '');

        window.dispatchEvent(new CustomEvent('zoomChanged', { detail: { scale, zoom } } as any));

        return () => {
            // @ts-ignore
            document.body.style.zoom = '';
            document.body.style.height = '';
            setHeight('#root', '');
        };
    };

    useEffect(calcPageHeight.current, [zoom]);

    useEffect(() => {
        const onResize = () => {
            calcPageHeight.current();
        };
        const onTabFocused = () => {
            setZoom(getZoom());
        };

        window.addEventListener('resize', onResize);
        window.addEventListener('focus', onTabFocused);

        return () => {
            window.removeEventListener('resize', onResize);
            window.removeEventListener('focus', onTabFocused);
        };
    }, []);

    const calculatePixels = (value: number): string => zoomScale * value + 'px';

    return (
        <div
            className="zoomer"
            style={{
                width: calculatePixels(112),
                height: calculatePixels(32),
                fontSize: calculatePixels(13),
                bottom: calculatePixels(1),
                right: calculatePixels(24),
                borderRadius: calculatePixels(7),
            }}
        >
            <button
                className="zoomer__btn zoomer__minus"
                style={{
                    fontSize: calculatePixels(14),
                }}
                onClick={handleMinusZoom}
            >
                <span className="icon icon-minus" />
            </button>
            <span className="zoomer__count">{zoom}%</span>
            <button
                className="zoomer__btn zoomer__plus"
                style={{
                    fontSize: calculatePixels(14),
                }}
                onClick={handlePlusZoom}
            >
                <span className="icon icon-add" />
            </button>
        </div>
    );
};

const pageZoom = 'pageZoom';
const pageScale = 'pageScale';

const setHeight = (query: string, height: string) => {
    const element = document.querySelector(query) as any;
    if (element && element.style) {
        element.style.height = height;
    }
};

export const getZoom = (): number => {
    const zoom = parseInt(window.localStorage.getItem(pageZoom));

    return Number.isNaN(zoom) ? 100 : zoom;
};

export const getScale = (): number => {
    const scale = parseFloat(window.localStorage.getItem(pageScale));

    return Number.isNaN(scale) ? 1 : scale;
};

export default Zoom;
