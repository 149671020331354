import React from 'react';
import InfiniteInput from '../infinite-input/infinite-input';
import { DisplayValueHelper } from 'common/helpers/display-value-helper';
import ReactInputMask, { BeforeMaskedStateChangeStates } from 'react-input-mask';

function PhoneInput(props: any) {
    const { value, onChange, ...propsToPass } = props;
    const emptyMaskValue = '(___) ___-____';

    const onBeforeMaskedValueChange = ({ nextState }: BeforeMaskedStateChangeStates) => {
        if (nextState.value === emptyMaskValue) {
            return { ...nextState, value: '' };
        }

        return nextState;
    };

    return (
        <ReactInputMask
            value={value}
            beforeMaskedStateChange={onBeforeMaskedValueChange}
            mask="(999) 999-9999"
            onChange={(event) => onChange(DisplayValueHelper.unmaskPhone(event.target.value))}
        >
            <InfiniteInput {...propsToPass}></InfiniteInput>
        </ReactInputMask>
    );
}

export default PhoneInput;
