import Tab from 'common/components/tabs/tab';
import Tabs from 'common/components/tabs/tabs';
import DropdownOption from 'common/models/dropdown-option';
import entityService from 'common/services/api/entity/entity-service';
import { IEntityDetails } from 'common/services/api/entity/types';
import EntityHeaderInfo from 'features/shared/entity-header-info/entity-header-info';
import React, { useEffect, useRef, useState } from 'react';
import { useParams } from 'react-router-dom';
import ChildEntitiesListTab from './child-entities/child-entities-list-tab';
import EntityGeneralInfoTab from './general-info-tab/entity-general-info-tab';
import { IEntityDetailsContext, IEntitiesParamType } from './types';
import { ILinkItem } from 'common/components/Breadcrumb/types';
import BreadcrumbLinks from 'common/components/Breadcrumb/breadcrumb-links';
import { trackPromise } from 'react-promise-tracker';
import EntityRequestListTab from './requests-tab/entity-request-list';
import EntityPatintsTab from './patients-tab/patients-tab';
import { entityTypeKey } from 'common/constants/entity-type';
import EntitiesRatesTab from './rates-tab/entity-rates-page';
import NursingHomeCustomRatesTable from 'features/nursing-home-requests-page/components/nursing-home-custom-rates-table';
import './entity-details-page.scss';
import EntityNursingHomeRequestListTab from './requests-tab/entity-nursing-home-request-list';
import { IToggleFeatureList } from 'common/services/api/settings/types';
import { useSelector } from 'react-redux';
import { useHasPermission } from 'common/hooks/use-has-permission';
import { PermissionType } from 'common/models/permission-type';
import PhysiciansList from '../../../physicians-page/components/physicians-list';
import { NavigationRoutes } from 'models/routes/navigation-routes';
import ServerNavigation from 'common/components/details-navigation/server-navigation';

const EntityDetailsPage = () => {
    const [details, setDetails] = useState<IEntityDetails>(null);
    const headerRef = useRef<any>();
    const entityId = Number.parseInt(useParams<IEntitiesParamType>()?.entityId);
    const isDoctorOfficeEntity = details?.functionLinks?.some((type) => type?.legalFunction?.key === entityTypeKey.DO);
    const isNursingHomeEntity = details?.functionLinks?.some((type) => type?.legalFunction?.key === entityTypeKey.NH);
    const showPatientTab = details?.functionLinks?.some(
        (type) => type?.legalFunction?.key === entityTypeKey.CHHA || type?.legalFunction?.key === entityTypeKey.PS
    );
    const hasViewNursingHomeRates = useHasPermission(PermissionType.CanViewNursingHomeRates) && isNursingHomeEntity;

    const featureList: IToggleFeatureList = useSelector((state: any) => state.siteSettings?.settings?.features || {});

    useEffect(() => {
        if ((!details && entityId) || details?.id !== entityId) {
            trackPromise(entityService.getDetails(entityId)).then((data) => setDetails(data));
        }
    }, [details, entityId]);

    const updateEntityStatus = (id: number, status: DropdownOption) => {
        trackPromise(entityService.setEntityStatus(id, status.value)).then(() => {
            trackPromise(entityService.getDetails(entityId)).then((data) => setDetails(data));
        });
    };

    return (
        <EntityDetailsContext.Provider value={{ entityDetails: details, setEntityDetails: setDetails }}>
            <div className="details entity">
                <header ref={headerRef}>
                    {featureList.isDetailsNavigationEnabled && (
                        <ServerNavigation
                            entityApiBaseUrl="entities"
                            entityId={entityId}
                            navigationRoute={NavigationRoutes.entityDetailsRoute}
                        />
                    )}
                    <div className="header-mock">{<BreadcrumbLinks items={items} />}</div>
                    {details && <EntityHeaderInfo details={details} handleChangeStatus={updateEntityStatus} />}
                </header>
                <Tabs orientation="horizontal">
                    <Tab linkClassName={'custom-link'} text="General Info">
                        {details && <EntityGeneralInfoTab id={entityId} details={details} />}
                    </Tab>
                    <Tab linkClassName={'custom-link'} text="Requests">
                        {details && isNursingHomeEntity ? (
                            <EntityNursingHomeRequestListTab id={entityId} details={details} />
                        ) : (
                            details && <EntityRequestListTab id={entityId} details={details} />
                        )}
                    </Tab>
                    <Tab linkClassName={'custom-link'} text="Child Entities">
                        {details && <ChildEntitiesListTab id={entityId} details={details} />}
                    </Tab>
                    <Tab linkClassName={`custom-link`} text="Patients" isHide={!showPatientTab}>
                        {details && <EntityPatintsTab id={entityId} details={details} />}
                    </Tab>
                    <Tab linkClassName={`custom-link`} text="Rates" isHide={!hasViewNursingHomeRates || !featureList.isEntityRateEnabled}>
                        <div className="rates-wrapper">
                            <EntitiesRatesTab entityId={entityId} />
                            <NursingHomeCustomRatesTable entityId={entityId} />
                        </div>
                    </Tab>
                    {isDoctorOfficeEntity && (
                        <Tab linkClassName={`custom-link`} text="Physicians" isHide={!isDoctorOfficeEntity}>
                            <PhysiciansList extendDefaultCustomFilters={{ entityId }} storePrefix={'entity'} />
                        </Tab>
                    )}
                </Tabs>
            </div>
        </EntityDetailsContext.Provider>
    );
};

const items: ILinkItem[] = [
    { to: '/entities', label: 'Entities' },
    { to: '', label: 'Entity Details' },
];

export const EntityDetailsContext = React.createContext<IEntityDetailsContext>(null);

export default EntityDetailsPage;
