import { InfiniteServerClient } from 'common/helpers/http-clients';
import { SequenceHelper } from 'common/helpers/sequence-helper';
import { IPatientContactForm, IPatientContactDetails } from './types';

export class PatientContactService {
    createContact(patientId: number, patientFormData: IPatientContactForm) {
        SequenceHelper.setSequenceItems(patientFormData?.phones);
        SequenceHelper.setSequenceItems(patientFormData?.emails);

        return InfiniteServerClient.post(`/patients/${patientId}/contacts`, patientFormData).then(
            (response) => {
                return response.data;
            },
            (error) => {
                throw error;
            }
        );
    }

    archive(patientId: number, contactId: number) {
        return InfiniteServerClient.put(`/patients/${patientId}/contacts/${contactId}/status`, {
            status: 'Archived',
        }).then(
            (response) => {
                return response.data;
            },
            (error) => {
                throw error;
            }
        );
    }

    getContactDetails(patientId: number, contactId: number): Promise<IPatientContactDetails> {
        return InfiniteServerClient.get(`/patients/${patientId}/contacts/${contactId}/details`).then(
            (response) => {
                return {
                    ...response.data,
                    contact: {
                        ...response.data?.contact,
                        emails: SequenceHelper.getSequenceItems(response.data?.contact?.emails),
                        phones: SequenceHelper.getSequenceItems(response.data?.contact?.phones),
                    },
                };
            },
            (error) => {
                throw error;
            }
        );
    }

    editContact(patientId: number, contactId: number, patientFormData: IPatientContactForm) {
        SequenceHelper.setSequenceItems(patientFormData?.phones);
        SequenceHelper.setSequenceItems(patientFormData?.emails);

        return InfiniteServerClient.put(`/patients/${patientId}/contacts/${contactId}`, patientFormData).then(
            (response) => {
                return response.data;
            },
            (error) => {
                throw error;
            }
        );
    }
}

export default new PatientContactService();
