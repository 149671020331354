import React, { useCallback } from 'react';
import { IAddEditInterviewModel, IInterview, LocationType } from 'common/services/api/communication/types';
import { IFormSidebar } from 'common/models/types';
import useFormSidebar from 'common/hooks/use-form-sidebar';
import RightSideBarForm from 'common/components/right-side-bar/right-side-bar-form';
import AddEditInterviewForm from '../add-edit-interview-form/add-edit-interview-form';
import moment from 'moment-timezone';
import DropdownOption from 'common/models/dropdown-option';
import { AuthErrorCodes } from 'common/helpers/ms-auth/types';
import { msAuth } from 'common/helpers/ms-auth/ms-auth';
import { toast } from 'react-toastify';
import { IMSGraphSettings } from 'common/services/api/settings/types';
import { useSelector } from 'react-redux';

const AddEditInterviewDialog = ({ onClose, onSave, data: interview }: IFormSidebar<IAddEditInterviewModel, IInterview>) => {
    const msSettings = useSelector<any, IMSGraphSettings>((state: any) => state?.siteSettings?.settings?.microsoftGraph);
    const [data, error, handleChangeData, handleCancelClick, handleSaveClick, , setErrorData] = useFormSidebar<IAddEditInterviewModel>(
        {
            location: interview?.location || LocationType.Office,
            hostId: (interview?.host?.id as string) || '',
            duration: interview?.duration,
            date: interview?.dateTime ? moment(interview.dateTime).local().format('MM/DD/YYYY hh:mm A') : undefined,
            time: interview?.dateTime ? moment.utc(interview.dateTime).local().format('HH:mm') : undefined,
            meetingLink: interview?.meetingLink,
            password: interview?.password,
            participants: interview?.participants?.map((i) => i.id) as string[],
            instructions: interview?.instructions,
            outcome: interview?.outcome,

            selectedHost: interview?.host ? new DropdownOption(interview.host.id, interview.host.fullName) : null,
            selectedParticipantsOptions: interview?.participants?.map((i) => new DropdownOption(i.id, i.fullName)),
        },
        {
            onClose,
            onSave: (data: IAddEditInterviewModel) => {
                const { date, time, duration, ...restData } = data;

                const dateTime = moment(date).local(true);
                const timeMoment = moment(time, 'HH:mm').local(true);

                dateTime.set({ hour: timeMoment.get('hour'), minute: timeMoment.get('minute') });

                if (data.location === LocationType.Virtual && !new RegExp('https?://').test(restData.meetingLink)) {
                    setErrorData({
                        meetingLink: ['Please supply a link starting with https://'],
                    });

                    return Promise.reject();
                }

                const fromData = {
                    dateTime:
                        dateTime && time?.length > 0 && dateTime?.isValid() ? dateTime.utc()?.format('MM/DD/YYYY hh:mm A') : undefined,
                    duration: duration ? duration : undefined,
                    ...restData,
                };

                const handleOnError = (error: any) => {
                    if (error?.response?.data?.errorCode === AuthErrorCodes.InvalidMSAuthCode) {
                        msAuth.loginPopup({ ...msSettings }).then(() => {
                            onSave(fromData).catch(() => {
                                if (error?.response?.data?.errorCode === AuthErrorCodes.InvalidMSAuthCode) {
                                    toast.error('Please login into your Microsoft account for sending an email');
                                }
                            });
                        });
                    }

                    throw error;
                };

                return onSave(fromData).catch(handleOnError);
            },
        }
    );

    const generateTitle = useCallback(() => {
        return interview ? 'Edit Interview' : 'Add Interview';
    }, [interview]);

    return (
        <div className="add-interview-sidebar">
            <RightSideBarForm title={generateTitle()} onCancel={handleCancelClick} onSave={handleSaveClick}>
                <AddEditInterviewForm data={data} errors={error} onChange={handleChangeData} />
            </RightSideBarForm>
        </div>
    );
};

export default AddEditInterviewDialog;
