import { ColDef } from 'ag-grid-community/dist/lib/entities/colDef';
import { DateTimeHelper } from 'common/helpers/date-time-helper';
import { PATIENT_AGENCY_CODE, PATIENT_NAME } from 'common/constants/common';

export const requestExternalColumnDefs: ColDef[] = [
    {
        field: 'createdAt',
        headerName: 'Creation Date',
        valueFormatter: (props: any) => DateTimeHelper.format(props.value, 'MM/DD/YYYY'),
        filter: 'agDateColumnFilter',
        initialSort: 'desc',
        filterParams: {
            isDateTime: true,
        },
        width: 154,
    },
    {
        field: 'therapyType',
        headerName: 'Therapy Type',
        filter: 'agCustomFilter',
        width: 148,
        filterParams: {
            isNullable: true,
        },
    },
    {
        field: 'zipCode',
        headerName: 'Zip Code',
        filter: 'agCustomFilter',
        width: 113,
        filterParams: {
            isNullable: true,
        },
    },
    {
        field: PATIENT_NAME,
        headerName: 'Patient Name',
        filter: null,
        width: 350,
        cellRenderer: 'objectLinkValueRender',
        filterParams: {
            isNullable: true,
        },
    },
    {
        field: PATIENT_AGENCY_CODE,
        headerName: 'Patient ID',
        filter: 'agCustomFilter',
        width: 140,
        filterParams: {
            isNullable: true,
        },
    },
    {
        field: 'source',
        headerName: 'Source of Request',
        filter: 'agCustomFilter',
        width: 188,
        filterParams: {
            isNullable: true,
        },
    },
    {
        field: 'population',
        headerName: 'Population',
        filter: 'agCustomFilter',
        width: 140,
        filterParams: {
            isNullable: true,
        },
    },
    {
        field: 'authorizationStatus',
        headerName: 'Authorization Status',
        valueFormatter: (props: any) => props?.value?.value,
        filter: 'agCustomFilter',
        width: 207,
    },
    {
        field: 'status',
        headerName: 'Status',
        pinned: 'right',
        cellRenderer: 'statusRender',
        filter: 'agCustomFilter',
        cellClass: 'd-flex',
        width: 115,
    },
];
