import { InfiniteServerClient } from 'common/helpers/http-clients';
import { ICounty } from './types';

export class CountyService {
    // TODO Remove this method and use getAllCounties
    getByState(stateId: number): Promise<ICounty[]> {
        return InfiniteServerClient.get<ICounty[]>(`counties/${stateId}/all`).then(
            (response) => {
                return response.data;
            },
            (error) => {
                throw error;
            }
        );
    }

    // TODO Remove this method and use getAllCounties
    getByStateWithPagination(stateId: number, term?: string, skip?: number, take?: number): Promise<ICounty[]> {
        return InfiniteServerClient.get<ICounty[]>(`counties/${stateId}`, { params: { term, skip, take } }).then(
            (response) => {
                return response.data;
            },
            (error) => {
                throw error;
            }
        );
    }

    getAllCounties(): Promise<ICounty[]> {
        return InfiniteServerClient.get<ICounty[]>(`counties`).then(
            (response) => {
                return response.data;
            },
            (error) => {
                throw error;
            }
        );
    }
}

export default new CountyService();
