import { IContactWithRoleInfo } from './../../types';
import DropdownOption from 'common/models/dropdown-option';
import { IFormItem } from 'common/models/types';

export type ContactInfoFormProps = {
    contactInfo?: IContactWithRoleInfo;
    errors?: any;
    options: ContactInfoFormOptions;
    onChange: (data: IFormItem) => void;
};

export type ContactInfoFormOptions = {
    contactRoles: DropdownOption[];
    entityPhoneTypes: DropdownOption[];
};

export enum CustomerErrorCodes {
    CustomerUniqueConstraintFailed = 'CUSTOMER_UNIQUE_CONSTRAINT_FAILED',
}

export enum EntityErrorCodes {
    EntityUniqueConstraintFailed = 'ENTITY_UNIQUE_CONSTRAINT_FAILED',
}
