import { Reducer } from '@reduxjs/toolkit';
import { DocumentRulesActionsTypes, IDocumentRuleState } from './types';

const initialState: IDocumentRuleState = {
    data: [],
};

export const documentRuleReducer: Reducer<IDocumentRuleState, DocumentRulesActionsTypes> = (state = initialState, action) => {
    switch (action.type) {
        case 'SET_DOCUMENT_RULES':
            return { ...state, data: action.data };
        default:
            return state;
    }
};
