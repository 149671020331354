import FieldSet from 'common/components/field-set/field-set';
import InfiniteSelect from 'common/components/infinite-select/infinite-select';
import DropdownOption from 'common/models/dropdown-option';
import { IDepartment } from 'common/services/api/department/types';
import React, { useEffect, useState } from 'react';
import { getWeekOptions } from '../types';
import { AvailableTherapistFilterConfig } from './types';

type AvailableTherapistFilterProps = {
    filters: AvailableTherapistCustomFilters;
    onFiltersChange: (filter: AvailableTherapistCustomFilters) => void;
    config: AvailableTherapistFilterConfig;
    departments: IDepartment[];
};

type AvailableTherapistCustomFilters = {
    assignmentStatus: string;
    week: string;
    department?: number;
    subDepartment?: number;
};

export const AvailableTherapistCustomFilter = ({ filters, onFiltersChange, config, departments }: AvailableTherapistFilterProps) => {
    const statusesOptions = [new DropdownOption('Active', 'Active'), new DropdownOption('All', 'All')];

    const [departmentOptions, setDepartmentOptions] = useState<DropdownOption[]>([]);
    const [subDepartmentOptions, setSubDepartmentOptions] = useState<DropdownOption[]>([]);

    const handleFieldChange = (data: any) => {
        onFiltersChange({ ...filters, [data.name]: data.value });
    };

    const weekOptions = getWeekOptions();

    useEffect(() => {
        if (departments.length > 0) {
            setDepartmentOptions(departments.map((i) => new DropdownOption(i.id, i.name)));
        }
    }, [departments]);

    const handleDepartmentChange = (item: DropdownOption) => {
        onFiltersChange({ ...filters, department: item.value, subDepartment: null });
    };

    useEffect(() => {
        if (!filters.department) {
            return;
        }

        const subDepartments = departments
            .find((i) => i.id === filters.department)
            ?.subDepartments?.map((s) => new DropdownOption(s.id, s.name));

        setSubDepartmentOptions(subDepartments);
    }, [departments, filters.department]);

    return (
        <>
            {config.department && departmentOptions?.length > 0 && (
                <FieldSet name="department">
                    <InfiniteSelect
                        id="departmentSelect"
                        items={departmentOptions}
                        label="Department"
                        className="available-therapist-filter__department"
                        defaultMultipleSelectLabel="All Department"
                        value={departmentOptions.find((item) => filters.department === item.value)}
                        onChange={handleDepartmentChange}
                    />
                </FieldSet>
            )}
            {config.subDepartment && subDepartmentOptions?.length > 0 && (
                <FieldSet name="sub-department">
                    <InfiniteSelect
                        id="subDepartmentSelect"
                        items={subDepartmentOptions}
                        label="Sub Department"
                        className="available-therapist-filter__department"
                        defaultMultipleSelectLabel="All Department"
                        value={subDepartmentOptions.find((item) => filters.subDepartment === item.value)}
                        onChange={(item: DropdownOption) => handleFieldChange({ name: 'subDepartment', value: item.value })}
                    />
                </FieldSet>
            )}
            <FieldSet name="status" customClass="available-therapist-header-select status">
                <InfiniteSelect
                    id="assignmentStatus"
                    items={statusesOptions}
                    label="Assignment Status"
                    className="available-therapist-filter__status"
                    value={statusesOptions.find((item) => filters.assignmentStatus === item.value)}
                    onChange={(item: DropdownOption) => handleFieldChange({ name: 'assignmentStatus', value: item.value })}
                />
            </FieldSet>
            <FieldSet name="week" customClass="available-therapist-header-select week">
                <InfiniteSelect
                    id="week"
                    items={weekOptions}
                    label="Week"
                    className="available-therapist-filter__week"
                    value={weekOptions.find((item) => filters.week === item.value)}
                    onChange={(item: DropdownOption) => handleFieldChange({ name: 'week', value: item.value })}
                    iconClass="icon-calendar"
                />
            </FieldSet>
        </>
    );
};
