class DropdownOption {
    constructor(value, label, className = undefined, id = undefined, zipCode = undefined) {
        this.value = value;
        this.label = label;
        this.className = className;
        this.id = id;
        this.zipCode = zipCode;
    }
}

export default DropdownOption;
