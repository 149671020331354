import { ColDef } from 'ag-grid-community/dist/lib/entities/colDef';
import { setColumnFilterParams, UTCDateFilterParams } from 'common/helpers/ag-helper';

export const schoolRequestColumnDefs: ColDef[] = [
    {
        field: 'schoolYear',
        headerName: 'School Year',
        width: 200,
        filter: 'agSetColumnFilter',
        filterParams: {
            ...setColumnFilterParams,
            isNullable: false,
        },
    },
    {
        field: 'caseId',
        headerName: 'Case ID',
        filter: 'agSetColumnFilter',
        filterParams: setColumnFilterParams,
        minWidth: 250,
    },
    {
        field: 'dbn',
        headerName: 'DBN',
        filter: 'agSetColumnFilter',
        filterParams: setColumnFilterParams,
        minWidth: 150,
        getQuickFilterText: (params) => params.value,
    },
    {
        field: 'bidRank',
        headerName: 'Bid Rank',
        filter: 'agSetColumnFilter',
        filterParams: setColumnFilterParams,
        width: 154,
    },
    {
        field: 'daysOpen',
        headerName: 'Days Open',
        filter: 'agSetColumnFilter',
        filterParams: setColumnFilterParams,
        width: 150,
    },
    {
        field: 'therapy',
        headerName: 'Therapy',
        filter: 'agSetColumnFilter',
        filterParams: setColumnFilterParams,
        width: 154,
    },
    {
        field: 'stdnts',
        headerName: 'Students',
        width: 150,
        filter: 'agSetColumnFilter',
        filterParams: setColumnFilterParams,
    },
    {
        field: 'lang',
        headerName: 'Lang',
        width: 150,
        filter: 'agSetColumnFilter',
        filterParams: setColumnFilterParams,
    },
    {
        field: 'days',
        headerName: 'Days',
        width: 113,
        filter: 'agSetColumnFilter',
        filterParams: setColumnFilterParams,
    },
    {
        field: 'startDate',
        headerName: 'Start Date',
        width: 155,
        filter: 'agDateColumnFilter',
        filterParams: UTCDateFilterParams,
    },
    {
        field: 'endDate',
        headerName: 'End Date',
        width: 155,
        filter: 'agDateColumnFilter',
        filterParams: UTCDateFilterParams,
    },
    {
        field: 'placeOfService',
        headerName: 'Place Of Service',
        width: 300,
        filter: 'agSetColumnFilter',
        filterParams: setColumnFilterParams,
        getQuickFilterText: (params) => params.value,
    },
    {
        field: 'priorityLevel',
        headerName: 'Priority',
        width: 113,
        filter: 'agSetColumnFilter',
        filterParams: setColumnFilterParams,
    },
    {
        field: 'populations',
        headerName: 'Populations',
        width: 113,
        filter: 'agSetColumnFilter',
        filterParams: {
            ...setColumnFilterParams,
            isNullable: false,
        },
        hide: true,
        columnGroupShow: 'hide',
    },
    {
        field: 'assignedTo',
        headerName: 'Assigned To',
        width: 200,
        filter: 'agSetColumnFilter',
        filterParams: setColumnFilterParams,
    },
    {
        field: 'assignedBy',
        headerName: 'Assigned By',
        width: 200,
        filter: 'agSetColumnFilter',
        filterParams: setColumnFilterParams,
    },
    {
        field: 'assignedDate',
        headerName: 'Assigned Date',
        width: 200,
        filter: 'agSetColumnFilter',
        filterParams: setColumnFilterParams,
    },
    {
        field: 'status',
        headerName: 'Status',
        pinned: 'right',
        cellRenderer: 'statusRender',
        filter: 'agCustomFilter',
        cellClass: 'd-flex',
        width: 150,
    },
];
