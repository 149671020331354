import React from 'react';
import { IRequestDeclineReason } from './types';
import { IFormSidebar } from 'common/models/types';
import RequestDeclineForm from './request-decline-form';
import useFormSidebar from 'common/hooks/use-form-sidebar';
import RightSideBarForm from 'common/components/right-side-bar/right-side-bar-form';

const RequestDeclineSidebar = ({ onClose, onSave, options }: IFormSidebar<IRequestDeclineReason, null>) => {
    const [data, error, handleChangeData, handleCancelClick, handleSaveClick] = useFormSidebar<IRequestDeclineReason>(
        {
            reasonId: 0,
            comment: null,
        },
        { onClose, onSave }
    );

    return (
        <RightSideBarForm saveText="Decline" title="Decline the Request" onCancel={handleCancelClick} onSave={handleSaveClick}>
            <RequestDeclineForm data={data} errors={error} onChange={handleChangeData} options={options} />
        </RightSideBarForm>
    );
};

export default RequestDeclineSidebar;
