import React from 'react';
import { VerticalTabWrapperProps } from './types';

const VerticalTabWrapper: React.FC<VerticalTabWrapperProps> = ({ onEdit = undefined, children }) => {
    return (
        <>
            <div className="field-data-wrapper">
                <div className="field-data">{children}</div>
                {onEdit && (
                    <div className="actions">
                        <button id="edit-button" className="edit-button base-text-button-with-icon" onClick={onEdit}>
                            <span className="icon-edit icon"></span>
                            Edit
                        </button>
                    </div>
                )}
            </div>
        </>
    );
};

export default VerticalTabWrapper;
