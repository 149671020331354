import AgTable from 'common/components/ag-table/ag-table';
import { IParamsWatcher } from 'common/components/ag-table/types';
import { ExtentedDefaultTableProps, IDefaultFilters } from 'common/constants/types';
import { DateTimeHelper } from 'common/helpers/date-time-helper';
import { LocalStoreRepository } from 'common/helpers/repository/local-store-repository';
import { LocalTableRepository } from 'common/helpers/repository/local-table-repository';
import { getTableData } from 'common/services/api/tabel-fetch-service';
import React, { useEffect, useMemo, useRef, useState } from 'react';

const handleFetchData = getTableData('full-fill/therapists/patients');

const PatientsInZipTable: React.FC<ExtentedDefaultTableProps> = ({ storePrefix = '', extendGridOptions, extendDefaultCustomFilters }) => {
    const tableRepository = useMemo(() => new LocalTableRepository(`${storePrefix}-patients-in-zip-table`, 'v3'), [storePrefix]);
    const customFiltersRepository = useMemo(() => new LocalStoreRepository(`${storePrefix}-patients-in-zip-table-custom-filters`), [
        storePrefix,
    ]);
    const loadedFilters = useRef<IDefaultFilters>(customFiltersRepository.load());

    const defaultFilters: IDefaultFilters = {
        search: null,
    };

    if (extendDefaultCustomFilters) {
        Object.keys(extendDefaultCustomFilters).forEach((key) => {
            defaultFilters[key] = extendDefaultCustomFilters[key];
            if (loadedFilters.current) {
                loadedFilters.current[key] = extendDefaultCustomFilters[key];
            }
        });
    }

    const [filters] = useState(loadedFilters.current ?? defaultFilters);
    const paramsWatcher = useRef<IParamsWatcher>();

    useEffect(() => {
        customFiltersRepository.save(filters);
        loadedFilters.current = filters;
    }, [filters, customFiltersRepository]);

    const gridOptions = useMemo(
        () => ({
            ...extendGridOptions,
            columnDefs: extendGridOptions?.columnDefs ?? PatientsInZipColumnDefs,
            frameworkComponents: {},
        }),
        [extendGridOptions]
    );

    return (
        <>
            <div className="main-info-wrapper ag-table-wrapper patients-zip-table">
                <AgTable
                    getAllData
                    gridOptions={gridOptions}
                    repository={tableRepository}
                    customFilters={filters}
                    paramsWatcher={paramsWatcher}
                    onFetchData={handleFetchData}
                    disableShadow={true}
                />
            </div>
        </>
    );
};

export const PatientsInZipColumnDefs = [
    {
        field: 'id',
        headerName: 'Patient ID',
        width: 120,
    },
    {
        field: 'scheduledDate',
        headerName: 'Scheduled Date',
        valueFormatter: (props: any) => DateTimeHelper.format(props.value, 'MM/DD/YYYY'),
        width: 170,
    },
    {
        field: 'name',
        headerName: 'Patient Name',
        width: 270,
        initialSort: 'asc',
    },
    {
        field: 'address',
        headerName: 'Address',
        width: 300,
    },
];

export default PatientsInZipTable;
