import RightSideBarForm from 'common/components/right-side-bar/right-side-bar-form';
import useFormSidebar from 'common/hooks/use-form-sidebar';
import DropdownOption from 'common/models/dropdown-option';
import { IFormSidebar } from 'common/models/types';
import { EXISTING_RATE_CONFLICT } from 'features/entities-page/components/entity-details/rates-tab/constants';
import { PayTypes } from 'models/enums/pay-types';
import React, { useState } from 'react';
import {} from '../therapist-rates-validation-messages';
import TherapistRateFormCreate from './therapist-rate-form-create';
import { ITherapistRateForm, RateErrorCodes } from './types';

const errorConfigs = [
    { errorCode: RateErrorCodes.AddRateActiveConstraintFailed, error: EXISTING_RATE_CONFLICT, field: ['endDate', 'startDate'] },
    { errorCode: RateErrorCodes.AddRateActiveFutureConstraintFailed, error: EXISTING_RATE_CONFLICT, field: ['startDate', 'endDate'] },
    { errorCode: RateErrorCodes.AddtRatePastConstraintFailed, error: EXISTING_RATE_CONFLICT, field: ['startDate', 'endDate'] },
];

const TherapistRateDialogAdd = ({ onClose, onSave, options }: IFormSidebar<ITherapistRateForm, any>) => {
    const [allowSave, setAllowSave] = useState(false);
    const [data, error, handleChangeData, handleCancelClick, handleSaveClick] = useFormSidebar<ITherapistRateForm>(
        {
            therapistId: options.therapistId,
            departmentId: null,
            subDepartmentIds: null,
            department: null,
            subDepartment: null,
            payType: null,
            corporateName: options?.corporateName,
            taxId: options?.taxId,
            rate: '0',
            startDate: null,
            endDate: '12/31/2099',

            corporateNameSelector: options?.corporateName,
            disableSave: false,
        },
        {
            onClose,
            onSave: (date: any) => {
                const { department, subDepartment, rate, ...restData } = date;

                if (options?.corporateName || restData?.payType === PayTypes.W2.value) {
                    delete restData.corporateName;
                    delete restData.taxId;
                }

                return onSave({
                    ...restData,
                    departmentId: department?.value,
                    subDepartmentIds: subDepartment?.map((x: DropdownOption) => x.value),
                    rate: rate,
                });
            },
            errorConfigs,
        }
    );

    const handleChangeDataWrapper = (...restData: any) => {
        handleChangeData(...restData);
        setAllowSave(true);
    };

    return (
        <RightSideBarForm
            title="Add Rate"
            onCancel={handleCancelClick}
            onSave={handleSaveClick}
            disableSave={data.disableSave || !allowSave}
        >
            <TherapistRateFormCreate data={data} errors={error} onChange={handleChangeDataWrapper} />
        </RightSideBarForm>
    );
};

export default TherapistRateDialogAdd;
