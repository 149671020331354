import FieldSet from 'common/components/field-set/field-set';
import InputField from 'common/components/field/input-field';
import React from 'react';
import { PasswordFieldProps } from './types';

const PasswordField: React.FC<PasswordFieldProps> = ({ placeholder, onChange, name, value, errorData }) => {
    return (
        <FieldSet name={name} errors={errorData} customClass="edit-field required login-input-wrapper">
            <InputField type="password" placeholder={placeholder} name={name} onChange={onChange} value={value} />
        </FieldSet>
    );
};

export default React.memo(PasswordField);
