import Tab from 'common/components/tabs/tab';
import Tabs from 'common/components/tabs/tabs';
import React from 'react';
import { HomeCareTab, HRTab } from '../settings-tabs/settings-tabs';

const SettingsNavigationTab: React.FC = () => {
    return (
        <div className="settings-navigation-tab">
            <Tabs orientation="vertical">
                <Tab text="HR" id="hr" iconName="icon-job" linkClassName={'custom-link'}>
                    <HRTab />
                </Tab>
                <Tab text="Home Care" iconName="icon-home" id="hc" linkClassName={'custom-link'}>
                    <HomeCareTab />
                </Tab>
                <Tab text="General" iconName="icon-settings" id="general" linkClassName={'custom-link'}></Tab>
            </Tabs>
        </div>
    );
};

export default SettingsNavigationTab;
