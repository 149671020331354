import { LocalStoreRepository } from 'common/helpers/repository/local-store-repository';
import { SessionStoreRepository } from 'common/helpers/repository/session-store-repository';
import DropdownOption from 'common/models/dropdown-option';
import GridLayout from 'react-grid-layout';

export interface IFilterStore<T> {
    filter: T;
}

export interface ICommonDashboardFilter {
    isSupervisorView: boolean;
}

export const MY_FOLLOW_UP_WIDGET_KEY: string = 'my-follow-up';
export const NEW_WIDGET_KEY: string = 'new';
export const IN_PROCESS_WIDGET_KEY = 'in-process';
export const INTERVIEW_SCHEDULED_WIDGET_KEY = 'interview-scheduled';
export const ONBOARDING_WIDGET_KEY = 'onboarding';
export const ONBOARDING_DOCUMENTS_WIDGET_KEY = 'onboarding-documents';
export const ACTIVE_DOCUMENTS_WIDGET_KEY = 'active-documents';
export const MISSING_DATA_WIDGET_KEY = 'missing-data';

export const supervisorOptions = [new DropdownOption(false, 'Personal'), new DropdownOption(true, 'Supervisor')];
export const dashboardStoreRepository = new SessionStoreRepository<ICommonDashboardFilter>('common-dashboard-filter');
export const repository = new LocalStoreRepository<GridLayout.Layout[]>('dashboard-widget-position');

export interface IDashboardPageContext {
    agAPIs: any;
}
