import { ColumnApi, GridApi, IFilterComp } from 'ag-grid-community';
import { CellClickedEvent, GridReadyEvent } from 'ag-grid-community/dist/lib/events';
import AgTable from 'common/components/ag-table/ag-table';
import { IParamsWatcher } from 'common/components/ag-table/types';
import BadgeTopFilter from 'common/components/badges/badge/badge-top-filter/badge-top-filter';
import { IBadge } from 'common/components/badges/types';
import ClearFiltersButton from 'common/components/header/clear-filters-button/clear-filters-button';
import ExportButton from 'common/components/header/export-button/export-button';
import HeaderLeftSide from 'common/components/header/header-left-side';
import HeaderRightSide from 'common/components/header/header-right-side';
import HeaderWrapper from 'common/components/header/header-wrapper';
import { DateTimeHelper } from 'common/helpers/date-time-helper';
import { FilterHelper } from 'common/helpers/filter-helper';
import { LocalStoreRepository } from 'common/helpers/repository/local-store-repository';
import { useSearchInput } from 'common/hooks/use-serch-input';
import { getExportData, getFilterData, getTableData } from 'common/services/api/tabel-fetch-service';
import { NavigationRoutes } from 'models/routes/navigation-routes';
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { ExtentedDefaultTableProps, IDefaultFilters } from 'common/constants/types';
import { ColDef } from 'ag-grid-community/dist/lib/entities/colDef';
import { CUSTOMER, dateTimeConstants, PATIENT_AGENCY_CODE, PATIENT_NAME, THERAPIST_NAME } from 'common/constants/common';
import { LocalTableRepository } from 'common/helpers/repository/local-table-repository';
import { useSelector } from 'react-redux';
import moment from 'moment';
import MultipleBadges from 'common/components/badges/multiple-badges';
import { followUpsService } from 'common/services/api/follow-ups/follow-ups-service';
import {
    FollowUpStatusCount,
    FollowUpStatusCountToStatus,
    FollowUpStatusToCount,
    ITopFollowUpFilter,
} from 'common/services/api/follow-ups/types';
import MultipleFilterAutocomplete from 'common/components/header/multiple-filter-autocomplete/multiple-filter-autocomplete';

// Fetches
const handleFetchData = getTableData('requests/therapists');
const handleFetchFilters = getFilterData('requests/therapists/filter/source');
const handleExportData = getExportData('requests/therapists/export');

const defaultBadgeList = [
    { value: FollowUpStatusCount.OFFERED, title: 'Offered', body: 0 },
    { value: FollowUpStatusCount.ADDED, title: 'Added', body: 0 },
];

const statusField = 'therapistStatus';

const FollowUpsListener: React.FC<ExtentedDefaultTableProps> = ({ storePrefix = '', extendGridOptions }) => {
    const assignedToDefault = useSelector((state: any) => state?.auth?.user.fullName);
    const tableRepository = useMemo(() => new LocalTableRepository(`${storePrefix}-follow-ups-table`, 'v3' + assignedToDefault), [
        storePrefix,
        assignedToDefault,
    ]);
    const customFiltersRepository = useMemo(() => new LocalStoreRepository(`${storePrefix}-follow-ups-table-custom-filters`, 'v1'), [
        storePrefix,
    ]);
    const loadedFilters = useRef<IDefaultFilters>(customFiltersRepository.load());
    const [defaultKeys, setDefaultKeys] = useState([]);

    const defaultFilters: IDefaultFilters = {
        search: null,
    };

    const [badgeList, setBadgeList] = useState(defaultBadgeList);
    const { setSearch } = useSearchInput(loadedFilters.current?.search, (value: string) => {
        setFilters((prev) => ({ ...prev, search: value }));
    });
    const [gridApi, setGridApi] = useState<GridApi>(null);
    const [gridColumnApi, setColumnApi] = useState<ColumnApi>(null);
    const [filters, setFilters] = useState(loadedFilters.current ?? defaultFilters);
    const paramsWatcher = useRef<IParamsWatcher>();
    const lastQuery = useRef(null);

    useEffect(() => {
        customFiltersRepository.save(filters);
        loadedFilters.current = filters;
    }, [filters, customFiltersRepository]);

    // Badge
    const handleBadgeChange = useCallback(
        (currentValues: IBadge[]) => {
            if (!gridApi || !gridColumnApi) {
                return;
            }

            type filerModelType = {
                values: string[];
            };

            const setFilter = (name: string, filerModel: filerModelType) => {
                gridApi.getFilterInstance(name, (filterApi) => {
                    filterApi.setModel({ ...filterApi.getModel(), exclude: false, ...filerModel });
                });
                gridApi.onFilterChanged();
            };

            const status: string[] = currentValues?.map((value) => FollowUpStatusCountToStatus.get(value.value as FollowUpStatusCount));
            setFilter(statusField, { values: status?.length ? status : null });
        },
        [gridApi, gridColumnApi]
    );

    // Status column to Badge
    useEffect(() => {
        if (!gridApi) {
            return;
        }

        const handleFilterChange = (e: any) => {
            e.api.getFilterInstance(statusField, (filterApi: IFilterComp) => {
                if (filterApi.getModel()?.values) {
                    setDefaultKeys(filterApi.getModel().values.map((value: string) => FollowUpStatusToCount.get(value)));
                } else {
                    setDefaultKeys([]);
                }
            });
        };

        gridApi.addEventListener('filterChanged', handleFilterChange);

        return () => {
            gridApi.removeEventListener('filterChanged', handleFilterChange);
        };
    }, [gridApi]);

    const updateCount = (data: any, params?: IParamsWatcher) => {
        let queryParams = '';
        if (params) {
            const { getFilterModelParams, getSortParams, getCustomFilterParams, request } = paramsWatcher.current;

            queryParams = FilterHelper.toURLQueryParams({
                ...getSortParams(request),
                ...getFilterModelParams(request),
                ...getCustomFilterParams,
                [statusField]: null,
            }).join('&');
        }

        if (lastQuery.current === queryParams) {
            return;
        }

        lastQuery.current = queryParams;

        followUpsService.getStatusCount(queryParams).then((data: ITopFollowUpFilter) => {
            setBadgeList(
                badgeList.map((item) => ({
                    value: item.value,
                    title: FollowUpStatusCountToStatus.get(item.value as FollowUpStatusCount),
                    body: data[item.value as FollowUpStatusCount],
                }))
            );
        });
    };

    const gridOptions = useMemo(
        () => ({
            ...extendGridOptions,
            onCellClicked: (event: CellClickedEvent) => {
                const { requestId, patientId, customerId, therapistId } = event.data;

                switch (event.column.getColId()) {
                    case THERAPIST_NAME: {
                        if (therapistId) {
                            window.open(NavigationRoutes.therapistDetailsRoute(therapistId), '_blank').focus();
                        }
                        break;
                    }
                    case PATIENT_NAME: {
                        if (patientId) {
                            window.open(NavigationRoutes.patientDetailsRoute(patientId), '_blank').focus();
                        }
                        break;
                    }
                    case CUSTOMER: {
                        if (customerId) {
                            window.open(NavigationRoutes.entityDetailsRoute(customerId), '_blank').focus();
                        }
                        break;
                    }
                    default: {
                        window.open(NavigationRoutes.requestDetailsRoute(requestId), '_blank').focus();
                        break;
                    }
                }
                if (extendGridOptions?.onCellClicked) extendGridOptions?.onCellClicked(event);
            },
            columnDefs: extendGridOptions?.columnDefs ?? requestColumnDefs,
            defaultColDef: {
                resizable: true,
                filterParams: {
                    onFetchFilters: handleFetchFilters,
                },
            },
            frameworkComponents: {
                objectLinkValueRender: (props: any) => {
                    const cellValue = (props.valueFormatted ? props.valueFormatted : props.value) || '';

                    return <span className="object-link">{cellValue}</span>;
                },
                ...extendGridOptions?.frameworkComponents,
            },
        }),
        [extendGridOptions]
    );

    // set default assignedTo
    useEffect(() => {
        if (!gridApi || !assignedToDefault) {
            return;
        }
        gridApi.getFilterInstance('assignedTo', (filterApi) => {
            if (!tableRepository.load()?.filterModel?.assignedTo) {
                filterApi.setModel({ ...filterApi.getModel(), values: [assignedToDefault] });
                gridApi.onFilterChanged();
            }
        });
    }, [gridApi, assignedToDefault, tableRepository]);

    const handleFiltersClear = () => {
        setSearch('');
        setFilters({ ...defaultFilters });
        setDefaultKeys([]);
    };

    const handleFiltersLoadedFromUrl = () => {
        handleFiltersClear();
        gridApi.getFilterInstance('assignedTo', (filterApi) => {
            filterApi.setModel({ ...filterApi.getModel(), filterType: 'customSet', values: [assignedToDefault] });
            gridApi.onFilterChanged();
        });
    };

    const handleGridReady = (params: GridReadyEvent) => {
        setGridApi(params.api);
        setColumnApi(params.columnApi);
    };

    const exportFileNameGenerator = (type: string) => {
        return `HomeCareRequestExport_${moment().format(dateTimeConstants.MMDDYYYY)}.${type.toLowerCase()}`;
    };

    return (
        <>
            <div className="main-info-wrapper ag-table-wrapper">
                <HeaderWrapper>
                    <HeaderLeftSide>
                        <MultipleFilterAutocomplete
                            gridApi={gridApi}
                            colId="assignedTo"
                            id="assignedTo"
                            label="Assigned To"
                            onFetchFilters={handleFetchFilters}
                            isNullable
                        />
                    </HeaderLeftSide>
                    <HeaderRightSide>
                        <ExportButton
                            title="PlacementFollowUps"
                            paramsWatcher={paramsWatcher}
                            onExportData={handleExportData}
                            fileNameGenerator={exportFileNameGenerator}
                        />
                        <ClearFiltersButton
                            title="PlacementFollowUps"
                            gridApi={gridApi}
                            gridColumnApi={gridColumnApi}
                            onClick={handleFiltersClear}
                        />
                    </HeaderRightSide>
                </HeaderWrapper>
                <MultipleBadges
                    className="pl-25"
                    items={badgeList}
                    defaultKey={defaultKeys}
                    onChange={handleBadgeChange}
                    badgeComponent={BadgeTopFilter}
                    repository={new LocalStoreRepository(`${storePrefix}-follow-ups-table-badge`, 'v7')}
                />
                <AgTable
                    onGridReady={handleGridReady}
                    gridOptions={gridOptions}
                    onFiltersLoadedFromUrl={handleFiltersLoadedFromUrl}
                    repository={tableRepository}
                    customFilters={filters}
                    onDataChange={updateCount}
                    paramsWatcher={paramsWatcher}
                    onFetchData={handleFetchData}
                />
            </div>
        </>
    );
};

export const requestColumnDefs: ColDef[] = [
    {
        field: 'statusDate',
        headerName: 'Status Date',
        valueFormatter: (props: any) => DateTimeHelper.format(props.value, 'MM/DD/YYYY'),
        filter: 'agDateColumnFilter',
        filterParams: {
            isDateTime: true,
        },
        width: 154,
    },
    {
        field: THERAPIST_NAME,
        headerName: 'Provider',
        filter: 'agCustomFilter',
        width: 350,
        cellRenderer: 'objectLinkValueRender',
    },
    {
        field: 'reminderDate',
        headerName: 'Reminder Date',
        valueFormatter: (props: any) => DateTimeHelper.format(props.value, 'MM/DD/YYYY'),
        filter: 'agDateColumnFilter',
        filterParams: {
            isDateTime: true,
        },
        width: 154,
    },
    {
        field: 'assignedTo',
        headerName: 'Assigned To',
        filter: 'agCustomFilter',
        width: 300,
        filterParams: {
            debounceMs: 0,
            isNullable: true,
        },
    },
    {
        field: 'department',
        headerName: 'Department',
        //  valueFormatter: (props: any) => DisplayValueHelper.getValue(props.value, 'name'),
        filter: 'agCustomFilter',
        width: 180,
        filterParams: {
            isNullable: true,
        },
    },
    {
        field: 'createdAt',
        headerName: 'Creation Date',
        valueFormatter: (props: any) => DateTimeHelper.format(props.value, 'MM/DD/YYYY'),
        filter: 'agDateColumnFilter',
        initialSort: 'desc',
        filterParams: {
            isDateTime: true,
        },
        width: 154,
    },
    {
        field: 'therapyType',
        headerName: 'Therapy Type',
        filter: 'agCustomFilter',
        width: 148,
        filterParams: {
            isNullable: true,
        },
    },
    {
        field: 'population',
        headerName: 'Population',
        filter: 'agCustomFilter',
        width: 140,
        filterParams: {
            isNullable: true,
        },
    },
    {
        field: 'county',
        headerName: 'County',
        filter: 'agCustomFilter',
        width: 154,
        filterParams: {
            isNullable: true,
        },
    },
    {
        field: 'state',
        headerName: 'State',
        filter: 'agCustomFilter',
        width: 113,
        filterParams: {
            isNullable: true,
        },
    },
    {
        field: 'zipCode',
        headerName: 'Zip Code',
        filter: 'agCustomFilter',
        width: 113,
        filterParams: {
            isNullable: true,
        },
    },
    {
        field: CUSTOMER,
        headerName: 'Customer',
        filter: 'agCustomFilter',
        cellRenderer: 'objectLinkValueRender',
        width: 350,
        filterParams: {
            isNullable: true,
        },
    },
    {
        field: PATIENT_NAME,
        headerName: 'Patient Name',
        filter: null,
        width: 350,
        cellRenderer: 'objectLinkValueRender',
    },
    {
        field: PATIENT_AGENCY_CODE,
        headerName: 'Patient ID',
        filter: null,
        width: 140,
    },
    {
        field: 'requestId',
        headerName: 'Request ID',
        filter: 'agCustomFilter',
        width: 130,
    },
    {
        field: statusField,
        headerName: 'Status',
        filter: 'agCustomFilter',
        width: 150,
    },
];

export default FollowUpsListener;
