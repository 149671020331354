import { CellClickedEvent, GridReadyEvent } from 'ag-grid-community/dist/lib/events';
import AgTable from 'common/components/ag-table/ag-table';
import { IParamsWatcher } from 'common/components/ag-table/types';
import AddButton from 'common/components/header/add-button/add-button';
import ClearFiltersButton from 'common/components/header/clear-filters-button/clear-filters-button';
import HeaderRightSide from 'common/components/header/header-right-side';
import HeaderWrapper from 'common/components/header/header-wrapper';
import Popper from 'common/components/infinite-popper/infinite-popper';
import InfiniteSelect from 'common/components/infinite-select/infinite-select';
import ConfirmPopup from 'common/components/inifinite-popups/confirm-popup';
import { phoneTypes } from 'common/constants/phone-types';
import { IDefaultFilters } from 'common/constants/types';
import { CommunicationHelper } from 'common/helpers/communication-helper';
import { DisplayValueHelper } from 'common/helpers/display-value-helper';
import DropdownOption from 'common/models/dropdown-option';
import PatientContactService from 'common/services/api/patient-contact/patient-contact';
import { IPatientContactForm } from 'common/services/api/patient-contact/types';
import { getExportData, getFilterData, getTableData } from 'common/services/api/tabel-fetch-service';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import { trackPromise } from 'react-promise-tracker';
import { PatientContactTabProps } from '../../types';
import PatientContactSidebarCreate from './patient-contact-forms/patient-contact-sidebar-create';
import PatientContactSidebarEdit from './patient-contact-forms/patient-contact-sidebar-edit';
import PatientContactSidebarInfo from './patient-contact-forms/patients-contact-sidebar-info';
import baseService from 'common/services/api/base/base-service';
import { OptionField } from 'common/constants/option-fields';
import { IRelationship } from 'common/services/api/patient/types';
import { RowNode } from 'ag-grid-community';
import EmailRender from 'common/components/ag-table/render-components/email-render';
import PhoneRender from 'common/components/ag-table/render-components/phone-render';

const defaultFilters: IDefaultFilters = {
    search: null,
};

const PatientContactListTab: React.FC<PatientContactTabProps> = ({ patientId }) => {
    const [gridApi, setGridApi] = useState(null);
    const [gridColumnApi, setColumnApi] = useState(null);
    const [filters, setFilters] = useState(defaultFilters);
    const [isPatientContactAddDialogOpen, togglePatientContactAddDialog] = useState(false);
    const [isPatientContactInfoDialogOpen, togglePatientContactInfoDialog] = useState(false);
    const [isPatientEditDialogOpen, togglePatientEditDialog] = useState(false);
    const [contactDetails, setContactDetails] = useState(null);
    const [relationships, setRelationships] = useState<DropdownOption[]>([]);

    const paramsWatcher = useRef<IParamsWatcher>();
    const options = useMemo(() => [new DropdownOption({ target: null, value: 'delete' }, 'Delete')], []);

    const fetchOption = useMemo(
        () => ({
            handleFetchData: getTableData(`patients/${patientId}/contacts`),
            handleFetchFilters: getFilterData(`patients/${patientId}/contacts/filter/source`),
            handleExportData: getExportData(`patients/${patientId}/contacts/export`),
        }),
        [patientId]
    );

    const sidebarViewActions = [
        {
            title: 'Edit',
            handler: () => {
                togglePatientContactInfoDialog(false);
                togglePatientEditDialog(true);
            },
        },
    ];

    const handleSavePatientEditDialog = (patientContactForm: IPatientContactForm) => {
        const patientContact = { ...patientContactForm };
        const communicationInfo = CommunicationHelper.Validate({
            phones: patientContactForm.phones,
            isPhonesRequiered: true,
            emails: patientContactForm.emails,
            isEmailsRequiered: false,
        });

        patientContact.emails = communicationInfo.emails;
        patientContact.phones = communicationInfo.phones;

        return trackPromise(PatientContactService.editContact(patientId, contactDetails?.id, patientContact)).then(() => {
            gridApi.onFilterChanged();
            togglePatientEditDialog(false);
        });
    };

    const handleSavePatientsDetailAddDialog = (formData: IPatientContactForm) => {
        const patientContact = { ...formData };
        const communicationInfo = CommunicationHelper.Validate({
            phones: formData.phones,
            isPhonesRequiered: true,
            emails: formData.emails,
            isEmailsRequiered: false,
        });

        patientContact.emails = communicationInfo.emails;
        patientContact.phones = communicationInfo.phones;

        return PatientContactService.createContact(patientId, patientContact).then(() => {
            gridApi.onFilterChanged();
            togglePatientContactAddDialog(false);
        });
    };

    useEffect(() => {
        baseService.getOptions(null, null, null, OptionField.relationship, null).then((response) => {
            setRelationships(response.map((i) => new DropdownOption(i.id, i.name)));
        });
    }, []);

    const hasMainContact = useMemo(() => {
        let hasMainContact: boolean = false;
        if (isPatientContactAddDialogOpen) {
            gridApi?.forEachNode((node: RowNode) => {
                if (node.data.isMainContact === true) {
                    hasMainContact = true;

                    return;
                }
            });
        }

        return hasMainContact;
    }, [isPatientContactAddDialogOpen, gridApi]);

    const gridOptions = useMemo(
        () => ({
            onCellClicked: (event: CellClickedEvent) => {
                if (event.column.getColId() === 'doteMenu') {
                    return;
                }
                trackPromise(PatientContactService.getContactDetails(patientId, event.data.id)).then((data) => {
                    togglePatientContactInfoDialog(true);
                    setContactDetails(data);
                });
            },
            frameworkComponents: {
                doteRender: () => {
                    return (
                        <InfiniteSelect
                            id="entityContactMenu"
                            popper
                            items={options}
                            className="no-select-border"
                            icon={<i className="icon icon-options" />}
                            onChange={(item: DropdownOption) => {
                                setTargetConfirm(item?.value?.target);
                            }}
                        />
                    );
                },
                phoneRender: PhoneRender,
                emailRender: EmailRender,
            },
            columnDefs: [
                {
                    field: 'name',
                    headerName: 'Name',
                    width: 350,
                },
                {
                    field: 'isEmergencyContact',
                    headerName: 'Emergency Contact',
                    valueFormatter: (props: any) => DisplayValueHelper.convertToYesOrNo(props.value),
                    width: 202,
                },
                {
                    field: 'isMainContact',
                    headerName: 'Main Contact',
                    valueFormatter: (props: any) => DisplayValueHelper.convertToYesOrNo(props.value),
                    width: 202,
                },
                {
                    field: 'relationship',
                    headerName: 'Relationship',
                    width: 160,
                    valueFormatter: (props: any) => (props.value as IRelationship)?.name,
                },
                {
                    field: 'emails',
                    headerName: 'Email Address',
                    cellRenderer: 'emailRender',
                    width: 350,
                },
                {
                    field: 'phones',
                    headerName: 'Phone',
                    cellRenderer: 'phoneRender',
                    width: 180,
                },
                {
                    headerName: '',
                    colId: 'doteMenu',
                    pinned: 'right',
                    cellRenderer: 'doteRender',
                    cellClass: ['dote-cell'],
                    width: 40,
                    hideForExport: true,
                },
            ],
            defaultColDef: {
                filterParams: {
                    onFetchFilters: fetchOption.handleFetchFilters,
                },
            },
        }),
        [fetchOption.handleFetchFilters, options, patientId]
    );

    // Clear
    const onFiltersClear = () => {
        setFilters({ ...defaultFilters });
    };

    const onGridReady = (params: GridReadyEvent) => {
        setGridApi(params.api);
        setColumnApi(params.columnApi);
    };

    const [targetConfirm, setTargetConfirm] = useState(null);

    const handleOnClickConfirm = () => {
        trackPromise(PatientContactService.archive(patientId, gridApi.getSelectedRows()[0].id)).then(() => {
            gridApi.onFilterChanged();
            setTargetConfirm(null);
        });
    };

    return (
        <>
            <div className="table-page">
                <HeaderWrapper>
                    <HeaderRightSide>
                        <ClearFiltersButton
                            title="Entity Contacts"
                            gridApi={gridApi}
                            gridColumnApi={gridColumnApi}
                            onClick={onFiltersClear}
                        />
                        <AddButton title="Add Contact" onClick={() => togglePatientContactAddDialog(true)} />
                    </HeaderRightSide>
                </HeaderWrapper>
                <AgTable
                    absoluteShadow
                    onGridReady={onGridReady}
                    gridOptions={gridOptions}
                    customFilters={filters}
                    paramsWatcher={paramsWatcher}
                    onFetchData={fetchOption.handleFetchData}
                />
                {targetConfirm && (
                    <Popper target={targetConfirm} width={271} onClickOutside={() => setTargetConfirm(null)}>
                        <ConfirmPopup onCancel={() => setTargetConfirm(null)} onConfirm={handleOnClickConfirm}>
                            Are you sure you want to delete this contact?
                        </ConfirmPopup>
                    </Popper>
                )}
                {isPatientEditDialogOpen && (
                    <PatientContactSidebarEdit
                        data={contactDetails}
                        onSave={handleSavePatientEditDialog}
                        options={{ phoneTypes: phoneTypes, relationshipTypes: relationships || [] }}
                        onClose={() => togglePatientEditDialog(false)}
                    />
                )}
                {isPatientContactInfoDialogOpen && (
                    <PatientContactSidebarInfo
                        data={contactDetails}
                        actions={sidebarViewActions}
                        onClose={() => togglePatientContactInfoDialog(false)}
                    />
                )}
                {isPatientContactAddDialogOpen && (
                    <PatientContactSidebarCreate
                        data={!hasMainContact}
                        onClose={() => togglePatientContactAddDialog(false)}
                        options={{ phoneTypes: phoneTypes, relationshipTypes: relationships || [] }}
                        onSave={handleSavePatientsDetailAddDialog}
                    />
                )}
            </div>
        </>
    );
};

export default PatientContactListTab;
