import NotePage from 'common/components/note-page/note-page';
import Tab from 'common/components/tabs/tab';
import Tabs from 'common/components/tabs/tabs';
import { ICreateNoteRequest } from 'common/models/create-note-request';
import noteService from 'common/services/api/note/note-service';
import schoolService from 'common/services/api/school/school-service';
import React from 'react';
import { SchoolRequestGeneralInfoTabProp } from '../types';
import SchoolRequestBasicInfoTab from './basic-info-tab/school-request-basic-info-tab';
import PastHistoryTab from './past-history-tab/past-history-tab';

const SchoolRequestGeneralInfoTab: React.FC<SchoolRequestGeneralInfoTabProp> = ({ requestId, requestDetails }) => {
    if (!requestDetails) {
        return null;
    }

    const handleClickOnSaveNote = (request: ICreateNoteRequest) => {
        return schoolService.createNote(requestId, request);
    };

    const handleClickOnCompleteNote = (id: number) => {
        noteService.complete(id);
    };

    return (
        <Tabs orientation="vertical" parentTabUrl="general-info" className="school-general-tab">
            <Tab id="basicInfo" linkClassName={'custom-link'} text="Basic Info">
                <SchoolRequestBasicInfoTab requestId={requestId} requestDetails={requestDetails} />
            </Tab>
            <Tab id="notes" linkClassName={'custom-link'} text="Notes">
                <NotePage
                    url={`school/requests/${requestId}/notes`}
                    onCompleteNoteClick={handleClickOnCompleteNote}
                    onSave={handleClickOnSaveNote}
                />
            </Tab>
            <Tab id="pastHistory" linkClassName={'custom-link'} text="Past History">
                <PastHistoryTab requestId={requestId} />
            </Tab>
        </Tabs>
    );
};

export default SchoolRequestGeneralInfoTab;
