import React, { useState, useEffect, useCallback } from 'react';
import FieldSet from 'common/components/field-set/field-set';
import NumberField from 'common/components/field/number-field';
import InfiniteSelect from 'common/components/infinite-select/infinite-select';
import DropdownOption from 'common/models/dropdown-option';
import { IRequestSettingData } from './types';
import { FieldValue } from 'common/models/field-value';
import RequestService from 'common/services/api/requests/request-service';
import { IRequestSetting, IRequestField } from 'common/services/api/requests/types';
import { settingsValidationRules } from '../../settings-validation-rules';
import { IRequestsTabProps } from './types';

const RequestsTab: React.FC<IRequestsTabProps> = (props) => {
    const [errors, setErrors] = useState({});
    const [requiredOptions, setRequiredOptions] = useState([]);
    const [requestSettingsModel, setRequestSettingsModel] = useState<IRequestSetting>(null);
    const { getSettingsParam, onSaveSettings, type } = props;
    const [saveOnFocusOut, setSaveOnFocusOut] = useState(false);
    const [requestData, setRequestData] = useState<IRequestSettingData>({
        requiredFieldKeys: [],
        daysForReopenDeclinedRequest: 0,
    });

    const updateSettings = async (settings: IRequestSettingData, isRequiredFields?: boolean) => {
        const setting = { ...requestSettingsModel?.setting, ...settings };
        const updatedModel = { ...requestSettingsModel, setting };
        await onSaveSettings(updatedModel, isRequiredFields);
        getSettingsData();
    };

    const onChange = (data: FieldValue) => {
        setRequestData((prevInfo) => ({ ...prevInfo, [data.name]: data.value }));
        setErrors((prevError) => ({ ...prevError, [data.name]: undefined }));
        setSaveOnFocusOut(true);
    };

    const getSettingsData = useCallback(() => {
        RequestService.getSettings(getSettingsParam).then((res: IRequestSetting) => {
            setRequestData({
                requiredFieldKeys: res.setting.requiredFieldKeys,
                daysForReopenDeclinedRequest: res.setting.daysForReopenDeclinedRequest,
            });
            setRequiredOptions(res.setting.requestFields.map((item: IRequestField) => new DropdownOption(item.key, item.description)));
            setRequestSettingsModel({ id: res.id, isDefault: res.isDefault, setting: res.setting });
        });
    }, [getSettingsParam]);

    useEffect(() => {
        getSettingsData();
    }, [getSettingsData]);

    const handleSaveRequiredFields = () => {
        if (saveOnFocusOut) {
            updateSettings(requestData, true);
            setSaveOnFocusOut(false);
        }
    };

    const handleSaveDaysForReopen = () => {
        if (saveOnFocusOut) {
            updateSettings(requestData);
            setSaveOnFocusOut(false);
        }
    };

    return (
        <div className="requests-tab">
            <div className="requests-tab-block info-input-block">
                <h1 className="title">Required Fields</h1>
                <p className="comment">the fields that must be populated when creating a new request</p>
                <FieldSet name="requiredFields" customClass="required-fields" errors={errors}>
                    <InfiniteSelect
                        items={requiredOptions}
                        multiselect={true}
                        value={requiredOptions?.filter((item) => requestData.requiredFieldKeys.some((id) => id === item.value))}
                        label="Select Required Fields"
                        onChange={(item) => onChange({ name: 'requiredFieldKeys', value: item.map((i: FieldValue) => i.value) })}
                        showSelectedCounter
                        onDropdownClose={handleSaveRequiredFields}
                    />
                </FieldSet>
            </div>
            {type !== 'customer' && (
                <div className="requests-tab-block info-input-block">
                    <h1 className="title">Reopen a Declined Request</h1>
                    <p className="comment">Maximum number of days to reopen a declined request</p>
                    <FieldSet name="daysForReopenDeclinedRequest" customClass="days-input" errors={errors}>
                        <NumberField
                            id="daysForReopenDeclinedRequest"
                            placeholder="Days"
                            name="daysForReopenDeclinedRequest"
                            onChange={onChange}
                            maxValue={settingsValidationRules.daysForReopenRequest.maxValue}
                            value={requestData.daysForReopenDeclinedRequest}
                            onBlur={handleSaveDaysForReopen}
                            defaultValue={0}
                            allowNegative={false}
                        />
                    </FieldSet>
                </div>
            )}
        </div>
    );
};

export default RequestsTab;
