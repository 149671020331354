import DateField from 'common/components/field/date-field';
import NativeTimePicker from 'common/components/field/native-time-picker/native-time-picker';
import React from 'react';
import FieldSet from '../field-set/field-set';
import './individual-days.scss';
import { dateTimeConstants } from '../../constants/common';

const IndividualDays: React.FC<any> = ({ value, onChange, errors }) => {
    return (
        <div className="individual-days-wrapper">
            <FieldSet name="date" errors={errors} customClass="required-field-star">
                <DateField
                    id="date"
                    placeholder="Date"
                    name="date"
                    onChange={(data: any) => onChange({ ...value, date: data.value })}
                    value={value?.date}
                    dateFormat={dateTimeConstants.MM_DD_YYYY}
                    maskFormat="##/##/####"
                ></DateField>
            </FieldSet>
            <div className="row-two-column edges">
                <FieldSet name="startTime" errors={errors} customClass="native-time-picker required-field-star">
                    <NativeTimePicker
                        id="ReminderTime"
                        placeholder="From"
                        name="startTime"
                        onChange={(data: any) => onChange({ ...value, startTime: data.value })}
                        value={value?.startTime}
                        className="from-time"
                    />
                </FieldSet>
                <FieldSet name="endTime" errors={errors} customClass="native-time-picker required-field-star">
                    <NativeTimePicker
                        id="ReminderTime"
                        placeholder="TO"
                        name="endTime"
                        onChange={(data: any) => onChange({ ...value, endTime: data.value })}
                        value={value?.endTime}
                        className="to-time"
                    />
                </FieldSet>
            </div>
        </div>
    );
};

export default IndividualDays;
