import React, { useCallback } from 'react';
import RightSideBarForm from 'common/components/right-side-bar/right-side-bar-form';
import useFormSidebar from 'common/hooks/use-form-sidebar';
import { IFormSidebar } from 'common/models/types';
import { IApplicationRole, ICreateUpdateRoleRequest } from 'common/services/api/role/types';
import AddEditRoleForm from '../add-edit-role-form/add-edit-role-form';

const AddEditRoleSidebar = ({ onClose, onSave, data: role }: IFormSidebar<ICreateUpdateRoleRequest, IApplicationRole>) => {
    const [data, error, handleChangeData, handleCancelClick, handleSaveClick] = useFormSidebar<ICreateUpdateRoleRequest>(
        {
            name: role?.name || '',
            permissions: role?.permissions || [],
        },
        { onClose, onSave }
    );

    const generateTitle = useCallback(() => {
        return role ? 'Edit Role' : 'Create Role';
    }, [role]);

    return (
        <div className="add-edit-user-sidebar">
            <RightSideBarForm title={generateTitle()} onCancel={handleCancelClick} onSave={handleSaveClick}>
                <AddEditRoleForm data={data} errors={error} onChange={handleChangeData} />
            </RightSideBarForm>
        </div>
    );
};

export default React.memo(AddEditRoleSidebar);
