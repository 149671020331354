import { dateTimeConstants } from 'common/constants/common';
import Moment from 'moment';
import React from 'react';
import { PatientHeaderInfoProp } from '../types';
import Avatar from 'common/components/Avatar/Avatar';
import Chip from 'common/components/chip/chip';

const PatientHeaderInfo: React.FC<PatientHeaderInfoProp> = ({ details }) => {
    return (
        <>
            <div className="general-info ph-info">
                <div className="bio">
                    <Avatar user={details} imageData={details.profilePhoto} />
                    <div className="personal-data">
                        <div className="fullname">{details.fullName}</div>
                        <div className="note">{details.facility?.name}</div>
                        {details?.createdAt && (
                            <div className="bio-footer">
                                <span className="description">Created on {formatDate(details?.createdAt)}</span>
                            </div>
                        )}
                    </div>
                </div>
                {
                    <div className="contact-info">
                        <div className="contact-info-wrapper">
                            {details.phones?.length > 0 && <Chip iconClass="icon-phone" content={details.phones[0].number} isPhoneNumber />}
                            {details.emails?.length > 0 && <Chip iconClass="icon-email" content={details.emails[0].email} />}
                        </div>
                    </div>
                }
            </div>
        </>
    );
};

const formatDate = (date: Date) => {
    return Moment(date).format(dateTimeConstants.MMM_D_YYY);
};

export default React.memo(PatientHeaderInfo, function areEqual(prevProps, nextProps) {
    return prevProps.details === nextProps.details;
});
