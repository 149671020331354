import React from 'react';
import { IIndividualDay, INursingHomeBasicForm } from './types';
import { IFormSidebar } from 'common/models/types';
import useFormSidebar from 'common/hooks/use-form-sidebar';
import moment from 'moment';
import NursingHomeBaseForm from './nursing-home-basic-form';
import RightSideBarForm from 'common/components/right-side-bar/right-side-bar-form';
import { INursingHomeRequestDetails } from 'common/services/api/nursing-home-requests/types';

const NursingHomeBasicSidebar = ({
    data: nursingHomeDetails,
    onClose,
    onSave,
    options,
    isFocused,
}: IFormSidebar<INursingHomeBasicForm, INursingHomeRequestDetails | any>) => {
    const [data, error, handleChangeData, handleCancelClick, handleSaveClick] = useFormSidebar<INursingHomeBasicForm>(
        {
            id: nursingHomeDetails?.id,
            therapyTypeId: nursingHomeDetails?.therapyType?.id,
            entityId: nursingHomeDetails?.entity?.id,
            facilityId: nursingHomeDetails?.facility?.id,
            placementTypeId: nursingHomeDetails?.placementType?.id,
            customRate: nursingHomeDetails?.customRate,
            flexDate: nursingHomeDetails?.flexDate,
            flexTime: nursingHomeDetails?.flexTime,
            coverageType: nursingHomeDetails?.coverageType,
            sourceId: nursingHomeDetails?.source?.id,
            specialInstructions: nursingHomeDetails?.specialInstructions,
            cameInDate: nursingHomeDetails?.cameInDate ?? null,
            startDate: nursingHomeDetails?.startDate ? moment(nursingHomeDetails?.startDate) : null,
            endDate: nursingHomeDetails?.endDate ? moment(nursingHomeDetails?.endDate) : null,
            rateId: nursingHomeDetails?.rate?.rateId ?? null,
            individualDays:
                nursingHomeDetails?.coverageType === 'IndividualDays'
                    ? toIndividualDays(nursingHomeDetails?.startDate, nursingHomeDetails?.endDate)
                    : [],
            weekDays: nursingHomeDetails?.weekDays || [],
            isCustomRate: nursingHomeDetails?.customRate ? true : false,
            labels: {
                therapyTypeId: nursingHomeDetails?.therapyType?.name,
                entityId: nursingHomeDetails?.entity?.name,
                facilityId: nursingHomeDetails?.facility?.name,
                placementTypeId: nursingHomeDetails?.placementType?.name,
                cameInDate: nursingHomeDetails?.cameInDate ? moment(nursingHomeDetails?.cameInDate) : moment(),
                startDate: nursingHomeDetails?.startDate ? moment(nursingHomeDetails?.startDate) : null,
                endDate: nursingHomeDetails?.endDate ? moment(nursingHomeDetails?.endDate) : null,
                rateId: nursingHomeDetails?.rate?.rate,

                sourceId: nursingHomeDetails?.source
                    ? { ...nursingHomeDetails?.source, label: nursingHomeDetails?.source?.contact?.fullName }
                    : null,
            },
        },
        { onClose, onSave }
    );

    return (
        <RightSideBarForm title={options?.title || 'Create Requests'} onCancel={handleCancelClick} onSave={handleSaveClick}>
            <NursingHomeBaseForm
                isEdit={options?.isEdit}
                options={{ isTherapists: nursingHomeDetails?.therapists?.length > 0, disabledEntity: options?.disabledEntity }}
                data={data}
                errors={error}
                onChange={handleChangeData}
                isInstructionField={isFocused}
            />
        </RightSideBarForm>
    );
};

const toIndividualDays = (startDate?: string, endDate?: string): IIndividualDay[] => {
    if (!startDate || !endDate) {
        return [];
    }
    const startDateTime = startDate.split('T');
    const endDateTime = endDate.split('T');

    return [
        {
            date: moment(startDateTime[0] + 'T00:00:00.000Z') || endDateTime[0] + 'T00:00:00.000Z' || '',
            startTime: startDateTime[1]?.substr(0, 5) || '',
            endTime: endDateTime[1]?.substr(0, 5) || '',
        },
    ];
};

export default NursingHomeBasicSidebar;
