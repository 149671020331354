export type OverdueFilterProps = {
    onChange: (value: OverdueFilterOption) => void;
    className?: string;
    value?: OverdueFilterOption;
    options?: OverdueFilterOption[];
};

export enum OverdueFilterOption {
    OVERDUE = 'Overdue',
    ALL = 'All',
    TODAY = 'Today',
}
