import { BackgroundCheckStatus } from 'common/constants/background-check-status';
import { DateTimeHelper } from 'common/helpers/date-time-helper';
import { DisplayValueHelper } from 'common/helpers/display-value-helper';

export const BackgorundChecksTableColumnDefinitions = [
    {
        field: 'name',
        headerName: 'Name',
        filter: 'agCustomFilter',
        width: 350,
    },
    {
        field: 'checkDate',
        headerName: 'Check Date',
        filter: 'agDateColumnFilter',
        valueFormatter: (props: any) => DateTimeHelper.format(props.value),
        width: 154,
        filterParams: {
            isNullable: true,
        },
        initialSort: 'desc',
    },
    {
        field: 'expirationDate',
        headerName: 'Expiration Date',
        filter: 'agDateColumnFilter',
        valueFormatter: (props: any) => DateTimeHelper.format(props.value),
        width: 154,
        filterParams: {
            isNullable: true,
        },
    },
    {
        field: 'state',
        headerName: 'State',
        sortable: false,
        width: 154,
        cellClass: (params: any) => params.value.toLowerCase(),
    },
    {
        field: 'status',
        headerName: 'Status',
        filter: 'agCustomFilter',
        valueFormatter: (props: any) => (props.value === BackgroundCheckStatus.NOT_PASSED ? 'Not Passed' : props.value),
        width: 154,
    },
    {
        field: 'originalFileName',
        headerName: 'File',
        cellRenderer: 'cellLinkRender',
        sortable: false,
        width: 140,
    },
    {
        field: 'notApplicable',
        headerName: 'Not Applicable',
        valueFormatter: (props: any) => DisplayValueHelper.convertToYesOrNo(props.value),
        sortable: false,
        width: 196,
    },
    {
        field: 'notApplicableReason',
        headerName: 'Reason',
        width: 420,
    },
    {
        headerName: '',
        colId: 'doteMenu',
        pinned: 'right',
        cellRenderer: 'doteRender',
        cellClass: ['dote-cell'],
        width: 40,
        hideForExport: true,
    },
];
