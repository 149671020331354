import { getDocumentsRequest, getTherapistDetailsRequest } from 'app/store/therapist/action-creators';
import FieldSet from 'common/components/field-set/field-set';
import CheckBoxField from 'common/components/field/checkbox-field';
import DateField from 'common/components/field/date-field';
import InputField from 'common/components/field/input-field';
import PaginationAutocomplete from 'common/components/field/pagination-autocomplete/pagination-autocomplete';
import TextAreaField from 'common/components/field/text-area-field';
import InfiniteSelect from 'common/components/infinite-select/infinite-select';
import RightSideBar from 'common/components/right-side-bar/right-side-bar';
import { OptionField } from 'common/constants/option-fields';
import { ConverterHelper } from 'common/helpers/converter-helper';
import DropdownOption from 'common/models/dropdown-option';
import { FieldValue } from 'common/models/field-value';
import BaseService from 'common/services/api/base/base-service';
import DepartmentService from 'common/services/api/department/department-service';
import DocumentService from 'common/services/api/document/document-service';
import EntityService from 'common/services/api/entity/entity-service';
import therapistDocumentService from 'common/services/api/therapist-document/therapist-document-service';
import { IRequsetTherapistDocumentInfo } from 'common/services/api/therapist-document/types';
import { requestDocumentRules } from 'common/validation/therapist-document-validation-rules';
import { PayTypes } from 'models/enums/pay-types';
import Moment from 'moment';
import React, { useState } from 'react';
import { ThunkDispatch } from 'redux-thunk';
import { Action } from '@reduxjs/toolkit';
import { trackPromise } from 'react-promise-tracker';
import { useDispatch } from 'react-redux';
import { RequestTherapistDocumentDialogProps } from './type';

// TO-DO: Set tab indexes on the form
const RequestTherapistDocumentDialog: React.FC<RequestTherapistDocumentDialogProps> = ({ onCancel, therapistId }) => {
    const [therapistDocument, setRequestTherapistDocument] = useState<IRequsetTherapistDocumentInfo>({
        name: undefined,
        effectiveDate: Moment().toDate(),
        endDate: undefined,
        payType: undefined,
        isOverrideAllow: false,
        isOrientationRelated: false,
        isComplianceRelated: false,
        departmentId: undefined,
        subDepartmentId: undefined,
        disciplineId: undefined,
        customerId: undefined,
        reason: undefined,
        documentIds: [],
        documentOptions: [],
    });

    const [errorData, setErrorData] = useState({});
    const [showSubDepartmentField, setShowSubDepartmentField] = useState(false);
    const dispatch = useDispatch<ThunkDispatch<any, any, Action>>();

    const payTypeOptions = Object.values(PayTypes).map((x) => new DropdownOption(x.value, x.label));

    const handleFieldChange = (data: FieldValue) => {
        setRequestTherapistDocument((prevInfo) => ({ ...prevInfo, [data.name]: data.value }));
        setErrorData((prevError) => ({ ...prevError, [data.name]: undefined }));
    };

    const handleSave = () => {
        trackPromise(
            therapistDocumentService
                .createRequestTherapistDocument(therapistId, {
                    ...therapistDocument,
                    documentIds: therapistDocument.documentOptions.map((x) => x.value),
                })

                .then(() => {
                    dispatch(getDocumentsRequest(therapistId));
                    trackPromise(dispatch(getTherapistDetailsRequest(therapistId)));
                    onCancel();
                })
                .catch(setErrorData)
        );
    };

    const getSubDepartmentOptions = (term: string, skip: number, take: number, field?: string) => {
        return BaseService.getOptions(term, skip, take, field, [therapistDocument.departmentId]);
    };

    const getCustomerOptions = (term: string, skip: number, take: number) => {
        return EntityService.getEntityOptions(term, skip, take);
    };

    return (
        <div>
            <RightSideBar title="Request a Document" onCancel={onCancel} onSave={handleSave}>
                <div className="edit-therapist-form">
                    <FieldSet name="name" errors={errorData} customClass="edit-therapist-field required-field-star">
                        <InputField placeholder="Name" name="name" onChange={handleFieldChange} value={therapistDocument.name}></InputField>
                    </FieldSet>
                    <FieldSet errors={errorData} name="documentIds" customClass="edit-therapist-field document-field required-field-star">
                        <PaginationAutocomplete
                            id="documentIds"
                            name="documentIds"
                            onDelete={null}
                            placeholder="Documents"
                            cleanable={false}
                            tabIndex={null}
                            multiselect={true}
                            defaultValue={therapistDocument.documentOptions}
                            onSelect={(items) => handleFieldChange({ name: 'documentOptions', value: items })}
                            convertFunction={(item) => new DropdownOption(item.id, item.name)}
                            fetchData={DocumentService.getDocumentOptions}
                        ></PaginationAutocomplete>
                    </FieldSet>
                    <FieldSet
                        name="effectiveDate"
                        errors={errorData}
                        customClass="edit-therapist-field date-picker-field required-field-star"
                    >
                        <DateField
                            id="effectiveDate"
                            placeholder="Effective Date"
                            name="effectiveDate"
                            onChange={handleFieldChange}
                            value={therapistDocument.effectiveDate}
                        ></DateField>
                    </FieldSet>
                    <FieldSet name="endDate" errors={errorData} customClass="edit-therapist-field date-picker-field required-field-star">
                        <DateField
                            id="endDate"
                            placeholder="End Date"
                            name="endDate"
                            onChange={handleFieldChange}
                            value={therapistDocument.endDate}
                        ></DateField>
                    </FieldSet>
                    {therapistDocument.departmentId && (
                        <FieldSet name="payType" errors={errorData} customClass="edit-therapist-field">
                            <InfiniteSelect
                                id="payTypeSelect"
                                items={payTypeOptions}
                                label="Pay Type"
                                value={payTypeOptions.find((item) => therapistDocument.payType === item.value)}
                                onChange={(item) => handleFieldChange({ name: 'payType', value: item.value })}
                            ></InfiniteSelect>
                        </FieldSet>
                    )}
                    <FieldSet errors={errorData} name="departmentId" customClass="edit-therapist-field">
                        <PaginationAutocomplete
                            fetchData={DepartmentService.getDepartmentOptions}
                            convertFunction={ConverterHelper.departmentConvert}
                            id="departmentSelect"
                            defaultValue=""
                            placeholder="Department"
                            name="department"
                            tabIndex=""
                            onDelete={() => {
                                handleFieldChange({ name: 'departmentId', value: undefined });
                                setShowSubDepartmentField(false);
                            }}
                            onSelect={(item) => {
                                handleFieldChange({ name: 'departmentId', value: item?.value });
                                setShowSubDepartmentField(item?.hasSubDepartments);
                            }}
                        />
                    </FieldSet>
                    {showSubDepartmentField && (
                        <FieldSet errors={errorData} name="subDepartmentId" customClass="edit-therapist-field">
                            <PaginationAutocomplete
                                fetchData={getSubDepartmentOptions}
                                convertFunction={ConverterHelper.subDepartmentFromOptionConvert}
                                fetchTrigger={therapistDocument.departmentId}
                                id="subDepartmentId"
                                defaultValue=""
                                placeholder="Sub Department"
                                name={OptionField.subDepartment}
                                tabIndex=""
                                onDelete={() => handleFieldChange({ name: 'subDepartmentId', value: undefined })}
                                onSelect={(item) => handleFieldChange({ name: 'subDepartmentId', value: item.value })}
                            />
                        </FieldSet>
                    )}
                    <FieldSet name="customerId" errors={errorData} customClass="edit-therapist-field">
                        <PaginationAutocomplete
                            fetchData={getCustomerOptions}
                            convertFunction={ConverterHelper.optionConvert}
                            id="customerId"
                            defaultValue=""
                            placeholder="Customer"
                            name="customer"
                            tabIndex=""
                            onDelete={() => handleFieldChange({ name: 'customerId', value: undefined })}
                            onSelect={(item) => handleFieldChange({ name: 'customerId', value: item.value })}
                        />
                    </FieldSet>
                    <FieldSet name="disciplineId" errors={errorData} customClass="edit-therapist-field">
                        <PaginationAutocomplete
                            fetchData={BaseService.getOptions}
                            convertFunction={ConverterHelper.optionConvert}
                            id="disciplineId"
                            defaultValue=""
                            placeholder="Discipline"
                            name={OptionField.discipline}
                            tabIndex=""
                            onDelete={() => handleFieldChange({ name: 'disciplineId', value: undefined })}
                            onSelect={(item) => handleFieldChange({ name: 'disciplineId', value: item.value })}
                        />
                    </FieldSet>
                    <FieldSet name="isOrientationRelated" errors={errorData} customClass="edit-therapist-field">
                        <CheckBoxField
                            id="isOrientationRelated"
                            placeholder="Orientation Related"
                            name="isOrientationRelated"
                            onChange={handleFieldChange}
                            value={therapistDocument.isOrientationRelated}
                        ></CheckBoxField>
                    </FieldSet>
                    <FieldSet name="isComplianceRelated" errors={errorData} customClass="edit-therapist-field">
                        <CheckBoxField
                            id="isComplianceRelated"
                            placeholder="Compliance Related"
                            name="isComplianceRelated"
                            onChange={handleFieldChange}
                            value={therapistDocument.isComplianceRelated}
                        ></CheckBoxField>
                    </FieldSet>
                    <FieldSet name="isOverrideAllow" errors={errorData} customClass="edit-therapist-field">
                        <CheckBoxField
                            id="isOverrideAllow"
                            placeholder="Allow Override"
                            name="isOverrideAllow"
                            onChange={handleFieldChange}
                            value={therapistDocument.isOverrideAllow}
                        ></CheckBoxField>
                    </FieldSet>
                    <FieldSet name="reason" errors={errorData} customClass="edit-therapist-field internal-note required-field-star">
                        <TextAreaField
                            maxLength={requestDocumentRules.reason.maxLength}
                            placeholder="Reason"
                            name="reason"
                            onChange={handleFieldChange}
                            value={therapistDocument.reason}
                        ></TextAreaField>
                    </FieldSet>
                </div>
            </RightSideBar>
            <div className="right-sidebar-overlay"></div>
        </div>
    );
};

export default RequestTherapistDocumentDialog;
