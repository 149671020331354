import React from 'react';
import { TotalCounterProps } from './types';

export const TotalCounter: React.FC<TotalCounterProps> = ({ title, value, className }) => {
    const classes = `total-counter ${className ? className : ''}`;

    return (
        <div className={classes}>
            <strong>{title} </strong>
            <span>{value}</span>
        </div>
    );
};

export default React.memo(TotalCounter);
