import React, { useEffect, useState } from 'react';
import DropdownOption from 'common/models/dropdown-option';
import { useDispatch } from 'react-redux';
import { ThunkDispatch } from 'redux-thunk';
import RightSideBar from 'common/components/right-side-bar/right-side-bar';
import DateField from 'common/components/field/date-field';
import FieldSet from 'common/components/field-set/field-set';
import FileField from 'common/components/field/file-field/file-field';
import InfiniteSelect from 'common/components/infinite-select/infinite-select';
import { trackPromise } from 'react-promise-tracker';
import { BackgroundCheckStatus } from 'common/constants/background-check-status';
import moment from 'moment';
import CheckBoxField from 'common/components/field/checkbox-field';
import InputField from 'common/components/field/input-field';
import { BackgroundCheckState } from 'models/enums/BackgroundCheckState';
import { BackgroundCheckDialogProps } from './types';
import { Action } from '@reduxjs/toolkit';
import { FieldValue } from 'common/models/field-value';
import { ITherapistBackgroundCheckInfo } from 'common/services/api/therapist-background-check/types';
import therapistBackgroundCheckService from 'common/services/api/therapist-background-check/therapist-background-check-service';
import { getTherapistDetailsRequest } from 'app/store/therapist/action-creators';

export const isEditNotAddedBackgroundCheck = (isEdit: boolean, backgroundCheck: any) => {
    return isEdit && backgroundCheck.state === BackgroundCheckState.Waiting;
};

export const determineDefaultCheckDate = (isEdit: boolean, backgroundCheck: any) => {
    if (isEdit && backgroundCheck.checkDate) {
        return new Date(backgroundCheck.checkDate);
    }

    if (isEditNotAddedBackgroundCheck(isEdit, backgroundCheck)) {
        return undefined;
    }

    return new Date();
};

export const determineDefaultExpirationDate = (isEdit: boolean, backgroundCheck: any) => {
    if (isEdit && backgroundCheck.checkDate) {
        return new Date(backgroundCheck.expirationDate);
    }

    if (isEditNotAddedBackgroundCheck(isEdit, backgroundCheck)) {
        return undefined;
    }

    return moment().add(backgroundCheck.expirationPeriod, 'months').toDate();
};

// TO-DO: Set tab indexes on the form
const BackgroundCheckDialog: React.FC<BackgroundCheckDialogProps> = ({ isEdit, isHistory, therapistId, backgroundCheck, onCancel }) => {
    let title = 'Add Background Check';
    if (isEdit) {
        title = isHistory ? 'Edit Background Check History' : 'Edit Background Check';
    }
    const [therapistBackgroundCheck, setTherapistBackgroundCheck] = useState<ITherapistBackgroundCheckInfo>({
        id: isEdit ? backgroundCheck.id : undefined,
        backgroundCheckRuleId: backgroundCheck.backgroundCheckRuleId,
        file: isEdit ? { name: backgroundCheck.originalFileName } : undefined,
        checkDate: determineDefaultCheckDate(isEdit, backgroundCheck),
        expirationDate: determineDefaultExpirationDate(isEdit, backgroundCheck),
        status: isEdit ? backgroundCheck.status : 'Passed',
        notApplicable: backgroundCheck?.notApplicable ?? false,
        notApplicableReason: backgroundCheck?.notApplicableReason || undefined,
    });

    const statuses = [
        new DropdownOption(BackgroundCheckStatus.PASSED, 'Passed', 'passed'),
        new DropdownOption(BackgroundCheckStatus.NOT_PASSED, 'Not Passed'),
    ];

    const [errorData, setErrorData] = useState<any>({});
    const dispatch = useDispatch<ThunkDispatch<any, any, Action>>();

    const handleFieldChange = (data: FieldValue) => {
        setTherapistBackgroundCheck((prevInfo) => ({ ...prevInfo, [data.name]: data.value }));
        setErrorData((prevError: any) => ({ ...prevError, [data.name]: undefined }));
    };

    useEffect(() => {
        if (!therapistBackgroundCheck.notApplicable) {
            setTherapistBackgroundCheck((prevInfo) => ({ ...prevInfo, notApplicableReason: undefined }));
        }
    }, [therapistBackgroundCheck.notApplicable]);

    const handleSave = () => {
        let saveRequest = therapistBackgroundCheckService.addTherapistBackgroundCheck;
        if (isEdit) {
            saveRequest = isHistory
                ? therapistBackgroundCheckService.updateTherapistBackgroundCheckHistory
                : therapistBackgroundCheckService.updateTherapistBackgroundCheck;
        }

        trackPromise(
            saveRequest(therapistId, therapistBackgroundCheck)
                .then(() => {
                    trackPromise(dispatch(getTherapistDetailsRequest(therapistId)));
                    onCancel();
                })
                .catch(setErrorData)
        );
    };

    const handleBackgroundCheckFields = () => {
        if (isEdit && backgroundCheck.state === BackgroundCheckState.Waiting) {
            return <></>;
        }

        return (
            <>
                <FieldSet errors={errorData} name="status" customClass="edit-therapist-field status-field">
                    <InfiniteSelect
                        items={statuses}
                        value={statuses.find((item) => therapistBackgroundCheck.status === item.value)}
                        onChange={(item) => handleFieldChange({ name: 'status', value: item.value })}
                    />
                </FieldSet>
                <FieldSet name="checkDate" errors={errorData} customClass="edit-therapist-field">
                    <DateField
                        placeholder="Check Date"
                        name="checkDate"
                        onChange={handleFieldChange}
                        value={therapistBackgroundCheck.checkDate}
                    />
                </FieldSet>
                <FieldSet name="expirationDate" errors={errorData} customClass="edit-therapist-field">
                    <DateField
                        placeholder="Expiration Date"
                        name="expirationDate"
                        onChange={handleFieldChange}
                        value={therapistBackgroundCheck.expirationDate}
                    />
                </FieldSet>
                <FieldSet errors={errorData} name="file" customClass="edit-therapist-field">
                    <FileField
                        name="file"
                        onChange={handleFieldChange}
                        placeholder="Upload or Drop File"
                        iconClass="icon-upload-file"
                        uploadedFileName={therapistBackgroundCheck.file?.name}
                        isError={errorData?.file}
                    />
                </FieldSet>
            </>
        );
    };

    return (
        <div className="therapist-background-check-wrapper">
            <RightSideBar title={title} onCancel={onCancel} onSave={handleSave}>
                <div className="edit-therapist-form">
                    {handleBackgroundCheckFields()}
                    {isEdit && (
                        <FieldSet name="notApplicable" errors={errorData} customClass="edit-therapist-field">
                            <CheckBoxField
                                placeholder="Not Applicable"
                                name="notApplicable"
                                onChange={handleFieldChange}
                                value={therapistBackgroundCheck.notApplicable}
                            />
                        </FieldSet>
                    )}
                    {therapistBackgroundCheck.notApplicable && isEdit && (
                        <FieldSet name="notApplicableReason" errors={errorData} customClass="edit-therapist-field">
                            <InputField
                                placeholder="Reason"
                                name="notApplicableReason"
                                onChange={handleFieldChange}
                                value={therapistBackgroundCheck.notApplicableReason}
                            />
                        </FieldSet>
                    )}
                </div>
            </RightSideBar>
            <div className="right-sidebar-overlay"></div>
        </div>
    );
};

export default BackgroundCheckDialog;
