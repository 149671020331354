import { ENTITY } from 'common/constants/common';
import React from 'react';
import { GeneralInfoTabProp } from '../types';
import NursingHomeRequestTable, { requestColumnDefs } from 'features/nursing-home-requests-page/components/nursing-home-requests-table';

const EntityNursingHomeRequestListTab = ({ details: entityDetails }: GeneralInfoTabProp) => {
    return (
        <div className="entity-child-tab tabs-active-horizontal table-page no-min-height">
            <NursingHomeRequestTable
                storePrefix="entity-nursing-home-requests"
                extendGridOptions={{
                    columnDefs: customerColumnDef,
                }}
                extendDefaultCustomFilters={{ entity: entityDetails?.name }}
                details={entityDetails}
                serachTitle="Entity Requests"
            />
        </div>
    );
};

const customerColumnDef = [...requestColumnDefs];
const indexCustomerName = requestColumnDefs.findIndex((columnConfig) => columnConfig.field === ENTITY);

if (indexCustomerName > -1) {
    customerColumnDef[indexCustomerName] = {
        field: ENTITY,
        headerName: 'Entity',
        cellRenderer: 'objectLinkValueRender',
        width: 280,
        sortable: false,
        hide: true,
    };
}

export default EntityNursingHomeRequestListTab;
