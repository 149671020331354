import React, { forwardRef, useEffect, useImperativeHandle, useState } from 'react';
import { IFilterParams } from 'ag-grid-community';

// Easy example for other developers, we don't use this filter
const PartialMatchFilter = forwardRef((props: IFilterParams, ref) => {
    const { filterChangedCallback } = props;
    const [filterText, setFilterText] = useState('');

    // expose AG Grid Filter Lifecycle callbacks
    useImperativeHandle(ref, () => {
        return {
            isFilterActive() {
                return filterText != null && filterText !== '';
            },

            getModel() {
                return { value: filterText };
            },

            setModel(model: any) {
                setFilterText(model.value);
            },
        };
    });

    const handleInputChange = (event: any) => {
        setFilterText(event.target.value);
    };

    useEffect(() => {
        filterChangedCallback();
    }, [filterText, filterChangedCallback]);

    return (
        <div>
            <div>
                <input type="text" value={filterText} onChange={handleInputChange} placeholder="Some text..." />
            </div>
        </div>
    );
});

export default PartialMatchFilter;
