import React from 'react';
import { IAutopopulateDropdownOptionProps } from './types';

const generateText = (text: string = '', highlight: string) => {
    if (!text) {
        return;
    }
    const strMatchFromIndex = text.toLowerCase().indexOf(highlight?.toLowerCase());
    if (strMatchFromIndex !== -1) {
        const end = text.slice(strMatchFromIndex + highlight?.length).replace(' ', '\u00a0');
        const match = text.slice(strMatchFromIndex, strMatchFromIndex + highlight?.length).replace(' ', '\u00a0');
        const start = text.slice(0, strMatchFromIndex).replace(' ', '\u00a0');

        return (
            <p className="autopopulate-text-wrapper">
                {start}
                <span className="bold">{match}</span>
                {end}
            </p>
        );
    } else {
        return <span>{text}</span>;
    }
};

const AutopopulateDropdownOption: React.FC<IAutopopulateDropdownOptionProps> = ({
    asCheckbox,
    item,
    onOptionClick,
    checked,
    inputValue,
}) => {
    return (
        <span className="option-item" onClick={() => onOptionClick(item)}>
            {asCheckbox && <input type="checkbox" className="nps-checkbox" id={item.label} checked={checked} readOnly />}
            <label htmlFor={item.label}>
                <div className="option-item-value ">{generateText(item.label, inputValue)}</div>
            </label>
            <div className="option-item-value ">{generateText(item.value, inputValue)}</div>
            <span className="option-item-btn">Use</span>
        </span>
    );
};

export default AutopopulateDropdownOption;
