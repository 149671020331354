import { GridApi, GridOptions, GridReadyEvent, IServerSideGetRowsParams } from 'ag-grid-community';
import AgTable from 'common/components/ag-table/ag-table';
import { IParamsWatcher } from 'common/components/ag-table/types';
import AddButton from 'common/components/header/add-button/add-button';
import ClearFiltersButton from 'common/components/header/clear-filters-button/clear-filters-button';
import HeaderLeftSide from 'common/components/header/header-left-side';
import HeaderRightSide from 'common/components/header/header-right-side';
import HeaderWrapper from 'common/components/header/header-wrapper';
import SearchFilterInput from 'common/components/header/search-filter-input/search-filter-input';
import InfiniteSelect from 'common/components/infinite-select/infinite-select';
import { IDefaultFilters } from 'common/constants/types';
import { FilterHelper } from 'common/helpers/filter-helper';
import { LocalStoreRepository } from 'common/helpers/repository/local-store-repository';
import { LocalTableRepository } from 'common/helpers/repository/local-table-repository';
import { useSearchInput } from 'common/hooks/use-serch-input';
import DropdownOption from 'common/models/dropdown-option';
import { getFilterData } from 'common/services/api/tabel-fetch-service';
import therapistAssignmentService from 'common/services/api/therapist-assignment/therapist-assignment-service';
import { ITherapistAssignment } from 'common/services/api/therapist-assignment/types';
import { TherapistStatus } from 'features/therapist-details-page/shared/constants';
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { AssignmentListColumnsDefenitions } from './list-column-defenition';
import CustomerLinkRederer from './rederers/customer-link-rederer';
import EffectiveDatesRenderer from './rederers/effective-dates-renderer';
import RateRenderer from './rederers/rate-renderer';
import { AssignmentActions, AssignmentsListProps } from './types';

export const getAssignments = (therapistId: number) => {
    return (
        event: IServerSideGetRowsParams,
        { pageIndex, limit, getFilterModelParams, getSortParams, getCustomFilterParams, request }: any
    ) => {
        const pagination = { pageIndex: pageIndex, limit: limit };

        const queryParams = FilterHelper.toURLQueryParams({
            ...getSortParams(request),
            ...getFilterModelParams(request),
            ...getCustomFilterParams,
            ...pagination,
        }).join('&');

        return therapistAssignmentService.getAssignments(therapistId, queryParams).then((data) => ({
            data: data,
        }));
    };
};

const tableRepository = new LocalTableRepository(`therapist-assignment-table`, 'v2');
const filterRespoistory = new LocalStoreRepository(`therapist-assignment-filter`);

const AssignmentList: React.FC<AssignmentsListProps> = ({ therapistId, therapistStatus, forceUpdate, onAddClick, onEditClick }) => {
    const handleFetchData = getAssignments(therapistId);
    const handleFetchFilters = getFilterData(`therapists/${therapistId}/assignments/filter/source`);

    const [gridApi, setGridApi] = useState<GridApi>(null);
    const [gridColumnApi, setColumnApi] = useState(null);
    const [filter, setFilters] = useState<IDefaultFilters>(filterRespoistory.load() || {});
    const paramsWatcher = useRef<IParamsWatcher>();

    const { search, handleSearchInputChange, setSearch } = useSearchInput(filter?.search || '', (value: string) => {
        setFilters((prev) => ({ ...prev, search: value }));
    });

    useEffect(() => {
        if (gridApi || forceUpdate) {
            gridApi.onFilterChanged();
        }
    }, [gridApi, forceUpdate]);

    useEffect(() => {
        filterRespoistory.save(filter);
    }, [filter]);

    const handleGridReady = (params: GridReadyEvent) => {
        setGridApi(params.api);
        setColumnApi(params.columnApi);
    };

    const getOptionList = useCallback((): DropdownOption[] => {
        const itemOptions = [];

        if (therapistStatus !== TherapistStatus.INACTIVE.value) {
            itemOptions.push(new DropdownOption(AssignmentActions.EDIT, AssignmentActions.EDIT));
        }

        return itemOptions;
    }, [therapistStatus]);

    const handleOptionClick = useCallback(
        (value: string, assignment: ITherapistAssignment) => {
            switch (value) {
                case AssignmentActions.EDIT:
                    onEditClick(assignment);
                    break;
                default:
                    break;
            }
        },
        [onEditClick]
    );

    const gridOptions: GridOptions = useMemo(
        () => ({
            columnDefs: AssignmentListColumnsDefenitions,
            defaultColDef: {
                resizable: true,
                filterParams: {
                    onFetchFilters: handleFetchFilters,
                },
            },
            frameworkComponents: {
                customersLinkRenderer: CustomerLinkRederer,
                effectiveDatesRenderer: EffectiveDatesRenderer,
                rateRenderer: RateRenderer,
                doteRender: (props: any) => {
                    const itemOptions = getOptionList();

                    return (
                        itemOptions.length > 0 && (
                            <InfiniteSelect
                                id="therapistDocumentMenu"
                                popper
                                items={itemOptions}
                                className="no-select-border"
                                icon={<i className="icon icon-options" />}
                                onChange={(item: DropdownOption) => {
                                    handleOptionClick(item?.value, props.data);
                                }}
                            />
                        )
                    );
                },
            },
        }),
        [getOptionList, handleFetchFilters, handleOptionClick]
    );

    const handleClearFilter = () => {
        setSearch('');
        setFilters({ search: '' });
    };

    return (
        <>
            <div className="main-info-wrapper ag-table-wrapper">
                <HeaderWrapper>
                    <HeaderLeftSide>
                        <SearchFilterInput title="Assignments" value={search} onInput={handleSearchInputChange} />
                    </HeaderLeftSide>
                    <HeaderRightSide>
                        <ClearFiltersButton
                            title="Assignments"
                            gridApi={gridApi}
                            gridColumnApi={gridColumnApi}
                            onClick={handleClearFilter}
                        />
                        <AddButton onClick={onAddClick} title="Add Assignment" />
                    </HeaderRightSide>
                </HeaderWrapper>
                <AgTable
                    onGridReady={handleGridReady}
                    gridOptions={gridOptions}
                    customFilters={filter}
                    repository={tableRepository}
                    paramsWatcher={paramsWatcher}
                    onFetchData={handleFetchData}
                />
            </div>
        </>
    );
};

export default React.memo(AssignmentList);
