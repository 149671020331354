import React from 'react';
import CustomSmallDropdown from 'common/components/CustomSmallDropdown/CustomSmallDropdown';

const RightSideBarHeaderViewMode = ({ title, onCancel, actions, subtext }) => {
    const handleClickOnActionOption = (value) => {
        const clickedItem = actions.find((i) => i.title === value);

        if (clickedItem) {
            clickedItem.handler();
        }
    };

    return (
        <div className="right-side-bar-header">
            <button className="base-text-btn white icon icon-close" onClick={onCancel}></button>
            <span className="right-side-bar-title">{title}</span>
            {subtext && <div className="header-subtext">{subtext}</div>}
            {actions && actions.length > 0 && (
                <div className="actions">
                    <CustomSmallDropdown
                        iconClass="icon-options"
                        buttonId="customSmallDropdownHeaderButton"
                        optionsList={actions.map((item) => item.title)}
                        onOptionClick={handleClickOnActionOption}
                    />
                </div>
            )}
        </div>
    );
};

export default RightSideBarHeaderViewMode;
