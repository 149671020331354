export class StringHelper {
    static camelize(str: string) {
        return str
            .replace(/(?:^\w|[A-Z]|\b\w)/g, function (word, index) {
                return index === 0 ? word.toLowerCase() : word.toUpperCase();
            })
            .replace(/\s+/g, '');
    }
}

export const getUniqueName = (addStr: string = '') => {
    return (
        addStr +
        Math.random().toString(36) +
        Math.random()
            .toString(36)
            .replace(/[^a-z]+/g, '')
            .substr(0, 100)
    );
};
