import { IFilterComp } from 'ag-grid-community';
import { GridApi } from 'ag-grid-community';
import React, { useEffect, useState } from 'react';
import CheckBoxField from '../field/checkbox-field';
import './filter-checkbox.scss';

export type FilterCheckboxProps = {
    gridApi: GridApi;
    columnName: string;
    filterValue: string;
};

const FilterCheckbox = ({ gridApi, columnName, filterValue }: FilterCheckboxProps) => {
    const [filterChecked, setFilterChecked] = useState<boolean>(false);

    useEffect(() => {
        if (!gridApi) {
            return;
        }

        const handleFilterChange = (e: any) => {
            e.api.getFilterInstance(columnName, (filterApi: IFilterComp) => {
                if (filterApi.getModel()?.values?.includes(filterValue)) {
                    setFilterChecked(true);
                } else {
                    setFilterChecked(false);
                }
            });
        };

        gridApi.addEventListener('filterChanged', handleFilterChange);

        return () => {
            gridApi.removeEventListener('filterChanged', handleFilterChange);
        };
    }, [gridApi, columnName, filterValue]);

    const filterCheckboxChanged = ({ value }: any) => {
        if (!gridApi) return;

        if (value) {
            gridApi.getFilterInstance(columnName, (filterApi: IFilterComp) => {
                filterApi.setModel({
                    ...filterApi.getModel(),
                    values: [filterValue],
                });
            });
        } else {
            gridApi.getFilterInstance(columnName, (filterApi: IFilterComp) => {
                filterApi.setModel(undefined);
            });
        }
        gridApi.onFilterChanged();
    };

    const handleOnClick = () => {
        filterCheckboxChanged({ value: !filterChecked });
        setFilterChecked(!filterChecked);
    };

    return (
        <div className="filter-checkbox" onClick={handleOnClick}>
            <span>{filterValue}</span>
            <CheckBoxField classic label=" " value={filterChecked} onChange={filterCheckboxChanged} />
        </div>
    );
};

export default FilterCheckbox;
