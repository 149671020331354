import { updateTherapistHasAssignments } from 'app/store/therapist/action-creators';
import FieldSet from 'common/components/field-set/field-set';
import DateField from 'common/components/field/date-field';
import DropdownAutocomplete from 'common/components/field/dropdown/dropdown-autocomplete';
import PaginationAutocomplete from 'common/components/field/pagination-autocomplete/pagination-autocomplete';
import InfiniteSelect from 'common/components/infinite-select/infinite-select';
import RightSideBar from 'common/components/right-side-bar/right-side-bar';
import { OptionField } from 'common/constants/option-fields';
import { ConverterHelper } from 'common/helpers/converter-helper';
import { DateTimeHelper } from 'common/helpers/date-time-helper';
import { usePopulationData } from 'common/hooks/use-population-data';
import DepartmentDropdownOption from 'common/models/department-dropdown-option';
import DropdownOption from 'common/models/dropdown-option';
import BaseService from 'common/services/api/base/base-service';
import DepartmentService from 'common/services/api/department/department-service';
import EntityService from 'common/services/api/entity/entity-service';
import { ICreateEditTherapistAssignment } from 'common/services/api/therapist/types';
import { EmploymentStatuses } from 'models/enums/employment-statuses';
import { PayTypes } from 'models/enums/pay-types';
import React, { useEffect, useState } from 'react';
import { trackPromise } from 'react-promise-tracker';
import { useDispatch } from 'react-redux';
import { CreateEditAssignmentDialogProps } from './types';

// TO-DO: Set tab indexes on the form
const CreateEditAssignmentDialog: React.FC<CreateEditAssignmentDialogProps> = ({
    title,
    onCancel,
    therapistId,
    onAssignmentSaved,
    therapistAssignment,
    onSave,
}) => {
    const defaultEndDate = DateTimeHelper.getDefaultEndDate(); // create without timezone offset
    const [assignment, setAssignment] = useState<ICreateEditTherapistAssignment>({
        id: therapistAssignment?.id,
        departmentId: therapistAssignment?.department?.id,
        department: therapistAssignment?.department ? ConverterHelper.departmentConvert(therapistAssignment?.department) : undefined,
        subDepartmentIds: therapistAssignment?.subDepartments?.map((item) => item.id),
        subDepartmentOptions: therapistAssignment?.subDepartments?.map((item) => ConverterHelper.optionConvert(item)) || [],
        rate: therapistAssignment?.rate,
        effectiveDate: therapistAssignment?.effectiveDate ? new Date(therapistAssignment.effectiveDate) : undefined,
        endDate: therapistAssignment?.endDate ? new Date(therapistAssignment.endDate) : defaultEndDate,
        customerIds: therapistAssignment?.customers?.map((item) => item.id),
        customerOptions: therapistAssignment?.customers.map((item) => ConverterHelper.optionConvert(item)) || [],
        payType: therapistAssignment?.payType,
        employmentStatus: therapistAssignment?.employmentStatus,
        orientation: therapistAssignment?.orientation,
        populationIds: therapistAssignment?.populations.map((i) => i.id) || [],
    });

    const populationsOptions = usePopulationData().map((item) => new DropdownOption(item.id, item.name));

    const employmentStatuses = Object.values(EmploymentStatuses).map((x) => new DropdownOption(x.value, x.label));
    const [subDepartmentOptions, setSubDepartmentOptions] = useState([]);
    const [subDepartmentTerm, setSubDepartmentTerm] = useState('');
    const [subDepartmentsPageIndex, setSubDepartmentsPageIndex] = useState(0);

    const [customerOptions, setСustomerOptions] = useState([]);
    const [customerTerm, setCustomerTerm] = useState('');
    const [customersPageIndex, setCustomersPageIndex] = useState(0);

    const dispatch = useDispatch();
    const pageSize = 20;

    const handleScrollSubDepartmentsSelect = () => {
        const index = subDepartmentsPageIndex + 1;
        setSubDepartmentsPageIndex(index);
        BaseService.getOptions(subDepartmentTerm, index * pageSize, pageSize, OptionField.subDepartment, [
            assignment.department.value,
        ]).then((options) => {
            setSubDepartmentOptions([...subDepartmentOptions, ...options.map((item) => ConverterHelper.optionConvert(item))]);
        });
    };

    useEffect(() => {
        if (assignment.department) {
            BaseService.getOptions(subDepartmentTerm, 0, pageSize, OptionField.subDepartment, [assignment.department.value]).then(
                (options) => {
                    setSubDepartmentOptions([...options.map((item) => ConverterHelper.optionConvert(item))]);
                }
            );
        }
    }, [subDepartmentTerm, assignment.department]);

    const payTypeOptions = [
        new DropdownOption(PayTypes.W2.value, PayTypes.W2.label),
        new DropdownOption(PayTypes.Pay1099.value, PayTypes.Pay1099.label),
    ];

    const orientationOptions = [new DropdownOption('Incompleted', 'Incompleted'), new DropdownOption('Completed', 'Completed')];

    const [errorData, setErrorData] = useState({});

    const handleFieldChange = (data: any) => {
        setAssignment((prevInfo) => ({ ...prevInfo, [data.name]: data.value }));
        setErrorData((prevError) => ({ ...prevError, [data.name]: undefined }));
    };

    const handleDepartmentFieldChange = (department: DepartmentDropdownOption) => {
        setAssignment((prevInfo) => ({ ...prevInfo, department }));

        if (department) {
            if (!department || !department.isOrientationRequired) {
                setAssignment((prevInfo) => ({ ...prevInfo, orientation: undefined }));
            } else {
                setAssignment((prevInfo) => ({ ...prevInfo, orientation: 'Incompleted' }));
            }

            setAssignment((prevInfo) => ({ ...prevInfo, subDepartmentOptions: [] }));
        }
    };

    const handleSave = () => {
        trackPromise(
            dispatch(
                onSave(therapistId, {
                    ...assignment,
                    departmentId: assignment.department?.value,
                    subDepartmentIds: assignment.subDepartmentOptions?.map((x) => x.value),
                    customerIds: assignment.customerOptions?.map((x) => x.value),
                })
            )
                .then(() => {
                    dispatch(updateTherapistHasAssignments(true));
                    onAssignmentSaved();
                })
                .catch(setErrorData)
        );
    };

    const handleScrollCustomerSelect = () => {
        const index = customersPageIndex + 1;
        setCustomersPageIndex(index);

        EntityService.getEntityOptions(customerTerm, index * pageSize, pageSize).then((options) => {
            setСustomerOptions([...customerOptions, ...options.map((item) => ConverterHelper.optionConvert(item))]);
        });
    };

    useEffect(() => {
        EntityService.getEntityOptions(customerTerm, 0, pageSize).then((options) => {
            setСustomerOptions([...options.map((item) => ConverterHelper.optionConvert(item))]);
        });
    }, [customerTerm]);

    return (
        <div>
            <RightSideBar title={title} onCancel={onCancel} onSave={handleSave}>
                <div className="edit-therapist-form">
                    <FieldSet errors={errorData} name="departmentId" customClass="edit-therapist-field required-field-star">
                        <PaginationAutocomplete
                            cleanable={false}
                            fetchData={DepartmentService.getDepartmentOptions}
                            convertFunction={ConverterHelper.departmentConvert}
                            id="departmentSelect"
                            defaultValue={therapistAssignment?.department?.name}
                            placeholder="Department"
                            name={'department'}
                            tabIndex=""
                            onDelete={() => handleDepartmentFieldChange(undefined)}
                            onSelect={(item) => {
                                handleDepartmentFieldChange(item);
                            }}
                        />
                    </FieldSet>
                    {subDepartmentOptions?.length > 0 && (
                        <FieldSet errors={errorData} name="subDepartmentIds" customClass="edit-therapist-field required-field-star">
                            <DropdownAutocomplete
                                id="subDepartmentSelect"
                                items={subDepartmentOptions}
                                label={'Sub Department'}
                                multiselect={true}
                                cleanable={false}
                                searchPlaceholder={'Search Sub Department'}
                                value={assignment.subDepartmentOptions}
                                onChange={setSubDepartmentTerm}
                                onSelect={(items: DropdownOption) => handleFieldChange({ name: 'subDepartmentOptions', value: items })}
                                onScrollEnd={handleScrollSubDepartmentsSelect}
                                onClose={() => setSubDepartmentsPageIndex(0)}
                            ></DropdownAutocomplete>
                        </FieldSet>
                    )}
                    <FieldSet name="customerSelect" errors={errorData} customClass="edit-therapist-field">
                        <DropdownAutocomplete
                            id="customerSelect"
                            items={customerOptions}
                            label={'Customer'}
                            multiselect={true}
                            searchPlaceholder={'Search Customer'}
                            value={assignment.customerOptions}
                            onChange={setCustomerTerm}
                            onSelect={(items: DropdownOption) => handleFieldChange({ name: 'customerOptions', value: items })}
                            onScrollEnd={handleScrollCustomerSelect}
                            onClose={() => setCustomersPageIndex(0)}
                        ></DropdownAutocomplete>
                    </FieldSet>
                    <FieldSet name="payType" errors={errorData} customClass="edit-therapist-field required-field-star">
                        <InfiniteSelect
                            id="payTypeSelect"
                            items={payTypeOptions}
                            label="Pay Type"
                            value={payTypeOptions.find((item) => assignment.payType === item.value)}
                            onChange={(item) => handleFieldChange({ name: 'payType', value: item.value })}
                        ></InfiniteSelect>
                    </FieldSet>
                    <FieldSet name="effectiveDate" errors={errorData} customClass="edit-therapist-field required-field-star">
                        <DateField
                            id="effectiveDate"
                            placeholder="Effective Date"
                            name="effectiveDate"
                            onChange={handleFieldChange}
                            value={assignment.effectiveDate}
                        ></DateField>
                    </FieldSet>
                    <FieldSet name="endDate" errors={errorData} customClass="edit-therapist-field">
                        <DateField
                            id="endDate"
                            placeholder="End Date"
                            name="endDate"
                            onChange={handleFieldChange}
                            value={assignment.endDate}
                        ></DateField>
                    </FieldSet>
                    <FieldSet name="employmentStatus" errors={errorData} customClass="edit-therapist-field required-field-star">
                        <InfiniteSelect
                            id="employmentStatus"
                            items={employmentStatuses}
                            label="Employment Status"
                            value={employmentStatuses.find((item) => assignment.employmentStatus === item.value)}
                            onChange={(item) => handleFieldChange({ name: 'employmentStatus', value: item.value })}
                        ></InfiniteSelect>
                    </FieldSet>
                    <FieldSet name="populationIds" errors={errorData} customClass="edit-therapist-field required-field-star">
                        <InfiniteSelect
                            id="populationsSelect"
                            items={populationsOptions}
                            multiselect={true}
                            label="Population"
                            value={populationsOptions.filter((item) => assignment.populationIds.some((id) => id === item.value))}
                            onChange={(item: any) => handleFieldChange({ name: 'populationIds', value: item.map((i: any) => i.value) })}
                        ></InfiniteSelect>
                    </FieldSet>
                    {assignment?.department?.isOrientationRequired && (
                        <FieldSet name="orientation" errors={errorData} customClass="edit-therapist-field">
                            <InfiniteSelect
                                items={orientationOptions}
                                label="Orientation"
                                value={orientationOptions.find((item) => assignment.orientation === item.value)}
                                onChange={(item) => handleFieldChange({ name: 'orientation', value: item.value })}
                            ></InfiniteSelect>
                        </FieldSet>
                    )}
                </div>
            </RightSideBar>
            <div className="right-sidebar-overlay"></div>
        </div>
    );
};

export default CreateEditAssignmentDialog;
