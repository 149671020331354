import Breadcrumb from 'common/components/Breadcrumb/breadcrumb';
import Tab from 'common/components/tabs/tab';
import Tabs from 'common/components/tabs/tabs';
import React, { useEffect } from 'react';
import { Link, useParams } from 'react-router-dom';
import PatientGeneralInfoTab from './general-info-tab/patient-general-info-tab';
import './patient-details.scss';
import PatientHeaderInfo from './patient-header-info/patient-header-info';
import { IPatientParamType } from './types';
import { fetchPatientDetails } from 'app/store/patients-details/action-creators';
import { useDispatch, useSelector } from 'react-redux';
import { trackPromise } from 'react-promise-tracker';
import { ThunkDispatch } from 'redux-thunk';
import { Action } from '@reduxjs/toolkit';
import PatientRequestsTab from './requests-tab/patient-requests-tab';
import { NavigationRoutes } from 'models/routes/navigation-routes';
import ServerNavigation from 'common/components/details-navigation/server-navigation';
import { IToggleFeatureList } from 'common/services/api/settings/types';

const PatientDetailsPage = () => {
    const dispatch = useDispatch<ThunkDispatch<any, any, Action>>();
    const patient = useSelector((state: any) => state.patientDetails.patient);
    const { patientId } = useParams<IPatientParamType>();
    const patientDetails = patient?.id !== Number.parseInt(patientId) ? null : patient;

    const featureList: IToggleFeatureList = useSelector((state: any) => state.siteSettings?.settings?.features || {});

    useEffect(() => {
        trackPromise(dispatch(fetchPatientDetails(Number.parseInt(patientId))));
    }, [dispatch, patientId]);

    return (
        <div className="details patient-details">
            <header>
                {featureList.isDetailsNavigationEnabled && (
                    <ServerNavigation
                        entityApiBaseUrl="patients"
                        entityId={parseInt(patientId)}
                        navigationRoute={NavigationRoutes.patientDetailsRoute}
                    />
                )}
                {breadcrumb}
                {patientDetails && <PatientHeaderInfo details={patientDetails} />}
            </header>
            <Tabs orientation="horizontal">
                <Tab linkClassName={'custom-link'} text="General Info">
                    {patientDetails && <PatientGeneralInfoTab patientId={Number.parseInt(patientId)} patientDetails={patientDetails} />}
                </Tab>
                <Tab linkClassName={'custom-link'} text="Requests">
                    {patientDetails && <PatientRequestsTab patientId={Number.parseInt(patientId)} patientDetails={patientDetails} />}
                </Tab>
            </Tabs>
        </div>
    );
};

const items = [
    { to: '/patients', label: 'Patient' },
    { to: '', label: 'Patient Details' },
];

const breadcrumb = (
    <div className="header-mock">
        <Breadcrumb>
            {items.map(({ to, label }) => (
                <Link key={to} to={to}>
                    {label}
                </Link>
            ))}
        </Breadcrumb>
    </div>
);

export default PatientDetailsPage;
