import React from 'react';
import { PageHeaderProps } from './types';

const PageHeader: React.FC<PageHeaderProps> = ({ title }) => {
    return (
        <header>
            <div className="header-wrapper">
                <div className="title-wrapper">
                    <h1>{title}</h1>
                </div>
            </div>
        </header>
    );
};

export default React.memo(PageHeader);
