import { ICellRendererParams } from 'ag-grid-community';

const EmailRender = (props: ICellRendererParams) => {
    const emailValue = props?.data?.emails;
    const mainEmail = emailValue?.find((p: any) => p?.seq === 1);

    return emailValue?.length > 0 && mainEmail?.email;
};

export default EmailRender;
