import React, { useRef } from 'react';
import NursingHomeRequestTable from './components/nursing-home-requests-table';
import { ITableWrapperReady } from '../../common/constants/types';

const NursingHomeRequestsPage = () => {
    const handleClickAddRequest = useRef((_value: boolean) => {});

    const handleOnGridReady = ({ extraParams }: ITableWrapperReady) => {
        handleClickAddRequest.current = extraParams?.setCreateDialogOpen;
    };

    return (
        <div className="therapist-page request-page">
            <header>
                <div className="header-wrapper">
                    <div className="title-wrapper">
                        <h1>Requests</h1>
                    </div>
                    <button
                        className="create-therapist-btn base-text-btn blue"
                        id="createNewRequestBtn"
                        onClick={() => handleClickAddRequest?.current?.(true)}
                    >
                        Add Request
                    </button>
                </div>
            </header>
            <NursingHomeRequestTable onGridReady={handleOnGridReady} />
        </div>
    );
};

export default NursingHomeRequestsPage;
