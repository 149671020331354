import { AnyAction } from '@reduxjs/toolkit';
import { ITherapistDetailsModel } from 'common/services/api/therapist/types';
import { Reducer } from 'react';
import {
    GET_THERAPIST_ASSIGNMENTS_SUCCESS,
    GET_THERAPIST_BACKGROUND_CHECK_SUCCESS,
    GET_THERAPIST_DETAILS_SUCCESS,
    GET_THERAPIST_DOCUMENTS_SUCCESS,
    ITherapistDetailsState,
    UPDATE_INACTIVE_REASON,
    UPDATE_THERAPIST_ASSIGNED_TO,
    UPDATE_THERAPIST_HAS_ASSIGNMENTS,
    UPDATE_THERAPIST_PROFILE_PHOTO_SUCCESS,
    UPDATE_THERAPIST_STATUS,
    UPDATE_THERAPIST_SUCCESS,
} from './types';

const initialState: ITherapistDetailsState = {
    therapist: null,
    assignmentTab: {
        data: [],
    },
    documentsTab: {
        data: [],
    },
    backgroundChecksTab: {
        data: [],
    },
};

function therapistReducer(state: ITherapistDetailsModel, action: AnyAction) {
    switch (action.type) {
        case GET_THERAPIST_DETAILS_SUCCESS:
            return { ...state, ...action.therapist };
        case UPDATE_THERAPIST_SUCCESS:
            return { ...state, ...action.therapist };
        case UPDATE_THERAPIST_ASSIGNED_TO:
            return { ...state, assignedTo: action.assignedTo };
        case UPDATE_THERAPIST_STATUS:
            return { ...state, status: action.status };
        case UPDATE_THERAPIST_HAS_ASSIGNMENTS:
            return { ...state, hasAssignments: action.hasAssignments };
        case UPDATE_THERAPIST_PROFILE_PHOTO_SUCCESS:
            return { ...state, generalInfo: { ...state.generalInfo, profilePhoto: action.profilePhoto } };
        case UPDATE_INACTIVE_REASON:
            return {
                ...state,
                inactiveReasons: action.inactiveReasons,
                inactiveReasonComment: action.inactiveReasonComment,
            };
        default:
            return state;
    }
}

function assignmentTabReducer(state: { data: any[] }, action: AnyAction) {
    switch (action.type) {
        case GET_THERAPIST_ASSIGNMENTS_SUCCESS:
            return { ...state, data: action.data };
        default:
            return state;
    }
}

function backgroudChecksTabReducer(state: { data: any[] }, action: AnyAction) {
    switch (action.type) {
        case GET_THERAPIST_BACKGROUND_CHECK_SUCCESS:
            return { ...state, data: action.data };
        default:
            return state;
    }
}

function documentsTabReducer(state: { data: any[] }, action: AnyAction) {
    switch (action.type) {
        case GET_THERAPIST_DOCUMENTS_SUCCESS:
            return { ...state, data: action.data };
        default:
            return state;
    }
}

export const therapistDetailsReducer: Reducer<ITherapistDetailsState, AnyAction> = (state = initialState, action) => {
    return {
        therapist: therapistReducer(state.therapist, action),
        assignmentTab: assignmentTabReducer(state.assignmentTab, action),
        backgroundChecksTab: backgroudChecksTabReducer(state.backgroundChecksTab, action),
        documentsTab: documentsTabReducer(state.documentsTab, action),
    };
};
