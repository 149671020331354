import { InfiniteServerClient } from 'common/helpers/http-clients';
import {
    IEntityContactDetails,
    IEntityContactForm,
    IEntityContactOption,
    IEntityContactWithRoleOption,
    IEntityExistingContact,
} from './type';
import { SequenceHelper } from 'common/helpers/sequence-helper';

export class EntityContactService {
    getContactOptions(
        term?: string,
        skip?: number,
        take?: number,
        legalEntityId?: number,
        isActive: boolean = true
    ): Promise<IEntityContactOption[]> {
        return InfiniteServerClient.get(`/contacts/entities/options`, { params: { legalEntityId, term, skip, take, isActive } }).then(
            (response) => {
                return response.data;
            },
            (error) => {
                throw error;
            }
        );
    }

    getContactWithRole(
        term?: string,
        skip?: number,
        take?: number,
        entityId?: number,
        facilityId?: number,
        isActive: boolean = true
    ): Promise<IEntityContactWithRoleOption[]> {
        return InfiniteServerClient.get(`/entities/${entityId}/contacts/options`, {
            params: { facilityId, term, skip, take, isActive },
        }).then(
            (response) => {
                return response.data;
            },
            (error) => {
                throw error;
            }
        );
    }

    getContactDetails(entityId: number, contactId: number): Promise<IEntityContactDetails> {
        return InfiniteServerClient.get(`/entities/${entityId}/contacts/${contactId}/details`).then(
            (response) => {
                return {
                    ...response.data,
                    contact: {
                        ...response.data?.contact,
                        emails: SequenceHelper.getSequenceItems(response.data?.contact?.emails),
                        phones: SequenceHelper.getSequenceItems(response.data?.contact?.phones),
                    },
                };
            },
            (error) => {
                throw error;
            }
        );
    }

    createContact(entityId: number, entityFormData: IEntityContactForm) {
        SequenceHelper.setSequenceItems(entityFormData?.phones);
        SequenceHelper.setSequenceItems(entityFormData?.emails);

        return InfiniteServerClient.post(`/entities/${entityId}/contacts`, entityFormData).then(
            (response) => {
                return response.data;
            },
            (error) => {
                throw error;
            }
        );
    }

    addExistingContacts(entityId: number, existingContacts: IEntityExistingContact[]) {
        return InfiniteServerClient.post(`/entities/${entityId}/contacts/existing`, { existingContacts }).then(
            (response) => {
                return response.data;
            },
            (error) => {
                throw error;
            }
        );
    }

    editContact(entityId: number, contactId: number, entityFormData: IEntityContactForm) {
        SequenceHelper.setSequenceItems(entityFormData?.phones);
        SequenceHelper.setSequenceItems(entityFormData?.emails);

        return InfiniteServerClient.put(`/entities/${entityId}/contacts/${contactId}`, entityFormData).then(
            (response) => {
                return response.data;
            },
            (error) => {
                throw error;
            }
        );
    }

    archive(entityId: number, contactId: number) {
        return InfiniteServerClient.put(`/entities/${entityId}/contacts/${contactId}/statuses`, {
            status: 'Archived',
        }).then(
            (response) => {
                return response.data;
            },
            (error) => {
                throw error;
            }
        );
    }
}

export default new EntityContactService();
