export const overrideDocumentRules = {
    comment: {
        maxLength: 500,
    },
};

export const requestDocumentRules = {
    reason: {
        maxLength: 255,
    },
};
