import React from 'react';
import './header.scss';

type HeaderWrapperProps = {
    className?: string;
    children: React.ReactNode;
};

const HeaderWrapper = ({ children, className = '' }: HeaderWrapperProps) => {
    return <div className={`table-header-top-bar new-table-header-top-bar ${className}`}>{children}</div>;
};

export default HeaderWrapper;
