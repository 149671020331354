import React, { useEffect } from 'react';
import { IForm } from 'common/models/types';
import FieldSet from 'common/components/field-set/field-set';
import InputField from 'common/components/field/input-field';
import DropdownOption from 'common/models/dropdown-option';
import PaginationAutocomplete from 'common/components/field/pagination-autocomplete/pagination-autocomplete';
import { useTabIndex } from 'common/hooks/use-tab-index';
import { generalValidationRules } from 'common/validation/general-validation-rules';
import NumberField from 'common/components/field/number-field';
import { ConverterHelper } from 'common/helpers/converter-helper';
import LanguageService from 'common/services/api/language/language-service';
import { IProvider, ISchoolNewRequestDataModel } from 'common/services/api/school/types';
import DateField from 'common/components/field/date-field';
import TextAreaField from 'common/components/field/text-area-field';
import schoolService from 'common/services/api/school/school-service';
import { ObjectHelper } from 'common/helpers/object-helper';

const names: {
    [key: string]: keyof ISchoolNewRequestDataModel;
} = {
    schoolYear: 'year',
    serviceType: 'serviceTypeId',
    language: 'languageId',
    bidRank: 'bidRankId',
    population: 'populationId',
    priorityLevel: 'priorityLevelId',
    contractType: 'contractTypeId',
    doeContact: 'doeContactId',
    startDate: 'startDate',
    endDate: 'endDate',
    schoolName: 'schoolEntityId',
    posContact: 'posContactId',
    specialInstructions: 'specialInstructions',
};

const AddEditSchoolForm = ({ data: schoolForm, errors, onChange }: IForm<any>) => {
    const { getTabIndex } = useTabIndex();

    // Providers
    useEffect(() => {
        if (Number.isNaN(parseInt(schoolForm.providersCount))) {
            onChange({ name: 'providers', value: [] });
            onChange({ name: 'providersCount', value: 0 });

            return;
        }
        const providersCount = parseInt(schoolForm.providersCount) || 0;

        // init
        if (!schoolForm.providers?.length || !Array.isArray(schoolForm.providers)) {
            onChange({
                name: 'providers',
                value: Array.from(Array(providersCount).keys()).map(() => ({ students: '', days: '' })),
            });

            return;
        }

        // add
        if (schoolForm.providers?.length < providersCount) {
            const count = providersCount - schoolForm.providers?.length;
            const newElements = Array.from(Array(count).keys()).map(() => ({ students: '', days: '' }));
            onChange({ name: 'providers', value: [...schoolForm.providers, ...newElements] });

            return;
        }

        // remove
        if (schoolForm.providers?.length > providersCount) {
            const newLength = providersCount - schoolForm.providers?.length;
            onChange({ name: 'providers', value: schoolForm.providers.slice(0, newLength) });
        }
    }, [schoolForm.providersCount]);

    return (
        <>
            <div className="edit-form" tabIndex={getTabIndex()}>
                <FieldSet name={names.schoolYear} errors={errors} customClass="edit-therapist-field required-field-star">
                    <PaginationAutocomplete
                        clientSide
                        cleanable={false}
                        fetchData={schoolService.getYearOptions}
                        convertFunction={ConverterHelper.schoolConvert}
                        id={names.schoolYear}
                        defaultValue={schoolForm?.[names.schoolYear]}
                        placeholder="School Year"
                        name={names.schoolYear}
                        tabIndex={getTabIndex()}
                        onDataLoaded={(options: any) => {
                            if (options?.length > 0) {
                                const optionToPick = schoolForm?.[names.schoolYear]
                                    ? options.find((i: DropdownOption) => i.label === schoolForm?.[names.schoolYear])
                                    : options[0];

                                onChange({ name: names.schoolYear, value: optionToPick });
                            }
                        }}
                        onDelete={null}
                        onSelect={(item) => {
                            onChange({ name: names.schoolYear, value: item as DropdownOption });
                        }}
                    />
                </FieldSet>

                <FieldSet name={names.serviceType} errors={errors} customClass="edit-field required-field-star">
                    <PaginationAutocomplete
                        cleanable={false}
                        fetchData={schoolService.getTherapyOptions}
                        convertFunction={ConverterHelper.therapyTypeConvert}
                        id={names.serviceType}
                        defaultValue={schoolForm?.[names.serviceType]}
                        placeholder="Therapy"
                        name={names.serviceType}
                        tabIndex=""
                        onDelete={() => onChange({ name: names.serviceType, value: undefined })}
                        onSelect={(item) => onChange({ name: names.serviceType, value: item })}
                    />
                </FieldSet>

                <FieldSet name={names.language} errors={errors} customClass="edit-field">
                    <PaginationAutocomplete
                        cleanable={false}
                        className="autocomplete-one-border"
                        fetchData={LanguageService.getLanguageOptions}
                        convertFunction={ConverterHelper.languageConvert}
                        id={names.language}
                        defaultValue={schoolForm?.[names.language]}
                        placeholder="Language"
                        name={names.language}
                        tabIndex=""
                        onDelete={() => onChange({ name: names.language, value: undefined })}
                        onSelect={(item) => onChange({ name: names.language, value: item })}
                    />
                </FieldSet>

                <FieldSet name={names.bidRank} errors={errors} customClass="edit-therapist-field">
                    <PaginationAutocomplete
                        clientSide
                        fetchData={schoolService.getBidRankOptions}
                        convertFunction={ConverterHelper.optionConvert}
                        id={names.bidRank}
                        defaultValue={schoolForm?.[names.bidRank]}
                        placeholder="Bid Rank"
                        name={names.bidRank}
                        tabIndex={getTabIndex()}
                        //onDelete={null}
                        onDelete={() => onChange({ name: names.bidRank, value: undefined })}
                        onSelect={(item) => {
                            onChange({ name: names.bidRank, value: item as DropdownOption });
                        }}
                    />
                </FieldSet>

                <FieldSet errors={errors} name="providersCount" customClass="edit-field">
                    <NumberField
                        id="providersCount"
                        classic
                        min={0}
                        maxLength={2}
                        tabIndex={getTabIndex()}
                        placeholder="Number of Providers"
                        name="providersCount"
                        onChange={onChange}
                        value={schoolForm.providersCount.toString() === '0' ? '' : schoolForm.providersCount}
                    />
                </FieldSet>

                {schoolForm.providers?.map((provider: IProvider, index: number) => {
                    return (
                        <>
                            <FieldSet
                                key={index + 'students'}
                                errors={errors?.providers?.[index]}
                                name="students"
                                customClass="edit-field required-field-star"
                            >
                                <InputField
                                    id="students"
                                    placeholder={`Provider ${index + 1} # of Students`}
                                    name="students"
                                    value={provider?.students}
                                    tabIndex={getTabIndex()}
                                    onChange={(data: any) => {
                                        const providers = ObjectHelper.deepCopy(schoolForm.providers);
                                        providers[index].students = data.value;
                                        onChange({
                                            name: 'providers',
                                            value: providers,
                                        });
                                    }}
                                />
                            </FieldSet>

                            <FieldSet
                                key={index + 'days'}
                                errors={errors?.providers?.[index]}
                                name="days"
                                customClass="edit-field required-field-star"
                            >
                                <InputField
                                    id="days"
                                    placeholder={`Provider ${index + 1} # of Days`}
                                    name="days"
                                    value={provider?.days}
                                    tabIndex={getTabIndex()}
                                    onChange={(data: any) => {
                                        const providers = ObjectHelper.deepCopy(schoolForm.providers);
                                        providers[index].days = data.value;
                                        onChange({
                                            name: 'providers',
                                            value: providers,
                                        });
                                    }}
                                />
                            </FieldSet>
                        </>
                    );
                })}

                <FieldSet name={names.population} errors={errors} customClass="edit-therapist-field required-field-star">
                    <PaginationAutocomplete
                        clientSide
                        cleanable={false}
                        fetchData={schoolService.getPopulationOptions}
                        convertFunction={ConverterHelper.optionConvert}
                        id={names.population}
                        defaultValue={schoolForm?.[names.population] || schoolForm?.population}
                        placeholder="Population"
                        name={names.population}
                        tabIndex={getTabIndex()}
                        onDelete={null}
                        onSelect={(item) => {
                            onChange({ name: names.population, value: item as DropdownOption });
                        }}
                    />
                </FieldSet>

                <FieldSet name={names.priorityLevel} errors={errors} customClass="edit-therapist-field">
                    <PaginationAutocomplete
                        clientSide
                        fetchData={schoolService.getPriorityLevelOptions}
                        convertFunction={ConverterHelper.optionConvert}
                        id={names.priorityLevel}
                        defaultValue={schoolForm?.[names.priorityLevel]}
                        placeholder="Priority Level"
                        name={names.priorityLevel}
                        tabIndex={getTabIndex()}
                        onDelete={() => onChange({ name: names.priorityLevel, value: undefined })}
                        onSelect={(item) => {
                            onChange({ name: names.priorityLevel, value: item as DropdownOption });
                        }}
                    />
                </FieldSet>

                <FieldSet name={names.contractType} errors={errors} customClass="edit-therapist-field required-field-star">
                    <PaginationAutocomplete
                        clientSide
                        cleanable={false}
                        fetchData={schoolService.getContractTypeOptions}
                        convertFunction={ConverterHelper.optionConvert}
                        id={names.contractType}
                        defaultValue={schoolForm?.[names.contractType] || schoolForm?.contractType}
                        placeholder="Contract Type"
                        name={names.contractType}
                        tabIndex={getTabIndex()}
                        onDelete={null}
                        onSelect={(item) => {
                            onChange({ name: names.contractType, value: item as DropdownOption });
                        }}
                    />
                </FieldSet>

                <FieldSet name={names.doeContact} errors={errors} customClass="edit-therapist-field required-field-star">
                    <PaginationAutocomplete
                        clientSide
                        cleanable={false}
                        fetchData={schoolService.getDOEContactOptions}
                        convertFunction={ConverterHelper.optionConvert}
                        id={names.doeContact}
                        defaultValue={schoolForm?.[names.doeContact]}
                        placeholder="DOE Contact"
                        name={names.doeContact}
                        tabIndex={getTabIndex()}
                        onDelete={null}
                        onSelect={(item) => {
                            onChange({ name: names.doeContact, value: item as DropdownOption });
                        }}
                    />
                </FieldSet>

                <FieldSet name={names.startDate} errors={errors} customClass={`edit-therapist-field required-field-star`}>
                    <DateField
                        placeholder="Start Date"
                        name={names.startDate}
                        onChange={onChange}
                        value={schoolForm?.[names.startDate]}
                        tabIndex={getTabIndex()}
                    />
                </FieldSet>

                <FieldSet name={names.endDate} errors={errors} customClass={`edit-therapist-field required-field-star`}>
                    <DateField
                        placeholder="End Date"
                        name={names.endDate}
                        onChange={onChange}
                        value={schoolForm?.[names.endDate]}
                        tabIndex={getTabIndex()}
                    />
                </FieldSet>

                <FieldSet
                    name={names.schoolName}
                    errors={errors}
                    customClass="edit-therapist-field school-name-autocomplete required-field-star"
                >
                    <PaginationAutocomplete
                        cleanable={false}
                        fetchData={schoolService.getSchoolNameOptions}
                        convertFunction={ConverterHelper.schoolNameConvert}
                        id={names.schoolName}
                        defaultValue={schoolForm?.[names.schoolName]}
                        placeholder="School Name"
                        name={names.schoolName}
                        tabIndex={getTabIndex()}
                        onDelete={null}
                        onSelect={(item) => {
                            onChange({ name: names.posContact, value: undefined });
                            onChange({ name: names.schoolName, value: item as DropdownOption });
                        }}
                        onOptionWrapper={(options) => {
                            return (
                                <div className="school-names-table">
                                    <div className="option-roles school-headers">
                                        <div>Type</div>
                                        <div>Name</div>
                                        <div>DBN</div>
                                        <div>Address</div>
                                    </div>
                                    {options}
                                </div>
                            );
                        }}
                        onRenderOption={({ onClick, item }: any) => {
                            const school = item?.value;
                            const address = school.address;

                            return (
                                <div key={item?.value} className="option-roles school-names" onClick={() => onClick(item)}>
                                    <div>{school.type}</div>
                                    <div>{school.name}</div>
                                    <div>{school.dbn}</div>
                                    <div>{school.address ? address.address1 ?? '' : ''}</div>
                                </div>
                            );
                        }}
                    />
                </FieldSet>

                <FieldSet name={names.posContact} errors={errors} customClass="edit-therapist-field">
                    <PaginationAutocomplete
                        clientSide
                        fetchData={schoolService.getPOSContactOptions(schoolForm?.[names.schoolName]?.value?.id)}
                        convertFunction={ConverterHelper.posContactConvert}
                        fetchTrigger={schoolForm?.[names.schoolName]?.value?.id}
                        id={names.posContact}
                        defaultValue={schoolForm?.[names.posContact]}
                        placeholder="POS Contact"
                        name={names.posContact}
                        tabIndex={getTabIndex()}
                        onDelete={() => onChange({ name: names.posContact, value: undefined })}
                        onSelect={(item) => {
                            onChange({ name: names.posContact, value: item as DropdownOption });
                        }}
                    />
                </FieldSet>

                <FieldSet name={names.specialInstructions} errors={errors} customClass="edit-field">
                    <TextAreaField
                        id={names.specialInstructions}
                        placeholder="Special Instructions"
                        name={names.specialInstructions}
                        onChange={onChange}
                        value={schoolForm?.specialInstructions}
                        maxLength={generalValidationRules.note.maxLength}
                    ></TextAreaField>
                </FieldSet>
            </div>
        </>
    );
};

export default React.memo(AddEditSchoolForm);
