import { Dispatch } from '@reduxjs/toolkit';
import { PopulationService } from 'common/services/api/population/population-service';
import { IPopulation } from 'common/services/api/population/types';
import { ISetPopulationsAction, ThunkResult } from './types';

export const setPopulations = (payload: IPopulation[]): ISetPopulationsAction => {
    return {
        populations: payload,
        type: 'SET_POPULATIONS',
    };
};

export const fetchPopulations = (): ThunkResult<Promise<IPopulation[]>> => {
    return async (dispatch: Dispatch) => {
        const populations = await new PopulationService().getAll();
        dispatch(setPopulations(populations));

        return populations;
    };
};
