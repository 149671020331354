import React, { useState, useEffect, useRef } from 'react';
import InputField from '../input-field';
import Field from '../field';
import AutopopulateDropdownOption from './dropdown-auto-populate-option';
import useOnClickOutside from 'react-cool-onclickoutside';
import { getUniqueName } from '../../../helpers/string-helper';
import DropdownOption from '../../../models/dropdown-option';
import { FieldValue } from '../../../models/field-value';
import { IDropdownAutoPopulateProps } from './types';

function DropdownAutoPopulate({
    items,
    onChange,
    onSelect,
    onDelete = undefined,
    label = undefined,
    id = undefined,
    name = undefined,
    value,
    onBlur = undefined,
    onScrollEnd = undefined,
    tabIndex = undefined,
    searchPlaceholder = undefined,
    hasError = undefined,
    autoPopulateDropdownCallback = undefined,
    fetchTrigger = undefined,
    multiselect = false,
    errorMessage = undefined,
    maxInputLength = undefined,
}: IDropdownAutoPopulateProps) {
    const [isOpen, toggleDropdown] = useState(false);
    const [selectedItem, setItem] = useState<DropdownOption>();
    const [selectedItems, setItems] = useState<DropdownOption[]>(value || []);
    const [inputValue, setInputValue] = useState<string>(value);

    const [isAutocompleteSearchFocused, setAutocompleteSearchFocused] = useState(false);
    const inputRef = useRef();
    const dropdownOptionsBodyRef = useRef<HTMLDivElement>();

    useOnClickOutside(
        () => {
            toggleDropdown(false);
        },
        { refs: [dropdownOptionsBodyRef] }
    );

    const handleClickOnDropdown = () => {
        toggleDropdown(true);
    };

    const handleClickOnItem = (item: DropdownOption) => {
        if (multiselect) {
            let values = [...selectedItems];

            if (selectedItems.some((x) => x.value === item.value)) {
                values = selectedItems.filter((i) => i.value !== item.value);
            } else {
                values = [...selectedItems, item];
            }

            setItems(values);
            onSelect(values);
        } else {
            setItem(item);

            setInputValue(item.value);
            onChange(item.value);
            toggleDropdown(false);
            onSelect(item);
        }
    };

    const handleChangeInput = (item: any) => {
        const value = multiselect ? (item as React.ChangeEvent<HTMLInputElement>).target.value : (item as FieldValue).value;
        toggleDropdown(true);
        setInputValue(value);
        onChange(value);
    };

    const handleDeleteItem = (item: DropdownOption) => {
        const existingItemIndex = selectedItems.findIndex((x) => x.value === item.value);
        selectedItems.splice(existingItemIndex, 1);
        setItems(selectedItems);
        onSelect(selectedItems);
    };

    const handleOnBlurInput = () => {
        if (!inputValue && selectedItem) {
            if (onDelete) {
                onDelete(selectedItem);
            }
            setItem(null);
        } else {
            setInputValue(inputValue || value || '');
        }
    };

    const handleOnClickSearchInput = () => {
        if (items.length > 0) {
            toggleDropdown(true);
        }
    };

    useEffect(() => {
        if (multiselect) {
            setItems(value);
        } else {
            setInputValue(value);
        }
    }, [value, fetchTrigger, multiselect]);

    const handleScroll = () => {
        const { scrollTop, scrollHeight, clientHeight } = dropdownOptionsBodyRef.current;
        if (onScrollEnd && scrollTop > 0 && Math.ceil(scrollTop + clientHeight) + 2 >= scrollHeight) {
            onScrollEnd();
        }
    };

    const options = items.map((item, i) => (
        <AutopopulateDropdownOption
            inputValue={inputValue}
            asCheckbox={multiselect}
            checked={multiselect && selectedItems.some((x) => x.value === item.value)}
            key={i}
            item={item}
            onOptionClick={handleClickOnItem}
        />
    ));
    const className = `number-select-wrapper custom-select-wrapper autocomplete ${isOpen ? 'open' : 'close'}`;

    const didntFindOptionClick = () => {
        autoPopulateDropdownCallback(inputValue);
        toggleDropdown(false);
        onBlur();
    };

    return (
        <>
            <div className="custom-field-outer">
                <div className={className + ' ' + name} id={id}>
                    {multiselect && (
                        <div onClick={handleOnClickSearchInput} className="autcomplete">
                            <Field
                                customClass="multiple-autocomplete"
                                hasError={hasError}
                                id={id}
                                placeholder={label}
                                isFocused={isOpen || selectedItems.length !== 0}
                            >
                                {selectedItems.length > 0 && (
                                    <span className="selected">
                                        {selectedItems.map((x, i) => (
                                            <div key={i} className="item">
                                                <span>{x.label}</span>
                                                <button
                                                    className="item-delete-btn icon icon-close"
                                                    onClick={() => handleDeleteItem(x)}
                                                ></button>
                                            </div>
                                        ))}
                                    </span>
                                )}
                            </Field>
                        </div>
                    )}
                    {!multiselect && (
                        <div onClick={handleClickOnDropdown} className="autcomplete">
                            <InputField
                                hasError={hasError}
                                tabIndex={tabIndex}
                                id={id}
                                placeholder={label}
                                value={inputValue}
                                onChange={handleChangeInput}
                                onFocus={() => toggleDropdown(true)}
                                onBlur={handleOnBlurInput}
                                maxLength={maxInputLength}
                            />
                        </div>
                    )}
                    {isOpen && multiselect && (
                        <div className="option-list multiselect" ref={dropdownOptionsBodyRef}>
                            <div className={isAutocompleteSearchFocused ? 'search-wrapper focused' : 'search-wrapper'}>
                                <input
                                    className="search"
                                    onFocus={() => setAutocompleteSearchFocused(true)}
                                    onBlur={() => setAutocompleteSearchFocused(false)}
                                    placeholder={searchPlaceholder || 'Search...'}
                                    ref={inputRef}
                                    onChange={handleChangeInput}
                                    autoComplete={getUniqueName()}
                                />
                            </div>
                            <div className="options-list" onScroll={handleScroll}>
                                {options}
                            </div>
                        </div>
                    )}

                    {isOpen && options.length > 0 && (
                        <div className="option-list autopopulate" onScroll={handleScroll} ref={dropdownOptionsBodyRef}>
                            <div className="autopopulate-header">
                                <h3 className="option-list-title">Matching ID’s found</h3>
                                <span className="create-new" onClick={() => didntFindOptionClick()}>
                                    Create New
                                </span>
                            </div>

                            {options}
                        </div>
                    )}
                    {isOpen && options.length === 0 && (
                        <div className="option-list autopopulate" ref={dropdownOptionsBodyRef}>
                            <div className="autopopulate-header">
                                <h3 className="option-list-title">Matching ID’s found</h3>
                                <span className="create-new" onClick={() => didntFindOptionClick()}>
                                    Create New
                                </span>
                            </div>
                            {errorMessage && (
                                <div className="option-item didnt-find">
                                    <span className="option-item-title">{errorMessage}</span>
                                </div>
                            )}
                            {!errorMessage && (
                                <div className="option-item didnt-find">
                                    <span className="option-item-title">Didn't find</span>
                                    <span className="option-item-value">{inputValue}</span>
                                </div>
                            )}
                        </div>
                    )}
                </div>
            </div>
        </>
    );
}

export default DropdownAutoPopulate;
