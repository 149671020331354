import React from 'react';

class RightSideBarHeaderEditMode extends React.Component {
    render() {
        const { title, onCancel, onSave, disableSave } = this.props;

        let saveButtonClassName = 'base-text-btn ';

        if (disableSave) {
            saveButtonClassName += 'disabled';
        } else {
            saveButtonClassName += 'blue';
        }

        const saveText = this.props.saveText ? this.props.saveText : 'Save';

        return (
            <>
                <div className="right-side-bar-header">
                    <span className="right-side-bar-title">{title}</span>
                    <div className="actions">
                        <button className="base-text-btn white" onClick={onCancel}>
                            Cancel
                        </button>
                        <button className={saveButtonClassName} onClick={onSave} disabled={disableSave}>
                            {saveText}
                        </button>
                    </div>
                </div>
                {this.props.renderAdditionalContent && this.props.renderAdditionalContent()}
            </>
        );
    }
}

export default RightSideBarHeaderEditMode;
