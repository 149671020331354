import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

export function useHasPermission(...permissions: string[]): boolean {
    const [hasPermission, setHasPermission] = useState(null);
    const userPermissions = useSelector<string[]>((state: any) => state.auth.user.permissions || []) as string[];

    useEffect(() => {
        const hasAccess = userPermissions.some((i: string) => permissions.includes(i));
        setHasPermission(hasAccess);
    }, [permissions, userPermissions]);

    return hasPermission;
}
