import PropTypes from 'prop-types';
import React from 'react';
import DatePicker from 'react-datepicker';
import { getUniqueName } from '../../helpers/string-helper';

const password = 'password';
const text = 'text';

class InfiniteInput extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            focused: false,
            value: null,
            type: null,
        };

        this.handleOnBlur = this.handleOnBlur.bind(this);
        this.handleOnFocus = this.handleOnFocus.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.handleClickViewPassword = this.handleClickViewPassword.bind(this);
    }

    handleClickViewPassword() {
        this.setState((prevState) => ({
            type: prevState.type === text ? password : text,
        }));
    }

    handleOnFocus(e) {
        this.onFocusChanged(true);

        if (this.props.onFocus) {
            this.props.onFocus(e);
        }
    }

    handleOnBlur() {
        this.onFocusChanged(false);
    }

    handleChange(event) {
        if (this.props.type === 'date') {
            this.setState({ value: event });
        } else {
            this.setState({ value: event.target.value });
        }

        this.props.onChange(event);
    }

    onFocusChanged(focused) {
        this.setState({ focused: focused });
    }

    render() {
        const { focused } = this.state;
        const {
            placeholder,
            name,
            inputClass,
            required,
            value,
            hasError,
            definedTabIndex,
            id,
            customClass,
            uncontrolled,
            autocomplete,
        } = this.props;
        const otherProps = uncontrolled ? undefined : { value };
        const type = this.props.type || 'text';

        return (
            <div className="custom-field-outer infinite-input-field-outer">
                <div
                    className={`base-input-wrapper ${focused ? 'focused' : ''} ${hasError ? 'has-error' : ''} ${type} ${customClass || ''}`}
                >
                    {type !== 'date' && (
                        <input
                            id={autocomplete ? id : undefined}
                            name={autocomplete ? name : undefined}
                            type={this.state.type ?? type}
                            className={inputClass}
                            onFocus={this.handleOnFocus}
                            onBlur={this.handleOnBlur}
                            required={required}
                            onChange={this.handleChange}
                            autoComplete={autocomplete ? 'on' : getUniqueName()}
                            tabIndex={definedTabIndex}
                            {...otherProps}
                        />
                    )}

                    {type === 'date' && (
                        <DatePicker
                            showPopperArrow={false}
                            selected={value}
                            onFocus={this.handleOnFocus}
                            onBlur={this.handleOnBlur}
                            onChange={this.handleChange}
                            autoComplete={getUniqueName()}
                        />
                    )}
                    <span className={`base-input-label ${focused || value ? 'floated' : ''} ${type}`}>{placeholder}</span>
                </div>
                {type === password && (
                    <span
                        className={`view-password icon icon-eye-${eyeToggleMap[this.state.type ?? password]}`}
                        onClick={this.handleClickViewPassword}
                    ></span>
                )}
            </div>
        );
    }
}

const eyeToggleMap = {
    [password]: 'on',
    [text]: 'off',
};

InfiniteInput.propTypes = {
    placeholder: PropTypes.string,
    name: PropTypes.string.isRequired,
    inputClass: PropTypes.string,
    value: PropTypes.any,
    required: PropTypes.bool,
    type: PropTypes.string,
    onChange: PropTypes.func,
};

export default InfiniteInput;
