import React from 'react';
import SettingsNavigationTab from './settings-general-tab/settings-general-tab';
import './styles.scss';
import { toast } from 'react-toastify';
import { ISettingsPageProps } from './types';

const SettingsPage: React.FC<ISettingsPageProps> = ({ hasAccess }) => {
    if (hasAccess !== null && !hasAccess) {
        toast.error('Access Denied');
    }

    return (
        <div className="details settings">
            {hasAccess && (
                <>
                    <header>
                        <div className="header-wrapper">
                            <h1>Settings</h1>
                        </div>
                    </header>
                    <SettingsNavigationTab />
                </>
            )}
        </div>
    );
};

export default SettingsPage;
