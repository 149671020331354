import { ColumnApi, GridApi, IFilterComp } from 'ag-grid-community';
import { ColDef } from 'ag-grid-community/dist/lib/entities/colDef';
import { CellClickedEvent, GridReadyEvent } from 'ag-grid-community/dist/lib/events';
import AgTable from 'common/components/ag-table/ag-table';
import { IParamsWatcher } from 'common/components/ag-table/types';
import BadgeTopFilter from 'common/components/badges/badge/badge-top-filter/badge-top-filter';
import { IBadge } from 'common/components/badges/types';
import ClearFiltersButton from 'common/components/header/clear-filters-button/clear-filters-button';
import ExportButton from 'common/components/header/export-button/export-button';
import HeaderLeftSide from 'common/components/header/header-left-side';
import HeaderRightSide from 'common/components/header/header-right-side';
import HeaderWrapper from 'common/components/header/header-wrapper';
import SearchFilterInput from 'common/components/header/search-filter-input/search-filter-input';
import ColumnsVisibleFilterSelect from 'common/components/header/сolumns-visible-filter-select/columns-visible-filter-select';
import { ENTITY, FACILITY } from 'common/constants/common';
import { ExtentedDefaultTableProps, IDefaultFilters } from 'common/constants/types';
import { DateTimeHelper } from 'common/helpers/date-time-helper';
import { FilterHelper } from 'common/helpers/filter-helper';
import { LocalStoreRepository } from 'common/helpers/repository/local-store-repository';
import { useSearchInput } from 'common/hooks/use-serch-input';
import NursingHomeRequestService from 'common/services/api/nursing-home-requests/nursing-home-request-service';
import { INursingHomeRequestStatusCount, NursingHomeRequestStatusCount } from 'common/services/api/nursing-home-requests/types';
import { getExportData, getFilterData, getTableData } from 'common/services/api/tabel-fetch-service';
import { NavigationRoutes } from 'models/routes/navigation-routes';
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { trackPromise } from 'react-promise-tracker';
import { useHistory } from 'react-router';
import AddButton from 'common/components/header/add-button/add-button';
import nursingHomeService from 'common/services/api/nursing-home/nurcing-home-service';
import NursingHomeBaseSidebar from './nursing-home-request-form/nursing-home-basic-sidebar';
import { INursingHomeBasicForm } from './nursing-home-request-form/types';
import { LocalTableRepository } from 'common/helpers/repository/local-table-repository';
import { DisplayValueHelper } from 'common/helpers/display-value-helper';
import MultipleBadges from 'common/components/badges/multiple-badges';
import { DetailsNavigationHelper } from 'common/helpers/details-navigation-helper';

// Fetches
const handleFetchData = getTableData('nursing-home/requests');
const handleFetchFilters = getFilterData('nursing-home/requests/filter/source');
const handleExportData = getExportData('nursing-home/requests/export');

const defaultBadgeList = [
    { value: 'newCount', title: 'New', body: 0 },
    { value: 'openCount', title: 'Open', body: 0 },
    { value: 'assignedCount', title: 'Assigned', body: 0 },
    { value: 'closedCount', title: 'Closed', body: 0 },
];

export const RequestStatusLabel = new Map<NursingHomeRequestStatusCount, string>([
    [NursingHomeRequestStatusCount.New, 'New'],
    [NursingHomeRequestStatusCount.Open, 'Open'],
    [NursingHomeRequestStatusCount.Assigned, 'Assigned'],
    [NursingHomeRequestStatusCount.Closed, 'Closed'],
]);

export const RequestStatusToRequestCount = new Map<string, NursingHomeRequestStatusCount>([
    ['New', NursingHomeRequestStatusCount.New],
    ['Open', NursingHomeRequestStatusCount.Open],
    ['Assigned', NursingHomeRequestStatusCount.Assigned],
    ['Closed', NursingHomeRequestStatusCount.Closed],
]);

const statusField = 'status';

const NursingHomeRequestTable: React.FC<ExtentedDefaultTableProps> = ({
    updateTable,
    storePrefix = '',
    extendGridOptions,
    extendDefaultCustomFilters,
    details,
    onGridReady,
}) => {
    // Repositories
    const tableRepository = useMemo(() => new LocalTableRepository(`${storePrefix}-nursing-home-request-table`, 'v2'), [storePrefix]);
    const customFiltersRepository = useMemo(() => new LocalStoreRepository(`${storePrefix}-nursing-home-request-table-custom-filters`), [
        storePrefix,
    ]);
    const [defaultKeys, setDefaultKeys] = useState([]);
    const loadedFilters = useRef<IDefaultFilters>(customFiltersRepository.load());
    const defaultFilters: IDefaultFilters = {
        search: null,
    };
    if (extendDefaultCustomFilters) {
        Object.keys(extendDefaultCustomFilters).forEach((key) => {
            defaultFilters[key] = extendDefaultCustomFilters[key];
            if (loadedFilters.current) {
                loadedFilters.current[key] = extendDefaultCustomFilters[key];
            }
        });
    }
    const [badgeList, setBadgeList] = useState(defaultBadgeList);
    const { search, handleSearchInputChange, setSearch } = useSearchInput(loadedFilters.current?.search, (value: string) => {
        setFilters((prev) => ({ ...prev, search: value }));
    });
    const [gridApi, setGridApi] = useState<GridApi>(null);
    const [gridColumnApi, setColumnApi] = useState<ColumnApi>(null);
    const [filters, setFilters] = useState(loadedFilters.current ?? defaultFilters);
    const paramsWatcher = useRef<IParamsWatcher>();
    const history = useHistory();
    const lastQuery = useRef(null);

    useEffect(() => {
        customFiltersRepository.save(filters);
        loadedFilters.current = filters;
    }, [filters, customFiltersRepository]);

    // Badge
    const handleBadgeChange = useCallback(
        (currentValues: IBadge[]) => {
            if (!gridApi || !gridColumnApi) {
                return;
            }

            type filerModelType = {
                values: string[];
            };

            function setFilter(name: string, filerModel: filerModelType) {
                gridApi.getFilterInstance(name, (filterApi) => {
                    filterApi.setModel({ ...filterApi.getModel(), exclude: false, ...filerModel });
                });
                gridApi.onFilterChanged();
            }

            const status: string[] = currentValues?.map((value) => RequestStatusLabel.get(value.value as NursingHomeRequestStatusCount));
            setFilter(statusField, { values: status?.length ? status : null });
        },
        [gridApi, gridColumnApi]
    );

    // Status column to Badge
    useEffect(() => {
        if (!gridApi) {
            return;
        }

        const handleFilterChange = (e: any) => {
            e.api.getFilterInstance(statusField, (filterApi: IFilterComp) => {
                if (filterApi.getModel()?.values) {
                    setDefaultKeys(filterApi.getModel().values.map((value: string) => RequestStatusToRequestCount.get(value)));
                } else {
                    setDefaultKeys([]);
                }
            });
        };

        gridApi.addEventListener('filterChanged', handleFilterChange);

        return () => {
            gridApi.removeEventListener('filterChanged', handleFilterChange);
        };
    }, [gridApi]);

    const updateCount = (data: any, params?: IParamsWatcher) => {
        let queryParams = '';
        if (params) {
            const { getFilterModelParams, getSortParams, getCustomFilterParams, request } = paramsWatcher.current;

            queryParams = FilterHelper.toURLQueryParams({
                ...getSortParams(request),
                ...getFilterModelParams(request),
                ...getCustomFilterParams,
                authorizationStatus: null,
                status: null,
            }).join('&');
        }

        if (lastQuery.current === queryParams) {
            return;
        }

        lastQuery.current = queryParams;

        NursingHomeRequestService.getCountGroupStatuses(queryParams).then((data: INursingHomeRequestStatusCount) => {
            setBadgeList(
                Object.keys(data).map((key) => ({
                    value: key,
                    title: RequestStatusLabel.get(key as NursingHomeRequestStatusCount),
                    body: data[key as NursingHomeRequestStatusCount],
                }))
            );
        });
    };

    useEffect(() => {
        if (updateTable && gridApi) {
            gridApi.onFilterChanged();
        }
    }, [updateTable, gridApi]);

    const gridOptions = useMemo(
        () => ({
            ...extendGridOptions,
            onCellClicked: (event: CellClickedEvent) => {
                const { id, entity, facility } = event.data;
                switch (event.column.getColId()) {
                    case ENTITY: {
                        if (entity) {
                            window.open(NavigationRoutes.entityDetailsRoute(entity.id), '_blank').focus();
                        }
                        break;
                    }
                    case FACILITY: {
                        if (facility) {
                            window.open(NavigationRoutes.entityDetailsRoute(facility.id), '_blank').focus();
                        }
                        break;
                    }
                    default: {
                        history.push(
                            { pathname: NavigationRoutes.nursinghomerequestDetailsRoute(id) },
                            DetailsNavigationHelper.getStateForDetails(paramsWatcher.current, event.node.rowIndex)
                        );
                        break;
                    }
                }
                if (extendGridOptions?.onCellClicked) extendGridOptions?.onCellClicked(event);
            },
            columnDefs: extendGridOptions?.columnDefs ?? requestColumnDefs,
            defaultColDef: {
                resizable: true,
                filterParams: {
                    onFetchFilters: handleFetchFilters,
                },
            },
            frameworkComponents: {
                statusRender: (props: any) => {
                    const cellValue = (props.valueFormatted ? props.valueFormatted : props.value) || '';

                    return <span className={`request-status d-flex fixed-width ${cellValue.toLowerCase()}`}>{cellValue}</span>;
                },
                objectLinkValueRender: (props: any) => {
                    const cellValue = (props.valueFormatted ? props.valueFormatted : props.value) || '';

                    return <span className="object-link">{cellValue.name}</span>;
                },
                ...extendGridOptions?.frameworkComponents,
            },
        }),
        [extendGridOptions, history]
    );

    //Side Bar
    const [isCreateDialogOpen, setCreateDialogOpen] = useState<boolean>(false);

    const handleOnClose = () => {
        setCreateDialogOpen(false);
    };

    const handleOnSave = (nursingHomeBasicForm: INursingHomeBasicForm) => {
        return trackPromise(nursingHomeService.createRequest(nursingHomeBasicForm)).then(() => {
            setCreateDialogOpen(false);
            gridApi.onFilterChanged();
        });
    };

    const handleClickAddRequest = () => {
        setCreateDialogOpen(true);
    };

    const handleFiltersClear = () => {
        setSearch('');
        setFilters({ ...defaultFilters });
        setDefaultKeys([]);
    };

    const handleGridReady = (params: GridReadyEvent) => {
        setGridApi(params.api);
        setColumnApi(params.columnApi);
        if (onGridReady) onGridReady({ params, extraParams: { setCreateDialogOpen } });
    };

    return (
        <>
            <div className="main-info-wrapper ag-table-wrapper">
                <HeaderWrapper>
                    <HeaderLeftSide>
                        <SearchFilterInput title="Request" value={search} onInput={handleSearchInputChange} />
                        <ColumnsVisibleFilterSelect title="Request" gridApi={gridApi} gridColumnApi={gridColumnApi} />
                    </HeaderLeftSide>
                    <HeaderRightSide>
                        <ExportButton title="Request" paramsWatcher={paramsWatcher} onExportData={handleExportData} />
                        <ClearFiltersButton title="Request" gridApi={gridApi} gridColumnApi={gridColumnApi} onClick={handleFiltersClear} />
                        {filters.entity && <AddButton title="Add Request" onClick={handleClickAddRequest} />}
                    </HeaderRightSide>
                </HeaderWrapper>
                <MultipleBadges
                    className="pl-25"
                    items={badgeList}
                    defaultKey={defaultKeys}
                    onChange={handleBadgeChange}
                    badgeComponent={BadgeTopFilter}
                    repository={new LocalStoreRepository(`${storePrefix}-nursing-home-request-table-badge`, 'v3')}
                />
                <AgTable
                    onGridReady={handleGridReady}
                    gridOptions={gridOptions}
                    onFiltersLoadedFromUrl={handleFiltersClear}
                    repository={tableRepository}
                    customFilters={filters}
                    onDataChange={updateCount}
                    paramsWatcher={paramsWatcher}
                    onFetchData={handleFetchData}
                />
            </div>
            {isCreateDialogOpen && (
                <NursingHomeBaseSidebar
                    onClose={handleOnClose}
                    onSave={handleOnSave}
                    data={{ entity: { name: details?.name, id: details?.id } }}
                    options={{ disabledEntity: details?.name && details?.id }}
                />
            )}
        </>
    );
};

export const requestColumnDefs: ColDef[] = [
    {
        field: 'createdDate',
        headerName: 'Date Created',
        filter: 'agDateColumnFilter',
        valueFormatter: (props: any) => DateTimeHelper.format(props.value, 'MM/DD/YYYY'),
        filterParams: {
            isDateTime: true,
        },
        width: 147,
        initialSort: 'desc',
    },
    {
        field: 'startDate',
        headerName: 'Request Start',
        filter: 'agDateColumnFilter',
        valueFormatter: (props: any) => DateTimeHelper.format(props.value, 'MM/DD/YYYY', true),
        width: 152,
    },
    {
        field: 'endDate',
        headerName: 'Request End',
        filter: 'agDateColumnFilter',
        valueFormatter: (props: any) => DateTimeHelper.format(props.value, 'MM/DD/YYYY', true),
        width: 145,
    },
    {
        field: ENTITY,
        headerName: 'Entity',
        cellRenderer: 'objectLinkValueRender',
        filter: 'agCustomFilter',
        width: 250,
    },
    {
        field: FACILITY,
        headerName: 'Facility',
        cellRenderer: 'objectLinkValueRender',
        filter: 'agCustomFilter',
        width: 250,
    },
    {
        field: 'therapyType',
        headerName: 'Discipline',
        filter: 'agCustomFilter',
        width: 140,
    },
    {
        field: 'placementType',
        headerName: 'Type',
        filter: 'agCustomFilter',
        width: 208,
    },
    {
        field: 'rate',
        headerName: 'Rate',
        filter: 'agCustomFilter',
        valueFormatter: (props: any) => DisplayValueHelper.rateEmptyOrIsSet(props.value, ''),
        width: 150,
    },
    {
        field: 'assignedTo',
        headerName: 'AssignedTo',
        filter: 'agCustomFilter',
        valueFormatter: (props: any) => props.value?.fullName,
        width: 150,
    },
    {
        field: statusField,
        headerName: 'Status',
        pinned: 'right',
        cellRenderer: 'statusRender',
        filter: 'agCustomFilter',
        width: 120,
        cellClass: 'd-flex',
    },
];

export default NursingHomeRequestTable;
