import React, { useState } from 'react';
import GeneralInfoDisplaySection from './general-info-display-section';
import { useDispatch, useSelector } from 'react-redux';
import GeneralInfoEditForm from './general-info-edit-form';
import { trackPromise } from 'react-promise-tracker';
import UpdateAvatarSection from 'common/components/update-avatar-section/update-avatar-section';
import { Card, CardWrapper } from 'common/components/card';
import SetInactiveReasonDialog from '../../set-inactive-reason/set-inactive-reason-dialog';
import { DepartmentList } from '../departments-list/departments-list';
import { useHasPermission } from 'common/hooks/use-has-permission';
import { PermissionType } from 'common/models/permission-type';
import {
    getTherapistDetailsRequest,
    updateTherapistGeneralInfoRequest,
    updateTherapistProflePhotoRequest,
} from 'app/store/therapist/action-creators';
import { ThunkDispatch } from 'redux-thunk';
import { Action } from '@reduxjs/toolkit';
import { IEditGeneralInfo } from 'common/services/api/therapist/types';
import { useResponsiveLayout } from 'common/hooks/use-responsive-layout';

const GeneralInfoTab: React.FC = () => {
    const [isEditDialogOpen, toggleEditDialog] = useState(false);
    const dispatch = useDispatch<ThunkDispatch<any, any, Action>>();
    const therapist = useSelector((state: any) => state.therapistDetails.therapist);

    const hasAccessToDepartmentsSection = useHasPermission(PermissionType.CanViewTherapistDepartmentWorkflow);

    const handleClickOnEdit = () => {
        toggleEditDialog(true);
    };

    const handleCancelDialog = () => {
        toggleEditDialog(false);
    };

    const saveGeneralInfo = (generalInfo: IEditGeneralInfo) => {
        return trackPromise(dispatch(updateTherapistGeneralInfoRequest(therapist.id, generalInfo))).then((response) => {
            trackPromise(dispatch(getTherapistDetailsRequest(therapist.id)));
            handleCancelDialog();

            return response;
        });
    };

    const handleFileChange = (file: File) => {
        return updateTherapistProflePhotoRequest(therapist.id, file);
    };

    const [isSetInactiveReasonDialogOpen, toggleSetInactiveDialog] = useState(false);

    const handleCloseInactiveReasonDialog = () => {
        toggleSetInactiveDialog(false);
    };

    const { mediaClass } = useResponsiveLayout({
        contentWrapper: '.provider-details-base-info-tab',
        onResize: (width, contentWidth) => {
            let className = '';
            if (width <= 1800 || contentWidth <= 1529) {
                className = 'lg-card';
            }
            if (width <= 1426 || contentWidth <= 1155) {
                className = 'md-card';
            }
            if (width <= 1260 || contentWidth <= 989) {
                className = 'sm-card';
            }
            if (width <= 1024 || contentWidth <= 753) {
                className = 'extra-sm-card';
            }

            return className;
        },
    });

    return (
        <div className="provider-details-base-info-tab">
            {therapist && (
                <CardWrapper
                    className={`provider-grid-wrapper ${hasAccessToDepartmentsSection ? 'three-column' : 'two-column'} ${mediaClass}`}
                >
                    <GeneralInfoDisplaySection generalInfo={therapist.generalInfo} onEdit={handleClickOnEdit} />

                    {hasAccessToDepartmentsSection && (
                        <Card className="therapist-details-departments box-2" titleIcon="icon-job" title="Departments" defaultValue="-">
                            <DepartmentList therapist={therapist} />
                        </Card>
                    )}
                    <Card className="therapist-details-avatar box-3" titleIcon="icon-job" title="Avatar image" defaultValue="-">
                        <span className="avatar-comment">
                            avatar that will be used across the system to help other users recognizing him
                        </span>
                        <UpdateAvatarSection handleFileChange={handleFileChange} currentImageData={therapist.generalInfo.profilePhoto} />
                    </Card>

                    {isEditDialogOpen && (
                        <GeneralInfoEditForm
                            generalInfo={therapist.generalInfo}
                            onSave={saveGeneralInfo}
                            onCancel={handleCancelDialog}
                        ></GeneralInfoEditForm>
                    )}
                    {isSetInactiveReasonDialogOpen && (
                        <SetInactiveReasonDialog
                            therapistId={therapist.id}
                            onCancel={handleCloseInactiveReasonDialog}
                        ></SetInactiveReasonDialog>
                    )}
                </CardWrapper>
            )}
        </div>
    );
};

export default GeneralInfoTab;
