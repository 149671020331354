import RightSideBarForm from 'common/components/right-side-bar/right-side-bar-form';
import useFormSidebar from 'common/hooks/use-form-sidebar';
import { IFormSidebar } from 'common/models/types';
import React, { useState } from 'react';
import { CONFLICT_WITH_CURRENT_ACTIVE_RATE, CONFLICT_WITH_FUTURE_ACTIVE_RATE } from '../therapist-rates-validation-messages';
import TherapistRateFormBase from './therapist-rate-form-base';
import TherapistRateTemplateDepartaments from './therapist-rate-template-departaments';
import { ITherapistRateEditForm, RateErrorCodes } from './types';

const errorConfigs = [
    { errorCode: RateErrorCodes.EditRateActiveConstraintFailed, error: CONFLICT_WITH_CURRENT_ACTIVE_RATE, field: ['endDate', 'startDate'] },
    {
        errorCode: RateErrorCodes.EditRateActiveFutureConstraintFailed,
        error: CONFLICT_WITH_FUTURE_ACTIVE_RATE,
        field: ['startDate', 'endDate'],
    },
];

const TherapistRateDialogEdit = ({
    data: rateDetails,
    onClose,
    onSave,
    options,
}: IFormSidebar<ITherapistRateEditForm, ITherapistRateEditForm>) => {
    const [allowSave, setAllowSave] = useState(false);
    const [data, error, handleChangeData, handleCancelClick, handleSaveClick] = useFormSidebar<ITherapistRateEditForm>(
        {
            rateId: rateDetails.rateId,
            rate: rateDetails.rate || '0',
            startDate: rateDetails.startDate,
            endDate: rateDetails.endDate,
        },
        { onClose, onSave, errorConfigs }
    );

    const handleChangeDataWrapper = (...restData: any) => {
        handleChangeData(...restData);
        setAllowSave(true);
    };

    const renderRateTemplateTypes = () => {
        return <TherapistRateTemplateDepartaments rateTemplate={options}></TherapistRateTemplateDepartaments>;
    };

    return (
        <RightSideBarForm
            title="Edit Rate"
            onCancel={handleCancelClick}
            onSave={handleSaveClick}
            className="therapist-rate-edit-sidebar"
            renderAdditionalContent={renderRateTemplateTypes}
            disableSave={!allowSave}
        >
            <TherapistRateFormBase data={data} errors={error} isEdit={true} onChange={handleChangeDataWrapper} />
        </RightSideBarForm>
    );
};

export default TherapistRateDialogEdit;
