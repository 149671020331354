import React from 'react';
import FieldSet from 'common/components/field-set/field-set';
import InfiniteSelect from 'common/components/infinite-select/infinite-select';
import DropdownOption from 'common/models/dropdown-option';
import { DocumentCategoryFilterProps, DocumentCategoryFilterType } from './types';

const DocumentCategoryFilter: React.FC<DocumentCategoryFilterProps> = ({ filter, onFiltersChange }) => {
    const categoryOptions: DropdownOption[] = [
        new DropdownOption(DocumentCategoryFilterType.Additional, 'Additional'),
        new DropdownOption(DocumentCategoryFilterType.Expired, 'Expired'),
        new DropdownOption(DocumentCategoryFilterType.Inactive, 'Inactive'),
        new DropdownOption(DocumentCategoryFilterType.ToApprove, 'To approve'),
    ];

    const handleCategoryChange = (values: DocumentCategoryFilterType[]) => {
        onFiltersChange({ ...filter, category: values });
    };

    return (
        <FieldSet name="category">
            <InfiniteSelect
                id="therapistDocumentCategory"
                items={categoryOptions}
                label="Category"
                defaultMultipleSelectLabel="All Categories"
                value={categoryOptions.filter((item) => filter.category?.some((c) => c === item.value))}
                onChange={(items: DropdownOption[]) => handleCategoryChange(items.map((i: DropdownOption) => i.value))}
                multiselect
                isDisplaySelectedCount
            />
        </FieldSet>
    );
};

export default DocumentCategoryFilter;
