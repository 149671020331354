export enum DisplayMode {
    List = 'List',
    Map = 'Map',
}

export type ToggleViewModeProps = {
    activeMode: DisplayMode;

    onListClick: () => void;
    onMapClick: () => void;
};
