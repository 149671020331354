import { InfiniteServerClient } from 'common/helpers/http-clients';
import { IDepartment } from './types';

export class DepartmentService {
    getAll(): Promise<IDepartment[]> {
        return InfiniteServerClient.get<IDepartment[]>('/departments').then(
            (response) => {
                return response.data;
            },
            (error) => {
                throw error;
            }
        );
    }

    getDepartmentOptions(term: string, skip: number, take: number): Promise<IDepartment[]> {
        return InfiniteServerClient.get<IDepartment[]>('/departments/options', {
            params: {
                term,
                skip,
                take,
            },
        }).then(
            (response) => {
                return response.data;
            },
            (error) => {
                throw error;
            }
        );
    }
}

export default new DepartmentService();
