import { InfiniteServerClient } from 'common/helpers/http-clients';
import { ITherapyTypeOption } from './types';
export class TherapyService {
    getAll(departmentKey: string): Promise<ITherapyTypeOption[]> {
        return InfiniteServerClient.get(`therapies/types`, { params: { departmentKey } }).then(
            (response) => {
                return response.data;
            },
            (error) => {
                throw error;
            }
        );
    }

    getTherapyTypeOptions(departmentKey: string, term?: string, skip?: number, take?: number): Promise<ITherapyTypeOption[]> {
        return InfiniteServerClient.get(`therapies/types/options`, { params: { departmentKey, term, skip, take } }).then(
            (response) => {
                return response.data;
            },
            (error) => {
                throw error;
            }
        );
    }
}

export default new TherapyService();
