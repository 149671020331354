import React, { useEffect, useState } from 'react';
import NoteList from 'common/components/note-list/note-list';
import { NoteTabProps } from './types';
import CreateNoteDialog from 'common/components/create-note-dialog/create-note-dialog';
import { ICreateNoteRequest } from 'common/models/create-note-request';
import { useDispatch } from 'react-redux';
import { refreshNoteStatus } from 'app/store/notes/action-creators';

const NotePage: React.FC<NoteTabProps> = (props) => {
    const { onSave, onCompleteNoteClick, ...restProps } = props;
    const dispach = useDispatch();
    const [isNewNoteDialogOpen, setIsNewNoteDialogOpen] = useState(false);
    const [forceUpdate, setForceUpdate] = useState(0);
    const { viewOnly } = props;

    const handleClickOnNewNoteButton = () => {
        setIsNewNoteDialogOpen(true);
    };

    const handleClickOnCloseNewNoteDialog = () => {
        setIsNewNoteDialogOpen(false);
    };

    const handleUpdate = () => {
        handleClickOnCloseNewNoteDialog();
        setForceUpdate((prev) => prev + 1);
        dispach(refreshNoteStatus());
    };

    useEffect(() => {
        handleUpdate();
    }, [props.url]);

    return (
        <div className="notes-tab content-flex">
            <NoteList
                absoluteShadow
                onCompleteNoteClick={(id) => onCompleteNoteClick(id).then(handleUpdate)}
                forceUpdate={forceUpdate}
                onNewClick={handleClickOnNewNoteButton}
                {...restProps}
            />
            {!viewOnly && isNewNoteDialogOpen && (
                <CreateNoteDialog
                    onCancel={handleClickOnCloseNewNoteDialog}
                    onSave={(request: ICreateNoteRequest) => onSave(request).then(handleUpdate)}
                ></CreateNoteDialog>
            )}
        </div>
    );
};

export default NotePage;
