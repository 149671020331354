import { IPoint } from 'common/models/point';
import { IEntityOption } from './../entity/types';
import { IPatientRequest } from '../patient/types';
import { IPopulation } from '../population/types';
import { ILanguage } from '../language/types';
import { ITherapyTypeOption } from '../therapy/types';
import { IOption } from 'common/models/types';
import { IUserBaseInfo } from '../user/types';
import { IDiscipline } from '../disciplince/types';
import { ICreateNoteRequest } from '../../../models/create-note-request';
import { TherapistRequestStatus } from 'common/components/assigned-therapist-list/types';
import { IZipCode } from '../zip-code/types';
import { DisplayAddressType } from '../map/types';
import { IDepartment } from '../department/types';
import { IFacilityDetails } from '../nursing-home-requests/types';

export enum RequestStatus {
    Undefined,
    New,
    InProgress,
    Assigned,
    Declined,
    PendingAuth,
    PendingLegacy,
    Complete,
    Hold,
}

export const RequestStatusLabel = new Map<RequestStatus, string>([
    [RequestStatus.New, 'New'],
    [RequestStatus.InProgress, 'In Progress'],
    [RequestStatus.Assigned, 'Assigned'],
    [RequestStatus.PendingAuth, 'Pending Auth'],
    [RequestStatus.PendingLegacy, 'Pending Legacy'],
    [RequestStatus.Complete, 'Complete'],
    [RequestStatus.Declined, 'Declined'],
    [RequestStatus.Hold, 'Hold'],
]);

export interface IRequestsGridData {
    createdAt: Date;
    therapistType: string;
    therapistsCount: number;
    customer: string;
    patientName: string;
    patientID: number;
    source: string;
    requestID: number;
    internalReferralSource: string;
    externalReferralSource: string;
    requestStatus: string;
    authorizationStatus: IAuthorizationStatus;
    population: string;
    language: string;
    zipCode: string;
}

export interface IRequestCreateEditAddress {
    address1: string;
    address2: string;
    city: string;
    stateId: number;
    countyId: number;
    zip: string;
}

export interface ICreateRequestForm {
    therapyTypeIds: number[];
    source: string;
    specialInstructions?: string;
    customerId: number;
    facilityId: number;
    populationId: number;
    languageId: number;
    internalReferralSourceId: string;
    externalReferralSourceId: number;
    caseManagerId: number;
    patientId: number;
    patientAgencyCode: string;
    position: IPoint;
    zipCode: string;
    address1: string;
    address2: string;
    city: string;
    countyId: any;
    stateId: any;
    zip: string;
}

export interface ICreateRequestInfo {
    therapyTypeIds: number[];
    source: string;
    specialInstructions?: string;
    customerId: number;
    facilityId: number;
    populationId: number;
    languageId: number;
    internalReferralSourceId: string;
    externalReferralSourceId: number;
    caseManagerId: number;
    patientId: number;
    patientAgencyCode: string;
    zipCode: string;
    position: IPoint;
    address: IRequestCreateEditAddress;
}

export interface IRequestSetting {
    id: number;
    isDefault: boolean;
    setting: IRequestSettingBody;
}

export interface IRequestField {
    key: string;
    description: string;
}

export interface IRequestSettingBody {
    requestFields: IRequestField[];
    requiredFieldKeys: string[];
    daysForReopenDeclinedRequest: number;
}

export interface IRequestSettingRequestModel {
    requiredFieldKeys?: string[];
}

export interface ICreateRequestSettingRequestModel extends IRequestSettingRequestModel {
    customerId: number;
}

export interface IUpdateRequestSettingRequestModel extends IRequestSettingRequestModel {
    daysForReopenDeclinedRequest?: number;
}

export interface IRequestGroupStatus {
    search: string;
}

export interface IPatientDefaultFilters {
    patientName?: string;
    patientAgencyCode?: string;
}

export enum RequestStatusCount {
    New = 'newCount',
    AwaitingAuth = 'awaitingAuthCount',
    AcceptedToday = 'acceptedTodayCount',
    InProgress = 'inProgressCount',
    Assigned = 'assignedCount',
    PendingAuth = 'pendingAuthCount',
    PendingLegacy = 'pendingLegacyCount',
    Complete = 'completeCount',
    Declined = 'declinedCount',
    Hold = 'holdCount',
}

export const RequestCountToStatus = new Map<RequestStatusCount, string>([
    [RequestStatusCount.New, RequestStatus[RequestStatus.New]],
    [RequestStatusCount.InProgress, RequestStatus[RequestStatus.InProgress]],
    [RequestStatusCount.Assigned, RequestStatus[RequestStatus.Assigned]],
    [RequestStatusCount.AwaitingAuth, RequestStatus[RequestStatus.PendingAuth]],
    [RequestStatusCount.PendingLegacy, RequestStatus[RequestStatus.PendingLegacy]],
    [RequestStatusCount.Complete, RequestStatus[RequestStatus.Complete]],
    [RequestStatusCount.Declined, RequestStatus[RequestStatus.Declined]],
    [RequestStatusCount.Hold, RequestStatus[RequestStatus.Hold]],
]);

export const RequestStatusToRequestCount = new Map<string, RequestStatusCount>([
    [RequestStatus[RequestStatus.New], RequestStatusCount.New],
    [RequestStatus[RequestStatus.InProgress], RequestStatusCount.InProgress],
    [RequestStatus[RequestStatus.Assigned], RequestStatusCount.Assigned],
    [RequestStatus[RequestStatus.PendingAuth], RequestStatusCount.AwaitingAuth],
    [RequestStatus[RequestStatus.PendingLegacy], RequestStatusCount.PendingLegacy],
    [RequestStatus[RequestStatus.Complete], RequestStatusCount.Complete],
    [RequestStatus[RequestStatus.Declined], RequestStatusCount.Declined],
    [RequestStatus[RequestStatus.Hold], RequestStatusCount.Hold],
]);

export interface ITopFilter {
    [RequestStatusCount.New]: number;
    [RequestStatusCount.AwaitingAuth]: number;
    [RequestStatusCount.AcceptedToday]: number;
    [RequestStatusCount.InProgress]: number;
    [RequestStatusCount.Assigned]: number;
    [RequestStatusCount.PendingAuth]: number;
    [RequestStatusCount.PendingLegacy]: number;
    [RequestStatusCount.Complete]?: number;
    [RequestStatusCount.Declined]?: number;
    [RequestStatusCount.Hold]?: number;
}

export interface IAuthorizationStatus {
    name: string;
    value: string;
}

export interface IFile {
    name?: string;
    originalName: string;
    id: number;
    isDisabled?: boolean;
}

export interface IAuthorization {
    startDate: Date;
    endDate: Date;
    isAuthorized: boolean;
    evalId?: number;
    files: any[];
}

export interface IReason {
    id: number;
    name: string;
    type: string;
}

export interface IRequestDetails {
    therapists: any[];
    id: number;
    source: string;
    address: any;
    specialInstructions?: string;
    status: RequestStatus;
    authorizationStatus: IAuthorizationStatus;
    authorization: IAuthorization;
    createdAt: Date;
    updatedAt?: Date;
    zipCode: string;
    caseManager: IOption;
    internalReferralSource: IUserBaseInfo;
    externalReferralSource: IOption;
    language: ILanguage;
    population: IPopulation;
    customer: IEntityOption;
    facility: IFacilityDetails;
    therapyType: ITherapyTypeOption;
    patientDetails: IPatientRequest;
    createdBy: string;
    updatedBy: string;
    reason: IReason;
    reasonComment: string;
    lastDeclineDate?: string;
    primaryInsurance?: IInsurance;
    secondaryInsurance?: IInsurance;
    medicalAssistanceProgram?: IMedicalAssistanceProgram;
    patientAgencyCode: string;
    assignedTo: IUserBaseInfo;
    department: IDepartment;
    hasTherapists: boolean;
}

export interface IInsurance {
    id: number;
    name: string;
}

export interface IMedicalAssistanceProgram {
    id: number;
    name: string;
}

export type AssignedTherapistListItem = {
    id?: number;
    fullName?: string;
    firstName?: string;
    lastName?: string;
    disciplines?: IDiscipline[];
    rateLevel?: number;
    status?: string;
    logo?: string;
    note?: string;
};
export interface AddTherapistToRequest {
    requestId: number;
    therapistIds: number[];
}

export interface ITherapistRequestStatusForm {
    status: TherapistRequestStatus;
    note: ICreateNoteRequest;
}

export interface IRequestPointModel {
    position: IPoint;
    requests?: IRequestDataModel[];
    type: DisplayAddressType;
}

export interface IRequestDataModel {
    id: number;
    name: string;
    address: IRequestAddress;
    zipCode: IZipCode;
    therapyType: ITherapyTypeOption;
}

export interface IRequestAddress {
    address1: string;
    address2: any;
    postalCode: string;
    city: string;
    country: string;
    state: IRequestState;
    longitude: number;
    latitude: number;
}

export interface IRequestPatient {
    id: number;
    patientAgencyCode: string;
    patientName: string;
    zipCode: string;
}

export interface IRequestState {
    id: number;
    name: string;
    abbreviation: string;
}
