import React from 'react';
import NavigationItem from './navigation-item';
import { SingleNavItemProps } from './types';

const SingleNavItem = ({
    className,
    classIcon,
    routePath,
    abbreviation,
    expanded,
    itemName,
    additionalContent = null,
}: SingleNavItemProps) => {
    return (
        <div className={`${className} single-menu-main-item-wrapper  ${expanded ? 'extended' : ''}`}>
            <NavigationItem routePath={routePath} classIcon={classIcon} abbreviation={abbreviation}>
                {itemName} {additionalContent}
            </NavigationItem>
        </div>
    );
};

export default SingleNavItem;
