import React, { useState } from 'react';
import LeftNavigationBar from '../side-bar/left-navigation-bar';
import { logOut } from '../../../features/authentication/auth.actions';
import store from 'app/store';
import { IDefaultLayoutContext } from './types';
import { LeftNavigationBarExternalUser } from '../side-bar/left-navigation-bar-external-user/left-navigation-bar-external-user';
import { useIsUserType } from '../../hooks/use-is-user-type';
import Zoom from '../zoom/zoom';

export const DefaultLayoutContext = React.createContext<IDefaultLayoutContext>(null);

function DefaultLayout({ children }: any) {
    const [isExpanded, setExpanded] = useState(false);
    const toggle = () => setExpanded((isExpanded) => !isExpanded);
    const [contentClass, setContentClass] = useState('');
    const { isExternal, isInfiniteUser, isTherapistUser } = useIsUserType();

    const getSideBar = () => {
        if (isExternal) {
            return <LeftNavigationBarExternalUser onLogOut={() => store.dispatch(logOut())} expanded={isExpanded} toggle={toggle} />;
        }

        if (isInfiniteUser || isTherapistUser) {
            return <LeftNavigationBar onLogOut={() => store.dispatch(logOut())} expanded={isExpanded} toggle={toggle} />;
        }

        return new Error('User type is not found');
    };

    return (
        <DefaultLayoutContext.Provider value={{ sidebarWidth: isExpanded ? 260 : 65, setContentClass }}>
            <div className={isExpanded ? 'navigation-bar expanded' : 'navigation-bar'}>{getSideBar()}</div>
            <div className={`${contentClass} ${isExpanded ? 'content compressed' : 'content'}`}>{children}</div>
            <Zoom />
        </DefaultLayoutContext.Provider>
    );
}

export default DefaultLayout;
