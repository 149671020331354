import AssignedTherapistTable from 'common/components/assigned-therapist-list/assigned-therapist-table';
import { TherapistSchoolRequestActions } from 'common/components/assigned-therapist-list/types';
import { AvailableTherapistForSchoolRequestColumnDefs } from 'common/components/availability/availability-table/available-therapist-table';
import Card, { CardWrapper } from 'common/components/card';
import ActionButton from 'common/components/card/parts/action-button/action-button';
import { ICardConfig } from 'common/components/card/types';
import DisplayValue from 'common/components/display-value/display-value';
import { dateTimeConstants, maskConstants } from 'common/constants/common';
import { ErrorCodes } from 'common/constants/error-codes';
import { schoolIconArray } from 'common/constants/map/map';
import { RequestType } from 'common/constants/types';
import { DateTimeHelper } from 'common/helpers/date-time-helper';
import { useIsUserType } from 'common/hooks/use-is-user-type';
import schoolService from 'common/services/api/school/school-service';
import { ISchoolNewRequest, SchoolRequestStatus } from 'common/services/api/school/types';
import { getTableData } from 'common/services/api/tabel-fetch-service';
import TherapistAssignDialog from 'features/request-page/components/request-details/general-info-tab/basic-info-tab/thrapist-assign-dialog/thrapist-assign-dialog';
import AddEditSchoolSidebar from 'features/school-page/components/add-edit-school-sidebar/add-edit-school-sidebar';
import moment from 'moment';
import React, { useContext, useMemo, useState } from 'react';
import { trackPromise } from 'react-promise-tracker';
import { toast } from 'react-toastify';
import { SchoolRequestDetailsContext } from '../../school-request-details-page';
import { SchoolRequestBasicInfoTabProp } from '../../types';
import './school-request-basic-info-tab.scss';
import { useResponsiveLayout } from 'common/hooks/use-responsive-layout';

const renderDaysOpen = (createdAt: Date) => {
    const start = moment(createdAt);
    const end = moment(new Date());

    return Math.floor(moment.duration(end.diff(start)).asDays());
};

const SchoolRequestBasicInfoTab: React.FC<SchoolRequestBasicInfoTabProp> = ({ requestId, requestDetails }) => {
    const [isCaseBarOpen, toggleCaseBar] = useState(false);
    const [isTherapistAssignOpen, toggleTherapistAssign] = useState(false);
    const { setRequestDetails, updateDetailsPage } = useContext(SchoolRequestDetailsContext);
    const { isExternal } = useIsUserType();
    const isDeclined = () => requestDetails?.status === SchoolRequestStatus.Declined;

    const { placeOfService } = requestDetails;
    const caseDetailsConfig: ICardConfig[] = [
        { title: 'Case ID', field: 'id' },
        { title: 'Year', field: 'year' },
        { title: 'DBN', field: 'placeOfService', render: (value) => value?.dbn },
        { title: 'BID Rank', field: 'bidRank', render: (value) => value?.name },
        { title: 'Days Open', field: 'createdAt', render: renderDaysOpen },
        { title: 'Therapy', field: 'serviceType', render: (value) => value?.name },
        { title: 'Lang', field: 'language', render: (value) => value?.name },
        { title: 'Number of Providers', field: 'providers', render: (value) => value?.length },
        { title: 'Stdnts', field: 'providers', render: (value) => value?.map((provider: any) => provider?.students + '').join(', ') },
        { title: 'Days', field: 'providers', render: (value) => value?.map((provider: any) => provider?.days + '').join(', ') },
        { title: 'Start', field: 'startDate', render: (value) => DateTimeHelper.format(value, dateTimeConstants.MM_DD_YYYY, true) },
        { title: 'End', field: 'endDate', render: (value) => DateTimeHelper.format(value, dateTimeConstants.MM_DD_YYYY, true) },
        { title: 'Priority Level', field: 'priorityLevel', render: (value) => value?.name },
        { title: 'Contract Type', field: 'contractType', render: (value) => value?.name },
        { title: 'DOE Contact', field: 'doeContact', render: (value) => value?.name },
        { title: 'POS Contact', field: 'posContact', render: (value) => value?.name },
        { title: 'School name', field: 'placeOfService', render: (value) => value?.name },
        { title: 'Population', field: 'population', render: (value) => value?.name },
        { title: 'Special Instructions', field: 'specialInstructions' },
    ];

    const placeOfServiceConfig: ICardConfig[] = [
        {
            title: 'Address 1',
            field: 'placeOfService',
            render: (value) => value?.address?.address1,
        },
        {
            title: 'Address 2',
            field: 'placeOfService',
            render: (value) => value?.address?.address2,
        },
        {
            title: 'City',
            field: 'placeOfService',
            width: '50%',
            render: (value) => value?.address?.city,
        },
        {
            title: 'State',
            field: 'placeOfService',
            width: '50%',
            render: (value) => value?.address?.state?.name,
        },
        {
            title: 'County',
            field: 'placeOfService',
            width: '50%',
            render: (value) => value?.address?.county?.name,
        },
        {
            title: 'Zip',
            field: 'placeOfService',
            width: '50%',
            render: (value) => value?.address?.postalCode,
        },
        {
            title: 'First Name',
            field: 'posContact',
            width: '50%',
            render: (value) => value?.contact?.firstName,
        },
        {
            title: 'Last Name',
            field: 'posContact',
            width: '50%',
            render: (value) => value?.contact?.lastName,
        },
        {
            title: 'Email',
            field: 'posContact',
            width: '50%',
            render: (value) => value?.contact?.emails?.find((i: any) => i?.seq === 1).email,
        },
        {
            title: 'Phone',
            field: 'posContact',
            width: '50%',
            render: (value) => {
                const number = value?.contact?.phones?.find((i: any) => i?.seq === 1).number;

                return <DisplayValue placeholder="" value={number} mask={maskConstants.PHONE_NUMBER} />;
            },
        },
    ];

    const openAvailableTherapistList = () => {
        toggleTherapistAssign(true);
    };

    const handleFetchData = useMemo(() => {
        const requestUrl: string = `school/requests/${requestId}/therapists`;

        return getTableData(requestUrl);
    }, [requestId]);

    const handleAddTherapistToRequest = (requestId: number, therapistIds: number[]) => {
        schoolService
            .addTherapistToRequest({
                requestId,
                therapistIds,
            })
            .then(() => {
                setRequestDetails(null);
                toggleTherapistAssign(false);
            });
    };

    const handleClickOnCaseEdit = () => {
        toggleCaseBar(true);
    };

    const handleClickOnCaseClose = () => {
        toggleCaseBar(false);
    };

    const handleClickCaseSave = (request: ISchoolNewRequest) => {
        return trackPromise(schoolService.update(requestDetails.id, request)).then(() => {
            toggleCaseBar(false);
            setRequestDetails(null);
        });
    };

    const handleChangeStatusError = (error: any) => {
        const errorCode = error?.response?.data?.errorCode;

        if (errorCode === ErrorCodes.THERAPIST_NOT_AUTHORIZED_FOR_DEPARTMENT) {
            toast.error(`Provider is not authorized for Schools department`);
        } else {
            toast.error(error?.response?.data?.errorMessage || 'Some error occurred');
        }
    };

    const transformPlaceOfServiceAddress = () => {
        const address = placeOfService?.address;

        return (
            (address?.address1 || address?.postalCode) && {
                address1: address.address1,
                city: address.city,
                state: address.state,
                zip: address.postalCode,
            }
        );
    };

    const mapRequestDetailsToTherapistAssignProp = (): any => {
        return {
            ...requestDetails,
            population: undefined,
            therapyType: requestDetails.serviceType,
        };
    };

    const { mediaClass } = useResponsiveLayout({
        contentWrapper: '.school-details-base-info-tab',
        onResize: (width, contentWidth) => {
            let className = '';
            if (width <= 1600 || contentWidth <= 1329) {
                className = 'lg-card';
            }
            if (width <= 1426 || contentWidth <= 1155) {
                className = 'md-card';
            }
            if (width <= 1260 || contentWidth <= 989) {
                className = 'sm-card';
            }
            if (width <= 1024 || contentWidth <= 753) {
                className = 'extra-sm-card';
            }

            return className;
        },
    });

    return (
        <div className="school-details-base-info-tab">
            <CardWrapper className={`school-grid-wrapper ${mediaClass}`}>
                <Card
                    titleIcon="icon-job"
                    title="Case Details"
                    className="request-case-details-card box-1"
                    defaultValue="-"
                    config={caseDetailsConfig}
                    onEdit={!isDeclined() ? handleClickOnCaseEdit : null}
                    data={requestDetails}
                />
                <Card
                    titleIcon="icon-facility"
                    title="Place of Service"
                    data={requestDetails}
                    defaultValue="-"
                    config={placeOfServiceConfig}
                    className="school-request-place-of-service-card box-2"
                />
                <Card
                    className="school-request-therapist-details-card assigned-therapist-table box-4"
                    titleIcon="icon-technician"
                    title="Provider Assignment"
                    defaultValue="-"
                    actionButtons={<>{!isExternal && <ActionButton icon="icon-add" onClick={openAvailableTherapistList} />}</>}
                >
                    <AssignedTherapistTable
                        requestId={requestId}
                        hideDoteMenu={isExternal}
                        maxAssignedTherapists={requestDetails.providers.length}
                        onFetchData={handleFetchData}
                        onChangeStatus={(requestId, _therapistRequestId, statusForm, therapistId) =>
                            schoolService
                                .setStatusToTherapistRequest(requestId, therapistId, statusForm)
                                .then(updateDetailsPage)
                                .catch(handleChangeStatusError)
                        }
                        customRequestActions={TherapistSchoolRequestActions}
                        onSaveNote={(requestId, _therapistRequestId, statusForm, therapistId) =>
                            schoolService.addNoteToTherapistRequest(requestId, therapistId, statusForm)
                        }
                    />
                </Card>
            </CardWrapper>
            {isTherapistAssignOpen && (
                <TherapistAssignDialog
                    toggleTherapistAssign={toggleTherapistAssign}
                    iconArray={schoolIconArray}
                    addressDetails={transformPlaceOfServiceAddress()}
                    requestDetails={mapRequestDetailsToTherapistAssignProp()}
                    requestType={RequestType.School}
                    onAddTherapistToRequest={handleAddTherapistToRequest}
                    extendGridOptions={{ columnDefs: AvailableTherapistForSchoolRequestColumnDefs }}
                />
            )}
            {isCaseBarOpen && <AddEditSchoolSidebar onClose={handleClickOnCaseClose} onSave={handleClickCaseSave} data={requestDetails} />}
        </div>
    );
};

export default SchoolRequestBasicInfoTab;
