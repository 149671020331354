import { Dispatch } from '@reduxjs/toolkit';
import countyService from 'common/services/api/county/county-service';
import { ICounty } from 'common/services/api/county/types';
import { ISetCountiesAction, ThunkResult } from './types';

export const setCounties = (payload: ICounty[]): ISetCountiesAction => {
    return {
        counties: payload,
        type: 'SET_COUNTIES',
    };
};

export const fetchCounties = (): ThunkResult<Promise<ICounty[]>> => {
    return async (dispatch: Dispatch) => {
        const data = await countyService.getAllCounties();
        dispatch(setCounties(data));

        return data;
    };
};
