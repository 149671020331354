export class GoogleGeocodingService {
    geocode(city: string, address1: string, zip: string): Promise<any> {
        const formatAddress = (city: string, address1: string, zip: string): string => {
            let address = address1 ?? '';

            if (city) {
                address += address?.length > 0 ? ', ' + city : city;
            }

            if (zip) {
                address += address?.length > 0 ? ', ' + zip : zip;
            }

            return address;
        };

        const address = formatAddress(city, address1, zip);

        return this.geocodeProcess(address);
    }

    private geocodeProcess = (address: string): Promise<any> => {
        return new Promise((resolve, reject) => {
            if (!address) {
                resolve(null);
            }

            new google.maps.Geocoder().geocode({ address: address, componentRestrictions: { country: 'us' } }, (result, status) => {
                if (status !== google.maps.GeocoderStatus.OK) {
                    return reject(`Google was not able to geocode this address. Status = '${status}'`);
                } else {
                    if (result.length === 0) {
                        return reject(`Google was not able to geocode this address`);
                    }

                    const lat = result[0].geometry.location.lat();
                    const lng = result[0].geometry.location.lng();

                    return resolve({ latitude: lat, longitude: lng });
                }
            });
        });
    };
}

export default new GoogleGeocodingService();
