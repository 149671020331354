import { DateTimeHelper } from 'common/helpers/date-time-helper';
import { ValidationHelper } from 'common/helpers/validation-helper';
import { ITherapistBackgroundCheckInfo } from './types';
import { InfiniteServerClient } from 'common/helpers/http-clients';

export class TherapistBackgroundCheckService {
    addTherapistBackgroundCheck(therapistId: number, backgroundCheck: ITherapistBackgroundCheckInfo) {
        const bodyFormData = getFormData(backgroundCheck);

        return (
            ValidationHelper.validateFile(bodyFormData) ??
            InfiniteServerClient.post(`/therapists/${therapistId}/background-checks`, bodyFormData).then(
                (response) => {
                    const data = response.data;
                    if (data) {
                        return data;
                    }
                },
                (error) => {
                    throw error.response.data;
                }
            )
        );
    }

    updateTherapistBackgroundCheck(therapistId: number, backgroundCheck: ITherapistBackgroundCheckInfo) {
        const bodyFormData = getFormData(backgroundCheck);

        return (
            ValidationHelper.validateFile(bodyFormData) ??
            InfiniteServerClient.put(`/therapists/${therapistId}/background-checks/${backgroundCheck.id}`, bodyFormData).then(
                (response) => {
                    const data = response.data;
                    if (data) {
                        return data;
                    }
                },
                (error) => {
                    throw error.response.data;
                }
            )
        );
    }

    updateTherapistBackgroundCheckHistory = (therapistId: number, backgroundCheckHistory: ITherapistBackgroundCheckInfo) => {
        const bodyFormData = getFormData(backgroundCheckHistory);

        return (
            ValidationHelper.validateFile(bodyFormData) ??
            InfiniteServerClient.put(
                `/therapists/${therapistId}/background-checks/history/${backgroundCheckHistory.id}`,
                bodyFormData
            ).then(
                (response) => {
                    const data = response.data;
                    if (data) {
                        return data;
                    }
                },
                (error) => {
                    throw error.response.data;
                }
            )
        );
    };
}

const getFormData = (object: any) => {
    const bodyFormData = new FormData();
    for (const key in object) {
        let value = object[key];
        if (value) {
            if (value._isAMomentObject) {
                value = DateTimeHelper.format(value.toDate(), null, true);
            }

            if (value instanceof Date) {
                value = DateTimeHelper.format(value, null, true);
            }

            bodyFormData.append(key, value);
        }
    }

    return bodyFormData;
};

export default new TherapistBackgroundCheckService();
