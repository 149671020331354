import { InfiniteServerClient } from 'common/helpers/http-clients';
import { IPhysiciansForm, IPhysiciansModel, IPhysiciansOptionModel, IPhysiciansParams, IPhysiciansRow } from './types';
import { SequenceHelper } from '../../../helpers/sequence-helper';
import { IEntity, IEntityOption } from '../entity/types';
import { IEmail, IPhone } from '../../../../features/shared/types';
import entityService from '../entity/entity-service';
import { entityTypeKey } from '../../../constants/entity-type';
import { optionsFormatter } from '../../../helpers/ag-helper';

export class PhysiciansService {
    getDoctorOfficeOptions(): Promise<IEntity[]> {
        return entityService.getEntityOptions(null, null, null, entityTypeKey.DO);
    }

    getPhysicianOptions(term: string, skip: number, take: number): Promise<IPhysiciansOptionModel[]> {
        return InfiniteServerClient.get('/physicians/options', { params: { term, skip, take } }).then(
            (response) => {
                return response?.data;
            },
            (error) => {
                throw error;
            }
        );
    }

    getPracticeOptions(physicianId: string): Promise<IEntityOption[]> {
        if (!physicianId) {
            return Promise.resolve([]);
        }

        return InfiniteServerClient.get(`physicians/${physicianId}/practices`).then(
            async (response) => {
                return response?.data;
            },
            (error) => {
                throw error;
            }
        );
    }

    edit(physician: IPhysiciansForm): Promise<IPhysiciansRow> {
        if (!physician?.id) {
            throw new Error('Please add physician id for edit a physician');
        }

        return InfiniteServerClient.put(`physicians/${physician?.id}`, physician).then(
            (response) => {
                return this._transformPhysicianToRows(response.data);
            },
            (error) => {
                throw error;
            }
        );
    }

    create(physician: IPhysiciansForm): Promise<IPhysiciansRow> {
        return InfiniteServerClient.post(`/physicians`, physician).then(
            (response) => {
                return this._transformPhysicianToRows(response.data);
            },
            (error) => {
                throw error;
            }
        );
    }

    getData(params?: IPhysiciansParams): Promise<IPhysiciansRow[]> {
        return InfiniteServerClient.get('/physicians', { params }).then(
            (response) => {
                return response?.data?.map(this._transformPhysicianToRows);
            },
            (error) => {
                throw error;
            }
        );
    }

    _transformPhysicianToRows(item: IPhysiciansModel): IPhysiciansRow {
        const phones: any = SequenceHelper.getSequenceItems(item?.phones);
        const emails: any = SequenceHelper.getSequenceItems(item?.emails);
        const isFax = (item: any) => item.type.toLowerCase() === 'fax';
        const fax = phones?.find(isFax);

        const result = {
            ...item,
            address1: item?.address?.address1,
            address2: item?.address?.address2,
            city: item?.address?.city,
            state: item?.address?.state,
            postalCode: item?.address?.postalCode,
            phones: phones as IPhone[],
            phonesView: phones?.filter((phone: IPhone) => !isFax(phone))?.[0]?.number,
            fax: fax?.number,
            emails: emails as IEmail[],
        };

        /* For value for Exel. Because ag-grid is ignored a cell formatter*/
        if (result.practices) {
            result.practices.toString = (): string => {
                return optionsFormatter({ value: result.practices });
            };
        }
        if (result.state) {
            result.state.toString = (): string => {
                return result.state?.name;
            };
        }
        if (result.emails) {
            result.emails.toString = (): string => {
                return result.emails?.length > 0 ? result.emails[0].email : '';
            };
        }

        return result;
    }
}

export default new PhysiciansService();
