import React, { useState } from 'react';
import DropdownOption from 'common/models/dropdown-option';
import RightSideBar from 'common/components/right-side-bar/right-side-bar';
import FieldSet from 'common/components/field-set/field-set';
import DateField from 'common/components/field/date-field';
import InfiniteSelect from 'common/components/infinite-select/infinite-select';
import { useDispatch } from 'react-redux';
import FileField from 'common/components/field/file-field/file-field';
import { trackPromise } from 'react-promise-tracker';
import { UploadTherapistDocumentDialogProps } from './type';
import { FieldValue } from 'common/models/field-value';
import { ThunkDispatch } from 'redux-thunk';
import { Action } from '@reduxjs/toolkit';
import { ITherapistDocumentInfo } from 'common/services/api/therapist-document/types';
import therapistDocumentService from 'common/services/api/therapist-document/therapist-document-service';
import { getDocumentsRequest, getTherapistDetailsRequest } from 'app/store/therapist/action-creators';

// TO-DO: Set tab indexes on the form
const UploadTherapistDocumentDialog: React.FC<UploadTherapistDocumentDialogProps> = ({
    therapistId,
    document,
    onCancel,
    isEdit,
    isHistory,
}) => {
    const [currentDocumentType, setCurrentDocumentType] = useState(
        isEdit
            ? document.acceptableDocuments.find((x) => x.id === document.documentId)
            : document.acceptableDocuments.length === 1
            ? document.acceptableDocuments[0]
            : null
    );

    const [therapistDocument, setTherapistDocument] = useState<ITherapistDocumentInfo>({
        id: isEdit ? document.id : undefined,
        documentRuleId: document.documentRuleId,
        file: isEdit ? { name: document.fileOriginalName } : undefined,
        issuedDate: isEdit && document.issuedDate ? new Date(document.issuedDate) : undefined,
        expirationDate: isEdit && document.expirationDate ? new Date(document.expirationDate) : undefined,
        documentId: currentDocumentType?.id,
        comment: currentDocumentType?.comment,
        instructions: currentDocumentType?.instructions,
    });

    const [errorData, setErrorData] = useState<any>({});
    const dispatch = useDispatch<ThunkDispatch<any, any, Action>>();

    const handleFieldChange = (data: FieldValue) => {
        setTherapistDocument((prevInfo) => ({ ...prevInfo, [data.name]: data.value }));
        setErrorData((prevError: any) => ({ ...prevError, [data.name]: undefined }));
    };

    const handleDocumentTypeFieldChange = (value: any) => {
        const documentType = document.acceptableDocuments.find((x) => x.id === value);
        setCurrentDocumentType(documentType);

        setTherapistDocument((prevInfo) => ({
            ...prevInfo,
            documentId: value,
            comment: documentType.comment,
            instructions: documentType.instructions,
        }));
        setErrorData((prevError: any) => ({
            ...prevError,
            documentId: undefined,
            comment: undefined,
            instructions: undefined,
        }));
    };

    const handleSave = () => {
        let saveRequest = therapistDocumentService.addTherapistDocument;

        if (isEdit) {
            saveRequest = isHistory
                ? therapistDocumentService.updateTherapistDocumentHistory
                : therapistDocumentService.updateTherapistDocument;
        }

        trackPromise(
            saveRequest(therapistId, therapistDocument)
                .then(() => {
                    dispatch(getDocumentsRequest(therapistId));
                    trackPromise(dispatch(getTherapistDetailsRequest(therapistId)));
                    onCancel();
                })
                .catch(setErrorData)
        );
    };

    const acceptableOptions = document.acceptableDocuments.map((i) => new DropdownOption(i.id, i.name));

    return (
        <div>
            <RightSideBar title={`Upload ${isEdit ? document.document : document.ruleName}`} onCancel={onCancel} onSave={handleSave}>
                <div className="edit-therapist-form">
                    <FieldSet errors={errorData} name="file" customClass="edit-therapist-field">
                        <FileField
                            name="file"
                            onChange={handleFieldChange}
                            placeholder="Upload or Drop File"
                            iconClass="icon-upload-file"
                            uploadedFileName={therapistDocument.file?.name}
                            isError={errorData.file}
                        />
                    </FieldSet>
                    {acceptableOptions && acceptableOptions.length > 1 && (
                        <FieldSet errors={errorData} name="documentId" customClass="edit-therapist-field">
                            <InfiniteSelect
                                disabled={isEdit === true && !isHistory}
                                items={acceptableOptions}
                                label="Type"
                                value={acceptableOptions.find((item) => therapistDocument.documentId === item.value)}
                                onChange={(item) => handleDocumentTypeFieldChange(item.value)}
                            />
                        </FieldSet>
                    )}
                    {currentDocumentType?.isIssueDateRequired && (
                        <FieldSet name="issuedDate" errors={errorData} customClass="edit-therapist-field">
                            <DateField
                                placeholder="Issue Date"
                                name="issuedDate"
                                onChange={handleFieldChange}
                                value={therapistDocument.issuedDate}
                                iconClass="icon-calendar"
                            />
                        </FieldSet>
                    )}
                    {currentDocumentType?.isExpirationDateRequired && (
                        <FieldSet name="expirationDate" errors={errorData} customClass="edit-therapist-field">
                            <DateField
                                placeholder="Expiration Date"
                                name="expirationDate"
                                onChange={handleFieldChange}
                                value={therapistDocument.expirationDate}
                                iconClass="icon-calendar"
                            />
                        </FieldSet>
                    )}
                    {therapistDocument.comment && (
                        <div className="document-notes">
                            <div className="col icon icon-info notes-icon"></div>
                            <div className="col">
                                <h4 className="notes-label">Internal Comment</h4>
                                <div className="notes-content" title={therapistDocument.comment}>
                                    {therapistDocument.comment}
                                </div>
                            </div>
                        </div>
                    )}
                    <br />
                    {therapistDocument.instructions && (
                        <div className="document-notes">
                            <div className="col icon icon-info notes-icon"></div>
                            <div className="col">
                                <h4 className="notes-label">Instruction</h4>
                                <div className="notes-content" title={therapistDocument.instructions}>
                                    {therapistDocument.instructions}
                                </div>
                            </div>
                        </div>
                    )}
                </div>
            </RightSideBar>
            <div className="right-sidebar-overlay"></div>
        </div>
    );
};

export default UploadTherapistDocumentDialog;
