import { InfiniteServerClient } from 'common/helpers/http-clients';

import { ITopFollowUpFilter } from './types';

export class FollowUpsService {
    getStatusCount(queryParams: string = ''): Promise<ITopFollowUpFilter> {
        return InfiniteServerClient.get<ITopFollowUpFilter>(`requests/therapists/status/counts?${queryParams}`).then(
            (response) => {
                return response.data;
            },
            (error) => {
                throw error;
            }
        );
    }
}

export const followUpsService = new FollowUpsService();
