import MultipleBadges from 'common/components/badges/multiple-badges';
import React, { useCallback, useEffect } from 'react';
import BadgeTopFilter from 'common/components/badges/badge/badge-top-filter/badge-top-filter';
import { IBadge } from 'common/components/badges/types';
import { MultipleBadgesFilterProps } from './types';
import { IFilterComp } from 'ag-grid-community';

const MultipleBadgesFilter = ({
    defaultKeys,
    repository,
    items,
    gridApi,
    setDefaultKeys,
    updateCount,
    statusLabel,
    statusToRequestCount,
    convertStringToRequestStatus,
}: MultipleBadgesFilterProps) => {
    const handleBadgeChange = useCallback(
        (currentValues: IBadge[]) => {
            if (!gridApi) {
                return;
            }

            type filterModelType = {
                values: string[];
            };

            function setFilter(name: string, filterModel: filterModelType) {
                gridApi.getFilterInstance(name, (filterApi) => {
                    filterApi.setModel({ ...filterApi.getModel(), exclude: false, ...filterModel });
                });
                gridApi.onFilterChanged();
            }

            const status: string[] = currentValues?.map((value) => statusLabel.get(convertStringToRequestStatus(value.value)));
            setFilter('status', { values: status?.length ? status : [] });
        },
        [gridApi, convertStringToRequestStatus, statusLabel]
    );

    // Status column to Badge
    useEffect(() => {
        if (!gridApi) {
            return;
        }

        const handleFilterChange = (e: any) => {
            e.api.getFilterInstance('status', (filterApi: IFilterComp) => {
                if (filterApi.getModel()?.values) {
                    setDefaultKeys(filterApi.getModel().values.map((value: string) => statusToRequestCount.get(value)));
                } else {
                    setDefaultKeys([]);
                }
            });
            updateCount();
        };

        gridApi.addEventListener('filterChanged', handleFilterChange);

        return () => {
            gridApi.removeEventListener('filterChanged', handleFilterChange);
        };
    }, [gridApi, updateCount, setDefaultKeys, statusToRequestCount]);

    return (
        <MultipleBadges
            className="pl-25"
            items={items}
            defaultKey={defaultKeys}
            onChange={handleBadgeChange}
            badgeComponent={BadgeTopFilter}
            repository={repository}
        />
    );
};

export default React.memo(MultipleBadgesFilter);
