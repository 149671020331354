import React from 'react';
import Button from '../Button/button';
import { MarkAdDoneButtonProps } from './types';

const MarkAsDoneButton: React.FC<MarkAdDoneButtonProps> = ({ onClick }) => {
    return (
        <div className="mark-as-done-wrapper">
            <Button className="mark-as-done-button" id="mark-as-done-btn" onClick={onClick}>
                <span className="icon icon-check" /> Mark as Done
            </Button>
        </div>
    );
};

export default MarkAsDoneButton;
