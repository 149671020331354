import DropdownAutocomplete from 'common/components/field/dropdown/dropdown-autocomplete';
import { ConverterHelper } from 'common/helpers/converter-helper';
import { useDepartmentData } from 'common/hooks/use-department-data';
import DropdownOption from 'common/models/dropdown-option';
import React, { useCallback, useEffect, useState } from 'react';
import { DepartmentGroupAutocompleteProps } from './types';

export const DepartmentGroupAutocomplete: React.FC<DepartmentGroupAutocompleteProps> = ({
    departments,
    subDepartments,
    onDepartmentChange,
    onSubDepartmentChange,
    hideSubDepartment,
    widthOptions,
    popper,
    defaultSelectedLabelDepartment = 'All',
    defaultSelectedLabelSubDepartment = 'All',
    cleanable = true,
}) => {
    const departmentData = useDepartmentData();
    const allDepartmentOptions = departmentData.map((item) => new DropdownOption(item.id, item.name));
    const allSubDepartmentOptions = departmentData.flatMap((i) =>
        i.subDepartments.map((sb) => ConverterHelper.subDepartmentConvert(sb, i.name))
    );

    const [departmentTerm, setDepartmentTerm] = useState<string>('');
    const [subDepartmentTerm, setSubDepatmentTerm] = useState<string>();

    const avaliableSubDepartments = departmentData
        .filter((item) => departments.includes(item.id))
        .flatMap((i) => i.subDepartments.map((sb) => ConverterHelper.subDepartmentConvert(sb, i.name)));

    useEffect(() => {
        if (departmentData?.length > 0) {
            const newPreferredSubDepartments = subDepartments.filter((item) =>
                avaliableSubDepartments.some((avaliable) => avaliable.value === item)
            );

            if (!subDepartments.every((item) => newPreferredSubDepartments.includes(item))) {
                onSubDepartmentChange(newPreferredSubDepartments);
            }
        }
    }, [avaliableSubDepartments, departmentData, onSubDepartmentChange, subDepartments]);

    const handleDepartmentChange = (value: DropdownOption[]) => {
        onDepartmentChange(value.map((i) => i.value));
    };

    const handleSubDepartmentChange = (value: DropdownOption[]) => {
        onSubDepartmentChange(value.map((i) => i.value));
    };

    const determineDepartmentOptions = useCallback(() => {
        return departmentTerm
            ? allDepartmentOptions.filter((department) => department.label.toLowerCase().includes(departmentTerm.toLowerCase()))
            : allDepartmentOptions;
    }, [departmentTerm, allDepartmentOptions]);

    const determineSubDepartmentOptions = useCallback(() => {
        return subDepartmentTerm
            ? avaliableSubDepartments.filter((subDepartment) => subDepartment.label.toLowerCase().includes(subDepartmentTerm.toLowerCase()))
            : avaliableSubDepartments;
    }, [subDepartmentTerm, avaliableSubDepartments]);

    return (
        <>
            <DropdownAutocomplete
                popper={popper}
                widthOptions={widthOptions}
                items={determineDepartmentOptions()}
                cleanable={cleanable}
                label="Department"
                defaultSelectedLabel={defaultSelectedLabelDepartment}
                multiselect
                searchPlaceholder={'Search Department'}
                value={allDepartmentOptions.filter((item) => departments.some((id) => id === item.value))}
                onChange={setDepartmentTerm}
                onSelect={handleDepartmentChange}
            />
            {!hideSubDepartment && determineSubDepartmentOptions().length > 0 && (
                <DropdownAutocomplete
                    items={determineSubDepartmentOptions()}
                    label="Sub Department"
                    cleanable={cleanable}
                    defaultSelectedLabel={defaultSelectedLabelSubDepartment}
                    multiselect
                    searchPlaceholder={'Search Sub Department'}
                    value={allSubDepartmentOptions.filter((item) => subDepartments.some((id) => id === item.value))}
                    onChange={setSubDepatmentTerm}
                    onSelect={handleSubDepartmentChange}
                />
            )}
        </>
    );
};

export default React.memo(DepartmentGroupAutocomplete);
