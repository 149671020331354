import { refreshNoteStatus } from 'app/store/notes/action-creators';
import { CurrentNoteStatus } from 'common/services/api/note/types';
import React from 'react';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import NavigationItem from './navigation-item';
import { NotesMenuItemProps } from './types';

const refreshStatusInterval: number = 60000;

const NotesMenuItem: React.FC<NotesMenuItemProps> = ({ extended }) => {
    const currentNoteStatus = useSelector<any, CurrentNoteStatus>((state: any) => state.note.status || CurrentNoteStatus.Empty);
    const dispatch = useDispatch();

    useEffect(() => {
        const updateNoteStatus = () => {
            dispatch(refreshNoteStatus());
        };

        updateNoteStatus();
        const id = setInterval(updateNoteStatus, refreshStatusInterval);

        return () => clearInterval(id);
    }, [dispatch]);

    const determineStatusClassName = () => {
        if (currentNoteStatus === CurrentNoteStatus.Overdue || currentNoteStatus === CurrentNoteStatus.Today) {
            return 'red';
        }

        if (currentNoteStatus === CurrentNoteStatus.ScheduledForTomorrow) {
            return 'green';
        }

        return '';
    };

    return (
        <div className={`notes-menu-item-wrapper  ${extended ? 'extended' : ''}`}>
            <NavigationItem classIcon=" icon-notes" routePath="/notes" key="1">
                <span className={determineStatusClassName()}></span>
                <span className="menu-item-notes">Notes</span>
            </NavigationItem>
        </div>
    );
};

export default NotesMenuItem;
