import React, { useEffect, useRef } from 'react';
import ReactDOM from 'react-dom';
import { usePopper } from 'react-popper';
import { PopperProps } from './types';
import useOnClickOutside from 'react-cool-onclickoutside';
import { customOffsetModifier } from './constants';

const InfinitePopper = ({
    target,
    width = 300,
    children = 'Popup Content',
    className = '',
    onClickOutside,
    placement = 'bottom-end',
    blockPageScroll = false,
    options,
}: PopperProps) => {
    const [popperElement, setPopperElement] = React.useState(null);
    const { styles, attributes } = usePopper(target, popperElement, {
        placement: placement,
        modifiers: [customOffsetModifier],
        ...options,
    });
    const clickOutsideRef = useRef();
    clickOutsideRef.current = popperElement;

    useOnClickOutside(
        () => {
            if (blockPageScroll) {
                document.body.style.overflow = '';
            }
            if (onClickOutside) onClickOutside();
        },
        { refs: [clickOutsideRef] }
    );

    useEffect(() => {
        if (target && blockPageScroll) {
            document.body.style.overflow = 'hidden';
        }
    }, [target, blockPageScroll]);

    return ReactDOM.createPortal(
        <div ref={setPopperElement} style={{ ...styles.popper, width: width }} className={`${className}`} {...attributes.popper}>
            {children}
        </div>,
        document.body
    );
};

export default InfinitePopper;
