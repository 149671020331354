import React, { forwardRef, useCallback, useEffect, useImperativeHandle, useState } from 'react';
import { IStatusPanelParams } from 'ag-grid-community';
import './total-row-bar.scss';
const debounce = require('lodash.debounce');

const TotalRowBar = forwardRef((props: IStatusPanelParams, ref) => {
    const [visible, setVisible] = useState(true);
    const [count, setCount] = useState(props.api.getDisplayedRowCount());
    const { renderTotalRow } = props.context || {};

    useImperativeHandle(ref, () => {
        return {
            setVisible: (visible: boolean) => {
                setVisible(visible);
            },
            isVisible: () => {
                return visible;
            },
        };
    });

    const handleRowDataChanged = useCallback(
        debounce(() => {
            const isLastRowIndexKnown = props.api?.getModel()?.isLastRowIndexKnown();
            setCount(
                isLastRowIndexKnown === undefined || isLastRowIndexKnown
                    ? props.api.getDisplayedRowCount()
                    : props.api.getDisplayedRowCount() - 1
            );
        }, 200),
        []
    );

    useEffect(() => {
        props.api.addEventListener('modelUpdated', handleRowDataChanged);

        return () => {
            props.api.removeEventListener('modelUpdated', handleRowDataChanged);
        };
    }, [props.api, handleRowDataChanged]);

    if (visible) {
        return <div className={`total-row-bar`}>{renderTotalRow ? renderTotalRow(count) : count + ' results'}</div>;
    }

    return null;
});

export default TotalRowBar;
