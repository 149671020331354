import React from 'react';
import { LabelProps } from './types';

const Label: React.FC<LabelProps> = ({ text, onClick, iconClass, className }) => {
    return (
        <div onClick={onClick} className={`label ${className ? className : ''}`}>
            {iconClass && <span className={iconClass}></span>}
            <span>{text}</span>
        </div>
    );
};

export default Label;
