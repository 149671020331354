import FieldSet from 'common/components/field-set/field-set';
import PaginationAutocomplete from 'common/components/field/pagination-autocomplete/pagination-autocomplete';
import InfiniteSelect from 'common/components/infinite-select/infinite-select';
import { OptionField } from 'common/constants/option-fields';
import { ConverterHelper } from 'common/helpers/converter-helper';
import DropdownOption from 'common/models/dropdown-option';
import { IOption } from 'common/models/types';
import BaseService from 'common/services/api/base/base-service';
import DepartmentService from 'common/services/api/department/department-service';
import { PayTypes } from 'models/enums/pay-types';
import React from 'react';
import { CorporateName } from './component/corporate-name';
import TherapistRateFormBase from './therapist-rate-form-base';
import { DIFFERENT_CORPORATE_NAME, ITherapistRateFormCreateProps } from './types';

// TO-DO: Set tab indexes on the form
const TherapistRateFormCreate = ({
    data,
    errors,
    className = 'therapist-rate-create-form',
    onChange,
    isUpdate,
}: ITherapistRateFormCreateProps) => {
    const { corporateName, taxId, corporateNameSelector } = data;
    const payTypeOptions = Object.values(PayTypes).map((x) => new DropdownOption(x.value, x.label));
    const isPay1099 = data.payType === PayTypes.Pay1099.value;

    const getSubDepartmentOptions = (term: string, skip: number, take: number, fieldId: string): Promise<IOption[]> => {
        return BaseService.getOptions(term, skip, take, fieldId, [data?.department?.value]);
    };

    let corporateNameProps = {};
    if (isPay1099 && corporateNameSelector?.length > 0) {
        corporateNameProps = {
            corporateNameValue: `${corporateName} / ${taxId}`,
            corporateNameOptions: [
                new DropdownOption(corporateName, `${corporateName} / ${taxId}`),
                new DropdownOption(DIFFERENT_CORPORATE_NAME, 'For a different Corporate Name, contact Admin'),
            ],
            readonly: isUpdate,
            selector: isUpdate ? false : true,
        };
    }

    return (
        <div className={`edit-form ${className}`}>
            {!isUpdate && (
                <>
                    <FieldSet errors={errors} name="departmentId" customClass="therapist-rate-field required-field-star">
                        <PaginationAutocomplete
                            cleanable={false}
                            fetchData={DepartmentService.getDepartmentOptions}
                            convertFunction={ConverterHelper.departmentConvert}
                            id="departmentSelect"
                            placeholder="Department"
                            name="department"
                            tabIndex=""
                            onDelete={() => {
                                onChange({ name: 'department', value: undefined });
                                onChange({ name: 'subDepartment', value: undefined });
                            }}
                            onSelect={(item) => {
                                onChange({ name: 'department', value: item });
                                onChange({ name: 'subDepartment', value: undefined });
                            }}
                        />
                    </FieldSet>

                    {data?.department?.hasSubDepartments && (
                        <FieldSet errors={errors} name="subDepartmentIds" customClass="therapist-rate-field required-field-star">
                            <PaginationAutocomplete
                                cleanable={false}
                                multiselect
                                fetchData={getSubDepartmentOptions}
                                convertFunction={ConverterHelper.optionConvert}
                                fetchTrigger={data.department}
                                id="subDepartmentId"
                                defaultValue={data.subDepartment || []}
                                placeholder="Sub Department"
                                name={OptionField.subDepartment}
                                tabIndex=""
                                onDelete={() => onChange({ name: 'subDepartment', value: undefined })}
                                onSelect={(item) => {
                                    onChange({ name: 'subDepartment', value: item });
                                }}
                            />
                        </FieldSet>
                    )}
                </>
            )}

            <FieldSet name="payType" errors={errors} customClass="therapist-rate-field required-field-star">
                <InfiniteSelect
                    id="payTypeSelect"
                    items={payTypeOptions}
                    label="Pay Type"
                    value={payTypeOptions.find((item) => data.payType === item.value)}
                    onChange={(item) => onChange({ name: 'payType', value: item.value })}
                ></InfiniteSelect>
            </FieldSet>

            {isPay1099 && (
                <CorporateName
                    data={data}
                    onChange={onChange}
                    errors={errors}
                    isUpdate={isUpdate}
                    selector={false}
                    {...corporateNameProps}
                />
            )}

            <TherapistRateFormBase data={data} onChange={onChange} errors={errors} />
        </div>
    );
};

export default TherapistRateFormCreate;
