import Button from 'common/components/Button/button';
import ShowOnMapButton from 'common/components/header/show-on-map-button/show-on-map-button';
import React from 'react';
import { DisplayMode, ToggleViewModeProps } from './types';

import './_styles.scss';

const ToggleViewMode: React.FC<ToggleViewModeProps> = ({ activeMode, onListClick, onMapClick }) => {
    return (
        <div className="group-toggle-mode">
            <Button
                className={'base-text-btn white toggle-button'}
                id="toogle-mode-list"
                disabled={activeMode === DisplayMode.List}
                onClick={onListClick}
            >
                <span className="icon icon-list"></span>
                List
            </Button>
            <ShowOnMapButton disabled={activeMode === DisplayMode.Map} onClick={onMapClick} />
        </div>
    );
};

export default React.memo(ToggleViewMode);
