import { AssignedTherapistListItem, ITherapistRequestStatusForm } from 'common/services/api/requests/types';
import { SetStateAction } from 'react';
import { ICreateNoteRequest } from 'common/models/create-note-request';
import { IDiscipline } from 'common/services/api/disciplince/types';
import { IServerSideGetRowsParams } from 'ag-grid-community';

export interface IAssignedTherapistListProps {
    requestId?: number;
    therapists?: AssignedTherapistListItem[];
    setRequestDetails?: SetStateAction<any>;
    setStatusToTherapistRequest?: (therapistId: number, status: TherapistRequestStatus) => void;
    deleteTherapistFromRequest?: (therapistId: number) => void;
    onSaveNote?: (val: any, callBack: any, item: any) => void;
    isDisabled?: boolean;
}

export enum TherapistRequestAction {
    SetAsOffered = 'Set as Offered',
    SetAsAssigned = 'Set as Assigned',
    SetAsDeclined = 'Set as Declined',
    SetAsPendingInterview = 'Set as Pending Interview',
    AddANote = 'Add a note',
    TherapistDetailsPage = 'Provider details page',
    Remove = 'Remove',
}

export const TherapistRequestActions = [
    TherapistRequestAction.SetAsOffered,
    TherapistRequestAction.SetAsAssigned,
    TherapistRequestAction.SetAsDeclined,
    TherapistRequestAction.AddANote,
    TherapistRequestAction.TherapistDetailsPage,
];

export const TherapistSchoolRequestActions = [
    TherapistRequestAction.SetAsOffered,
    TherapistRequestAction.SetAsAssigned,
    TherapistRequestAction.SetAsDeclined,
    TherapistRequestAction.SetAsPendingInterview,
    TherapistRequestAction.AddANote,
    TherapistRequestAction.TherapistDetailsPage,
];

export const TherapistAssignedActions = [
    TherapistRequestAction.SetAsOffered,
    TherapistRequestAction.SetAsDeclined,
    TherapistRequestAction.AddANote,
    TherapistRequestAction.TherapistDetailsPage,
];

export const TherapistRequestClosedStatusActions = [TherapistRequestAction.TherapistDetailsPage];

// TODO: Refactor enum to use int value instead of string
export enum TherapistRequestStatus {
    Added = 'Added',
    Offered = 'Offered',
    NotRelevant = 'NotRelevant',
    Declined = 'Declined',
    Assigned = 'Assigned',
    PendingInterview = 'PendingInterview',
}

//----------------
export type AssignedTherapistTableProps = {
    requestId: number;
    onCellClick?(rowDate: any): void;
    isDisabled?: boolean;
    hideDoteMenu: boolean;
    maxAssignedTherapists?: number;
    customRequestActions?: TherapistRequestAction[];
    onFetchData(event: IServerSideGetRowsParams, props: any): Promise<any>;
    onChangeStatus: (
        requestId: number,
        therapistRequestId: number,
        statusForm: ITherapistRequestStatusForm,
        therapistId: number
    ) => Promise<void>;
    onSaveNote: (requestId: number, therapistRequestId: number, statusForm: ICreateNoteRequest, therapistId?: number) => Promise<void>;
};

export interface IRequestTherapistAssignment {
    id: number;
    therapistId: number;
    firstName: string;
    lastName: string;
    fullName: string;
    status: string;
    disciplines: IDiscipline;
    rateLevel: number;
    updatedAt: Date;
}
