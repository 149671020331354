import Tab from 'common/components/tabs/tab';
import Tabs from 'common/components/tabs/tabs';
import React from 'react';
import { PatientGeneralInfoTabProp } from '../types';
import PatientBasicInfoTab from './basic-info-tab/patient-basic-info-tab';
import PatientContactListTab from './contacts-tab/patient-contacts-list-tab';
import NoteTab from './note-tab/note-tab';
import { useIsUserType } from 'common/hooks/use-is-user-type';
import { PatientEnrollmentHistory } from '../../patient-enrollment-history/patient-enrollment-history';

const PatientGeneralInfoTab: React.FC<PatientGeneralInfoTabProp> = ({ patientId, patientDetails }) => {
    const { isExternal } = useIsUserType();

    return (
        <>
            <Tabs orientation="vertical" parentTabUrl="general-info">
                <Tab id="basicInfo" linkClassName={'custom-link'} text="Basic Info">
                    <PatientBasicInfoTab patientId={patientId} patientDetails={patientDetails} />
                </Tab>
                <Tab id="contacts" linkClassName={'custom-link'} text="Contacts">
                    <PatientContactListTab patientId={patientId} />
                </Tab>
                {!isExternal && (
                    <Tab id="notes" linkClassName={'custom-link'} text="Notes">
                        <NoteTab patientId={patientId} />
                    </Tab>
                )}
                <Tab id="enrollment-history" linkClassName={'custom-link'} text="Enrollment History">
                    <PatientEnrollmentHistory extendGridOptions={{ rowData: patientDetails?.enrollments }} />
                </Tab>
            </Tabs>
        </>
    );
};

export default PatientGeneralInfoTab;
