import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import RequestTherapistDocumentDialog from './request-therapist-document-dialog';
import UploadTherapistDocumentDialog from './upload-therapist-document-dialog';
import UploadAdditionalTherapistDocumentDialog from './upload-additional-therapist-document-dialog';
import OverrideDocumentDialog from './override-document-dialog';
import RejectDialog from '../../../common/components/reject-dialog/reject-dialog';
import therapistDocumentService from 'common/services/api/therapist-document/therapist-document-service';
import { trackPromise } from 'react-promise-tracker';
import { ReasonType } from 'common/services/api/reason/types';
import TherapistDocumentTable from './therapist-documents-table';
import { TherapistDocumentsTabProps, UploadDocumentOptions } from './type';
import { ITherapistDocumentModel } from 'common/services/api/therapist-document/types';
import { getDocumentsRequest, getTherapistDetailsRequest } from 'app/store/therapist/action-creators';
import { Action } from '@reduxjs/toolkit';
import { ThunkDispatch } from 'redux-thunk';

const TherapistDocumentsTab: React.FC<TherapistDocumentsTabProps> = ({ therapist }) => {
    const dataFromApi = useSelector((state: any) => state.therapistDetails.documentsTab.data);
    const therapistId = useSelector((state: any) => state.therapistDetails.therapist.id);

    const dispatch = useDispatch<ThunkDispatch<any, any, Action>>();

    const [uploadDocumentDialogOptions, setUploadDocumentOptions] = useState<UploadDocumentOptions>({
        isOpen: false,
        isEdit: false,
        isHistory: false,
        isOverride: false,
        document: undefined,
    });

    const [uploadAdditionalDocumentDialogOptions, setUploadAdditionalDocumentOptions] = useState({ isOpen: false });

    const [rejectDocumentOptions, setRejectDocumentOptions] = useState({ isOpen: false, document: null });

    const [isRequestDialogOpen, setIsRequestDialogOpen] = useState(false);

    const handleClickOnAddRule = () => {
        setIsRequestDialogOpen(true);
    };

    const handleClickOnAddDocument = () => {
        setUploadAdditionalDocumentOptions({ isOpen: true });
    };

    const handleCloseUploadAdditionalDocumentDialog = () => {
        setUploadAdditionalDocumentOptions({ isOpen: false });
    };

    const handleCloseRequestDialog = () => {
        setIsRequestDialogOpen(false);
    };

    const handleCloseUploadDialog = () => {
        setUploadDocumentOptions({ isOpen: false, isEdit: false, isHistory: false, isOverride: false, document: undefined });
    };

    const handleClickOnDocument = (document: ITherapistDocumentModel) => {
        setUploadDocumentOptions({ isOpen: true, document: document });
    };

    const handleClickOnEditDocument = (document: ITherapistDocumentModel) => {
        setUploadDocumentOptions({ isOpen: true, isEdit: true, document: document });
    };

    const handleClickOnEditHistory = (document: ITherapistDocumentModel) => {
        setUploadDocumentOptions({ isOpen: true, isEdit: true, isHistory: true, document: document });
    };

    const handleClickOnInitialUpload = (document: ITherapistDocumentModel) => {
        setUploadDocumentOptions({ isOpen: true, document: document });
    };

    const handleClickOnOverrideDocument = (document: any) => {
        setUploadDocumentOptions({ isOverride: true, document: document });
    };

    const handleClickOnApproveDocument = (therapistDocumentId: number) => {
        trackPromise(therapistDocumentService.approveTherapistDocument(therapistId, therapistDocumentId)).then(() => {
            dispatch(getDocumentsRequest(therapistId));
        });
    };

    const handleClickOnApproveDocuments = (therapistDocumentIds: number[]) => {
        trackPromise(therapistDocumentService.approveTherapistDocuments(therapistId, therapistDocumentIds)).then(() => {
            dispatch(getDocumentsRequest(therapistId));
        });
    };

    const handleClickOnRejectDocument = (document: ITherapistDocumentModel) => {
        setRejectDocumentOptions({ isOpen: true, document: document });
    };

    const handleClickOnCancelRejectDocument = () => {
        setRejectDocumentOptions({ isOpen: false, document: null });
    };

    const handleSaveRejectDocument = (request: any) => {
        return trackPromise(
            therapistDocumentService.rejectDocument(therapistId, rejectDocumentOptions.document.id, request).then(() => {
                dispatch(getDocumentsRequest(therapistId));
                trackPromise(dispatch(getTherapistDetailsRequest(therapistId)));
                handleClickOnCancelRejectDocument();
            })
        );
    };

    return (
        <div className="therapist-assignemnt">
            <div className="table-page">
                <TherapistDocumentTable
                    onAddButtonClick={handleClickOnAddRule}
                    onAddDocumentClick={handleClickOnAddDocument}
                    onUploadFileClick={handleClickOnDocument}
                    onEditFileClick={handleClickOnEditDocument}
                    onEditFileHistoryClick={handleClickOnEditHistory}
                    onInitialUploadFileClick={handleClickOnInitialUpload}
                    onOverrideDocumentClick={handleClickOnOverrideDocument}
                    onApproveTherapistDocumentClick={handleClickOnApproveDocument}
                    onApproveTherapistDocumentsClick={handleClickOnApproveDocuments}
                    onRejectTherapistDocumentClick={handleClickOnRejectDocument}
                    updateTable={dataFromApi}
                    therapistId={therapistId}
                    therapist={therapist}
                />
            </div>

            {uploadDocumentDialogOptions.isOpen && (
                <UploadTherapistDocumentDialog
                    therapistId={therapistId}
                    isEdit={uploadDocumentDialogOptions.isEdit}
                    isHistory={uploadDocumentDialogOptions.isHistory}
                    onCancel={handleCloseUploadDialog}
                    document={uploadDocumentDialogOptions.document}
                />
            )}

            {uploadDocumentDialogOptions.isOverride && (
                <OverrideDocumentDialog
                    therapistId={therapistId}
                    onCancel={handleCloseUploadDialog}
                    document={uploadDocumentDialogOptions.document}
                />
            )}

            {isRequestDialogOpen && <RequestTherapistDocumentDialog onCancel={handleCloseRequestDialog} therapistId={therapistId} />}

            {rejectDocumentOptions.isOpen && (
                <RejectDialog
                    title={`Reject ${rejectDocumentOptions.document.name}`}
                    reasonType={ReasonType.RejectDocument}
                    onCancel={handleClickOnCancelRejectDocument}
                    onSave={handleSaveRejectDocument}
                    required={['reasonId', 'comment']}
                ></RejectDialog>
            )}
            {uploadAdditionalDocumentDialogOptions.isOpen && (
                <UploadAdditionalTherapistDocumentDialog
                    therapistId={therapistId}
                    onCancel={handleCloseUploadAdditionalDocumentDialog}
                ></UploadAdditionalTherapistDocumentDialog>
            )}
        </div>
    );
};

export default TherapistDocumentsTab;
