import React, { useState } from 'react';
import EntitiesList from './components/entities-list/entities-list';
import CreateEntityForm from './components/entities-forms/entity-create-form/create-entity-form';
import EntityService from 'common/services/api/entity/entity-service';
import { IEntityBasicInfo, IEntityDetailsBasicInfo } from 'common/services/api/entity/types';
import { ConverterHelper } from 'common/helpers/converter-helper';
import { ITablePageSetting } from 'common/components/ag-table/types';

const EntitiesPage = () => {
    const [entitiesPageSettings, setEntitiesPageSettings] = useState<ITablePageSetting>({
        isCreateDialogOpen: false,
        updateTable: false,
    });

    const saveEntity = (entity: IEntityDetailsBasicInfo) => {
        const request: IEntityBasicInfo = ConverterHelper.entityDetailsBasicInfoToEntityBasicInfo(entity);

        return EntityService.createEntity(request).then(() => {
            setEntitiesPageSettings({ isCreateDialogOpen: false, updateTable: true });
        });
    };

    return (
        <div className="table-page">
            <header>
                <div className="header-wrapper">
                    <div className="title-wrapper">
                        <h1>Entities</h1>
                    </div>
                    <button
                        onClick={() => setEntitiesPageSettings({ isCreateDialogOpen: true, updateTable: false })}
                        className="create-therapist-btn base-text-btn blue"
                        id="createEntityBtn"
                    >
                        New Entity
                    </button>
                </div>
            </header>
            <EntitiesList updateTable={entitiesPageSettings.updateTable} />
            {entitiesPageSettings.isCreateDialogOpen && (
                <CreateEntityForm
                    onClose={() => setEntitiesPageSettings({ isCreateDialogOpen: false, updateTable: false })}
                    onSave={saveEntity}
                />
            )}
        </div>
    );
};

export default EntitiesPage;
