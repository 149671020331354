import React from 'react';
import './fake-tabs.scss';
import { FakeTabsProps } from './types';
import DropdownOption from 'common/models/dropdown-option';

export const FakeTabs = ({ className, value, onChange, id, items, children }: FakeTabsProps) => {
    const handleOnClick = (item: DropdownOption) => {
        if (item.value !== value?.value) {
            onChange(item);
        }
    };

    return (
        <div id={id} className={`fake-tabs ${className}`}>
            {items.map((item) => {
                return (
                    <div
                        onClick={() => handleOnClick(item)}
                        className={`fake-tabs__item ${item.value === value?.value ? 'fake-tabs--active' : ''}`}
                    >
                        {item.label}
                    </div>
                );
            })}
            <div className="fake-tabs__actions">{children}</div>
        </div>
    );
};
