import { GridApi, GridOptions, GridReadyEvent, ICellRendererParams, IServerSideGetRowsParams } from 'ag-grid-community';
import { useSearchInput } from 'common/hooks/use-serch-input';
import { INote } from 'common/models/note';
import { getTableData } from 'common/services/api/tabel-fetch-service';
import { ICurrentSearchState } from 'features/therapist-details-page/communication-tab/types';
import React from 'react';
import { useEffect } from 'react';
import { useMemo } from 'react';
import { useRef } from 'react';
import { useState } from 'react';
import AgTable from '../ag-table/ag-table';
import { IParamsWatcher } from '../ag-table/types';
import AddButton from '../header/add-button/add-button';
import HeaderLeftSide from '../header/header-left-side';
import HeaderRightSide from '../header/header-right-side';
import HeaderWrapper from '../header/header-wrapper';
import SearchFilterInput from '../header/search-filter-input/search-filter-input';
import NoteCell from './note-cell';
import { ITherapistNoteFilter, NoteListProps } from './types';

import './_style.scss';

const defaultFilter: ITherapistNoteFilter = {
    search: null,
};

const NoteList: React.FC<NoteListProps> = ({
    url,
    title = '',
    forceUpdate,
    searchData,
    onNewClick,
    onCompleteNoteClick,
    onNoteClick,
    onSearchTermChange,
    absoluteShadow = false,
    viewOnly,
}) => {
    const ref = useRef<ICurrentSearchState<INote>>();
    const noteUrl = React.useRef(url);
    const { search, handleSearchInputChange } = useSearchInput(searchData?.term || '', (value: string) => {
        if (onSearchTermChange) {
            onSearchTermChange(value, paramsWatcher.current.limit);
        } else {
            setFilters((prev) => ({ ...prev, search: value }));
        }
    });

    const [gridApi, setGridApi] = useState<GridApi>(null);
    const [filters, setFilters] = useState<ITherapistNoteFilter>(defaultFilter);
    const paramsWatcher = useRef<IParamsWatcher>();

    useEffect(() => {
        noteUrl.current = url;
    }, [url]);

    useEffect(() => {
        ref.current = searchData;
    }, [searchData]);

    useEffect(() => {
        if (gridApi || forceUpdate) {
            gridApi.onFilterChanged();
        }
    }, [gridApi, forceUpdate, searchData]);

    const handleFetchData = (event: IServerSideGetRowsParams, props: any) => {
        if (ref && ref.current?.term) {
            return Promise.resolve({ data: ref.current.result.data });
        }

        return getTableData(noteUrl.current)(event, props);
    };

    const gridOptions: GridOptions = useMemo(
        () => ({
            rowHeight: 102,
            columnDefs: [
                {
                    field: 'note',
                    cellRenderer: 'noteRenderer',
                    flex: 1,
                },
            ],
            frameworkComponents: {
                noteRenderer: (props: ICellRendererParams) => {
                    const note: INote = props.data;

                    return (
                        <NoteCell
                            onNoteClick={(note: INote) => onNoteClick && onNoteClick(note)}
                            note={note}
                            onCompleteNoteClick={onCompleteNoteClick}
                        />
                    );
                },
            },
        }),
        [onCompleteNoteClick, onNoteClick]
    );

    const handleGridReady = (params: GridReadyEvent) => {
        setGridApi(params.api);
    };

    const handleClickOnClearButton = () => {
        handleSearchInputChange('');
    };

    return (
        <>
            <div className="main-info-wrapper ag-table-wrapper notes-list">
                <HeaderWrapper>
                    <HeaderLeftSide>
                        <SearchFilterInput
                            onClearClick={handleClickOnClearButton}
                            title={title}
                            value={search}
                            onInput={handleSearchInputChange}
                        />
                    </HeaderLeftSide>
                    <HeaderRightSide>
                        {!viewOnly && onNewClick && <AddButton title="Add Note" onClick={onNewClick}></AddButton>}
                    </HeaderRightSide>
                </HeaderWrapper>
                <AgTable
                    absoluteShadow={absoluteShadow}
                    onGridReady={handleGridReady}
                    gridOptions={gridOptions}
                    customFilters={filters}
                    paramsWatcher={paramsWatcher}
                    onFetchData={handleFetchData}
                />
            </div>
        </>
    );
};

export default NoteList;
