import { authReducer } from '../features/authentication/auth.reducer';
import { combineReducers } from 'redux';
import { departmentReducer } from './store/department/reducer';
import { disciplineReducer } from './store/disciplince/reducer';
import { referralSourceReducer } from './store/referral-source/reducer';
import { populationReducer } from './store/population/reducer';
import { staffingAgencyReducer } from './store/staffing-agency/reducer';
import { stateReducer } from './store/state/reducer';
import { zipCodeReducer } from './store/zip-code/reducer';
import { languageReducer } from './store/language/reducer';
import { countyReducer } from './store/county/reducer';
import { siteSettingsReducer } from './store/settings/reducer';
import { documentReducer } from './store/document/reducer';
import { documentRuleReducer } from './store/document-rule/reducer';
import { contactRoleReducer } from './store/contact-role/reducer';
import { patientsReducer } from './store/patients/reducer';
import { patientDetailsReducer } from './store/patients-details/reducer';
import { noteReducer } from './store/notes/reducer';
import { versionModalReducer } from './store/version-modal/reducer';
import { therapistDetailsReducer } from './store/therapist/reducer';

const rootReducer = combineReducers({
    auth: authReducer,
    therapistDetails: therapistDetailsReducer,
    siteSettings: siteSettingsReducer,
    document: documentReducer,
    documentRule: documentRuleReducer,
    department: departmentReducer,
    patients: patientsReducer,
    patientDetails: patientDetailsReducer,
    discipline: disciplineReducer,
    population: populationReducer,
    referralSource: referralSourceReducer,
    staffingAgency: staffingAgencyReducer,
    state: stateReducer,
    zipCode: zipCodeReducer,
    language: languageReducer,
    county: countyReducer,
    contactRole: contactRoleReducer,
    note: noteReducer,
    versionModal: versionModalReducer,
});

export default rootReducer;
