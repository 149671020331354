import { CellClickedEvent } from 'ag-grid-community';
import AgTable from 'common/components/ag-table/ag-table';
import { IParamsWatcher } from 'common/components/ag-table/types';
import { getTableData } from 'common/services/api/tabel-fetch-service';
import { NavigationRoutes } from 'models/routes/navigation-routes';
import React, { useMemo, useRef, useState } from 'react';
import { DisplayValueHelper } from 'common/helpers/display-value-helper';
import { FACILITY } from 'common/constants/common';

export type NursingHomeCustomRatesProps = {
    entityId: number;
};

const NursingHomeCustomRatesTable = ({ entityId }: NursingHomeCustomRatesProps) => {
    const [isCustomRateOpen, setCustomRateOpen] = useState(false);

    // Fetches
    const handleFetchData = getTableData(`nursing-home/requests/${entityId}/rates`);

    const [filters] = useState({ customRateOnly: true });

    const paramsWatcher = useRef<IParamsWatcher>();

    const gridOptions = useMemo(
        () => ({
            onCellClicked: (event: CellClickedEvent) => {
                if (event.data.id) {
                    switch (event.column.getColId()) {
                        case 'id': {
                            window.open(NavigationRoutes.nursinghomerequestDetailsRoute(event.data.id), '_blank').focus();
                            break;
                        }
                    }
                }
            },
            columnDefs: [
                {
                    field: 'therapyType',
                    headerName: 'Discipline',
                    filter: 'agCustomFilter',
                    width: 125,
                },
                {
                    field: 'id',
                    headerName: 'Request ID',
                    cellRenderer: 'objectLinkValueRender',
                    width: 125,
                },
                {
                    field: FACILITY,
                    headerName: 'Facility',
                    filter: 'agCustomFilter',
                    width: 250,
                },
                {
                    field: 'rate',
                    headerName: 'Rate',
                    width: 100,
                    valueFormatter: (props: any) => DisplayValueHelper.rateEmptyOrIsSet(props.value, ''),
                },
            ],
            defaultColDef: {},
            frameworkComponents: {
                objectLinkValueRender: (props: any) => {
                    const cellValue = (props.valueFormatted ? props.valueFormatted : props.value) || '';

                    return <span className="object-link">{cellValue}</span>;
                },
            },
        }),
        []
    );

    return (
        <>
            <div className="main-info-wrapper ag-table-wrapper rate-table">
                <div className="custom-rates-table" onClick={() => setCustomRateOpen(!isCustomRateOpen)}>
                    <i className={`icon icon-dropdown ${isCustomRateOpen ? 'dropdown-icon-to-top' : ''}`}></i> Custom Rates
                </div>
                {isCustomRateOpen && (
                    <AgTable
                        gridOptions={gridOptions}
                        // repository={tableRepository}
                        // onDataChange={updateCount}
                        customFilters={filters}
                        paramsWatcher={paramsWatcher}
                        onFetchData={handleFetchData}
                    />
                )}
            </div>
        </>
    );
};

export default NursingHomeCustomRatesTable;
