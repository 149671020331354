import React from 'react';
import './badge-top-filter.scss';
import { IBadgeTopFilter } from '../../types';

type BadgeTopFilterComponentProps = {
    badgeValue: IBadgeTopFilter;
    onClick: (value: IBadgeTopFilter) => {};
    active: boolean;
};

const BadgeTopFilter = ({ badgeValue, active, onClick }: BadgeTopFilterComponentProps) => {
    const { title, body } = badgeValue;

    return (
        <div className={`badge-top-filter ${active ? 'badge-top-filter--active' : ''}`} onClick={() => onClick(badgeValue)}>
            <div className="badge-top-filter__title">{title}</div>
            <div className="badge-top-filter__body">{body ?? 0}</div>
        </div>
    );
};

export default React.memo(BadgeTopFilter);
