import { Reducer } from '@reduxjs/toolkit';
import { DocumentActionsTypes, IDocumentState, SET_DOCUMENTS } from './types';

const initialState: IDocumentState = {
    data: [],
};

export const documentReducer: Reducer<IDocumentState, DocumentActionsTypes> = (state = initialState, action) => {
    switch (action.type) {
        case SET_DOCUMENTS:
            return { ...state, data: action.data };
        default:
            return state;
    }
};
