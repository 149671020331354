import { InfiniteServerClient } from 'common/helpers/http-clients';
import { IDiscipline } from './types';

export class DisciplineService {
    getAll(): Promise<IDiscipline[]> {
        return InfiniteServerClient.get<IDiscipline[]>('/disciplines').then(
            (response) => {
                return response.data;
            },
            (error) => {
                throw error;
            }
        );
    }

    getAvailableDisciplines(
        departmentKey: string,
        therapyTypeId?: number,
        customerId?: number,
        populationId?: number
    ): Promise<IDiscipline[]> {
        return InfiniteServerClient.get<IDiscipline[]>(`/disciplines/department-keys/${departmentKey}`, {
            params: { therapyTypeId, customerId, populationId },
        }).then(
            (response) => {
                return response.data;
            },
            (error) => {
                throw error;
            }
        );
    }
}

export default new DisciplineService();
