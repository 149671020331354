import FieldSet from 'common/components/field-set/field-set';
import InfiniteSelect from 'common/components/infinite-select/infinite-select';
import DropdownOption from 'common/models/dropdown-option';
import { EntityStatues } from 'models/enums/Entity-statuses';
import React from 'react';

type EntitiesFilterProps = {
    filters: EntitiesCustomFilters;
    onFiltersChange: (filter: EntitiesCustomFilters) => void;
};

type EntitiesCustomFilters = {
    status: string | null;
};

export const EntitiesCustomFilter = ({ filters, onFiltersChange }: EntitiesFilterProps) => {
    const statusesOptions = [
        new DropdownOption(null, '---'),
        ...Object.values(EntityStatues).map((x: any) => new DropdownOption(x.value, x.label)),
    ];

    const handleFieldChange = (data: any) => {
        onFiltersChange({ ...filters, [data.name]: data.value });
    };

    return (
        <>
            <FieldSet name="status">
                <InfiniteSelect
                    id="status"
                    items={statusesOptions}
                    label="Status"
                    value={statusesOptions.find((item) => filters.status === item.value)}
                    onChange={(item: DropdownOption) => handleFieldChange({ name: 'status', value: item.value })}
                />
            </FieldSet>
        </>
    );
};
