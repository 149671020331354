import React, { useRef } from 'react';
import './school-page.scss';
import SchoolList from './components/school-list';
import { ITableWrapperReady } from 'common/constants/types';

const SchoolPage = () => {
    const handleClickAddPhysician = useRef((_value: boolean) => {});

    const handleOnGridReady = ({ extraParams }: ITableWrapperReady) => {
        handleClickAddPhysician.current = extraParams?.setCreateDialogOpen;
    };

    return (
        <div className="school-page table-page">
            <header>
                <div className="header-wrapper">
                    <div className="title-wrapper">
                        <h1>School Requests</h1>
                    </div>
                    <button
                        className="create-therapist-btn base-text-btn blue"
                        id="createNewRequestBtn"
                        onClick={() => handleClickAddPhysician?.current?.(true)}
                    >
                        New Request
                    </button>
                </div>
            </header>
            <SchoolList onGridReady={handleOnGridReady} />
        </div>
    );
};

export default SchoolPage;
