import Button from 'common/components/Button/button';
import FieldSet from 'common/components/field-set/field-set';
import InputField from 'common/components/field/input-field';
import { FieldValue } from 'common/models/field-value';
import React, { useState } from 'react';
import { ForgotPasswordFormProps } from './types';
import { useHistory } from 'react-router-dom';
import { IForgotPasswordRequest } from 'common/services/api/user/types';

const ForgotPasswordForm: React.FC<ForgotPasswordFormProps> = ({ onSubmit }) => {
    const [model, setUpdatePasswordModel] = useState<IForgotPasswordRequest>();
    const [errorData, setErrorData] = useState({});
    const history = useHistory();
    const handleFieldChange = (data: FieldValue) => {
        setUpdatePasswordModel((prev) => ({ ...(prev as IForgotPasswordRequest), [data.name]: data.value }));
        setErrorData((prevError) => ({ ...prevError, [data.name]: undefined }));
    };

    const handleClickOnSubmit = () => {
        onSubmit(model)
            .then(() => {
                setEmailSent(true);
            })
            .catch((error) => {
                const status = error?.response?.status;

                switch (status) {
                    case 400:
                        setErrorData(error.response.data);
                        break;
                    case 404:
                        setErrorData((prevError) => ({ ...prevError, email: ["This email address doesn't exist"] }));
                        break;
                    default:
                        break;
                }
            });
    };

    const isDisabled = !(model?.email?.length > 0);
    const [isEmailSent, setEmailSent] = useState(false);

    return (
        <>
            <div className="edit-form login-form forgot-password">
                <Button id="setupPasswordBtn" className="base-text-btn white medium back-button" onClick={() => history.push('/login')}>
                    <span className="icon icon-back"></span> Back to Login
                </Button>
                <span className="infinite-logo"></span>
                <h4>Forgot my Password</h4>

                {!isEmailSent && (
                    <>
                        <h6>Please enter your main email address</h6>
                        <FieldSet name="email" errors={errorData} customClass="edit-field required login-input-wrapper">
                            <InputField
                                type="text"
                                placeholder="Email Address"
                                name="email"
                                onChange={handleFieldChange}
                                value={model?.email || ''}
                            />
                        </FieldSet>
                        <Button
                            id="setupPasswordBtn"
                            disabled={isDisabled}
                            className="login-btn"
                            onClick={handleClickOnSubmit}
                            color={isDisabled ? 'gray' : 'blue'}
                        >
                            Send Reset Link
                        </Button>
                    </>
                )}

                {isEmailSent && (
                    <>
                        <span className="icon icon-check sent-email__check"></span>
                        <div className="emails-sent">Email Sent</div>
                        <div className="follow-inbox">Please follow your inbox</div>
                    </>
                )}
            </div>
        </>
    );
};

export default React.memo(ForgotPasswordForm);
