import DropdownOption from 'common/models/dropdown-option';
import { IFormItem } from 'common/models/types';
import { IEntity, IEntityDetailsBasicInfo, IFunctionLink } from 'common/services/api/entity/types';
import { IState } from 'common/services/api/state/types';
import { ITabIndex } from 'common/hooks/types';

export interface IEntityBasicInfoFormProps extends ITabIndex {
    entityBasicInfo?: IEntityDetailsBasicInfo;
    errors?: any;
    options: EntityBasicInfoOptions;
    selectedState?: IState;
    onChange: (data: IFormItem) => void;
}

export type EntityBasicInfoOptions = {
    id?: number;
    counties: DropdownOption[];
    functionLinks?: IFunctionLink[];
    parent?: IEntity;
};

export class FunctionDropdownOption extends DropdownOption {
    key: string;
    isNumberOfBedsRequired: boolean;

    constructor(
        value: number,
        label: string,
        isNumberOfBedsRequired: boolean,
        key: string = undefined,
        className: string = undefined,
        id: any = undefined,
        zipCode: any = undefined
    ) {
        super(value, label, className, id, zipCode);

        this.key = key;
        this.isNumberOfBedsRequired = isNumberOfBedsRequired;
    }
}
