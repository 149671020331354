import { dateTimeConstants, MAX_NOTIFICATION_MESSAGE_LENGTH } from 'common/constants/common';
import { DateTimeHelper } from 'common/helpers/date-time-helper';
import React, { useCallback } from 'react';
import { INotificationItemProps } from './types';

const NotificationItem: React.FC<INotificationItemProps> = ({ note, key, onClick }) => {
    const formatName = useCallback(() => {
        if (note.entity) {
            return `${note.createdBy?.fullName || ''}/${note.entity.name}`.replaceAll('/', ' / ');
        }

        return note.createdBy?.fullName || '';
    }, [note]);

    const renderDate = useCallback(() => {
        const date = note.snoozedReminderDate ?? note.reminderDate;
        const isToday = DateTimeHelper.isToday(date);

        if (isToday) {
            return (
                <div className="date today">{`${DateTimeHelper.formatToLocalTime(note.reminderDate, dateTimeConstants.H_A)} TODAY`}</div>
            );
        }

        return <div className={'date'}>{DateTimeHelper.formatToLocalTime(note.reminderDate, dateTimeConstants.H_A_MMM_D)}</div>;
    }, [note]);

    const renderNoteText = useCallback(() => {
        if (note.note.length < MAX_NOTIFICATION_MESSAGE_LENGTH) {
            return <div className="text">{note.note}</div>;
        }

        return (
            <div className="text">
                {`${note.note.slice(0, MAX_NOTIFICATION_MESSAGE_LENGTH - 1)}... `}
                <span className="show-more">show more</span>
            </div>
        );
    }, [note]);

    return (
        <div className="notification-item" key={key} onClick={() => onClick(note)}>
            <div className="created-by">{formatName()}</div>
            {renderNoteText()}
            {renderDate()}
        </div>
    );
};

export default NotificationItem;
