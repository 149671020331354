import AvailableTherapistTable from 'common/components/availability/availability-table/available-therapist-table';
import { IAvailableTherapistFilter } from 'common/components/availability/availability-table/types';
import PageHeader from 'common/components/page-header/page-header';
import { departmentKey } from 'common/constants/department-key';
import { defaultIconArray } from 'common/constants/map/map';
import { useDepartmentData } from 'common/hooks/use-department-data';
import { DisplayMode } from 'common/components/toggle-view-mode/types';
import React, { useCallback, useEffect, useState } from 'react';
import AvailableTherapistMapDialog from 'common/components/availability/availability-map/available-therapist-map-dialog';
import { IAvailableTherapistMapFilter } from 'common/components/availability/availability-map/types';
import './availability-portal.scss';
import { getWeekOptions } from 'common/components/availability/types';
import { LocalStoreRepository } from 'common/helpers/repository/local-store-repository';
import { TherapistStatus } from 'features/therapist-details-page/shared/constants';

const customFiltersRepository = new LocalStoreRepository(`availability-portal-available-therapist-table-custom-filters`, 'v7');

const AvailabilityPortalPage: React.FC = () => {
    const savedFilter = customFiltersRepository.load();

    const [displayMode, setDisplayMode] = useState(DisplayMode.List);

    const [tableFilter, setTableFilter] = useState<IAvailableTherapistFilter>(
        savedFilter ?? {
            search: null,
            searchZip: null,
            assignmentStatus: 'Active',
            week: getWeekOptions()[0]?.value,
        }
    );

    const departments = useDepartmentData();

    const getDepartments = useCallback(() => {
        return departments.filter((d) => d.key === departmentKey.HCA || d.key === departmentKey.HCB);
    }, [departments]);

    const handleListClick = () => {
        setDisplayMode(DisplayMode.List);
    };

    const handleMapClick = () => {
        setDisplayMode(DisplayMode.Map);
    };

    // Set DEFAULT department filter
    useEffect(() => {
        if (departments?.length > 0 && !tableFilter.department) {
            setTableFilter((prev) => ({ ...prev, department: departments.find((i) => i.key === departmentKey.HCA)?.id }));
        }
    }, [departments, tableFilter.department]);

    const handleFilterChange = useCallback((value: IAvailableTherapistFilter) => {
        setTableFilter(value);
    }, []);

    const handleFilterClear = useCallback(() => {
        const filter: IAvailableTherapistFilter = {
            search: null,
            searchZip: null,
            assignmentStatus: 'Active',
            week: getWeekOptions()[0]?.value,
            department: departments.find((i) => i.key === departmentKey.HCA)?.id,
        };

        setTableFilter(filter);

        return filter;
    }, [departments]);

    useEffect(() => {
        customFiltersRepository.save(tableFilter);
    }, [tableFilter]);

    const renderListType = useCallback(() => {
        return (
            <AvailableTherapistTable
                departments={getDepartments()}
                storePrefix="availability-portal-page"
                onMapClick={handleMapClick}
                extendGridOptions={{ columnDefs: tableDefenition }}
                filter={tableFilter}
                filterConfig={{ department: true, subDepartment: true }}
                onFilterChange={handleFilterChange}
                onFilterClear={handleFilterClear}
                onSearchChange={handleSearchChange}
                onZipChange={handleZipChange}
            />
        );
    }, [getDepartments, tableFilter, handleFilterChange, handleFilterClear]);

    const handleSearchChange = (value: string) => {
        setTableFilter((prev) => ({ ...prev, search: value }));
    };

    const handleZipChange = (value: string) => {
        setTableFilter((prev) => ({ ...prev, searchZip: value }));
    };

    const renderShowOnMapType = useCallback(() => {
        const defaultFilter: IAvailableTherapistMapFilter = {
            languages: [],
            populations: [],
            week: tableFilter.week,
            preferredDepartments: [],
            preferredSubDepartments: [],
            departments: [],
            disciplines: [],
            complianceStatuses: [],
            statuses: [],
            subDepartments: [],
            employmentStatuses: [],
        };

        return (
            <AvailableTherapistMapDialog
                iconArray={defaultIconArray}
                addressDetails={null}
                onSelectedTherapistsChange={() => {}}
                zip={tableFilter.searchZip}
                filter={defaultFilter}
                onListClick={handleListClick}
            />
        );
    }, [tableFilter]);

    const renderContent = useCallback(() => {
        if (departments?.length > 0) {
            return displayMode === DisplayMode.List ? renderListType() : renderShowOnMapType();
        } else {
            return <> </>;
        }
    }, [departments, displayMode, renderListType, renderShowOnMapType]);

    return (
        <div className="availability-portal-page table-page">
            <PageHeader title="Availability Portal" />
            {tableFilter.department && renderContent()}
        </div>
    );
};

export default React.memo(AvailabilityPortalPage);

export const tableDefenition = [
    {
        field: 'firstName',
        headerName: 'First Name',
        width: 180,
    },
    {
        field: 'lastName',
        headerName: 'Last Name',
        width: 180,
    },
    {
        field: 'email',
        headerName: 'Email',
        width: 220,
    },
    {
        field: 'disciplines',
        headerName: 'Discipline',
        width: 200,
        filter: 'agCustomFilter',
        filterParams: {
            isNullable: true,
        },
    },
    {
        field: 'populations',
        headerName: 'Population',
        width: 250,
        sortable: false,
        filter: 'agCustomFilter',
        filterParams: {
            isNullable: true,
        },
    },
    {
        field: 'zipCodes',
        headerName: 'Zip Code',
        width: 200,
        sortable: false,
        filter: 'agCustomFilter',
        filterParams: {
            isNullable: true,
        },
    },
    {
        field: 'counties',
        headerName: 'County',
        width: 250,
        sortable: false,
        filter: 'agCustomFilter',
        filterParams: {
            isNullable: true,
        },
    },
    {
        field: 'scheduled',
        headerName: 'Scheduled',
        width: 150,
    },
    {
        field: 'available',
        headerName: 'Available',
        width: 150,
    },
    {
        field: 'rateLevel',
        headerName: 'Rate Level',
        initialSort: 'asc',
        width: 150,
        cellRenderer: 'rateLavevRender',
        cellClass: 'rateLavev',
    },
    {
        field: 'assignmentStatus',
        headerName: 'Assignment Status',
        cellRenderer: 'isActiveRender',
        width: 200,
        sortable: false,
        cellClass: 'status-active',
    },
    {
        field: 'status',
        headerName: 'Employment Status',
        width: 220,
        sortable: false,
        filter: 'agCustomFilter',
        filterParams: {
            isNullable: false,
            values: () =>
                Object.entries(TherapistStatus)
                    .filter((key) => key[1].value !== 'Unknown')
                    .map((key) => key[1].label),
        },
    },
    {
        field: 'patientCount',
        headerName: 'Patient Count',
        cellRenderer: 'patientsInZipRender',
        width: 153,
    },
    {
        headerName: '',
        colId: 'therapistLink',
        pinned: 'right',
        cellRenderer: 'therapistLink',
        width: 40,
        hideForExport: true,
        cellClass: 'therapistLink',
    },
    {
        field: 'postalCodes',
        hideForExport: true,
        hide: true,
        filter: 'agCustomFilter',
    },
];
