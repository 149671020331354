import { ExportType } from 'common/constants/export-options';
import { IRecruitmentWidgetFilter, ITherapistDashboardItem, IWidgetOptions } from 'common/services/api/therapist-dahsboard/types';
import useWidget from 'features/dashboard-page/hooks/use-widget';
import React, { useContext, useEffect, useMemo, useRef, useState } from 'react';
import DepartmentFilter from '../department-filter/department-filter';
import DisciplineFilter from '../discipline-filter/discipline-filter';
import { FilterSection } from '../filter-section/filter-section';
import { IconButton } from '../icon-button/icon-button';
import { OverdueFilter } from '../overdue-filter/overdue-filter';
import { OverdueFilterOption } from '../overdue-filter/types';
import { WidgetHeader } from '../widget-header/widget-header';
import { RecruitmentWidgetProps } from './types';
import { IParamsWatcher } from 'common/components/ag-table/types';
import { DashboardPageContext } from '../../dashboard-page';
import { useAssignToColumn } from '../../hooks/use-assign-to-column';
import { CellClickedEvent, GridOptions } from 'ag-grid-community';
import { NavigationRoutes } from '../../../../models/routes/navigation-routes';
import { DAYS_COLUMN, DISCIPLINE_COLUMN, PROVIDER_NAME_COLUMN } from '../../constants';
import AgTable from 'common/components/ag-table/ag-table';
import moment from 'moment';

const defaultFilters: IRecruitmentWidgetFilter = {
    pageIndex: 1,
    limit: 5,
    isOverdue: false,
    isSupervisorView: false,
    assignedTo: [],
};

const InterviewScheduled = 'InterviewScheduled';

const RecruitmentWidget: React.FC<RecruitmentWidgetProps> = ({
    status,
    title,
    fetchFunction,
    exportFunction,
    isSupervisorView,
    globalFilters,
    overdueDaysSetting,
    assignedToOptions,
}) => {
    const [filter, totalCount, repository, fetchData, handleFilterChange, handleOverdueChange, handleExport] = useWidget({
        title,
        defaultFilter: defaultFilters,
        fetchFunction,
        exportFunction,
        isSupervisorView,
    });

    useEffect(() => {
        if (globalFilters) {
            handleFilterChange({ ...filter, assignedTo: globalFilters.assignedTo });
        }
    }, [globalFilters]);

    const [widgetIsReady, setWidgetReady] = useState(false);
    const widgetOptionsRef = useRef<IWidgetOptions>();

    const handleDisciplinesChange = (values: number[]) => {
        handleFilterChange({ ...filter, disciplines: values });
    };

    const handleDepartmentChange = (values: number[]) => {
        handleFilterChange({ ...filter, departments: values });
    };

    const handleClickOnExport = () => {
        handleExport(ExportType.XLSX);
    };

    useEffect(() => {
        assignedToOptions(isSupervisorView).then((data) => {
            widgetOptionsRef.current = data;
            setWidgetReady(true);
        });
    }, [status, isSupervisorView, assignedToOptions]);

    const extendedConfig =
        status === InterviewScheduled
            ? [
                  {
                      headerName: 'Date',
                      field: 'interviewDateTime',
                      width: 150,
                      valueFormatter: (props: any) => (props?.value ? moment(props.value).local().format('MM/DD/YYYY') : ''),
                  },
                  {
                      headerName: 'Time',
                      field: 'interviewDateTime',
                      width: 150,
                      sortable: false,
                      valueFormatter: (props: any) => (props?.value ? moment(props.value).local().format('hh:mm A') : ''),
                  },
              ]
            : [];

    //Base table config
    const paramsWatcher = useRef<IParamsWatcher>();

    const { agAPIs } = useContext(DashboardPageContext);

    const hideColumnsDefault: any = {
        assignedTo: isSupervisorView,
    };

    const ASSIGNED_TO_COLUMN = useAssignToColumn(widgetOptionsRef);

    const gridOptions: GridOptions = useMemo(
        () => ({
            onCellClicked: (event: CellClickedEvent) => {
                const therapist: ITherapistDashboardItem = event.data as ITherapistDashboardItem;
                window.open(NavigationRoutes.therapistDetailsRoute(therapist.id), '_blank').focus();
            },
            columnDefs: [PROVIDER_NAME_COLUMN, DISCIPLINE_COLUMN, DAYS_COLUMN, ...extendedConfig, ASSIGNED_TO_COLUMN],
            paginationPageSize: 5,
            cacheBlockSize: 5,
            defaultColDef: { resizable: true },
            rowClassRules: {
                overdue: (params) => (params.data as ITherapistDashboardItem)?.days < overdueDaysSetting && isSupervisorView,
            },
        }),
        [overdueDaysSetting, isSupervisorView, ASSIGNED_TO_COLUMN, extendedConfig]
    );

    return widgetIsReady === false ? (
        <></>
    ) : (
        <>
            <WidgetHeader title={title} totalCount={totalCount}>
                <IconButton icon="icon-export" onClick={handleClickOnExport} />
            </WidgetHeader>
            <FilterSection>
                <OverdueFilter
                    value={filter.isOverdue ? OverdueFilterOption.OVERDUE : OverdueFilterOption.ALL}
                    onChange={handleOverdueChange}
                />
                <DepartmentFilter value={filter.departments} onChange={handleDepartmentChange} />
                <DisciplineFilter value={filter.disciplines} onChange={handleDisciplinesChange} />
            </FilterSection>
            <div className="ag-table-wrapper">
                <AgTable
                    agAPIs={agAPIs.current}
                    repository={repository}
                    gridOptions={gridOptions}
                    customFilters={filter}
                    paramsWatcher={paramsWatcher}
                    onFetchData={fetchData}
                    globalFilterPosition={true}
                    hideColumns={hideColumnsDefault}
                />
            </div>
        </>
    );
};

export default React.memo(RecruitmentWidget);
