import React from 'react';
import Tooltip from 'react-tooltip-lite';
import { TooltipButtonProps } from './types';
import './reopened-button.scss';

const TooltipButton: React.FC<TooltipButtonProps> = ({
    id,
    className = 'white',
    disabled,
    onClick,
    visible = false,
    tooltipText,
    children = 'Reopened',
}) => {
    const tooltipContent = (
        <div className="tooltip-content-status ">
            <span className="tooltip-content-status tooltip-text">{tooltipText}</span>
        </div>
    );

    return (
        <Tooltip
            content={tooltipContent}
            useHover={visible}
            distance={7}
            arrowSize={5}
            direction="down"
            tipContentClassName="tooltip-content-status"
        >
            <button
                onClick={!disabled && onClick}
                className={`tooltip-btn base-text-btn ${disabled ? 'tooltip-btn--disabled' : ''} ${className}`}
                id={id}
            >
                {children}
                {!visible && tooltipText && disabled && (
                    <Tooltip content={tooltipContent} direction="down" distance={7} arrowSize={5} tipContentClassName="tooltip-description">
                        <div className="tooltip-content-status ">
                            <span className="icon icon-info reopened-btn__icon"></span>
                        </div>
                    </Tooltip>
                )}
            </button>
        </Tooltip>
    );
};

export default TooltipButton;
