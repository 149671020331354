export interface IWeekDay {
    day: string;
    startTime: string;
    endTime: string;
}

export interface IWeekDayState extends IWeekDay {
    selected: boolean;
}

export interface IWeekDaysSave {
    weekdays: IWeekDay[];
    flexDays: boolean;
    flexTimes: boolean;
}

export type WeekDaysProps = {
    // days: Day[],
    flexDays: boolean;
    flexTimes: boolean;
    title?: string;
    errors?: any;
    name?: string;
    icon?: string;
    selected?: IWeekDay[];
    defaultList?: IWeekDay[];
    onChange?: (props: IWeekDaysSave) => void;
};

export type Day = {
    day: string;
    startTime: string;
    endTime: string;
    selected: boolean;
};

export enum TimeFields {
    startTime = 'startTime',
    endTime = 'endTime',
}

export type OnChangeTimeModel = {
    name: TimeFields;
    value: string;
};

export enum FlexValues {
    flexDays = 'flexDays',
    flexTimes = 'flexTimes',
}
