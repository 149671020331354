import React from 'react';
import RightSideBar, { SideBarMode } from 'common/components/right-side-bar/right-side-bar';
import DisplayValue from 'common/components/display-value/display-value';
import NavigationSideBar from 'common/components/navigation-side-bar/navigation-side-bar';
import { DateTimeHelper } from 'common/helpers/date-time-helper';
import { DisplayValueHelper } from 'common/helpers/display-value-helper';
import { DocumentRuleDetailsDialogProps } from './types';
import { PayTypes } from 'models/enums/pay-types';
import { NavigationRoutes } from 'models/routes/navigation-routes';
import { dateTimeConstants } from 'common/constants/common';

const DocumentRuleDetailsDialog: React.FC<DocumentRuleDetailsDialogProps> = ({
    documentRule,
    onCancel,
    prevButton,
    nextButton,
    actions,
}) => {
    return (
        <div className="document-rule-wrapper">
            <RightSideBar title="Rule Details" onCancel={onCancel} mode={SideBarMode.VIEW} actions={actions}>
                <div className="details  no-flex">
                    <div className="field-group-wrapper">
                        <div className="field-group">
                            <DisplayValue id="ruleName" placeholder="Name" value={documentRule.name} />
                            <DisplayValue
                                id="ruleDocuments"
                                placeholder="Documents"
                                value={documentRule.acceptableDocuments.map((item) => item.name).join(', ')}
                            />
                            <DisplayValue
                                id="ruleEffectiveDate"
                                placeholder="Effective Date"
                                value={DateTimeHelper.format(documentRule.effectiveDate, dateTimeConstants.MM_DD_YYYY, true)}
                            />
                            <DisplayValue
                                id="ruleEndDate"
                                placeholder="End Date"
                                value={DateTimeHelper.format(documentRule.endDate, dateTimeConstants.MM_DD_YYYY, true)}
                            />
                            <DisplayValue
                                id="rulePayType"
                                placeholder="Pay Type"
                                value={Object.values(PayTypes).find((x) => x.value === documentRule.payType)?.label}
                            />
                            <DisplayValue
                                id="ruleDepartment"
                                placeholder="Department"
                                value={DisplayValueHelper.getValue(documentRule.department, 'name')}
                            />
                            <DisplayValue
                                id="ruleSubDepartment"
                                placeholder="Sub Department"
                                value={DisplayValueHelper.getValue(documentRule.subDepartment, 'name')}
                            />
                            <DisplayValue
                                id="ruleDiscipline"
                                placeholder="Discipline"
                                value={DisplayValueHelper.getValue(documentRule.discipline, 'name')}
                            />
                            <DisplayValue
                                id="ruleCustomer"
                                placeholder="Customer"
                                value={DisplayValueHelper.getValue(documentRule.customer, 'name')}
                                customClass={documentRule.customer ? 'object-link-with-icon' : ''}
                                onClick={() =>
                                    documentRule.customer
                                        ? window.open(NavigationRoutes.entityDetailsRoute(documentRule.customer.id), '_blank').focus()
                                        : null
                                }
                            />
                            <DisplayValue
                                id="isOrientationRelated"
                                placeholder="Orientation Related"
                                value={DisplayValueHelper.convertToYesOrNo(documentRule.isOrientationRelated)}
                            />
                            <DisplayValue
                                id="isComplianceRelated"
                                placeholder="Compliance Related"
                                value={DisplayValueHelper.convertToYesOrNo(documentRule.isComplianceRelated)}
                            />
                            <DisplayValue
                                id="ruleIsAllowOverride"
                                placeholder="Allow Override"
                                value={DisplayValueHelper.convertToYesOrNo(documentRule.isOverrideAllow)}
                            />
                            <DisplayValue
                                id="ruleIsPublic"
                                placeholder="Public"
                                value={DisplayValueHelper.convertToYesOrNo(documentRule.isPublic)}
                            />
                        </div>
                    </div>
                    <div className="navigation-buttons">
                        <NavigationSideBar prevButton={prevButton} nextButton={nextButton} />
                    </div>
                </div>
            </RightSideBar>
            <div className="right-sidebar-overlay"></div>
        </div>
    );
};

export default DocumentRuleDetailsDialog;
