import { InfiniteServerClient } from 'common/helpers/http-clients';
import { IReason, ReasonType } from './types';

export class ReasonService {
    getReasons(type: ReasonType): Promise<IReason[]> {
        return InfiniteServerClient.get<IReason[]>(`/reasons?type=${type}`).then(
            (response) => {
                return response.data;
            },
            (error) => {
                throw error;
            }
        );
    }
}

export default new ReasonService();
