import { IPoint } from 'common/models/point';
import { IAvailableTherapistMapFilter } from 'common/components/availability/availability-map/types';

export enum DisplayAddressType {
    Home = 'Home',
    Preference = 'Preference',
    Request = 'Request',
    FacilityActivePlacement = 'FacilityActivePlacement',
    HcMedAActivePlacement = 'HcMedAActivePlacement',
    HcMedBActivePlacement = 'HcMedBActivePlacement',
    SchoolActivePlacement = 'SchoolActivePlacement',
    FacilityRequest = 'FacilityRequests',
    HcMedARequest = 'HcMedARequests',
    HcMedBRequest = 'HcMedBRequests',
    SchoolsRequest = 'SchoolsRequests',
    Cluster = 'Cluster',
    ClusterSearch = 'ClusterSearch',
}

export enum MapType {
    Main = 'Main',
    Request = 'Request',
}

export interface ITherapistMapFilter {
    departments: number[];
    subDepartments: number[];
    populations: number[];
    disciplines: number[];
    complianceStatuses: string[];
    employmentStatuses: string[];
    statuses: string[];
    languages: string[];
    preferredDepartments: number[];
    preferredSubDepartments: number[];
}

export interface IAddressSearchModel {
    center: IPoint;
    radius: number;
    addressTypes?: DisplayAddressType[];
    mapType: MapType;
    filter?: ITherapistMapFilter | IAvailableTherapistMapFilter;
}

export interface IMapPointResponse extends IPoint {
    type: DisplayAddressType;
    id: number;
    data?: any;
    clusters?: { [key: string]: IMapPointResponse };
}

export interface IPointData {
    id: number;
    type: DisplayAddressType;
    data?: any;
}

export interface IMapPoint extends IPoint {
    isCluster: boolean;
    data: IPointData[];
}
