import DropdownOption from './dropdown-option';

class AutoPopulateDropdownOption extends DropdownOption {
    id: number;
    zipCode: string;

    constructor(value: number, label: string, className: string, id: number, zipCode: string) {
        super(value, label, className);
        this.id = id;
        this.zipCode = zipCode;
    }
}

export default AutoPopulateDropdownOption;
