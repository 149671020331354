import React, { useEffect, useState } from 'react';
import FieldSet from 'common/components/field-set/field-set';
import InfiniteSelect from 'common/components/infinite-select/infinite-select';
import DropdownOption from 'common/models/dropdown-option';
import { ComplianceStatuses } from 'models/enums/compliance-statuses';
import { useDepartmentData } from 'common/hooks/use-department-data';
import FilterSelect from 'common/components/header/multiple-filter-select/filter-select';
import { TherapistStatus } from '../therapist-details-page/shared/constants';
import { GridApi } from 'ag-grid-community';

type TherapistListFiltersProps = {
    filters: TherapistListFilter;
    onFiltersChange: (filters: TherapistListFilter) => void;
    gridApi: GridApi;
};

type TherapistListFilter = {
    assignedDepartment: number[];
    assignedSubDepartment: number[];
    employmentStatus: string[];
    complianceStatus: string[];
};

export const TherapistListFilters = ({ filters, onFiltersChange, gridApi }: TherapistListFiltersProps) => {
    const complianceStatusesOptions = Object.values(ComplianceStatuses).map((x: any) => new DropdownOption(x.value, x.label));
    const departments = useDepartmentData();

    const [departmentOptions, setDepartmentOptions] = useState([] as DropdownOption[]);
    const [subDepartmentOptions, setSubDepartmentOptions] = useState([] as DropdownOption[]);

    useEffect(() => {
        setDepartmentOptions(departments.map((x: any) => new DropdownOption(x.id, x.name)));
    }, [departments]);

    const loadSubDepartmentOptions = (data: any, selectedSubDepartments: number[]) => {
        if (!data?.length) {
            return;
        }

        const selectedDepartments = departments.filter((item) => data.some((x: number) => x === item.id));

        if (selectedDepartments?.length > 0) {
            // Take all subDepartments
            const subDepartments: DropdownOption[] = [];
            selectedDepartments.forEach((value) =>
                subDepartments.push(...value.subDepartments?.map((i) => new DropdownOption(i.id, i.name)))
            );
            setSubDepartmentOptions(subDepartments);

            // Select/unselect
            onFiltersChange({
                ...filters,
                assignedSubDepartment: selectedSubDepartments?.filter((item) => subDepartments.some((status) => status.value === item)),
            });
        }
    };

    const handleDepartmentFieldChange = (data: any) => {
        onFiltersChange({ ...filters, assignedDepartment: data });
    };

    const handleFieldChange = (data: any) => {
        onFiltersChange({ ...filters, [data.name]: data.value });
    };

    useEffect(() => {
        if (!departments?.length) {
            return;
        }

        if (filters.assignedDepartment?.length) {
            loadSubDepartmentOptions(filters.assignedDepartment, filters.assignedSubDepartment);
        } else {
            onFiltersChange({ ...filters, assignedSubDepartment: null });
            setSubDepartmentOptions([]);
        }
    }, [departments, filters.assignedDepartment]);

    return (
        <>
            <FieldSet name="assignedDepartment">
                <InfiniteSelect
                    id="departmentSelect"
                    items={departmentOptions}
                    label="Assigned Department"
                    defaultMultipleSelectLabel="All Department"
                    value={departmentOptions.filter((item) => filters.assignedDepartment?.some((status) => status === item.value))}
                    onChange={(items: DropdownOption[]) => {
                        handleDepartmentFieldChange(items.map((i: DropdownOption) => i.value));
                    }}
                    multiselect
                    isDisplaySelectedCount
                />
            </FieldSet>
            {filters.assignedDepartment && subDepartmentOptions.length > 0 && (
                <FieldSet name="assignedSubDepartment">
                    <InfiniteSelect
                        id="subDepartmentSelect"
                        items={subDepartmentOptions}
                        label="Assigned Sub Department"
                        className={'assigned-sub-department'}
                        defaultMultipleSelectLabel="--"
                        value={subDepartmentOptions.filter((item) =>
                            filters.assignedSubDepartment?.some((status) => status === item.value)
                        )}
                        onChange={(items: DropdownOption[]) => {
                            handleFieldChange({ name: 'assignedSubDepartment', value: items.map((i: DropdownOption) => i.value) });
                        }}
                        multiselect
                        isDisplaySelectedCount
                    />
                </FieldSet>
            )}
            <FieldSet name="complianceStatus">
                <InfiniteSelect
                    id="complianceStatusSelect"
                    items={complianceStatusesOptions}
                    label="Assignment status"
                    defaultMultipleSelectLabel="All Statuses"
                    value={complianceStatusesOptions.filter((item) => filters.complianceStatus.some((status) => status === item.value))}
                    onChange={(items: DropdownOption[]) =>
                        handleFieldChange({ name: 'complianceStatus', value: items.map((i: DropdownOption) => i.value) })
                    }
                    multiselect
                    isDisplaySelectedCount
                />
            </FieldSet>
            <FieldSet name="employmentStatus">
                <FilterSelect
                    items={employmentStatus}
                    gridApi={gridApi}
                    colId="status"
                    id="employmentStatusSelect"
                    label="Employment Status"
                    multiselect
                    defaultMultipleSelectLabel="All Statuses"
                />
            </FieldSet>
        </>
    );
};

const employmentStatus = Object.values(TherapistStatus)
    .sort((a, b) => a.label.localeCompare(b.label))
    .map((x) => new DropdownOption(x.label, x.label, x.value));
