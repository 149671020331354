import DisplayValue from 'common/components/display-value/display-value';
import React from 'react';
import { RateTemplateTypesProps } from './types';

const RateTemplateTypes: React.FC<RateTemplateTypesProps> = ({ rateTemplate }) => {
    return (
        <div className="rate-template-types">
            <DisplayValue id="discipline" placeholder="Discipline" value={rateTemplate?.therapyTypeName} />
            <DisplayValue id="type" placeholder="Type" value={rateTemplate?.placementTypeName} />
        </div>
    );
};

export default RateTemplateTypes;
