import { ColumnApi, GridApi } from 'ag-grid-community';
import { CellClickedEvent, GridReadyEvent } from 'ag-grid-community/dist/lib/events';
import AgTable from 'common/components/ag-table/ag-table';
import { IParamsWatcher } from 'common/components/ag-table/types';
import ClearFiltersButton from 'common/components/header/clear-filters-button/clear-filters-button';
import ExportButton from 'common/components/header/export-button/export-button';
import HeaderLeftSide from 'common/components/header/header-left-side';
import HeaderRightSide from 'common/components/header/header-right-side';
import HeaderWrapper from 'common/components/header/header-wrapper';
import SearchFilterInput from 'common/components/header/search-filter-input/search-filter-input';
import ColumnsVisibleFilterSelect from 'common/components/header/сolumns-visible-filter-select/columns-visible-filter-select';
import { LocalStoreRepository } from 'common/helpers/repository/local-store-repository';
import { useSearchInput } from 'common/hooks/use-serch-input';
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { ExtentedDefaultTableProps, IDefaultFilters } from 'common/constants/types';
import { ColDef } from 'ag-grid-community/dist/lib/entities/colDef';
import { maskConstants } from 'common/constants/common';
import { LocalTableRepository } from 'common/helpers/repository/local-table-repository';
import { optionsCompare, optionsFormatter, optionsKeyCreator, setColumnFilterParams } from 'common/helpers/ag-helper';
import physiciansService from 'common/services/api/physicians/physicians-service';
import { IPhysiciansForm, IPhysiciansRow } from 'common/services/api/physicians/types';
import DropdownOption from 'common/models/dropdown-option';
import { UserGridActions } from '../../users-page/components/users-external-list/types';
import InfiniteSelect from 'common/components/infinite-select/infinite-select';
import DisplayValue from 'common/components/display-value/display-value';
import AddButton from 'common/components/header/add-button/add-button';
import { trackPromise } from 'react-promise-tracker';
import AddEditPhysicianSidebar from './add-edit-physician-sidebar/add-edit-physician-sidebar';

const defaultFilters: IDefaultFilters = {
    search: null,
};

const PhysiciansList: React.FC<ExtentedDefaultTableProps> = ({
    onGridReady,
    updateTable,
    storePrefix = '',
    extendGridOptions,
    extendDefaultCustomFilters,
}) => {
    // Repositories
    const gridRef = useRef<any>();
    const [gridApi, setGridApi] = useState<GridApi>(null);
    const [gridColumnApi, setColumnApi] = useState<ColumnApi>(null);
    const tableRepository = useMemo(() => new LocalTableRepository(`${storePrefix}-physicians-table`, 'v3'), [storePrefix]);
    const customFiltersRepository = useMemo(() => new LocalStoreRepository(`${storePrefix}-physicians-table-custom-filters`, 'v3'), [
        storePrefix,
    ]);
    const loadedFilters = useRef<IDefaultFilters>(customFiltersRepository.load());
    const entityId = extendDefaultCustomFilters?.entityId as number;

    if (extendDefaultCustomFilters) {
        Object.keys(extendDefaultCustomFilters).forEach((key) => {
            defaultFilters[key] = extendDefaultCustomFilters[key];
            if (loadedFilters.current) {
                loadedFilters.current[key] = extendDefaultCustomFilters[key];
            }
        });
    }

    const { search, handleSearchInputChange, setSearch } = useSearchInput(
        loadedFilters.current?.search,
        (value: string) => {
            setFilters((prev) => ({ ...prev, search: value }));
        },
        0
    );
    const [filters, setFilters] = useState(loadedFilters.current ?? defaultFilters);
    const paramsWatcher = useRef<IParamsWatcher>();

    useEffect(() => {
        customFiltersRepository.save(filters);
        loadedFilters.current = filters;
    }, [filters, customFiltersRepository]);

    useEffect(() => {
        if (gridRef.current?.api) {
            gridRef.current.api.setQuickFilter(filters.search);
        }
    }, [filters, gridRef.current?.api]);

    useEffect(() => {
        if (updateTable && gridRef?.current?.api) {
            gridRef.current.api.onFilterChanged();
        }
    }, [updateTable, gridRef?.current?.api]);

    const [rows, setRows] = useState<IPhysiciansRow[]>();

    useEffect(() => {
        physiciansService.getData({ entityId: filters?.entityId as number }).then((data) => {
            setRows(data);
        });
    }, [updateTable]);

    const [currentRow, setCurrentRow] = useState<IPhysiciansRow>();

    const handleClickOnAction = useCallback((action: UserGridActions, physician: IPhysiciansRow) => {
        switch (action) {
            case UserGridActions.Edit:
                setCurrentRow(physician);
                setCreateDialogOpen(true);
                break;
        }
    }, []);

    const gridOptions = useMemo(
        () => ({
            ...extendGridOptions,
            onCellClicked: (event: CellClickedEvent) => {
                if (extendGridOptions?.onCellClicked) extendGridOptions?.onCellClicked(event);
            },
            columnDefs: extendGridOptions?.columnDefs ?? requestColumnDefs,
            excelStyles: [
                {
                    id: 'header',
                    font: {
                        color: '#000000',
                        weight: 800,
                        bold: true,
                        size: 12,
                    },
                    alignment: {
                        horizontal: 'Center' as const,
                        vertical: 'Center' as const,
                    },
                },
                {
                    id: 'cell',
                    font: {
                        color: '#000000',
                    },
                    alignment: {
                        horizontal: 'Left' as const,
                        vertical: 'Center' as const,
                    },
                },
            ],
            defaultColDef: {
                resizable: true,
            },
            frameworkComponents: {
                phoneFormat: (props: any) => {
                    const cellValue = props.valueFormatted ? props.valueFormatted : props.value;

                    return <DisplayValue id="phone" placeholder="" value={cellValue} mask={maskConstants.PHONE_NUMBER} />;
                },
                doteRender: (props: any) => {
                    const options = [new DropdownOption(UserGridActions.Edit, 'Edit')];

                    return (
                        options.length > 0 && (
                            <InfiniteSelect
                                id="users-actions-menu"
                                popper
                                items={options}
                                className="no-select-border"
                                icon={<i className="icon icon-options" />}
                                onChange={(item: DropdownOption) => {
                                    handleClickOnAction(item?.value, props.data);
                                }}
                            />
                        )
                    );
                },
                objectLinkValueRender: (props: any) => {
                    const cellValue = (props.valueFormatted ? props.valueFormatted : props.value) || '';

                    return <span className="object-link">{cellValue}</span>;
                },
                ...extendGridOptions?.frameworkComponents,
            },
        }),
        [extendGridOptions, handleClickOnAction]
    );

    const handleFiltersClear = () => {
        setSearch('');
        setFilters({ ...defaultFilters });
    };

    const handleGridReady = (params: GridReadyEvent) => {
        setGridApi(params.api);
        setColumnApi(params.columnApi);
        if (onGridReady) {
            onGridReady({ params, extraParams: { setCreateDialogOpen } });
        }
    };

    //Side Bar
    const [isCreateDialogOpen, setCreateDialogOpen] = useState<boolean>(false);

    const handleOnClose = () => {
        setCreateDialogOpen(false);
        setCurrentRow(null);
    };

    const handleOnSave = (physicianForm: IPhysiciansForm) => {
        if (currentRow) {
            return trackPromise(physiciansService.edit(physicianForm)).then((data) => {
                setCreateDialogOpen(false);
                setCurrentRow(null);
                gridApi.applyTransaction({
                    update: [Object.assign(currentRow, data)] as any,
                });
            });
        }

        return trackPromise(physiciansService.create(physicianForm)).then((data) => {
            setCreateDialogOpen(false);
            setCurrentRow(null);
            gridApi.applyTransaction({
                add: [data] as any,
            });
        });
    };

    const handleClickAddPhysician = () => {
        setCreateDialogOpen(true);
    };

    const presetPractice = entityId ? [{ id: entityId, name: '' }] : [];

    return (
        <>
            <div className="main-info-wrapper ag-table-wrapper">
                <HeaderWrapper>
                    <HeaderLeftSide>
                        <SearchFilterInput title="physicians" value={search} onInput={handleSearchInputChange} />
                        <ColumnsVisibleFilterSelect title="physicians" gridApi={gridApi} gridColumnApi={gridColumnApi} />
                    </HeaderLeftSide>
                    <HeaderRightSide>
                        <ExportButton title="Physicians" paramsWatcher={paramsWatcher} gridRef={gridRef} />
                        <ClearFiltersButton
                            title="physicians"
                            gridApi={gridApi}
                            gridColumnApi={gridColumnApi}
                            onClick={handleFiltersClear}
                        />
                        {filters.entityId && <AddButton title="Add Physician " onClick={handleClickAddPhysician} />}
                    </HeaderRightSide>
                </HeaderWrapper>
                <AgTable
                    clientOptions={{ rowData: rows }}
                    gridRef={gridRef}
                    clientSide
                    onGridReady={handleGridReady}
                    gridOptions={gridOptions}
                    onFiltersLoadedFromUrl={handleFiltersClear}
                    repository={tableRepository}
                    customFilters={filters}
                    paramsWatcher={paramsWatcher}
                />
            </div>
            {isCreateDialogOpen && (
                <AddEditPhysicianSidebar
                    onClose={handleOnClose}
                    onSave={handleOnSave}
                    data={currentRow}
                    options={{ entityId, presetPractice }}
                />
            )}
        </>
    );
};

export default PhysiciansList;

const requestColumnDefs: ColDef[] = [
    {
        field: 'firstName',
        headerName: 'First Name',
        minWidth: 250,
    },
    {
        field: 'lastName',
        headerName: 'Last Name',
        minWidth: 250,
    },
    {
        field: 'practices',
        headerName: 'Doctor Office',
        width: 154,
        keyCreator: optionsKeyCreator,
        valueFormatter: optionsFormatter,
        filter: 'agSetColumnFilter',
        sortable: true,
        filterParams: setColumnFilterParams,
        comparator: optionsCompare,
    },
    {
        field: 'address1',
        headerName: 'Address1',
        width: 350,
        filter: 'agSetColumnFilter',
        filterParams: setColumnFilterParams,
    },
    {
        field: 'address2',
        headerName: 'Address2',
        width: 350,
        filter: 'agSetColumnFilter',
        filterParams: setColumnFilterParams,
    },
    {
        field: 'city',
        headerName: 'City',
        width: 150,
        filter: 'agSetColumnFilter',
        filterParams: setColumnFilterParams,
    },
    {
        field: 'state',
        headerName: 'State',
        valueFormatter: (props) => props.value?.name,
        width: 150,
        filter: 'agSetColumnFilter',
        filterParams: setColumnFilterParams,
    },
    {
        field: 'postalCode',
        headerName: 'Zip Code',
        width: 113,
        filter: 'agSetColumnFilter',
        filterParams: setColumnFilterParams,
    },
    {
        field: 'phonesView',
        headerName: 'Phone',
        width: 210,
        cellRenderer: 'phoneFormat',
        filter: 'agSetColumnFilter',
        sortable: true,
        filterParams: setColumnFilterParams,
    },
    {
        field: 'fax',
        headerName: 'Fax',
        width: 210,
        cellRenderer: 'phoneFormat',
        filter: 'agSetColumnFilter',
        filterParams: setColumnFilterParams,
    },
    {
        field: 'emails',
        headerName: 'Email',
        width: 300,
        valueFormatter: (props) => (props.value?.length ? props.value[0].email : ''),
        filter: 'agSetColumnFilter',
        filterParams: setColumnFilterParams,
    },
    {
        headerName: '',
        colId: 'doteMenu',
        filter: 'agCustomFilter',
        sortable: false,
        pinned: 'right',
        cellRenderer: 'doteRender',
        cellClass: ['dote-cell flex-center'],
        width: 40,
    },
];
