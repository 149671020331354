import { MAX_WEEK_DAYS_COUNT, MIN_WEEK_DAYS_COUNT } from 'common/constants/common';
import { DateTimeHelper } from 'common/helpers/date-time-helper';
import DropdownOption from 'common/models/dropdown-option';
import moment from 'moment';

export const getWeekOptions = (): DropdownOption[] => {
    const type = 'inRange';
    const currentDate = moment();
    const weekOptions = new Array<DropdownOption>();
    const tenWeeksCount = MAX_WEEK_DAYS_COUNT * 10;
    const dateOfTenthWeek = currentDate.clone().add(tenWeeksCount, 'days');

    const originalFormat = 'YYYY-MM-DD';

    while (currentDate < dateOfTenthWeek) {
        const from = currentDate.clone().weekday(MIN_WEEK_DAYS_COUNT);
        const to = currentDate.clone().weekday(MAX_WEEK_DAYS_COUNT);

        const dateConditionValue = btoa(
            JSON.stringify({
                weekCondition: {
                    from: from.format(originalFormat),
                    to: to.format(originalFormat),
                    type,
                },
            })
        );

        const weekLabel = `${DateTimeHelper.format(from.toDate(), 'MMM D', true)} - ${DateTimeHelper.format(to.toDate(), 'MMM D', true)}`;
        weekOptions.push(new DropdownOption(dateConditionValue, weekLabel));
        currentDate.add(MAX_WEEK_DAYS_COUNT, 'days');
    }

    return weekOptions;
};
