import React, { useEffect } from 'react';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import { FieldListProps } from './types';

const FieldList: React.FC<FieldListProps> = ({
    values,
    disableRemove,
    name,
    startTabIndex,
    defaultValue,
    addButtonText,
    onChange,
    renderInput,
    className,
    isDragNDrop = false,
}) => {
    useEffect(() => {
        if (values.length === 0) {
            onChange([...values, defaultValue]);
        }
    }, []);

    const onDeleteClick = (index: number) => {
        values.splice(index, 1);
        onChange([...values]);
    };

    const onAddClick = () => {
        onChange([...values, defaultValue]);
    };

    const swapArrayElements = (list: any[], firstIndex: number, secondIndex: number) => {
        const swapItem = list[firstIndex];
        list[firstIndex] = list[secondIndex];
        list[secondIndex] = swapItem;
    };

    const onDragEnd = (result: any) => {
        if (!result.destination) {
            return;
        }

        const swapedValues = values;
        swapArrayElements(swapedValues, result.source.index, result.destination.index);

        onChange([...swapedValues]);
    };

    const renderListItem = (value: any, index: number) => {
        return (
            <div className={`${className ?? ''} field-list-item`}>
                {isDragNDrop && values && values.length > 1 && <div className="icon icon-drag"></div>}
                {renderInput(value, index, startTabIndex)}
                <>
                    {values && values.length > 1 && !value.isDisabled && !disableRemove && (
                        <button className="delete-button base-text-btn white" onClick={() => onDeleteClick(index)}>
                            <span className="icon icon-close"></span>
                        </button>
                    )}
                </>
            </div>
        );
    };

    return (
        <div
            className={`field-list-section ${values.length === 1 ? 'field-list-section-single' : ''} ${
                !isDragNDrop ? 'field-list-section-no-draggable' : 'field-list-section-is-draggable'
            }`}
        >
            <DragDropContext onDragEnd={onDragEnd}>
                <Droppable droppableId={name}>
                    {(provided) => (
                        <div {...provided.droppableProps} ref={provided.innerRef}>
                            {values.map((value, index) => (
                                <Draggable isDragDisabled={!isDragNDrop} key={index} draggableId={name + index} index={index}>
                                    {(provided) => (
                                        <div ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps}>
                                            {renderListItem(value, index)}
                                        </div>
                                    )}
                                </Draggable>
                            ))}
                            {provided.placeholder}
                        </div>
                    )}
                </Droppable>
                {addButtonText && (
                    <button className="add-button base-input-wrapper base-text-btn white" onClick={onAddClick}>
                        {addButtonText}
                        <span className="plus icon icon-add"></span>
                    </button>
                )}
            </DragDropContext>
        </div>
    );
};

export default FieldList;
