import { SET_PATIENT_DETAILS, UPDATE_PATIENT_PROFILE_PHOTO, IPatientState, PatientDetailsStateActionTypes } from './types';
import { Reducer } from '@reduxjs/toolkit';

const initialState: IPatientState = {
    patient: null,
};

export const patientDetailsReducer: Reducer<IPatientState, PatientDetailsStateActionTypes> = (state = initialState, action) => {
    switch (action.type) {
        case SET_PATIENT_DETAILS:
            return { ...state, patient: action.patient };
        case UPDATE_PATIENT_PROFILE_PHOTO:
            return { ...state, patient: { ...state.patient, profilePhoto: action.profilePhoto } };
        default:
            return state;
    }
};
