import React, { useState, useEffect } from 'react';
import { useMemo } from 'react';
import { useCallback } from 'react';
import InfiniteSelect from '../../infinite-select/infinite-select';
import { ColumnsVisibleFilterSelectProps } from '../type';

const ColumnsVisibleFilterSelect = ({
    gridApi,
    gridColumnApi,
    title,
    label = 'Columns',
    placeholder,
    selectAll,
}: ColumnsVisibleFilterSelectProps) => {
    const [columnsOptionsList, setColumnsOptionsList] = useState([]);
    const placeholderValue = placeholder || 'All Columns';

    const updateColumns = useCallback(() => {
        setColumnsOptionsList(
            gridColumnApi
                .getAllColumns()
                .filter((column) => {
                    return column.getColDef().columnGroupShow !== 'hide' && !column.isPinned();
                })
                .map((column) => ({
                    colId: column.getColId(),
                    name: column.getColDef().headerName ?? column.getColDef().field,
                    isShown: column.isVisible(),
                }))
        );
    }, [gridColumnApi]);

    useEffect(() => {
        if (gridColumnApi && gridApi) {
            updateColumns();
            gridApi.addEventListener('columnVisible', updateColumns);

            return () => {
                gridApi.removeEventListener('columnVisible', updateColumns);
            };
        }
    }, [gridColumnApi, gridApi, updateColumns]);

    const getHiddenItems = () => {
        const quantity = columnsOptionsList.filter((item) => !item.isShown).length;

        return quantity > 0 ? `${quantity} Hidden` : placeholderValue;
    };

    const selectedColumns = useMemo(() => {
        return columnsOptionsList.filter((item) => item.isShown);
    }, [columnsOptionsList]);

    const toggleColumnsVisibility = (option: any) => {
        const visibleColumn = gridColumnApi.getAllDisplayedColumns().filter((column) => !column.isPinned());
        if (visibleColumn.length > 1 || !option.isShown) {
            gridColumnApi.setColumnVisible(option.colId, !option.isShown);
            updateColumns();
        }
    };

    const renderVisibleColumnsOptionsList = () => {
        return columnsOptionsList
            .filter((i) => i.name)
            .map((column, index) => {
                return (
                    <span key={index} className="option-item" onClick={() => toggleColumnsVisibility(column)}>
                        <input type="checkbox" className="nps-checkbox" id={column.colId} checked={column.isShown} readOnly />
                        <label htmlFor={column.name}>
                            <span>{column.name}</span>
                        </label>
                    </span>
                );
            });
    };

    const handleSelectAll = (itemsToSelect: any[]) => {
        if (itemsToSelect.length === 0) {
            hideAllColumns();
        } else {
            selectAllColumns();
        }
    };

    const selectAllColumns = () => {
        gridColumnApi
            .getAllColumns()
            .filter((column) => {
                return column.getColDef().columnGroupShow !== 'hide' && !column.isPinned();
            })
            .forEach((column) => {
                gridColumnApi.setColumnVisible(column.getColId(), true);
                updateColumns();
            });
    };

    const hideAllColumns = () => {
        let tableHasPinnedColumn = false;

        gridColumnApi
            .getAllColumns()
            .filter((column) => {
                if (column.isPinned()) {
                    tableHasPinnedColumn = true;
                }

                return column.getColDef().columnGroupShow !== 'hide' && !column.isPinned();
            })
            .forEach((column, index, columns) => {
                // Not to hide last element if table doesn't have pinned column.
                if (index === columns.length - 1 && !tableHasPinnedColumn) {
                    return;
                }

                gridColumnApi.setColumnVisible(column.getColId(), false);
                updateColumns();
            });
    };

    if (selectAll) {
        return (
            <InfiniteSelect
                id={`${title}HideUnselectedColumn`}
                label={label}
                items={columnsOptionsList}
                customSelectedLabel={getHiddenItems()}
                renderCustomOptions={renderVisibleColumnsOptionsList}
                onSelectAll={handleSelectAll}
                onClear={selectAllColumns}
                multiselect
                customSelectedValues={selectedColumns}
            />
        );
    }

    return (
        <InfiniteSelect
            id={`${title}HideUnselectedColumn`}
            label={label}
            customSelectedLabel={getHiddenItems()}
            renderCustomOptions={renderVisibleColumnsOptionsList}
        />
    );
};

export default React.memo(ColumnsVisibleFilterSelect);
