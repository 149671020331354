import React, { useEffect, useMemo, useState } from 'react';
import { MapClusterTypes } from './types';
import './map-cluster.scss';
import { iconMap } from 'common/constants/map/map';
import { DisplayAddressType } from 'common/services/api/map/types';

const MapCluster = ({ point, getType }: MapClusterTypes) => {
    const [currentType, setCurrentType] = useState(null);

    useEffect(() => {
        setCurrentType(null);
    }, [point.isCluster]);

    const markers = useMemo(() => {
        if (!point.isCluster) {
            return [];
        }

        const filterUnique = (value: string, index: number, self: string[]) => {
            return self.indexOf(value) === index;
        };

        return point.data
            .flatMap((i) => i.type)
            .filter(filterUnique)
            .sort(function (a, b) {
                return a.toLowerCase().localeCompare(b.toLowerCase());
            });
    }, [point.data, point.isCluster]);

    if (!point?.isCluster) {
        return <>{getType(point)}</>;
    }

    if (currentType) {
        return <>{getType({ ...point, data: point.data.filter((i) => i.type === currentType) }, () => setCurrentType(null))}</>;
    }

    return (
        <>
            <div className="map-cluster__title">The Same Addresses</div>
            <div className="map-cluster">
                {markers.map((type) => {
                    const data = point.data.filter((i) => i.type === type);

                    const iconConfig = iconMap[type];
                    const icon = iconConfig.icon ? (
                        <img src={iconConfig.icon} alt="House" />
                    ) : (
                        <span className="icon icon-marker" style={{ color: iconConfig.color }}></span>
                    );

                    return (
                        <div
                            key={type}
                            className={`map-cluster__item ${type.toLowerCase()}`}
                            onClick={type === DisplayAddressType.Request ? null : () => setCurrentType(type)}
                        >
                            {icon}
                            <span className="map-cluster__label" style={{ color: iconConfig.color }}>
                                {iconConfig.name ?? type} {data.length > 1 ? `(${data.length})` : ``}
                            </span>
                        </div>
                    );
                })}
            </div>
        </>
    );
};

export default MapCluster;
