import Moment from 'moment';
import { dateTimeConstants } from 'common/constants/common';
import { CommonSettings } from 'settings.json';

export class DateTimeHelper {
    static format(value: string | Date, format = dateTimeConstants.MM_DD_YYYY, toUtc = false) {
        if (!value) {
            return '';
        }

        const date = Moment(value);
        if (!toUtc) {
            return date.local().format(format);
        }

        return date.utc().format(format);
    }

    static formatToLocalTime(value: string | Date, format: string) {
        return DateTimeHelper.format(value, format, false);
    }

    static getDefaultEndDate() {
        return new Date(Date.parse(CommonSettings.DefaultEndDate));
    }

    static timespanToUTCTime(value: string, format = dateTimeConstants.HH_MM) {
        if (!value) {
            return '';
        }

        const date = Moment(Moment().format(dateTimeConstants.MM_DD_YYYY).concat(` ${value}Z`));

        return date.utc().format(format);
    }

    static timespanToLocalTime(value: string, format = dateTimeConstants.HH_MM_A) {
        if (!value) {
            return '';
        }

        const date = Moment(Moment().format(dateTimeConstants.MM_DD_YYYY).concat(` ${value}Z`));

        return date.local().format(format);
    }

    static isToday(value: Date) {
        return Moment(value).local().isSame(new Date(), 'day');
    }
}
