import { InfoWindow } from '@react-google-maps/api';
import React from 'react';
import { MapInfoWindowProps } from './types';
import './style.scss';

export const MapInfoWindow: React.FC<MapInfoWindowProps> = ({ children, position, onCloseClick }) => {
    const center = new window.google.maps.LatLng(position.latitude, position.longitude);

    return (
        <InfoWindow
            position={center}
            onCloseClick={onCloseClick}
            options={{ disableAutoPan: true, pixelOffset: new google.maps.Size(0, -30) }}
        >
            <div className="therapists-window">{children}</div>
        </InfoWindow>
    );
};

export default React.memo(MapInfoWindow);
