import { useSelector } from 'react-redux';
import { UserType } from '../constants/user-type';
import { IUserType } from './types';

export function useIsUserType(): IUserType {
    const currentProfile = useSelector<IUserType>((state: any) => state.auth.user.type);
    const isUserType = (profiles: UserType) => (currentProfile ? profiles === currentProfile : null);

    return {
        isExternal: isUserType(UserType.External),
        isInfiniteUser: isUserType(UserType.Infinite),
        isTherapistUser: isUserType(UserType.Therapist),
    };
}
