import React from 'react';
import { AddTherapistLabelProps } from './types';

const StatusTherapistLabel: React.FC<AddTherapistLabelProps> = ({ onClick, status }) => {
    return (
        <span className="status-label" onClick={onClick}>
            {status}
        </span>
    );
};

export default React.memo(StatusTherapistLabel);
