import { dateTimeConstants } from 'common/constants/common';
import Moment from 'moment';
import React from 'react';
import { SchoolRequestHeaderInfoProp } from '../types';
import { SchoolRequestStatus, SchoolRequestStatusLabel } from 'common/services/api/school/types';
import { useIsUserType } from 'common/hooks/use-is-user-type';

const formatDate = (date: Date) => {
    return Moment(date).format(dateTimeConstants.MM_DD_YYYY);
};

const SchoolRequestHeaderInfo: React.FC<SchoolRequestHeaderInfoProp> = ({ details, handleSeeActivityButton }) => {
    const statusClass = SchoolRequestStatus[details.status].toLowerCase();
    const { isExternal } = useIsUserType();

    return (
        <div className="rh-info">
            <div className="rh-info__title">{details.placeOfService.name}</div>
            <div className="rh-info__body">
                <span>
                    {' '}
                    {details?.id} <i className="rh-info__dote" />{' '}
                </span>
                <span>
                    {' '}
                    Created {formatDate(details.createdAt)} by {details.createdBy?.fullName}{' '}
                </span>
                {details.updatedAt && (
                    <span>
                        {' '}
                        <i className="rh-info__dote" /> Last updated {formatDate(details.updatedAt)} by {details.updatedBy?.fullName}
                    </span>
                )}
            </div>
            <div className="rh-info__footer">
                <span className={`school-status ${statusClass}`} onClick={handleSeeActivityButton}>
                    {SchoolRequestStatusLabel.get(details.status)}
                </span>
                <div className="rh-info__actions">
                    {!isExternal && (
                        <button className="base-text-btn white" onClick={handleSeeActivityButton}>
                            See Activity
                        </button>
                    )}
                </div>
            </div>
        </div>
    );
};

export default SchoolRequestHeaderInfo;
// export default React.memo(SchoolRequestHeaderInfo, function areEqual(prevProps, nextProps) {
//     return prevProps.details === nextProps.details;
// });
