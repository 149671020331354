import React from 'react';
import { DetailsNavigationProps } from './types';
import './styles.scss';

const DetailsNavigation: React.FC<DetailsNavigationProps> = ({ navigationData, onPreviousClick, onNextClick }) => {
    return (
        <div className="details-navigation">
            {navigationData?.previousId && (
                <button className="base-text-btn white" id="previousEntityBtn" onClick={onPreviousClick}>
                    Previous
                </button>
            )}
            {navigationData?.nextId && (
                <button className="base-text-btn white" id="nextEntityBtn" onClick={onNextClick}>
                    Next
                </button>
            )}
        </div>
    );
};

export default DetailsNavigation;
