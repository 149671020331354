import { DateTimeHelper } from 'common/helpers/date-time-helper';
import { InfiniteServerClient } from 'common/helpers/http-clients';
import { EDIT_RATE_REQUEST, ILegalEntityRate, IRateOption, NEW_RATE_REQUEST } from './types';
import { ValidationHelper } from 'common/helpers/validation-helper';

export class RateService {
    addRate = (entityId: number, rate: ILegalEntityRate) => {
        const bodyFormData = this.getFormData(rate);

        return (
            ValidationHelper.validateFile(bodyFormData) ??
            InfiniteServerClient.post(`/entities/${entityId}/rates`, bodyFormData).then(
                (response) => {
                    if (response.status === 204) {
                        return;
                    }
                },
                (error) => {
                    throw error.response.data;
                }
            )
        );
    };

    editRate = (entityId: number, rate: ILegalEntityRate) => {
        const bodyFormData = this.getFormData(rate);

        return (
            ValidationHelper.validateFile(bodyFormData) ??
            InfiniteServerClient.put(`/entities/${entityId}/rates`, bodyFormData).then(
                (response) => {
                    if (response.status === 204) {
                        return;
                    }
                },
                (error) => {
                    throw error.response.data;
                }
            )
        );
    };

    changeRate = (entityId: number, editRrate: ILegalEntityRate, newRate: ILegalEntityRate) => {
        let bodyFormData: FormData = new FormData();
        bodyFormData = this.getFormData(editRrate, bodyFormData, EDIT_RATE_REQUEST);
        bodyFormData = this.getFormData(newRate, bodyFormData, NEW_RATE_REQUEST);

        return (
            ValidationHelper.validateFile(bodyFormData) ??
            InfiniteServerClient.put(`/entities/${entityId}/rates/actions/change`, bodyFormData).then(
                (response) => {
                    if (response.status === 204) {
                        return;
                    }
                },
                (error) => {
                    throw error.response.data;
                }
            )
        );
    };

    getFormData(rate: any, bodyFormData: FormData = new FormData(), prefix: string = '') {
        for (const key in rate) {
            if (rate[key]) {
                if (rate[key] instanceof Date) {
                    rate[key] = DateTimeHelper.format(rate[key], null, true);
                }

                if (rate[key]._isAMomentObject) {
                    rate[key] = DateTimeHelper.format(rate[key].toDate(), null, true);
                }

                if (rate[key] instanceof Array) {
                    for (let i = 0; i < rate[key].length; i++) {
                        bodyFormData.append(`${prefix}${key}`, rate[key][i]);
                    }
                    continue;
                }

                bodyFormData.append(`${prefix}${key}`, rate[key]);
            }
        }

        return bodyFormData;
    }

    getRateOptions(term: string, skip: number, take: number, entityId?: number, therapyTypeId?: number): Promise<IRateOption[]> {
        return InfiniteServerClient.get<IRateOption[]>(`/entities/${entityId}/rates/options`, {
            params: {
                term,
                skip,
                take,
                therapyTypeId,
            },
        }).then(
            (response) => {
                return response.data;
            },
            (error) => {
                throw error;
            }
        );
    }
}

export default new RateService();
