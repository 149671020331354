import { CellClickedEvent, GridReadyEvent } from 'ag-grid-community';
import AgTable from 'common/components/ag-table/ag-table';
import { IParamsWatcher } from 'common/components/ag-table/types';
import HeaderLeftSide from 'common/components/header/header-left-side';
import HeaderRightSide from 'common/components/header/header-right-side';
import HeaderWrapper from 'common/components/header/header-wrapper';
import { ExtentedDefaultTableProps } from 'common/constants/types';
import { LocalStoreRepository } from 'common/helpers/repository/local-store-repository';
import { LocalTableRepository } from 'common/helpers/repository/local-table-repository';
import { useSearchInput } from 'common/hooks/use-serch-input';
import { getExportData, getFilterData, getTableData } from 'common/services/api/tabel-fetch-service';
import { NavigationRoutes } from 'models/routes/navigation-routes';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import { useHistory } from 'react-router';
import { PatientCustomFilters } from './type';
import SearchFilterInput from 'common/components/header/search-filter-input/search-filter-input';
import ColumnsVisibleFilterSelect from 'common/components/header/сolumns-visible-filter-select/columns-visible-filter-select';
import ExportButton from 'common/components/header/export-button/export-button';
import ClearFiltersButton from 'common/components/header/clear-filters-button/clear-filters-button';
import { DateTimeHelper } from 'common/helpers/date-time-helper';
import { dateTimeConstants } from 'common/constants/common';
import { facilityAddressToString } from 'common/helpers/address-helper';
import { DetailsNavigationHelper } from 'common/helpers/details-navigation-helper';

const CUSTOMER = 'customer';
const FACILITY = 'facility';

let handleFetchData = getTableData('patients');
let handleFetchFilters = getFilterData('patients/filter/source');
let handleExportData = getExportData('patients/export');

const PatientsList: React.FC<ExtentedDefaultTableProps> = ({
    updateTable,
    storePrefix = '',
    extendGridOptions,
    extendDefaultCustomFilters,
    extendFetchers,
    serachTitle = 'Patients',
}) => {
    if (extendFetchers?.handleFetchData) {
        handleFetchData = extendFetchers?.handleFetchData;
    }
    if (extendFetchers?.handleFetchFilters) {
        handleFetchFilters = extendFetchers?.handleFetchFilters;
    }
    if (extendFetchers?.handleExportData) {
        handleExportData = extendFetchers?.handleExportData;
    }

    const tableRepository = useMemo(() => new LocalTableRepository(`${storePrefix}-patient-table`, 'v3'), [storePrefix]);
    const customFiltersRepository = useMemo(() => new LocalStoreRepository(`${storePrefix}-patient-table-custom-filters`, 'v3'), [
        storePrefix,
    ]);
    const loadedFilters = useRef<PatientCustomFilters>(customFiltersRepository.load());
    const defaultFilters: PatientCustomFilters = {
        search: null,
    };
    if (extendDefaultCustomFilters) {
        Object.keys(extendDefaultCustomFilters).forEach((key) => {
            defaultFilters[key] = extendDefaultCustomFilters[key];
            if (loadedFilters.current) {
                loadedFilters.current[key] = extendDefaultCustomFilters[key];
            }
        });
    }

    const [gridApi, setGridApi] = useState(null);
    const [gridColumnApi, setColumnApi] = useState(null);
    const [filters, setFilters] = useState(loadedFilters.current ?? defaultFilters);
    const { search, handleSearchInputChange, setSearch } = useSearchInput(loadedFilters.current?.search, (value: string) => {
        setFilters((prev) => ({ ...prev, search: value }));
    });
    const paramsWatcher = useRef<IParamsWatcher>();
    const history = useHistory();

    useEffect(() => {
        customFiltersRepository.save(filters);
        loadedFilters.current = filters;
    }, [filters, customFiltersRepository]);

    const gridOptions = useMemo(
        () => ({
            ...extendGridOptions,
            onCellClicked: (event: CellClickedEvent) => {
                const { id, facility, customer } = event.data;

                switch (event.column.getColId()) {
                    case CUSTOMER: {
                        if (customer?.id) {
                            window.open(NavigationRoutes.entityDetailsRoute(customer?.id), '_blank').focus();
                        }
                        break;
                    }
                    case FACILITY: {
                        if (facility?.id) {
                            window.open(NavigationRoutes.entityDetailsRoute(facility?.id)).focus();
                        }
                        break;
                    }
                    default: {
                        const rowIndex = event.node.parent?.rowIndex ?? event.node.rowIndex;
                        history.push(
                            { pathname: NavigationRoutes.patientDetailsRoute(id) },
                            DetailsNavigationHelper.getStateForDetails(paramsWatcher.current, rowIndex)
                        );
                        break;
                    }
                }
                if (extendGridOptions?.onCellClicked) extendGridOptions?.onCellClicked(event);
            },
            columnDefs: extendGridOptions?.columnDefs ?? patientColumnDef,
            treeData: true,
            isServerSideGroup: (dataItem: any) => {
                return dataItem?.previousEnrollments?.length > 0;
            },
            getServerSideGroupKey: function (dataItem: any) {
                return dataItem?.previousEnrollments.map((item: any) => ({
                    id: dataItem.id,
                    patientAgencyCode: item?.externalPatientId,
                    startDate: item?.startDate,
                    endDate: item?.endDate,
                    customer: item?.entity,
                }));
            },
            autoGroupColumnDef: {
                headerName: '',
                field: 'id',
                sortable: false,
                hideForExport: true,
                lockPosition: true,
                maxWidth: 50,
                minWidth: 50,
                cellClass: 'arrow-cell',

                cellRendererParams: {
                    innerRenderer: function (params: any) {
                        return params.data.id;
                    },
                },
            },

            defaultColDef: {
                resizable: true,
                filterParams: {
                    onFetchFilters: handleFetchFilters,
                },
            },
            frameworkComponents: {
                cellLinkRender: (props: any) => {
                    const cellValue = props.valueFormatted ? props.valueFormatted : props.value;

                    return <span className="object-link">{cellValue}</span>;
                },
                ...extendGridOptions?.frameworkComponents,
            },
        }),
        [extendGridOptions, history]
    );

    useEffect(() => {
        if (updateTable && gridApi) {
            gridApi.onFilterChanged();
        }
    }, [updateTable, gridApi]);

    // Clear
    const handleFiltersClear = () => {
        setSearch('');
        setFilters({ ...defaultFilters });
    };

    const handleGridReady = (params: GridReadyEvent) => {
        setGridApi(params.api);
        setColumnApi(params.columnApi);
    };

    return (
        <>
            <div className="main-info-wrapper ag-table-wrapper">
                <HeaderWrapper>
                    <HeaderLeftSide>
                        <SearchFilterInput title={serachTitle} value={search} onInput={handleSearchInputChange} />
                        <ColumnsVisibleFilterSelect title="Entities" gridApi={gridApi} gridColumnApi={gridColumnApi} />
                    </HeaderLeftSide>
                    <HeaderRightSide>
                        <ExportButton title="Entities" paramsWatcher={paramsWatcher} onExportData={handleExportData} />
                        <ClearFiltersButton title="Entities" gridApi={gridApi} gridColumnApi={gridColumnApi} onClick={handleFiltersClear} />
                    </HeaderRightSide>
                </HeaderWrapper>
                <AgTable
                    onGridReady={handleGridReady}
                    gridOptions={gridOptions}
                    customFilters={filters}
                    onFiltersLoadedFromUrl={handleFiltersClear}
                    repository={tableRepository}
                    paramsWatcher={paramsWatcher}
                    onFetchData={handleFetchData}
                />
            </div>
        </>
    );
};

export const patientColumnDef: any[] = [
    {
        field: 'name',
        headerName: 'Name',
        filter: 'agCustomFilter',
        width: 350,
    },
    {
        field: 'patientAgencyCode',
        headerName: 'Patient ID',
        filter: 'agCustomFilter',
        width: 140,
    },
    {
        field: 'age',
        headerName: 'Age',
        filter: 'agCustomFilter',
        width: 70,
    },
    {
        field: 'address',
        headerName: 'Address',
        width: 350,
        valueFormatter: (props: any) => {
            return props?.value?.length > 1 ? props?.value : facilityAddressToString(props.data?.facility?.address);
        },
    },
    {
        field: CUSTOMER,
        headerName: 'Customer',
        cellRenderer: 'cellLinkRender',
        filter: 'agCustomFilter',
        valueFormatter: (props: any) => props?.value?.name,
        width: 350,
    },
    {
        field: FACILITY,
        headerName: 'Facility',
        cellRenderer: 'cellLinkRender',
        filter: 'agCustomFilter',
        valueFormatter: (props: any) => props?.value?.name,
        width: 350,
    },
    {
        field: 'startDate',
        headerName: 'Start Date',
        valueFormatter: (props: any) => DateTimeHelper.format(props.value, dateTimeConstants.MM_DD_YYYY, true),
        width: 150,
    },
    {
        field: 'endDate',
        headerName: 'Termination Date',
        hideForExport: true,
        valueFormatter: (props: any) => DateTimeHelper.format(props.value, dateTimeConstants.MM_DD_YYYY, true),
    },
];

export default PatientsList;
