import React from 'react';
import { ICellRendererParams } from 'ag-grid-community';
import { ITherapistAssignment } from 'common/services/api/therapist-assignment/types';
import { NavigationRoutes } from 'models/routes/navigation-routes';

export const CustomerLinkRederer = (props: ICellRendererParams) => {
    const data: ITherapistAssignment = props.data as ITherapistAssignment;

    return data.customers.map((customer, index) => {
        return (
            <>
                <span
                    onClick={() => window.open(NavigationRoutes.entityDetailsRoute(customer?.id), '_blank').focus()}
                    className="object-link"
                >
                    {customer?.name ?? customer?.id}
                </span>
                {data.customers.length - 1 > index ? ', ' : ''}
            </>
        );
    });
};

export default CustomerLinkRederer;
