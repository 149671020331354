import { Dispatch } from '@reduxjs/toolkit';
import { LanguageService } from 'common/services/api/language/language-service';
import { ILanguage } from 'common/services/api/language/types';
import { ISetLanguagesAction, ThunkResult } from './types';

export const setLanguages = (payload: ILanguage[]): ISetLanguagesAction => {
    return {
        languages: payload,
        type: 'SET_LANGUAGES',
    };
};

export const fetchLanguages = (): ThunkResult<Promise<ILanguage[]>> => {
    return async (dispatch: Dispatch) => {
        const data = await new LanguageService().getAll();
        dispatch(setLanguages(data));

        return data;
    };
};
