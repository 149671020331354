import React from 'react';
import DisplayValue from 'common/components/display-value/display-value';
import { maskConstants } from 'common/constants/common';
import { ContactInfoDisplaySectionProps } from './types';

const ContactInfoDisplaySection: React.FC<ContactInfoDisplaySectionProps> = ({ contactInfo }) => {
    return (
        <div className="field-group-wrapper">
            <div className="field-group">
                <DisplayValue id="contactInfoAddress1" placeholder="Address 1" value={contactInfo?.address1} />
                <DisplayValue id="contactInfoAddress2" placeholder="Address 2" value={contactInfo?.address2} />
                <DisplayValue id="contactInfoCity" placeholder="City" value={contactInfo?.city} />
                <DisplayValue id="contactInfoState" placeholder="State/Province" value={contactInfo?.state?.name} />
                <DisplayValue id="contactInfoZip" placeholder="Zip" value={contactInfo?.zip} />
            </div>
            <div className="field-group">
                {contactInfo?.phones?.map &&
                    contactInfo.phones.map((phone, index) => (
                        <div className={`phone-wrapper ${index === 0 ? 'main-item' : ''}`}>
                            <DisplayValue
                                key={index}
                                id={`contactInfoMobilePhone${index}`}
                                placeholder={`${phone.type === 'CellPhone' ? 'Cell' : 'Home'} phone`}
                                value={phone.number}
                                mask={maskConstants.PHONE_NUMBER}
                            />
                        </div>
                    ))}
                <DisplayValue id="contactInfoEmail" placeholder="Email" value={contactInfo?.email} />
                <DisplayValue id="contactInfoInternalEmail" placeholder="Internal Email" value={contactInfo?.internalEmail} />
            </div>
        </div>
    );
};

export default ContactInfoDisplaySection;
