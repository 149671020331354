import { Action, AnyAction, ThunkAction } from '@reduxjs/toolkit';
import { IContactRole } from 'common/services/api/contact-role/types';

export const SET_CONTACT_ROLES = 'SET_CONTACT_ROLES';

export type ThunkResult<R> = ThunkAction<R, IContactRoleState, null, AnyAction>;

export interface IContactRoleState {
    readonly data: IContactRole[];
}

export interface ISetContactRolesAction extends Action<'SET_CONTACT_ROLES'> {
    contactRoles: IContactRole[];
}

export type ContactRoleActionTypes = ISetContactRolesAction;
