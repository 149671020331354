import React, { useRef } from 'react';
import StringMask from 'string-mask';
import './display-value.scss';
import { IDisplayValueProps } from './types';

const DisplayValue = ({
    placeholder,
    value,
    iconClass = null,
    customClass = '',
    mask = '',
    id = null,
    children = null,
    onClick = null,
}: IDisplayValueProps) => {
    const formatter = useRef(new StringMask(mask, { reverse: true }));

    const renderValue = () => {
        if (children) {
            return children;
        }

        if (mask && value) {
            return formatter.current.apply(value + '');
        }

        return value;
    };

    const displayValue = (
        <div id={id} className={`view-field ${customClass ? customClass : ''}`} onClick={onClick}>
            <span className="base-input-label floated">{placeholder}</span>
            <div className="view-value">{renderValue()}</div>
        </div>
    );

    if (iconClass) {
        return (
            <div className={`display-value__wrapper`}>
                <div className={`display-value__icon`}>
                    <span className={`icon ${iconClass}`}></span>
                </div>
                {displayValue}
            </div>
        );
    }

    return displayValue;
};

export default DisplayValue;
