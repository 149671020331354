import React, { useMemo } from 'react';
import './patient-enrollment-history.scss';
import { DateTimeHelper } from 'common/helpers/date-time-helper';
import { dateTimeConstants } from 'common/constants/common';
import { ColDef } from 'ag-grid-community/dist/lib/entities/colDef';
import { CellClickedEvent } from 'ag-grid-community/dist/lib/events';
import AgTable from 'common/components/ag-table/ag-table';
import { LocalTableRepository } from 'common/helpers/repository/local-table-repository';
import { ExtentedDefaultTableProps } from 'common/constants/types';

export const PatientEnrollmentHistory = ({ storePrefix = '', extendGridOptions }: ExtentedDefaultTableProps) => {
    const tableRepository = new LocalTableRepository(`${storePrefix}-external-table`, 'v1');

    const gridOptions = useMemo(
        () => ({
            ...extendGridOptions,
            onCellClicked: (event: CellClickedEvent) => {
                if (extendGridOptions?.onCellClicked) extendGridOptions?.onCellClicked(event);
            },
            columnDefs: extendGridOptions?.columnDefs ?? enrollmentHistoryColumnDefs,
            defaultColDef: {
                resizable: true,
                sortable: false,
                filterable: false,
            },
            frameworkComponents: {
                ...extendGridOptions?.frameworkComponents,
            },
        }),
        [extendGridOptions]
    );

    return (
        <>
            <div className="main-info-wrapper ag-table-wrapper enrollment-history-list">
                <AgTable
                    clientOptions={{ rowData: extendGridOptions.rowData }}
                    clientSide
                    gridOptions={gridOptions}
                    repository={tableRepository}
                />
            </div>
        </>
    );
};
const enrollmentHistoryColumnDefs: ColDef[] = [
    {
        field: 'entity',
        headerName: 'Customer',
        cellRenderer: 'cellLinkRender',
        valueFormatter: (props: any) => props?.value?.name,
        width: 350,
    },
    {
        field: 'externalPatientId',
        headerName: 'Patient ID',
        width: 220,
    },
    {
        field: 'startDate',
        headerName: 'Start Date',
        valueFormatter: (props: any) => DateTimeHelper.format(props.value, dateTimeConstants.MM_DD_YYYY, true),
        width: 220,
    },
    {
        field: 'endDate',
        headerName: 'Termination Date',
        valueFormatter: (props: any) => DateTimeHelper.format(props.value, dateTimeConstants.MM_DD_YYYY, true),
        width: 220,
    },
];
