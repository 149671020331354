import React, { useState } from 'react';
import Tab from 'common/components/tabs/tab';
import Tabs from 'common/components/tabs/tabs';
import DocumentsTab from '../document-management/documents-tab/documents-tab';
import DocumentRulesTab from './document-rules-tab/document-rules-tab';
import SecureData from 'common/components/secure-data/secure-data';
import { PermissionType } from 'common/models/permission-type';

const DocumentManagement = () => {
    const [isAddDocumentDialogOpen, setIsAddDialogOpen] = useState(false);
    const [isAddDocumentRuleDialogOpen, setIsAddDocumentRuleDialogOpen] = useState(false);

    const newDocumentButton = { text: 'New Document', onClick: () => setIsAddDialogOpen(true) };
    const newDocumentRuleButton = { text: 'New Rule', onClick: () => setIsAddDocumentRuleDialogOpen(true) };

    const [newButtonOptions, setNewButtonOptions] = useState(newDocumentButton);

    const handleDocumentsActive = () => {
        setNewButtonOptions(newDocumentButton);
    };

    const handleDocumentRuleHandle = () => {
        setNewButtonOptions(newDocumentRuleButton);
    };

    return (
        <div className="document-management-page table-page">
            <header>
                <div className="header-wrapper">
                    <div className="title-wrapper">
                        <h1>Document Management</h1>
                    </div>

                    <SecureData permission={PermissionType.DocumentManagement}>
                        <button className="create-rule-btn base-text-btn blue" id="createRuleBtn" onClick={newButtonOptions.onClick}>
                            {newButtonOptions.text}
                        </button>
                    </SecureData>
                </div>
            </header>
            <Tabs orientation="horizontal">
                <Tab linkClassName={'custom-link'} text="Documents" onActive={handleDocumentsActive}>
                    <DocumentsTab
                        isAddDocumentDialogOpen={isAddDocumentDialogOpen}
                        onCloseAddDocumentDialog={() => setIsAddDialogOpen(false)}
                    />
                </Tab>
                <Tab linkClassName={'custom-link'} text="Rules" onActive={handleDocumentRuleHandle}>
                    <DocumentRulesTab
                        isAddDocumentRuleDialogOpen={isAddDocumentRuleDialogOpen}
                        onCloseAddDocumentRuleDialog={() => setIsAddDocumentRuleDialogOpen(false)}
                    />
                </Tab>
            </Tabs>
        </div>
    );
};

export default DocumentManagement;
