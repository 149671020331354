import React, { useEffect, useState } from 'react';
import { IForm, IFormItem } from 'common/models/types';
import { ICreateUpdateUserRequest } from 'common/services/api/user/types';
import FieldSet from 'common/components/field-set/field-set';
import InputField from 'common/components/field/input-field';
import CheckBoxField from 'common/components/field/checkbox-field';
import roleService from 'common/services/api/role/role-service';
import DropdownOption from 'common/models/dropdown-option';
import InfiniteSelect from 'common/components/infinite-select/infinite-select';

// TO-DO: Set tab indexes on the form
const AddEditUserForm = ({ data: userRequest, errors, onChange, isEdit }: IForm<ICreateUpdateUserRequest>) => {
    const [rolesOptions, setRolesOptions] = useState([]);

    useEffect(() => {
        roleService.getAll().then((options) => {
            setRolesOptions(options.map((item) => new DropdownOption(item.id, item.name)));
        });
    }, []);

    const handleOnChangeLockoutEnabled = (data: IFormItem) => {
        onChange(data);
    };

    return (
        <>
            <div className="edit-form">
                <FieldSet errors={errors} name="firstName" customClass="edit-field required-field-star">
                    <InputField
                        id="firstName"
                        disabled={isEdit}
                        placeholder="First Name"
                        name="firstName"
                        value={userRequest.firstName}
                        onChange={onChange}
                    />
                </FieldSet>
                <FieldSet errors={errors} name="lastName" customClass="edit-field required-field-star">
                    <InputField
                        disabled={isEdit}
                        id="lastName"
                        placeholder="Last Name"
                        name="lastName"
                        value={userRequest.lastName}
                        onChange={onChange}
                    />
                </FieldSet>
                <FieldSet errors={errors} name="email" customClass="edit-field required-field-star">
                    <InputField
                        disabled={isEdit}
                        id="email"
                        placeholder="Email address"
                        name="email"
                        value={userRequest.email}
                        onChange={onChange}
                    />
                </FieldSet>
                <FieldSet errors={errors} name="username" customClass="edit-field required-field-star">
                    <InputField
                        disabled={isEdit}
                        id="username"
                        placeholder="Login"
                        name="username"
                        value={userRequest.username}
                        onChange={onChange}
                    />
                </FieldSet>
                {!isEdit && (
                    <FieldSet errors={errors} name="legacyId" customClass="edit-field required-field-star">
                        <InputField
                            id="legacyId"
                            placeholder="Legacy ID"
                            name="legacyId"
                            value={userRequest.legacyId}
                            onChange={onChange}
                        />
                    </FieldSet>
                )}
                <FieldSet errors={errors} name="lockoutEnabled" customClass="edit-field required-field-star">
                    <CheckBoxField
                        id="lockoutEnabled"
                        placeholder="Lockout Enabled"
                        name="lockoutEnabled"
                        onChange={handleOnChangeLockoutEnabled}
                        value={userRequest?.lockoutEnabled}
                    ></CheckBoxField>
                </FieldSet>
                {!isEdit && (
                    <FieldSet errors={errors} name="rolesIds" customClass="edit-field required-field-star">
                        <InfiniteSelect
                            items={rolesOptions}
                            multiselect={true}
                            label="Role"
                            value={rolesOptions.filter((item) => userRequest.rolesIds.some((id) => id === item.value))}
                            onChange={(item) => onChange({ name: 'rolesIds', value: item.map((i: DropdownOption) => i.value) })}
                        ></InfiniteSelect>
                    </FieldSet>
                )}
            </div>
        </>
    );
};

export default React.memo(AddEditUserForm);
