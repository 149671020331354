import React, { useState, useEffect, useMemo } from 'react';
import RequestCreateForm from './request-create-form';
import { ICreateRequestForm, ICreateRequestInfo } from 'common/services/api/requests/types';
import { toLowerFirstSymbol } from 'common/helpers/validation-helper';
import RequestService from 'common/services/api/requests/request-service';
import useFormSidebar from 'common/hooks/use-form-sidebar';
import { IFormSidebar, ILabelOptions } from 'common/models/types';
import RightSideBarForm from 'common/components/right-side-bar/right-side-bar-form';
import { ErrorCodes } from 'common/constants/error-codes';
import { PATIENT_ID_POPULATED, PATIENT_ALREADY_EXIST_FOR_CUSTOMER } from 'features/request-page/request-validation-messages';
import { useIsUserType } from 'common/hooks/use-is-user-type';
import { useSelector } from 'react-redux';
import { SourceType } from 'common/constants/source-type';

const errorConfigs = [
    { errorCode: ErrorCodes.CUSTOMER_DOES_NOT_SET, error: PATIENT_ID_POPULATED, field: 'agencyId' },
    { errorCode: ErrorCodes.PATIENT_ALREADY_EXISTS_FOR_CUSTOMER, error: PATIENT_ALREADY_EXIST_FOR_CUSTOMER, field: 'patientId' },
];

const RequestCreateSidebar = ({ onSave, onClose }: IFormSidebar<ICreateRequestInfo, any>) => {
    const { isExternal } = useIsUserType();
    const entityId = useSelector((state: any) => state.auth.entity?.id);

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const [data, error, handleChangeData, handleCancelClick, handleSaveClick, setData, setErrorData] = useFormSidebar<
        ICreateRequestForm & ILabelOptions
    >(
        {
            therapyTypeIds: [],
            source: isExternal ? SourceType.CustomerPortal : null,
            customerId: isExternal ? entityId : null,
            facilityId: null,
            populationId: null,
            languageId: null,
            internalReferralSourceId: null,
            externalReferralSourceId: null,
            caseManagerId: null,
            patientId: null,
            patientAgencyCode: null,
            position: null,
            specialInstructions: null,
            zipCode: null,
            address1: null,
            address2: null,
            city: null,
            stateId: null,
            countyId: null,
            zip: null,
        },
        {
            onClose,
            onSave: (data) => {
                return onSave({
                    ...data,
                    address: {
                        address1: data.address1,
                        address2: data.address2,
                        city: data.city,
                        stateId: data.stateId,
                        countyId: data.countyId,
                        zip: data.zip,
                    },
                });
            },
            errorConfigs: errorConfigs,
        }
    );
    const [requiredFields, setRequiredFields] = useState([]);

    const isAddressTouched = useMemo(() => {
        return data.address1 || data.city || data.countyId;
    }, [data.address1, data.city, data.countyId]);

    const generateAddressClassName = useMemo(() => {
        if (isAddressTouched) {
            return 'edit-field required-field-star';
        } else {
            setErrorData((prevError: any) => ({ ...prevError, address: null }));

            return 'edit-field';
        }
    }, [isAddressTouched, setErrorData]);

    useEffect(() => {
        RequestService.getSettings(data.customerId).then((data) => {
            if (data?.setting?.requiredFieldKeys) {
                setRequiredFields(data.setting.requiredFieldKeys.map((x: string) => toLowerFirstSymbol(x)));
            }
        });
    }, [data.customerId]);

    return (
        <div className="create-request">
            <RightSideBarForm title="Create Request" onCancel={handleCancelClick} onSave={handleSaveClick}>
                <RequestCreateForm
                    data={data}
                    errors={error}
                    onChange={handleChangeData}
                    requiredFields={requiredFields}
                    options={{
                        generateAddressClassName,
                    }}
                />
            </RightSideBarForm>
        </div>
    );
};

export default RequestCreateSidebar;
