import React from 'react';
import './button.scss';
import { ButtonProps } from './types';

const Button = (props: React.DetailedHTMLProps<React.ButtonHTMLAttributes<HTMLButtonElement>, HTMLButtonElement> & ButtonProps) => {
    const { type = 'button', onClick, children, size = 'medium', className = '', disabled, color = '', id } = props;
    const classes = `${className} ${color} ${size}`;

    return (
        <button id={id} type={type} onClick={onClick} disabled={disabled} className={classes}>
            {children}
        </button>
    );
};

export default Button;
