import FieldList from 'common/components/field-list/field-list';
import FieldSet from 'common/components/field-set/field-set';
import DropdownAutocomplete from 'common/components/field/dropdown/dropdown-autocomplete';
import InputField from 'common/components/field/input-field';
import NumberField from 'common/components/field/number-field';
import PaginationAutocomplete from 'common/components/field/pagination-autocomplete/pagination-autocomplete';
import PhoneInput from 'common/components/phone-input/phone-input';
import { ConverterHelper } from 'common/helpers/converter-helper';
import DropdownOption from 'common/models/dropdown-option';
import { IFormItem, IOption } from 'common/models/types';
import { ICounty } from 'common/services/api/county/types';
import EntityService from 'common/services/api/entity/entity-service';
import { StateService } from 'common/services/api/state/state-service';
import { generalValidationRules } from 'common/validation/general-validation-rules';
import React, { useCallback, useEffect, useState } from 'react';
import { IEntityBasicInfoFormProps } from './types';
import { entityTypeKey } from 'common/constants/entity-type';

const EntityBasicInfoForm: React.FC<IEntityBasicInfoFormProps> = ({ entityBasicInfo, options, errors, onChange, getTabIndex }) => {
    const [countySearchTerm, setCountySearchTerm] = useState('');
    const [parentSearchTerm, setParentSearchTerm] = useState('');
    const [entityParents, setEntityParents] = useState<DropdownOption[]>([]);
    const isDoctorOffice = entityBasicInfo?.functionLinks?.some((type: any) => type?.legalFunction?.key === entityTypeKey.DO);
    const isSchool = entityBasicInfo?.functionLinks?.some((type: any) => type?.legalFunction?.key === entityTypeKey.SCHOOL);

    useEffect(() => {
        if (!isDoctorOffice) {
            onChange({ name: 'npiNumber', value: null });
        }
    }, [isDoctorOffice]);

    useEffect(() => {
        if (!isSchool) {
            onChange({ name: 'dbn', value: null });
        }
    }, [isSchool]);

    const getEntityParentOptions = useCallback((): Promise<IOption[]> => {
        return EntityService.getEntityParentOptions(
            entityBasicInfo.id,
            entityBasicInfo.functionLinks?.map((link) => link.legalFunction?.id),
            null,
            null,
            null
        );
    }, [entityBasicInfo.id, entityBasicInfo.functionLinks]);

    useEffect(() => {
        getEntityParentOptions().then((options) => {
            setEntityParents(options.map((x) => new DropdownOption(x.id, x.name)));
        });
    }, [entityBasicInfo.functionLinks, getEntityParentOptions]);

    const determineParentOptions = useCallback(() => {
        return parentSearchTerm
            ? entityParents.filter((i) => i.label.toLowerCase().includes(parentSearchTerm.toLowerCase()))
            : entityParents;
    }, [parentSearchTerm, entityParents]);

    return (
        <>
            <h3>Basic Info</h3>
            <div className="edit-entity-form edit-form outline-none" tabIndex={getTabIndex()}>
                <FieldSet name="name" errors={errors} customClass="edit-field required-field-star">
                    <InputField placeholder="Name" name="name" onChange={onChange} value={entityBasicInfo.name} tabIndex={getTabIndex()} />
                </FieldSet>
                <FieldList
                    name={'functionLinks'}
                    isDragNDrop={false}
                    startTabIndex={getTabIndex()}
                    values={[...entityBasicInfo.functionLinks]}
                    defaultValue={{ legalFunction: null, department: null }}
                    addButtonText="Add Type"
                    onChange={(values: any[]) => {
                        onChange({ name: 'functionLinks', value: values });
                    }}
                    renderInput={(value: any, index: number, startTabIndex) => (
                        <div className="function-wrapper">
                            <FieldSet
                                name="functionLinks"
                                errors={errors}
                                customClass="edit-field required-field-star state-autocomplete"
                                renderError={(error: any) => error[index]?.functionId && error[index]?.functionId}
                            >
                                <PaginationAutocomplete
                                    fetchData={EntityService.getFunctionOptions}
                                    convertFunction={ConverterHelper.functionConvert}
                                    id={`function${index}`}
                                    defaultValue={value.legalFunction?.name}
                                    placeholder="Type"
                                    cleanable={false}
                                    name="function"
                                    tabIndex={startTabIndex + index}
                                    hasError={
                                        errors &&
                                        errors['functionLinks'] &&
                                        errors['functionLinks'][index] &&
                                        errors['functionLinks'][index].functionId
                                            ? undefined
                                            : false
                                    }
                                    onDelete={() => {
                                        const links = [...entityBasicInfo.functionLinks];
                                        links[index] = {
                                            ...entityBasicInfo.functionLinks[index],
                                            legalFunction: undefined,
                                            linkNumberOfBeds: undefined,
                                        };
                                        onChange({ name: 'functionLinks', value: links });
                                        onChange({ name: 'parent', value: null });
                                    }}
                                    onSelect={(item) => {
                                        const links = [...entityBasicInfo.functionLinks];
                                        links[index] = {
                                            ...entityBasicInfo.functionLinks[index],
                                            legalFunction: {
                                                id: item.value,
                                                name: item.label,
                                                key: item.key,
                                                isNumberOfBedsRequired: item.isNumberOfBedsRequired,
                                            },
                                            linkNumberOfBeds: undefined,
                                        };
                                        onChange({ name: 'functionLinks', value: links });
                                        onChange({ name: 'parent', value: null });
                                    }}
                                />
                            </FieldSet>
                            {entityBasicInfo.functionLinks[index].legalFunction?.isNumberOfBedsRequired && (
                                <FieldSet
                                    name="functionLinks"
                                    errors={errors}
                                    customClass="edit-field"
                                    renderError={(error: any) =>
                                        error[index]?.linkNumberOfBeds?.numberOfBeds && error[index]?.linkNumberOfBeds.numberOfBeds[0]
                                    }
                                >
                                    <NumberField
                                        classic
                                        id="numberOfBeds"
                                        maxLength={generalValidationRules.numberOfBeds.maxLength}
                                        placeholder="No Of Beds"
                                        name="numberOfBeds"
                                        hasError={
                                            errors &&
                                            errors['functionLinks'] &&
                                            errors['functionLinks'][index] &&
                                            errors['functionLinks'][index].linkNumberOfBeds
                                                ? undefined
                                                : false
                                        }
                                        onChange={(item: IFormItem) => {
                                            const links = [...entityBasicInfo.functionLinks];
                                            links[index] = {
                                                ...entityBasicInfo.functionLinks[index],
                                                linkNumberOfBeds: {
                                                    ...entityBasicInfo.functionLinks[index].linkNumberOfBeds,
                                                    numberOfBeds: item.value || undefined,
                                                },
                                            };

                                            onChange({ name: 'functionLinks', value: links });
                                        }}
                                        value={entityBasicInfo.functionLinks[index].linkNumberOfBeds?.numberOfBeds || undefined}
                                        tabIndex={startTabIndex + index + 1}
                                    />
                                </FieldSet>
                            )}
                        </div>
                    )}
                />
                {isDoctorOffice && (
                    <FieldSet name="npiNumber" errors={errors} customClass="edit-field">
                        <NumberField
                            id="npiNumber"
                            classic
                            maxLength={generalValidationRules.npiNumber.maxLength}
                            placeholder="NPI"
                            name="npiNumber"
                            tabIndex={getTabIndex(entityBasicInfo?.functionLinks?.length * 2)}
                            onChange={onChange}
                            value={entityBasicInfo.npiNumber}
                        />
                    </FieldSet>
                )}
                {isSchool && (
                    <FieldSet name="DBN" errors={errors} customClass="edit-field">
                        <InputField
                            placeholder="DBN"
                            name="dbn"
                            onChange={onChange}
                            maxLength={generalValidationRules.dbn.maxLength}
                            value={entityBasicInfo.dbn}
                            tabIndex={getTabIndex()}
                        />
                    </FieldSet>
                )}
                <FieldSet name="parentEntity" errors={errors} customClass="edit-field state-autocomplete">
                    <DropdownAutocomplete
                        tabIndex={getTabIndex()}
                        items={determineParentOptions()}
                        label="Parent"
                        name="parentEntity"
                        value={entityParents.find((x) => x.value === entityBasicInfo.parent?.id)}
                        onDelete={() => onChange({ name: 'parent', value: undefined })}
                        onSelect={(item: DropdownOption) => {
                            if (item) {
                                onChange({ name: 'parent', value: { id: item.value, name: item.label } });
                            }
                        }}
                        onChange={setParentSearchTerm}
                    />
                </FieldSet>
                <h3>Address Info</h3>
                <FieldSet name="address1" errors={errors} customClass="edit-field required-field-star">
                    <InputField
                        placeholder="Address 1"
                        name="address1"
                        onChange={onChange}
                        value={entityBasicInfo.address1}
                        tabIndex={getTabIndex()}
                    />
                </FieldSet>
                <FieldSet name="address2" errors={errors} customClass="edit-field">
                    <InputField
                        placeholder="Address 2"
                        name="address2"
                        onChange={onChange}
                        value={entityBasicInfo.address2}
                        tabIndex={getTabIndex()}
                    />
                </FieldSet>
                <div className="row-two-column">
                    <FieldSet name="city" errors={errors} customClass="edit-field required-field-star">
                        <InputField
                            placeholder="City"
                            maxLength={generalValidationRules.city.maxLength}
                            name="city"
                            onChange={onChange}
                            value={entityBasicInfo.city}
                            tabIndex={getTabIndex()}
                        />
                    </FieldSet>
                    <FieldSet name="stateId" errors={errors} customClass="edit-field required-field-star state-autocomplete">
                        <PaginationAutocomplete
                            cleanable={false}
                            fetchData={new StateService().getAll}
                            convertFunction={ConverterHelper.stateConvert}
                            id="states"
                            defaultValue={entityBasicInfo.state?.name}
                            placeholder="State"
                            name="stateId"
                            tabIndex={getTabIndex()}
                            onDelete={() => onChange({ name: 'state', value: undefined })}
                            onSelect={(item) => onChange({ name: 'state', value: { id: item.value, name: item.label } })}
                        />
                    </FieldSet>
                </div>
                <div className="row-two-column">
                    <FieldSet name="countyIds" errors={errors} customClass="edit-field county">
                        <DropdownAutocomplete
                            tabIndex={getTabIndex()}
                            items={
                                countySearchTerm
                                    ? options.counties.filter((county) =>
                                          county.label.toLowerCase().includes(countySearchTerm.toLowerCase())
                                      )
                                    : options.counties
                            }
                            label="County"
                            multiselect
                            searchPlaceholder={'Search County'}
                            value={options.counties.filter((countyOption) => {
                                const county = entityBasicInfo.counties.find((county: ICounty) => county.id === countyOption.value);
                                if (county) {
                                    countyOption.id = county.legalEntityCountyId;

                                    return true;
                                }

                                return false;
                            })}
                            onChange={setCountySearchTerm}
                            onSelect={(item: DropdownOption[]) =>
                                onChange({
                                    name: 'counties',
                                    value: item.map((i) => {
                                        return { id: i.value, name: i.label, legalEntityCountyId: i.id };
                                    }),
                                })
                            }
                        />
                    </FieldSet>
                    <FieldSet name="zip" errors={errors} customClass="edit-field required-field-star">
                        <NumberField
                            id="zip"
                            classic
                            maxLength={generalValidationRules.zip.maxLength}
                            placeholder="Zip"
                            name="zip"
                            onChange={onChange}
                            value={entityBasicInfo.zip}
                            tabIndex={getTabIndex()}
                        />
                    </FieldSet>
                </div>
                <FieldSet name="phone" errors={errors} customClass="edit-field">
                    <PhoneInput
                        placeholder="Phone"
                        className="phone"
                        onChange={(number: any) => onChange({ name: 'phone', value: number })}
                        value={entityBasicInfo.phone}
                        definedTabIndex={getTabIndex()}
                    />
                </FieldSet>
            </div>
        </>
    );
};

export default EntityBasicInfoForm;
