import { InfiniteServerClient } from 'common/helpers/http-clients';
import { IStaffingAgency } from './types';

export class StaffingAgencyService {
    getAll(): Promise<IStaffingAgency[]> {
        return InfiniteServerClient.get<IStaffingAgency[]>('/staffing-agencies/options').then(
            (response) => {
                return response.data;
            },
            (error) => {
                throw error;
            }
        );
    }
}

export default new StaffingAgencyService();
