export enum ProfileStatusType {
    Applied = 'Applied',
    InProcess = 'In process',
    AwaitingReview = 'Awaiting Review',
    Approved = 'Approved',
    Pending = 'Pending',
}

export const ProfileStatusDescription = new Map<ProfileStatusType, string>([
    [ProfileStatusType.Applied, 'Applied'],
    [ProfileStatusType.Approved, 'Approved'],
    [ProfileStatusType.InProcess, 'In Process'],
    [ProfileStatusType.AwaitingReview, 'Awaiting Review'],
    [ProfileStatusType.Pending, 'Pending'],
]);
