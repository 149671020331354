import React, { useState } from 'react';
import NoteList from 'common/components/note-list/note-list';
import { NoteTabProps } from './types';
import CreateNoteDialog from 'common/components/create-note-dialog/create-note-dialog';
import { ICreateNoteRequest } from 'common/models/create-note-request';
import therapistNoteService from 'common/services/api/therapist-note/therapist-note-service';
import noteService from 'common/services/api/note/note-service';
import { useDispatch } from 'react-redux';
import { refreshNoteStatus } from 'app/store/notes/action-creators';

const NoteTab: React.FC<NoteTabProps> = ({ therapistId, onSearchInputChange, searchData }) => {
    const dispach = useDispatch();
    const [isNewNoteDialogOpen, setIsNewNoteDialogOpen] = useState(false);
    const [forceUpdateTable, setForceUpdateTable] = useState<boolean>(false);

    const handleClickOnNewNoteButton = () => {
        setForceUpdateTable(false);
        setIsNewNoteDialogOpen(true);
    };

    const handleClickOnCloseNewNoteDialog = () => {
        setIsNewNoteDialogOpen(false);
    };

    const handleClickOnSaveNote = (request: ICreateNoteRequest) => {
        return therapistNoteService.create(therapistId, request).then((_) => {
            handleClickOnCloseNewNoteDialog();
            setForceUpdateTable(true);
            dispach(refreshNoteStatus());
        });
    };

    const handleClickOnCompleteNote = (id: number) => {
        noteService.complete(id).then(() => {
            setForceUpdateTable(true);
            dispach(refreshNoteStatus());
        });
    };

    return (
        <div className="table-page">
            <NoteList
                title="by Communication"
                onSearchTermChange={onSearchInputChange}
                url={`therapists/${therapistId}/notes`}
                onCompleteNoteClick={handleClickOnCompleteNote}
                forceUpdate={forceUpdateTable}
                onNewClick={handleClickOnNewNoteButton}
                searchData={searchData}
            />
            {isNewNoteDialogOpen && (
                <CreateNoteDialog onCancel={handleClickOnCloseNewNoteDialog} onSave={handleClickOnSaveNote}></CreateNoteDialog>
            )}
        </div>
    );
};

export default React.memo(NoteTab);
