import React, { useCallback, useEffect, useState } from 'react';
import './_styles.scss';
import { MapWrapper } from '../../common/components/map/map-wrapper/map-wrapper';
import TherapistMapFilter from '../../common/components/map/therapist-map-filter/therapist-map-filter';
import ResultCounter from '../../common/components/map/result-counter/result-counter';
import PageHeader from 'common/components/page-header/page-header';
import MapTopFilter from '../../common/components/map/map-top-filter/map-top-filter';
import { MapInfoWindow } from '../../common/components/map/map-info-window/map-info-window';
import { TherapistInfo } from './components/therapist-info/therapist-info';
import useTherapistMap from 'common/hooks/use-therapist-map/use-therapist-map';
import DataPagination from 'common/components/data-pagination/data-pagination';
import { RequestInfo } from 'common/components/map/map-info-window/request-info/request-info';
import MapCluster from 'common/components/map/map-cluster/map-cluster';
import { isRequestData, isTherapistData, toMapPoint } from 'common/helpers/map-helper';
import mapService from 'common/services/api/map/map-service';
import { DisplayAddressType, MapType, IMapPoint, IMapPointResponse } from 'common/services/api/map/types';
import { PlacementInfo } from 'common/components/map/map-info-window/placement-info/placement-info';
import { TherapistMapFilterWrapper } from 'common/components/map/therapist-map-filter/therapist-map-filter-wrapper';
import { RequestType } from '../../common/constants/types';
import { ITherapistMapPageProps } from './types';
import ToggleViewMode from 'common/components/toggle-view-mode/toggle-view-mode';
import { DisplayMode } from 'common/components/toggle-view-mode/types';
import googleGeocodingService from 'common/services/google-geocoding-service';
import { toast } from 'react-toastify';

const TherapistsMapPage: React.FC<ITherapistMapPageProps> = ({ onListClick, defaultFilters, defaultAddress }) => {
    const [windowInfo, setWindowInfoData] = useState<IMapPoint | null>();

    const fetchFunction = useCallback(async (searchModel) => {
        const response: any = await mapService.searchInRadius(searchModel);
        const responsePlacements: any = await mapService.searchInRadiusPlacements(searchModel);

        const result = [...response, ...responsePlacements];

        return Promise.resolve(result);
    }, []);

    const [
        result,
        searchModel,
        handleCenterChange,
        handleMilesChange,
        handleDisplayTypeChange,
        handleTherapistFilterChange,
        handleExport,
        handleClearFilters,
    ] = useTherapistMap({
        fetchFunction: fetchFunction,
        exportFunction: mapService.exportTherapistsOnMap,
        mapType: MapType.Main,
    });

    const handleClickOnMarker = (item: IMapPoint) => {
        setWindowInfoData(item);
    };

    const handleClickOnCloseWindow = () => {
        setWindowInfoData(null);
    };

    const [getPoints, setPoints] = useState<IMapPoint[]>([]);
    const [hideMarker, setHideMarker] = useState<any>({});

    useEffect(() => {
        if (defaultFilters) {
            handleTherapistFilterChange({
                ...searchModel.filter,
                departments: defaultFilters.assignedDepartment,
                subDepartments: defaultFilters.assignedSubDepartment,
                employmentStatuses: defaultFilters.employmentStatus,
                complianceStatuses: defaultFilters.complianceStatus,
            });
        }
    }, [defaultFilters]);

    useEffect(() => {
        if (defaultAddress) {
            googleGeocodingService.geocode('', '', defaultAddress).then(
                (position) => {
                    handleCenterChange(position, defaultAddress);
                },
                (errorMessage) => toast.error(errorMessage)
            );
        }
    }, [defaultAddress, handleCenterChange]);

    useEffect(() => {
        setWindowInfoData(null);
        const points: IMapPointResponse[] = [...result];

        if (searchModel.center && !hideMarker.ClusterSearch && !hideMarker.Request) {
            const requestPoint: IMapPointResponse = {
                id: -1,
                latitude: searchModel.center.latitude,
                longitude: searchModel.center.longitude,
                type: DisplayAddressType.Request,
            };

            points.push(requestPoint);
        }

        setPoints(toMapPoint(points)?.filter((point: any) => !hideMarker[point.type]));
    }, [result, searchModel.center, hideMarker]);

    const getInfo = (point: IMapPoint, handleClickBack: () => void) => {
        let result: any = [];

        result = point.data.map((item, i) => {
            if (isTherapistData(item)) {
                return <TherapistInfo id={item.id} key={i} index={i} />;
            }

            if (isRequestData(item)) {
                return (
                    <RequestInfo
                        id={item.id}
                        key={i}
                        index={i}
                        type={item.type?.toLowerCase()?.startsWith('hc') ? RequestType.HomeCare : RequestType.NursingHome}
                    />
                );
            } else {
                return <PlacementInfo placement={item.data} type={item.type} key={i} index={i} />;
            }
        });

        return <DataPagination onBack={handleClickBack}>{result}</DataPagination>;
    };

    return (
        <div className="therapists-on-map-page edit-therapist-form">
            <PageHeader title="Providers on Map" />
            <MapTopFilter
                radius={searchModel.radius}
                onExportClick={handleExport}
                onCenterChange={handleCenterChange}
                onClearFilterClick={handleClearFilters}
                onRadiusChange={handleMilesChange}
                address={defaultAddress}
            >
                {onListClick && <ToggleViewMode activeMode={DisplayMode.Map} onListClick={onListClick} onMapClick={null} />}
            </MapTopFilter>
            <div className="map-wrapper">
                <TherapistMapFilterWrapper>
                    <TherapistMapFilter onChange={handleTherapistFilterChange} filter={searchModel.filter} />
                </TherapistMapFilterWrapper>
                <MapWrapper
                    points={getPoints}
                    center={searchModel.center}
                    radius={searchModel.radius}
                    onMarkerClick={handleClickOnMarker}
                    onDisplayTypeChange={handleDisplayTypeChange}
                    onManualDisplayTypeChange={setHideMarker}
                >
                    {windowInfo && (
                        <MapInfoWindow
                            onCloseClick={handleClickOnCloseWindow}
                            position={{ latitude: windowInfo.latitude, longitude: windowInfo.longitude }}
                        >
                            <MapCluster point={windowInfo} getType={getInfo} />
                        </MapInfoWindow>
                    )}
                </MapWrapper>
                <ResultCounter result={result} />
            </div>
        </div>
    );
};

export default TherapistsMapPage;
