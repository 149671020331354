import { Reducer } from '@reduxjs/toolkit';
import { DepartmentActionTypes, IDepartmentState } from './types';

const initialState: IDepartmentState = {
    data: undefined,
};

export const departmentReducer: Reducer<IDepartmentState, DepartmentActionTypes> = (state = initialState, action) => {
    switch (action.type) {
        case 'SET_DEPARTMENTS':
            return { ...state, data: action.departments };
        default:
            return state;
    }
};
