import { ILegalEntityRate } from 'common/services/api/rate/types';
import { IEnum } from '../models/types';

export const dateTimeConstants = {
    MMDDYYYY: 'MMDDYYYY',
    MM_DD_YYYY: 'MM/DD/YYYY',
    MMM_D_YYY: 'MMM D, YYYY',
    MM_DOT_DD_DOT_YYYY: 'MM.DD.YYYY',
    HH_MM_A: 'hh:mm A',
    HH_MM: 'hh:mm',
    H_A: 'h A',
    H_A_MMM_D: 'h A, MMM D',
};

export const maskConstants = {
    PHONE_NUMBER: '(999) 999-9999',
    SSN: '###-##-####',
    SENSITIVE_INFO: '********',
};

export const getEnumOptionTitle = (value: IEnum) => value.name ?? value;

export const getMoneyTitle = (value: IEnum) => `$${value}`;

export const getRate = (value: ILegalEntityRate) => {
    return (value.rate || value) && `$${value.rate ?? value}`;
};

export const MAIN = 'main';
export const ACTIVE = 'Active';
export const OTHER_REASON = 'Other';
export const PATIENT_NAME = 'patientName';
export const PARENT = 'Parent';
export const PATIENT_AGENCY_CODE = 'patientAgencyCode';
export const CUSTOMER = 'customer';
export const CUSTOMER_ID = 'customerId';
export const FACILITY = 'facility';
export const ENTITY = 'entity';
export const THERAPIST_NAME = 'therapistName';
export const MAX_WEEK_DAYS_COUNT = 7;
export const MIN_WEEK_DAYS_COUNT = 1;
export const MAX_NOTIFICATION_MESSAGE_LENGTH = 24;
