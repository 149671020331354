import { RedirectHelper } from 'common/helpers/redirect-helper';
import { INote } from 'common/models/note';
import noteService from 'common/services/api/note/note-service';
import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import NotificationItem from './notification-item';
import { INotificationCenterProps } from './types';

const NotificationCenter: React.FC<INotificationCenterProps> = ({ closeNotificationCenter }) => {
    const history = useHistory();

    const [notes, setNotes] = useState<INote[]>([]);

    useEffect(() => {
        noteService.getTodayNotes().then((response) => {
            setNotes(response);
        });
    }, []);

    const handleNoteOnClick = (note: INote) => {
        if (!note.entity) {
            throw new Error('Entity for note is not specified.');
        }

        closeNotificationCenter();

        history.push({ pathname: RedirectHelper.GetNotePagePathnameByEntity(note.entity) });
    };

    return (
        <div className="notification-center">
            <div className="header">
                <div className="body">
                    Notifications
                    <span className="new-count">{notes.length} new</span>
                </div>
                <div className="close-notification-center-btn" onClick={() => closeNotificationCenter()}>
                    &#10006;
                </div>
            </div>
            <div className="notifications-list">
                {notes.map((note, i) => (
                    <NotificationItem note={note} key={i} onClick={handleNoteOnClick} />
                ))}
            </div>
        </div>
    );
};

export default NotificationCenter;
