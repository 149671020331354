import React from 'react';
import DisplayValue from 'common/components/display-value/display-value';
import SecureData from 'common/components/secure-data/secure-data';
import { DateTimeHelper } from 'common/helpers/date-time-helper';
import { dateTimeConstants, maskConstants } from 'common/constants/common';
import { PermissionType } from 'common/models/permission-type';
import { labels } from 'common/constants/labels';
import { Card } from 'common/components/card';
import { GeneralInfoDisplaySectionProps } from './types';

const GeneralInfoDisplaySection: React.FC<GeneralInfoDisplaySectionProps> = ({ generalInfo, onEdit }) => {
    return (
        <>
            <Card className="therapist-details-card box-1" titleIcon="icon-person" title="Basic info" onEdit={onEdit}>
                <DisplayValue id="firstName" placeholder="First Name" value={generalInfo.firstName ?? '-'} />
                <DisplayValue id="lastName" placeholder="Last Name" value={generalInfo.lastName ?? '-'} />
                <DisplayValue id="middleInitial" placeholder={labels.middleNameInitial} value={generalInfo.middleName ?? '-'} />
                <SecureData permission={PermissionType.AccessTherapistSecureData} isMask={true}>
                    <DisplayValue
                        id="dob"
                        placeholder="DOB"
                        value={DateTimeHelper.format(generalInfo.dateOfBirth, dateTimeConstants.MM_DD_YYYY, true) ?? '-'}
                    />
                    <DisplayValue id="npi" placeholder="Npi Number" value={generalInfo.npiNumber ?? '-'} />
                </SecureData>
                <SecureData permission={PermissionType.AccessTherapistSecureData} isMask={true}>
                    <DisplayValue id="ssn" placeholder="SSN" value={generalInfo.ssn} mask={maskConstants.SSN ?? '-'} />
                    <DisplayValue id="medicaid" placeholder="Medicaid Id" value={generalInfo.medicaidId ?? '-'} />
                </SecureData>
                <DisplayValue id="language" placeholder="Language" value={generalInfo.languages.map((i) => i.name).join(', ') ?? '-'} />
                <DisplayValue id="type" placeholder="Provider Type" value={generalInfo.disciplines.map((i) => i.name).join(', ') ?? '-'} />
                <DisplayValue
                    id="staffingAgency"
                    placeholder="Staffing Agency"
                    value={generalInfo.staffingAgency ? generalInfo.staffingAgency.name : '-'}
                />
                <DisplayValue id="referralSource" placeholder="Referral Source" value={generalInfo.referralSource.name ?? '-'} />
                <DisplayValue
                    id="displayInternalNote"
                    customClass="display-internal-note"
                    placeholder="General Note"
                    value={generalInfo.note ?? '-'}
                />
            </Card>
        </>
    );
};

export default GeneralInfoDisplaySection;
