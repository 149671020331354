import React, { useState } from 'react';
import FieldSet from 'common/components/field-set/field-set';
import InputField from 'common/components/field/input-field';
import NumberField from 'common/components/field/number-field';
import DateField from 'common/components/field/date-field';
import RightSideBar from 'common/components/right-side-bar/right-side-bar';
import TextAreaField from 'common/components/field/text-area-field';
import SecureData from 'common/components/secure-data/secure-data';
import { useLanguageData } from 'common/hooks/use-language-data';
import { therapistValidationRules } from 'common/validation/therapist-validation-rules';
import { generalValidationRules } from 'common/validation/general-validation-rules';
import DropdownOption from 'common/models/dropdown-option';
import { PermissionType } from 'common/models/permission-type';
import { labels } from 'common/constants/labels';
import { ConverterHelper } from 'common/helpers/converter-helper';
import PaginationAutocomplete from 'common/components/field/pagination-autocomplete/pagination-autocomplete';
import DropdownAutocomplete from 'common/components/field/dropdown/dropdown-autocomplete';
import { OptionField } from 'common/constants/option-fields';
import DisciplineAutocomplete from 'common/components/discipline-autocomplete/discipline-autocomplete';
import { IEditGeneralInfo } from 'common/services/api/therapist/types';
import { FieldValue } from 'common/models/field-value';
import ReferralSourceService from 'common/services/api/referral-source/referral-source-service';
import StaffingAgencyService from 'common/services/api/staffing-agency/staffing-agency-service';
import { GeneralInfoEditFormProps } from './types';

// TO-DO: Set tab indexes on the form
const GeneralInfoEditForm: React.FC<GeneralInfoEditFormProps> = ({ generalInfo, onSave, onCancel }) => {
    const [generalInfoData, setGeneralInfo] = useState<IEditGeneralInfo>({
        firstName: generalInfo.firstName,
        middleName: generalInfo.middleName,
        ssn: generalInfo.ssn,
        lastName: generalInfo.lastName,
        referralSourceId: generalInfo.referralSource.id,
        staffingAgencyId: generalInfo.staffingAgency ? generalInfo.staffingAgency.id : null,
        disciplineOptions: generalInfo.disciplines.map((i) => ConverterHelper.optionConvert(i)),
        disciplines: generalInfo.disciplines.map((i) => i.id),
        languages: generalInfo.languages.map((i) => i.id),
        npiNumber: generalInfo.npiNumber,
        medicaidId: generalInfo.medicaidId,
        dateOfBirth: generalInfo.dateOfBirth ? new Date(generalInfo.dateOfBirth) : null,
        note: generalInfo.note,
    });

    const [languageSearchTerm, setLanguageSearchTerm] = useState('');

    const languagesOptions = useLanguageData().map((item) => new DropdownOption(item.id, item.name));

    const [errorData, setErrorData] = useState({});

    const handleFieldChange = (data: FieldValue) => {
        setGeneralInfo((prevInfo) => ({ ...prevInfo, [data.name]: data.value }));
        setErrorData((prevError) => ({ ...prevError, [data.name]: undefined }));
    };

    const handleSave = () => {
        onSave({
            ...generalInfoData,
            disciplines: generalInfoData.disciplineOptions.map((item) => item.value),
        }).catch(setErrorData);
    };

    return (
        <div>
            <RightSideBar title="Edit General Info" onCancel={onCancel} onSave={handleSave}>
                <div className="edit-therapist-form">
                    <FieldSet name="firstName" errors={errorData} customClass="edit-therapist-field required-field-star">
                        <InputField
                            placeholder="First Name"
                            name="firstName"
                            onChange={handleFieldChange}
                            value={generalInfoData.firstName}
                        ></InputField>
                    </FieldSet>
                    <FieldSet name="lastName" errors={errorData} customClass="edit-therapist-field required-field-star">
                        <InputField
                            placeholder="Last Name"
                            name="lastName"
                            onChange={handleFieldChange}
                            value={generalInfoData.lastName}
                        ></InputField>
                    </FieldSet>
                    <FieldSet name="middleName" errors={errorData} customClass="edit-therapist-field">
                        <InputField
                            placeholder={labels.middleNameInitial}
                            name="middleName"
                            maxLength={therapistValidationRules.middleName.maxLength}
                            onChange={handleFieldChange}
                            value={generalInfoData.middleName}
                        ></InputField>
                    </FieldSet>
                    <SecureData permission={PermissionType.AccessTherapistSecureData}>
                        <FieldSet name="dateOfBirth" errors={errorData} customClass="edit-therapist-field">
                            <DateField
                                placeholder="DOB"
                                name="dateOfBirth"
                                onChange={handleFieldChange}
                                value={generalInfoData.dateOfBirth}
                            ></DateField>
                        </FieldSet>
                        <FieldSet name="ssn" errors={errorData} customClass="edit-therapist-field">
                            <NumberField
                                id="ssn"
                                format={generalValidationRules.ssn.format}
                                mask={generalValidationRules.ssn.mask}
                                placeholder="SSN"
                                name="ssn"
                                onChange={handleFieldChange}
                                value={generalInfoData.ssn}
                            ></NumberField>
                        </FieldSet>
                        <FieldSet name="npiNumber" errors={errorData} customClass="edit-therapist-field">
                            <NumberField
                                maxLength={generalValidationRules.npiNumber.maxLength}
                                placeholder="NPI Number"
                                name="npiNumber"
                                onChange={handleFieldChange}
                                value={generalInfoData.npiNumber}
                                allowNegative={false}
                            ></NumberField>
                        </FieldSet>
                        <FieldSet name="medicaidId" errors={errorData} customClass="edit-therapist-field">
                            <NumberField
                                maxLength={generalValidationRules.medicaidId.maxLength}
                                placeholder="Medicaid Id"
                                name="medicaidId"
                                onChange={handleFieldChange}
                                value={generalInfoData.medicaidId}
                                allowNegative={false}
                            ></NumberField>
                        </FieldSet>
                    </SecureData>
                    <FieldSet name="languages" errors={errorData} customClass="edit-therapist-field">
                        <DropdownAutocomplete
                            tabIndex=""
                            items={
                                languageSearchTerm
                                    ? languagesOptions.filter((therapyType) =>
                                          therapyType.label.toLowerCase().includes(languageSearchTerm.toLowerCase())
                                      )
                                    : languagesOptions
                            }
                            label="Language"
                            multiselect
                            searchPlaceholder={'Search Language'}
                            value={languagesOptions.filter((item) => generalInfoData.languages.some((id) => id === item.value))}
                            onChange={setLanguageSearchTerm}
                            onSelect={(item: DropdownOption[]) => handleFieldChange({ name: 'languages', value: item.map((i) => i.value) })}
                        />
                    </FieldSet>
                    <FieldSet name="disciplines" errors={errorData} customClass="edit-therapist-field required-field-star">
                        <DisciplineAutocomplete
                            label="Provider Type"
                            defaultSelectedLabel={null}
                            cleanable={false}
                            onChange={(items) => {
                                handleFieldChange({ name: 'disciplineOptions', value: items });
                                setErrorData((prevError) => ({ ...prevError, disciplines: undefined }));
                            }}
                            value={generalInfoData.disciplineOptions.map((i) => i.value)}
                        />
                    </FieldSet>
                    <FieldSet name="referralSourceId" errors={errorData} customClass="edit-therapist-field required-field-star">
                        <PaginationAutocomplete
                            cleanable={false}
                            fetchData={ReferralSourceService.getAll}
                            convertFunction={ConverterHelper.referralSourceConvert}
                            id="referralSourceId"
                            defaultValue={generalInfo.referralSource?.name}
                            placeholder="Referral Source"
                            name={OptionField.referralSource}
                            tabIndex=""
                            onDelete={() => handleFieldChange({ name: 'referralSourceId', value: undefined })}
                            onSelect={(item) => handleFieldChange({ name: 'referralSourceId', value: item.value })}
                        />
                    </FieldSet>
                    <FieldSet name="staffingAgencyId" errors={errorData} customClass="edit-therapist-field">
                        <PaginationAutocomplete
                            fetchData={StaffingAgencyService.getAll}
                            convertFunction={ConverterHelper.staffingAgencyConvert}
                            id="staffingAgencyId"
                            defaultValue={generalInfo.staffingAgency?.name}
                            placeholder="Staffing Agency"
                            name={OptionField.staffingAgency}
                            tabIndex=""
                            onDelete={() => handleFieldChange({ name: 'staffingAgencyId', value: undefined })}
                            onSelect={(item) => handleFieldChange({ name: 'staffingAgencyId', value: item.value })}
                        />
                    </FieldSet>
                    <FieldSet name="note" errors={errorData} customClass="edit-therapist-field internal-note">
                        <TextAreaField
                            placeholder="General Note"
                            name="note"
                            onChange={handleFieldChange}
                            value={generalInfoData.note}
                        ></TextAreaField>
                    </FieldSet>
                </div>
            </RightSideBar>
            <div className="right-sidebar-overlay"></div>
        </div>
    );
};

export default GeneralInfoEditForm;
