import React, { useEffect, useState } from 'react';
import Field from './field';
import NumberFormat, { NumberFormatValues } from 'react-number-format';
import { IField, IMoneyField } from './types';

const MoneyField = (props: IField & IMoneyField) => {
    const [isFocused, toggleFocus] = useState(false);
    const [value, setValue] = useState(props.value);
    const DecimalScale = props?.fixedDecimalScale ? false : true;

    useEffect(() => {
        setValue(props.value ?? '');
    }, [props.value]);

    const handleOnFocus = (e: React.ChangeEvent<HTMLInputElement>) => {
        toggleFocus(true);

        if (props.onFocus) {
            props.onFocus(e);
        }
    };

    const handleOnBlur = (e: React.ChangeEvent<HTMLInputElement>) => {
        toggleFocus(false);

        if (props?.onBlur) {
            props.onBlur(e);
        }
    };

    const handleOnKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
        if (props.onKeyDown) {
            props.onKeyDown(e);
        }
    };

    const handleChange = (values: NumberFormatValues) => {
        setValue(values.value);
        props.onChange({ value: values.value, name: props.name });
    };

    const isAllowed = (values: NumberFormatValues) => {
        if (props.maxValue) {
            return values.floatValue ? values.floatValue <= props.maxValue : true;
        }

        return true;
    };

    return (
        <Field {...props} isFocused={isFocused} customClass="money-field">
            <NumberFormat
                disabled={props.disabled}
                isAllowed={isAllowed}
                decimalScale={2}
                fixedDecimalScale={DecimalScale}
                name={props.name}
                className={props.inputClass}
                value={value}
                onKeyDown={handleOnKeyDown}
                displayType={'input'}
                thousandSeparator={true}
                prefix={'$'}
                onFocus={handleOnFocus}
                onBlur={handleOnBlur}
                onValueChange={handleChange}
            ></NumberFormat>
        </Field>
    );
};

export default MoneyField;
