import React, { useEffect, useState } from 'react';
import NotificationCenter from './notification-center';
import { INotificationBellProps } from './types';

const NotificationBell: React.FC<INotificationBellProps> = ({ isSideBarExpanded }) => {
    const [isNotificationCenterOpened, setNotificationCenterOpened] = useState<boolean>(false);

    useEffect(() => {
        if (!isSideBarExpanded) {
            setNotificationCenterOpened(false);
        }
    }, [isSideBarExpanded]);

    return (
        <div>
            <div
                className={`notification-bell ${isNotificationCenterOpened && 'active'}`}
                onClick={() => setNotificationCenterOpened(!isNotificationCenterOpened)}
            ></div>
            {isNotificationCenterOpened && <NotificationCenter closeNotificationCenter={() => setNotificationCenterOpened(false)} />}
        </div>
    );
};

export default NotificationBell;
