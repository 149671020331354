import Card from 'common/components/card/card';
import FieldListPhones from 'common/components/field-list/field-list-phones';
import FieldListEmails from 'common/components/field-list/fild-list-emails';
import FieldListOtherRoles from 'common/components/field-list/fild-list-other-roles';
import FieldSet from 'common/components/field-set/field-set';
import DropdownAutocomplete from 'common/components/field/dropdown/dropdown-autocomplete';
import InputField from 'common/components/field/input-field';
import { OTHER_REASON } from 'common/constants/common';
import { labels } from 'common/constants/labels';
import { useContactRolesData } from 'common/hooks/use-contact-roles-data';
import DropdownOption from 'common/models/dropdown-option';
import { IForm } from 'common/models/types';
import { IEntityContactForm } from 'common/services/api/entity-contact/type';
import React, { useState } from 'react';
import TextAreaField from 'common/components/field/text-area-field';

const EntityContactForm = ({ data, errors, onChange }: IForm<IEntityContactForm>) => {
    const { firstName, middleName, lastName, phones, emails, otherContactRoles, contactRoleIds, additionalRoleInformation } = data;
    const [contactRoleSearchTerm, setContactRoleSearchTerm] = useState('');

    const contactRoles = useContactRolesData().map((item) => new DropdownOption(item.id, item.role));
    const otherRoleId = contactRoles.find((item) => item.label === OTHER_REASON)?.value;

    return (
        <div className="entity-from outline-none" tabIndex={1}>
            <Card className="void-card pl-34" noBorder titleIcon="icon-info" title="Basic Info">
                <FieldSet name="firstName" errors={errors} customClass="entity-field required-field-star">
                    <InputField
                        id="firstName"
                        placeholder="First Name"
                        name="firstName"
                        onChange={onChange}
                        value={firstName}
                        tabIndex="1"
                    />
                </FieldSet>
                <FieldSet name="middleName" errors={errors} customClass="entity-field">
                    <InputField
                        placeholder={labels.middleNameInitial}
                        id="middleName"
                        name="middleName"
                        onChange={onChange}
                        value={middleName}
                        tabIndex="2"
                    />
                </FieldSet>
                <FieldSet name="lastName" errors={errors} customClass="entity-field required-field-star">
                    <InputField placeholder="Last Name" id="lastName" name="lastName" onChange={onChange} value={lastName} tabIndex="3" />
                </FieldSet>
                <FieldSet name="contactRoleIds" errors={errors} customClass="edit-therapist-field required-field-star">
                    <DropdownAutocomplete
                        tabIndex=""
                        items={
                            contactRoleSearchTerm
                                ? contactRoles.filter((county) => county.label.toLowerCase().includes(contactRoleSearchTerm.toLowerCase()))
                                : contactRoles
                        }
                        label="Roles"
                        cleanable={false}
                        multiselect
                        searchPlaceholder={'Search Roles'}
                        value={contactRoles.filter((item) => data.contactRoleIds.some((id) => id === item.value))}
                        onChange={setContactRoleSearchTerm}
                        onSelect={(item: DropdownOption[]) => {
                            onChange({ name: 'contactRoleIds', value: item.map((i) => i.value) });
                            if (!item.includes(otherRoleId)) {
                                onChange({ name: 'otherContactRoles', value: [] });
                            }
                        }}
                    />
                </FieldSet>
                <FieldSet name="additionalRoleInformation" errors={errors} customClass="edit-therapist-field additional-role-information">
                    <TextAreaField
                        maxLength={100}
                        placeholder="Additional Role Information"
                        name="additionalRoleInformation"
                        onChange={onChange}
                        value={additionalRoleInformation}
                    ></TextAreaField>
                </FieldSet>
                {contactRoleIds.includes(otherRoleId) && (
                    <FieldListOtherRoles data={otherContactRoles} errors={errors} onChange={onChange} />
                )}
            </Card>

            <Card className="void-card pl-34" noBorder titleIcon="icon-phone" title="Phones">
                <FieldListPhones data={phones} errors={errors} onChange={onChange} />
            </Card>

            <Card className="void-card pl-34" noBorder titleIcon="icon-email" title="Email Addresses">
                <FieldListEmails data={emails} errors={errors} onChange={onChange} />
            </Card>
        </div>
    );
};

export default EntityContactForm;
