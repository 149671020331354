import DisplayValue from 'common/components/display-value/display-value';
import { maskConstants } from 'common/constants/common';
import { ICellRendererParams } from 'ag-grid-community';
import React from 'react';

const PhoneRender = (props: ICellRendererParams) => {
    const phoneValue = props?.data?.phones;
    const mainPhone = phoneValue?.find((p: any) => p?.seq === 1);

    if (phoneValue?.length > 0 && mainPhone?.number) {
        return <DisplayValue placeholder="" value={mainPhone?.number} mask={maskConstants.PHONE_NUMBER} />;
    }
};

export default PhoneRender;
