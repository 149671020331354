import React from 'react';
import FocusTrap from 'focus-trap-react';
import { ISimilarDialogProps } from './types';

const SimilarDialog = ({
    existedEntities,
    transformer,
    allowSave = false,
    onCancel,
    onSave = undefined,
    saveButtonText = 'Save Anyway',
    title = 'Following Therapists with similar details were found',
}: ISimilarDialogProps) => {
    const renderSimilarTherapistItem = (existedEntity: any) => {
        const { title, body, url } = transformer(existedEntity); //NavigationRoutes.therapistDetailsRoute(id)

        return (
            <div className="similar-existing-entity-wrapper">
                <div className="similar-existing-entity">
                    <span className="similar-existing-entity-full-name">{title}</span>
                    <span className="similar-existing-entity-body">{body}</span>
                </div>
                <span className="similar-existing-entity-link" onClick={() => handleClickOnSimilarTherapistLink(url)}></span>
            </div>
        );
    };

    const handleClickOnSimilarTherapistLink = (url: string) => {
        const newWindow = window.open(url, '_blank');
        newWindow.open();
        newWindow.focus();
    };

    let saveButtonClassName = 'base-text-btn ';

    if (!allowSave) {
        saveButtonClassName += 'disabled';
    } else {
        saveButtonClassName += 'blue';
    }

    return (
        <FocusTrap>
            <div className="similar-existing-entity-dialog">
                <span className="similar-existing-entity-dialog-title">{title}</span>
                <div className="similar-existing-entity-dialog-body">
                    {existedEntities.map((existedEntity) => renderSimilarTherapistItem(existedEntity))}
                </div>
                <div className="similar-existing-entity-dialog-actions">
                    <button className="base-text-btn white" onClick={onCancel}>
                        Cancel
                    </button>
                    {saveButtonText && (
                        <button className={saveButtonClassName} onClick={onSave} disabled={!allowSave}>
                            {saveButtonText}
                        </button>
                    )}
                </div>
            </div>
        </FocusTrap>
    );
};

export default SimilarDialog;
