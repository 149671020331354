import React from 'react';
import InfiniteSelect from 'common/components/infinite-select/infinite-select';
import { usePopulationData } from 'common/hooks/use-population-data';
import DropdownOption from 'common/models/dropdown-option';
import { ComplianceStatuses } from 'models/enums/compliance-statuses';
import { EmploymentStatuses } from 'models/enums/employment-statuses';
import { TherapistFilterGroup } from '../therapist-filter-group/therapist-filter-group';
import { TherapistMapFiterProps } from './types';
import { DepartmentGroupAutocomplete } from 'common/components/department-group-autocomplete/department-group-autocomplete';
import DisciplineAutocomplete from 'common/components/discipline-autocomplete/discipline-autocomplete';
import { LanguageAutocomplete } from 'common/components/language-autocomplete/language-autocomplete';
import { TherapistStatus } from 'features/therapist-details-page/shared/constants';

const employmentStatuses = Object.values(EmploymentStatuses).map((x) => new DropdownOption(x.value, x.label));
const complianceStatuses = Object.values(ComplianceStatuses).map((x) => new DropdownOption(x.value, x.label));
const therapistStatuses = Object.values(TherapistStatus).map((x) => new DropdownOption(x.value, x.label));

const TherapistMapFilter: React.FC<TherapistMapFiterProps> = ({ onChange, filter }) => {
    const populationsOptions = usePopulationData().map((item) => new DropdownOption(item.id, item.name));

    const handleEmploymentStatusChange = (statuses: DropdownOption[]) => {
        onChange({ ...filter, employmentStatuses: statuses.map((i) => i.value) });
    };

    const handleComplianceStatusChange = (statuses: DropdownOption[]) => {
        onChange({ ...filter, complianceStatuses: statuses.map((i) => i.value) });
    };

    const handleDisciplineChange = (disciplines: DropdownOption[]) => {
        onChange({ ...filter, disciplines: disciplines.map((i) => i.value) });
    };

    const handleLanguageChange = (languages: DropdownOption[]) => {
        onChange({ ...filter, languages: languages.map((i) => i.value) });
    };

    const handlePopulationChange = (populations: DropdownOption[]) => {
        onChange({ ...filter, populations: populations.map((i) => i.value) });
    };

    const handleTherapistStatusChange = (statuses: DropdownOption[]) => {
        onChange({ ...filter, statuses: statuses.map((i) => i.value) });
    };

    const handleDepartmentChange = (departments: number[]) => {
        onChange({ ...filter, departments: departments });
    };

    const handleSubDepartmentChange = (subDepartments: number[]) => {
        onChange({ ...filter, subDepartments: subDepartments });
    };

    const handlePreferredDepartmentChange = (departments: number[]) => {
        onChange({ ...filter, preferredDepartments: departments });
    };

    const handlePreferredSubDepartmentChange = (subDepartments: number[]) => {
        onChange({ ...filter, preferredSubDepartments: subDepartments });
    };

    return (
        <>
            <TherapistFilterGroup title="Assignment">
                <DepartmentGroupAutocomplete
                    onDepartmentChange={handleDepartmentChange}
                    onSubDepartmentChange={handleSubDepartmentChange}
                    departments={filter?.departments || []}
                    subDepartments={filter?.subDepartments || []}
                />
                <InfiniteSelect
                    id="complianceStatus"
                    items={complianceStatuses}
                    label="Assignment status"
                    defaultMultipleSelectLabel="All"
                    multiselect={true}
                    value={complianceStatuses.filter((item) => filter?.complianceStatuses?.some((i) => i === item.value))}
                    onChange={handleComplianceStatusChange}
                ></InfiniteSelect>
                <InfiniteSelect
                    id="employmentStatus"
                    items={employmentStatuses}
                    label="Employment Status"
                    defaultMultipleSelectLabel="All"
                    multiselect={true}
                    value={employmentStatuses.filter((item) => filter?.employmentStatuses?.some((i) => i === item.value))}
                    onChange={handleEmploymentStatusChange}
                ></InfiniteSelect>
            </TherapistFilterGroup>

            <TherapistFilterGroup title="Provider Preferences">
                <DisciplineAutocomplete onChange={handleDisciplineChange} value={filter?.disciplines || []} />
                <LanguageAutocomplete onChange={handleLanguageChange} value={filter?.languages || []} />
                <InfiniteSelect
                    items={populationsOptions}
                    multiselect={true}
                    label="Population"
                    defaultMultipleSelectLabel="All"
                    value={populationsOptions.filter((item) => filter?.populations?.some((id) => id === item.value))}
                    onChange={handlePopulationChange}
                ></InfiniteSelect>
                <InfiniteSelect
                    id="therapistStatus"
                    items={therapistStatuses}
                    label="Status"
                    defaultMultipleSelectLabel="All"
                    multiselect={true}
                    value={therapistStatuses.filter((item) => filter?.statuses?.some((i) => i === item.value))}
                    onChange={handleTherapistStatusChange}
                ></InfiniteSelect>
                <DepartmentGroupAutocomplete
                    onDepartmentChange={handlePreferredDepartmentChange}
                    onSubDepartmentChange={handlePreferredSubDepartmentChange}
                    departments={filter?.preferredDepartments || []}
                    subDepartments={filter?.preferredSubDepartments || []}
                />
            </TherapistFilterGroup>
        </>
    );
};

export default React.memo(TherapistMapFilter);
