import DisplayValue from 'common/components/display-value/display-value';
import React from 'react';
import { TherapistRateTemplateTypesProps } from './types';
import './therapist-rate-from.scss';

const TherapistRateTemplateDepartaments: React.FC<TherapistRateTemplateTypesProps> = ({ rateTemplate }) => {
    return (
        <div className="therapist-rate-template">
            <DisplayValue id="discipline" placeholder="DEPARTMENT" value={rateTemplate?.department} />
            {rateTemplate?.subDepartments && <DisplayValue id="type" placeholder="SUB-DEPARTMENT" value={rateTemplate.subDepartments} />}
        </div>
    );
};

export default TherapistRateTemplateDepartaments;
