import { IEntityOption } from 'common/services/api/entity/types';
import { InfiniteServerClient } from 'common/helpers/http-clients';
import { IOption } from 'common/models/types';
import {
    ICreateFacilityRequest,
    IEntity,
    IFacilityDetails,
    IUpdateFacilityBasicInfoRequest,
    IFunction,
    IEntityDetails,
    IUpdateEntityRequest,
    IEntityBasicInfo,
} from './types';

export class EntityService {
    getOptions(type?: string): Promise<IEntityOption[]> {
        return InfiniteServerClient.get<IEntityOption[]>('/entities/options', { params: { type } }).then(
            (response) => {
                return response.data;
            },
            (error) => {
                throw error;
            }
        );
    }

    getOption(entityId: number): Promise<IEntityOption> {
        return InfiniteServerClient.get<IEntityOption>(`/entities/${entityId}/option`).then(
            (response) => {
                return response.data;
            },
            (error) => {
                throw error;
            }
        );
    }

    getFunctionOptions(term: string, skip: number, take: number): Promise<IFunction[]> {
        return InfiniteServerClient.get<IFunction[]>('/entities/functions/options', {
            params: {
                term,
                skip,
                take,
            },
        }).then(
            (response) => {
                return response.data;
            },
            (error) => {
                throw error;
            }
        );
    }

    getEntityParentOptions(currnetEntityId: number, functionIds: number[], term: string, skip: number, take: number): Promise<IOption[]> {
        return InfiniteServerClient.get<IEntity[]>('/entities/parents/options', {
            params: {
                currnetEntityId,
                functionIds: [...functionIds],
                term,
                skip,
                take,
            },
        }).then(
            (response) => {
                return response.data || [];
            },
            (error) => {
                throw error;
            }
        );
    }

    getEntityOptions(
        term?: string,
        skip?: number,
        take?: number,
        entityTypeKey?: string,
        parentId?: number,
        physicianId?: number
    ): Promise<IEntity[]> {
        return InfiniteServerClient.get<IEntity[]>('/entities/options', {
            params: { term, skip, take, entityTypeKey, parentId, physicianId },
        }).then(
            (response) => {
                return response.data;
            },
            (error) => {
                throw error;
            }
        );
    }

    getEntitiesTreeOptions(entityId: number, term: string, skip: number, take: number): Promise<IOption[]> {
        return InfiniteServerClient.get<IEntity[]>('/entities/tree/options', {
            params: {
                entityId,
                term,
                skip,
                take,
            },
        }).then(
            (response) => {
                return response.data || [];
            },
            (error) => {
                throw error;
            }
        );
    }

    createEntity(entity: IEntityBasicInfo): Promise<number> {
        return InfiniteServerClient.post('/entities', entity).then(
            (response) => {
                return response.data;
            },
            (error) => {
                throw error;
            }
        );
    }

    updateEntity(entity: IUpdateEntityRequest) {
        console.log(entity);

        return InfiniteServerClient.put(`entities`, entity).then(
            (response) => {
                return response.data;
            },
            (error) => {
                throw error;
            }
        );
    }

    createFacility(facility: ICreateFacilityRequest): Promise<number> {
        return InfiniteServerClient.post('/entities/facilities', facility).then(
            (response) => {
                return response.data;
            },
            (error) => {
                throw error;
            }
        );
    }

    getFacilityDetails(facilityId: number): Promise<IFacilityDetails> {
        return InfiniteServerClient.get(`entities/facilities/${facilityId}/details`).then(
            (response) => {
                return response.data;
            },
            (error) => {
                throw error;
            }
        );
    }

    getDetails(entityId: number): Promise<IEntityDetails> {
        return InfiniteServerClient.get(`entities/${entityId}/details`).then(
            (response) => {
                return response.data;
            },
            (error) => {
                throw error;
            }
        );
    }

    updateFacilityBasicInfo(facilityId: number, facility: IUpdateFacilityBasicInfoRequest) {
        return InfiniteServerClient.put(`entities/facilities/${facilityId}/basic-info`, facility).then(
            (response) => {
                return response.data;
            },
            (error) => {
                throw error;
            }
        );
    }

    setEntityStatus(id: number, status: string) {
        return InfiniteServerClient.put(`entities/${id}/statuses`, { status: status }).then(
            (response) => {
                return response.data;
            },
            (error) => {
                throw error;
            }
        );
    }
}

export default new EntityService();
