import React from 'react';
import { CardProps, ICardConfig } from './types';

const isEmpty = (value: any) => value === undefined || value === null || value === '' || value.length === 0;

const Card: React.FC<CardProps> = ({
    actionButtons,
    config,
    children,
    data = {},
    title,
    className,
    fullWidth,
    noBorder,
    defaultValue = '-',
    defaultConfig = {},
    titleIcon,
    iconInCard = 'icon-edit',
    iconText = 'Edit',
    onEdit,
}) => {
    if (data === null) data = {};

    if (!config && !children) return null;

    const list = config?.map((item) => {
        const {
            field,
            key,
            width,
            title,
            hideTitle,
            right,
            render,
            rightIcon,
            onClickRightIcon,
            className,
            onClick,
            hideIfEmpty,
            hide,
            renderEmpty,
        }: ICardConfig = {
            ...defaultConfig,
            ...item,
        };

        let value = data[field];

        if (render && (!isEmpty(value) || renderEmpty)) {
            value = render(value, data);
        }

        if ((isEmpty(value) && hideIfEmpty) || hide) {
            return null;
        }

        if (value !== null && typeof value === 'object' && !React.isValidElement(value)) {
            console.error(`${field} is object, please create a render functions for any object`);
            value = null;
        }

        const customClass = className && typeof className !== 'string' ? className(data[field], data) : className || '';

        return (
            <div
                className={`card__item ${value ? 'card__item--value' : 'card__item--no-value'} ${customClass}`}
                key={key || field}
                style={{ width: width ? width : null }}
                onClick={() => (onClick ? onClick(data[field], data) : null)}
            >
                <div className="card__item-body">
                    {!hideTitle && <div className="card__subtitle">{title || field}</div>}

                    <div className={`card__text ${className}`}>{!isEmpty(value) ? value : defaultValue}</div>
                </div>

                {!isEmpty(data[field]) &&
                    (right ||
                        (rightIcon &&
                            (onClickRightIcon ? (
                                <div className="card__item-icon clickable" onClick={() => onClickRightIcon(value, data)}>
                                    <i className={`icon ${rightIcon}`} />
                                </div>
                            ) : (
                                <div className="card__item-icon">
                                    <i className={`icon ${rightIcon}`} />
                                </div>
                            ))))}
            </div>
        );
    });

    return (
        <div className={`card ${className || ''}  ${fullWidth ? 'w-100' : ''} ${noBorder ? 'no-border' : ''} `}>
            {(titleIcon || title || onEdit) && (
                <div className="card__header">
                    <div className="card__title">
                        {titleIcon && <i className={`icon ${titleIcon}`} />}
                        <span>{title}</span>
                    </div>
                    {onEdit && (
                        <div className="card__edit" onClick={onEdit}>
                            <i className={`icon ${iconInCard}`} /> {iconText}
                        </div>
                    )}
                    {actionButtons && <div className="card__actions">{actionButtons}</div>}
                </div>
            )}
            <div className="card__body">
                {list}
                {children}
            </div>
        </div>
    );
};

export default React.memo(Card, (prevProps, nextProps) => {
    return prevProps.children === nextProps.children && prevProps.data === nextProps.data && prevProps.title === nextProps.title;
});
