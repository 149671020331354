import React, { useState, useEffect, useMemo } from 'react';
import RightSideBar from 'common/components/right-side-bar/right-side-bar';
import { ValidationHelper } from 'common/helpers/validation-helper';
import FocusTrap from 'focus-trap-react';
import PatientBasicInfoForm from '../patient-info-form/patient-basic-info-form';
import PatientContactInfoForm from '../patient-info-form/patient-contact-info-form';
import { usePopulationData } from 'common/hooks/use-population-data';
import DropdownOption from 'common/models/dropdown-option';
import { IPatientBasicInfo, IPatientContactInfo } from 'common/services/api/patient/types';
import { CreatePatientFormProps } from './types';
import { IFormItem } from 'common/models/types';
import { phoneTypes } from 'common/constants/patients/phone-types';
import { CommunicationHelper } from 'common/helpers/communication-helper';
import { SequenceHelper } from 'common/helpers/sequence-helper';
import EntityService from 'common/services/api/entity/entity-service';
import { IEntityOption } from 'common/services/api/entity/types';
import baseService from 'common/services/api/base/base-service';
import { OptionField } from 'common/constants/option-fields';
import { useTabIndex } from 'common/hooks/use-tab-index';
import SimilarDialog from 'common/components/similar-dialog/similar-dialog';
import { NavigationRoutes } from 'models/routes/navigation-routes';
import { IAttachPhysicianModel, IPhysiciansViewModel } from 'common/services/api/physicians/types';

const CreatePatientForm: React.FC<CreatePatientFormProps> = ({
    onClose,
    onSave,
    defaultState,
    dataForCreation = {},
    hideCustomer,
    showCopyAddressFromRequestBtn,
}) => {
    const [basicInfo, setBasicInfo] = useState<IPatientBasicInfo>({
        patientAgencyCode: dataForCreation?.patientAgencyCodeForCreation || null,
        firstName: null,
        lastName: null,
        middleName: null,
        countyId: null,
        zip: dataForCreation?.zipCodeForCreation,
        populationId: 0,
        stateId: defaultState?.id,
        stateName: defaultState?.name,
        address1: null,
        address2: null,
        city: null,
        specialInstructions: null,
        phones: [],
        emails: [],
        dateOfBirth: null,
        customerId: dataForCreation?.customerIdForCreation || 0,
        facilityId: dataForCreation?.facilityIdForCreation,
        physicians: [],
    });

    const [contactInfo, setContactInfo] = useState<IPatientContactInfo>({
        firstName: null,
        lastName: null,
        middleName: null,
        phones: [],
        emails: [],
        isEmergencyContact: true,
        isMainContact: true,
        relationship: undefined,
    });
    const { getTabIndex } = useTabIndex();
    const [basicInfoErrors, setBasicInfoErrors] = useState({});
    const [contactInfoErrors, setContactInfoErrors] = useState({});
    const [customer, setCustomer] = useState<IEntityOption>();
    const [facility, setFacility] = useState<IEntityOption>();

    const [relationships, setRelationships] = useState<DropdownOption[]>([]);

    const [trackStateChange, setTrackStateChange] = useState(true);

    const isAddressTouched = useMemo(() => {
        return basicInfo.address1 || basicInfo.address2 || basicInfo.city || basicInfo.stateId || basicInfo.countyId || basicInfo.zip;
    }, [basicInfo.address1, basicInfo.address2, basicInfo.city, basicInfo.stateId, basicInfo.countyId, basicInfo.zip]);

    const generateAddressClassName = useMemo(() => {
        if (!basicInfo?.facilityId || (basicInfo?.facilityId && isAddressTouched)) {
            return 'edit-field required-field-star';
        } else {
            setBasicInfoErrors((prevError) => ({
                ...prevError,
                address1: undefined,
                address2: undefined,
                city: undefined,
                stateId: undefined,
                countyId: undefined,
                zip: undefined,
            }));

            return 'edit-field';
        }
    }, [basicInfo?.facilityId, isAddressTouched]);

    const [similarTherapistsDialogData, setSimilarDialogData] = useState({
        isOpen: false,
        existedEntities: [],
        allowSave: false,
    });

    const handleOnSaveClick = (_?: any, saveAnyway?: boolean) => {
        const requestBasicInfo = { ...basicInfo } as any;
        const requestContactInfo = { ...contactInfo };

        requestBasicInfo.physicians = requestBasicInfo.physicians
            .map((physician: IPhysiciansViewModel) => {
                return {
                    id: physician?.id,
                    physicianId: physician?.physician?.id,
                    practiceId: physician?.practice?.id,
                };
            })
            .filter((physician: IAttachPhysicianModel) => physician?.physicianId);

        const basicInfoValidCommunicationInfo = CommunicationHelper.Validate({
            phones: basicInfo.phones,
            isPhonesRequiered: true,
            emails: basicInfo.emails,
            isEmailsRequiered: false,
        });

        requestBasicInfo.phones = basicInfoValidCommunicationInfo.phones;
        requestBasicInfo.emails = basicInfoValidCommunicationInfo.emails;

        const contactInfoValidCommunicationInfo = CommunicationHelper.Validate({
            phones: contactInfo.phones,
            isPhonesRequiered: true,
            emails: contactInfo.emails,
            isEmailsRequiered: false,
        });

        requestContactInfo.phones = contactInfoValidCommunicationInfo.phones;
        requestContactInfo.emails = contactInfoValidCommunicationInfo.emails;

        SequenceHelper.setSequenceItems(requestBasicInfo?.emails);
        SequenceHelper.setSequenceItems(requestBasicInfo?.phones);
        SequenceHelper.setSequenceItems(requestBasicInfo?.physicians);
        SequenceHelper.setSequenceItems(requestContactInfo?.emails);
        SequenceHelper.setSequenceItems(requestContactInfo?.phones);

        onSave({ basicInfo: requestBasicInfo, contactInfo: requestContactInfo, saveDespiteUniqueCheck: saveAnyway }).catch((error: any) => {
            switch (error?.response?.data?.errorCode) {
                case 'PATIENT_UNIQUE_CONSTRAINT_FAILED':
                    setSimilarDialogData({
                        isOpen: true,
                        existedEntities: error?.response?.data?.details.existedEntities,
                        allowSave: error?.response?.data?.details.allowSave,
                    });
                    break;
                default: {
                    const errors = ValidationHelper.handleErrorResponse(error?.response?.data);
                    if (errors['basicInfo']) {
                        setBasicInfoErrors(errors['basicInfo']);
                    }
                    if (errors['contactInfo']) {
                        setContactInfoErrors(errors['contactInfo']);
                    }
                    break;
                }
            }
        });
    };

    const populationsOptions = usePopulationData().map((item) => {
        return new DropdownOption(item.id, item.name);
    });

    useEffect(() => {
        baseService.getOptions(null, null, null, OptionField.relationship, null).then((response) => {
            setRelationships(response.map((i) => new DropdownOption(i.id, i.name)));
        });
    }, []);

    useEffect(() => {
        if (trackStateChange) {
            handleBasicInfoChange({ name: 'countyId', value: null });
        }
    }, [basicInfo.stateId]);

    useEffect(() => {
        if (dataForCreation?.customerIdForCreation) {
            EntityService.getOption(dataForCreation.customerIdForCreation).then((response) => {
                setCustomer(response);
            });
        }

        if (dataForCreation?.facilityIdForCreation) {
            EntityService.getOption(dataForCreation.facilityIdForCreation).then((response) => {
                setFacility(response);
            });
        }
    }, [dataForCreation]);

    const handleBasicInfoChange = (data: IFormItem) => {
        setBasicInfo((prevInfo) => ({ ...prevInfo, [data.name]: data.value }));
        setBasicInfoErrors((prevError) => ({ ...prevError, [data.name]: undefined }));
    };

    const handleContactInfoChange = (data: IFormItem) => {
        setContactInfo((prevInfo) => ({ ...prevInfo, [data.name]: data.value }));
        setContactInfoErrors((prevError) => ({ ...prevError, [data.name]: undefined }));
    };

    const handleCancelClick = () => {
        setBasicInfoErrors({});
        setContactInfoErrors({});
        onClose();
    };

    const handleCloseSimilarPatientDialog = () => {
        setSimilarDialogData({ isOpen: false, existedEntities: [], allowSave: false });
    };

    const handleClickOnSaveAnywayButton = () => {
        handleOnSaveClick(null, true);
    };

    const handleClickOnCopyAddressFromRequest = () => {
        setTrackStateChange(false);
        setBasicInfo((prevInfo) => ({
            ...prevInfo,
            stateId: dataForCreation.addressForCreation?.state?.id,
            stateName: dataForCreation.addressForCreation?.state?.name,
            address1: dataForCreation.addressForCreation?.address1,
            address2: dataForCreation.addressForCreation?.address2,
            city: dataForCreation.addressForCreation?.city,
            zip: dataForCreation.addressForCreation?.postalCode,
            countyId: dataForCreation.addressForCreation?.county?.id,
            countyName: dataForCreation.addressForCreation?.county?.name,
        }));
        setTimeout(() => {
            setTrackStateChange(true);
        }, 2000);
    };

    return (
        <div className="create-patients-form">
            <FocusTrap focusTrapOptions={{ allowOutsideClick: true }}>
                <RightSideBar title="Create Patient" onCancel={handleCancelClick} onSave={handleOnSaveClick}>
                    <div className="create-form">
                        <PatientBasicInfoForm
                            getTabIndex={getTabIndex}
                            basicInfo={basicInfo}
                            errors={basicInfoErrors}
                            onChange={handleBasicInfoChange}
                            options={{
                                phoneTypes: phoneTypes,
                                populationsOptions: populationsOptions,
                                customer: customer,
                                facility: facility,
                                isCreateForm: true,
                                hideCustomer,
                                countyName: basicInfo.countyName,
                                showCopyAddressFromRequestBtn,
                                copyAddressFromRequest: handleClickOnCopyAddressFromRequest,
                            }}
                            selectedState={defaultState}
                            generateAddressClassName={generateAddressClassName}
                        />
                        <PatientContactInfoForm
                            getTabIndex={getTabIndex}
                            contactInfo={contactInfo}
                            errors={contactInfoErrors}
                            onChange={handleContactInfoChange}
                            options={{ phoneTypes: phoneTypes, relationshipTypes: relationships || [] }}
                        />
                    </div>
                    {similarTherapistsDialogData.isOpen && (
                        <div className="similar-existing-entity-dialog-wrapper">
                            <SimilarDialog
                                title="'Following Patients with similar details were found'"
                                transformer={handleTransformation}
                                existedEntities={similarTherapistsDialogData.existedEntities}
                                allowSave={similarTherapistsDialogData.allowSave}
                                onCancel={handleCloseSimilarPatientDialog}
                                onSave={handleClickOnSaveAnywayButton}
                            />
                        </div>
                    )}
                </RightSideBar>
            </FocusTrap>
            <div className="right-sidebar-overlay"></div>
        </div>
    );
};

const handleTransformation = (patient: any) => ({
    title: patient.firstName + ' ' + patient.lastName,
    body: `${patient.address1 ?? ''} ${patient.address2 ?? ''} ${patient.city ?? ''} ${patient.zip ?? ''}`,
    url: NavigationRoutes.patientDetailsRoute(patient.id),
});

export default CreatePatientForm;
