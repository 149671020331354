import React, { useEffect, useState, useRef, MutableRefObject } from 'react';
import ReactDOM from 'react-dom';
import { ObjectHelper } from 'common/helpers/object-helper';
import useOnClickOutside from 'react-cool-onclickoutside';
import DropdownOption from 'common/models/dropdown-option';
import { usePopper } from 'react-popper';
import { customOffsetModifier } from '../infinite-popper/constants';
import DefaultOptionItem from './option-items/default-option-item/default-option-item';
import SelectAllOptionItem from './option-items/select-all-option-item/select-all-option-item';

export type InfiniteSelectProps = {
    id?: string;
    popper?: boolean;
    items?: DropdownOption[];
    definedTabIndex?: number;
    name?: string;
    label?: string;
    disabled?: boolean;
    value?: DropdownOption | DropdownOption[];
    hasError?: boolean;
    onChange?: (value: any, target: HTMLElement) => void;
    onDropdownOpen?: () => void;
    onDropdownClose?: () => void;
    onScrollToEnd?: () => void;
    onRenderSelected?: (selectedValues: any) => string | JSX.Element | JSX.Element[];
    onFormatOption?: (item: any) => string | JSX.Element | JSX.Element[];
    onClear?: () => void;
    onSelectAll?: (itemsToSelect: DropdownOption[]) => void;
    multiselect?: boolean;
    isHandleOnSave?: boolean;
    isDisplaySelectedCount?: boolean;
    defaultMultipleSelectLabel?: string;
    renderCustomOptions?: () => any[];
    customSelectedLabel?: string | JSX.Element;
    className?: string;
    onlyIcon?: JSX.Element;
    width?: string | number;
    showSelectedCounter?: boolean;
    iconClass?: string;
    icon?: any;
    customSelectedValues?: any;
};

const InfiniteSelect = ({
    id = undefined,
    items = [],
    definedTabIndex = undefined,
    popper = false,
    disabled = false,
    label = undefined,
    value = undefined,
    hasError = false,
    onChange = undefined,
    onDropdownOpen = undefined,
    onDropdownClose = undefined,
    onScrollToEnd = undefined,
    onClear = undefined,
    onSelectAll = undefined,
    multiselect = false,
    isHandleOnSave = false,
    isDisplaySelectedCount = false,
    defaultMultipleSelectLabel = undefined,
    renderCustomOptions = undefined,
    customSelectedLabel = undefined,
    onRenderSelected = undefined,
    onlyIcon = undefined,
    width = undefined,
    className,
    showSelectedCounter = false,
    iconClass = undefined,
    icon = undefined,
    onFormatOption = undefined,
    customSelectedValues = undefined,
}: InfiniteSelectProps) => {
    const [isOpen, setIsOpen] = useState(false);
    const [selectedValue, setSelectedValue] = useState<any>(multiselect ? [] : null);
    const optionListRef = useRef() as MutableRefObject<HTMLDivElement>;
    const wrapperRef = useRef() as MutableRefObject<HTMLDivElement>;
    const initialValue = useRef() as MutableRefObject<any>;
    const isFocused = useRef<boolean>();
    const [referenceElement, setReferenceElement] = React.useState(null);
    const [popperElement, setPopperElement] = React.useState(null);
    const { styles, attributes } = usePopper(referenceElement, popperElement, {
        placement: 'bottom-start',
        modifiers: [customOffsetModifier],
    });

    useEffect(() => {
        if (popperElement) optionListRef.current = popperElement;
    }, [popperElement, optionListRef]);

    useOnClickOutside(
        (event: any) => {
            if (wrapperRef.current.contains(event.target)) {
                return;
            }

            resetResultIfHandleOnSave();
            closeSelect();
        },
        { refs: [optionListRef] }
    );

    useEffect(() => {
        setSelectedValue(value);
    }, [value]);

    const handleClickOnSelect = () => {
        if (!isOpen) {
            if (isHandleOnSave) {
                initialValue.current = ObjectHelper.deepCopy(selectedValue);
            }

            openSelect();
        } else {
            closeSelect();
        }
    };

    const determineItem = (item: DropdownOption) => {
        if (!item) {
            return null;
        }

        if (multiselect) {
            if (!selectedValue || selectedValue.length === 0) {
                return Array.isArray(item) ? item : [item];
            }

            const existedItem = selectedValue.find((i: any) => i.value === item.value);

            if (existedItem) {
                return selectedValue.filter((i: any) => i.value !== item.value);
            } else {
                return [...selectedValue, item];
            }
        } else if (item?.value && Object.keys(item?.value).includes('target')) {
            item.value.target = referenceElement;
        }

        return item;
    };

    const resetResultIfHandleOnSave = () => {
        if (isHandleOnSave && isOpen) {
            setSelectedValue(initialValue.current);
        }
    };

    const handleFocus = () => {
        if (isFocused.current) {
            isFocused.current = false;

            return;
        }
        openSelect();
    };

    const handleBlur = () => {
        resetResultIfHandleOnSave();
        closeSelect();
    };

    const handleChangeItem = (item: DropdownOption) => {
        const selectedElement = determineItem(item);

        if (isHandleOnSave) {
            setSelectedValue(selectedElement);
        } else {
            onChange(selectedElement, referenceElement);
        }

        if (multiselect) {
            return;
        }

        closeSelect();
    };

    const handleHeaderCancel = () => {
        resetResultIfHandleOnSave();
        closeSelect();
    };

    const handleScrollToEnd = () => {
        if (!onScrollToEnd) {
            return;
        }

        const { scrollTop, scrollHeight, clientHeight } = optionListRef.current;
        if (scrollTop > 0 && Math.ceil(scrollTop + clientHeight) + 2 >= scrollHeight) {
            onScrollToEnd();
        }
    };

    const handleHeaderSave = () => {
        initialValue.current = ObjectHelper.deepCopy(selectedValue);
        onChange(selectedValue, referenceElement);
        closeSelect();
    };

    const openSelect = () => {
        setIsOpen(true);
        if (onDropdownOpen) {
            onDropdownOpen();
        }
    };

    const closeSelect = () => {
        setIsOpen(false);
        if (onDropdownClose && isOpen) {
            onDropdownClose();
        }
    };

    const setClass = () => {
        let baseClass = 'custom-select-wrapper';

        if (isOpen) {
            baseClass += ' open';
        }

        if (hasError) {
            baseClass += ' with-error';
        }

        return baseClass;
    };

    const getSelectedLabel = () => {
        if (customSelectedLabel) {
            return customSelectedLabel;
        }

        if (onlyIcon) {
            return onlyIcon;
        }

        if (!selectedValue) {
            return defaultMultipleSelectLabel ?? null;
        }

        if (multiselect) {
            return !selectedValue?.length
                ? defaultMultipleSelectLabel
                : isDisplaySelectedCount
                ? `${selectedValue.length} Selected`
                : onRenderSelected
                ? onRenderSelected(selectedValue)
                : selectedValue.map((x: any) => x.label).join(', ');
        }

        return onRenderSelected ? onRenderSelected(selectedValue) : selectedValue.label;
    };

    const isChecked = (currentValue: any) => {
        if (!selectedValue) {
            return false;
        }

        return selectedValue.some((i: any) => i.value === currentValue);
    };

    const renderBaseComponent = () => {
        const selectedLabel = getSelectedLabel();

        return (
            <div className="custom-select-display" onClick={handleClickOnSelect} ref={setReferenceElement}>
                {iconClass && <i className={`icon ${iconClass}`} />}
                {!!icon && icon}
                <div>
                    {showSelectedCounter && selectedValue && selectedValue.length > 0 && (
                        <span className="selected-counter">({selectedValue.length})</span>
                    )}
                    <span className={`custom-select-description ${selectedLabel ? 'floated' : ''}`}>{label}</span>
                    {selectedLabel && <span className="select-value">{selectedLabel}</span>}
                </div>
            </div>
        );
    };

    const renderOptions = () => {
        return items.map((item, index) =>
            multiselect ? (
                <DefaultOptionItem
                    item={item}
                    index={index}
                    multiselect={multiselect}
                    checked={isChecked(item.value)}
                    handleChange={handleChangeItem}
                    onFormatOption={onFormatOption}
                />
            ) : (
                <DefaultOptionItem
                    item={item}
                    index={index}
                    multiselect={multiselect}
                    handleChange={handleChangeItem}
                    onFormatOption={onFormatOption}
                />
            )
        );
    };

    const defaultWidth = onlyIcon && !width ? '200px' : width;

    const popperConfig = popper
        ? {
              ref: setPopperElement,
              style: { ...styles.popper, width: defaultWidth },
              ...attributes.popper,
              className: 'custom-dropdown option-list',
          }
        : { ref: optionListRef, className: 'option-list' };

    const optionList = (
        <div {...popperConfig} onScroll={handleScrollToEnd}>
            {multiselect && isHandleOnSave && (
                <div className="custom-select-options-wrapper-header">
                    <button id="selectCancelBtn" className="cancel-select-header-btn" onClick={() => handleHeaderCancel()}>
                        Cancel
                    </button>
                    <span></span>
                    <button id="selectSaveBtn" className="save-select-header-btn" onClick={() => handleHeaderSave()}>
                        Save
                    </button>
                </div>
            )}
            {onClear && (
                <div className="clear-btn-wrapper">
                    <button className="clear-btn" onClick={onClear} onMouseDown={(e) => e.preventDefault()}>
                        Clear Filters
                    </button>
                </div>
            )}
            {multiselect && onSelectAll && (
                <SelectAllOptionItem
                    multiselect={multiselect}
                    items={items}
                    selectedValues={customSelectedValues ?? selectedValue}
                    onSelectAll={onSelectAll}
                    onFormatOption={onFormatOption}
                />
            )}
            {renderCustomOptions ? renderCustomOptions() : renderOptions()}
        </div>
    );

    return (
        <div
            ref={wrapperRef}
            className={`custom-field-outer outline-none ${className || ''} ${onlyIcon ? 'only-icon' : ''} ${disabled ? 'disabled' : ''}`}
            onMouseDown={() => {
                isFocused.current = true;
            }}
            onFocus={handleFocus}
            onBlur={handleBlur}
            tabIndex={definedTabIndex}
        >
            <div className={setClass()} id={id}>
                {renderBaseComponent()}
                {isOpen && !disabled && popper && ReactDOM.createPortal(optionList, document.body)}
                {isOpen && !disabled && !popper && optionList}
            </div>
        </div>
    );
};

export default InfiniteSelect;
