import React, { useEffect } from 'react';
import { useState } from 'react';
import { NativeTimePickerProps } from '../native-time-picker/types';
import './simple-time-picker.scss';

const SimpleTimePicker: React.FC<NativeTimePickerProps> = ({ label, ...props }) => {
    const [value, setValue] = useState<string>((props.value as string) || '');

    useEffect(() => {
        setValue(props.value);
    }, [props.value]);

    const handleChange = (currentValue: string) => {
        setValue(currentValue);

        props.onChange({ value: currentValue, name: props.name });
    };

    return (
        <div className="simple-time-picker-wrapper">
            {label && <div className="simple-time-label">{label}</div>}
            <div className={`simple-time-picker-content ${!value && 'with-no-value'}`}>
                <input type="time" value={value} onChange={(event) => handleChange(event.target.value)} />
                <span className={`icon ${props.iconClass || ''}`}></span>
                {!label && !value && <span className="simple-time-picker-label">{props.placeholder}</span>}
            </div>
        </div>
    );
};

export default SimpleTimePicker;
