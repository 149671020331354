import React from 'react';
import { TotalCounter } from '../total-counter/total-counter';
import { WidgetHeaderProps } from './types';
import './widget-header.scss';

export const WidgetHeader: React.FC<WidgetHeaderProps> = ({ title, totalCount, children }) => {
    return (
        <div className="widget-header drag-this">
            <TotalCounter className="total-count" value={totalCount} title={title} />
            <div className="action-buttons">{children}</div>
        </div>
    );
};

export default React.memo(WidgetHeader);
