import { Dispatch } from '@reduxjs/toolkit';
import { SiteSettingsService } from 'common/services/api/settings/site-settings-service';
import { ISiteSettings } from 'common/services/api/settings/types';
import { ISetSitSettingsAction, ThunkResult } from './types';

export const setSettings = (payload: ISiteSettings): ISetSitSettingsAction => {
    return {
        settings: payload,
        type: 'SET_SETTINGS',
    };
};

export const fetchSiteSettings = (): ThunkResult<Promise<ISiteSettings>> => {
    return async (dispatch: Dispatch) => {
        const data = await new SiteSettingsService().getSettings();
        dispatch(setSettings(data));

        return data;
    };
};
