import { InfiniteServerClient } from 'common/helpers/http-clients';
import { IAvailableTherapistGridItemModel, IAvailableTherapistGridItemRow, IAvailableTherapistRequest } from './types';

class AvailableTherapistService {
    getList(requestData: IAvailableTherapistRequest): Promise<IAvailableTherapistGridItemRow[]> {
        const data = {
            ...requestData,
            populations: requestData.populations?.length > 0 ? requestData.populations.reduce((f, s) => `${f},${s}`) : null,
        };

        return InfiniteServerClient.get('full-fill/therapists/available', { params: data }).then(
            (response) => {
                return response.data.map((row: IAvailableTherapistGridItemModel) => this._transformToRows(row));
            },
            (error) => {
                throw error;
            }
        );
    }

    _transformToRows(row: IAvailableTherapistGridItemModel): IAvailableTherapistGridItemRow {
        return {
            ...row,
            disciplines: formatArrayForExport(row.disciplines.map((discipline) => discipline.name)),
            zipCodes: formatArrayForExport(row.zipCodes.map((zipCode) => zipCode.code)),
            populations: formatArrayForExport(row.populations.map((popultaion) => popultaion.name)),
            counties: formatArrayForExport(row.counties.map((county) => county.name)),
        };
    }
}

const formatArrayForExport = (values: string[]): string[] => {
    values.toString = (): string => {
        return values.join(', ');
    };

    return values;
};

export default new AvailableTherapistService();
