import React, { Component } from 'react';

class NavigationGroup extends Component {
    constructor(props) {
        super(props);

        this.state = {
            expanded: false,
            expandedSidebar: false,
        };

        this.toggle = this.toggle.bind(this);
    }

    componentDidUpdate(prevProps) {
        if (this.props.expandedSidebar !== prevProps.expandedSidebar) this.setState({ expandedSidebar: this.props.expandedSidebar });
    }

    toggle() {
        this.setState((prevState) => ({
            expanded: !prevState.expanded,
        }));
    }

    render() {
        const { text, classIcon, children, activeNavItemType, navItemType } = this.props;
        const setItemStatus = activeNavItemType === navItemType ? 'active' : '';

        return (
            children.length > 0 && (
                <div className="sidebar-navigation-group-item" onClick={this.props.onNavItemClick}>
                    <div className="group-name" onClick={this.toggle}>
                        {this.state.expandedSidebar ? (
                            <div className="navigation-main-item-wrapper">
                                <span className={`${classIcon} navigation-main-item ${setItemStatus}`}></span>
                                <span className="navigation-main-item-text">{text}</span>
                                <span className={this.state.expanded ? 'icon icon-down opened' : 'icon icon-down closed'}></span>
                            </div>
                        ) : (
                            <span className={`${classIcon} navigation-main-item ${setItemStatus}`}></span>
                        )}
                    </div>
                    {this.state.expandedSidebar && this.state.expanded && <div className="navigation-group-items">{children}</div>}
                </div>
            )
        );
    }
}

export default NavigationGroup;
