import AgTable from 'common/components/ag-table/ag-table';
import { IParamsWatcher } from 'common/components/ag-table/types';
import { DateTimeHelper } from 'common/helpers/date-time-helper';
import React, { useMemo, useRef } from 'react';
import { PastHistoryTabProps } from '../../types';

const PastHistoryTab: React.FC<PastHistoryTabProps> = () => {
    const paramsWatcher = useRef<IParamsWatcher>();

    const gridOptions = useMemo(
        () => ({
            columnDefs: [
                {
                    field: 'pos',
                    headerName: 'POS',
                    width: 150,
                },
                {
                    field: 'therapyType',
                    headerName: 'Therapy',
                    width: 150,
                },
                {
                    field: 'year',
                    headerName: 'School Year',
                    width: 150,
                },
                {
                    field: 'therapists',
                    headerName: 'Therapist name(s)',
                    width: 250,
                },
                {
                    field: 'startDate',
                    headerName: 'Start Date',
                    width: 150,
                    valueFormatter: (props: any) => DateTimeHelper.format(props.value, 'MM/DD/YYYY'),
                },
                {
                    field: 'endDate',
                    headerName: 'End Date',
                    width: 150,
                    valueFormatter: (props: any) => DateTimeHelper.format(props.value, 'MM/DD/YYYY'),
                },
                {
                    field: 'transmittals',
                    headerName: 'Transmittals Count',
                    width: 250,
                },
            ],
        }),
        []
    );

    return (
        <div className="main-info-wrapper ag-table-wrapper">
            <AgTable clientSide clientOptions={{ rowData: [] }} paramsWatcher={paramsWatcher} gridOptions={gridOptions} />
        </div>
    );
};

export default PastHistoryTab;
