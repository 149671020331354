import { IMessageItem, TherapistCommunicationType } from 'common/services/api/communication/types';
import { ICurrentSearchState } from 'features/therapist-details-page/communication-tab/types';

export type TherapistMessageListProps = {
    therapistId: number;
    searchData: ICurrentSearchState<IMessageItem>;
    onNewButtonClick: (type: TherapistCommunicationType) => void;
    onItemClick: (rowIndex: number, item: IMessageItem) => void;
    onDataChanged: (data: IMessageItem[]) => void;
    onSearchTermChange?: (value: string, limit: number) => Promise<void>;
    forceUpdate?: boolean;
};

export type TherapistMessageFilterTypeProps = {
    filter: ITherapistMessageTypeFilter;
    onFilterChange: (filter: ITherapistMessageTypeFilter) => void;
};

export interface ITherapistMessageFilter extends ITherapistMessageTypeFilter {
    search: string;
}

export interface ITherapistMessageTypeFilter {
    type?: TherapistCommunicationType;
}

export const DEFAULT_OPTIONS_COLUMN_ID = 'options-menu';
