import FieldSet from 'common/components/field-set/field-set';
import TextAreaField from 'common/components/field/text-area-field';
import InfiniteSelect from 'common/components/infinite-select/infinite-select';
import { OTHER_REASON } from 'common/constants/common';
import DropdownOption from 'common/models/dropdown-option';
import { IForm } from 'common/models/types';
import { INursingHomeRequestCloseReason } from 'common/services/api/nursing-home-requests/types';
import ReasonService from 'common/services/api/reason/reason-service';
import { ReasonType } from 'common/services/api/reason/types';
import React, { useEffect, useState } from 'react';
import { validationRules } from '../../validation-rules';

const NursingHomeRequestCloseForm = ({ data: reasonClose, errors, onChange }: IForm<INursingHomeRequestCloseReason>) => {
    const [requestCloseReasonOptions, setRequestCloseReasonOptions] = useState([]);

    useEffect(() => {
        ReasonService.getReasons(ReasonType.CloseRequet).then((data) => {
            setRequestCloseReasonOptions(data.map((item) => new DropdownOption(item.id, item.name)));
        });
    }, []);

    let isOtherReason = false;
    const closeCurrentValue: DropdownOption = requestCloseReasonOptions.find((item: DropdownOption) => {
        const result = item.value === reasonClose.reasonId;
        if (result && item.label === OTHER_REASON) isOtherReason = true;

        return result;
    });

    return (
        <>
            <div className="edit-form">
                <FieldSet errors={errors} name="reasonId" customClass="edit-field required-field-star">
                    <InfiniteSelect
                        id="reasonId"
                        label="Reason"
                        items={requestCloseReasonOptions}
                        value={closeCurrentValue}
                        onChange={(item) => {
                            onChange({ name: 'reasonId', value: item.value });
                        }}
                    />
                </FieldSet>
                {isOtherReason && (
                    <FieldSet name="comment" errors={errors} customClass="edit-field">
                        <TextAreaField
                            maxLength={validationRules.closingReason.maxLength}
                            id="comment"
                            placeholder="Closing reason"
                            name="comment"
                            onChange={(item: any) => {
                                onChange({ name: 'comment', value: item.value });
                            }}
                            value={reasonClose.comment}
                            tabIndex={13}
                        />
                    </FieldSet>
                )}
            </div>
        </>
    );
};

export default NursingHomeRequestCloseForm;
