import React, { useState } from 'react';
import RightSideBar, { SideBarMode } from 'common/components/right-side-bar/right-side-bar';
import { DateTimeHelper } from 'common/helpers/date-time-helper';
import NavigationSideBar from 'common/components/navigation-side-bar/navigation-side-bar';
import Avatar from 'common/components/Avatar/Avatar';
import { ViewMessageDialogProps } from './types';
import { TherapistCommunicationType } from 'common/services/api/communication/types';

const ViewMessageDialog = ({ message: email, onCancel, previousButton, nextButton }: ViewMessageDialogProps) => {
    const [collapse, setCollapsed] = useState(true);

    const handleClickOnCollapse = () => {
        setCollapsed((prev) => !prev);
    };

    const title = `${email.type === TherapistCommunicationType.Email ? 'Email' : 'SMS'} Details`;

    return (
        <div className="details-email">
            <RightSideBar title={title} onCancel={onCancel} mode={SideBarMode.VIEW} subtext={email?.subject}>
                <div className="therapist-email-details">
                    <div className={`therapist-email-detail ${collapse ? 'collapse' : 'show'}`}>
                        <div className="email-header">
                            <div className="details-email-header">
                                <span className="icon icon-down therapist-email-details-icon-down" onClick={handleClickOnCollapse}></span>
                                <Avatar user={email.sender} />
                                <div className="details-email-header-sender-send-to">
                                    <span className="email-sender">{`${email.sender.firstName} ${email.sender.lastName}`}</span>
                                    <div>
                                        <span className="email-send-to">To: {email.to}</span>
                                    </div>
                                </div>
                            </div>
                            <span className="email-created-date">{DateTimeHelper.format(email.createdAt, 'll LT')}</span>
                        </div>
                        {collapse && (
                            <div className="email-header email-header-message" dangerouslySetInnerHTML={{ __html: email.message }}></div>
                        )}
                    </div>
                    <div className="navigation-buttons">
                        <NavigationSideBar prevButton={previousButton} nextButton={nextButton} />
                    </div>
                </div>
            </RightSideBar>
        </div>
    );
};

export default ViewMessageDialog;
