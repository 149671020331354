export const validationRules = {
    decliningReason: {
        maxLength: 250,
    },
    reopeningReason: {
        maxLength: 250,
    },
    evalId: {
        maxLength: 250,
    },
};
