import React from 'react';
import OptionsButton from 'common/components/options-button/options-button';
import { ExportOptions } from 'settings.json';
import { trackPromise } from 'react-promise-tracker';
import { FileHelper } from 'common/helpers/file-helper';
import moment from 'moment';
import { dateTimeConstants } from 'common/constants/common';
import { ExportButtonProps } from '../type';

const ExportButton = ({
    title,
    onExportData,
    classNameList,
    classNameWrapper,
    paramsWatcher,
    options,
    gridRef,
    fileNameGenerator,
}: ExportButtonProps) => {
    const exportOptions = options ?? Object.values(ExportOptions);
    const defaultExportFileName = `${title}Export`;

    const onExportButtonClick = (exportType: string) => {
        const nameGenerator = fileNameGenerator ? fileNameGenerator : generateFileName;

        if (gridRef) {
            gridRef.current.api.exportDataAsExcel({
                headerRowHeight: 35,
                fileName: nameGenerator(exportType),
            });
        } else {
            trackPromise(
                onExportData(exportType, paramsWatcher.current).then((fileBlob: any) => {
                    FileHelper.downloadBlob(fileBlob, nameGenerator(exportType));
                })
            );
        }
    };

    const generateFileName = (type: string) => {
        return `${defaultExportFileName}_${moment().format(dateTimeConstants.MMDDYYYY)}.${type.toLowerCase()}`;
    };

    return (
        <OptionsButton
            className={'export-button base-text-btn export-btn white'}
            id={`${title}Export`}
            key={`optionButton_${`${title}Export`}`}
            classNameList={classNameList}
            classNameWrapper={classNameWrapper}
            optionsList={exportOptions}
            onOptionClick={(value) => onExportButtonClick(value)}
        />
    );
};

export default React.memo(ExportButton);
