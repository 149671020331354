import React, { useCallback } from 'react';
import { OverdueFilterOption, OverdueFilterProps } from './types';
import { IButtonToolbarItem } from 'common/components/button-toolbar/types';
import ButtonToolbar from 'common/components/button-toolbar/button-toolbar';

import './overdue-filter.scss';

export const OverdueFilter: React.FC<OverdueFilterProps> = ({
    onChange,
    className,
    value,
    options = [OverdueFilterOption.ALL, OverdueFilterOption.OVERDUE],
}) => {
    const handleFilterChange = (value: string) => {
        onChange(value as OverdueFilterOption);
    };

    const generateOptions = useCallback(() => {
        const result: IButtonToolbarItem[] = [];
        options.forEach((item) => {
            result.push({ value: item, title: item });
        });

        return result;
    }, [options]);

    return (
        <ButtonToolbar
            currentId={value + ''}
            className={`message-type-filter overdue-filter small-size ${className}`}
            buttons={generateOptions()}
            onClick={handleFilterChange}
        />
    );
};

export default React.memo(OverdueFilter);
