import React from 'react';
import { ICellRendererParams } from 'ag-grid-community';
import { ITherapistAssignment } from 'common/services/api/therapist-assignment/types';
import { dateTimeConstants } from 'common/constants/common';
import moment from 'moment';

export const EffectiveDatesRenderer = (props: ICellRendererParams) => {
    const data: ITherapistAssignment = props.data as ITherapistAssignment;
    let effectiveDate = null;
    let endDate = null;

    if (data.effectiveDate) {
        effectiveDate = moment(data.effectiveDate).format(dateTimeConstants.MM_DD_YYYY);
    }

    if (data.endDate) {
        endDate = moment(data.endDate).format(dateTimeConstants.MM_DD_YYYY);
    }

    if (!effectiveDate && !endDate) {
        return <></>;
    }

    return <>{`${effectiveDate ? effectiveDate : ''}-${endDate ? endDate : ''}`}</>;
};

export default EffectiveDatesRenderer;
