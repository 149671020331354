import React from 'react';
import { Route, Redirect } from 'react-router-dom';

export const PrivateRoute = ({ component: Component, isLoggedIn, hasAccess, isAvailable = true, ...rest }) => {
    if (!isAvailable) {
        return <></>;
    }

    return (
        <Route
            {...rest}
            render={(props) =>
                isLoggedIn ? (
                    <Component {...props} hasAccess={hasAccess} />
                ) : (
                    <Redirect to={{ pathname: isLoggedIn && '/login', state: { from: props.location } }} />
                )
            }
        />
    );
};
