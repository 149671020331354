export enum LeadStatus {
    Undefined,
    New,
    InProgress,
    Refused,
    Verified,
}

export interface ILead {
    id: number;
    appliedOn: Date;
    firstName: string;
    middleName?: string;
    lastName: string;
    phoneNumber: string;
    email: string;
    disciplines: string[];
    resumeId: number;
    isDuplicate: boolean;
    assignedTo: string;
    source: string;
    refusedOrVerifiedAt?: Date;
    status: LeadStatus;
}

export interface IVerifyLeadResponse {
    therapistId: number;
}

export interface ILeadStatusCount {
    new: number;
    verified: number;
    refused: number;
    inProgress: number;
}
