import React, { useRef } from 'react';
import './file-field.scss';
import { IFileField } from '../types';

const FileField = ({ placeholder, onChange, id, name, iconClass, uploadedFileName, isError, isDisabled = false }: IFileField) => {
    const inputRef = useRef<HTMLInputElement>();
    const handleChange = () => {
        if (!inputRef.current) {
            return;
        }
        const file = inputRef.current?.files?.[0];
        onChange({ value: file, name: name });
    };

    return (
        <div className={`input-file-wrapper ${isError && 'with-error'} `}>
            {iconClass && <span className={`icon ${iconClass}`}></span>}
            <label htmlFor={id} className={uploadedFileName && 'floated'}>
                {placeholder}
            </label>
            <input type="file" id={id} className="input-file" onChange={handleChange} ref={inputRef} disabled={isDisabled} />
            {uploadedFileName && <span className="input-file-name">{uploadedFileName}</span>}
        </div>
    );
};

export default FileField;
