import React, { useEffect, useMemo, useState, useCallback } from 'react';
import { HttpTransportType, HubConnectionBuilder, HubConnectionState, LogLevel } from '@microsoft/signalr';
import { ACCESS_TOKEN_KEY } from 'common/constants/local-storage-keys';
import './container.scss';
import noteService from 'common/services/api/note/note-service';
import { INotification, INotificationsContainerProps } from './types';
import NotificationToast from './components/notification-toast';
import { useHistory } from 'react-router-dom';
import { RedirectHelper } from 'common/helpers/redirect-helper';

const NotificationsContainer: React.FC<INotificationsContainerProps> = ({ loggedIn }) => {
    const history = useHistory();

    const [notificationsList, setNotificationsList] = useState<INotification[]>([]);

    const connection = useMemo(() => {
        if (loggedIn) {
            return new HubConnectionBuilder()
                .withUrl(`${process.env.REACT_APP_INFINITE_SERVER_BASE_URL}/hubs/notification`, {
                    transport: HttpTransportType.WebSockets,
                    accessTokenFactory: () => localStorage.getItem(ACCESS_TOKEN_KEY),
                    withCredentials: false,
                })
                .configureLogging(LogLevel.None)
                .withAutomaticReconnect()
                .build();
        }
    }, [loggedIn]);

    useEffect(() => {
        if (connection?.state === HubConnectionState.Disconnected) {
            connection.start().catch((e) => `HubConnection error: ${e}`);
            connection.on('Notify', (response) => {
                setNotificationsList((notifications) => [...notifications, response]);
            });
        }

        return () => {
            connection?.off('Notify');
        };
    }, [connection]);

    const closeNotification = useCallback((id: number) => {
        setNotificationsList((previousList) => previousList.filter((i) => i.id !== id));
    }, []);

    const handleClickNotificationDone = (id: number) => {
        noteService.complete(id).then(() => {
            closeNotification(id);
        });
    };

    const openNote = (notification: INotification) => {
        if (!notification.notificationEntity) {
            throw new Error('Entity for note is not specified.');
        }

        closeNotification(notification.id);
        history.push({ pathname: RedirectHelper.GetNotePagePathnameByEntity(notification.notificationEntity) });
    };

    return (
        <div className="notifications-container">
            {notificationsList.map((notification, i) => (
                <NotificationToast
                    hubConnection={connection}
                    notification={notification}
                    closeNotification={closeNotification}
                    handleClickNotificationDone={handleClickNotificationDone}
                    handleNotificationClick={openNote}
                    key={i}
                />
            ))}
        </div>
    );
};

export default React.memo(NotificationsContainer);
