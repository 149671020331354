import FieldList from 'common/components/field-list/field-list';
import FieldSet from 'common/components/field-set/field-set';
import DateField from 'common/components/field/date-field';
import InputField from 'common/components/field/input-field';
import NumberField from 'common/components/field/number-field';
import PaginationAutocomplete from 'common/components/field/pagination-autocomplete/pagination-autocomplete';
import TextAreaField from 'common/components/field/text-area-field';
import InfiniteSelect from 'common/components/infinite-select/infinite-select';
import PhoneTypeInput from 'common/components/phone-type-input/phone-type-input';
import { entityTypeKey } from 'common/constants/entity-type';
import { labels } from 'common/constants/labels';
import { OptionField } from 'common/constants/option-fields';
import { ConverterHelper } from 'common/helpers/converter-helper';
import DropdownOption from 'common/models/dropdown-option';
import baseService from 'common/services/api/base/base-service';
import CountyService from 'common/services/api/county/county-service';
import { ICounty } from 'common/services/api/county/types';
import EntityService from 'common/services/api/entity/entity-service';
import { IEntity } from 'common/services/api/entity/types';
import StateService from 'common/services/api/state/state-service';
import { generalValidationRules } from 'common/validation/general-validation-rules';
import { IPhone } from 'features/shared/types';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import { validationRules } from '../../validation-rules';
import { IPatientsBasicInfoFormProps } from './types';
import { dateTimeConstants } from 'common/constants/common';
import FieldListPhysicians from 'common/components/field-list/field-list-physicians';
import Button from 'common/components/Button/button';
import { useIsUserType } from 'common/hooks/use-is-user-type';

const PatientBasicInfoForm: React.FC<IPatientsBasicInfoFormProps> = ({
    basicInfo,
    options,
    errors,
    selectedState,
    generateAddressClassName,
    onChange,
    getTabIndex,
}) => {
    const [genders, setGenders] = useState<DropdownOption[]>([]);
    const { isExternal } = useIsUserType();

    useEffect(() => {
        baseService.getOptions(null, null, null, OptionField.gender, null).then((response) => {
            setGenders(response.map((item) => new DropdownOption(item.id, item.name)));
        });
    }, []);

    const fetchCountiesData = (term: string, skip: any, take: number): Promise<ICounty[]> => {
        if (basicInfo.stateId) {
            return CountyService.getByStateWithPagination(basicInfo.stateId, term, skip, take);
        } else {
            return new Promise<ICounty[]>(() => {});
        }
    };

    const getCustomerOptions = (term: string, skip: number, take: number): Promise<IEntity[]> => {
        return EntityService.getEntityOptions(term, skip, take, entityTypeKey.CHHA);
    };

    const getFacilityOptions = (term: string, skip: number, take: number): Promise<IEntity[]> => {
        return EntityService.getEntityOptions(term, skip, take, entityTypeKey.PS, isExternal && basicInfo?.customerId);
    };

    const newCustomer = useRef(null);
    const oldCustomer = useMemo(() => options?.customer?.name, []);
    const isNewCustomer = () => oldCustomer && newCustomer.current && oldCustomer !== newCustomer.current;

    return (
        <>
            <h3>Basic Info</h3>
            <div className="edit-form outline-none" tabIndex={getTabIndex()}>
                <FieldSet name="patientAgencyCode" errors={errors} customClass="edit-field required-field-star">
                    <InputField
                        maxLength={validationRules.patientAgencyCode.maxLength}
                        placeholder="Patient ID"
                        name="patientAgencyCode"
                        onChange={onChange}
                        value={basicInfo.patientAgencyCode}
                        tabIndex={getTabIndex()}
                    />
                </FieldSet>
                <FieldSet name="firstName" errors={errors} customClass="edit-field required-field-star">
                    <InputField
                        placeholder="First Name"
                        name="firstName"
                        onChange={onChange}
                        value={basicInfo.firstName}
                        tabIndex={getTabIndex()}
                    />
                </FieldSet>
                <FieldSet name="middleName" errors={errors} customClass="edit-field">
                    <InputField
                        maxLength={validationRules.middleName.maxLength}
                        placeholder={labels.middleNameInitial}
                        name="middleName"
                        onChange={onChange}
                        value={basicInfo.middleName}
                        tabIndex={getTabIndex()}
                    />
                </FieldSet>
                <FieldSet name="lastName" errors={errors} customClass="edit-field required-field-star">
                    <InputField
                        placeholder="Last Name"
                        name="lastName"
                        onChange={onChange}
                        value={basicInfo.lastName}
                        tabIndex={getTabIndex()}
                    />
                </FieldSet>
                <FieldSet name="dateOfBirth" errors={errors} customClass="edit-therapist-field">
                    <DateField
                        placeholder="DOB"
                        name="dateOfBirth"
                        onChange={onChange}
                        value={basicInfo.dateOfBirth}
                        tabIndex={getTabIndex()}
                    />
                </FieldSet>
                <FieldSet name="genderId" errors={errors} customClass="edit-field">
                    <InfiniteSelect
                        label="Birth Gender"
                        items={genders}
                        id="primaryInsuranceId"
                        name="primaryInsuranceId"
                        value={genders.find((item: DropdownOption) => {
                            return basicInfo.genderId === item.value;
                        })}
                        onChange={(item) => onChange({ name: 'genderId', value: item.value })}
                    />
                </FieldSet>
                {!options?.hideCustomer && (
                    <FieldSet errors={errors} name="customerId" customClass="edit-field required-field-star">
                        <PaginationAutocomplete
                            cleanable={false}
                            className="autocomplete-one-border"
                            fetchData={getCustomerOptions}
                            convertFunction={ConverterHelper.entityConvert}
                            id="customer"
                            defaultValue={options?.customer?.name}
                            placeholder="Customer"
                            name="customerId"
                            tabIndex={getTabIndex()}
                            onDelete={() => onChange({ name: 'customerId', value: undefined })}
                            onSelect={(item) => {
                                newCustomer.current = item.label;
                                if (!isNewCustomer()) {
                                    onChange({ name: 'startDate', value: null });
                                    onChange({ name: 'terminationDate', value: null });
                                }
                                onChange({ name: 'customerId', value: item.value });
                            }}
                        />
                    </FieldSet>
                )}
                {isNewCustomer() && (
                    <>
                        <FieldSet errors={errors} name="terminationDate" customClass="required-field-star">
                            <DateField
                                id="terminationDate"
                                placeholder={`Termination Date for ${oldCustomer}`}
                                name="terminationDate"
                                value={basicInfo.terminationDate}
                                onChange={onChange}
                                dateFormat={dateTimeConstants.MM_DD_YYYY}
                                maskFormat="##/##/####"
                                tabIndex={getTabIndex()}
                            ></DateField>
                        </FieldSet>
                        <FieldSet errors={errors} name="startDate" customClass="required-field-star">
                            <DateField
                                id="startDate"
                                placeholder={`Start Date with ${newCustomer.current}`}
                                value={basicInfo.startDate}
                                name="startDate"
                                onChange={onChange}
                                dateFormat={dateTimeConstants.MM_DD_YYYY}
                                maskFormat="##/##/####"
                                tabIndex={getTabIndex()}
                            ></DateField>
                        </FieldSet>
                    </>
                )}
                <FieldSet errors={errors} name="facilityId" customClass="edit-field">
                    <PaginationAutocomplete
                        className="autocomplete-one-border"
                        fetchData={getFacilityOptions}
                        convertFunction={ConverterHelper.entityConvert}
                        id="facility"
                        defaultValue={options?.facility?.name}
                        placeholder="Facility"
                        name="facilityId"
                        tabIndex={getTabIndex()}
                        onDelete={() => onChange({ name: 'facilityId', value: undefined })}
                        onSelect={(item) => onChange({ name: 'facilityId', value: item.value })}
                    />
                </FieldSet>

                <FieldList
                    name="phones"
                    isDragNDrop
                    values={basicInfo.phones}
                    startTabIndex={getTabIndex()}
                    defaultValue={{ type: options.phoneTypes[0].value, number: '' }}
                    addButtonText="Add Phone"
                    onChange={(values: any[]) => onChange({ name: 'phones', value: values })}
                    renderInput={(value: any, index: number, startTabIndex) => (
                        <FieldSet
                            name={'phones'}
                            errors={errors}
                            customClass={`edit-phone ${index === 0 ? 'main-item' : ''} required-field-star`}
                            renderError={(error: any) => error[index]?.number[0]}
                        >
                            <PhoneTypeInput
                                tabIndex={startTabIndex + 1}
                                errors={errors['phones']?.[index]}
                                phoneTypes={options.phoneTypes}
                                onChange={(value: IPhone) => {
                                    basicInfo.phones[index] = { ...value };
                                    onChange({ name: 'phones', value: [...basicInfo.phones] });
                                }}
                                value={value}
                            />
                        </FieldSet>
                    )}
                />
                <FieldList
                    name="emails"
                    isDragNDrop
                    values={basicInfo.emails}
                    startTabIndex={getTabIndex()}
                    defaultValue={{ email: '' }}
                    addButtonText="Add Email Address"
                    onChange={(values: any[]) => onChange({ name: 'emails', value: values })}
                    renderInput={(value: any, index: number, startTabIndex: number) => (
                        <FieldSet
                            name="emails"
                            errors={errors}
                            customClass={`edit-field email ${index === 0 ? 'main-item' : ''}`}
                            renderError={(error: any) => error[index]?.email && error[index]?.email[0]}
                        >
                            <InputField
                                placeholder="Email Address"
                                onChange={(data: any) => {
                                    basicInfo.emails[index] = { email: data.value };
                                    onChange({ name: 'emails', value: [...basicInfo.emails] });
                                }}
                                value={value.email}
                                tabIndex={startTabIndex + index}
                            />
                        </FieldSet>
                    )}
                />

                {options?.showCopyAddressFromRequestBtn && (
                    <Button id="copy-address-from-request-btn" onClick={options.copyAddressFromRequest} className="blue">
                        Copy address from request
                    </Button>
                )}

                <FieldSet name="address1" errors={errors} customClass={generateAddressClassName}>
                    <InputField
                        placeholder="Address 1"
                        name="address1"
                        onChange={onChange}
                        value={basicInfo.address1}
                        tabIndex={getTabIndex(basicInfo?.phones?.length + basicInfo?.emails?.length)}
                    />
                </FieldSet>
                <FieldSet name="address2" errors={errors} customClass="edit-field">
                    <InputField
                        placeholder="Address 2"
                        name="address2"
                        onChange={onChange}
                        value={basicInfo.address2}
                        tabIndex={getTabIndex()}
                    />
                </FieldSet>
                <div className="row-two-column">
                    <FieldSet name="city" errors={errors} customClass={generateAddressClassName}>
                        <InputField
                            placeholder="City"
                            name="city"
                            maxLength={generalValidationRules.city.maxLength}
                            onChange={onChange}
                            value={basicInfo.city}
                            tabIndex={getTabIndex()}
                        />
                    </FieldSet>
                    <FieldSet name="stateId" errors={errors} customClass={`${generateAddressClassName} state-autocomplete`}>
                        <PaginationAutocomplete
                            cleanable={true}
                            fetchData={StateService.getAll}
                            convertFunction={ConverterHelper.stateConvert}
                            id="state"
                            defaultValue={selectedState?.name || basicInfo?.stateName}
                            placeholder="State"
                            name="stateId"
                            tabIndex={getTabIndex()}
                            onDelete={() => onChange({ name: 'stateId', value: undefined })}
                            onSelect={(item) => onChange({ name: 'stateId', value: item.value })}
                        />
                    </FieldSet>
                </div>
                <div className="row-two-column">
                    <FieldSet name="countyId" errors={errors} customClass={`${generateAddressClassName} state-autocomplete`}>
                        <PaginationAutocomplete
                            cleanable={true}
                            fetchData={fetchCountiesData}
                            convertFunction={ConverterHelper.countyConvert}
                            id="county"
                            defaultValue={options.countyName || basicInfo.countyName || ''}
                            placeholder="County"
                            name="county"
                            tabIndex={getTabIndex()}
                            fetchTrigger={basicInfo.stateId}
                            onDelete={() => onChange({ name: 'countyId', value: undefined })}
                            onSelect={(item) => onChange({ name: 'countyId', value: item.value })}
                        />
                    </FieldSet>
                    <FieldSet name="zip" errors={errors} customClass={generateAddressClassName}>
                        <NumberField
                            id="zip"
                            classic
                            maxLength={generalValidationRules.zip.maxLength}
                            placeholder="Zip"
                            name="zip"
                            tabIndex={getTabIndex()}
                            onChange={(option: any) => onChange({ name: 'zip', value: option?.value?.length > 0 ? option?.value : null })}
                            value={basicInfo.zip}
                        />
                    </FieldSet>
                </div>

                <FieldSet errors={errors} name="populationId" customClass="edit-field required-field-star">
                    <InfiniteSelect
                        id="populationId"
                        label="Population"
                        definedTabIndex={getTabIndex()}
                        items={options.populationsOptions}
                        value={options.populationsOptions.find((item: DropdownOption) => item.value === basicInfo.populationId)}
                        onChange={(item) => onChange({ name: 'populationId', value: item.value })}
                    />
                </FieldSet>

                <FieldListPhysicians data={basicInfo.physicians} tabIndex={getTabIndex()} errors={errors} onChange={onChange} />

                <FieldSet name="specialInstructions" errors={errors} customClass="edit-field">
                    <TextAreaField
                        maxLength={validationRules.specialInstructions.maxLength}
                        id="specialInstructions"
                        placeholder="Special Instructions"
                        name="specialInstructions"
                        onChange={onChange}
                        value={basicInfo.specialInstructions}
                        tabIndex={getTabIndex()}
                    />
                </FieldSet>
            </div>
        </>
    );
};

export default PatientBasicInfoForm;
