import React, { useCallback, useEffect, useRef, useState } from 'react';
import Tabs from '../../common/components/tabs/tabs';
import Tab from '../../common/components/tabs/tab';
import GeneralInfo from './general-info/general-info';
import { Link, useParams } from 'react-router-dom';
import './style.scss';
import Button from '../../common/components/Button/button';
import Breadcrumb from '../../common/components/Breadcrumb/breadcrumb';
import { connect, useSelector } from 'react-redux';
import BasicInfoTab from './basic-info-tab/basic-info-tab';
import AssignmentTab from './assignments-tab/assignment-tab';
import TherapistDocumentsTab from './therapist-documents-tab/therapist-documents-tab';
import BackgroundChecksTab from './background-check/background-checks-tab';
import { trackPromise } from 'react-promise-tracker';
import CommunicationTab from './communication-tab/communication-tab';
import StartProcessButton from './start-process-button/start-process-button';
import Label from './label/label';
import DuplicateTherapistLabel from './duplicate-therapist-label/duplicate-therapist-label';
import { ProfileStatusType } from 'common/models/profile-status';
import RejectDialog from 'common/components/reject-dialog/reject-dialog';
import { ReasonType } from 'common/services/api/reason/types';
import TherapistRateTab from './therapist-rates-tab/therapist-rates-page';
import { PermissionType } from 'common/models/permission-type';
import { useHasPermission } from 'common/hooks/use-has-permission';
import { IRejectRequest } from 'common/models/reject-request';
import {
    approveProfile,
    getTherapistDetailsRequest,
    initiateStartProcessRequest,
    rejectProfile,
} from 'app/store/therapist/action-creators';
import { NavigationRoutes } from 'models/routes/navigation-routes';
import ServerNavigation from 'common/components/details-navigation/server-navigation';
import { IToggleFeatureList } from 'common/services/api/settings/types';

const TherapistDetailsPage = (props: any) => {
    const items = [
        { to: '/providers', label: 'Providers' },
        { to: '', label: 'Provider Details' },
    ];

    const headerRef = useRef<HTMLElement>();

    const [isRejectProfileDialogOpen, setRejectProfileDialogOpen] = useState(false);

    const hasViewTherapistRatesPrivilege = useHasPermission(PermissionType.CanViewTherapistRates);
    const [headerHeight, setHeaderHeight] = useState(0);
    const { therapistId } = useParams<{ therapistId: string }>();

    const websiteReferralName = useSelector((state: any) => state.siteSettings?.settings?.infiniteWebsiteReferralSourceName || '');
    const featureList: IToggleFeatureList = useSelector((state: any) => state.siteSettings?.settings?.features || {});

    const referralSource = props.therapist?.generalInfo?.referralSource.name || '';

    const handleClickOnStartProcess = useCallback(() => {
        props.startProcess(props.therapist.id);
    }, [props]);

    const handleClickOnApprove = useCallback(() => {
        props.approveProfile(props.therapist.id);
    }, [props]);

    const handleClickOnReject = () => {
        setRejectProfileDialogOpen(true);
    };

    const handleClickOnCancelRejectDialog = () => {
        setRejectProfileDialogOpen(false);
    };

    const rejectProfile = (request: IRejectRequest) => {
        return props.rejectProfile(props.therapist.id, request).then((_: any) => {
            handleClickOnCancelRejectDialog();
        });
    };

    useEffect(() => {
        if (!props.therapist || (props.therapist && props.therapist.id !== therapistId)) {
            props.loadTherapistDetails(therapistId);
        }
    }, [therapistId]);

    useEffect(() => {
        if (props.therapist) {
            setHeaderHeight(headerRef.current.clientHeight);
        }
    }, [props.therapist]);

    const profileStatus = props.therapist?.profileStatus;

    const renderButtonsBlock = useCallback(() => {
        if (!props.therapist || !profileStatus) {
            return <></>;
        }

        return (
            <div className="button-block">
                {profileStatus === ProfileStatusType.Applied && (
                    <>
                        {referralSource === websiteReferralName && props.therapist.duplicates?.length > 0 && (
                            <DuplicateTherapistLabel therapists={props.therapist.duplicates} />
                        )}
                        <StartProcessButton disabled={!props.therapist.hasAssignments} onClick={handleClickOnStartProcess} />
                    </>
                )}
                {profileStatus === ProfileStatusType.InProcess && <Label className="duplicate-inprogress" text="In Process" />}
                {profileStatus === ProfileStatusType.AwaitingReview && (
                    <>
                        <Button id="approveProfile" onClick={handleClickOnApprove} className="left-button" color="blue">
                            Approve Profile
                        </Button>
                        <Button id="rejectProfile" onClick={handleClickOnReject} color="white">
                            Reject Profile
                        </Button>
                    </>
                )}
            </div>
        );
    }, [props.therapist, profileStatus, referralSource, websiteReferralName, handleClickOnStartProcess, handleClickOnApprove]);

    return (
        <div className="details old-details">
            <header ref={headerRef}>
                {featureList.isDetailsNavigationEnabled && (
                    <ServerNavigation
                        entityApiBaseUrl="therapists"
                        entityId={parseInt(therapistId)}
                        navigationRoute={NavigationRoutes.therapistDetailsRoute}
                    />
                )}
                <div className="header-mock">
                    <Breadcrumb>
                        {items.map(({ to, label }) => (
                            <Link key={to} to={to}>
                                {label}
                            </Link>
                        ))}
                    </Breadcrumb>
                    {featureList.isTherapistRejectApproveButtonsEnabled && renderButtonsBlock()}
                </div>
                {props.therapist && <GeneralInfo therapist={props.therapist} />}
            </header>
            <div className="old-details-content" style={{ height: `${window.innerHeight - headerHeight}px` }}>
                <Tabs orientation="horizontal">
                    <Tab linkClassName={'custom-link'} text="Basic Info">
                        {props.therapist && <BasicInfoTab></BasicInfoTab>}
                    </Tab>
                    <Tab
                        linkClassName={'custom-link'}
                        text="Documents"
                        count={
                            featureList.isTherapistDocumentAndBackgroundCheckCountsEnabled &&
                            props?.therapist?.expiredMissingTherapistDocumentCount
                        }
                    >
                        {props.therapist && <TherapistDocumentsTab therapist={props.therapist} />}
                    </Tab>
                    <Tab
                        linkClassName={'custom-link'}
                        text="Background Check"
                        count={
                            featureList.isTherapistDocumentAndBackgroundCheckCountsEnabled &&
                            props?.therapist?.notCompletedBackgroundChecksCount
                        }
                    >
                        {props.therapist && <BackgroundChecksTab></BackgroundChecksTab>}
                    </Tab>
                    <Tab linkClassName={'custom-link'} text="Communication">
                        {props.therapist && <CommunicationTab></CommunicationTab>}
                    </Tab>
                    <Tab linkClassName={'custom-link'} text="Assignments">
                        {props.therapist && <AssignmentTab></AssignmentTab>}
                    </Tab>

                    {featureList.isTherapistRateEnabled && hasViewTherapistRatesPrivilege && props.therapist && (
                        <Tab linkClassName={'custom-link'} text="Rates">
                            <TherapistRateTab {...props.therapist}></TherapistRateTab>
                        </Tab>
                    )}
                </Tabs>
            </div>
            {isRejectProfileDialogOpen && (
                <RejectDialog
                    title="Reject Profile"
                    reasonType={ReasonType.RejectProfile}
                    onSave={rejectProfile}
                    onCancel={handleClickOnCancelRejectDialog}
                />
            )}
        </div>
    );
};

const mapDispatchToProps = (dispatch: any) => {
    return {
        loadTherapistDetails: (id: number) => {
            trackPromise(dispatch(getTherapistDetailsRequest(id)));
        },
        startProcess: (id: number) => {
            trackPromise(dispatch(initiateStartProcessRequest(id)));
        },
        approveProfile: (id: number) => {
            trackPromise(dispatch(approveProfile(id)));
        },
        rejectProfile: (id: any, request: IRejectRequest) => {
            return trackPromise(dispatch(rejectProfile(id, request)));
        },
    };
};

const mapStateToProps = (state: any) => {
    return {
        therapist: state.therapistDetails.therapist,
        assignedTo: state.therapistDetails.assignedTo,
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(TherapistDetailsPage);
