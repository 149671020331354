export class NavigationRoutes {
    static therapistDetailsRoute(therapistId: string | number) {
        return `/providers/${therapistId}`;
    }

    static facilityDetailsRoute(facilityId: string | number) {
        return `/facilities/${facilityId}`;
    }

    static customerDetailsRoute(agencyId: string | number) {
        return `/customers/${agencyId}`;
    }

    static entityDetailsRoute(entityId: string | number) {
        return `/entities/${entityId}`;
    }

    static legalentityDetailsRoute(entityId: string | number) {
        return `/entities/${entityId}`;
    }

    static patientDetailsRoute(patientId: string | number) {
        return `/patients/${patientId}`;
    }

    static requestDetailsRoute(requstId: string | number) {
        return `/requests/${requstId}`;
    }

    static leadDetaisPage(leadId: string | number) {
        return `/leads/${leadId}`;
    }

    static nursinghomerequestDetailsRoute(requstId: string | number) {
        return `/nursing-home/requests/${requstId}`;
    }

    static schoolRequestDetailsRoute(requestId: string | number) {
        return `/school/requests/${requestId}`;
    }

    static isDetailsRoute(name: string) {
        return name && this[(name?.toLowerCase() + 'DetailsRoute') as keyof NavigationRoutes];
    }

    static openDetailsRoute(name: string, id: string | number) {
        const method: Function = name && this[(name?.toLowerCase() + 'DetailsRoute') as keyof NavigationRoutes];

        return method && window.open(method(id), '_blank').focus();
    }
}
