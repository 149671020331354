import Tab from 'common/components/tabs/tab';
import Tabs from 'common/components/tabs/tabs';
import React from 'react';
import { NursingHomeRequestGeneralInfoTabProp } from '../types';
import NursingHomeRequestBasicInfoTab from './basic-info-tab/nursing-home-request-basic-info-tab';

import { ICreateNoteRequest } from 'common/models/create-note-request';
import nursingHomeRequestService from 'common/services/api/nursing-home-requests/nursing-home-request-service';
import noteService from 'common/services/api/note/note-service';
import NotePage from 'common/components/note-page/note-page';

const NursingHomeRequestGeneralInfoTab: React.FC<NursingHomeRequestGeneralInfoTabProp> = ({ requestId, requestDetails }) => {
    const handleClickOnSaveNote = (request: ICreateNoteRequest) => {
        return nursingHomeRequestService.createNote(requestId, request);
    };

    const handleClickOnCompleteNote = (id: number) => {
        noteService.complete(id);
    };

    return (
        <Tabs orientation="vertical" parentTabUrl="general-info" className="general-tab">
            <Tab id="basicInfo" linkClassName={'custom-link'} text="Basic Info">
                <NursingHomeRequestBasicInfoTab requestId={requestId} requestDetails={requestDetails} />
            </Tab>
            <Tab id="notes" linkClassName={'custom-link'} text="Notes">
                <NotePage
                    url={`nursing-home/requests/${requestId}/notes`}
                    onCompleteNoteClick={handleClickOnCompleteNote}
                    onSave={handleClickOnSaveNote}
                />
            </Tab>
        </Tabs>
    );
};

export default NursingHomeRequestGeneralInfoTab;
