import React from 'react';
import { ICreateNoteRequest } from 'common/models/create-note-request';
import { FieldValue } from 'common/models/field-value';
import { useState } from 'react';
import RightSideBar from '../right-side-bar/right-side-bar';
import { CreateNoteProps, ICreateNoteForm } from './types';
import FieldSet from '../field-set/field-set';
import TextAreaField from '../field/text-area-field';
import { generalValidationRules } from 'common/validation/general-validation-rules';
import DateField from '../field/date-field';
import NativeTimePicker from '../field/native-time-picker/native-time-picker';
import ReminderCheckbox from './reminder-checkbox';
import moment from 'moment';
import { dateTimeConstants } from 'common/constants/common';
import './create-note-dialog.scss';

// TO-DO: Set tab indexes on the form
const CreateNoteDialog: React.FC<CreateNoteProps> = ({ onSave, onCancel, renderAdditionalContent, title = 'Add Note' }) => {
    const [noteRequest, setNoteRequest] = useState<ICreateNoteForm>({ note: undefined, reminder: false });
    const [errorData, setErrorData] = useState({});

    const handleClickOnSave = () => {
        const utcDateString = moment(noteRequest.date).format(dateTimeConstants.MM_DD_YYYY);
        const utcDate = moment(`${utcDateString} ${noteRequest.time}`).utc();

        const request: ICreateNoteRequest = {
            note: noteRequest.note,
            reminder: noteRequest.reminder,
            reminderDate: utcDate.toDate(),
        };

        onSave(request).catch((error) => {
            if (error.response.status === 400) {
                setErrorData(error.response.data);
            }
        });
    };

    const handleFieldChange = (data: FieldValue) => {
        setNoteRequest((prevRequest) => ({ ...(prevRequest as ICreateNoteRequest), [data.name]: data.value }));
        setErrorData((prevError) => ({ ...prevError, [data.name]: undefined }));
    };

    const handleReminderChange = (data: boolean) => {
        const currentDate = moment().local();

        setNoteRequest((request) => ({
            ...request,
            reminder: data,
            date: data ? currentDate.toDate() : undefined,
            time: data ? currentDate.format('HH:mm') : undefined,
        }));
    };

    return (
        <>
            <RightSideBar className="create-note-dialog" title={title} onCancel={onCancel} onSave={handleClickOnSave}>
                {renderAdditionalContent && renderAdditionalContent()}
                <FieldSet name="note" errors={errorData} customClass="edit-field add-note">
                    <TextAreaField
                        id="note"
                        placeholder="Note"
                        name="note"
                        onChange={handleFieldChange}
                        value={noteRequest?.note}
                        maxLength={generalValidationRules.note.maxLength}
                    ></TextAreaField>
                </FieldSet>
                <hr className="notes-separator" />
                <div className="reminder-block">
                    <FieldSet name="reminder" errors={errorData} customClass="edit-field reminder">
                        <ReminderCheckbox
                            id="reminder"
                            value={noteRequest.reminder}
                            onChange={handleReminderChange}
                            name="reminder"
                            className="reminder-checkbox"
                        />
                    </FieldSet>
                    {noteRequest.reminder && (
                        <FieldSet customClass="reminder-block" errors={errorData} name="reminderDate">
                            <div className="edit-field reminder-date">
                                <DateField
                                    hasError={(errorData as any)?.reminderDate}
                                    id="ReminderDate"
                                    placeholder="Date"
                                    name="date"
                                    onChange={handleFieldChange}
                                    value={noteRequest?.date}
                                ></DateField>
                            </div>
                            <div className="edit-field reminder-date">
                                <NativeTimePicker
                                    id="ReminderTime"
                                    placeholder="Time"
                                    name="time"
                                    onChange={handleFieldChange}
                                    value={noteRequest?.time}
                                ></NativeTimePicker>
                            </div>
                        </FieldSet>
                    )}
                </div>
            </RightSideBar>
        </>
    );
};

export default CreateNoteDialog;
