import React from 'react';
import './../container.scss';
import InfiniteSelect from 'common/components/infinite-select/infinite-select';
import { snoozeTypes } from '../snooze-types';
import DropdownOption from 'common/models/dropdown-option';
import { INotificationSnoozeReminderSelectProps } from '../types';

const NotificationSnoozeReminderSelect: React.FC<INotificationSnoozeReminderSelectProps> = ({
    hubConnection,
    noteId,
    closeNotification,
}) => {
    const handleOnChange = async (selectedSnoozeTime: DropdownOption) => {
        await hubConnection.send('Snooze', noteId, selectedSnoozeTime.value);
        closeNotification(noteId);
    };

    return (
        <InfiniteSelect
            items={snoozeTypes}
            label="Reminder"
            popper
            width="148px"
            className="notification-snooze-select"
            onChange={handleOnChange}
        />
    );
};

export default React.memo(NotificationSnoozeReminderSelect);
