import { InfiniteServerClient } from 'common/helpers/http-clients';
import { IContactRole } from './types';

export class ContactRoleService {
    getAll(): Promise<IContactRole[]> {
        return InfiniteServerClient.get<IContactRole[]>('/contacts/roles').then(
            (response) => {
                return response.data;
            },
            (error) => {
                throw error;
            }
        );
    }
}

export default new ContactRoleService();
