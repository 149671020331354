import React, { Component } from 'react';
import { connect } from 'react-redux';
import { USER_FULL_NAME } from 'common/constants/local-storage-keys';
import { UserType } from '../../constants/user-type';
import NotificationBell from './notification-center/notification-bell';

export const SideBarContext = React.createContext({ expanded: true });

class SideBar extends Component {
    constructor(props) {
        super(props);

        this.state = {
            expanded: false,
        };
    }

    componentDidUpdate(prevProps) {
        if (this.props.expanded !== prevProps.expanded) this.setState({ expanded: this.props.expanded });
    }

    toggle = this.props.toggle;

    render() {
        const children = React.Children.map(this.props.children, (child) => {
            if (child?.type.name === 'NavigationGroup') {
                return React.cloneElement(child, { displayOnlyIcon: !this.state.expanded });
            }

            return child;
        });

        return (
            <React.Fragment>
                <div className="side-bar">
                    <div className="sidebar-logo">
                        <span className="infinite-logo"></span>

                        {this.props?.isExternal && (
                            <div className="logo-text logo-external">
                                <div className="logo-external__customer">{this.props.entityName}</div>
                                <div className="logo-external__user">{this.props.userFullName}</div>
                            </div>
                        )}

                        {this.props?.isExternal === false && (
                            <div className="logo-text">
                                Welcome, {this.props.userFullName}
                                {this.props.isNotificationsEnabled && <NotificationBell isSideBarExpanded={this.props.expanded} />}
                            </div>
                        )}
                    </div>
                    <div className="sidebar-nav">{children}</div>
                    <div className={`nav-bar-button logout ${this.state.expanded && 'expanded'}`} onClick={() => this.props.onLogOut()}>
                        <div>
                            <span className="logout-icon-wrapper">
                                <i className="icon icon-log-out"></i> Log Out
                            </span>
                        </div>
                    </div>
                    <div
                        className={this.state.expanded ? 'show-hide-extend-left-sidebar extended-toggle' : 'show-hide-extend-left-sidebar'}
                        onClick={this.toggle}
                    >
                        <div></div>
                    </div>
                </div>
            </React.Fragment>
        );
    }
}

const mapStateToProps = (state) => ({
    userFullName: state.auth.user.fullName || localStorage.getItem(USER_FULL_NAME),
    isExternal: state.auth?.user?.type === UserType.External,
    entityName: state.auth?.entity?.name || '',
});

export default connect(mapStateToProps)(SideBar);
