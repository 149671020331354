export interface IRateBase {
    rateId?: number;
    rate?: number;
    startDate: Date;
    endDate: Date;
}
export interface ILegalEntityRate extends IRateBase {
    rateTemplateId: number;
    documents: any[];
}

export interface ILegalEntityRateView extends ILegalEntityRate {
    placementTypeName: string;
    therapyTypeName: string;
    rates: ILegalEntityRate[];
}

export interface IRateOption {
    id: number;
    rate: number;
}

export const EDIT_RATE_REQUEST = 'editRateRequest.';
export const NEW_RATE_REQUEST = 'newRateRequest.';
