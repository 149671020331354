import Card from 'common/components/card';
import DisplayValue from 'common/components/display-value/display-value';
import FieldListEmailsView from 'common/components/field-list/field-list-view/field-list-email-view';
import FieldListPhonesView from 'common/components/field-list/field-list-view/field-list-phones-view';
import { SideBarMode } from 'common/components/right-side-bar/right-side-bar';
import RightSideBarForm from 'common/components/right-side-bar/right-side-bar-form';
import { labels } from 'common/constants/labels';
import { DisplayValueHelper } from 'common/helpers/display-value-helper';
import { IViewSidebar } from 'common/models/types';
import { IPatientContactDetails } from 'common/services/api/patient-contact/types';
import React from 'react';

const PatientContactSidebarInfo = ({ data, actions, onClose }: IViewSidebar<IPatientContactDetails>) => {
    if (!data) {
        return null;
    }
    const { firstName, middleName, lastName, phones, emails } = data?.contact;
    const { relationship, isEmergencyContact, isMainContact } = data;

    return (
        <RightSideBarForm title="Contact Info" onCancel={onClose} mode={SideBarMode.VIEW} actions={actions}>
            <Card className="void-card pl-34-view" noBorder titleIcon="icon-info" title="Basic Info">
                <DisplayValue id="firstName" placeholder="First Name" value={firstName} />
                <DisplayValue id="middleName" placeholder={labels.middleNameInitial} value={middleName} />
                <DisplayValue id="lastName" placeholder="Last Name" value={lastName} />
                <DisplayValue id="relationship" placeholder="Relationship" value={relationship?.name} />
                <DisplayValue
                    id="emergancyContact"
                    placeholder="Is Emergency Contact"
                    value={DisplayValueHelper.convertToYesOrNo(isEmergencyContact)}
                />
                <DisplayValue id="mainContact" placeholder="Is Main Contact" value={DisplayValueHelper.convertToYesOrNo(isMainContact)} />
            </Card>
            <Card className="void-card pl-34-view" noBorder titleIcon="icon-phone" title="Phones">
                <FieldListPhonesView phones={phones} />
            </Card>
            {emails?.length > 0 && (
                <Card className="void-card pl-34-view" noBorder titleIcon="icon-email" title="Email Addresses">
                    <FieldListEmailsView emails={emails} />
                </Card>
            )}
        </RightSideBarForm>
    );
};

export default PatientContactSidebarInfo;
