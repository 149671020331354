import React, { useCallback, useEffect } from 'react';
import { dateTimeConstants } from 'common/constants/common';
import { DateTimeHelper } from 'common/helpers/date-time-helper';
import NotificationSnoozeReminderSelect from './notification-snooze-reminder-select';
import { INotificationSettings } from 'common/services/api/settings/types';
import { useSelector } from 'react-redux';
import { INotificationToast } from '../types';
import './../container.scss';

const NotificationToast: React.FC<INotificationToast> = ({
    hubConnection,
    notification,
    key,
    closeNotification,
    handleClickNotificationDone,
    handleNotificationClick,
}) => {
    const notificationSettings: INotificationSettings = useSelector(
        (state: any) => state.siteSettings?.settings?.notificationSettings || {}
    );

    useEffect(() => {
        if (notificationSettings?.displayNotificationTimeoutInSeconds) {
            const interval = setInterval(() => {
                closeNotification(notification.id);
            }, notificationSettings.displayNotificationTimeoutInSeconds * 1000);

            return () => {
                clearInterval(interval);
            };
        }
    }, [closeNotification, notification.id, notificationSettings]);

    const formatName = useCallback(() => {
        if (notification.notificationEntity) {
            return `${notification.createdBy?.fullName || ''}/${notification.notificationEntity.name}`.replaceAll('/', ' / ');
        }

        return notification.createdBy?.fullName || '';
    }, [notification]);

    return (
        <div className="notification-toast" key={key}>
            <div className="notification-body">
                <div className="notification-info" onClick={() => handleNotificationClick(notification)}>
                    <div>{formatName()}</div>
                    <div className="notification-text">{notification.notification}</div>
                    <div className="notification-date">
                        {`${DateTimeHelper.formatToLocalTime(notification.reminderDate, dateTimeConstants.H_A)} TODAY`}
                    </div>
                </div>
                <div className="close-notification-btn" onClick={() => closeNotification(notification.id)}>
                    &#10006;
                </div>
            </div>
            <div className="notification-buttons">
                <NotificationSnoozeReminderSelect
                    hubConnection={hubConnection}
                    noteId={notification.id}
                    closeNotification={closeNotification}
                />
                <div className="notificication-done-btn" onClick={() => handleClickNotificationDone(notification.id)}>
                    Done
                </div>
            </div>
        </div>
    );
};

export default React.memo(NotificationToast);
