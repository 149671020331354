import InfinitePopper from 'common/components/infinite-popper/infinite-popper';
import React, { useRef, useState } from 'react';
import { customOffsetModifier } from 'common/components/infinite-popper/constants';

const rules = [
    'Minimum number of characters - 8',
    'Include at least 1 Digit',
    'Include at least 1 Special character',
    'Include at least 1 Uppercase and 1 lowercase characters',
    'Password should not include any name of the user or e-mail address, phone',
];

const PasswordRulesHint: React.FC = () => {
    const refTarget = useRef();
    const [target, setTarget] = useState(null);

    return (
        <div>
            <span className="form-label" ref={refTarget} onClick={() => setTarget(refTarget.current)}>
                <span className="icon icon-info"></span>
                <span className="form-label__content">Password rules</span>
            </span>
            {target && (
                <InfinitePopper
                    className="tooltip-rules-wrapper"
                    target={target}
                    width={330}
                    onClickOutside={() => setTarget(null)}
                    blockPageScroll={true}
                    options={{
                        placement: 'bottom-start',
                        modifiers: [customOffsetModifier],
                    }}
                >
                    <div className="tooltip-rules">
                        {rules.map((rule, index) => (
                            <div className="tooltip-rules__item">
                                <span className="tooltip-rules__number">{index + 1}</span>
                                <span className="tooltip-rules__text">{rule}</span>
                            </div>
                        ))}
                    </div>
                </InfinitePopper>
            )}
        </div>
    );
};

export default React.memo(PasswordRulesHint);
