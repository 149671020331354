import { UserType } from 'common/constants/user-type';

export enum UserStatus {
    Undefined,
    Inactive,
    Active,
    InvitationSent,
}

export interface IUserBaseInfo {
    id: string | number;
    fullName: string;
    firstName: string;
    middleName: string;
    lastName: string;
}

export interface IUser {
    id: string;
    fullName: string;
    firstName: string;
    middleName: string;
    lastName: string;
    email: string;
    username: string;
    lockoutEnabled: boolean;
    type: UserType;
    status: UserStatus;
    legacyId: number;
    roles: IUserRoleModel[];
}

export interface ICreateUpdateExternalUserRequest {
    lockoutEnabled: boolean;
}

export interface ICreateUpdateUserRequest {
    firstName: string;
    lastName: string;
    email: string;
    username: string;
    lockoutEnabled: boolean;
    legacyId?: number;
    lockoutEnd?: Date;
    rolesIds: string[];
}

export interface IUserRoleModel {
    id: string;
    name: string;
}

export interface IResetPasswordRequest {
    email: string;
    password: string;
    confirmPassword: string;
    token: string;
}

export interface IVerifyResetTokenRequest {
    email: string;
    token: string;
}

export interface IVerifyResetTokenResponse {
    isValid: boolean;
}

export interface IForgotPasswordRequest {
    email: string;
}
