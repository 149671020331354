import React, { useEffect, useRef } from 'react';
import Tooltip, { TooltipProps } from 'react-tooltip-lite';

export const InfiniteTooltip: React.FC<TooltipProps> = ({ children, onToggle, ...rest }) => {
    const tooltipRef = useRef<any>();
    const marginLeft = useRef<number>(0);

    const recalculateMarginLeft = () => {
        const target = tooltipRef.current.target;
        const tip = tooltipRef.current.tip;
        const targetRect = target.getBoundingClientRect();
        const tipRect = tip.getBoundingClientRect();
        const diff = targetRect.left - tipRect.left + targetRect.width / 2 - tipRect.width / 2;

        if (diff && marginLeft.current === 0) {
            marginLeft.current = diff;
            tip.style.marginLeft = marginLeft.current + 'px';
        }
    };

    useEffect(() => {
        const handleResetMarginLeft = () => {
            marginLeft.current = 0;
            if (tooltipRef.current?.tip) {
                tooltipRef.current.tip.style.marginLeft = 0 + 'px';
            }
        };
        window.addEventListener('resize', handleResetMarginLeft);
        window.addEventListener('zoomChanged', handleResetMarginLeft);

        return () => {
            window.removeEventListener('resize', handleResetMarginLeft);
            window.removeEventListener('zoomChanged', handleResetMarginLeft);
        };
    });

    return (
        <Tooltip
            {...rest}
            ref={tooltipRef}
            onToggle={(show) => {
                if (show) {
                    recalculateMarginLeft();
                }
                if (onToggle) {
                    onToggle(show);
                }
            }}
        >
            {children}
        </Tooltip>
    );
};
