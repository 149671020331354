import DropdownAutocomplete from 'common/components/field/dropdown/dropdown-autocomplete';
import DropdownOption from 'common/models/dropdown-option';
import React, { useCallback, useEffect, useState } from 'react';
import { DocumentFilterProps } from './types';
import './document-filter.scss';

const DocumentFilter: React.FC<DocumentFilterProps> = ({ value, documents, onChange, cleanable = true }) => {
    const [options, setOptions] = useState<DropdownOption[]>([]);
    const [searchTerm, setSearchTerm] = useState<string>('');

    useEffect(() => {
        setOptions(documents?.map((i) => new DropdownOption(i.id, i.name)) ?? []);
    }, [documents]);

    const handleChange = (documents: DropdownOption[]) => {
        const ids = documents.map((item) => item.value);
        onChange(ids);
    };

    const determineOptions = useCallback(() => {
        return searchTerm ? options.filter((item) => item.label.toLowerCase().includes(searchTerm.toLowerCase())) : options;
    }, [searchTerm, options]);

    return (
        <div className="document-filter small-size">
            <DropdownAutocomplete
                popper={true}
                items={determineOptions()}
                label="Document"
                cleanable={cleanable}
                multiselect
                defaultSelectedLabel="Document"
                searchPlaceholder="Search Document"
                widthOptions={310}
                value={options?.filter((item) => (value || []).some((i) => i === item.value)) ?? []}
                onChange={setSearchTerm}
                onSelect={handleChange}
            />
        </div>
    );
};

export default React.memo(DocumentFilter);
