import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import CreateEditAssignmentDialog from './components/create-edit-assignment-dialog/create-edit-assignment-dialog';
import AssignmentList from './components/assignment-list/assignment-list';
import { AssignmentDialogOptions } from './types';
import { ITherapistAssignment } from 'common/services/api/therapist-assignment/types';
import { addAssignmentRequest, editAssignmentRequest } from 'app/store/therapist/action-creators';

const AssignmentTab: React.FC = () => {
    const therapist = useSelector((state: any) => state.therapistDetails.therapist);
    const [forceUpdate, setForceUpdate] = useState(false);

    const [dialogOptions, setDialogOptions] = useState<AssignmentDialogOptions>({
        isOpen: false,
        assignment: undefined,
    });

    if (!therapist) {
        return null;
    }

    const handleClickOnAddAssignment = () => {
        setDialogOptions({ isOpen: true });
        setForceUpdate(false);
    };

    const handleClickOnEditAssignment = (assignment: ITherapistAssignment) => {
        setDialogOptions({ isOpen: true, assignment });
    };

    const handleCloseAddDialog = () => {
        setForceUpdate(false);
        setDialogOptions({ isOpen: false });
    };

    const handleAssignmentSaved = () => {
        setForceUpdate(true);
        handleCloseAddDialog();
    };

    return (
        <div className="therapist-assignemnt">
            <div className="table-page">
                <AssignmentList
                    therapistId={therapist.id}
                    therapistStatus={therapist.status}
                    onAddClick={handleClickOnAddAssignment}
                    onEditClick={handleClickOnEditAssignment}
                    forceUpdate={forceUpdate}
                />
            </div>

            {dialogOptions.isOpen && (
                <CreateEditAssignmentDialog
                    title={dialogOptions.assignment ? 'Edit assignment' : 'Create assignment'}
                    therapistId={therapist.id}
                    therapistAssignment={dialogOptions.assignment}
                    onAssignmentSaved={handleAssignmentSaved}
                    onSave={dialogOptions.assignment ? editAssignmentRequest : addAssignmentRequest}
                    onCancel={handleCloseAddDialog}
                ></CreateEditAssignmentDialog>
            )}
        </div>
    );
};

export default AssignmentTab;
