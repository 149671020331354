import React, { useState } from 'react';
import Field from './field';
import NumberFormat, { NumberFormatValues } from 'react-number-format';
import { getUniqueName } from '../../helpers/string-helper';
import { IField, INumberField } from './types';
const isFunction = require('lodash.isfunction');

const NumberField = (props: IField & INumberField) => {
    const { defaultValue } = props;
    let { value } = props;

    const [isFocused, toggleFocus] = useState(false);

    if (value === undefined || value === null) value = defaultValue || '';

    const handleOnFocus = () => {
        toggleFocus(true);
    };

    const handleOnBlur = () => {
        toggleFocus(false);
        if (isFunction(props.onBlur)) {
            props.onBlur();
        }
    };

    const handleChange = (values: NumberFormatValues) => {
        if (values.value.length === 0 && defaultValue !== undefined) {
            props.onChange({ value: defaultValue, name: props.name });
        } else {
            props.onChange({ value: values.value, name: props.name });
        }
    };

    const isAllowed = (values: NumberFormatValues) => {
        if (props.maxLength) {
            return values.formattedValue.length <= props.maxLength;
        }

        if (props.maxValue) {
            return values.floatValue ? values.floatValue <= props.maxValue : true;
        }

        return true;
    };

    return (
        <>
            <Field {...props} value={value} isFocused={isFocused} customClass="number-field">
                {props.classic ? (
                    <input
                        ref={props.itemRef}
                        type="text"
                        pattern="[0-9]*"
                        id={props.id + getUniqueName('.')}
                        min={props.min}
                        maxLength={props.maxLength}
                        placeholder={props.placeholder}
                        onChange={(e) => props.onChange({ name: props.name, value: e.target.validity.valid ? e.target.value : value })}
                        className={`${props.inputClass + ' '} ${props.name ?? ''} ${props.id ?? ''}`}
                        value={value}
                        onFocus={handleOnFocus}
                        onBlur={handleOnBlur}
                        tabIndex={props.tabIndex}
                        autoComplete={getUniqueName()}
                    />
                ) : (
                    <NumberFormat
                        ref={props.itemRef}
                        isAllowed={isAllowed}
                        allowNegative={props.allowNegative}
                        format={props.format}
                        mask={props.mask}
                        className={`${props.inputClass + ' '} ${props.name ?? ''} ${props.id ?? ''}`}
                        value={value}
                        displayType={'input'}
                        onFocus={handleOnFocus}
                        onBlur={handleOnBlur}
                        onValueChange={handleChange}
                        autoComplete={getUniqueName()}
                    />
                )}
            </Field>
        </>
    );
};

export default NumberField;
