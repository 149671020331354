import { AxiosRequestConfig } from 'axios';
import { InfiniteServerClient } from 'common/helpers/http-clients';
import { IOption } from 'common/models/types';
import queryString from 'query-string';

export class BaseService {
    getOptions(term: string, skip: number, take: number, fieldId: string, correlatedIds?: number[]): Promise<IOption[]> {
        const config: AxiosRequestConfig = {
            params: {
                fieldId,
                term,
                skip,
                take,
                correlatedIds,
            },
            paramsSerializer: (params) => {
                return queryString.stringify(params);
            },
        };

        return InfiniteServerClient.get<IOption[]>(`/options`, config).then(
            (response) => {
                return response.data;
            },
            (error) => {
                throw error;
            }
        );
    }
}

export default new BaseService();
