import { Dispatch } from '@reduxjs/toolkit';
import { ContactRoleService } from 'common/services/api/contact-role/contact-role-service';
import { IContactRole } from 'common/services/api/contact-role/types';
import { ISetContactRolesAction, SET_CONTACT_ROLES, ThunkResult } from './types';

export const setContactRoles = (payload: IContactRole[]): ISetContactRolesAction => {
    return {
        contactRoles: payload,
        type: SET_CONTACT_ROLES,
    };
};

export const fetchContactRoles = (): ThunkResult<Promise<IContactRole[]>> => {
    return async (dispatch: Dispatch) => {
        const data = await new ContactRoleService().getAll();
        dispatch(setContactRoles(data));

        return data;
    };
};
