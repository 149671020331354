import React, { useState } from 'react';
import { IFormSidebar } from 'common/models/types';
import useFormSidebar from 'common/hooks/use-form-sidebar';
import RightSideBarForm from 'common/components/right-side-bar/right-side-bar-form';
import EntityContactForm from './entity-contact-form';
import { IEntityContactForm } from 'common/services/api/entity-contact/type';
import DropdownOption from 'common/models/dropdown-option';
import InfiniteSelect from 'common/components/infinite-select/infinite-select';
import { ContactActionType } from 'common/constants/entity-type';
import EntityExistingContactForm from './entity-existing-contact-form';

const EntityContactSidebarCreate = ({ onClose, onSave, options }: IFormSidebar<IEntityContactForm, any>) => {
    const [data, error, handleChangeData, handleCancelClick, handleSaveClick] = useFormSidebar<IEntityContactForm>(
        {
            firstName: null,
            middleName: null,
            lastName: null,
            phones: [],
            emails: [],
            contactRoleIds: [],
            otherContactRoles: [],
            existingContacts: [],
        },
        { onClose, onSave }
    );

    const contactForms: Array<DropdownOption> = [
        new DropdownOption(ContactActionType.NewContact, 'New Contact'),
        new DropdownOption(ContactActionType.ExistingContact, 'Existing Contact'),
    ];

    const [contactForm, setContactForm] = useState(contactForms[0]);

    const onSaveClick = () => {
        handleSaveClick(contactForm.value);
    };

    return (
        <RightSideBarForm title="Add Contact" classNameWrapper="flex-column" onCancel={handleCancelClick} onSave={onSaveClick}>
            <div className="contact-form-type">
                <InfiniteSelect
                    id="contactForm"
                    name="type"
                    items={contactForms}
                    value={contactForm}
                    className="add-entity-contact-select"
                    onChange={(item) => setContactForm(item)}
                    icon={
                        <div className="button-with-icons">
                            <i className="icon icon-person" />
                        </div>
                    }
                />
            </div>
            {contactForm.value === ContactActionType.NewContact && (
                <EntityContactForm data={data} errors={error} onChange={handleChangeData} />
            )}
            {contactForm.value === ContactActionType.ExistingContact && (
                <EntityExistingContactForm
                    data={{
                        entityId: options.entityId,
                        existingContacts: data.existingContacts,
                    }}
                    onChange={handleChangeData}
                />
            )}
        </RightSideBarForm>
    );
};

export default EntityContactSidebarCreate;
