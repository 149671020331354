import React, { useState, useEffect, useRef, useCallback } from 'react';
import InputField from '../input-field';
import Field from '../field';
import DropdownOptionComponent from './dropdown-option';
import useOnClickOutside from 'react-cool-onclickoutside';
import { usePopper } from 'react-popper';
import ReactDOM from 'react-dom';
import { ObjectHelper } from '../../../helpers/object-helper';
import { debounce } from 'lodash';
import { getUniqueName } from '../../../helpers/string-helper';
import { IDropdownAutocomplete } from './types';
import DropdownOption from '../../../models/dropdown-option';
import { FieldValue } from '../../../models/field-value';

function DropdownAutocomplete({
    popper = false,
    widthOptions = 200,
    selectDebounceMs = 600,
    items = [],
    onChange,
    onSelect,
    onDelete = undefined,
    onClose = undefined,
    label = undefined,
    id = undefined,
    name = undefined,
    value,
    onBlur = undefined,
    onScrollEnd = undefined,
    tabIndex = undefined,
    searchPlaceholder = undefined,
    hasError = undefined,
    fetchTrigger = undefined,
    multiselect = false,
    cleanable = true,
    defaultSelectedLabel = undefined,
    showSelectedCounter = undefined,
    onRenderOption = undefined,
    onFormatOption = undefined,
    onRenderInput = undefined,
    readOnly = undefined,
    disabled = undefined,
    isHandleOnSave = false,
    optionWrapper = undefined,
}: IDropdownAutocomplete) {
    const [isOpen, toggleDropdown] = useState(false);
    const [selectedItem, setItem] = useState<DropdownOption>(value);
    const [selectedItems, setItems] = useState<DropdownOption[]>(value || []);
    const [inputValue, setInputValue] = useState(value?.label || (multiselect ? '' : value) || '');

    const [isAutocompleteSearchFocused, setAutocompleteSearchFocused] = useState(false);
    const inputRef = useRef();
    const dropdownOptionsBodyRef = useRef<HTMLDivElement>();

    const [referenceElement, setReferenceElement] = React.useState<HTMLDivElement>(null);
    const [popperElement, setPopperElement] = React.useState(null);
    const { styles, attributes, update } = usePopper(referenceElement, popperElement, {
        placement: 'bottom-start',
        modifiers: [
            {
                name: 'offset',
                options: {
                    offset: [-2, 5],
                },
            },
        ],
    });

    useEffect(() => {
        if (popperElement) dropdownOptionsBodyRef.current = popperElement;
    }, [popperElement]);

    useOnClickOutside(
        () => {
            onChange(null);
            toggleDropdown(false);
        },
        { refs: [dropdownOptionsBodyRef, inputRef] }
    );

    const handleClickOnDropdown = () => {
        if (!disabled) {
            toggleDropdown(true);
        }
    };

    const handleSelect = useCallback(debounce(onSelect, selectDebounceMs), [onSelect]);

    const handleClickOnItem = (item: DropdownOption) => {
        if (multiselect) {
            let values = [...selectedItems];

            if (selectedItems.some((x) => x.value === item.value)) {
                values = selectedItems.filter((i) => i.value !== item.value);
            } else {
                values = [...selectedItems, item];
            }

            setItems(values);
            if (!isHandleOnSave) {
                handleSelect(values);
            }
            if (update) {
                update();
            }
        } else {
            setItem(item);
            setInputValue(item.label);
            onChange(item.label);
            toggleDropdown(false);
            onSelect(item);
        }
    };

    const handleChangeInput = (item: any) => {
        const value = multiselect ? (item as React.ChangeEvent<HTMLInputElement>).target.value : (item as FieldValue).value;
        setInputValue(value);
        onChange(value);
    };

    const handleDeleteItem = (item: DropdownOption) => {
        const existingItemIndex = selectedItems.findIndex((x) => x.value === item.value);
        selectedItems.splice(existingItemIndex, 1);
        setItems(selectedItems);
        onSelect(selectedItems);
    };

    const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
        if (event.key === 'Tab') {
            onChange(null);
            toggleDropdown(false);
        }
    };

    const handleOnBlurInput = () => {
        if (!inputValue && selectedItem) {
            if (onDelete) {
                onDelete(selectedItem);
            }
            setItem(null);
        } else {
            setInputValue((selectedItem || {}).label || value?.label || value || '');
        }

        if ((onBlur && !isOpen) || items.length === 0) {
            toggleDropdown(false);
            if (onBlur) {
                //fix onBlur is nof a function
                onBlur();
            }
        }
    };

    const handleOnFocusInput = (e: React.ChangeEvent<HTMLInputElement>) => {
        const target = e.target;
        const setCursorToEnd = () => {
            if (target) {
                target.setSelectionRange(-1, -1);
            }
        };
        setCursorToEnd();
        setTimeout(setCursorToEnd, 0);
        toggleDropdown(true);
    };

    const handleOnClickSearchInput = () => {
        if (items.length > 0) {
            toggleDropdown(true);
        }
    };

    useEffect(() => {
        if (multiselect) {
            setItems(value);
            if (isHandleOnSave) {
                initialValue.current = value;
            }
        } else {
            setInputValue(value?.label || value || '');
        }
    }, [value, fetchTrigger]);

    const handleScroll = () => {
        const { scrollTop, scrollHeight, clientHeight } = dropdownOptionsBodyRef.current;
        if (onScrollEnd && scrollTop > 0 && Math.ceil(scrollTop + clientHeight) + 2 >= scrollHeight) {
            onScrollEnd();
        }
    };

    const handleOnClickNone = () => {
        setItem(null);
        setInputValue(null);
        onChange(null);
        if (onDelete) {
            onDelete(null);
        }
        toggleDropdown(false);
    };

    useEffect(() => {
        if (onClose && !isOpen) {
            if (multiselect) {
                setInputValue('');
            }
            onClose();
        }
        resetResultIfHandleOnSave();
    }, [isOpen]);

    const options = items.map((item, i) => {
        const dropdownOptions = {
            asCheckbox: multiselect,
            checked: multiselect && selectedItems.some((x) => x.value === item.value),
            key: i,
            item: item,
            onClick: handleClickOnItem,
        };
        if (onFormatOption) {
            return <DropdownOptionComponent {...dropdownOptions} onRenderOption={onFormatOption}></DropdownOptionComponent>;
        } else {
            return onRenderOption ? (
                onRenderOption(dropdownOptions)
            ) : (
                <DropdownOptionComponent {...dropdownOptions}></DropdownOptionComponent>
            );
        }
    });

    const className = `custom-select-wrapper autocomplete ${isOpen ? 'open' : 'close'} ${disabled ? 'disabled' : ''}`;

    const initialValue = useRef(selectedItems || []);

    const resetResultIfHandleOnSave = () => {
        if (isHandleOnSave) {
            setItems(initialValue.current);
        }
    };

    const handleHeaderCancel = () => {
        resetResultIfHandleOnSave();
        toggleDropdown(false);
    };

    const handleHeaderSave = () => {
        initialValue.current = ObjectHelper.deepCopy(selectedItems);
        onSelect(selectedItems, referenceElement);
        toggleDropdown(false);
    };

    let optionList;
    if (isOpen && multiselect) {
        const popperConfig = popper
            ? {
                  ref: setPopperElement,
                  style: { ...styles.popper, width: widthOptions },
                  ...attributes.popper,
                  className: 'custom-autocomplete option-list multiselect',
              }
            : { ref: dropdownOptionsBodyRef, className: 'option-list multiselect' };

        optionList = (
            <div {...popperConfig} onScroll={handleScroll}>
                {multiselect && isHandleOnSave && (
                    <div className="custom-select-options-wrapper-header autocomplete-actions">
                        <button id="selectCancelBtn" className="cancel-select-header-btn" onClick={() => handleHeaderCancel()}>
                            Cancel
                        </button>
                        <span></span>
                        <button id="selectSaveBtn" className="save-select-header-btn" onClick={() => handleHeaderSave()}>
                            Save
                        </button>
                    </div>
                )}
                <div className={isAutocompleteSearchFocused ? 'search-wrapper focused' : 'search-wrapper'}>
                    <input
                        className="search"
                        value={inputValue}
                        onFocus={() => setAutocompleteSearchFocused(true)}
                        onBlur={() => setAutocompleteSearchFocused(false)}
                        placeholder={searchPlaceholder || 'Search...'}
                        ref={inputRef}
                        onChange={handleChangeInput}
                        autoComplete={getUniqueName()}
                    />
                </div>
                <div className="options-list">{options}</div>
            </div>
        );
    }

    const optionsMultiselect = multiselect
        ? {
              tabIndex: tabIndex as number,
              onKeyDown: (e: React.KeyboardEvent<HTMLInputElement>) => {
                  const code = e.keyCode || e.which;
                  if (code === 13 || code === 9) {
                      handleOnClickNone();
                  }
              },
              onFocus: () => {
                  handleOnClickSearchInput();
              },
          }
        : undefined;

    return (
        <div className="autocomplete-one-border" {...optionsMultiselect}>
            <div className="custom-field-outer">
                <div className={className + ' ' + name} id={id}>
                    {multiselect && (
                        <div onClick={handleOnClickSearchInput} className="autcomplete" ref={setReferenceElement}>
                            <Field
                                customClass={`multiple-autocomplete multiple-autocomplete-with-tabindex ${
                                    showSelectedCounter ? 'show-selected-counter hide-blue-border' : ''
                                }`}
                                hasError={hasError}
                                id={id}
                                placeholder={label}
                                isFocused={isOpen || selectedItems.length !== 0 || !!defaultSelectedLabel}
                            >
                                {showSelectedCounter && selectedItems.length > 0 && (
                                    <span className="default-selected-label">{selectedItems.length + ' Selected'}</span>
                                )}
                                {!showSelectedCounter && selectedItems.length > 0 && (
                                    <span className="selected">
                                        {selectedItems.map((x, i) => (
                                            <div key={i} className="item">
                                                <span>{x.label}</span>
                                                <button
                                                    className="item-delete-btn icon icon-close"
                                                    onClick={() => handleDeleteItem(x)}
                                                ></button>
                                            </div>
                                        ))}
                                    </span>
                                )}
                                {selectedItems.length === 0 && defaultSelectedLabel && (
                                    <span className="default-selected-label">{defaultSelectedLabel}</span>
                                )}
                            </Field>
                        </div>
                    )}
                    {!multiselect && (
                        <div onClick={handleClickOnDropdown} className="autcomplete">
                            {onRenderInput ? (
                                onRenderInput({
                                    hasError,
                                    tabIndex,
                                    maxLength: 35,
                                    id,
                                    placeholder: label,
                                    value: inputValue,
                                    onKeyDown: handleKeyDown,
                                    onChange: handleChangeInput,
                                    onBlur: handleOnBlurInput,
                                    onFocus: () => toggleDropdown(true),
                                    disabled,
                                    readOnly,
                                })
                            ) : (
                                <InputField
                                    hasError={hasError}
                                    tabIndex={tabIndex}
                                    maxLength={35}
                                    id={id}
                                    placeholder={label}
                                    value={inputValue}
                                    onKeyDown={handleKeyDown}
                                    onChange={handleChangeInput}
                                    onBlur={handleOnBlurInput}
                                    onFocus={handleOnFocusInput}
                                    disabled={disabled}
                                    readOnly={readOnly}
                                    inputRef={inputRef}
                                />
                            )}
                        </div>
                    )}
                    {isOpen && multiselect && popper && ReactDOM.createPortal(optionList, document.body)}
                    {isOpen && multiselect && !popper && optionList}
                    {isOpen && !multiselect && options.length > 0 && (
                        <div className="option-list" onScroll={handleScroll} ref={dropdownOptionsBodyRef}>
                            {cleanable && (
                                <div className="autocomplete__header">
                                    <span className="autocomplete__unselect-button" onClick={handleOnClickNone}>
                                        None
                                    </span>
                                </div>
                            )}
                            {optionWrapper ? optionWrapper(options) : options}
                        </div>
                    )}
                </div>
            </div>
        </div>
    );
}

export default DropdownAutocomplete;
