import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { CellClickedEvent, GridReadyEvent } from 'ag-grid-community/dist/lib/events';
import { IParamsWatcher } from 'common/components/ag-table/types';
import AgTable from 'common/components/ag-table/ag-table';
import {
    AssignedTherapistTableProps,
    IRequestTherapistAssignment,
    TherapistAssignedActions,
    TherapistRequestAction,
    TherapistRequestActions,
    TherapistRequestClosedStatusActions,
    TherapistRequestStatus,
} from './types';
import { NavigationRoutes } from 'models/routes/navigation-routes';
import { ColumnApi, ICellRendererParams } from 'ag-grid-community';
import { IDiscipline } from 'common/services/api/disciplince/types';
import CustomSmallDropdown from 'common/components/CustomSmallDropdown/CustomSmallDropdown';
import CreateNoteDialog from 'common/components/create-note-dialog/create-note-dialog';
import { ICreateNoteRequest } from 'common/models/create-note-request';
import DisplayValue from 'common/components/display-value/display-value';
import './_styles.scss';
import { DateTimeHelper } from 'common/helpers/date-time-helper';

const AssignedTherapistTable = ({
    onCellClick,
    requestId,
    onChangeStatus,
    onSaveNote,
    isDisabled = false,
    hideDoteMenu,
    onFetchData,
    customRequestActions,
    maxAssignedTherapists = 1,
}: AssignedTherapistTableProps) => {
    const [gridApi, setGridApi] = useState(null);
    const [gridColumnApi, setColumnApi] = useState<ColumnApi>(null);
    const paramsWatcher = useRef<IParamsWatcher>();

    useEffect(() => {
        if (gridColumnApi) {
            if (hideDoteMenu) {
                gridColumnApi.setColumnVisible('doteMenu', false);
            } else {
                gridColumnApi.setColumnVisible('doteMenu', true);
            }
        }
    }, [hideDoteMenu, gridColumnApi]);

    const onOptionClick = useCallback((therapistData: IRequestTherapistAssignment, option: string) => {
        switch (option) {
            case TherapistRequestAction.SetAsOffered:
                handleOpenSetStatusDialog(therapistData, TherapistRequestStatus.Offered);
                break;
            case TherapistRequestAction.SetAsAssigned:
                handleOpenSetStatusDialog(therapistData, TherapistRequestStatus.Assigned);
                break;
            case TherapistRequestAction.SetAsDeclined:
                handleOpenSetStatusDialog(therapistData, TherapistRequestStatus.Declined);
                break;
            case TherapistRequestAction.SetAsPendingInterview:
                handleOpenSetStatusDialog(therapistData, TherapistRequestStatus.PendingInterview);
                break;
            case TherapistRequestAction.TherapistDetailsPage: {
                const newWindow = window.open(NavigationRoutes.therapistDetailsRoute(therapistData.therapistId), '_blank');
                newWindow.open();
                newWindow.focus();
                break;
            }
            case TherapistRequestAction.AddANote:
                handleOpenAddNoteDialog(therapistData);
                break;
            default:
                break;
        }
    }, []);

    const therapistAssigned = useRef(false);
    therapistAssigned.current = false;

    const gridOptions = useMemo(
        () => ({
            onCellClicked: (event: CellClickedEvent) => {
                if (onCellClick) {
                    onCellClick(event.data.id);
                }
            },
            columnDefs: [
                {
                    field: 'fullName',
                    headerName: 'Name',
                    flex: 1,
                    width: 350,
                },
                {
                    field: 'disciplines',
                    headerName: 'Type',
                    cellRenderer: 'disciplinesRender',
                    width: 208,
                },
                {
                    field: 'updatedAt',
                    headerName: 'Date',
                    valueFormatter: (props: any) => DateTimeHelper.format(props.value, 'MM/DD/YYYY'),
                    width: 154,
                },
                {
                    field: 'status',
                    headerName: 'Status',
                    cellRenderer: 'statusRender',
                    cellClass: 'd-flex',
                    width: 120,
                },
                {
                    headerName: '',
                    colId: 'doteMenu',
                    cellRenderer: 'doteRender',
                    cellClass: ['dote-cell flex-center'],
                    width: 40,
                    hideForExport: true,
                },
            ],
            defaultColDef: {
                resizable: true,
                sortable: false,
            },
            frameworkComponents: {
                doteRender: (props: ICellRendererParams) => {
                    if (!therapistAssigned.current) {
                        let assignedCount = 0;
                        props.api.forEachNode((node) => {
                            if (node.data?.status === TherapistRequestStatus.Assigned) {
                                assignedCount++;
                            }
                        });

                        if (assignedCount >= maxAssignedTherapists) {
                            therapistAssigned.current = true;
                        }
                    }

                    return (
                        <>
                            <CustomSmallDropdown
                                popper={true}
                                width={320}
                                iconClass="icon-options"
                                buttonId={`detailsEmailOptionButtonId${props?.data?.id}`}
                                optionsList={
                                    isDisabled
                                        ? TherapistRequestClosedStatusActions
                                        : therapistAssigned.current
                                        ? TherapistAssignedActions
                                        : customRequestActions || TherapistRequestActions
                                }
                                onOptionClick={(value: string) => onOptionClick(props?.data, value)}
                            />
                        </>
                    );
                },
                disciplinesRender: (props: ICellRendererParams) => {
                    const disciplines = props.value;

                    return disciplines.map((item: IDiscipline) => item?.name).join(', ');
                },
                statusRender: (props: ICellRendererParams) => {
                    const status = (props.valueFormatted ? props.valueFormatted : props.value) || '';

                    return <div className={`status ${status.toLowerCase()}`}>{status}</div>;
                },
            },
        }),
        [isDisabled, onCellClick, onOptionClick, customRequestActions, maxAssignedTherapists]
    );

    const handleGridReady = (params: GridReadyEvent) => {
        setGridApi(params.api);
        setColumnApi(params.columnApi);
    };

    //Set Status
    const [statusTherapistOptions, setStatusTherapistOptions] = useState(null);

    const handleOpenSetStatusDialog = (therapistData: IRequestTherapistAssignment, status: TherapistRequestStatus) => {
        setStatusTherapistOptions({
            isOpen: true,
            title: 'Set as ' + status,
            data: {
                therapistRequestId: therapistData.id,
                therapistId: therapistData.therapistId,
                status: status,
            },
            template: {
                fullName: therapistData.fullName,
            },
        });
    };

    const handleCancelTherapistSetStatusDialog = () => {
        setStatusTherapistOptions(null);
    };

    const handleSaveTherapistSetStatusDialog = (data: ICreateNoteRequest) => {
        const { therapistRequestId, status, therapistId } = statusTherapistOptions?.data;

        return onChangeStatus(requestId, therapistRequestId, { status, note: data }, therapistId).then(() => {
            gridApi.onFilterChanged();
            setStatusTherapistOptions(null);
        });
    };

    const displayTherapistName = () => {
        const fullName = statusTherapistOptions?.template?.fullName;

        return <DisplayValue iconClass={'icon-technician'} placeholder="Provider" value={fullName} />;
    };

    //Add Note
    const [noteTherapistOptions, setNoteTherapistOptions] = useState(null);
    const handleOpenAddNoteDialog = (therapistData: IRequestTherapistAssignment) => {
        setNoteTherapistOptions({
            isOpen: true,
            title: 'Add Note',
            data: {
                therapistRequestId: therapistData.id,
                therapistId: therapistData.therapistId,
            },
        });
    };
    const handleCancelTherapistAddNoteDialog = () => {
        setNoteTherapistOptions(null);
    };
    const handleSaveTherapistAddNoteDialog = (data: ICreateNoteRequest) => {
        const { therapistRequestId, therapistId } = noteTherapistOptions?.data;

        return onSaveNote(requestId, therapistRequestId, data, therapistId).then(() => {
            setNoteTherapistOptions(null);
        });
    };

    return (
        <>
            <div className="main-info-wrapper ag-table-wrapper none-table-padding none-table-show">
                <AgTable
                    onGridReady={handleGridReady}
                    getAllData={true}
                    adaptiveHeight={true}
                    gridOptions={gridOptions}
                    paramsWatcher={paramsWatcher}
                    onFetchData={onFetchData}
                />
                {statusTherapistOptions?.isOpen && (
                    <CreateNoteDialog
                        title={statusTherapistOptions?.title}
                        onCancel={handleCancelTherapistSetStatusDialog}
                        onSave={handleSaveTherapistSetStatusDialog}
                        renderAdditionalContent={displayTherapistName}
                    ></CreateNoteDialog>
                )}
                {noteTherapistOptions?.isOpen && (
                    <CreateNoteDialog
                        title={noteTherapistOptions?.title}
                        onCancel={handleCancelTherapistAddNoteDialog}
                        onSave={handleSaveTherapistAddNoteDialog}
                    ></CreateNoteDialog>
                )}
            </div>
        </>
    );
};

export default AssignedTherapistTable;
