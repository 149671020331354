import { ICardConfig } from './types';
import { IEmail, IPhone } from 'features/shared/types';
import { ACTIVE, MAIN, maskConstants } from 'common/constants/common';
import { ICustomLabel } from 'common/models/custom-label';
import { IPhysiciansViewModel } from 'common/services/api/physicians/types';
const StringMask = require('string-mask');

type CardConfigProps = {
    friendlyTitle?: boolean;
    customLabels?: ICustomLabel[];
};

function titleize(text: string, customLabels: ICustomLabel[]) {
    if (!text || text.length === 0) return '';

    let result = null;

    if (customLabels) {
        result = customLabels.find((label) => label.field === text)?.label;

        if (result) {
            return result;
        }
    }

    result = text.replace(/([A-Z])/g, ' $1');

    return result.charAt(0).toUpperCase() + result.slice(1);
}

export function getCardConfig(object: object, props: CardConfigProps = {}): ICardConfig[] {
    if (!object || typeof object !== 'object') return [];

    const result: ICardConfig[] = [];
    Object.keys(object).forEach((key) => {
        result.push({
            field: key,
            title: props.friendlyTitle ? titleize(key, props.customLabels) : key,
        });
    });

    return result;
}

const phoneMask = new StringMask(maskConstants.PHONE_NUMBER, { reverse: true });

export function getPhonesCardConfig(patientPhones: IPhone[]): ICardConfig[] {
    let detailsConfig: ICardConfig[] = [];

    patientPhones?.forEach((phone, index) => {
        const isMain = index === 0;

        detailsConfig = [
            ...detailsConfig,

            {
                title: 'phone',
                field: 'phones',
                key: `phoneType${index}`,
                width: '30%',
                render: (phones) => phones[index]?.type,
            },
            {
                title: 'number',
                field: 'phones',
                key: `phoneNumber${index}`,
                width: '50%',
                render: (phones) => phoneMask.apply(phones[index]?.number),
            },
            {
                title: ' ',
                field: 'phones',
                key: `phonePriority${index}`,
                width: '20%',
                className: isMain ? 'highlited' : '',
                render: () => (isMain ? MAIN : ' '),
            },
        ];
    });

    if (detailsConfig.length === 0) {
        detailsConfig.push({ title: 'phones', field: 'phones', width: '100%' });
    }

    return detailsConfig;
}

export function getEmailsCardConfig(patientEmails: IEmail[]): ICardConfig[] {
    let detailsConfig: ICardConfig[] = [];

    patientEmails?.forEach((email, index) => {
        const isMain = index === 0;

        detailsConfig = [
            ...detailsConfig,

            {
                title: 'Email Address',
                field: 'emails',
                key: `email${index}`,
                width: '80%',
                render: (emails) => emails[index]?.email,
            },
            {
                title: ' ',
                field: 'emails',
                key: `emailPriority${index}`,
                width: '20%',
                className: isMain ? 'highlited' : '',
                render: () => (isMain ? MAIN : ' '),
            },
        ];
    });

    if (detailsConfig.length === 0) {
        detailsConfig.push({ title: 'Email Address', field: `emails`, width: '100%' });
    }

    return detailsConfig;
}

export function getPhysicianCardConfig(patientPhysicians: IPhysiciansViewModel[]): ICardConfig[] {
    let detailsConfig: ICardConfig[] = [];

    patientPhysicians?.forEach((physician, index) => {
        const isMain = index === 0;

        detailsConfig = [
            ...detailsConfig,

            {
                title: 'physician',
                field: 'physicians',
                key: `physician${index}`,
                width: '45%',
                render: () => physician?.physician?.firstName + physician?.physician?.lastName,
            },
            {
                title: 'NPI',
                field: 'physicians',
                key: `npiNumber${index}`,
                width: '35%',
                render: () => physician?.physician?.npiNumber,
            },
            {
                title: ' ',
                field: 'physicians',
                key: `phonePriority${index}`,
                width: '20%',
                className: isMain ? 'highlited' : '',
                render: () => (isMain ? ACTIVE : ' '),
            },
        ];
    });

    if (detailsConfig.length === 0) {
        detailsConfig.push({ title: 'physician', field: 'physicians', width: '100%' });
    }

    return detailsConfig;
}
