import { useHasPermission } from 'common/hooks/use-has-permission';
import React from 'react';
import { maskConstants } from 'common/constants/common';
import { ISecureDataProps } from './types';

export const SecureData = ({ permission, isMask = false, children }: ISecureDataProps) => {
    const hasAccess = useHasPermission(permission);

    if (hasAccess) {
        return children;
    }

    if (isMask) {
        const changedChildren = React.Children.map(children, (child) => {
            return React.cloneElement(child as any, { value: maskConstants.SENSITIVE_INFO, mask: null });
        });

        return changedChildren;
    } else {
        return <></>;
    }
};

export default SecureData;
