import DropdownOption from 'common/models/dropdown-option';
import { IListResult } from 'common/models/list-result';
import { INote } from 'common/models/note';
import { IUserBaseInfo } from 'common/services/api/user/types';

export enum TherapistCommunicationType {
    Undefined = 'Undefined',
    Email = 'Email',
    Sms = 'Sms',
}

export interface IMessageItem {
    id: number;
    type: TherapistCommunicationType;
    subject?: string;
    cc?: string;
    bcc?: string;
    message: string;
    isSystemMessage: boolean;
    createdAt: Date;
    sender: IUserBaseInfo;
    to: string;
}

export interface INewEmailModel {
    to?: Array<string>;
    subject?: string;
    message?: string;
    cc?: Array<string>;
    bcc?: Array<string>;
    code?: string;
}

export interface INewSmsModel {
    phoneNumber: string;
    text: string;
}

export interface IAddEditInterviewModel {
    hostId: string;
    location: LocationType;
    duration: number;
    meetingLink?: string;
    password?: string;
    participants?: string[];
    dateTime?: string;
    date?: string;
    time?: string;
    instructions?: string;
    outcome?: string;
    code?: string;

    selectedParticipantsOptions: DropdownOption[];
    selectedHost: DropdownOption;
}

export interface IInterview {
    id: number;
    host: IUserBaseInfo;
    location: LocationType;
    duration: number;
    meetingLink?: string;
    password?: string;
    dateTime: string;
    status: string;
    instructions?: string;
    outcome?: string;
    participants?: IUserBaseInfo[];
}

export interface ICommunicationSearchResult {
    note: IListResult<INote>;
    message: IListResult<IMessageItem>;
    interview: IListResult<IInterview>;
}

export enum LocationType {
    Office = 'Office',
    Virtual = 'Virtual',
}

export enum InterviewStatus {
    NEW = 'New',
    SCHEDULED = 'Scheduled',
    COMPLETED = 'Completed',
    CANCELLED = 'Cancelled ',
}

export interface ISetStatusRequest {
    status: InterviewStatus;
}
