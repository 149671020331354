export const ErrorCodes = {
    PATIENT_ALREADY_EXISTS_FOR_CUSTOMER: 'PATIENT_ALREADY_EXISTS_FOR_CUSTOMER',
    CUSTOMER_DOES_NOT_SET: 'CUSTOMER_DOES_NOT_SET',
    REASON_COMMENT_IS_EMPTY: 'REASON_COMMENT_IS_EMPTY',
    INVALID_REASON: 'INVALID_REASON',
    INVALID_THERAPIST_STATUS: 'INVALID_THERAPIST_STATUS',
    CONFLICT_WITH_CURRENT_ACTIVE_RATE: 'ADD_CONFLICT_WITH_CURRENT_ACTIVE_RATE',
    CONFLICT_WITH_FUTURE_ACTIVE_RATE: 'ADD_CONFLICT_WITH_FUTURE_ACTIVE_RATE',
    NO_RECORDS_TO_EXPORT: 'NO_RECORDS_TO_EXPORT',
    THERAPIST_NOT_AUTHORIZED_FOR_DEPARTMENT: 'THERAPIST_NOT_AUTHORIZED_FOR_DEPARTMENT',
};
