import { ThunkAction } from 'redux-thunk';
import { Action, ActionCreator } from 'redux';
import { IGetFileAction } from './document-viewer-action-types';
import { InfiniteServerClient } from 'common/helpers/http-clients';
import { FilterHelper } from 'common/helpers/filter-helper';

type AsyncActionCreator<A, Input, Result> = ActionCreator<ThunkAction<Promise<A>, Result, Input, Action<A>>>;

export const getFileActionCreator: AsyncActionCreator<IGetFileAction, number, Blob> = (id: number) => {
    return function () {
        return InfiniteServerClient.get(`/files/${id}`).then(
            (response) => {
                const result: IGetFileAction = { file: response.data, type: 'GET_FILE' };

                return result;
            },
            (error) => {
                throw error.response.data;
            }
        );
    };
};

export const getSeveralFilesInZipFileActionCreator: AsyncActionCreator<IGetFileAction, number, Blob> = (
    fileIds: number[],
    customerArchiveName?: string
) => {
    return function () {
        const queryParams = FilterHelper.toURLQueryParams({
            fileIds,
            customerArchiveName,
        }).join('&');

        return InfiniteServerClient.get(`/files/zip?${queryParams}`).then(
            (response) => {
                const result: IGetFileAction = { file: response.data, type: 'GET_FILE' };

                return result;
            },
            (error) => {
                throw error.response.data;
            }
        );
    };
};
