import { InfiniteServerClient } from 'common/helpers/http-clients';
import { IPopulation } from './types';

export class PopulationService {
    getAll(): Promise<IPopulation[]> {
        return InfiniteServerClient.get<IPopulation[]>('/populations').then(
            (response) => {
                return response.data;
            },
            (error) => {
                throw error;
            }
        );
    }
}

export default new PopulationService();
