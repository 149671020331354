import { useCallback, useEffect, useRef } from 'react';
const debounce = require('lodash.debounce');

export const useCustomColumnMove = (gridRef: any, gridRefWrapper: any) => {
    const dragging = useRef(false);
    const previousClientX = useRef(0);
    const currentColId = useRef('');
    const columnMoved = useRef(true);
    const previewsDirection = useRef(null);
    const previewsMovedColumn = useRef(null);

    useEffect(() => {
        const gridApi = gridRef?.current?.api;
        if (!gridApi) {
            return;
        }
        const handleColumnMoved = () => {
            columnMoved.current = true;
            if (dragging.current) {
                window.dispatchEvent(new Event('preventOpenMenu'));
            }
        };
        gridApi.addEventListener('columnMoved', handleColumnMoved);

        return () => {
            gridApi.removeEventListener('columnMoved', handleColumnMoved);
        };
    }, [gridRef?.current?.api]);

    const handleMouseMove = useCallback(
        debounce(
            (e: any) => {
                const columnApi = gridRef?.current?.columnApi;
                if (!dragging.current || !columnApi || !columnMoved.current) {
                    return;
                }

                const direction = e.clientX - previousClientX.current;
                previousClientX.current = e.clientX;
                const currentDirection = direction > 0 ? 'Right' : 'Left';
                const overMouseColId = e.target.closest('.table-dropdown-filter-wrapper')?.getAttribute('data-set-colid');

                const isDirectionChanged = () => {
                    return previewsMovedColumn.current === overMouseColId && previewsDirection.current !== currentDirection;
                };
                const isTargetChanged = () => {
                    return (
                        currentColId.current !== overMouseColId &&
                        overMouseColId &&
                        currentColId.current &&
                        previewsMovedColumn.current !== overMouseColId
                    );
                };
                const getColumnIndexByColId = (colId: string): number => {
                    return gridRef?.current?.api
                        .getColumnDefs()
                        .findIndex((col: any) => col.colId === colId && typeof col.pinned !== 'string');
                };

                if (isTargetChanged() || isDirectionChanged()) {
                    columnMoved.current = false;
                    previewsDirection.current = currentDirection;
                    previewsMovedColumn.current = overMouseColId;
                    const currentIndex = getColumnIndexByColId(currentColId.current);
                    const newIndex = getColumnIndexByColId(overMouseColId);
                    if (currentIndex !== -1 && newIndex !== -1) {
                        columnApi.moveColumn(currentColId.current, newIndex);
                    }
                }
            },
            [20]
        ),
        [gridRef?.current?.api, gridRef?.current?.columnApi]
    );

    const headerRef = useRef(null);
    const handleMouseDown = useCallback((e) => {
        // Column Selected
        if (e.target.closest('.ag-react-container')) {
            currentColId.current = e.target.closest('.table-dropdown-filter-wrapper')?.getAttribute('data-set-colid');
        }
        previousClientX.current = e.clientX;
        dragging.current = true;

        const header: HTMLDivElement = e.target.closest('.ag-header');
        if (header) {
            header.classList.add('move');
            headerRef.current = header;
        }
    }, []);

    const handleMouseUp = useCallback(() => {
        dragging.current = false;
        if (headerRef?.current) {
            headerRef?.current.classList.remove('move');
            headerRef.current = null;
        }
        columnMoved.current = true;
        previewsDirection.current = null;
        previewsMovedColumn.current = null;
    }, []);

    useEffect(() => {
        const gridApi = gridRefWrapper.current;

        if (!gridApi) {
            return;
        }
        gridApi.addEventListener('mousedown', handleMouseDown);
        window.addEventListener('mouseup', handleMouseUp);
        gridApi.addEventListener('mousemove', handleMouseMove);

        return () => {
            gridApi.removeEventListener('mousedown', handleMouseDown);
            window.removeEventListener('mouseup', handleMouseUp);
            gridApi.removeEventListener('mousemove', handleMouseMove);
        };
    }, [handleMouseDown, handleMouseUp, handleMouseMove, gridRefWrapper.current]);
};
