import React from 'react';
import './header.scss';

type HeaderLeftSideProps = {
    className?: string;
    children?: React.ReactNode;
};
const HeaderLeftSide = ({ children, className = '' }: HeaderLeftSideProps) => {
    return <div className={`table-header-top-bar__left-section ${className}`}>{children}</div>;
};

export default HeaderLeftSide;
