import FieldList from 'common/components/field-list/field-list';
import { FieldListWrapperProps } from 'common/components/field-list/types';
import React from 'react';
import IndividualDays from './individual-days';

const defaultStartValue = '09:00';
const defaultEndDate = '17:00';
const IndividualDaysList = ({ data: individualDays, errors, onChange, addable, name = 'individualDays' }: FieldListWrapperProps) => {
    return (
        <FieldList
            name={name}
            isDragNDrop={false}
            values={individualDays}
            defaultValue={{ date: '', startTime: defaultStartValue, endTime: defaultEndDate }}
            addButtonText={addable ? 'Add Date' : null}
            onChange={(values: any[]) => {
                onChange({ name: name, value: values });
            }}
            renderInput={(value: any, index: number) => {
                return (
                    <IndividualDays
                        errors={errors?.[name]?.[index]}
                        onChange={(value: any) => {
                            individualDays[index] = { ...value };
                            onChange({ name: name, value: [...individualDays] });
                        }}
                        value={value}
                    />
                );
            }}
        />
    );
};

export default IndividualDaysList;
