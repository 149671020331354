import React from 'react';
import { ICellRendererParams } from 'ag-grid-community';
import { IInterview, LocationType } from 'common/services/api/communication/types';

export const LocationRenderer = (props: ICellRendererParams) => {
    const data: IInterview = props.data as IInterview;

    if (data.location === LocationType.Office) {
        return <span>{data.location}</span>;
    } else {
        return (
            <span className="location-link-wrapper">
                {data.location}
                <a className="location-link" href={data.meetingLink} target="_blank" rel="noopener noreferrer">
                    <span className="icon icon-video-camera"></span>
                </a>
            </span>
        );
    }
};
