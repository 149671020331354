import React from 'react';
import FieldList from './field-list';
import FieldSet from '../field-set/field-set';
import { phoneTypes as defaultPhoneTypes } from 'common/constants/patients/phone-types';
import PhoneTypeInput from 'common/components/phone-type-input/phone-type-input';
import { IPhone } from 'features/shared/types';
import { FieldListWrapperProps } from './types';

const FieldListPhones = ({
    data: phones,
    errors,
    onChange,
    name = 'phones',
    phoneTypes = defaultPhoneTypes,
    tabIndex,
    className = 'required-field-star',
}: FieldListWrapperProps) => {
    return (
        <FieldList
            name={name}
            isDragNDrop
            values={phones}
            defaultValue={{ type: phoneTypes[0].value, number: '' }}
            addButtonText="Add Phone"
            onChange={(values: any[]) => {
                onChange({ name: name, value: values });
            }}
            renderInput={(value: any, index: number) => (
                <FieldSet
                    name={name}
                    errors={errors}
                    customClass={`edit-phone ${index === 0 ? 'main-item' : ''} ${className ?? ''}`}
                    renderError={(error: any) => error[index]?.number && error[index]?.number[0]}
                >
                    <PhoneTypeInput
                        tabIndex={tabIndex}
                        errors={errors[name]?.[index]}
                        phoneTypes={phoneTypes}
                        onChange={(value: IPhone) => {
                            phones[index] = { ...value };
                            onChange({ name: name, value: [...phones] });
                        }}
                        value={value}
                    />
                </FieldSet>
            )}
        />
    );
};

export default FieldListPhones;
