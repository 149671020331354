import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

export function useSelectorOrFetch<T>(selector: any, fetchMethod: Function): T[] {
    const data = useSelector<any, T[]>(selector);
    const [result, setResult] = useState<T[]>(data);
    const dispatch = useDispatch();

    useEffect(() => {
        if (!data) {
            dispatch(fetchMethod());
        }

        setResult(data);
    }, [data, dispatch, fetchMethod]);

    return result || [];
}
