import React, { useState } from 'react';
import Field from './field';
import { IField, ITextAreaFieldProps } from './types';

const TextAreaField = (props: IField & ITextAreaFieldProps) => {
    const [isFocused, toggleFocus] = useState<boolean>(false);
    const [value, setValue] = useState(props.value ?? '');

    const handleOnFocus = () => {
        toggleFocus(true);
    };

    const handleOnBlur = () => {
        toggleFocus(false);
    };

    const handleChange = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
        setValue(event.target.value);
        props.onChange({ value: event.target.value, name: props.name });
    };

    return (
        <Field {...props} value={value ?? ''} isFocused={isFocused} customClass="text-area-field">
            <textarea
                tabIndex={props.tabIndex}
                autoFocus={props.isFocused}
                maxLength={props.maxLength}
                className={`${props.inputClass ?? ''} ${props.name ?? ''}`}
                onFocus={handleOnFocus}
                onBlur={handleOnBlur}
                required={props.required}
                onChange={handleChange}
                value={value}
            />
        </Field>
    );
};

export default TextAreaField;
