import React, { useState, useCallback } from 'react';
import LoggingInfo from './components/logging-info/logging-info';
import LoggingTable from './components/logging-table/logging-table';
import './style.scss';

const LoggingPage = () => {
    const [loggingId, setLoggingId] = useState(null);

    const handleClickOnRow = useCallback((id: number) => {
        setLoggingId(id);
    }, []);

    return (
        <div className="therapist-page table-page logging-page">
            <header>
                <div className="header-wrapper">
                    <div className="title-wrapper">
                        <h1>User Activity </h1>
                    </div>
                </div>
            </header>
            {loggingId > 0 && (
                <LoggingInfo
                    onCancel={() => {
                        setLoggingId(null);
                    }}
                    loggingId={loggingId}
                />
            )}
            <LoggingTable onCellClick={handleClickOnRow} />
        </div>
    );
};

export default LoggingPage;
