import RightSideBar from 'common/components/right-side-bar/right-side-bar';
import { ValidationHelper } from 'common/helpers/validation-helper';
import DropdownOption from 'common/models/dropdown-option';
import { IFormItem } from 'common/models/types';
import CountyService from 'common/services/api/county/county-service';
import { IEntityDetailsBasicInfo } from 'common/services/api/entity/types';
import { EntityErrorCodes } from 'features/shared/components/contact-info/types';
import FocusTrap from 'focus-trap-react';
import React, { useEffect, useState } from 'react';
import EntityBasicInfoForm from '../entity-basic-info-form/entity-basic-info-form';
import { CreateEntityFormProps } from './types';
import { useTabIndex } from 'common/hooks/use-tab-index';
import { toast } from 'react-toastify';

const CreateEntityForm: React.FC<CreateEntityFormProps> = ({ onClose, onSave }) => {
    const [basicInfo, setBasicInfo] = useState<IEntityDetailsBasicInfo>({
        id: null,
        name: null,
        functionLinks: [],
        address1: null,
        address2: null,
        city: null,
        state: null,
        zip: null,
        phone: null,
        parent: null,
        npiNumber: null,
        counties: [],
    });

    const [basicInfoErrors, setBasicInfoErrors] = useState({});

    const handleOnSaveClick = () => {
        onSave(basicInfo).catch((error) => {
            if (error?.response?.status === 422) {
                toast.error(error?.response?.data);

                return;
            }

            switch (error?.response?.data?.errorCode) {
                case EntityErrorCodes.EntityUniqueConstraintFailed: {
                    const errorObject = { name: ['Name already exists'] };
                    setBasicInfoErrors(errorObject);
                    break;
                }
                default:
                    if (error?.response?.data) {
                        const errors = ValidationHelper.handleErrorResponse(error.response.data);
                        if (errors) {
                            setBasicInfoErrors(errors);
                        }
                    }
                    break;
            }
        });
    };

    const [counties, setCounties] = useState([]);

    useEffect(() => {
        handleBasicInfoChange({ name: 'countyIds', value: [] });
        if (basicInfo.state?.id) {
            CountyService.getByState(basicInfo.state?.id).then((counties) => {
                setCounties(counties.map((x) => new DropdownOption(x.id, x.name)));
            });
        }
    }, [basicInfo.state]);

    const handleBasicInfoChange = (data: IFormItem) => {
        setBasicInfo((prevInfo) => ({ ...prevInfo, [data.name]: data.value }));
        setBasicInfoErrors((prevError) => ({ ...prevError, [data.name]: undefined }));
    };

    const handleCancelClick = () => {
        setBasicInfoErrors({});
        onClose();
    };

    const { getTabIndex } = useTabIndex();

    return (
        <div className="create-entity">
            <FocusTrap focusTrapOptions={{ allowOutsideClick: true }}>
                <RightSideBar title="Create Entity" onCancel={handleCancelClick} onSave={handleOnSaveClick}>
                    <div className="create-form">
                        <EntityBasicInfoForm
                            getTabIndex={getTabIndex}
                            entityBasicInfo={basicInfo}
                            errors={basicInfoErrors}
                            onChange={handleBasicInfoChange}
                            options={{
                                counties: counties,
                            }}
                        />
                    </div>
                </RightSideBar>
            </FocusTrap>
            <div className="right-sidebar-overlay"></div>
        </div>
    );
};

export default CreateEntityForm;
