import React from 'react';
import { Counter } from '../../counter/counter';
import { TabItemProps } from './types';

const TabItem = ({ count, label }: TabItemProps) => {
    return (
        <>
            {label}
            {count ? <Counter className="ml-10">{count}</Counter> : null}
        </>
    );
};

export default React.memo(TabItem);
