import React, { useEffect, useMemo, useState, useRef, useCallback } from 'react';
import { NavigationRoutes } from 'models/routes/navigation-routes';
import { useHistory } from 'react-router';
import { CellClickedEvent, GridReadyEvent } from 'ag-grid-community/dist/lib/events';
import AgTable from 'common/components/ag-table/ag-table';
import SearchFilterInput from 'common/components/header/search-filter-input/search-filter-input';
import ColumnsVisibleFilterSelect from 'common/components/header/сolumns-visible-filter-select/columns-visible-filter-select';
import HeaderWrapper from 'common/components/header/header-wrapper';
import HeaderLeftSide from 'common/components/header/header-left-side';
import { IParamsWatcher } from 'common/components/ag-table/types';
import ExportButton from 'common/components/header/export-button/export-button';
import HeaderRightSide from 'common/components/header/header-right-side';
import ClearFiltersButton from 'common/components/header/clear-filters-button/clear-filters-button';
import { getTableData, getFilterData, getExportData } from 'common/services/api/tabel-fetch-service';
import { GridApi, ColumnApi } from 'ag-grid-community';
import { ExpiredDocumentReportType, ITherapistCustomFilters, TherapistTableProps } from './type';
import { useSearchInput } from 'common/hooks/use-serch-input';
import DisplayValue from 'common/components/display-value/display-value';
import { dateTimeConstants, maskConstants } from 'common/constants/common';
import { TherapistListFilters } from './therapist-list-filters';
import Tooltip from 'react-tooltip-lite';
import './therapist-list-page.scss';
import { LocalStoreRepository } from 'common/helpers/repository/local-store-repository';
import { DateTimeHelper } from 'common/helpers/date-time-helper';
import { LocalTableRepository } from 'common/helpers/repository/local-table-repository';
import { ExportType } from 'common/constants/export-options';
import { IExportOption } from 'common/components/header/type';
import moment from 'moment';
import therapistService from 'common/services/api/therapist/therapist-service';
import ToggleViewMode from 'common/components/toggle-view-mode/toggle-view-mode';
import { DisplayMode } from 'common/components/toggle-view-mode/types';
import { DetailsNavigationHelper } from 'common/helpers/details-navigation-helper';
import TherapistHireFilters from './therapist-hire-filters/therapist-hire-filters';
import { useSelector } from 'react-redux';
import { IToggleFeatureList } from 'common/services/api/settings/types';
import { PermissionType } from 'common/models/permission-type';

const handleFetchData = getTableData('therapists');
const handleFetchFilters = getFilterData('therapists/filter/source');
const handleExportData = getExportData('therapists/export');
const tableRepository = new LocalTableRepository('therapist-table', 'v4');
const customFiltersRepository = new LocalStoreRepository('therapist-table-custom-filters', 'v4');

const defaultFilters: ITherapistCustomFilters = {
    search: null,
    assignedDepartment: [],
    assignedSubDepartment: [],
    employmentStatus: [],
    complianceStatus: [],
    hiringDays: null,
};

let loadedFilters: ITherapistCustomFilters = customFiltersRepository.load() ?? defaultFilters;

if (loadedFilters && !Array.isArray(loadedFilters?.assignedDepartment)) {
    loadedFilters.assignedDepartment = [];
}
if (loadedFilters && !Array.isArray(loadedFilters?.assignedSubDepartment)) {
    loadedFilters.assignedSubDepartment = [];
}

const TherapistListPage: React.FC<TherapistTableProps> = ({ updateTable, onMapClick }) => {
    const { search, handleSearchInputChange, setSearch } = useSearchInput(loadedFilters?.search, (value: string) => {
        setFilters((prev) => ({ ...prev, search: value }));
    });
    const [gridApi, setGridApi] = useState<GridApi>(null);
    const [gridColumnApi, setColumnApi] = useState<ColumnApi>(null);
    const [filters, setFilters] = useState(loadedFilters);
    const paramsWatcher = useRef<IParamsWatcher>();
    const history = useHistory();

    const userPermissions = useSelector<string[]>((state: any) => state.auth.user.permissions || []) as string[];

    const hasSecureProviderInfoPermission: boolean = userPermissions.includes(PermissionType.AccessTherapistSecureData);

    const featureList: IToggleFeatureList = useSelector((state: any) => state.siteSettings?.settings?.features || {});

    const hideColumns = {
        dateOfBirth: hasSecureProviderInfoPermission,
    };

    useEffect(() => {
        customFiltersRepository.save(filters);
        loadedFilters = filters;
    }, [filters]);

    useEffect(() => {
        if (updateTable && gridApi) {
            gridApi.onFilterChanged();
        }
    }, [updateTable, gridApi]);

    const exportOptions: IExportOption[] = [
        { value: ExportType.XLSX, title: 'Current View' },
        { value: ExpiredDocumentReportType, title: 'Expired Document Report' },
    ];

    const generateExportFileName = (type: string) => {
        switch (type?.toLowerCase()) {
            case ExportType.XLSX:
                return `ProvidersExport_${moment().format(dateTimeConstants.MMDDYYYY)}.${type.toLowerCase()}`;
            case ExpiredDocumentReportType:
                return `ExpiredDocumentsExport_${moment().format(dateTimeConstants.MMDDYYYY)}.${ExportType.XLSX.toLowerCase()}`;
            default:
                break;
        }
    };

    const handleExport = (type: string, watcher: IParamsWatcher) => {
        switch (type) {
            case ExportType.XLSX:
                return handleExportData(type, watcher);
            case ExpiredDocumentReportType:
                return therapistService.exportExpiredDocuments(ExportType.XLSX, null);
            default:
                throw new Error('Not supported');
        }
    };

    const gridOptions = useMemo(
        () => ({
            blockLoadDebounceMillis: 600,
            onCellClicked: (event: CellClickedEvent) => {
                history.push(
                    { pathname: NavigationRoutes.therapistDetailsRoute(event.data.id) },
                    DetailsNavigationHelper.getStateForDetails(paramsWatcher.current, event.node.rowIndex)
                );
            },
            columnDefs: [
                {
                    field: 'fullName',
                    headerName: 'Name',
                    width: 300,
                },
                {
                    field: 'state',
                    headerName: 'State',
                    filter: 'agCustomFilter',
                    width: 180,
                    filterParams: {
                        isNullable: true,
                        debounceMs: 0,
                    },
                },
                {
                    field: 'phone',
                    headerName: 'Phone',
                    sortable: false,
                    width: 180,
                    cellRenderer: 'phoneFormat',
                },
                {
                    field: 'therapistType',
                    headerName: 'Type',
                    filter: 'agCustomFilter',
                    filterParams: {
                        debounceMs: 0,
                    },
                    sortable: false,
                    width: 204,
                },
                {
                    field: 'email',
                    headerName: 'Email',
                    width: 204,
                },
                {
                    field: 'internalNote',
                    headerName: 'General Note',
                    width: 360,
                },
                {
                    field: 'zip',
                    headerName: 'Zip',
                    filter: 'agCustomFilter',
                    width: 104,
                    filterParams: {
                        isNullable: true,
                        debounceMs: 0,
                    },
                },
                {
                    columnGroupShow: !hasSecureProviderInfoPermission ? 'hide' : '',
                    field: 'dateOfBirth',
                    headerName: 'Date Of Birth',
                    filter: 'agDateColumnFilter',
                    valueFormatter: (props: any) => DateTimeHelper.format(props.value),
                    filterParams: {
                        isDateTime: true,
                    },
                    width: 154,
                    hideForExport: !hasSecureProviderInfoPermission,
                },
                {
                    field: 'preferredZip',
                    headerName: 'Preferred Zip Code',
                    filter: 'agCustomFilter',
                    sortable: false,
                    width: 220,
                    filterParams: {
                        isNullable: true,
                        debounceMs: 0,
                    },
                },
                {
                    field: 'status',
                    headerName: 'Status',
                    filter: 'agCustomFilter',
                    filterParams: {
                        debounceMs: 0,
                    },
                    width: 240,
                },
                {
                    field: 'complianceStatus',
                    headerName: 'Assignment status',
                    sortable: false,
                    width: 252,
                    cellRenderer: 'complianceStatus',
                },
                {
                    field: 'assignedDepartment',
                    headerName: 'Assigned Department',
                    sortable: false,
                    width: 220,
                },
                {
                    field: 'assignedSubDepartment',
                    headerName: 'Assigned Sub Department',
                    sortable: false,
                    width: 260,
                },
                {
                    field: 'inactiveReasons',
                    headerName: 'Inactive Reason',
                    filter: 'agCustomFilter',
                    sortable: false,
                    width: 360,
                    filterParams: {
                        isNullable: true,
                        debounceMs: 0,
                    },
                },
                {
                    field: 'assignedTo',
                    headerName: 'Assigned To',
                    filter: 'agCustomFilter',
                    width: 300,
                    filterParams: {
                        debounceMs: 0,
                    },
                },
                {
                    field: 'referralSource',
                    headerName: 'Referral Source',
                    filter: 'agCustomFilter',
                    width: 240,
                    filterParams: {
                        debounceMs: 0,
                    },
                },
                {
                    field: 'language',
                    headerName: 'Language',
                    filter: 'agCustomFilter',
                    sortable: false,
                    width: 120,
                    filterParams: {
                        isNullable: true,
                        debounceMs: 0,
                    },
                },
                {
                    field: 'preferredDepartment',
                    headerName: 'Preferred Department',
                    filter: 'agCustomFilter',
                    sortable: false,
                    width: 220,
                    filterParams: {
                        isNullable: true,
                        debounceMs: 0,
                    },
                },
                {
                    field: 'preferredSubDepartment',
                    headerName: 'Preferred Sub Department',
                    filter: 'agCustomFilter',
                    sortable: false,
                    width: 250,
                    filterParams: {
                        isNullable: true,
                        debounceMs: 0,
                    },
                },
                {
                    field: 'preferredPopulation',
                    headerName: 'Preferred Population',
                    filter: 'agCustomFilter',
                    sortable: false,
                    width: 220,
                    filterParams: {
                        isNullable: true,
                        debounceMs: 0,
                    },
                },
                {
                    field: 'county',
                    headerName: 'County',
                    filter: 'agCustomFilter',
                    sortable: false,
                    width: 156,
                    filterParams: {
                        isNullable: true,
                        debounceMs: 0,
                    },
                },
                {
                    field: 'profileStatus',
                    headerName: 'Profile Status',
                    filter: 'agCustomFilter',
                    width: 180,
                    filterParams: {
                        debounceMs: 0,
                    },
                },
                {
                    field: 'createdBy',
                    headerName: 'Created by',
                    filter: 'agCustomFilter',
                    width: 300,
                    filterParams: {
                        isNullable: true,
                        debounceMs: 0,
                    },
                },
                {
                    field: 'createdAt',
                    headerName: 'Date Created',
                    filter: 'agDateColumnFilter',
                    valueFormatter: (props: any) => DateTimeHelper.format(props.value),
                    filterParams: {
                        isDateTime: true,
                    },
                    initialSort: 'desc',
                    width: 155,
                },
            ],
            defaultColDef: {
                filterParams: {
                    onFetchFilters: handleFetchFilters,
                },
            },
            frameworkComponents: {
                phoneFormat: (props: any) => {
                    const cellValue = props.valueFormatted ? props.valueFormatted : props.value;

                    return <DisplayValue id="phone" placeholder="" value={cellValue} mask={maskConstants.PHONE_NUMBER} />;
                },
                complianceStatus: (props: any) => {
                    const cellValue = props.valueFormatted ? props.valueFormatted : props.value;

                    const renderObjectValues = (value: any) => {
                        const valuesArray = [];
                        for (const prop in value) {
                            valuesArray.push({ name: prop, count: value[prop] });
                        }

                        return valuesArray.map((item) => (
                            <span key={item.name} className={`complience-status-wrapper ${item.name.replace(' ', '-').toLowerCase()}`}>
                                <span className="complience-count">{item.count}</span>
                                <span className="complience-name">{item.name}</span>
                            </span>
                        ));
                    };

                    return (
                        <Tooltip
                            arrow={false}
                            content={renderObjectValues(cellValue)}
                            direction="down-start"
                            distance={-5}
                            mouseOutDelay={0}
                            tipContentHover={false}
                            hoverDelay={0}
                            className="compliance-status-cell"
                            tipContentClassName="tooltip-compliance-status"
                        >
                            {renderObjectValues(cellValue)}
                        </Tooltip>
                    );
                },
            },
        }),
        [history, hasSecureProviderInfoPermission]
    );

    const handleFiltersClear = () => {
        setSearch('');
        setFilters({ ...defaultFilters });
    };

    const handleGridReady = (params: GridReadyEvent) => {
        setGridApi(params.api);
        setColumnApi(params.columnApi);
    };

    const handleFiltersChange = (filterValues: any) => {
        setFilters((prev) => ({ ...prev, ...filterValues }));
    };

    const handleMapClick = useCallback(() => {
        let zip: string;
        gridApi.forEachNode((node) => {
            if (zip === undefined && node.data.zip?.length > 0) {
                zip = node.data.zip;
            }
        });
        onMapClick(filters, zip);
    }, [gridApi, filters, onMapClick]);

    return (
        <>
            <div className="main-info-wrapper ag-table-wrapper therapist-list">
                <HeaderWrapper>
                    <HeaderLeftSide>
                        <SearchFilterInput title="Providers" value={search} onInput={handleSearchInputChange} />
                        <TherapistListFilters filters={filters} gridApi={gridApi} onFiltersChange={handleFiltersChange} />
                        <ColumnsVisibleFilterSelect title="Providers" gridApi={gridApi} gridColumnApi={gridColumnApi} />
                        {featureList.isNewHiresFiltrationEnabled && (
                            <TherapistHireFilters filters={filters} gridApi={gridApi} onFiltersChange={handleFiltersChange} />
                        )}
                    </HeaderLeftSide>
                    <HeaderRightSide>
                        <ToggleViewMode activeMode={DisplayMode.List} onListClick={null} onMapClick={handleMapClick} />
                        <ExportButton
                            options={exportOptions}
                            title="Providers"
                            fileNameGenerator={generateExportFileName}
                            paramsWatcher={paramsWatcher}
                            onExportData={handleExport}
                        />
                        <ClearFiltersButton
                            title="Providers"
                            gridApi={gridApi}
                            gridColumnApi={gridColumnApi}
                            onClick={handleFiltersClear}
                        />
                    </HeaderRightSide>
                </HeaderWrapper>
                <AgTable
                    onGridReady={handleGridReady}
                    gridOptions={gridOptions}
                    onFiltersLoadedFromUrl={handleFiltersClear}
                    repository={tableRepository}
                    customFilters={filters}
                    paramsWatcher={paramsWatcher}
                    onFetchData={handleFetchData}
                    hideColumns={hideColumns}
                />
            </div>
        </>
    );
};

export default TherapistListPage;
