import React, { useCallback } from 'react';
import RightSideBarForm from 'common/components/right-side-bar/right-side-bar-form';
import useFormSidebar from 'common/hooks/use-form-sidebar';
import { IFormSidebar } from 'common/models/types';
import AddEditSchoolForm from '../add-edit-school-form/add-edit-school-form';
import DropdownOption from 'common/models/dropdown-option';
import { ISchoolNewRequest, ISchoolNewRequestDataModel, ISchoolRequestDetails } from 'common/services/api/school/types';

const AddEditSchoolSidebar = ({ onClose, onSave, data: school, options }: IFormSidebar<ISchoolNewRequest, ISchoolRequestDetails>) => {
    const [data, error, handleChangeData, handleCancelClick, handleSaveClick] = useFormSidebar<ISchoolNewRequestDataModel>(
        {
            year: modelPropertyToForm(school?.year),
            serviceTypeId: modelPropertyToForm(school?.serviceType),
            languageId: modelPropertyToForm(school?.language),
            bidRankId: modelPropertyToForm(school?.bidRank),
            populationId: modelPropertyToForm(school?.population),
            priorityLevelId: modelPropertyToForm(school?.priorityLevel),
            contractTypeId: modelPropertyToForm(school?.contractType),
            doeContactId: modelPropertyToForm(school?.doeContact),
            startDate: modelPropertyToForm(school?.startDate),
            endDate: modelPropertyToForm(school?.endDate),
            schoolEntityId: modelPropertyToForm(school?.placeOfService, true),
            posContactId: modelPropertyToForm(school?.posContact),
            providersCount: school?.providers?.length.toString() || '1',
            specialInstructions: school?.specialInstructions || '',
            providers: modelPropertyToForm(school?.providers),
        },
        {
            onClose,
            onSave: (schoolFormData) => {
                return onSave({
                    year: schoolFormData.year?.value?.year,
                    serviceTypeId: schoolFormData.serviceTypeId?.value,
                    languageId: schoolFormData.languageId?.value,
                    bidRankId: schoolFormData.bidRankId?.value,
                    providers: schoolFormData.providers,
                    providersCount: schoolFormData.providers?.length,
                    populationId: schoolFormData.populationId?.value,
                    priorityLevelId: schoolFormData.priorityLevelId?.value,
                    contractTypeId: schoolFormData.contractTypeId?.value,
                    doeContactId: schoolFormData.doeContactId?.value,
                    startDate: schoolFormData.startDate,
                    endDate: schoolFormData.endDate,
                    schoolEntityId: schoolFormData.schoolEntityId?.value?.id,
                    POSContactId: schoolFormData.posContactId?.value,
                    specialInstructions: schoolFormData.specialInstructions,
                });
            },
        }
    );

    const generateTitle = useCallback(() => {
        return school ? 'Edit School' : 'Create School';
    }, [school]);

    return (
        <div className="add-edit-user-sidebar">
            <RightSideBarForm title={generateTitle()} onCancel={handleCancelClick} onSave={handleSaveClick}>
                <AddEditSchoolForm data={data} errors={error} onChange={handleChangeData} options={options} />
            </RightSideBarForm>
        </div>
    );
};

const modelPropertyToForm = (obj: any, useObjectAsDropdownValue = false) => {
    let result: any = {};

    if (obj?.id !== undefined && obj?.name) {
        const value = useObjectAsDropdownValue ? obj : obj?.id;
        result = new DropdownOption(value, obj?.name);
    } else {
        result = obj;
    }

    return result;
};

export default React.memo(AddEditSchoolSidebar);
