import React, { ReactNode } from 'react';
import BreadcrumbItem from './breadcrumb-item';
import BreadcrumbSeparator from './breadcrumb-separator';
import './breadcrumb.scss';
import { BreadcrumbProps } from './types';

const Breadcrumb = ({ separator = '>', ...props }: BreadcrumbProps) => {
    let children: ReactNode[] = React.Children.toArray(props.children);

    children = children.map((child, index) => <BreadcrumbItem key={`breadcrumb_item${index}`}>{child}</BreadcrumbItem>);

    const lastIndex = children.length - 1;

    children = children.reduce((acc: ReactNode[], child, index) => {
        const notLast = index < lastIndex;
        if (notLast) {
            acc.push(child, <BreadcrumbSeparator key={`breadcrumb_sep${index}`}>{separator}</BreadcrumbSeparator>);
        } else {
            acc.push(child);
        }

        return acc;
    }, []);

    return <ol>{children}</ol>;
};

export default Breadcrumb;
