import DropdownAutocomplete from 'common/components/field/dropdown/dropdown-autocomplete';
import { useLanguageData } from 'common/hooks/use-language-data';
import DropdownOption from 'common/models/dropdown-option';
import React, { useCallback, useState } from 'react';
import { LanguageAutocompleteProps } from './types';

export const LanguageAutocomplete: React.FC<LanguageAutocompleteProps> = ({ onChange, value, cleanable = true }) => {
    const [languageSearchTerm, setLanguageSearchTerm] = useState('');
    const languagesOptions = useLanguageData().map((item) => new DropdownOption(item.id, item.name));

    const determineOptions = useCallback(() => {
        return languageSearchTerm
            ? languagesOptions.filter((language) => language.label.toLowerCase().includes(languageSearchTerm.toLowerCase()))
            : languagesOptions;
    }, [languageSearchTerm, languagesOptions]);

    return (
        <DropdownAutocomplete
            tabIndex=""
            items={determineOptions()}
            cleanable={cleanable}
            label="Language"
            multiselect
            defaultSelectedLabel="All"
            searchPlaceholder={'Language'}
            value={languagesOptions.filter((item) => value.some((language) => language === item.value))}
            onChange={setLanguageSearchTerm}
            onSelect={onChange}
        />
    );
};

export default React.memo(LanguageAutocomplete);
