import React from 'react';
import { ActionButtonProps } from '../type';

const AddButton = ({ title = '', onClick }: ActionButtonProps) => {
    return (
        <>
            <hr className="vertical-separator" />
            <button
                id={`${title.replace(/\\s/g, '')}AddButton`}
                className="base-text-button-with-icon table-header-add-button"
                onClick={onClick}
            >
                <span className="icon icon-add"></span>
                {title}
            </button>
        </>
    );
};

export default React.memo(AddButton);
