import React from 'react';
import { IFormSidebar } from 'common/models/types';
import useFormSidebar from 'common/hooks/use-form-sidebar';
import RightSideBarForm from 'common/components/right-side-bar/right-side-bar-form';
import EntityContactForm from './entity-contact-form';
import { IEntityContactDetails, IEntityContactForm, IOtherRole, IRole } from 'common/services/api/entity-contact/type';

const EntityContactSidebarEdit = ({ data: contactDetails, onClose, onSave }: IFormSidebar<IEntityContactForm, IEntityContactDetails>) => {
    const [data, error, handleChangeData, handleCancelClick, handleSaveClick] = useFormSidebar<IEntityContactForm>(
        {
            id: contactDetails?.contact?.id,
            firstName: contactDetails?.contact?.firstName,
            middleName: contactDetails?.contact?.middleName,
            lastName: contactDetails?.contact?.lastName,
            additionalRoleInformation: contactDetails?.additionalRoleInformation,
            phones: contactDetails?.contact?.phones || [],
            emails: contactDetails?.contact?.emails || [],
            contactRoleIds: contactDetails?.roles ? contactDetails?.roles.filter((role: IRole) => role).map((role: IRole) => role.id) : [],
            otherContactRoles: contactDetails?.otherRoles ? contactDetails?.otherRoles.filter((role: IOtherRole) => role) : [],
        },
        { onClose, onSave }
    );

    return (
        <RightSideBarForm title="Edit Contact" onCancel={handleCancelClick} onSave={handleSaveClick}>
            <EntityContactForm data={data} errors={error} onChange={handleChangeData} />
        </RightSideBarForm>
    );
};

export default EntityContactSidebarEdit;
