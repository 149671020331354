import RightSideBarForm from 'common/components/right-side-bar/right-side-bar-form';
import { IFormItem, ILabelOptions } from 'common/models/types';
import { PayTypes } from 'models/enums/pay-types';
import moment from 'moment';
import React, { useState } from 'react';
import { EXISTING_RATE_CONFLICT } from '../../../entities-page/components/entity-details/rates-tab/constants';
import TherapistRateFormBase from './therapist-rate-form-base';
import TherapistRateFormCreate from './therapist-rate-form-create';
import TherapistRateTemplateDepartaments from './therapist-rate-template-departaments';
import {
    EDIT_RATE_REQUEST,
    ITherapistRateEditForm,
    ITherapistRateForm,
    NEW_RATE_REQUEST,
    RateErrorCodes,
    TherapistRateDialogUpdateProps,
} from './types';

const TherapistRateDialogUpdate = ({ data: rateDetails, onClose, onSave, template: rateTemplate }: TherapistRateDialogUpdateProps) => {
    const [allowSave, setAllowSave] = useState(false);

    const [editRate, setEditRate] = useState<ITherapistRateEditForm & ILabelOptions>({
        rateId: rateDetails.rateId,
        rate: rateDetails.rate || '0',
        startDate: rateDetails.startDate,
        endDate: rateDetails.endDate,
    });
    const [eitRateErrors, setEitRateErrors] = useState({});

    const [newRate, setNewRate] = useState<ITherapistRateForm & ILabelOptions>({
        departmentId: undefined,
        subDepartmentIds: undefined,
        therapistId: rateDetails.therapistId,
        payType:
            Object.values(PayTypes).find((payType) => payType.label === rateDetails?.payType || payType.value === rateDetails?.payType)
                ?.value || null,
        corporateName: rateDetails?.corporateName,
        taxId: rateDetails?.taxId,
        rate: rateDetails?.rate || '0',
        startDate: moment(rateDetails?.endDate).add(1, 'days').format('YYYY-MM-DDTHH:mm:ss'),
        endDate: null,
        corporateNameSelector: rateDetails?.corporateName,
    });
    const [newRateErrors, setNewRateErrors] = useState({});

    const getRateErrors = (errors: any, type: string) => {
        let rateErrors = {};

        Object.keys(errors).forEach((key) => {
            if (key.includes(type)) {
                let formattedKey = key.replace(type, '');
                formattedKey = formattedKey.charAt(0).toLowerCase() + formattedKey.substr(1);
                rateErrors = { ...rateErrors, [formattedKey]: errors[key] };
            }
        });

        return rateErrors;
    };

    const handleOnSaveClick = () => {
        const { corporateNameSelector, ...restNewRate } = newRate;

        if (corporateNameSelector?.length > 0 || newRate?.payType === PayTypes.W2.value) {
            delete restNewRate.corporateName;
            delete restNewRate.taxId;
        }

        onSave(
            {
                id: editRate?.rateId,
                endDate: editRate?.endDate,
            },
            restNewRate
        ).catch((errors) => {
            if (errors && !errors.errorCode) {
                const editRateErrors = getRateErrors(errors, EDIT_RATE_REQUEST);

                if (editRateErrors) {
                    setEitRateErrors(editRateErrors);
                }

                const newRateErrors = getRateErrors(errors, NEW_RATE_REQUEST);

                if (newRateErrors) {
                    setNewRateErrors(newRateErrors);
                }
            }

            if (errors.errorCode) {
                switch (errors.errorCode) {
                    case RateErrorCodes.EditRateActiveConstraintFailed:
                    case RateErrorCodes.EditRateActiveFutureConstraintFailed:
                    case RateErrorCodes.EditRatePastConstraintFailed: {
                        const activeEditRateError = {
                            startDate: [EXISTING_RATE_CONFLICT],
                            endDate: [EXISTING_RATE_CONFLICT],
                        };
                        setEitRateErrors(activeEditRateError);
                        break;
                    }
                    case RateErrorCodes.AddRateActiveConstraintFailed:
                    case RateErrorCodes.AddRateActiveFutureConstraintFailed:
                    case RateErrorCodes.AddtRatePastConstraintFailed: {
                        const activeAddRateError = {
                            startDate: [EXISTING_RATE_CONFLICT],
                            endDate: [EXISTING_RATE_CONFLICT],
                        };
                        setNewRateErrors(activeAddRateError);
                        break;
                    }
                    default:
                        break;
                }
            }
        });
    };

    const handleRateChange = (data: IFormItem) => {
        setEditRate((prevInfo) => ({ ...prevInfo, [data.name]: data.value }));
        setEitRateErrors((prevError) => ({ ...prevError, [data.name]: undefined }));
        setAllowSave(true);
    };

    const handleNewRateChange = (data: IFormItem) => {
        setNewRate((prevInfo) => ({ ...prevInfo, [data.name]: data.value }));
        setNewRateErrors((prevError) => ({ ...prevError, [data.name]: undefined }));
        if (!(data.name === 'documents' && data.value[0].document === '')) {
            setAllowSave(true);
        }
    };

    const handleCancelClick = () => {
        setEitRateErrors({});
        onClose();
    };

    const renderRateTemplateTypes = () => {
        return <TherapistRateTemplateDepartaments rateTemplate={rateTemplate}></TherapistRateTemplateDepartaments>;
    };

    return (
        <RightSideBarForm
            title="Edit Rate"
            className="dialog-with-subcontent therapist-rate-update-sidebar"
            onCancel={handleCancelClick}
            onSave={handleOnSaveClick}
            renderAdditionalContent={renderRateTemplateTypes}
            disableSave={!allowSave}
        >
            <div className="edit-rate-section">
                <TherapistRateFormBase data={editRate} errors={eitRateErrors} onChange={handleRateChange} isUpdate={true} />
            </div>

            <div className="update-rate">
                <div className="update-rate__header">
                    <span className="update-rate__title">Change Rate</span>
                    {/*The part form a mockup but hidden after QA request*/}
                    {/*<div className="update-rate__actions">*/}
                    {/*    <button className="base-text-btn white" onClick={handleUpdateCancel}>*/}
                    {/*        Cancel*/}
                    {/*    </button>*/}
                    {/*</div>*/}
                </div>
                <div className="update-rate__body">
                    <TherapistRateFormCreate
                        isEdit={false}
                        data={newRate}
                        errors={newRateErrors}
                        onChange={handleNewRateChange}
                        isUpdate={true}
                    />
                </div>
            </div>
        </RightSideBarForm>
    );
};

export default TherapistRateDialogUpdate;
