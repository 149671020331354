import { Reducer } from '@reduxjs/toolkit';
import { CountyActionTypes, ICountyState } from './types';

const initialState: ICountyState = {
    data: undefined,
};

export const countyReducer: Reducer<ICountyState, CountyActionTypes> = (state = initialState, action) => {
    switch (action.type) {
        case 'SET_COUNTIES':
            return { ...state, data: action.counties };
        default:
            return state;
    }
};
