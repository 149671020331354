import { ExportType } from 'common/constants/export-options';
import { InfiniteServerClient } from 'common/helpers/http-clients';
import { IAddressSearchModel, IMapPointResponse } from './types';

export class MapService {
    searchInRadius(request: IAddressSearchModel): Promise<IMapPointResponse[]> {
        return InfiniteServerClient.post<IMapPointResponse[]>(`/map/actions/search`, request).then(
            (response) => {
                return response.data;
            },
            (error) => {
                throw error;
            }
        );
    }

    searchInRadiusPlacements(request: IAddressSearchModel): Promise<IMapPointResponse[]> {
        return InfiniteServerClient.post<IMapPointResponse[]>(`active-placements/map/actions/search`, request).then(
            (response) => {
                return response.data;
            },
            (error) => {
                throw error;
            }
        );
    }

    exportTherapistsOnMap(type: ExportType, request: IAddressSearchModel): Promise<Blob> {
        return InfiniteServerClient.post<Blob>(`map/actions/export/${type}`, request, {
            responseType: 'blob',
        }).then(
            (response) => {
                return response.data;
            },
            async (error) => {
                throw JSON.parse(await error.response.data.text());
            }
        );
    }
}

export default new MapService();
