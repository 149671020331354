import React, { useCallback, useEffect } from 'react';
import RightSideBar from 'common/components/right-side-bar/right-side-bar';
import { useState } from 'react';
import { RejectProps } from './types';
import FieldSet from 'common/components/field-set/field-set';
import TextAreaField from 'common/components/field/text-area-field';
import { FieldValue } from 'common/models/field-value';
import reasonService from 'common/services/api/reason/reason-service';
import DropdownOption from 'common/models/dropdown-option';
import InfiniteSelect from 'common/components/infinite-select/infinite-select';
import { generalValidationRules } from 'common/validation/general-validation-rules';
import { IRejectRequest } from 'common/models/reject-request';

const RejectDialog: React.FC<RejectProps> = ({ title, onCancel, onSave, reasonType, required }) => {
    const [errorData, setErrorData] = useState({});
    const [request, setRequest] = useState<IRejectRequest | null>(null);
    const [reasonOptions, setReasonOptions] = useState<DropdownOption[]>([]);

    useEffect(() => {
        reasonService.getReasons(reasonType).then((data) => {
            setReasonOptions(data.map((i) => new DropdownOption(i.id, i.name)));
        });
    }, [reasonType]);

    const handleReasonChange = (reasonId: number) => {
        setRequest({ ...request, reasonId: reasonId });
        setErrorData((prevError) => ({ ...prevError, reasonId: undefined }));
    };

    const handleCommentChange = (comment: string) => {
        setRequest({ ...request, comment: comment });
        setErrorData((prevError) => ({ ...prevError, comment: undefined }));
    };

    const handleSave = () => {
        onSave(request).catch((error) => setErrorData(error.response.data));
    };

    const renderClassName = useCallback(
        (name: string) => {
            return `edit-therapist-field ${required?.includes(name) ? 'required-field-star' : ''}`;
        },
        [required]
    );

    return (
        <div>
            <RightSideBar title={title} onCancel={onCancel} onSave={handleSave}>
                <div className="edit-therapist-form">
                    <FieldSet name="reasonId" errors={errorData} customClass={renderClassName('reasonId')}>
                        <InfiniteSelect
                            id="reasonId"
                            items={reasonOptions}
                            label="Reason"
                            value={reasonOptions.find((item) => request?.reasonId === item.value)}
                            onChange={(item: FieldValue) => handleReasonChange(item.value)}
                        ></InfiniteSelect>
                    </FieldSet>
                    <FieldSet name="comment" errors={errorData} customClass={renderClassName('comment')}>
                        <TextAreaField
                            placeholder="Comment"
                            name="comment"
                            maxLength={generalValidationRules.comment.maxLength}
                            onChange={(data: FieldValue) => handleCommentChange(data.value)}
                            value={request?.comment}
                        ></TextAreaField>
                    </FieldSet>
                    <br />
                </div>
            </RightSideBar>
            <div className="right-sidebar-overlay"></div>
        </div>
    );
};

export default RejectDialog;
