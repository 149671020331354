import { DisplayAddressType, IMapPoint, IMapPointResponse, IPointData } from 'common/services/api/map/types';

const THERAPIST_POINT_TYPES = [DisplayAddressType.Home, DisplayAddressType.Preference];
const REQUEST_POINT_TYPES = [DisplayAddressType.HcMedARequest, DisplayAddressType.HcMedBRequest, DisplayAddressType.FacilityRequest];
const PLACEMENT_POINT_TYPES = [
    DisplayAddressType.HcMedAActivePlacement,
    DisplayAddressType.SchoolActivePlacement,
    DisplayAddressType.HcMedBActivePlacement,
    DisplayAddressType.FacilityActivePlacement,
];

export const toMapPoint = (response: IMapPointResponse[]): IMapPoint[] => {
    const result: IMapPoint[] = [];

    response.forEach((item) => {
        let resultItem = result.find((i) => i.latitude === item.latitude && i.longitude === item.longitude);

        if (resultItem == null) {
            resultItem = {
                latitude: item.latitude,
                longitude: item.longitude,
                data: [{ id: item.id, type: item.type, data: item.data }],
                isCluster: false,
            };
            result.push(resultItem);
        } else {
            resultItem.data.push({ id: item.id, type: item.type, data: item.data });
            resultItem.isCluster = !resultItem.data.every((i) => i.type === resultItem.data[0].type);
        }
    });

    return result;
};

export const isTherapistData = (data: IPointData) => {
    return THERAPIST_POINT_TYPES.includes(data.type);
};

export const isRequestData = (data: IPointData) => {
    return REQUEST_POINT_TYPES.includes(data.type);
};

export const isPlacementData = (data: IPointData) => {
    return PLACEMENT_POINT_TYPES.includes(data.type);
};
