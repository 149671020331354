import { FilterHelper } from 'common/helpers/filter-helper';
import { InfiniteServerClient } from 'common/helpers/http-clients';

import {
    IDocumentWidgetOptions,
    IHRWidgetFilter,
    IMyFollowUpWidgetFilter,
    IRecruitmentWidgetFilter,
    ITherapistDahsboardResponse,
    ITherapistMissingDataWidgetFilter,
    IWidgetOptions,
} from './types';

export class TherapistDashboaardService {
    getMyFollowUpWidgetData(filter: IMyFollowUpWidgetFilter): Promise<ITherapistDahsboardResponse> {
        const queryParams = FilterHelper.toURLQueryParams(filter).join('&');

        return InfiniteServerClient.get<ITherapistDahsboardResponse>(`dashboard/therapists/follow-up?${queryParams}`).then(
            (response) => {
                return response.data;
            },
            (error) => {
                throw error;
            }
        );
    }
    getMyFollowUpWidgetOptions(startDate?: string, endDate?: string): Promise<IWidgetOptions> {
        return InfiniteServerClient.get<IWidgetOptions>(
            `dashboard/therapists/follow-up/options?startDate=${startDate}&endDate=${endDate}`
        ).then(
            (response) => {
                return response.data;
            },
            (error) => {
                throw error;
            }
        );
    }

    exportMyFollowUpWidgetData(filter: IMyFollowUpWidgetFilter, exportType: string): Promise<Blob> {
        const queryParams = FilterHelper.toURLQueryParams(filter).join('&');

        return InfiniteServerClient.get<Blob>(`dashboard/therapists/follow-up/exports/${exportType}?${queryParams}`, {
            responseType: 'blob',
        }).then(
            (response) => {
                return response.data;
            },
            (error) => {
                throw error;
            }
        );
    }

    getRecruitmentWidgetData(filter: IRecruitmentWidgetFilter): Promise<ITherapistDahsboardResponse> {
        const queryParams = FilterHelper.toURLQueryParams({ ...filter }).join('&');

        return InfiniteServerClient.get<ITherapistDahsboardResponse>(`dashboard/therapists/recruitment?${queryParams}`).then(
            (response) => {
                return response.data;
            },
            (error) => {
                throw error;
            }
        );
    }

    getRecruitmentOptions(status: string, isSupervisor: boolean): Promise<IWidgetOptions> {
        return InfiniteServerClient.get<IWidgetOptions>(
            `dashboard/therapists/recruitment/options?status=${status}&isSupervisor=${isSupervisor}`
        ).then(
            (response) => {
                return response.data;
            },
            (error) => {
                throw error;
            }
        );
    }

    exportRecruitmentWidgetData(filter: IRecruitmentWidgetFilter, exportType: string): Promise<Blob> {
        const queryParams = FilterHelper.toURLQueryParams(filter).join('&');

        return InfiniteServerClient.get<Blob>(`dashboard/therapists/recruitment/exports/${exportType}?${queryParams}`, {
            responseType: 'blob',
        }).then(
            (response) => {
                return response.data;
            },
            (error) => {
                throw error;
            }
        );
    }

    getNewWidgetData(filter: IRecruitmentWidgetFilter): Promise<ITherapistDahsboardResponse> {
        const queryParams = FilterHelper.toURLQueryParams({ ...filter }).join('&');

        return InfiniteServerClient.get<ITherapistDahsboardResponse>(`dashboard/therapists/recruitment/new?${queryParams}`).then(
            (response) => {
                return response.data;
            },
            (error) => {
                throw error;
            }
        );
    }

    getNewAssignedToOptions(isSupervisor: boolean): Promise<IWidgetOptions> {
        return InfiniteServerClient.get<IWidgetOptions>(`dashboard/therapists/recruitment/new/options?isSupervisor=${isSupervisor}`).then(
            (response) => {
                return response.data;
            },
            (error) => {
                throw error;
            }
        );
    }

    exportNewWidgetData(filter: IRecruitmentWidgetFilter, exportType: string): Promise<Blob> {
        const queryParams = FilterHelper.toURLQueryParams(filter).join('&');

        return InfiniteServerClient.get<Blob>(`dashboard/therapists/recruitment/new/exports/${exportType}?${queryParams}`, {
            responseType: 'blob',
        }).then(
            (response) => {
                return response.data;
            },
            (error) => {
                throw error;
            }
        );
    }

    getInProcessWidgetData(filter: IRecruitmentWidgetFilter): Promise<ITherapistDahsboardResponse> {
        const queryParams = FilterHelper.toURLQueryParams({ ...filter }).join('&');

        return InfiniteServerClient.get<ITherapistDahsboardResponse>(`dashboard/therapists/recruitment/in-process?${queryParams}`).then(
            (response) => {
                return response.data;
            },
            (error) => {
                throw error;
            }
        );
    }

    getInProcessAssignedToOptions(isSupervisor: boolean): Promise<IWidgetOptions> {
        return InfiniteServerClient.get<IWidgetOptions>(
            `dashboard/therapists/recruitment/in-process/options?isSupervisor=${isSupervisor}`
        ).then(
            (response) => {
                return response.data;
            },
            (error) => {
                throw error;
            }
        );
    }

    exportInProcessWidgetData(filter: IRecruitmentWidgetFilter, exportType: string): Promise<Blob> {
        const queryParams = FilterHelper.toURLQueryParams(filter).join('&');

        return InfiniteServerClient.get<Blob>(`dashboard/therapists/recruitment/in-process/exports/${exportType}?${queryParams}`, {
            responseType: 'blob',
        }).then(
            (response) => {
                return response.data;
            },
            (error) => {
                throw error;
            }
        );
    }

    getInterviewScheduledWidgetData(filter: IRecruitmentWidgetFilter): Promise<ITherapistDahsboardResponse> {
        const queryParams = FilterHelper.toURLQueryParams({ ...filter }).join('&');

        return InfiniteServerClient.get<ITherapistDahsboardResponse>(
            `dashboard/therapists/recruitment/interview-scheduled?${queryParams}`
        ).then(
            (response) => {
                return response.data;
            },
            (error) => {
                throw error;
            }
        );
    }

    getInterviewScheduledAssignedToOptions(isSupervisor: boolean): Promise<IWidgetOptions> {
        return InfiniteServerClient.get<IWidgetOptions>(
            `dashboard/therapists/recruitment/interview-scheduled/options?isSupervisor=${isSupervisor}`
        ).then(
            (response) => {
                return response.data;
            },
            (error) => {
                throw error;
            }
        );
    }

    exportInterviewScheduledWidgetData(filter: IRecruitmentWidgetFilter, exportType: string): Promise<Blob> {
        const queryParams = FilterHelper.toURLQueryParams(filter).join('&');

        return InfiniteServerClient.get<Blob>(`dashboard/therapists/recruitment/interview-scheduled/exports/${exportType}?${queryParams}`, {
            responseType: 'blob',
        }).then(
            (response) => {
                return response.data;
            },
            (error) => {
                throw error;
            }
        );
    }

    getOnboardingWidgetData(filter: IHRWidgetFilter): Promise<ITherapistDahsboardResponse> {
        const queryParams = FilterHelper.toURLQueryParams({ ...filter }).join('&');

        return InfiniteServerClient.get<ITherapistDahsboardResponse>(`dashboard/therapists/hr?${queryParams}`).then(
            (response) => {
                return response.data;
            },
            (error) => {
                throw error;
            }
        );
    }

    exportOnboardingWidgetData(filter: IHRWidgetFilter, exportType: string): Promise<Blob> {
        const queryParams = FilterHelper.toURLQueryParams(filter).join('&');

        return InfiniteServerClient.get<Blob>(`dashboard/therapists/hr/exports/${exportType}?${queryParams}`, {
            responseType: 'blob',
        }).then(
            (response) => {
                return response.data;
            },
            (error) => {
                throw error;
            }
        );
    }

    getHrOptions(status: string, isSupervisor: boolean): Promise<IDocumentWidgetOptions> {
        return InfiniteServerClient.get<IDocumentWidgetOptions>(
            `dashboard/therapists/hr/options?status=${status}&isSupervisor=${isSupervisor}`
        ).then(
            (response) => {
                return response.data;
            },
            (error) => {
                throw error;
            }
        );
    }

    getOnboardingWithDocumentsWidget(filter: IHRWidgetFilter): Promise<ITherapistDahsboardResponse> {
        const queryParams = FilterHelper.toURLQueryParams({ ...filter }).join('&');

        return InfiniteServerClient.get<ITherapistDahsboardResponse>(`dashboard/therapists/hr-documents/onboarding?${queryParams}`).then(
            (response) => {
                return response.data;
            },
            (error) => {
                throw error;
            }
        );
    }

    getActiveWithDocumentsWidget(filter: IHRWidgetFilter): Promise<ITherapistDahsboardResponse> {
        const queryParams = FilterHelper.toURLQueryParams({ ...filter }).join('&');

        return InfiniteServerClient.get<ITherapistDahsboardResponse>(`dashboard/therapists/hr-documents/active?${queryParams}`).then(
            (response) => {
                return response.data;
            },
            (error) => {
                throw error;
            }
        );
    }

    getTherapistMissingData(filter: ITherapistMissingDataWidgetFilter): Promise<ITherapistDahsboardResponse> {
        const queryParams = FilterHelper.toURLQueryParams({ ...filter }).join('&');

        return InfiniteServerClient.get<ITherapistDahsboardResponse>(`dashboard/therapists/missing-data?${queryParams}`).then(
            (response) => {
                return response.data;
            },
            (error) => {
                throw error;
            }
        );
    }

    getTherapistMissingDataOptions(isSupervisor: boolean): Promise<IWidgetOptions> {
        return InfiniteServerClient.get<IWidgetOptions>(`dashboard/therapists/missing-data/options?isSupervisor=${isSupervisor}`).then(
            (response) => {
                return response.data;
            },
            (error) => {
                throw error;
            }
        );
    }

    getOnboardingDocumentOptions(isSupervisor: boolean): Promise<IDocumentWidgetOptions> {
        return InfiniteServerClient.get<IDocumentWidgetOptions>(
            `dashboard/therapists/hr-documents/onboarding/options?isSupervisor=${isSupervisor}`
        ).then(
            (response) => {
                return response.data;
            },
            (error) => {
                throw error;
            }
        );
    }

    getActiveDocumentOptions(isSupervisor: boolean): Promise<IDocumentWidgetOptions> {
        return InfiniteServerClient.get<IDocumentWidgetOptions>(
            `dashboard/therapists/hr-documents/active/options?isSupervisor=${isSupervisor}`
        ).then(
            (response) => {
                return response.data;
            },
            (error) => {
                throw error;
            }
        );
    }

    exportOnboardingWidgetWithDocumentsWidgetData(filter: IHRWidgetFilter, exportType: string): Promise<Blob> {
        const queryParams = FilterHelper.toURLQueryParams(filter).join('&');

        return InfiniteServerClient.get<Blob>(`dashboard/therapists/hr-documents/onboarding/exports/${exportType}?${queryParams}`, {
            responseType: 'blob',
        }).then(
            (response) => {
                return response.data;
            },
            (error) => {
                throw error;
            }
        );
    }

    exportActiveWidgetWithDocumentsWidgetData(filter: IHRWidgetFilter, exportType: string): Promise<Blob> {
        const queryParams = FilterHelper.toURLQueryParams(filter).join('&');

        return InfiniteServerClient.get<Blob>(`dashboard/therapists/hr-documents/active/exports/${exportType}?${queryParams}`, {
            responseType: 'blob',
        }).then(
            (response) => {
                return response.data;
            },
            (error) => {
                throw error;
            }
        );
    }
}

export default new TherapistDashboaardService();
