import { GridOptions } from 'ag-grid-community';
import { ColDef, ColGroupDef } from 'ag-grid-community/dist/lib/entities/colDef';
import { GridReadyEvent } from 'ag-grid-community/dist/lib/events';

export interface IDefaultPageSetting {
    isCreateDialogOpen: boolean;
    updateTable: boolean;
}

export interface IDefaultFilters {
    search?: string;
    [key: string]: number | boolean | string | Array<string> | Array<number>;
}

export interface ITableWrapperReady {
    params?: GridReadyEvent;
    extraParams?: any;
}

export type DefaultTableProps = {
    updateTable?: boolean;
    onAddBtn?: () => void;
    onGridReady?: (props: ITableWrapperReady) => void;
    serachTitle?: string;
};

export interface ITableFetch {
    handleFetchData?: any;
    handleFetchFilters?: any;
    handleExportData?: any;
}

export type ExtentedDefaultTableProps = DefaultTableProps & {
    storePrefix?: string;
    extendGridOptions?: GridOptions & {
        columnDefs?: { [position: number]: ColDef | ColGroupDef };
    };
    extendDefaultCustomFilters?: IDefaultFilters;
    extendFetchers?: ITableFetch;
    details?: any;
};

export interface IDefaultPageSetting {
    isCreateDialogOpen: boolean;
    updateTable: boolean;
}

export enum RequestType {
    HomeCare,
    NursingHome,
    School,
}
