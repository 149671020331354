import React from 'react';
import './confirm-popup.scss';
import { ConfirmPopupProps } from './types';

const ConfirmPopup = ({
    onCancel,
    className = '',
    onConfirm,
    text = 'Are you sure?',
    children,
    textCancel = 'Cancel',
    textConfirm = 'Confirm',
}: ConfirmPopupProps) => {
    return (
        <div className={`confirm-popup ${className}`}>
            <div className="confirm-popup__body">{children || text}</div>
            <div className="confirm-popup__actions">
                <button className="confirm-popup__cancel base-text-btn white" onClick={onCancel}>
                    {textCancel}
                </button>
                <button className="confirm-popup__confirm base-text-btn blue" onClick={onConfirm}>
                    {textConfirm}
                </button>
            </div>
        </div>
    );
};

export default ConfirmPopup;
