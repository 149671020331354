import { AnyAction, ThunkAction } from '@reduxjs/toolkit';
import { ITherapistDetailsModel } from 'common/services/api/therapist/types';

export const GET_THERAPIST_DETAILS_REQUEST = 'GET_THERAPIST_DETAILS_REQUEST';
export const GET_THERAPIST_DETAILS_SUCCESS = 'GET_THERAPIST_DETAILS_SUCCESS';
export const UPDATE_THERAPIST_SUCCESS = 'UPDATE_THERAPIST_SUCCESS';
export const UPDATE_THERAPIST_FAILURE = 'UPDATE_THERAPIST_FAILURE';
export const UPDATE_THERAPIST_ASSIGNED_TO = 'UPDATE_THERAPIST_ASSIGNED_TO';
export const ADD_NEW_INTERVIEW_SUCCESS = 'ADD_NEW_INTERVIEW_SUCCESS';
export const GET_THERAPIST_ASSIGNMENTS_SUCCESS = 'GET_THERAPIST_ASSIGNMENTS_SUCCESS';
export const GET_THERAPIST_DOCUMENTS_SUCCESS = 'GET_THERAPIST_DOCUMENTS_SUCCESS';
export const GET_THERAPIST_BACKGROUND_CHECK_SUCCESS = 'GET_THERAPIST_BACKGROUND_CHECK_SUCCESS';
export const UPDATE_THERAPIST_STATUS = 'UPDATE_THERAPIST_STATUS';
export const UPDATE_INACTIVE_REASON = 'UPDATE_INACTIVE_REASON';
export const START_PROCESS_REQUEST = 'START_PROCESS_REQUEST';
export const UPDATE_THERAPIST_PROFILE_PHOTO_REQUEST = 'UPDATE_THERAPIST_PROFILE_PHOTO_REQUEST';
export const UPDATE_THERAPIST_PROFILE_PHOTO_SUCCESS = 'UPDATE_THERAPIST_PROFILE_PHOTO_SUCCESS';
export const UPDATE_THERAPIST_HAS_ASSIGNMENTS = 'UPDATE_THERAPIST_HAS_ASSIGNMENTS';

export type ThunkResult<R> = ThunkAction<R, ITherapistDetailsState, null, AnyAction>;

export interface ITherapistDetailsState {
    therapist: ITherapistDetailsModel;
    assignmentTab: {
        data: any[];
    };
    documentsTab: {
        data: any[];
    };
    backgroundChecksTab: {
        data: any[];
    };
}
