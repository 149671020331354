import Tab from 'common/components/tabs/tab';
import Tabs from 'common/components/tabs/tabs';
import React from 'react';
import { GeneralInfoTabProp } from '../types';
import EntityBasicInfoTab from './basic-info-tab/entity-basic-info-tab';
import { useHasPermission } from 'common/hooks/use-has-permission';
import { PermissionType } from 'common/models/permission-type';
import EntityContactListTab from './contacts-tab/entity-contacts-list-tab';
import EntityNoteTab from './note-tab/entity-note-tab';
import { useIsUserType } from 'common/hooks/use-is-user-type';

const EntityGeneralInfoTab: React.FC<GeneralInfoTabProp> = ({ id: entityId, details: entityDetails }) => {
    const hasSettingsEditPermission = useHasPermission(PermissionType.SettingsEdit) ?? null;
    const { isExternal } = useIsUserType();

    return (
        <div className="entity-general-tab content-flex">
            <Tabs orientation="vertical" parentTabUrl="general-info">
                <Tab id="basicInfo" linkClassName={'custom-link'} text="Basic Info">
                    <EntityBasicInfoTab entityId={entityId} entityBasicInfo={entityDetails} />
                </Tab>

                <Tab id="contacts" linkClassName={'custom-link'} text="Contacts">
                    <EntityContactListTab entityId={entityId} />
                </Tab>

                {!isExternal && (
                    <Tab id="notes" linkClassName={'custom-link'} text="Notes">
                        <EntityNoteTab entityId={entityId} />
                    </Tab>
                )}

                {hasSettingsEditPermission && (
                    <Tab id="settings" linkClassName={'custom-link'} text="Settings">
                        {/*<EntitySettingsTab agencyId={entityId} />*/}
                    </Tab>
                )}
            </Tabs>
        </div>
    );
};

export default EntityGeneralInfoTab;
