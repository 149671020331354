import React, { useState, useEffect, useMemo } from 'react';
import RightSideBar, { SideBarMode } from 'common/components/right-side-bar/right-side-bar';
import FocusTrap from 'focus-trap-react';
import { Card, CardWrapper, getCardConfig } from 'common/components/card';
import { trackPromise } from 'react-promise-tracker';
import LoggingService from 'common/services/api/logging/logging-service';
import { ICardConfig } from 'common/components/card/types';
import { ILoggingDetailPage } from 'common/services/api/logging/types';
import { CreateLoggingFormProps } from './types';
import './logging-info.scss';
import moment from 'moment';
import isEqual from 'lodash/isEqual';
import { ICustomLabel } from 'common/models/custom-label';
import { labels } from 'common/constants/labels';
import { NavigationRoutes } from 'models/routes/navigation-routes';

const customLabels: ICustomLabel[] = [{ field: 'MiddleName', label: labels.middleNameInitial }];

const LoggingInfo = ({ loggingId, onCancel }: CreateLoggingFormProps) => {
    const [details, setDetails] = useState(null);
    const [changes, setChanges] = useState(null);

    useEffect(() => {
        trackPromise(
            LoggingService.getDetails(loggingId).then((data: ILoggingDetailPage) => {
                setDetails(data);
                setChanges(getChangedData(data.oldValues, data.newValues));
            })
        );
    }, [loggingId]);

    const changesConfig = useMemo(() => getCardConfig(changes, { friendlyTitle: true, customLabels }), [changes]);

    return (
        <div className="create-request">
            <FocusTrap focusTrapOptions={{ allowOutsideClick: false, onDeactivate: onCancel }}>
                <RightSideBar title="Activity Details" onCancel={onCancel} mode={SideBarMode.VIEW}>
                    <div className="create-form">
                        <CardWrapper>
                            <Card
                                title="Basic info"
                                className="details-card"
                                fullWidth
                                noBorder
                                config={caseDetailsConfig}
                                data={details}
                            />
                        </CardWrapper>
                        {changesConfig.length > 0 && (
                            <CardWrapper>
                                <Card title="Changes" className="logging-card" fullWidth noBorder config={changesConfig} data={changes} />
                            </CardWrapper>
                        )}
                    </div>
                </RightSideBar>
            </FocusTrap>
            <div className="right-sidebar-overlay"></div>
        </div>
    );
};

const isObject = (obj: any) => obj && typeof obj === 'object';

const isObjectArray = (arr: any) => {
    if (!Array.isArray(arr)) return;

    for (let i = 0; i < arr.length; ++i) if (!isObject(arr[i])) return false;

    return true;
};

const toJSONString = (str: string) => {
    return JSON.stringify(str).replace(/\\/g, '');
};

const DEEP_LOG_PARSING = 1;

const getChangedData = (oldValue: any = {}, newValue: any = {}, deep = 0, parentKey: string = '') => {
    if (!oldValue) oldValue = {};
    if (!newValue) oldValue = {};

    const getKey = (key: string) => (parentKey?.length > 0 ? parentKey + ' / ' + key : key);

    let result: any = {};
    const matcher = (key: string) => {
        if (deep < DEEP_LOG_PARSING && (isObject(oldValue[key]) || isObject(newValue[key]))) {
            result = { ...result, ...getChangedData(oldValue[key], newValue[key], deep + 1, getKey(key)) };

            return;
        }

        let prevVal = oldValue[key];
        let currentVal = newValue[key];

        // Flow Arrays with primitives (toString)
        if (Array.isArray(prevVal) && !isObjectArray(prevVal)) {
            prevVal = prevVal.join(', ');
        }

        if (Array.isArray(currentVal) && !isObjectArray(currentVal)) {
            currentVal = currentVal.join(', ');
        }

        // fix undefined !== null
        if (prevVal === currentVal) return;

        // Raw value (string, object)
        if (!isEqual(prevVal, currentVal)) {
            result[getKey(key)] = toJSONString(prevVal ?? null) + ' → ' + toJSONString(currentVal ?? null);
        }
    };

    Object.keys(oldValue).forEach(matcher);
    Object.keys(newValue).forEach(matcher);

    return result;
};

const caseDetailsConfig: ICardConfig[] = [
    { title: 'Timestamp', field: 'timeStamp', render: (value) => moment(value).local().format('MM/DD/YYYY | hh:mm A') },
    { title: 'User', field: 'user' },
    { title: 'Parent', field: 'parent' },
    {
        title: 'Parent ID',
        field: 'parentId',
        className: (value, data) => (NavigationRoutes.isDetailsRoute(data?.parent) ? 'object-link-with-icon' : ''),
        onClick: (value, data) => {
            if (NavigationRoutes.isDetailsRoute(data?.parent)) {
                NavigationRoutes.openDetailsRoute(data.parent, value);
            }
        },
    },
    { title: 'Object', field: 'object' },
    { title: 'Object ID', field: 'objectId' },
    { title: 'Action', field: 'actionType' },
];

export default LoggingInfo;
