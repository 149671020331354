import { IDocumentWidgetOptions, IHRWidgetFilter } from 'common/services/api/therapist-dahsboard/types';
import React, { useEffect, useRef, useState } from 'react';
import DepartmentFilter from '../department-filter/department-filter';
import DocumentFilter from '../document-filter/document-filter';
import { FilterSection } from '../filter-section/filter-section';
import { OverdueFilter } from '../overdue-filter/overdue-filter';
import { WidgetHeader } from '../widget-header/widget-header';
import { WidgetTable } from '../widget-table/widget-table';
import { HrWidgetProps } from './types';
import useWidget from 'features/dashboard-page/hooks/use-widget';
import { IconButton } from '../icon-button/icon-button';
import { ExportType } from 'common/constants/export-options';
import { OverdueFilterOption } from '../overdue-filter/types';
import therapistDashboardService from 'common/services/api/therapist-dahsboard/therapist-dashboard-service';
import { trackPromise } from 'react-promise-tracker';

const defaultFilters: IHRWidgetFilter = {
    pageIndex: 1,
    limit: 5,
    isOverdue: false,
    isSupervisorView: false,
    status: undefined,
    assignedTo: [],
};

export const HrWidget: React.FC<HrWidgetProps> = ({
    fetchFunction,
    exportFunction,
    title,
    isSupervisorView,
    enableDocumentFilter,
    overdueDaysSetting,
    status,
    globalFilters,
}) => {
    const widgetOptionsRef = useRef<IDocumentWidgetOptions>();
    const [widgetIsReady, setWidgetReady] = useState(false);

    const [filter, totalCount, repository, fetchData, handleFilterChange, handleOverdueChange, handleExport] = useWidget({
        title,
        defaultFilter: defaultFilters,
        fetchFunction,
        exportFunction,
        isSupervisorView,
    });

    useEffect(() => {
        if (globalFilters) {
            handleFilterChange({ ...filter, assignedTo: globalFilters.assignedTo });
        }
    }, [globalFilters]);

    const handleDocumentChange = (values: number[]) => {
        handleFilterChange({ ...filter, documents: values });
    };

    const handleDepartmentChange = (values: number[]) => {
        handleFilterChange({ ...filter, departments: values });
    };

    const handleClickOnExport = () => {
        handleExport(ExportType.XLSX);
    };

    useEffect(() => {
        if (enableDocumentFilter) {
            const optionsFunction =
                status === 'Onboarding'
                    ? therapistDashboardService.getOnboardingDocumentOptions
                    : therapistDashboardService.getActiveDocumentOptions;

            trackPromise(
                optionsFunction(isSupervisorView).then((data) => {
                    widgetOptionsRef.current = data;
                    setWidgetReady(true);
                })
            );
        } else {
            trackPromise(
                therapistDashboardService.getHrOptions(status, isSupervisorView).then((data) => {
                    widgetOptionsRef.current = data;
                    setWidgetReady(true);
                })
            );
        }
    }, [status, isSupervisorView, enableDocumentFilter]);

    return widgetIsReady ? (
        <>
            <WidgetHeader title={title} totalCount={totalCount}>
                {/*<IconButton icon="icon-open-in" />*/}
                <IconButton icon="icon-export" onClick={handleClickOnExport} />
            </WidgetHeader>
            <FilterSection>
                <OverdueFilter
                    value={filter.isOverdue ? OverdueFilterOption.OVERDUE : OverdueFilterOption.ALL}
                    onChange={handleOverdueChange}
                />
                <DepartmentFilter value={filter.departments} onChange={handleDepartmentChange} />
                {enableDocumentFilter && (
                    <DocumentFilter
                        documents={widgetOptionsRef.current.documentOptions}
                        value={filter.documents}
                        onChange={handleDocumentChange}
                    />
                )}
            </FilterSection>
            <WidgetTable
                widgetOptions={widgetOptionsRef}
                filter={filter}
                fetchFunction={fetchData}
                repository={repository}
                supervisorView={isSupervisorView}
                overdueDaysSetting={overdueDaysSetting}
            />
        </>
    ) : (
        <></>
    );
};
export default React.memo(HrWidget);
