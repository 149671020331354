import { InfiniteServerClient } from 'common/helpers/http-clients';
import { ICreateNoteRequest } from 'common/models/create-note-request';
import { INote } from 'common/models/note';

export class LegalEntityNoteService {
    create(legalEntityId: number, legalEntityNote: ICreateNoteRequest): Promise<INote> {
        return InfiniteServerClient.post<INote>(`/entities/${legalEntityId}/notes`, legalEntityNote || {}).then(
            (response) => {
                return response.data;
            },
            (error) => {
                throw error;
            }
        );
    }
}

export default new LegalEntityNoteService();
