import OpenProfileButton from 'common/components/open-profile-button/open-page-button';
import { NavigationRoutes } from 'models/routes/navigation-routes';
import React, { useEffect, useState } from 'react';
import './style.scss';
import { RequestInfoProps } from './types';
import { RequestType } from '../../../../constants/types';
import { IRequestDataModel } from 'common/services/api/requests/types';
import requestService from 'common/services/api/requests/request-service';
import nursingHomeRequestService from 'common/services/api/nursing-home-requests/nursing-home-request-service';

export const RequestInfo: React.FC<RequestInfoProps> = ({ id, type }) => {
    const [request, setRequest] = useState<IRequestDataModel>(null);

    useEffect(() => {
        const getFunction = type === RequestType.HomeCare ? requestService.getRequestForMap : nursingHomeRequestService.getRequestForMap;
        getFunction(id).then(setRequest);
    }, [id, type]);

    return request !== null ? (
        <>
            <div className="request-info">
                <div className="request-info-data">
                    <div className="request-name">{request?.name}</div>
                    <div className="request-disciplines">
                        {request.address
                            ? `${request.address.address1 ?? ''} ${request.address.city ?? ''}  ${request.address?.state?.name ?? ''}  ${
                                  request.address.postalCode ?? ''
                              }`
                            : request.zipCode?.code}
                    </div>
                    <div className="request-populations">{request.therapyType?.name}</div>
                </div>
            </div>
            <div className="request-info-actions">
                <OpenProfileButton
                    label={'Open Request'}
                    url={
                        type === RequestType.HomeCare
                            ? NavigationRoutes.requestDetailsRoute(request.id)
                            : NavigationRoutes.nursinghomerequestDetailsRoute(request.id)
                    }
                />
            </div>
        </>
    ) : (
        <></>
    );
};

export default React.memo(RequestInfo);
