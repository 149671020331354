import React from 'react';
import { IForm } from 'common/models/types';
import { IAddEditInterviewModel, LocationType } from 'common/services/api/communication/types';
import DropdownOption from 'common/models/dropdown-option';
import FieldSet from 'common/components/field-set/field-set';
import InputField from 'common/components/field/input-field';
import DateField from 'common/components/field/date-field';
import NativeTimePicker from 'common/components/field/native-time-picker/native-time-picker';
import NumberField from 'common/components/field/number-field';
import InfiniteSelect from 'common/components/infinite-select/infinite-select';
import TextAreaField from 'common/components/field/text-area-field';
import PaginationAutocomplete from 'common/components/field/pagination-autocomplete/pagination-autocomplete';
import userService from 'common/services/api/user/user-service';
import { ConverterHelper } from 'common/helpers/converter-helper';
import './_styles.scss';
import { IUserBaseInfo } from 'common/services/api/user/types';

const locationOptions = [
    new DropdownOption(LocationType.Office, LocationType.Office),
    new DropdownOption(LocationType.Virtual, LocationType.Virtual),
];

// TO-DO: Set tab indexes on the form
const AddEditInterviewForm = ({ data, errors, onChange }: IForm<IAddEditInterviewModel>) => {
    const handleParticipantsChange = (value: DropdownOption[] | DropdownOption) => {
        onChange({ name: 'participants', value: (value as DropdownOption[]).map((i: DropdownOption) => i.value) });
        onChange({ name: 'selectedParticipantsOptions', value: value });
    };

    const handleHostChange = (value: DropdownOption[] | DropdownOption) => {
        onChange({ name: 'hostId', value: (value as DropdownOption)?.value });
        onChange({ name: 'selectedHost', value: value });
    };

    const getUserStaffOptions = (term: string, skip: number, take: number): Promise<IUserBaseInfo[]> => {
        return userService.getUserStaffOptions(term, skip, take, true);
    };

    return (
        <>
            <div className="edit-therapist-form">
                <FieldSet name="hostId" errors={errors} customClass="edit-therapist-field required-field-star">
                    <PaginationAutocomplete
                        fetchData={getUserStaffOptions}
                        convertFunction={ConverterHelper.userConvert}
                        id="user-autocomplete-id"
                        defaultValue={data.selectedHost || ''}
                        placeholder={'Host'}
                        name="hostId"
                        tabIndex=""
                        cleanable
                        onDelete={() => {
                            onChange({ name: 'hostId', value: undefined });
                            onChange({ name: 'selectedHost', value: undefined });
                        }}
                        onSelect={handleHostChange}
                    ></PaginationAutocomplete>
                </FieldSet>
                <FieldSet name="dateTime" errors={errors} customClass="edit-therapist-field required-field-star">
                    <DateField
                        withSameValue
                        uniqueValue={data.date}
                        id="interviewDate"
                        placeholder="Date"
                        name="date"
                        onChange={onChange}
                        value={data.date}
                    ></DateField>
                </FieldSet>
                <div className="date-time-group row-two-column">
                    <FieldSet name="dateTime" errors={errors} customClass="edit-therapist-field required-field-star mr-15">
                        <NativeTimePicker
                            id="interviewTime"
                            placeholder="Time"
                            name="time"
                            onChange={onChange}
                            value={data.time}
                        ></NativeTimePicker>
                    </FieldSet>
                    <FieldSet name="duration" errors={errors} customClass="edit-therapist-field required-field-star">
                        <NumberField
                            id="duration"
                            maxLength={3}
                            placeholder="Duration"
                            name="duration"
                            onChange={onChange}
                            value={data.duration}
                        />
                    </FieldSet>
                </div>
                <FieldSet name="location" errors={errors} customClass="edit-therapist-field required-field-star">
                    <InfiniteSelect
                        id="location"
                        name="location"
                        label="Location"
                        value={locationOptions.find((i) => i.value === data.location)}
                        items={locationOptions}
                        onChange={(item) => onChange({ name: 'location', value: item.value })}
                    />
                </FieldSet>
                {data.location === LocationType.Virtual && (
                    <FieldSet name="meetingLink" errors={errors} customClass="edit-therapist-field required-field-star">
                        <InputField
                            id="meetingLink"
                            placeholder="Link to meeting"
                            name="meetingLink"
                            onChange={onChange}
                            value={data.meetingLink}
                        ></InputField>
                    </FieldSet>
                )}
                {data.location === LocationType.Virtual && (
                    <FieldSet name="password" errors={errors} customClass="edit-therapist-field">
                        <InputField
                            type="password"
                            id="password"
                            placeholder="Password"
                            name="password"
                            onChange={onChange}
                            value={data.password}
                        ></InputField>
                    </FieldSet>
                )}
                <FieldSet name="participants" errors={errors} customClass="edit-therapist-field">
                    <PaginationAutocomplete
                        fetchData={getUserStaffOptions}
                        convertFunction={ConverterHelper.userConvert}
                        id="user-autocomplete-id"
                        defaultValue={data.selectedParticipantsOptions || []}
                        placeholder={'Participants'}
                        name="participants"
                        multiselect
                        tabIndex=""
                        cleanable
                        onDelete={() => {
                            onChange({ name: 'participants', value: undefined });
                            onChange({ name: 'selectedParticipantsOptions', value: undefined });
                        }}
                        onSelect={handleParticipantsChange}
                    ></PaginationAutocomplete>
                </FieldSet>
                <FieldSet name="instructions" errors={errors} customClass="edit-therapist-field">
                    <TextAreaField
                        id="instructions"
                        placeholder="Special Instructions"
                        name="instructions"
                        onChange={onChange}
                        value={data.instructions}
                    ></TextAreaField>
                </FieldSet>
                <FieldSet name="outcome" errors={errors} customClass="edit-therapist-field interview-outcome">
                    <TextAreaField
                        id="outcome"
                        placeholder="Interview Outcome"
                        name="outcome"
                        onChange={onChange}
                        value={data.outcome}
                    ></TextAreaField>
                </FieldSet>
            </div>
        </>
    );
};

export default React.memo(AddEditInterviewForm);
