import React from 'react';
import { DefaultOptionItemProps } from './types';

const DefaultOptionItem: React.FC<DefaultOptionItemProps> = ({ item, index, multiselect, checked, handleChange, onFormatOption }) => {
    if (multiselect) {
        return (
            <span key={index} className="option-item" onClick={() => handleChange(item)}>
                <input type="checkbox" className="nps-checkbox" id={item.label} key={item.label} checked={checked} readOnly />
                <label htmlFor={item.label}>
                    <span>{onFormatOption ? onFormatOption(item) : item.label}</span>
                </label>
            </span>
        );
    } else {
        return (
            <span key={index} className="option-item" onClick={() => handleChange(item)}>
                {onFormatOption ? onFormatOption(item) : item.label}
            </span>
        );
    }
};

export default DefaultOptionItem;
