import React from 'react';
import { ICellRendererParams } from 'ag-grid-community';
import NumberFormat from 'react-number-format';

export const RateRenderer = (props: ICellRendererParams) => {
    return (
        <NumberFormat
            value={props.value}
            displayType={'text'}
            decimalScale={2}
            fixedDecimalScale={true}
            thousandSeparator={true}
            prefix={'$'}
        />
    );
};

export default RateRenderer;
