import { Column } from 'ag-grid-community';
import React, { useEffect, useRef, useState, useCallback } from 'react';
import InfiniteSelect from '../../infinite-select/infinite-select';
import { ISortOption, SortFilterSelectProps } from '../type';

//Example
//   const resetSortByFilter = useRef<Function>(null);
//   const onFiltersClear = () => {
//     ...
//     if (resetSortByFilter.current) resetSortByFilter.current();
//   };
//  <SortFilterSelect title="Request" gridApi={gridApi} gridColumnApi={gridColumnApi} resetFilter={resetSortByFilter}/>
const SortFilterSelect = ({ gridApi, gridColumnApi, title, label = 'Sort By', resetFilter }: SortFilterSelectProps) => {
    const [columnsOptionsList, setColumnsOptionsList] = useState([]);
    const lastColumn = useRef<Column>(null);

    const resetMove = useCallback(() => {
        gridColumnApi.getAllColumns().forEach((column, index) => {
            gridColumnApi.moveColumn(column.getColId(), index);
        });
    }, [gridColumnApi]);

    const setSort = useCallback(
        (colId: string, toggle: boolean) => {
            gridColumnApi.applyColumnState({
                state: [
                    {
                        colId: colId,
                        sort: toggle ? 'asc' : null,
                    },
                ],
                defaultState: { sort: null },
            });
        },
        [gridColumnApi]
    );

    const updateColumns = useCallback(
        (colId: string, toggle: boolean) => {
            setColumnsOptionsList(
                gridColumnApi
                    .getAllColumns()
                    .filter((column) => column.isVisible() && column.getColDef().sortable !== false && !column.isPinned())
                    .map((column) => ({
                        colId: column.getColId(),
                        name: column.getColDef().headerName ?? column.getColDef().field,
                        sort: colId == null && toggle == null ? !!column.getSort() : toggle && column.getColId() === colId,
                    }))
            );
        },
        [gridColumnApi]
    );

    const chooseFieldToSortBy = useCallback(
        (option: ISortOption) => {
            const lastCol = lastColumn.current;
            const toggle = !lastCol || lastCol.getColId() !== option.colId;

            resetMove();
            if (toggle) {
                gridColumnApi.moveColumn(option.colId, 0);
            }

            setSort(option.colId, toggle);
            updateColumns(option.colId, toggle);

            lastColumn.current = toggle ? gridColumnApi.getColumn(option.colId) : null;
        },
        [gridColumnApi, updateColumns, setSort, resetMove]
    );

    const handleIsVisibleColumn = useCallback(() => {
        console.log(1);
        const sortedColumnHidden = gridColumnApi
            .getAllColumns()
            .filter((column) => !column.isVisible() && column.getColDef().sortable !== false && !!column.getSort() && !column.isPinned());
        const updatedColumnOptions = gridColumnApi
            .getAllColumns()
            .filter((column) => column.isVisible() && column.getColDef().sortable !== false && !column.isPinned())
            .map((column) => ({
                colId: column.getColId(),
                name: column.getColDef().headerName ?? column.getColDef().field,
                sort: !!column.getSort(),
            }));

        if (sortedColumnHidden.length > 0 && updatedColumnOptions.length > 0) {
            updatedColumnOptions[0].sort = false;
            chooseFieldToSortBy(updatedColumnOptions[0]);
        } else setColumnsOptionsList(updatedColumnOptions);
    }, [chooseFieldToSortBy, gridColumnApi]);

    useEffect(() => {
        if (gridColumnApi && gridApi) {
            updateColumns(null, false);
            gridApi.addEventListener('columnVisible', handleIsVisibleColumn);
            if (resetFilter) {
                resetFilter.current = () => {
                    updateColumns(null, false);
                };
            }
        }
    }, [gridColumnApi, gridApi, handleIsVisibleColumn, updateColumns, resetFilter]);

    const getSelectedItem = () => {
        return columnsOptionsList.find((item) => item.sort === true)?.name;
    };

    const renderForwardByOptionsList = () => {
        return columnsOptionsList.map((column, index) => (
            <span key={index} className="option-item" onClick={() => chooseFieldToSortBy(column)}>
                <input type="radio" className="nps-checkbox" id={column.colId} checked={column.sort} readOnly />
                <label htmlFor={column.colId}>
                    <span>{column.name}</span>
                </label>
            </span>
        ));
    };

    return (
        <InfiniteSelect
            id={`${title}ForwardBySelect`}
            label={label}
            customSelectedLabel={getSelectedItem()}
            renderCustomOptions={renderForwardByOptionsList}
        />
    );
};

export default React.memo(SortFilterSelect);
