import { IFile } from 'common/models/file';

export class FileHelper {
    static downloadBlob(blob: Blob, fileName: string) {
        const downloadUrl = window.URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.href = downloadUrl;
        a.download = fileName;
        document.body.appendChild(a);
        a.click();
        URL.revokeObjectURL(downloadUrl);
    }

    static open(fileId: number) {
        window.open(`/files/${fileId}/view`, '_blank');
    }

    static canPreviewDocument(fileExtension: string) {
        return ['pdf', 'png', 'jpeg', 'jpg'].includes(fileExtension);
    }

    static getFileExtension(name: string) {
        return name.slice(((name.lastIndexOf('.') - 1) >>> 0) + 2);
    }

    static convertToArrayBuffer(base64: any) {
        const binaryString = window.atob(base64);
        const binaryLen = binaryString.length;
        const bytes = new Uint8Array(binaryLen);
        for (let i = 0; i < binaryLen; i++) {
            const ascii = binaryString.charCodeAt(i);
            bytes[i] = ascii;
        }

        return bytes;
    }

    static downloadOrOpen(file: IFile) {
        const extension = this.getFileExtension(file.originalName);

        const blob = new Blob([this.convertToArrayBuffer(file.fileBytes)], { type: file.contentType });

        if (this.canPreviewDocument(extension)) {
            const blobURL = URL.createObjectURL(blob);
            window.open(blobURL);
        } else {
            this.downloadBlob(blob, file.originalName);
        }
    }
}
