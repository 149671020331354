import React, { useState } from 'react';
import { IFormItem } from 'common/models/types';
import { RateChangeFormProps, RateErrorCodes } from './types';
import RightSideBar from 'common/components/right-side-bar/right-side-bar';
import FocusTrap from 'focus-trap-react';
import RateBaseForm from './rate-basic-form';
import './_rate-form.scss';
import RateTemplateTypes from './rate-template-types';
import { EDIT_RATE_REQUEST, ILegalEntityRate, NEW_RATE_REQUEST } from 'common/services/api/rate/types';
import { DateTimeHelper } from 'common/helpers/date-time-helper';
import { EXISTING_RATE_CONFLICT } from '../constants';

const RateChangeForm: React.FC<RateChangeFormProps> = ({ rateTemplate, rateData, onClose, onSave }) => {
    const [editRate, setEditRate] = useState<ILegalEntityRate>({
        rateId: rateData.rateId,
        rateTemplateId: rateTemplate.id,
        rate: rateData.rate,
        startDate: rateData.startDate,
        endDate: rateData.endDate,
        documents: rateData.documents.map((doc) => {
            return { ...doc, isDisabled: doc.fileId !== null };
        }),
    });

    const [newRate, setNewRate] = useState<ILegalEntityRate>({
        rateTemplateId: rateTemplate.id,
        rate: null,
        startDate: null,
        endDate: DateTimeHelper.getDefaultEndDate(),
        documents: [],
    });

    const [rateErrors, setRateErrors] = useState({});

    const [newRateErrors, setNewRateErrors] = useState({});

    const [allowSave, setAllowSave] = useState(false);

    const getRateErrors = (errors: any, type: string) => {
        let rateErrors = {};

        Object.keys(errors).forEach((key) => {
            if (key.includes(type)) {
                let formattedKey = key.replace(type, '');
                formattedKey = formattedKey.charAt(0).toLowerCase() + formattedKey.substr(1);
                rateErrors = { ...rateErrors, [formattedKey]: errors[key] };
            }
        });

        return rateErrors;
    };

    const handleOnSaveClick = () => {
        const documents = editRate.documents?.filter((doc) => doc.value != null).map((doc) => doc.value);

        const newRateDocuments = newRate.documents?.map((doc) => doc.value);

        onSave({ ...editRate, documents }, { ...newRate, documents: newRateDocuments }).catch((errors) => {
            if (errors && !errors.errorCode) {
                const editRateErrors = getRateErrors(errors, EDIT_RATE_REQUEST);

                if (editRateErrors) {
                    setRateErrors(editRateErrors);
                }

                const newRateErrors = getRateErrors(errors, NEW_RATE_REQUEST);

                if (newRateErrors) {
                    setNewRateErrors(newRateErrors);
                }
            }

            if (errors.errorCode) {
                switch (errors.errorCode) {
                    case RateErrorCodes.EditRateActiveConstraintFailed:
                    case RateErrorCodes.EditRatePastConstraintFailed:
                    case RateErrorCodes.EditRateActiveFutureConstraintFailed: {
                        const activeEditRateError = {
                            startDate: [EXISTING_RATE_CONFLICT],
                            endDate: [EXISTING_RATE_CONFLICT],
                        };
                        setRateErrors(activeEditRateError);
                        break;
                    }
                    case RateErrorCodes.AddRateActiveConstraintFailed:
                    case RateErrorCodes.AddRateActiveFutureConstraintFailed:
                    case RateErrorCodes.AddtRatePastConstraintFailed: {
                        const activeFututeAddRateError = {
                            startDate: [EXISTING_RATE_CONFLICT],
                            endDate: [EXISTING_RATE_CONFLICT],
                        };
                        setNewRateErrors(activeFututeAddRateError);
                        break;
                    }
                    default:
                        break;
                }
            }
        });
    };

    const handleRateChange = (data: IFormItem) => {
        setEditRate((prevInfo) => ({ ...prevInfo, [data.name]: data.value }));
        setRateErrors((prevError) => ({ ...prevError, [data.name]: undefined }));
        setAllowSave(true);
    };

    const handleNewRateChange = (data: IFormItem) => {
        setNewRate((prevInfo) => ({ ...prevInfo, [data.name]: data.value }));
        setNewRateErrors((prevError) => ({ ...prevError, [data.name]: undefined }));
        if (!(data.name === 'documents' && data.value[0].document === '')) {
            setAllowSave(true);
        }
    };

    const handleCancelClick = () => {
        setRateErrors({});
        onClose();
    };

    const renderRateTemplateTypes = () => {
        return <RateTemplateTypes rateTemplate={rateTemplate}></RateTemplateTypes>;
    };

    return (
        <div className="rate-form">
            <FocusTrap focusTrapOptions={{ allowOutsideClick: true }}>
                <RightSideBar
                    title="Edit Rate"
                    onCancel={handleCancelClick}
                    onSave={handleOnSaveClick}
                    renderAdditionalContent={renderRateTemplateTypes}
                    disableSave={!allowSave}
                >
                    <div className="create-form">
                        <RateBaseForm rate={editRate} errors={rateErrors} onChange={handleRateChange} />
                    </div>

                    <div className="change-rate-line"></div>
                    <div className="create-form">
                        <div className="change-rate-header">
                            <h3>Change Rate</h3>
                        </div>
                        <RateBaseForm rate={newRate} errors={newRateErrors} onChange={handleNewRateChange} />
                    </div>
                </RightSideBar>
            </FocusTrap>
            <div className="right-sidebar-overlay"></div>
        </div>
    );
};

export default RateChangeForm;
