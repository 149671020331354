import { useMemo, useState } from 'react';

export function useNextPage(onFetchFilters: Function, limit: number) {
    const [data, setData] = useState(null);
    const [options] = useState({
        hasMore: true,
        pageIndex: 0,
    });

    const callBacks = useMemo(() => {
        return [
            (colId: string, search: string) => {
                // loadData
                const { hasMore, pageIndex } = options;

                if (hasMore) {
                    onFetchFilters({
                        pageIndex: pageIndex + 1,
                        limit,
                        search: search,
                        column: colId,
                    }).then(({ data, clientSide }: any) => {
                        options.pageIndex++;
                        if (data?.length > 0) {
                            if (clientSide) {
                                setData(data.filter((item: string) => !search || item.toLowerCase().indexOf(search.toLowerCase()) >= 0));
                            } else {
                                setData(data);
                            }
                        }
                        if (!data || data.length < limit) options.hasMore = false;
                    });
                }
            },
            () => {
                // resetData
                options.hasMore = true;
                options.pageIndex = 0;
            },
        ];
    }, [limit]);

    return [data, ...callBacks];
}
