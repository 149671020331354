import Card from 'common/components/card';
import FieldListPhones from 'common/components/field-list/field-list-phones';
import FieldListEmails from 'common/components/field-list/fild-list-emails';
import FieldSet from 'common/components/field-set/field-set';
import CheckBoxField from 'common/components/field/checkbox-field';
import InputField from 'common/components/field/input-field';
import InfiniteSelect from 'common/components/infinite-select/infinite-select';
import { labels } from 'common/constants/labels';
import { useTabIndex } from 'common/hooks/use-tab-index';
import DropdownOption from 'common/models/dropdown-option';
import { IForm } from 'common/models/types';
import { IPatientContactForm } from 'common/services/api/patient-contact/types';
import { validationRules } from 'features/patients-page/validation-rules';
import React from 'react';

const PatientContactForm = ({ data, errors, onChange, options }: IForm<IPatientContactForm>) => {
    const { firstName, middleName, lastName, phones, emails, isEmergencyContact, isMainContact, relationshipId } = data;

    const { getTabIndex } = useTabIndex();

    return (
        <div className="edit-form patient-contact outline-none" tabIndex={getTabIndex()}>
            <FieldSet name="firstName" errors={errors} customClass={`edit-field required-field-star`}>
                <InputField
                    placeholder="First Name"
                    name="firstName"
                    onChange={onChange}
                    value={firstName}
                    tabIndex={getTabIndex()}
                ></InputField>
            </FieldSet>
            <FieldSet name="middleName" errors={errors} customClass="edit-field ">
                <InputField
                    maxLength={validationRules.middleName.maxLength}
                    placeholder={labels.middleNameInitial}
                    name="middleName"
                    onChange={onChange}
                    value={middleName}
                    tabIndex={getTabIndex()}
                ></InputField>
            </FieldSet>
            <FieldSet name="lastName" errors={errors} customClass={`edit-field required-field-star`}>
                <InputField
                    placeholder="Last Name"
                    name="lastName"
                    onChange={onChange}
                    value={lastName}
                    tabIndex={getTabIndex()}
                ></InputField>
            </FieldSet>
            <FieldSet name="isEmergencyContact" errors={errors} customClass="edit-therapist-field">
                <CheckBoxField
                    id="isEmergencyContact"
                    placeholder="Is Emergency Contact"
                    name="isEmergencyContact"
                    onChange={onChange}
                    value={isEmergencyContact}
                    tabIndex={getTabIndex()}
                />
            </FieldSet>
            <FieldSet name="isMainContact" errors={errors} customClass="edit-therapist-field">
                <CheckBoxField
                    id="isMainContact"
                    placeholder="Is Main Contact"
                    name="isMainContact"
                    onChange={onChange}
                    value={isMainContact}
                    tabIndex={getTabIndex()}
                />
            </FieldSet>
            <FieldSet errors={errors} name="relationship" customClass="edit-field">
                <InfiniteSelect
                    id="relationship"
                    label="Relationship"
                    items={options.relationshipTypes}
                    value={options.relationshipTypes.find((item: DropdownOption) => item.value === relationshipId)}
                    onChange={(item) => onChange({ name: 'relationshipId', value: item.value })}
                    definedTabIndex={getTabIndex()}
                />
            </FieldSet>
            <Card className="void-card pl-34 patient-contact-form" noBorder titleIcon="icon-phone" title="Phones">
                <FieldListPhones data={phones} errors={errors} onChange={onChange} tabIndex={getTabIndex()} />
            </Card>

            <Card className="void-card pl-34 patient-contact-form" noBorder titleIcon="icon-email" title="Email Addresses">
                <FieldListEmails data={emails} errors={errors} onChange={onChange} isRequired={true} tabIndex={getTabIndex()} />
            </Card>
        </div>
    );
};

export default PatientContactForm;
