export enum ReasonType {
    RejectDocument = 'RejectDocument',
    RejectProfile = 'RejectProfile',
    DeclineRequest = 'DeclineRequest',
    DeclineSchoolRequest = 'DeclineSchoolRequest',
    ReopenRequest = 'ReopenRequest',
    RefuseLead = 'RefuseLead',
    CloseRequet = 'CloseRequest',
}

export interface IReason {
    id: number;
    name: string;
    type: ReasonType;
}
