export const PROVIDER_NAME_COLUMN = {
    field: 'providerName',
    headerName: 'Provider Name',
    sortable: true,
    width: 270,
};

export const DISCIPLINE_COLUMN = {
    field: 'discipline',
    headerName: 'Discipline',
    sortable: true,
    width: 120,
};
export const DAYS_COLUMN = {
    field: 'days',
    headerName: 'Days',
    initialSort: 'desc',
    sortable: true,
    width: 80,
};
