import FieldSet from 'common/components/field-set/field-set';
import CheckBoxField from 'common/components/field/checkbox-field';
import InputField from 'common/components/field/input-field';
import TextAreaField from 'common/components/field/text-area-field';
import InfiniteSelect from 'common/components/infinite-select/infinite-select';
import RightSideBar from 'common/components/right-side-bar/right-side-bar';
import DropdownOption from 'common/models/dropdown-option';
import { FieldValue } from 'common/models/field-value';
import { IAddEditDocumentRequest } from 'common/services/api/document/types';
import React, { useEffect } from 'react';
import { useState } from 'react';
import { AddEditDocumentProps } from './types';
import { documentScheme } from './document-scheme';

// TO-DO: Set tab indexes on the form
const AddEditDocumentDialog: React.FC<AddEditDocumentProps> = ({ title, defaultDocument, onCancel, onSave }) => {
    const [document, setDocument] = useState<IAddEditDocumentRequest>({
        name: defaultDocument?.name,
        type: defaultDocument?.documentType,
        isExpirationDateRequired: defaultDocument?.isExpirationDateRequired,
        isIssueDateRequired: defaultDocument?.isIssueDateRequired,
        needsApprove: defaultDocument?.needsApprove,
        expirationPeriod: defaultDocument?.expirationPeriod,
        comment: defaultDocument?.comment,
        instructions: defaultDocument?.instructions,
    });

    useEffect(() => {
        if (!document.isExpirationDateRequired) {
            handleFieldChange({ name: 'expirationPeriod', value: undefined });
        }
    }, [document.isExpirationDateRequired]);

    const [errorData, setErrorData] = useState({});
    const documentTypes: Array<DropdownOption> = [new DropdownOption('Electronic', 'Electronic'), new DropdownOption('Paper', 'Paper')];

    const expirationPeriods: Array<DropdownOption> = [
        new DropdownOption(1 * 12, '1 Year'),
        new DropdownOption(2 * 12, '2 Year'),
        new DropdownOption(3 * 12, '3 Year'),
        new DropdownOption(4 * 12, '4 Year'),
        new DropdownOption(5 * 12, '5 Year'),
    ];

    const handleFieldChange = (data: FieldValue) => {
        setDocument((prevDocument) => ({ ...(prevDocument as IAddEditDocumentRequest), [data.name]: data.value }));
        setErrorData((prevError) => ({ ...prevError, [data.name]: undefined }));
    };

    const handleClickOnSave = () => {
        onSave(document).catch((error) => {
            if (error.response.status === 400) {
                setErrorData(error.response.data);
            }
        });
    };

    return (
        <div>
            <RightSideBar title={title} onCancel={onCancel} onSave={handleClickOnSave}>
                <div className="edit-therapist-form">
                    <FieldSet name="name" errors={errorData} customClass="edit-therapist-field required-field-star">
                        <InputField id="name" placeholder="Document Name" name="name" onChange={handleFieldChange} value={document?.name} />
                    </FieldSet>
                    <FieldSet name="type" errors={errorData} customClass="edit-therapist-field required-field-star">
                        <InfiniteSelect
                            id="documentType"
                            name="type"
                            items={documentTypes}
                            label="Document Type"
                            value={documentTypes.find((item) => document?.type === item.value)}
                            onChange={(item) => handleFieldChange({ name: 'type', value: item.value })}
                        />
                    </FieldSet>
                    <FieldSet name="isExpirationDateRequired" errors={errorData} customClass="edit-therapist-field">
                        <CheckBoxField
                            id="isExpirationDateRequired"
                            onChange={handleFieldChange}
                            name="isExpirationDateRequired"
                            placeholder="Expiration Date Required"
                            value={document?.isExpirationDateRequired}
                        />
                    </FieldSet>
                    {document?.isExpirationDateRequired && (
                        <FieldSet name="expirationPeriod" errors={errorData} customClass="edit-therapist-field">
                            <InfiniteSelect
                                id="expirationPeriod"
                                name="expirationPeriod"
                                items={expirationPeriods}
                                label="Expiration Period"
                                value={expirationPeriods.find((item) => document?.expirationPeriod === item.value)}
                                onChange={(item) => handleFieldChange({ name: 'expirationPeriod', value: item.value })}
                            />
                        </FieldSet>
                    )}
                    <FieldSet name="isIssueDateRequired" errors={errorData} customClass="edit-therapist-field">
                        <CheckBoxField
                            id="isIssueDateRequired"
                            onChange={handleFieldChange}
                            name="isIssueDateRequired"
                            placeholder="Issue Date Required"
                            value={document?.isIssueDateRequired}
                        />
                    </FieldSet>
                    <FieldSet name="needsApprove" errors={errorData} customClass="edit-therapist-field">
                        <CheckBoxField
                            id="needsApprove"
                            name="needsApprove"
                            onChange={handleFieldChange}
                            placeholder="Approval required"
                            value={document?.needsApprove}
                        />
                    </FieldSet>
                    <FieldSet name="comment" errors={errorData} customClass="edit-therapist-field">
                        <TextAreaField
                            id="comment"
                            maxLength={documentScheme.maxLength}
                            placeholder="Internal Comment"
                            name="comment"
                            onChange={handleFieldChange}
                            value={document?.comment}
                        />
                    </FieldSet>
                    <FieldSet name="instructions" errors={errorData} customClass="edit-therapist-field">
                        <TextAreaField
                            id="instructions"
                            maxLength={documentScheme.maxLength}
                            placeholder="Instructions"
                            name="instructions"
                            onChange={handleFieldChange}
                            value={document?.instructions}
                        />
                    </FieldSet>
                </div>
            </RightSideBar>
        </div>
    );
};

export default AddEditDocumentDialog;
