import React, { useCallback, useState } from 'react';
import AddEditDocumentDialog from './components/add-edit-document-dialog/add-edit-document-dialog';
import { IAddEditDocumentRequest, IDocument } from 'common/services/api/document/types';
import { DocumentService } from 'common/services/api/document/document-service';
import { DocumentDetailDialogOptions, DocumentsTabProps } from './types';
import DocumentDetailsDialog from './components/document-details-dialog/document-details-dialog';
import DocumentsTable from './components/documents-table/documents-table';
import { useHasPermission } from 'common/hooks/use-has-permission';
import { IActionOption } from 'common/models/action-option';
import { PermissionType } from 'common/models/permission-type';

const DocumentsTab: React.FC<DocumentsTabProps> = ({ isAddDocumentDialogOpen, onCloseAddDocumentDialog }) => {
    const [documentDetailsDialog, setDocumentDetailsDialog] = useState<DocumentDetailDialogOptions | null>(null);
    const [editDocumentModel, setEditDocumentModel] = useState<IDocument | null>(null);
    const [currentTableData, setTableData] = useState<IDocument[]>([]);
    const [updateDocuments, setUpdateDocuments] = useState(false);

    const handleClickOnEditDocument = useCallback(() => {
        const document = documentDetailsDialog.document;
        setDocumentDetailsDialog(null);
        setEditDocumentModel(document);
    }, [documentDetailsDialog]);

    const hasDocumentManagementPrivilege: boolean = useHasPermission(PermissionType.DocumentManagement);

    const getActions = useCallback(() => {
        const actions: IActionOption[] = [];

        if (hasDocumentManagementPrivilege) {
            actions.push({ title: 'Edit', handler: handleClickOnEditDocument });
        }

        return actions;
    }, [hasDocumentManagementPrivilege, handleClickOnEditDocument]);

    const handleCloseDetailsDialog = () => {
        setDocumentDetailsDialog(null);
    };

    const handleClickOnDocument = useCallback((document: IDocument, index: number, maxLength: number) => {
        setUpdateDocuments(false);
        setDocumentDetailsDialog({ document, currentIndex: index, maxLength });
    }, []);

    const getIndex = (document: IDocument) => {
        return currentTableData.findIndex((x) => x.id === document.id);
    };

    const handleClickOnNextDocument = () => {
        const index = getIndex(documentDetailsDialog.document) + 1;
        setDocumentDetailsDialog({
            document: currentTableData[index],
            currentIndex: index,
            maxLength: documentDetailsDialog.maxLength,
        });
    };

    const handleClickOnPreviousDocument = () => {
        const index = getIndex(documentDetailsDialog.document) - 1;
        setDocumentDetailsDialog({
            document: currentTableData[index],
            currentIndex: index,
            maxLength: documentDetailsDialog.maxLength,
        });
    };

    const handleClickOnSaveEditDocument = (document: IAddEditDocumentRequest) => {
        const documentRequest = { ...document, id: editDocumentModel.id };

        return new DocumentService().edit(editDocumentModel.id, documentRequest).then(() => {
            setEditDocumentModel(null);
            setUpdateDocuments(true);
        });
    };

    const handleClickOnSaveCreateDocument = (document: IAddEditDocumentRequest) => {
        return new DocumentService().create(document).then(() => {
            onCloseAddDocumentDialog();
            setUpdateDocuments(true);
        });
    };

    const getPreviousButton = () => {
        return documentDetailsDialog.currentIndex > 0 ? { text: 'Previous Document', onClick: handleClickOnPreviousDocument } : null;
    };

    const getNextButton = () => {
        return documentDetailsDialog.currentIndex + 1 < currentTableData.length
            ? { text: 'Next Document', onClick: handleClickOnNextDocument }
            : null;
    };

    return (
        <>
            <DocumentsTable onDocumentClick={handleClickOnDocument} updateDocuments={updateDocuments} onDataLoaded={setTableData} />

            {documentDetailsDialog?.document && (
                <DocumentDetailsDialog
                    actions={getActions()}
                    prevButton={getPreviousButton()}
                    nextButton={getNextButton()}
                    onCancel={handleCloseDetailsDialog}
                    document={documentDetailsDialog.document}
                />
            )}

            {editDocumentModel && (
                <AddEditDocumentDialog
                    title="Edit Document Details"
                    onCancel={() => setEditDocumentModel(null)}
                    onSave={handleClickOnSaveEditDocument}
                    defaultDocument={editDocumentModel}
                />
            )}

            {isAddDocumentDialogOpen && (
                <AddEditDocumentDialog title="Add Document" onCancel={onCloseAddDocumentDialog} onSave={handleClickOnSaveCreateDocument} />
            )}
        </>
    );
};

export default DocumentsTab;
