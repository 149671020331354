import React from 'react';
import FieldSet from 'common/components/field-set/field-set';
import NumberField from 'common/components/field/number-field';
import FilterSelect from 'common/components/header/multiple-filter-select/filter-select';
import { useDepartmentData } from 'common/hooks/use-department-data';
import { useDisciplinceData } from 'common/hooks/use-disciplince-data';
import { usePopulationData } from 'common/hooks/use-population-data';
import DropdownOption from 'common/models/dropdown-option';
import { TherapistHireFiltersProps } from './types';

const TherapistHireFilters: React.FC<TherapistHireFiltersProps> = ({ filters, onFiltersChange, gridApi }) => {
    const departments = useDepartmentData().map((x: any) => new DropdownOption(x.name, x.name));
    const disciplines = useDisciplinceData().map((item) => new DropdownOption(item.name, item.name));
    const populations = usePopulationData().map((item) => new DropdownOption(item.name, item.name));

    const handleFieldChange = (data: any) => {
        onFiltersChange({ ...filters, [data.name]: data.value });
    };

    return (
        <div className="new-hires">
            <div className="title">New hires</div>
            <FieldSet name="hiringDays" customClass="hiring-days-input">
                <NumberField
                    id="hiringDays"
                    classic
                    min={0}
                    placeholder="Days"
                    name="hiringDays"
                    onChange={handleFieldChange}
                    value={filters.hiringDays}
                />
            </FieldSet>
            <FieldSet name="preferredDepartment">
                <FilterSelect
                    items={departments}
                    gridApi={gridApi}
                    colId="preferredDepartment"
                    id="preferredDepartmentSelect"
                    label="Preferred Department"
                    multiselect
                    isNullable
                    clearFilters
                    defaultMultipleSelectLabel="All Departments"
                />
            </FieldSet>
            <FieldSet name="therapistType">
                <FilterSelect
                    items={disciplines}
                    gridApi={gridApi}
                    colId="therapistType"
                    id="therapistTypeSelect"
                    label="Type"
                    multiselect
                    clearFilters
                    defaultMultipleSelectLabel="All Types"
                />
            </FieldSet>
            <FieldSet name="preferredPopulation">
                <FilterSelect
                    items={populations}
                    gridApi={gridApi}
                    colId="preferredPopulation"
                    id="preferredPopulationSelect"
                    label="Population"
                    multiselect
                    isNullable
                    clearFilters
                    defaultMultipleSelectLabel="All Populations"
                />
            </FieldSet>
        </div>
    );
};

export default TherapistHireFilters;
