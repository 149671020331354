import React from 'react';

export const NavigationButton = {
    text: '',
    onClick: () => {},
};

const NavigationSideBar = ({ prevButton, nextButton }) => {
    return (
        <div className="navigation-buttons-wrapper">
            <div className="prev-navigation-button">
                {prevButton && (
                    <button type="text" className="base-text-btn white" id="prevNavigationButton" onClick={prevButton.onClick}>
                        {prevButton.text}
                    </button>
                )}
            </div>
            <div className="prev-navigation-button">
                {nextButton && (
                    <button type="text" className="base-text-btn white" id="nextNavigationButton" onClick={nextButton.onClick}>
                        {nextButton.text}
                    </button>
                )}
            </div>
        </div>
    );
};

export default NavigationSideBar;
