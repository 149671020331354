import React, { useEffect, useRef, useState } from 'react';
import { MapLegendProps } from './types';
import { DisplayAddressType } from '../../../services/api/map/types';
import { IIcon } from '../map-wrapper/types';
import './map-legend..scss';
import { useCallback } from 'react';

const MapLegend: React.FC<MapLegendProps> = ({ id, mapRef, items, onDisplayTypeChange, onManualDisplayTypeChange }) => {
    const [checked, setChecked] = useState<any>({});
    const currentGroup = useRef(null);

    const handleFieldChange = (checkedItem: IIcon) => {
        const key = checkedItem.value;
        setChecked((prev: any) => ({ ...prev, [key]: prev[key] ? null : checkedItem }));
        onManualDisplayTypeChange((prev: any) => ({ ...prev, [key]: !prev[key] }));
    };

    const resetLegends = useCallback(() => {
        const checked: any = {};
        items.forEach((item) => !item.disabled && (checked[item.value] = item));
        setChecked(checked);
    }, [items]);

    useEffect(() => {
        resetLegends();
    }, [resetLegends]);

    useEffect(() => {
        if (items?.length > 0) {
            onDisplayTypeChange(
                items.filter((item) => !item.manualHideOnMap && checked[item.value]).map((item) => item.value as DisplayAddressType),
                resetLegends
            );
        }
    }, [checked]);

    return (
        <div className="map-legend" id={id} ref={mapRef}>
            {items
                .filter((item) => !item.hideLegend)
                .map((item, index) => {
                    const isNewGroup = (currentGroup.current === null || currentGroup.current !== item.group) && item.group?.length > 0;
                    const isGroupedItem = item.group?.length > 0;
                    const itemElement = (
                        <div
                            className={`map-legend__item${isGroupedItem ? ' map-legend__item--grouped' : ''}`}
                            key={item.name ?? index}
                            onClick={() => handleFieldChange(item)}
                        >
                            <input
                                type="checkbox"
                                className="black-checkbox"
                                id={item.value}
                                key={item.value}
                                checked={!!checked[item.value]}
                                readOnly
                            />
                            <span></span>
                            {item.icon ? (
                                <img src={item.icon} alt="House" />
                            ) : (
                                <span className="icon icon-marker" style={{ color: item.color }}></span>
                            )}
                            <span className="map-legend__label" style={{ color: item.legendTextColor ?? item.color }}>
                                {item.name}
                            </span>
                        </div>
                    );
                    currentGroup.current = item.group;

                    return (
                        <>
                            {isNewGroup && <span className="map-legend__group-title">{item.group}</span>}
                            {itemElement}
                        </>
                    );
                })}
        </div>
    );
};

export default React.memo(MapLegend);
