import React from 'react';
import InfiniteSelect from 'common/components/infinite-select/infinite-select';
import PhoneInput from '../phone-input/phone-input';
import { PhoneTypeInputProps } from './types';
import NumberField from '../field/number-field';
import { generalValidationRules } from 'common/validation/general-validation-rules';
import { getUniqueName } from '../../helpers/string-helper';

type ExtensionType = {
    name: string;
    value: string;
};

const PhoneTypeInput: React.FC<PhoneTypeInputProps> = ({ phoneTypes, value, errors, onChange, tabIndex }) => {
    const isExtension = value.type === 'Work';

    return (
        <div className="phone">
            <InfiniteSelect
                label="Phone"
                hasError={errors}
                items={phoneTypes}
                value={phoneTypes?.find((i) => i.value === value.type)}
                onChange={(data) => onChange({ ...value, type: data.value })}
            />
            <PhoneInput
                id={getUniqueName('phone.')}
                className="phone"
                definedTabIndex={tabIndex}
                placeholder="Number"
                onChange={(number: any) => onChange({ ...value, number })}
                value={value.number}
            />
            {isExtension && (
                <NumberField
                    id="extension"
                    maxLength={generalValidationRules.phoneExtension.maxLength}
                    placeholder="ext."
                    inputClass="ext-field"
                    name="extension"
                    tabIndex={tabIndex}
                    onChange={(extension: ExtensionType) => onChange({ ...value, extension: extension.value })}
                    value={value.extension}
                />
            )}
        </div>
    );
};

export default PhoneTypeInput;
