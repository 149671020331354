import { Reducer } from '@reduxjs/toolkit';
import { IVersionModal, VersionModalActionTypes } from './types';

const initialState: IVersionModal = {
    visible: false,
};

export const versionModalReducer: Reducer<IVersionModal, VersionModalActionTypes> = (state = initialState, action) => {
    switch (action.type) {
        case 'SET_VERSION_MODAL_STATUS':
            return { ...state, visible: action.visible };
        default:
            return state;
    }
};
