import { InfiniteServerClient } from 'common/helpers/http-clients';
import { ILanguage } from './types';

export class LanguageService {
    getAll(): Promise<ILanguage[]> {
        return InfiniteServerClient.get<ILanguage[]>('/languages').then(
            (response) => {
                return response.data;
            },
            (error) => {
                throw error;
            }
        );
    }

    getLanguageOptions(term?: string, skip?: number, take?: number): Promise<ILanguage[]> {
        return InfiniteServerClient.get<ILanguage[]>('/languages/options', { params: { term, skip, take } }).then(
            (response) => {
                return response.data;
            },
            (error) => {
                throw error;
            }
        );
    }
}

export default new LanguageService();
