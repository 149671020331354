import { Reducer } from '@reduxjs/toolkit';
import { ISiteSettingsState, SiteSettingsActionTypes } from './types';

const initialState: ISiteSettingsState = {
    settings: null,
};

export const siteSettingsReducer: Reducer<ISiteSettingsState, SiteSettingsActionTypes> = (state = initialState, action) => {
    switch (action.type) {
        case 'SET_SETTINGS':
            return { ...state, settings: action.settings };
        default:
            return state;
    }
};
