import React from 'react';
import InfiniteSelect, { InfiniteSelectProps } from '../../infinite-select';
import './rate-level-select.scss';

export const RateLevelSelect = (props: InfiniteSelectProps) => {
    return (
        <InfiniteSelect
            id="rateLevel"
            multiselect={true}
            label="Rate Level"
            onRenderSelected={(values: any[]) => {
                return values.map((value) => <span className={'rate-level ' + value.className}>{value.label}</span>);
            }}
            {...props}
        />
    );
};
