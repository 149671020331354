import { Dispatch } from '@reduxjs/toolkit';
import { DepartmentService } from 'common/services/api/department/department-service';
import { IDepartment } from 'common/services/api/department/types';
import { ISetDepartmentsAction, ThunkResult } from './types';

export const setDepartments = (payload: IDepartment[]): ISetDepartmentsAction => {
    return {
        departments: payload,
        type: 'SET_DEPARTMENTS',
    };
};

export const fetchDepartments = (): ThunkResult<Promise<IDepartment[]>> => {
    return async (dispatch: Dispatch) => {
        const departments = await new DepartmentService().getAll();
        dispatch(setDepartments(departments));

        return departments;
    };
};
