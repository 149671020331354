import FieldSet from 'common/components/field-set/field-set';
import NumberField from 'common/components/field/number-field';
import PaginationAutocomplete from 'common/components/field/pagination-autocomplete/pagination-autocomplete';
import InfiniteSelect from 'common/components/infinite-select/infinite-select';
import { departmentKey } from 'common/constants/department-key';
import { entityTypeKey } from 'common/constants/entity-type';
import { ConverterHelper } from 'common/helpers/converter-helper';
import { usePopulationData } from 'common/hooks/use-population-data';
import DropdownOption from 'common/models/dropdown-option';
import { IForm } from 'common/models/types';
import EntityContactService from 'common/services/api/entity-contact/entity-contact-service';
import { IEntityContactOption } from 'common/services/api/entity-contact/type';
import EntityService from 'common/services/api/entity/entity-service';
import { IEntityOption } from 'common/services/api/entity/types';
import LanguageService from 'common/services/api/language/language-service';
import TherapyService from 'common/services/api/therapy/therapy-service';
import { ITherapyTypeOption } from 'common/services/api/therapy/types';
import { IUserBaseInfo } from 'common/services/api/user/types';
import UserService from 'common/services/api/user/user-service';
import { generalValidationRules } from 'common/validation/general-validation-rules';
import React, { useEffect, useState } from 'react';
import { IRequestCaseDetails, IRequiredFields } from './types';
import { useIsUserType } from 'common/hooks/use-is-user-type';
import { SourceType } from 'common/constants/source-type';
import baseService from 'common/services/api/base/base-service';
import { OptionField } from 'common/constants/option-fields';
import TextAreaField from 'common/components/field/text-area-field';
import InputField from 'common/components/field/input-field';
import StateService from 'common/services/api/state/state-service';
import { ICounty } from 'common/services/api/county/types';
import CountyService from 'common/services/api/county/county-service';

const sourcesOptions = [
    new DropdownOption(1, SourceType.Email),
    new DropdownOption(2, SourceType.Phone),
    new DropdownOption(3, SourceType.Text),
    new DropdownOption(4, SourceType.CustomerPortal),
];

// TO-DO: Set tab indexes on the form
const RequestCaseForm = ({
    data,
    isEdit,
    errors,
    onChange,
    requiredFields,
    isFetch,
    options,
    setFetch,
}: IForm<IRequestCaseDetails> & IRequiredFields) => {
    const populationsOptions = usePopulationData().map((item) => new DropdownOption(item.id, item.name));
    const { isExternal } = useIsUserType();

    const [paySources, setPaySources] = useState<DropdownOption[]>([]);

    useEffect(() => {
        if (!isFetch && setFetch) {
            onChange({ name: 'patientId', value: undefined });
            setFetch(false);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [data.customerId]);

    useEffect(() => {
        if (isEdit) {
            baseService.getOptions(null, null, null, OptionField.paySource, null).then((response) => {
                setPaySources(response.map((item) => new DropdownOption(item.id, item.name)));
            });
        }
    }, [isEdit]);

    const getCustomerOptions = (term: string, skip: number, take: number): Promise<IEntityOption[]> => {
        return EntityService.getEntityOptions(term, skip, take, entityTypeKey.CHHA);
    };

    const getFacilitiesOptions = (term: string, skip: number, take: number): Promise<IEntityOption[]> => {
        return EntityService.getEntityOptions(term, skip, take, entityTypeKey.PS, isExternal && data?.customerId);
    };

    const getTherapyOptions = (term: string, skip: number, take: number): Promise<ITherapyTypeOption[]> => {
        return TherapyService.getTherapyTypeOptions(departmentKey.HCA, term, skip, take);
    };

    const getInternalReferralSourceOptions = (term: string, skip: number, take: number): Promise<IUserBaseInfo[]> => {
        return UserService.getUserStaffOptions(term, skip, take, true);
    };

    const getCaseManagerOptions = (term: string, skip: number, take: number): Promise<IEntityContactOption[]> => {
        return EntityContactService.getContactOptions(term, skip, take, data?.customerId);
    };

    const getExteranlReferalSource = (term: string, skip: number, take: number): Promise<IEntityContactOption[]> => {
        return EntityContactService.getContactOptions(term, skip, take);
    };

    const addressRequired = options?.generateAddressClassName ?? '';

    const fetchCountiesData = (term: string, skip: any, take: number): Promise<ICounty[]> => {
        if (data.stateId) {
            return CountyService.getByStateWithPagination(data.stateId, term, skip, take);
        } else {
            return new Promise<ICounty[]>(() => {});
        }
    };

    return (
        <>
            <div className="edit-form">
                {isEdit && (
                    <FieldSet name="therapyTypeIds" errors={errors} customClass="edit-field" requiredFields={requiredFields}>
                        <PaginationAutocomplete
                            fetchData={getTherapyOptions}
                            convertFunction={ConverterHelper.therapyTypeConvert}
                            id="therapyTypeIds"
                            defaultValue={data?.labels?.therapyTypeIds}
                            placeholder="Therapy Type"
                            name="therapyTypeIds"
                            tabIndex=""
                            onDelete={() => onChange({ name: 'therapyTypeIds', value: undefined })}
                            onSelect={(item) => onChange({ name: 'therapyTypeIds', value: [item.value], lable: [item.label] })}
                        />
                    </FieldSet>
                )}
                <FieldSet name="specialInstructions" errors={errors} customClass="edit-field">
                    <TextAreaField
                        id="specialInstructions"
                        value={data.specialInstructions}
                        placeholder="Special Instructions"
                        name="specialInstructions"
                        onChange={onChange}
                    />
                </FieldSet>
                {!isExternal && (
                    <FieldSet name="source" errors={errors} customClass="edit-field" requiredFields={requiredFields}>
                        <InfiniteSelect
                            label="Source"
                            items={sourcesOptions}
                            id="source"
                            name="source"
                            value={sourcesOptions.find((item) => data.source === item.label)}
                            onChange={(item) => onChange({ name: 'source', value: item.label })}
                        />
                    </FieldSet>
                )}
                {!isExternal && (
                    <FieldSet name="customerId" errors={errors} customClass="edit-field" requiredFields={requiredFields}>
                        <PaginationAutocomplete
                            className="autocomplete-one-border"
                            fetchData={getCustomerOptions}
                            convertFunction={ConverterHelper.entityConvert}
                            id="customerId"
                            defaultValue={data?.labels?.customerId}
                            placeholder="Customer"
                            name="customerId"
                            tabIndex=""
                            onDelete={() => onChange({ name: 'customerId', value: undefined })}
                            onSelect={(item) => {
                                onChange({ name: 'customerId', value: item.value, lable: item.label });
                                onChange({ name: 'patientId', value: undefined });
                            }}
                        />
                    </FieldSet>
                )}
                <FieldSet name="facilityId" errors={errors} customClass="edit-field" requiredFields={requiredFields}>
                    <PaginationAutocomplete
                        className="autocomplete-one-border"
                        fetchData={getFacilitiesOptions}
                        convertFunction={ConverterHelper.entityConvert}
                        id="facility"
                        defaultValue={data?.labels?.facilityId}
                        placeholder="Facility"
                        name="facility"
                        tabIndex=""
                        onDelete={() => onChange({ name: 'facilityId', value: undefined })}
                        onSelect={(item) => onChange({ name: 'facilityId', value: item.value, lable: item.label })}
                    />
                </FieldSet>
                <FieldSet name="populationId" errors={errors} customClass="edit-field" requiredFields={requiredFields}>
                    <InfiniteSelect
                        label="Population"
                        items={populationsOptions}
                        id="population"
                        name="population"
                        value={populationsOptions.find((item) => data.populationId === item.value)}
                        onChange={(item) => onChange({ name: 'populationId', value: item.value })}
                    />
                </FieldSet>
                <FieldSet name="languageId" errors={errors} customClass="edit-field" requiredFields={requiredFields}>
                    <PaginationAutocomplete
                        className="autocomplete-one-border"
                        fetchData={LanguageService.getLanguageOptions}
                        convertFunction={ConverterHelper.languageConvert}
                        id="language"
                        defaultValue={data?.labels?.languageId}
                        placeholder="Language"
                        name="language"
                        tabIndex=""
                        onDelete={() => onChange({ name: 'languageId', value: undefined })}
                        onSelect={(item) => onChange({ name: 'languageId', value: item.value, lable: item.label })}
                    />
                </FieldSet>
                {isEdit && (
                    <FieldSet name="primaryInsuranceId" errors={errors} customClass="edit-field" requiredFields={requiredFields}>
                        <InfiniteSelect
                            label="Primary Insurance"
                            items={paySources}
                            id="primaryInsuranceId"
                            name="primaryInsuranceId"
                            value={paySources.find((item: DropdownOption) => {
                                return data.primaryInsuranceId === item.value;
                            })}
                            onChange={(item) => onChange({ name: 'primaryInsuranceId', value: item.value })}
                        />
                    </FieldSet>
                )}
                {isEdit && (
                    <FieldSet name="secondaryInsuranceId" errors={errors} customClass="edit-field" requiredFields={requiredFields}>
                        <InfiniteSelect
                            label="Secondary Insurance"
                            items={paySources}
                            id="secondaryInsuranceId"
                            name="secondaryInsuranceId"
                            value={paySources.find((item: DropdownOption) => {
                                console.log(data, 'data');

                                return data.secondaryInsuranceId === item.value;
                            })}
                            onChange={(item) => onChange({ name: 'secondaryInsuranceId', value: item.value })}
                        />
                    </FieldSet>
                )}
                {/*{isEdit && (*/}
                {/*    <FieldSet name="medicalAssistanceProgramId" errors={errors} customClass="edit-field" requiredFields={requiredFields}>*/}
                {/*        <InfiniteSelect*/}
                {/*            label="Medical Assistance Program"*/}
                {/*            items={medicalAssistanceProgram}*/}
                {/*            id="medicalAssistanceProgramId"*/}
                {/*            name="medicalAssistanceProgramId"*/}
                {/*            value={medicalAssistanceProgram.find((item: DropdownOption) => {*/}
                {/*                return data.medicalAssistanceProgramId === item.value;*/}
                {/*            })}*/}
                {/*            onChange={(item) => onChange({ name: 'medicalAssistanceProgramId', value: item.value })}*/}
                {/*        />*/}
                {/*    </FieldSet>*/}
                {/*)}*/}
                {!isExternal && (
                    <FieldSet name="internalReferralSourceId" errors={errors} customClass="edit-field" requiredFields={requiredFields}>
                        <PaginationAutocomplete
                            className="autocomplete-one-border"
                            fetchData={getInternalReferralSourceOptions}
                            convertFunction={ConverterHelper.internalReferralSourceConvert}
                            id="internalReferralSource"
                            defaultValue={data?.labels?.internalReferralSourceId}
                            placeholder="Internal Referral Source"
                            name="internalReferralSource"
                            tabIndex=""
                            onDelete={() => onChange({ name: 'internalReferralSourceId', value: undefined })}
                            onSelect={(item) => onChange({ name: 'internalReferralSourceId', value: item.value, lable: item.label })}
                        />
                    </FieldSet>
                )}
                {!isExternal && (
                    <FieldSet name="externalReferralSourceId" errors={errors} customClass="edit-field" requiredFields={requiredFields}>
                        <PaginationAutocomplete
                            className="autocomplete-one-border"
                            fetchData={getExteranlReferalSource}
                            convertFunction={ConverterHelper.externalReferralSourceConvert}
                            id="externalReferralSource"
                            defaultValue={data?.labels?.externalReferralSourceId}
                            placeholder="External Referral Source"
                            name="externalReferralSource"
                            tabIndex=""
                            onDelete={() => onChange({ name: 'externalReferralSourceId', value: undefined })}
                            onSelect={(item) => onChange({ name: 'externalReferralSourceId', value: item.value, lable: item.label })}
                        />
                    </FieldSet>
                )}
                <FieldSet name="caseManagerId" errors={errors} customClass="edit-field" requiredFields={requiredFields}>
                    <PaginationAutocomplete
                        className="autocomplete-one-border"
                        fetchData={getCaseManagerOptions}
                        fetchTrigger={data.customerId}
                        convertFunction={ConverterHelper.caseManagerConvert}
                        id="caseManager"
                        defaultValue={data?.labels?.caseManagerId}
                        placeholder="Case Manager"
                        name="caseManager"
                        tabIndex=""
                        onDelete={() => onChange({ name: 'caseManagerId', value: undefined })}
                        onSelect={(item) => onChange({ name: 'caseManagerId', value: item.value, lable: item.label })}
                    />
                </FieldSet>
                {isEdit && (
                    <>
                        <FieldSet name="address1" errors={errors?.address} customClass={addressRequired}>
                            <InputField
                                placeholder="Address 1"
                                name="address1"
                                onChange={(data: any) => {
                                    onChange({ ...data, parentName: 'address' });
                                }}
                                value={data.address1}
                                tabIndex={null}
                            />
                        </FieldSet>
                        <FieldSet name="address2" errors={errors?.address} customClass="edit-field">
                            <InputField
                                placeholder="Address 2"
                                name="address2"
                                onChange={(data: any) => {
                                    onChange({ ...data, parentName: 'address' });
                                }}
                                value={data.address2}
                                tabIndex={null}
                            />
                        </FieldSet>
                        <div className="row-two-column">
                            <FieldSet name="city" errors={errors?.address} customClass={addressRequired}>
                                <InputField
                                    placeholder="City"
                                    name="city"
                                    maxLength={generalValidationRules.city.maxLength}
                                    onChange={(data: any) => {
                                        onChange({ ...data, parentName: 'address' });
                                    }}
                                    value={data.city}
                                    tabIndex={null}
                                />
                            </FieldSet>
                            <FieldSet name="stateId" errors={errors?.address} customClass={`${addressRequired} state-autocomplete`}>
                                <PaginationAutocomplete
                                    cleanable={true}
                                    fetchData={StateService.getAll}
                                    convertFunction={ConverterHelper.stateConvert}
                                    id="state"
                                    defaultValue={data?.labels?.stateId}
                                    placeholder="State"
                                    name="stateId"
                                    tabIndex={null}
                                    disabled={options?.isMedA}
                                    onDelete={() => onChange({ name: 'stateId', parentName: 'address', value: undefined })}
                                    onSelect={(item) =>
                                        onChange({ name: 'stateId', parentName: 'address', value: item.value, lable: item.label })
                                    }
                                />
                            </FieldSet>
                        </div>
                        <div className="row-two-column">
                            <FieldSet name="countyId" errors={errors?.address} customClass={`${addressRequired} state-autocomplete`}>
                                <PaginationAutocomplete
                                    cleanable={!addressRequired?.includes('required')}
                                    fetchData={fetchCountiesData}
                                    convertFunction={ConverterHelper.countyConvert}
                                    id="county"
                                    defaultValue={data?.labels?.countyId || ''}
                                    placeholder="County"
                                    name="county"
                                    tabIndex={null}
                                    fetchTrigger={data.stateId}
                                    onDelete={() => onChange({ name: 'countyId', parentName: 'address', value: undefined })}
                                    onSelect={(item) =>
                                        onChange({ name: 'countyId', parentName: 'address', value: item.value, lable: item.label })
                                    }
                                />
                            </FieldSet>
                            <FieldSet name="zip" errors={errors?.address} customClass={addressRequired}>
                                <NumberField
                                    id="zip"
                                    classic
                                    maxLength={generalValidationRules.zip.maxLength}
                                    placeholder="Zip"
                                    name="zip"
                                    tabIndex={null}
                                    onChange={(option: any) =>
                                        onChange({
                                            name: 'zip',
                                            parentName: 'address',
                                            value: option?.value?.length > 0 ? option?.value : null,
                                        })
                                    }
                                    value={data.zip}
                                />
                            </FieldSet>
                        </div>
                    </>
                )}
            </div>
        </>
    );
};

export default RequestCaseForm;
