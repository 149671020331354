export class FilterHelper {
    static isEmpty(filterModel: any) {
        Object.keys(filterModel).forEach((key) => {
            if (filterModel[key]?.length > 0) {
                return false;
            }
        });

        return true;
    }

    static toURLQueryParams(filterModel: any): string[] {
        const paramsArray: string[] = [];

        Object.keys(filterModel).forEach((key) => {
            if (!filterModel[key]) {
                return;
            }

            if (!Array.isArray(filterModel[key])) {
                paramsArray.push(`${key}=${filterModel[key]}`);

                return;
            }

            filterModel[key].forEach((value: string) => {
                if (value) {
                    paramsArray.push(`${key}=${value}`);
                }
            });
        });

        return paramsArray;
    }
}
