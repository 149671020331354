export const therapistRateActions = {
    Edit: 'Edit',
    Change: 'Change',
    Delete: 'Delete',
    ValidateContract: 'Validate Contract',
    NotifyTherapist: 'Notify Therapist',
};

export const contractStates = {
    NotCreated: 'Not Created',
    PendingSignature: 'Pending Signature',
    NotVerified: 'Not Verified',
    Signed: 'Signed',
};
