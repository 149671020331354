import Button from 'common/components/Button/button';
import React from 'react';
import Tooltip from 'react-tooltip-lite';
import { StartProcessButtonProps } from './types';

const StartProcessButton: React.FC<StartProcessButtonProps> = ({ disabled, onClick }) => {
    const button = (
        <Button onClick={onClick} id="startProcess" disabled={disabled} className={`start-process-btn ${disabled ? 'disabled' : 'blue'} `}>
            Start Process
        </Button>
    );

    const tooltipContent = (
        <div className="tooltip-content-status">
            <span className="tooltip-content-status">An assignment must be made before Start Process is enabled</span>
        </div>
    );

    if (disabled) {
        return (
            <Tooltip content={tooltipContent} direction="down" tipContentClassName="tooltip-content-status">
                {button}
            </Tooltip>
        );
    } else {
        return button;
    }
};

export default StartProcessButton;
