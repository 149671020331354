import { InfiniteServerClient } from 'common/helpers/http-clients';
import { trackPromise } from 'react-promise-tracker';
import { IAddEditDocumentRuleRequest, IDocumentRule } from './types';

export class DocumentRuleService {
    getAll(): Promise<IDocumentRule[]> {
        return trackPromise(
            InfiniteServerClient.get<IDocumentRule[]>('/document-rules').then(
                (response) => {
                    return response.data;
                },
                (error) => {
                    throw error;
                }
            )
        );
    }

    create(document: IAddEditDocumentRuleRequest): Promise<IDocumentRule> {
        return trackPromise(
            InfiniteServerClient.post<IDocumentRule>('/document-rules', document).then(
                (response) => {
                    return response.data;
                },
                (error) => {
                    throw error;
                }
            )
        );
    }

    edit(id: number, document: IAddEditDocumentRuleRequest): Promise<IDocumentRule> {
        return trackPromise(
            InfiniteServerClient.put<IDocumentRule>(`/document-rules/${id}`, document).then(
                (response) => {
                    return response.data;
                },
                (error) => {
                    throw error;
                }
            )
        );
    }
}

export default new DocumentRuleService();
