import { Reducer } from '@reduxjs/toolkit';
import { DiscipliceActionTypes, IDisciplineState } from './types';

const initialState: IDisciplineState = {
    data: undefined,
};

export const disciplineReducer: Reducer<IDisciplineState, DiscipliceActionTypes> = (state = initialState, action) => {
    switch (action.type) {
        case 'SET_DISCIPLINES':
            return { ...state, data: action.discplines };
        default:
            return state;
    }
};
