import React, { useEffect, useMemo, useRef, useState } from 'react';
import { GridReadyEvent } from 'ag-grid-community/dist/lib/events';
import { IParamsWatcher } from 'common/components/ag-table/types';
import { getTableData, getFilterData, getExportData } from 'common/services/api/tabel-fetch-service';
import HeaderWrapper from 'common/components/header/header-wrapper';
import HeaderLeftSide from 'common/components/header/header-left-side';
import SearchFilterInput from 'common/components/header/search-filter-input/search-filter-input';
import ColumnsVisibleFilterSelect from 'common/components/header/сolumns-visible-filter-select/columns-visible-filter-select';
import HeaderRightSide from 'common/components/header/header-right-side';
import ExportButton from 'common/components/header/export-button/export-button';
import ClearFiltersButton from 'common/components/header/clear-filters-button/clear-filters-button';
import AgTable from 'common/components/ag-table/ag-table';
import { useSearchInput } from 'common/hooks/use-serch-input';
import { IStaffingAgenciesFilters } from './types';
import { LocalStoreRepository } from 'common/helpers/repository/local-store-repository';
import { DateTimeHelper } from 'common/helpers/date-time-helper';
import { LocalTableRepository } from 'common/helpers/repository/local-table-repository';

const handleFetchData = getTableData('staffing-agencies');
const handleFetchFilters = getFilterData('staffing-agencies/filter/source');
const handleExportData = getExportData('staffing-agencies/export');

const tableRepository = new LocalTableRepository('staffing-table', 'v2');
const customFiltersRepository = new LocalStoreRepository('staffing-table-custom-filters');

let loadedFilters: IStaffingAgenciesFilters = customFiltersRepository.load();
const defaultFilters: IStaffingAgenciesFilters = customFiltersRepository.load() ?? {
    search: null,
};

const StaffingAgenciesTable = () => {
    const [gridApi, setGridApi] = useState(null);
    const [gridColumnApi, setColumnApi] = useState(null);
    const [filters, setFilters] = useState(loadedFilters ?? defaultFilters);
    const { search, handleSearchInputChange, setSearch } = useSearchInput(loadedFilters?.search, (value: string) => {
        setFilters((prev) => ({ ...prev, search: value }));
    });
    const paramsWatcher = useRef<IParamsWatcher>();

    useEffect(() => {
        customFiltersRepository.save(filters);
        loadedFilters = filters;
    }, [filters]);

    const gridOptions = useMemo(
        () => ({
            columnDefs: [
                {
                    field: 'name',
                    headerName: 'Name',
                    width: 350,
                    resizable: true,
                },
                {
                    field: 'address1',
                    headerName: 'Address1',
                    width: 350,
                    resizable: true,
                },
                {
                    field: 'address2',
                    headerName: 'Address2',
                    width: 350,
                    resizable: true,
                },
                {
                    field: 'state',
                    headerName: 'State',
                    filter: 'agCustomFilter',
                    width: 210,
                    filterParams: {
                        isNullable: true,
                    },
                    resizable: true,
                },
                {
                    field: 'zip',
                    headerName: 'Zip',
                    filter: 'agCustomFilter',
                    width: 104,
                    filterParams: {
                        isNullable: true,
                    },
                    resizable: true,
                },
                {
                    field: 'contact',
                    headerName: 'Contact Person',
                    width: 350,
                    resizable: true,
                },
                {
                    field: 'phone',
                    headerName: 'Phone',
                    width: 180,
                    resizable: true,
                },
                {
                    field: 'email',
                    headerName: 'Email address',
                    width: 350,
                    resizable: true,
                },
                {
                    field: 'createdBy',
                    headerName: 'User Created',
                    width: 350,
                    resizable: true,
                },
                {
                    field: 'createdAt',
                    filter: 'agDateColumnFilter',
                    headerName: 'Date',
                    valueFormatter: (props: any) => DateTimeHelper.format(props.value, 'MM/DD/YYYY | hh:mm A'),
                    filterParams: {
                        isDateTime: true,
                    },
                    width: 155,
                    resizable: true,
                },
            ],
            defaultColDef: {
                filterParams: {
                    onFetchFilters: handleFetchFilters,
                },
            },
        }),
        []
    );

    const handleFiltersClear = () => {
        setSearch('');
        setFilters({ ...defaultFilters });
    };

    const handleGridReady = (params: GridReadyEvent) => {
        setGridApi(params.api);
        setColumnApi(params.columnApi);
    };

    return (
        <>
            <div className="main-info-wrapper ag-table-wrapper">
                <HeaderWrapper>
                    <HeaderLeftSide>
                        <SearchFilterInput title="Staffing Agencies" value={search} onInput={handleSearchInputChange} />
                        <ColumnsVisibleFilterSelect title="Staffing Agencies" gridApi={gridApi} gridColumnApi={gridColumnApi} />
                    </HeaderLeftSide>
                    <HeaderRightSide>
                        <ExportButton title="StaffingAgencies" paramsWatcher={paramsWatcher} onExportData={handleExportData} />
                        <ClearFiltersButton
                            title="Staffing Agencies"
                            gridApi={gridApi}
                            gridColumnApi={gridColumnApi}
                            onClick={handleFiltersClear}
                        />
                    </HeaderRightSide>
                </HeaderWrapper>
                <AgTable
                    onGridReady={handleGridReady}
                    gridOptions={gridOptions}
                    customFilters={filters}
                    onFiltersLoadedFromUrl={handleFiltersClear}
                    repository={tableRepository}
                    paramsWatcher={paramsWatcher}
                    onFetchData={handleFetchData}
                />
            </div>
        </>
    );
};

export default StaffingAgenciesTable;
