import { Dispatch } from '@reduxjs/toolkit';
import PatientService from 'common/services/api/patient/patient-service';
import { IPatientDetails } from 'common/services/api/patient/types';
import { ThunkResult } from '../department/types';
import { ISetPatientDetailsAction, IUpdatePatientProfilePhotoAction, SET_PATIENT_DETAILS, UPDATE_PATIENT_PROFILE_PHOTO } from './types';
import { InfiniteServerClient } from 'common/helpers/http-clients';

export const setPatientDetails = (payload: IPatientDetails): ISetPatientDetailsAction => {
    return {
        patient: payload,
        type: SET_PATIENT_DETAILS,
    };
};

export const updatePatientProfilePhoto = (payload: string): IUpdatePatientProfilePhotoAction => {
    return {
        profilePhoto: payload,
        type: UPDATE_PATIENT_PROFILE_PHOTO,
    };
};

export const fetchPatientDetails = (id: number): ThunkResult<Promise<IPatientDetails>> => {
    return async (dispatch: Dispatch) => {
        const patientDetails = await PatientService.getPatientDetails(id);
        dispatch(setPatientDetails(patientDetails));

        return patientDetails;
    };
};

export const updatePatientProfilePhotoRequest = (id: number, photo: File) => {
    return function (dispatch: Dispatch) {
        const bodyFormData = new FormData();
        bodyFormData.append('photo', photo);

        return InfiniteServerClient.post(`/patients/${id}/profile-photos`, bodyFormData).then(
            (response) => {
                const responseBody = response.data;

                if (responseBody) {
                    dispatch(updatePatientProfilePhoto(responseBody.data));

                    return responseBody.data;
                }
            },
            (error) => {
                throw error;
            }
        );
    };
};
