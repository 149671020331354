import React, { useEffect, useState } from 'react';
import RightSideBar from 'common/components/right-side-bar/right-side-bar';
import FieldSet from 'common/components/field-set/field-set';
import TextAreaField from 'common/components/field/text-area-field';
import { trackPromise } from 'react-promise-tracker';
import { useDispatch } from 'react-redux';
import { toast } from 'react-toastify';
import InfiniteSelect from 'common/components/infinite-select/infinite-select';
import { reasonsDropDownOptions, THERAPIST_HAS_EFFECTIVE_ASSIGNMENT } from './constants';
import { setInactiveRules } from './inactive-reason-rules';
import { SetInactiveReasonDialogProps } from './types';
import { IInactiveReason } from 'common/services/api/therapist/types';
import { deactivateTherapist } from 'app/store/therapist/action-creators';
import { ThunkDispatch } from 'redux-thunk';
import { Action } from '@reduxjs/toolkit';

const SetInactiveReasonDialog: React.FC<SetInactiveReasonDialogProps> = ({ therapistId, onCancel }) => {
    const [inactiveReasonData, setInactiveReasonData] = useState<IInactiveReason>({
        reasons: [],
        comment: '',
    });

    const [isOtherSelected, setIsOtherSelected] = useState(false);

    useEffect(() => {
        setIsOtherSelected(inactiveReasonData.reasons.includes('Other'));
    }, [inactiveReasonData.reasons]);

    const [errorData, setErrorData] = useState({});
    const dispatch = useDispatch<ThunkDispatch<any, any, Action>>();

    const handleChange = (data: any) => {
        setInactiveReasonData((prevInfo) => ({ ...prevInfo, [data.name]: data.value }));
        setErrorData((prevError) => ({ ...prevError, [data.name]: undefined }));
    };

    const handleSave = () => {
        trackPromise(
            dispatch(deactivateTherapist(therapistId, inactiveReasonData))
                .then(() => {
                    onCancel();
                })
                .catch((error) => {
                    switch (error.errorCode) {
                        case THERAPIST_HAS_EFFECTIVE_ASSIGNMENT:
                            toast.error('Provider with an open assignment cannot be Set Inactive.');
                            break;
                        default:
                            setErrorData(error);
                    }
                })
        );
    };

    return (
        <div className="inactive-wrapper">
            <RightSideBar title="Set Inactive" onCancel={onCancel} onSave={handleSave}>
                <FieldSet name="reasons" errors={errorData} customClass="edit-therapist-field">
                    <InfiniteSelect
                        label="Reason"
                        items={reasonsDropDownOptions}
                        onChange={(item) => handleChange({ name: 'reasons', value: item.map((i: any) => i.value) })}
                        id="reasons"
                        name="reasons"
                        definedTabIndex={1}
                        multiselect={true}
                        value={reasonsDropDownOptions.filter((item) => inactiveReasonData.reasons.some((id) => id === item.value))}
                    ></InfiniteSelect>
                </FieldSet>
                <FieldSet
                    name="comment"
                    errors={errorData}
                    customClass={`edit-therapist-field inactive-reason ${isOtherSelected ? 'required-field-star' : ''}`}
                >
                    <TextAreaField
                        placeholder="Comments"
                        id="comment"
                        name="comment"
                        value={inactiveReasonData.comment}
                        onChange={(data: any) => handleChange({ name: data.name, value: data.value })}
                        maxLength={setInactiveRules.commentLength}
                    ></TextAreaField>
                </FieldSet>
            </RightSideBar>
            <div className="right-sidebar-overlay"></div>
        </div>
    );
};

export default SetInactiveReasonDialog;
