import { ColumnApi, GridApi, IFilterComp } from 'ag-grid-community';
import { CellClickedEvent, GridReadyEvent } from 'ag-grid-community/dist/lib/events';
import AgTable from 'common/components/ag-table/ag-table';
import { IParamsWatcher } from 'common/components/ag-table/types';
import BadgeTopFilter from 'common/components/badges/badge/badge-top-filter/badge-top-filter';
import { IBadge } from 'common/components/badges/types';
import ClearFiltersButton from 'common/components/header/clear-filters-button/clear-filters-button';
import ExportButton from 'common/components/header/export-button/export-button';
import HeaderLeftSide from 'common/components/header/header-left-side';
import HeaderRightSide from 'common/components/header/header-right-side';
import HeaderWrapper from 'common/components/header/header-wrapper';
import SearchFilterInput from 'common/components/header/search-filter-input/search-filter-input';
import ColumnsVisibleFilterSelect from 'common/components/header/сolumns-visible-filter-select/columns-visible-filter-select';
import { DateTimeHelper } from 'common/helpers/date-time-helper';
import { FilterHelper } from 'common/helpers/filter-helper';
import { LocalStoreRepository } from 'common/helpers/repository/local-store-repository';
import { useSearchInput } from 'common/hooks/use-serch-input';
import RequestService from 'common/services/api/requests/request-service';
import {
    ITopFilter,
    RequestStatus,
    RequestStatusCount,
    RequestStatusLabel,
    RequestCountToStatus,
    RequestStatusToRequestCount,
} from 'common/services/api/requests/types';
import { getExportData, getFilterData, getTableData } from 'common/services/api/tabel-fetch-service';
import { NavigationRoutes } from 'models/routes/navigation-routes';
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useHistory } from 'react-router';
import { ExtentedDefaultTableProps, IDefaultFilters } from 'common/constants/types';
import { ColDef } from 'ag-grid-community/dist/lib/entities/colDef';
import { CUSTOMER, dateTimeConstants, PATIENT_AGENCY_CODE, PATIENT_NAME } from 'common/constants/common';
import { LocalTableRepository } from 'common/helpers/repository/local-table-repository';
import { useSelector } from 'react-redux';
import { useIsUserType } from 'common/hooks/use-is-user-type';
import { requestExternalColumnDefs } from './request-table-config';
import moment from 'moment';
import { DisplayValueHelper } from 'common/helpers/display-value-helper';
import MultipleFilterAutocomplete from 'common/components/header/multiple-filter-autocomplete/multiple-filter-autocomplete';
import MultipleBadges from 'common/components/badges/multiple-badges';
import FilterCheckbox from 'common/components/filter-checkbox/filter-checkbox';
import { SourceType } from 'common/constants/source-type';
import { DetailsNavigationHelper } from 'common/helpers/details-navigation-helper';

// Fetches
const handleFetchData = getTableData('requests');
const handleFetchFilters = getFilterData('requests/filter/source');
const handleExportData = getExportData('requests/export');

const defaultBadgeList = [
    { value: RequestStatusCount.New, title: 'New', body: 0 },
    { value: RequestStatusCount.InProgress, title: 'In Progress', body: 0 },
    { value: RequestStatusCount.Assigned, title: 'Assigned', body: 0 },
    { value: RequestStatusCount.AwaitingAuth, title: 'Pending Auth', body: 0 },
    { value: RequestStatusCount.PendingLegacy, title: 'Pending Legacy', body: 0 },
    { value: RequestStatusCount.Complete, title: 'Complete', body: 0 },
    { value: RequestStatusCount.Declined, title: 'Declined', body: 0 },
    { value: RequestStatusCount.Hold, title: 'Hold', body: 0 },
];

const externalBadgeList = [
    { value: 'newCount', title: 'New', body: 0 },
    { value: 'acceptedTodayCount', title: 'Accepted Today', body: 0 },
    { value: 'awaitingAuthCount', title: 'Awaiting Auth', body: 0 },
];

export const RequestStatusCountLabel = new Map<RequestStatusCount, string>([
    [RequestStatusCount.New, 'New'],
    [RequestStatusCount.AcceptedToday, 'Accepted Today'],
    [RequestStatusCount.InProgress, 'In Progress'],
    [RequestStatusCount.Assigned, 'Assigned'],
    [RequestStatusCount.AwaitingAuth, 'Pending Auth'],
    [RequestStatusCount.PendingLegacy, 'Pending Legacy'],
    [RequestStatusCount.Complete, 'Complete'],
    [RequestStatusCount.Declined, 'Declined'],
    [RequestStatusCount.Hold, 'Hold'],
]);

const statusField = 'status';

const RequestTable: React.FC<ExtentedDefaultTableProps> = ({
    updateTable,
    storePrefix = '',
    extendGridOptions,
    extendDefaultCustomFilters,
}) => {
    // Repositories
    const entityId = useSelector((state: any) => state.auth.entity?.id);
    const tableRepository = useMemo(() => new LocalTableRepository(`${storePrefix}-${entityId ?? ''}request-table`, 'v6'), [
        storePrefix,
        entityId,
    ]);
    const customFiltersRepository = useMemo(
        () => new LocalStoreRepository(`${storePrefix}-${entityId ?? ''}request-table-custom-filters`, 'v5'),
        [storePrefix, entityId]
    );
    const loadedFilters = useRef<IDefaultFilters>(customFiltersRepository.load());
    const [defaultKeys, setDefaultKeys] = useState([]);

    const { isExternal } = useIsUserType();
    if (entityId && loadedFilters.current) {
        loadedFilters.current['entityId'] = entityId;
    }

    const defaultFilters: IDefaultFilters = {
        search: null,
        entityId: entityId,
        acceptedToday: null,
    };

    if (extendDefaultCustomFilters) {
        Object.keys(extendDefaultCustomFilters).forEach((key) => {
            defaultFilters[key] = extendDefaultCustomFilters[key];
            if (loadedFilters.current) {
                loadedFilters.current[key] = extendDefaultCustomFilters[key];
            }
        });
    }

    const [badgeList, setBadgeList] = useState(isExternal === true ? externalBadgeList : defaultBadgeList);
    const { search, handleSearchInputChange, setSearch } = useSearchInput(loadedFilters.current?.search, (value: string) => {
        setFilters((prev) => ({ ...prev, search: value }));
    });
    const [gridApi, setGridApi] = useState<GridApi>(null);
    const [gridColumnApi, setColumnApi] = useState<ColumnApi>(null);
    const [filters, setFilters] = useState(loadedFilters.current ?? defaultFilters);
    const paramsWatcher = useRef<IParamsWatcher>();
    const history = useHistory();
    const lastQuery = useRef(null);

    useEffect(() => {
        customFiltersRepository.save(filters);
        loadedFilters.current = filters;
    }, [filters, customFiltersRepository]);

    // Badge
    const handleBadgeChange = useCallback(
        (currentValues: IBadge[]) => {
            if (!gridApi || !gridColumnApi) {
                return;
            }

            type filerModelType = {
                values: string[];
            };

            const setFilter = (name: string, filerModel: filerModelType) => {
                gridApi.getFilterInstance(name, (filterApi) => {
                    filterApi.setModel({ ...filterApi.getModel(), exclude: false, ...filerModel });
                });
                gridApi.onFilterChanged();
            };

            const resetAcceptedToday = () => setFilters((prev) => ({ ...prev, acceptedToday: null }));

            const status: string[] = currentValues
                ?.filter((item) => item.value !== RequestStatusCount.AcceptedToday)
                ?.map((value) => RequestCountToStatus.get(value.value as RequestStatusCount));

            resetAcceptedToday();
            setFilter(statusField, { values: status?.length ? status : null });

            currentValues.forEach((selectedBadge) => {
                if (selectedBadge?.value === RequestStatusCount.AcceptedToday) {
                    setFilters((prev) => ({ ...prev, acceptedToday: true }));
                    gridApi.onFilterChanged();
                }
            });
        },
        [gridApi, gridColumnApi]
    );

    // Status column to Badge
    useEffect(() => {
        if (!gridApi) {
            return;
        }

        const handleFilterChange = (e: any) => {
            e.api.getFilterInstance(statusField, (filterApi: IFilterComp) => {
                const defaultKeys = [];
                if (filters.acceptedToday) {
                    defaultKeys.push(RequestStatusCount.AcceptedToday);
                }

                if (filterApi.getModel()?.values) {
                    defaultKeys.push(...filterApi.getModel().values.map((value: string) => RequestStatusToRequestCount.get(value)));
                }

                setDefaultKeys(defaultKeys);
            });
        };

        gridApi.addEventListener('filterChanged', handleFilterChange);

        return () => {
            gridApi.removeEventListener('filterChanged', handleFilterChange);
        };
    }, [gridApi, filters]);

    const updateCount = (data: any, params?: IParamsWatcher) => {
        let queryParams = '';
        if (params) {
            const { getFilterModelParams, getSortParams, getCustomFilterParams, request } = paramsWatcher.current;

            queryParams = FilterHelper.toURLQueryParams({
                ...getSortParams(request),
                ...getFilterModelParams(request),
                ...getCustomFilterParams,
                authorizationStatus: null,
                status: null,
                acceptedToday: null,
            }).join('&');
        }

        if (lastQuery.current === queryParams) {
            return;
        }

        lastQuery.current = queryParams;

        RequestService.getCountGroupStatuses(queryParams).then((data: ITopFilter) => {
            setBadgeList(
                badgeList.map((item) => ({
                    value: item.value,
                    title: RequestStatusCountLabel.get(item.value as RequestStatusCount),
                    body: data[item.value as RequestStatusCount],
                }))
            );
        });
    };

    useEffect(() => {
        if (updateTable && gridApi) {
            gridApi.onFilterChanged();
        }
    }, [updateTable, gridApi]);

    const gridOptions = useMemo(
        () => ({
            ...extendGridOptions,
            onCellClicked: (event: CellClickedEvent) => {
                const { id, patientId, customerId } = event.data;

                switch (event.column.getColId()) {
                    case PATIENT_NAME: {
                        if (patientId) {
                            window.open(NavigationRoutes.patientDetailsRoute(patientId), '_blank').focus();
                        }
                        break;
                    }
                    case CUSTOMER: {
                        if (customerId) {
                            window.open(NavigationRoutes.entityDetailsRoute(customerId), '_blank').focus();
                        }
                        break;
                    }
                    default: {
                        history.push(
                            { pathname: NavigationRoutes.requestDetailsRoute(id) },
                            DetailsNavigationHelper.getStateForDetails(paramsWatcher.current, event.node.rowIndex)
                        );
                        break;
                    }
                }
                if (extendGridOptions?.onCellClicked) extendGridOptions?.onCellClicked(event);
            },
            columnDefs: extendGridOptions?.columnDefs ?? (isExternal ? requestExternalColumnDefs : requestColumnDefs),
            defaultColDef: {
                resizable: true,
                filterParams: {
                    onFetchFilters: handleFetchFilters,
                },
            },
            frameworkComponents: {
                statusRender: (props: any) => {
                    const cellValue = (props.valueFormatted ? props.valueFormatted : props.value) || '';

                    return (
                        <span className={`request-status d-flex fixed-width ${RequestStatus[props.value].toLowerCase()}`}>
                            {RequestStatusLabel.get(cellValue)}
                        </span>
                    );
                },
                objectLinkValueRender: (props: any) => {
                    const cellValue = (props.valueFormatted ? props.valueFormatted : props.value) || '';

                    return <span className="object-link">{cellValue}</span>;
                },
                ...extendGridOptions?.frameworkComponents,
            },
        }),
        [extendGridOptions, history, isExternal]
    );

    const handleFiltersClear = () => {
        setSearch('');
        setFilters({ ...defaultFilters });
        setDefaultKeys([]);
    };

    const handleGridReady = (params: GridReadyEvent) => {
        setGridApi(params.api);
        setColumnApi(params.columnApi);
    };

    const exportFileNameGenerator = (type: string) => {
        return `HomeCareRequestExport_${moment().format(dateTimeConstants.MMDDYYYY)}.${type.toLowerCase()}`;
    };

    return (
        <>
            <div className="main-info-wrapper ag-table-wrapper">
                <HeaderWrapper>
                    <HeaderLeftSide>
                        <SearchFilterInput title="Requests" value={search} onInput={handleSearchInputChange} />
                        <ColumnsVisibleFilterSelect title="Requests" gridApi={gridApi} gridColumnApi={gridColumnApi} />
                        <MultipleFilterAutocomplete
                            gridApi={gridApi}
                            colId="county"
                            id="county"
                            label="County"
                            onFetchFilters={handleFetchFilters}
                            isNullable
                        />
                        {!isExternal && (
                            <MultipleFilterAutocomplete
                                gridApi={gridApi}
                                colId="assignedTo"
                                id="assignedTo"
                                label="Assigned To"
                                onFetchFilters={handleFetchFilters}
                                isNullable
                            />
                        )}
                    </HeaderLeftSide>
                    <HeaderRightSide>
                        <ExportButton
                            title="Requests"
                            paramsWatcher={paramsWatcher}
                            onExportData={handleExportData}
                            fileNameGenerator={exportFileNameGenerator}
                        />
                        <ClearFiltersButton title="Requests" gridApi={gridApi} gridColumnApi={gridColumnApi} onClick={handleFiltersClear} />
                    </HeaderRightSide>
                </HeaderWrapper>
                <MultipleBadges
                    className="pl-25"
                    items={badgeList}
                    defaultKey={defaultKeys}
                    onChange={handleBadgeChange}
                    badgeComponent={BadgeTopFilter}
                    repository={new LocalStoreRepository(`${storePrefix}-${entityId ?? ''}request-table-badge`, 'v7')}
                >
                    <FilterCheckbox gridApi={gridApi} columnName="source" filterValue={SourceType.CustomerPortal} />
                </MultipleBadges>
                <AgTable
                    onGridReady={handleGridReady}
                    gridOptions={gridOptions}
                    onFiltersLoadedFromUrl={handleFiltersClear}
                    repository={tableRepository}
                    customFilters={filters}
                    onDataChange={updateCount}
                    paramsWatcher={paramsWatcher}
                    onFetchData={handleFetchData}
                />
            </div>
        </>
    );
};

export const requestColumnDefs: ColDef[] = [
    {
        field: 'department',
        headerName: 'Department',
        valueFormatter: (props: any) => DisplayValueHelper.getValue(props.value, 'name'),
        filter: 'agCustomFilter',
        width: 180,
        filterParams: {
            isNullable: true,
        },
    },
    {
        field: 'createdAt',
        headerName: 'Creation Date',
        valueFormatter: (props: any) => DateTimeHelper.format(props.value, 'MM/DD/YYYY'),
        filter: 'agDateColumnFilter',
        initialSort: 'desc',
        filterParams: {
            isDateTime: true,
        },
        width: 154,
    },
    {
        field: 'therapyType',
        headerName: 'Therapy Type',
        filter: 'agCustomFilter',
        width: 148,
        filterParams: {
            isNullable: true,
        },
    },
    {
        field: 'zipCode',
        headerName: 'Zip Code',
        filter: 'agCustomFilter',
        width: 113,
        filterParams: {
            isNullable: true,
        },
    },
    {
        field: 'state',
        headerName: 'State',
        filter: 'agCustomFilter',
        width: 113,
        filterParams: {
            isNullable: true,
        },
    },
    {
        field: CUSTOMER,
        headerName: 'Customer',
        filter: 'agCustomFilter',
        cellRenderer: 'objectLinkValueRender',
        width: 350,
        filterParams: {
            isNullable: true,
        },
    },
    {
        field: PATIENT_NAME,
        headerName: 'Patient Name',
        filter: null,
        width: 350,
        cellRenderer: 'objectLinkValueRender',
        filterParams: {
            isNullable: true,
        },
    },
    {
        field: PATIENT_AGENCY_CODE,
        headerName: 'Patient ID',
        filter: 'agCustomFilter',
        width: 140,
        filterParams: {
            isNullable: true,
        },
    },
    {
        field: 'source',
        headerName: 'Source of Request',
        filter: 'agCustomFilter',
        width: 188,
        filterParams: {
            isNullable: true,
        },
    },
    {
        field: 'id',
        headerName: 'Request ID',
        filter: 'agCustomFilter',
        width: 130,
    },
    {
        field: 'internalReferralSource',
        headerName: 'Internal Referral Source',
        filter: 'agCustomFilter',
        width: 350,
        filterParams: {
            isNullable: true,
        },
    },
    {
        field: 'externalReferralSource',
        headerName: 'External Referral Source',
        filter: 'agCustomFilter',
        width: 350,
        filterParams: {
            isNullable: true,
        },
    },
    {
        field: 'county',
        headerName: 'County',
        filter: 'agCustomFilter',
        width: 154,
        filterParams: {
            isNullable: true,
        },
    },
    {
        field: 'population',
        headerName: 'Population',
        filter: 'agCustomFilter',
        width: 140,
        filterParams: {
            isNullable: true,
        },
    },
    {
        field: 'assignedTo',
        headerName: 'Assigned To',
        filter: 'agCustomFilter',
        width: 140,
        valueFormatter: (props: any) => props?.value?.fullName,
        filterParams: {
            isNullable: true,
        },
    },
    {
        field: 'authorizationStatus',
        headerName: 'Authorization Status',
        valueFormatter: (props: any) => props?.value?.value,
        filter: 'agCustomFilter',
        width: 207,
    },
    {
        field: statusField,
        headerName: 'Status',
        pinned: 'right',
        cellRenderer: 'statusRender',
        filter: 'agCustomFilter',
        cellClass: 'd-flex',
        width: 130,
        filterParams: {
            transformFromServer: (status: number) => {
                return RequestStatusLabel.get(RequestStatus[status] as any);
            },
        },
    },
];

export default RequestTable;
