import React from 'react';
import DisplayValue from 'common/components/display-value/display-value';
import { PreferencesDisplaySectionProps } from './types';

const PreferencesDisplaySection: React.FC<PreferencesDisplaySectionProps> = ({ preferences }) => {
    return (
        <div className="field-group-wrapper">
            <div className="field-group">
                <DisplayValue id="preferencesCounty" placeholder="County" value={preferences.counties.map((i) => i.name).join(', ')} />
                <DisplayValue id="preferencesZip" placeholder="ZIP" value={preferences.zipCodes.map((i) => i.code).join(', ')} />
            </div>
            <div className="field-group">
                <DisplayValue
                    id="preferencesPopulation"
                    placeholder="Population"
                    value={preferences.populations.map((i) => i.name).join(', ')}
                />
                <DisplayValue
                    id="preferencesDepartment"
                    placeholder="Department"
                    value={preferences.departments.map((i) => i.name).join(', ')}
                />
                {preferences?.subDepartments?.length > 0 && (
                    <DisplayValue
                        id="preferencesSubDepartment"
                        placeholder="Sub Department"
                        value={preferences.subDepartments.map((i) => i.name).join(', ')}
                    />
                )}
            </div>
        </div>
    );
};

export default PreferencesDisplaySection;
