import SimilarDialog from 'common/components/similar-dialog/similar-dialog';
import React, { useState } from 'react';
import Label from '../label/label';
import { DuplicateTherapistLabelProps } from './types';
import { NavigationRoutes } from 'models/routes/navigation-routes';

const DuplicateTherapistLabel: React.FC<DuplicateTherapistLabelProps> = ({ therapists }) => {
    const [isDuplicateDialogOpen, setIsDuplicateDialogOpen] = useState(false);

    const handleClickOnClose = () => {
        setIsDuplicateDialogOpen(false);
    };

    const handleClickOnOpen = () => {
        setIsDuplicateDialogOpen(true);
    };

    const label = (
        <Label
            onClick={handleClickOnOpen}
            text="Duplicate"
            className="duplicate-therapist-label"
            iconClass="icon icon-info duplicate-icon"
        />
    );

    return (
        <div className="duplicate-therapist-wrapper">
            {label}
            {isDuplicateDialogOpen && (
                <div className="duplicate-therapists-wrapper">
                    <SimilarDialog
                        existedEntities={therapists}
                        transformer={handleTransformation}
                        saveButtonText={''}
                        onCancel={handleClickOnClose}
                    />
                </div>
            )}
        </div>
    );
};

const handleTransformation = (therapist: any) => ({
    title: therapist.fullName,
    body: therapist.email,
    url: NavigationRoutes.therapistDetailsRoute(therapist.id),
});

export default DuplicateTherapistLabel;
