import React from 'react';
import DisplayValue from 'common/components/display-value/display-value';
import { EntityBasicInfoDisplaySectionProp } from '../../types';
import { maskConstants } from 'common/constants/common';

const EntityBasicInfoDisplaySection: React.FC<EntityBasicInfoDisplaySectionProp> = ({ entityBasicInfo }) => {
    const { name, functionLinks, parent, phone, address, counties, npiNumber, dbn } = entityBasicInfo;

    const getUniqueFunctionNames = () => {
        const names: string[] = functionLinks.map((link) => link?.legalFunction?.name);

        return Array.from(new Set(names)).join(', ');
    };

    const getSumNumberOfBeds = () => {
        const result: number = functionLinks.reduce(function (prev, current) {
            return prev + (current.linkNumberOfBeds?.numberOfBeds || 0);
        }, 0);

        return result === 0 ? '' : result;
    };

    return (
        <div className="field-group-wrapper">
            <div className="field-group">
                <DisplayValue id="name" placeholder="Name" value={name} />
                <DisplayValue id="type" placeholder="Type" value={getUniqueFunctionNames()} />
                <DisplayValue id="npiNumber" placeholder="NPI" value={npiNumber} />
                <DisplayValue id="dbn" placeholder="DBN" value={dbn} />
                <DisplayValue id="type" placeholder="Parent" value={parent?.name} />
                <DisplayValue id="type" placeholder="No Of Beds" value={getSumNumberOfBeds()} />
            </div>
            <div className="field-group">
                <DisplayValue id="address1" placeholder="Address 1" value={address?.address1} />
                <DisplayValue id="address2" placeholder="Address 2" value={address?.address2} />
                <div className="row-two-column">
                    <DisplayValue id="city" placeholder="City" value={address?.city} />
                    <DisplayValue id="state" placeholder="State" value={address?.state?.name} />
                </div>
                <div className="row-two-column">
                    <DisplayValue id="county" placeholder="County" value={counties.map((county) => county.name).join(', ')} />
                    <DisplayValue id="zip" placeholder="Zip" value={address?.postalCode} />
                </div>
                <DisplayValue id="phone" placeholder="Phone" value={phone} mask={maskConstants.PHONE_NUMBER} />
            </div>
        </div>
    );
};

export default EntityBasicInfoDisplaySection;
