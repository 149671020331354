import React from 'react';
import FocusTrap from 'focus-trap-react';
import RightSideBar from './right-side-bar';
import { RightSideBarFormProps } from './type';

const RightSideBarForm = ({ children, classNameWrapper, ...restProps }: RightSideBarFormProps) => {
    return (
        <>
            <FocusTrap focusTrapOptions={{ allowOutsideClick: true }}>
                <RightSideBar {...restProps}>
                    <div className={'create-form ' + (classNameWrapper ?? '')}>{children}</div>
                </RightSideBar>
            </FocusTrap>
            <div className="right-sidebar-overlay"></div>
        </>
    );
};

export default RightSideBarForm;
