import { Action } from '@reduxjs/toolkit';
import { CellClickedEvent, GridApi, GridOptions, GridReadyEvent } from 'ag-grid-community';
import AgTable from 'common/components/ag-table/ag-table';
import { IParamsWatcher } from 'common/components/ag-table/types';
import Button from 'common/components/Button/button';
import ClearFiltersButton from 'common/components/header/clear-filters-button/clear-filters-button';
import HeaderLeftSide from 'common/components/header/header-left-side';
import HeaderRightSide from 'common/components/header/header-right-side';
import HeaderWrapper from 'common/components/header/header-wrapper';
import SearchFilterInput from 'common/components/header/search-filter-input/search-filter-input';
import InfiniteSelect from 'common/components/infinite-select/infinite-select';
import { IDefaultFilters } from 'common/constants/types';
import { FileHelper } from 'common/helpers/file-helper';
import { LocalStoreRepository } from 'common/helpers/repository/local-store-repository';
import { LocalTableRepository } from 'common/helpers/repository/local-table-repository';
import { useSearchInput } from 'common/hooks/use-serch-input';
import DropdownOption from 'common/models/dropdown-option';
import { getFilterData, getTableData } from 'common/services/api/tabel-fetch-service';
import { getFileActionCreator } from 'features/document-viewer/store/document-viewer-action-creators';
import React, { useEffect, useMemo, useRef, useState, useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { ThunkDispatch } from 'redux-thunk';
import { BackgorundChecksTableColumnDefinitions } from './background-checks-table-column-definitions';
import { BackgroundCheckActions, IBackgroundChecksTableProps } from './types';

const tableRepository = new LocalTableRepository(`background-checks-table`, 'v1');
const filterRespoistory = new LocalStoreRepository(`background-checks-filter`);

const BackgroundChecksList: React.FC<IBackgroundChecksTableProps> = ({
    therapistId,
    forceUpdate,
    onAddBackgroundCheckClick,
    onEditBackgroundCheckClick,
    onEditBackgroundCheckHistoryClick,
}) => {
    const handleFetchData = getTableData(`therapists/${therapistId}/background-checks`);
    const handleFetchFilters = getFilterData(`therapists/${therapistId}/background-checks/filter/source`);

    const dispatch = useDispatch<ThunkDispatch<any, any, Action>>();
    const [gridApi, setGridApi] = useState<GridApi>(null);
    const [gridColumnApi, setColumnApi] = useState(null);
    const [filter, setFilters] = useState<IDefaultFilters>(filterRespoistory.load() || {});
    const paramsWatcher = useRef<IParamsWatcher>();

    useEffect(() => {
        if (gridApi || forceUpdate) {
            gridApi.onFilterChanged();
        }
    }, [gridApi, forceUpdate]);

    const { search, handleSearchInputChange, setSearch } = useSearchInput(filter?.search || '', (value: string) => {
        setFilters((prev) => ({ ...prev, search: value }));
    });

    const getOptionList = useCallback((document: any): DropdownOption[] => {
        const itemOptions: DropdownOption[] = [];
        if (document.historyItems) {
            itemOptions.push(new DropdownOption(BackgroundCheckActions.Add, BackgroundCheckActions.Add));
            itemOptions.push(new DropdownOption(BackgroundCheckActions.Edit, BackgroundCheckActions.Edit));
        } else {
            itemOptions.push(new DropdownOption(BackgroundCheckActions.Edit, BackgroundCheckActions.Edit));
        }

        return itemOptions.sort((option) => option.value);
    }, []);

    const handleOptionClick = useCallback(
        (action: any, document: any) => {
            switch (action) {
                case BackgroundCheckActions.Edit:
                    if (document.historyItems) {
                        return onEditBackgroundCheckClick(document);
                    }

                    return onEditBackgroundCheckHistoryClick(document);
                case BackgroundCheckActions.Add:
                    return onAddBackgroundCheckClick(document);
                default:
                    return;
            }
        },
        [onEditBackgroundCheckClick, onEditBackgroundCheckHistoryClick, onAddBackgroundCheckClick]
    );

    const gridOptions: GridOptions = useMemo(
        () => ({
            columnDefs: BackgorundChecksTableColumnDefinitions,
            defaultColDef: {
                resizable: true,
                filterParams: {
                    onFetchFilters: handleFetchFilters,
                },
            },
            treeData: true,
            isServerSideGroup: (dataItem: any) => {
                return dataItem?.historyItems?.length > 0;
            },
            getServerSideGroupKey: function (dataItem: any) {
                return dataItem?.historyItems;
            },
            autoGroupColumnDef: {
                headerName: '',
                field: 'name',
                sortable: false,
                lockPosition: true,
                maxWidth: 50,
                minWidth: 50,

                cellRendererParams: {
                    innerRenderer: function (params: any) {
                        return params.data.name;
                    },
                },
            },
            onCellClicked: (event: CellClickedEvent) => {
                if (event.column.getColId() === 'originalFileName') {
                    dispatch(getFileActionCreator(event.data.fileId)).then((response: any) => {
                        FileHelper.downloadOrOpen(response.file);
                    });
                }
            },
            frameworkComponents: {
                doteRender: (props: any) => {
                    const itemOptions = getOptionList(props.data);

                    return (
                        itemOptions.length > 0 && (
                            <InfiniteSelect
                                id="therapistDocumentMenu"
                                popper
                                items={itemOptions}
                                className="no-select-border"
                                icon={<i className="icon icon-options" />}
                                onChange={(item: DropdownOption) => {
                                    handleOptionClick(item?.value, props.data);
                                }}
                            />
                        )
                    );
                },
                cellLinkRender: (props: any) => {
                    const cellValue = props.valueFormatted ? props.valueFormatted : props.value;

                    return (
                        !!cellValue && (
                            <Button className="link-button">
                                <span className="icon icon-export" />
                                View
                            </Button>
                        )
                    );
                },
            },
        }),
        [handleFetchFilters, getOptionList, dispatch, handleOptionClick]
    );

    const handleGridReady = (params: GridReadyEvent) => {
        setGridApi(params.api);
        setColumnApi(params.columnApi);
    };

    const handleClearFilter = () => {
        setSearch('');
        setFilters({ search: '' });
    };

    return (
        <div className="main-info-wrapper ag-table-wrapper">
            <HeaderWrapper>
                <HeaderLeftSide>
                    <SearchFilterInput title="Background checks" value={search} onInput={handleSearchInputChange} />
                </HeaderLeftSide>
                <HeaderRightSide>
                    <ClearFiltersButton
                        title="Background Cheks"
                        gridApi={gridApi}
                        gridColumnApi={gridColumnApi}
                        onClick={handleClearFilter}
                    />
                </HeaderRightSide>
            </HeaderWrapper>
            <AgTable
                onGridReady={handleGridReady}
                gridOptions={gridOptions}
                customFilters={filter}
                repository={tableRepository}
                paramsWatcher={paramsWatcher}
                onFetchData={handleFetchData}
            />
        </div>
    );
};

export default React.memo(BackgroundChecksList);
