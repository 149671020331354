import Button from 'common/components/Button/button';
import { FieldValue } from 'common/models/field-value';
import React, { useState } from 'react';
import { toast } from 'react-toastify';
import { ResetPasswordFormProps } from './types';
import PasswordRulesHint from '../password-rules-hint/password-rules-hint';
import PasswordField from '../password-field/password-field';
import { IUpdatePasswordModel } from 'features/update-password-page/types';

const ResetPasswordForm: React.FC<ResetPasswordFormProps> = ({ onSubmit }) => {
    const [model, setUpdatePasswordModel] = useState<IUpdatePasswordModel>();
    const [errorData, setErrorData] = useState({});

    const handleFieldChange = (data: FieldValue) => {
        setUpdatePasswordModel((prev: any) => ({ ...(prev as IUpdatePasswordModel), [data.name]: data.value }));
        setErrorData((prevError) => ({ ...prevError, [data.name]: undefined }));
    };

    const handleClickOnSubmit = () => {
        onSubmit(model).catch((error) => {
            const status = error?.response?.status;

            switch (status) {
                case 400:
                    setErrorData(error.response.data);
                    break;
                case 404:
                    toast.error('User does not exist');
                    break;
                default:
                    break;
            }
        });
    };

    const isDisabled = !model?.password || !model?.confirmPassword;

    return (
        <>
            <div className="edit-form login-form">
                <span className="infinite-logo"></span>
                <h4>Reset Password</h4>
                <h6>Please enter your new password</h6>
                <PasswordField
                    name="password"
                    placeholder="New Password"
                    onChange={handleFieldChange}
                    value={model?.password || ''}
                    errorData={errorData}
                />
                <PasswordField
                    name="confirmPassword"
                    placeholder="Re-enter New Password"
                    onChange={handleFieldChange}
                    value={model?.confirmPassword || ''}
                    errorData={errorData}
                />

                <PasswordRulesHint />

                <Button
                    id="setupPasswordBtn"
                    disabled={isDisabled}
                    className="login-btn"
                    onClick={handleClickOnSubmit}
                    color={isDisabled ? `gray` : 'blue'}
                >
                    Save
                </Button>
            </div>
        </>
    );
};

export default React.memo(ResetPasswordForm);
