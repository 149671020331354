import React from 'react';
import { CellClickedEvent, GridApi, GridOptions, GridReadyEvent, ICellRendererParams, IServerSideGetRowsParams } from 'ag-grid-community';
import { useSearchInput } from 'common/hooks/use-serch-input';
import { IUserBaseInfo } from 'common/services/api/user/types';
import { useMemo } from 'react';
import { useEffect } from 'react';
import { useRef } from 'react';
import { useState } from 'react';
import Avatar from 'common/components/Avatar/Avatar';
import moment from 'moment';
import HeaderWrapper from 'common/components/header/header-wrapper';
import HeaderLeftSide from 'common/components/header/header-left-side';
import SearchFilterInput from 'common/components/header/search-filter-input/search-filter-input';
import HeaderRightSide from 'common/components/header/header-right-side';
import AddButton from 'common/components/header/add-button/add-button';
import AgTable from 'common/components/ag-table/ag-table';
import { IParamsWatcher } from 'common/components/ag-table/types';
import { DEFAULT_OPTIONS_COLUMN_ID, ITherapistMessageFilter, ITherapistMessageTypeFilter, TherapistMessageListProps } from './types';
import { IMessageItem, TherapistCommunicationType } from 'common/services/api/communication/types';
import TherapistMessageFilterType from './therapist-message-filter-type';
import { getTableData } from 'common/services/api/tabel-fetch-service';
import { ICurrentSearchState } from 'features/therapist-details-page/communication-tab/types';

const TherapistMessageList: React.FC<TherapistMessageListProps> = ({
    therapistId,
    forceUpdate,
    onDataChanged,
    onNewButtonClick,
    onItemClick,
    searchData,
    onSearchTermChange,
}) => {
    const currentSearchStateRef = useRef<ICurrentSearchState<IMessageItem>>();
    const currentFilterRef = useRef<ITherapistMessageFilter>();

    const [gridApi, setGridApi] = useState<GridApi>(null);
    const [filters, setFilters] = useState<ITherapistMessageFilter>({ type: null, search: searchData?.term });
    const paramsWatcher = useRef<IParamsWatcher>();

    useEffect(() => {
        currentSearchStateRef.current = searchData;
        currentFilterRef.current = filters;
    }, [searchData, filters]);

    const { search, handleSearchInputChange } = useSearchInput(searchData.term, (value: string) => {
        onSearchTermChange(value, paramsWatcher.current.limit);
        setFilters((prev) => ({ ...prev, type: null, search: value }));
    });

    useEffect(() => {
        if (gridApi || forceUpdate) {
            gridApi.onFilterChanged();
        }
    }, [gridApi, forceUpdate, searchData]);

    const handleClickOnNewEmail = () => onNewButtonClick(TherapistCommunicationType.Email);
    const handleClickOnNewSms = () => onNewButtonClick(TherapistCommunicationType.Sms);

    const handleFetchData = (event: IServerSideGetRowsParams, props: any) => {
        if (currentSearchStateRef && currentSearchStateRef.current.term && !currentFilterRef.current.type) {
            return Promise.resolve({ data: currentSearchStateRef.current.result.data });
        }

        return getTableData(`therapists/${therapistId}/communication/messages`)(event, {
            ...props,
            search: currentSearchStateRef.current.term,
        });
    };

    const gridOptions: GridOptions = useMemo(
        () => ({
            onCellClicked: (event: CellClickedEvent) => {
                if (event.column.getId() === DEFAULT_OPTIONS_COLUMN_ID) {
                    return;
                }

                onItemClick(event.rowIndex, event.data);
            },
            columnDefs: [
                {
                    field: 'sender',
                    cellRenderer: 'senderFormat',
                    flex: 1,
                    width: 280,
                    resizable: true,
                },
                {
                    field: 'message',
                    flex: 2,
                    cellRenderer: 'messageFormat',
                    resizable: true,
                },
                {
                    field: 'type',
                    width: 54,
                    cellRenderer: 'typeFormat',
                    resizable: true,
                },
                {
                    field: 'createdAt',
                    cellClass: 'created-at',
                    width: 150,
                    resizable: true,
                    valueFormatter: (props: any) => moment(props.value).format('MMM DD, YYYY'),
                },
            ],
            rowHeight: 67,
            defaultColDef: {
                sortable: false,
            },
            frameworkComponents: {
                senderFormat: (props: ICellRendererParams) => {
                    const sender: IUserBaseInfo = props.valueFormatted ? props.valueFormatted : props.value;

                    return (
                        <div className="sender-info">
                            <Avatar user={sender} />
                            <span className="email-table-sender">{`${sender.firstName} ${sender.lastName}`}</span>
                        </div>
                    );
                },
                messageFormat: (props: ICellRendererParams) => {
                    const data: IMessageItem = props.data;

                    return (
                        <div className="email-table-message-cell">
                            <span className="email-table-message-title">{data.subject}</span>
                            <span className="email-table-message-text" dangerouslySetInnerHTML={{ __html: data.message }}></span>
                        </div>
                    );
                },
                typeFormat: (props: ICellRendererParams) => {
                    const data: IMessageItem = props.data;
                    const className = data.type === TherapistCommunicationType.Email ? 'icon-email' : 'icon-notes';

                    return <span className={className}></span>;
                },
            },
        }),
        [onItemClick]
    );

    const onGridReady = (params: GridReadyEvent) => {
        setGridApi(params.api);
    };

    const handleTherapistTypeChange = (filterValues: ITherapistMessageTypeFilter) => {
        setFilters((prev) => ({ ...prev, type: filterValues.type }));
    };

    const handleClickOnClearButton = () => {
        handleSearchInputChange('');
    };

    return (
        <>
            <div className="main-info-wrapper ag-table-wrapper">
                <HeaderWrapper>
                    <HeaderLeftSide>
                        <SearchFilterInput
                            onClearClick={handleClickOnClearButton}
                            title="by Communication"
                            value={search}
                            onInput={handleSearchInputChange}
                        />
                        <TherapistMessageFilterType filter={filters} onFilterChange={handleTherapistTypeChange} />
                    </HeaderLeftSide>
                    <HeaderRightSide>
                        <AddButton title="New Email" onClick={handleClickOnNewEmail}></AddButton>
                        <AddButton title="New SMS" onClick={handleClickOnNewSms}></AddButton>
                    </HeaderRightSide>
                </HeaderWrapper>
                <AgTable
                    onDataChange={onDataChanged}
                    onGridReady={onGridReady}
                    gridOptions={gridOptions}
                    customFilters={filters}
                    paramsWatcher={paramsWatcher}
                    onFetchData={handleFetchData}
                />
            </div>
        </>
    );
};

export default React.memo(TherapistMessageList);
