import { CellClickedEvent, ColumnApi, FirstDataRenderedEvent, GridApi, GridReadyEvent, RowNode } from 'ag-grid-community';
import AgTable from 'common/components/ag-table/ag-table';
import { IParamsWatcher } from 'common/components/ag-table/types';
import ClearFiltersButton from 'common/components/header/clear-filters-button/clear-filters-button';
import ExportButton from 'common/components/header/export-button/export-button';
import HeaderLeftSide from 'common/components/header/header-left-side';
import HeaderRightSide from 'common/components/header/header-right-side';
import HeaderWrapper from 'common/components/header/header-wrapper';
import SearchFilterInput from 'common/components/header/search-filter-input/search-filter-input';
import { DisplayValueHelper } from 'common/helpers/display-value-helper';
import { LocalTableRepository } from 'common/helpers/repository/local-table-repository';
import { useSearchInput } from 'common/hooks/use-serch-input';
import ToggleViewMode from 'common/components/toggle-view-mode/toggle-view-mode';
import { DisplayMode } from 'common/components/toggle-view-mode/types';
import { NavigationRoutes } from 'models/routes/navigation-routes';
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { AvailableTherapistCustomFilter } from './available-therapist-filters';
import PatientsOnZipDialog from './patients-in-zip-dialog/patients-in-zip-dialog';
import { AvailableTherapistTableProps, IAvailableTherapistFilter } from './types';
import './abailable-therapist-table.scss';
import { TherapistStatus } from 'features/therapist-details-page/shared/constants';
import availableTherapistService from 'common/services/api/available-therapist-service/available-therapist-service';
import { trackPromise } from 'react-promise-tracker';
import { IAvailableTherapistGridItemRow } from 'common/services/api/available-therapist-service/types';

const THERAPIST_REQUEST_STATUS = 'therapistRequestStatus';
const ADD = 'Add';
const ADDED = 'Added';

const AvailableTherapistTable: React.FC<AvailableTherapistTableProps> = ({
    storePrefix = '',
    extendGridOptions,
    serachTitle = 'Matching Providers',
    onGridReady,
    onMapClick,
    filter,
    filterConfig,
    departments,
    onFilterChange,
    onFilterClear,
    onZipChange,
    onSearchChange,
}) => {
    const tableRepository = new LocalTableRepository(`${storePrefix}-available-therapist-table`, 'v8');

    const onClickRef = useRef(true);

    const gridRef = useRef<any>();
    const filterRef = useRef<IAvailableTherapistFilter>(filter);

    const [rows, setRows] = useState<IAvailableTherapistGridItemRow[]>();
    const [gridApi, setGridApi] = useState<GridApi>(null);
    const [gridColumnApi, setColumnApi] = useState<ColumnApi>(null);
    const paramsWatcher = useRef<IParamsWatcher>();

    const [selectedTherapists, setSelectedTherapists] = useState<any[]>([]);

    const { search, handleSearchInputChange, setSearch: setTherapistSearch } = useSearchInput(filter?.search, onSearchChange);

    const { search: searchZip, handleSearchInputChange: handleSearchZipInputChange, setSearch: setZipSearch } = useSearchInput(
        filter?.searchZip,
        onZipChange
    );

    useEffect(() => {
        filterRef.current = filter;
    }, [filter]);

    useEffect(() => {
        trackPromise(availableTherapistService.getList(filter)).then((response) => {
            setRows(response);
            if (rows) {
                updateAssignmentStatusFilter(gridRef.current.api);
            }
        });
    }, [filter.department, filter.subDepartment, filter.week]);

    useEffect(() => {
        if (gridRef.current?.api) {
            gridRef.current.api.setQuickFilter(filter.search);
        }
    }, [filter.search, gridRef.current?.api]);

    useEffect(() => {
        if (rows && gridRef.current?.api) {
            updateSearchZipFilter(gridRef.current?.api);
        }
    }, [filter.searchZip, gridRef.current?.api]);

    useEffect(() => {
        if (rows && gridRef.current?.api) {
            updateAssignmentStatusFilter(gridRef.current?.api);
        }
    }, [filter.assignmentStatus, gridRef.current?.api]);

    const handleRowSelected = useCallback(
        (rowNode: RowNode) => {
            let therapists = selectedTherapists;

            if (rowNode.isSelected()) {
                const initTtherapistRequestStatus = rowNode.data.therapistRequestStatus;

                if (!initTtherapistRequestStatus || initTtherapistRequestStatus === ADD) {
                    rowNode?.setDataValue(THERAPIST_REQUEST_STATUS, ADDED);
                }

                therapists.push({ ...rowNode.data, initTtherapistRequestStatus: initTtherapistRequestStatus });
            } else {
                const therapist = therapists.find((therapist) => therapist.id === rowNode.data.id);

                if (!therapist?.initTtherapistRequestStatus || therapist?.initTtherapistRequestStatus === ADD) {
                    rowNode?.setDataValue(THERAPIST_REQUEST_STATUS, ADD);
                }

                therapists = therapists.filter((therapist) => therapist.id !== rowNode.data.id);
            }

            setSelectedTherapists(therapists);
        },
        [selectedTherapists]
    );

    const handleFiltersClear = () => {
        const clearedFilter = onFilterClear();

        setTherapistSearch(clearedFilter.search);
        setZipSearch(clearedFilter.searchZip);
        updateSearchZipFilter(gridApi);
        setDefaultStatusFilter(gridApi);
        updateAssignmentStatusFilter(gridApi);
    };

    const updateSearchZipFilter = useCallback(
        (grid: GridApi) => {
            if (grid) {
                grid.getFilterInstance('postalCodes', (filterApi) => {
                    filterApi.setModel(filter.searchZip?.length > 0 ? { ...filterApi.getModel(), values: [filter.searchZip] } : null);

                    grid.onFilterChanged();
                });
            }
        },
        [filter.searchZip]
    );

    const updateAssignmentStatusFilter = useCallback(
        (grid: GridApi) => {
            if (grid) {
                grid.getFilterInstance('assignmentStatus', (filterApi) => {
                    filterApi.setModel(
                        filter.assignmentStatus === 'Active'
                            ? { ...filterApi.getModel(), values: ['true'] }
                            : { ...filterApi.getModel(), values: ['true', 'false'] }
                    );
                    grid.onFilterChanged();
                });
            }
        },
        [filter.assignmentStatus]
    );

    const setDefaultStatusFilter = (grid: GridApi) => {
        grid.getFilterInstance('status', (filterApi) => {
            filterApi.setModel({
                ...filterApi.getModel(),
                exclude: false,
                ...{ values: [TherapistStatus.READY_TO_WORK.label, TherapistStatus.ACTIVE.label] },
            });
            grid.onFilterChanged();
        });
    };

    const handleGridReady = (params: GridReadyEvent) => {
        setGridApi(params.api);
        setColumnApi(params.columnApi);
        if (onGridReady) {
            onGridReady({ params });
        }

        const currentTableFilter = tableRepository.load();

        if (!currentTableFilter) {
            setDefaultStatusFilter(params.api);
        }
    };

    const handleFiltersChange = (filterValues: any) => {
        if (onFilterChange) {
            onFilterChange(filterValues);
        }
    };

    const handleMapClick = () => {
        onMapClick(filter);
    };

    const gridOptions = useMemo(
        () => ({
            ...extendGridOptions,
            onCellClicked: (event: CellClickedEvent) => {
                if (event.column.getColId() === THERAPIST_REQUEST_STATUS) {
                    handleRowSelected(event.node);
                }
            },
            onFirstDataRendered(event: FirstDataRenderedEvent) {
                updateSearchZipFilter(event.api);
                updateAssignmentStatusFilter(event.api);
            },
            onFilterChanged(event: any) {
                console.log(event.api.getFilterModel());
            },
            columnDefs: extendGridOptions?.columnDefs ?? AvailableTherapistColumnDefs,
            frameworkComponents: {
                therapistLink: (props: any) => {
                    return (
                        <div
                            className="icon icon-open-in"
                            onClick={() => window.open(NavigationRoutes.therapistDetailsRoute(props?.data?.id), '_blank').focus()}
                        />
                    );
                },
                valueOrUnknownRender: (props: any) => {
                    const cellValue = props?.value ? props?.value : 'Unknown';

                    return <span>{cellValue}</span>;
                },
                isActiveRender: (props: any) => {
                    const cellValue = props.value ? 'Active' : '';

                    return <span className={cellValue.toLowerCase()}>{cellValue}</span>;
                },
                patientsInZipRender: (props: any) => {
                    return (
                        <PatientsOnZipDialog
                            therapistId={props?.data?.id}
                            therapistName={props.data?.firstName.concat(' ', props.data?.lastName ?? '')}
                            patientsCount={props?.value}
                            week={filterRef.current.week}
                            onClickCallBack={() => {
                                onClickRef.current = false;
                            }}
                        />
                    );
                },
                rateLavevRender: (props: any) => {
                    if (!props.value) {
                        return <span>Unknown</span>;
                    }

                    return <span className={DisplayValueHelper.numberToString(props.value)}>{props.value}</span>;
                },
                therapistRequestStatusRender: (props: any) => {
                    return <span>{props.value || ADD}</span>;
                },
            },
            rowSelection: 'multiple',
            rowMultiSelectWithClick: true,
        }),
        [extendGridOptions, handleRowSelected, updateSearchZipFilter, updateAssignmentStatusFilter]
    );

    return (
        <>
            <div className="main-info-wrapper ag-table-wrapper available-therapist-table">
                <HeaderWrapper>
                    <HeaderLeftSide>
                        <SearchFilterInput title={serachTitle} value={search} onInput={handleSearchInputChange} />
                        <SearchFilterInput
                            searchtitle=""
                            title={'Zip Code'}
                            value={searchZip}
                            onInput={handleSearchZipInputChange}
                            className="zip-code-assigned"
                            iconClass="icon-map"
                            maxLength={5}
                        />
                        <AvailableTherapistCustomFilter
                            config={filterConfig}
                            departments={departments}
                            filters={filter}
                            onFiltersChange={handleFiltersChange}
                        />
                    </HeaderLeftSide>
                    <HeaderRightSide>
                        <ToggleViewMode activeMode={DisplayMode.List} onMapClick={handleMapClick} onListClick={null} />
                        <ExportButton title="Available therapists" paramsWatcher={paramsWatcher} gridRef={gridRef} />
                        <ClearFiltersButton
                            title="Availability"
                            gridApi={gridApi}
                            gridColumnApi={gridColumnApi}
                            onClick={handleFiltersClear}
                        />
                    </HeaderRightSide>
                </HeaderWrapper>
                <AgTable
                    clientSide
                    clientOptions={{ rowData: rows }}
                    gridRef={gridRef}
                    onGridReady={handleGridReady}
                    gridOptions={gridOptions}
                    repository={tableRepository}
                    customFilters={filter}
                    paramsWatcher={paramsWatcher}
                    disableShadow={true}
                />
            </div>
        </>
    );
};

export const AvailableTherapistColumnDefs = [
    {
        field: 'firstName',
        headerName: 'First Name',
        width: 350,
    },
    {
        field: 'lastName',
        headerName: 'Last Name',
        width: 350,
    },
    {
        field: 'disciplines',
        headerName: 'Discipline',
        width: 140,
        filter: 'agCustomFilter',
        filterParams: {
            isNullable: true,
        },
    },
    {
        field: 'scheduled',
        headerName: 'Scheduled',
        width: 150,
    },
    {
        field: 'available',
        headerName: 'Available',
        width: 150,
    },
    {
        field: 'rateLevel',
        headerName: 'Rate Level',
        initialSort: 'asc',
        width: 150,
        cellRenderer: 'rateLavevRender',
        cellClass: 'rateLavev',
    },
    {
        field: 'assignmentStatus',
        headerName: 'Assignment Status',
        cellRenderer: 'isActiveRender',
        width: 200,
        sortable: false,
        cellClass: 'status-active',
    },
    {
        field: 'status',
        headerName: 'Employment Status',
        width: 220,
        sortable: false,
        filter: 'agCustomFilter',
        filterParams: {
            isNullable: false,
            values: () =>
                Object.entries(TherapistStatus)
                    .filter((key) => key[1].value !== 'Unknown')
                    .map((key) => key[1].label),
        },
    },
    {
        field: 'patientCount',
        headerName: 'Patient Count',
        cellRenderer: 'patientsInZipRender',
        width: 153,
    },
    {
        headerName: '',
        field: THERAPIST_REQUEST_STATUS,
        cellClass: 'therapist-status',
        cellRenderer: 'therapistRequestStatusRender',
        pinned: 'right',
        width: 94,
        hideForExport: true,
    },
    {
        headerName: '',
        colId: 'therapistLink',
        pinned: 'right',
        cellRenderer: 'therapistLink',
        width: 40,
        hideForExport: true,
        cellClass: 'therapistLink',
    },
    {
        field: 'postalCodes',
        hideForExport: true,
        hide: true,
        filter: 'agCustomFilter',
    },
];

export const AvailableTherapistForSchoolRequestColumnDefs = [
    {
        field: 'firstName',
        headerName: 'First Name',
        width: 350,
    },
    {
        field: 'lastName',
        headerName: 'Last Name',
        width: 350,
    },
    {
        field: 'disciplines',
        headerName: 'Discipline',
        width: 140,
        filter: 'agCustomFilter',
        filterParams: {
            isNullable: true,
        },
    },
    {
        field: 'assignmentStatus',
        headerName: 'Assignment Status',
        cellRenderer: 'isActiveRender',
        width: 200,
        sortable: false,
        cellClass: 'status-active',
    },
    {
        field: 'status',
        headerName: 'Employment Status',
        width: 220,
        sortable: false,
        filter: 'agCustomFilter',
        filterParams: {
            isNullable: false,
            values: () =>
                Object.entries(TherapistStatus)
                    .filter((key) => key[1].value !== 'Unknown')
                    .map((key) => key[1].label),
        },
    },
    {
        headerName: '',
        field: THERAPIST_REQUEST_STATUS,
        cellClass: 'therapist-status',
        cellRenderer: 'therapistRequestStatusRender',
        pinned: 'right',
        width: 94,
        hideForExport: true,
    },
    {
        headerName: '',
        colId: 'therapistLink',
        pinned: 'right',
        cellRenderer: 'therapistLink',
        width: 40,
        hideForExport: true,
        cellClass: 'therapistLink',
    },
    {
        field: 'postalCodes',
        hideForExport: true,
        hide: true,
        filter: 'agCustomFilter',
    },
];

export default React.memo(AvailableTherapistTable);
