import { IIconMap } from 'common/components/map/map-wrapper/types';
import { homeIcon, preferencesIcon, requestIcon, workplaceIcon } from '../svg-icons';
import { DisplayAddressType } from 'common/services/api/map/types';

export const iconMap: IIconMap = {
    [DisplayAddressType.Home]: { icon: homeIcon, color: '#af52de', name: 'Provider Home' },
    [DisplayAddressType.Preference]: { icon: preferencesIcon.replace('5856D6', '14a501'), color: '#14a501' },
    [DisplayAddressType.Request]: { color: '#803AF7', name: 'My Search', manualHideOnMap: true }, //icon: preferencesIcon.replace('5856D6', '78909c'), #808080
    [DisplayAddressType.Cluster]: { color: '#d30606', name: 'Multiple Options', manualHideOnMap: true },
    [DisplayAddressType.ClusterSearch]: {
        color: '#d35b06',
        name: 'My Search +',
        strokeWeight: 2.5,
        manualHideOnMap: true,
    },

    [DisplayAddressType.HcMedAActivePlacement]: {
        icon: workplaceIcon.replace('919191', '33a695'),
        color: '#33a695',
        group: 'Homecare Med-A',
        name: 'Placement',
    },
    [DisplayAddressType.HcMedARequest]: {
        icon: requestIcon.replace('ED5285', 'ED5285'), //example how you can change color of svg icon
        color: '#ED5285',
        group: 'Homecare Med-A',
        name: 'Requests',
    },
    [DisplayAddressType.HcMedBActivePlacement]: {
        icon: workplaceIcon.replace('919191', '783c00'),
        color: '#783c00',
        group: 'Homecare Med-B',
        name: 'Placement',
    },

    [DisplayAddressType.SchoolActivePlacement]: {
        icon: workplaceIcon.replace('919191', '2f39fb'),
        color: '#2f39fb',
        group: 'School',
        name: 'Placement',
    },
    [DisplayAddressType.FacilityActivePlacement]: {
        icon: workplaceIcon.replace('919191', '2f3d7c'),
        color: '#2f3d7c',
        group: 'Nursing Home',
        name: 'Placement',
    },
    [DisplayAddressType.FacilityRequest]: {
        icon: requestIcon.replace('ED5285', '03a8a0'), //example how you can change color of svg icon
        color: '#03a8a0',
        group: 'Nursing Home',
        name: 'Requests',
    },
    [DisplayAddressType.HcMedBRequest]: {
        icon: requestIcon.replace('ED5285', '191919'),
        color: '#191919',
        group: 'Homecare Med-B',
        name: 'Requests',
    },
    // [DisplayAddressType.SchoolsRequest]: { color: '#fcb468' },
};

export const defaultIconArray = Object.keys(iconMap).map((key) => {
    return {
        value: key,
        name: key,
        ...iconMap[key],
    };
});

defaultIconArray.sort(function (a, b) {
    return a.group?.localeCompare(b.group) || a.name.toLowerCase().localeCompare(b.name.toLowerCase());
});

export const homeCareIconArray = defaultIconArray.map((value) => {
    return {
        ...value,
        disabled: value?.group?.includes('Nursing Home'),
    };
});

export const nursingHomeIconArray = defaultIconArray.map((value) => {
    return {
        ...value,
        disabled: value?.group?.includes('Homecare'),
    };
});

export const schoolIconArray = defaultIconArray.map((value) => {
    return {
        ...value,
        disabled: value?.group?.includes('School'),
    };
});
