import React from 'react';
import { CreateStaffingAgencyFormProps } from './types';
import RightSideBar from 'common/components/right-side-bar/right-side-bar';
import FocusTrap from 'focus-trap-react';

const CreateStaffingAgencyForm: React.FC<CreateStaffingAgencyFormProps> = ({ onSave, onCancel }) => {
    return (
        <FocusTrap focusTrapOptions={{ allowOutsideClick: true }}>
            <RightSideBar title="Create Staffing Agency" onCancel={onCancel} onSave={onSave}></RightSideBar>
        </FocusTrap>
    );
};

export default CreateStaffingAgencyForm;
