import { SET_PATIENTS, IPatientState, PatientStateActionTypes } from './types';
import { Reducer } from '@reduxjs/toolkit';

const initialState: IPatientState = {
    data: [],
};

export const patientsReducer: Reducer<IPatientState, PatientStateActionTypes> = (state = initialState, action) => {
    switch (action.type) {
        case SET_PATIENTS:
            return { ...state, data: action.patients };

        default:
            return state;
    }
};
