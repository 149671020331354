import { ErrorCodes } from 'common/constants/error-codes';
import { useHasPermission } from 'common/hooks/use-has-permission';
import { PermissionType } from 'common/models/permission-type';
import { ICreateUpdateUserRequest, IUser, UserStatus } from 'common/services/api/user/types';
import userService from 'common/services/api/user/user-service';
import React, { useCallback, useRef, useState } from 'react';
import { trackPromise } from 'react-promise-tracker';
import { toast } from 'react-toastify';
import AddEditUserSidebar from './components/add-edit-user-sidebar/add-edit-user-sidebar';
import UsersList from './components/users-list/users-list';
import { UsersPageProps } from './types';
import Tab from 'common/components/tabs/tab';
import Tabs from 'common/components/tabs/tabs';
import PageHeader from 'common/components/page-header/page-header';
import UsersExternalList from './components/users-external-list/users-external-list';
import { GridReadyEvent } from 'ag-grid-community';

const TOAST_ID = 'TOAST_USERS_FORBIDDEN';
const INTERNAL_USER = 'Internal Users';
const EXTERNAL_USER = 'External Users';

const UsersPage: React.FC<UsersPageProps> = ({ hasAccess }) => {
    if (hasAccess !== null && !hasAccess && !toast.isActive(TOAST_ID)) {
        toast.error('Access Denied', { toastId: TOAST_ID });
    }

    const hasCanViewInternalUsersPermission = useHasPermission(PermissionType.CanViewInternalUsers);
    const hasCanUpdateInternalUsersPermission = useHasPermission(PermissionType.CanUpdateInternalUsers);
    const hasCanViewExternalUsersPermission = useHasPermission(PermissionType.CanViewExternalUsers);
    const hasCanUpdateExternalUsersPermission = useHasPermission(PermissionType.CanUpdateExternalUsers);

    const [isAddEditDialogOpen, toggleAddEditDialog] = useState(false);
    const [isAddEditExternalDialogOpen, toggleAddEditExternalDialog] = useState(false);
    const [currentEditUser, setCurrentEditUser] = useState<IUser | null>(null);

    const handleClickOnUser = (user: IUser) => {
        setCurrentEditUser(user);
        openDialog();
    };

    const handleClickOnResetPassword = (user: IUser) => {
        return trackPromise(
            userService
                .sendResetPassword({ email: user.email })
                .then(() => toast.success('Reset password link has been successfully sent'))
                .catch(handleError)
        );
    };

    const handleClickOnResetExternalUser = (user: IUser) => {
        // if external user already exists
        if (user.status !== null) {
            handleClickOnResetPassword(user);
        } else {
            trackPromise(userService.createExternal(user.id, { lockoutEnabled: false }).then(() => handleSaveExternalUserSuccess()));
        }
    };

    const handleError = (error: any) => {
        const status = error?.response?.status;

        if (!status) {
            return;
        }

        if (status === 404) {
            toast.error('User not found');

            return;
        }

        const errorCode = error?.response?.data?.errorCode;

        if (errorCode === ErrorCodes.INVALID_THERAPIST_STATUS) {
            toast.error('Provider status is invalid');
        } else {
            toast.error(error?.response?.data?.errorMessage || 'Some error occurred');
        }
    };

    const handleClickOnSave = (data: ICreateUpdateUserRequest) => {
        if (currentEditUser) {
            return userService.update(currentEditUser.id, data).then(handleOnSuccess);
        } else {
            return userService.create(data).then(handleOnSuccess);
        }
    };

    const internalGridAPI = useRef(null);
    const externalGridAPI = useRef(null);

    const handleGridInternalReady = (params: GridReadyEvent) => {
        internalGridAPI.current = params.api;
    };

    const handleGridExternalReady = (params: GridReadyEvent) => {
        externalGridAPI.current = params.api;
    };

    const openDialog = () => {
        toggleAddEditDialog(true);
    };

    const closeDialog = () => {
        toggleAddEditDialog(false);
        setCurrentEditUser(null);
    };

    const handleOnSuccess = () => {
        if (internalGridAPI.current) {
            internalGridAPI.current.onFilterChanged();
        }

        closeDialog();
    };

    const handleClickOnExternalUser = (user: IUser) => {
        setCurrentEditUser({ ...user, lockoutEnabled: user.status === UserStatus.Inactive });
        toggleAddEditExternalDialog(true);
    };

    const handleSaveExternalUserSuccess = () => {
        toggleAddEditExternalDialog(false);
        if (externalGridAPI.current) {
            externalGridAPI.current.onFilterChanged();
        }
    };

    const handleClickOnExternalSave = (data: ICreateUpdateUserRequest) => {
        if (currentEditUser?.status !== null) {
            return userService.updateExternal(currentEditUser.id, data).then(handleSaveExternalUserSuccess);
        } else {
            return userService.createExternal(currentEditUser.id, data).then(handleSaveExternalUserSuccess);
        }
    };

    const closeExternalDialog = () => {
        toggleAddEditExternalDialog(false);
        setCurrentEditUser(null);
    };

    const hasAccessToExternalTab = useCallback(() => {
        return hasCanViewExternalUsersPermission || hasCanUpdateExternalUsersPermission;
    }, [hasCanViewExternalUsersPermission, hasCanUpdateExternalUsersPermission]);

    const hasAccessToInternalTab = useCallback(() => {
        return hasCanViewInternalUsersPermission || hasCanUpdateInternalUsersPermission;
    }, [hasCanViewInternalUsersPermission, hasCanUpdateInternalUsersPermission]);

    return hasAccess ? (
        <div className="table-page">
            <PageHeader title="Site Users" />
            <Tabs orientation="horizontal">
                {hasAccessToInternalTab() && (
                    <Tab linkClassName={'custom-link'} text={INTERNAL_USER}>
                        <UsersList
                            onEditUserClick={handleClickOnUser}
                            onGridReady={handleGridInternalReady}
                            onNewUserClick={openDialog}
                            onResetPasswordClick={handleClickOnResetPassword}
                        />
                    </Tab>
                )}

                {hasAccessToExternalTab() && (
                    <Tab linkClassName={'custom-link'} text={EXTERNAL_USER}>
                        <UsersExternalList
                            onEditUserClick={handleClickOnExternalUser}
                            onGridReady={handleGridExternalReady}
                            onResetPasswordClick={handleClickOnResetExternalUser}
                        />
                    </Tab>
                )}
            </Tabs>
            {hasCanUpdateInternalUsersPermission && isAddEditDialogOpen && (
                <AddEditUserSidebar data={currentEditUser} onClose={closeDialog} onSave={handleClickOnSave} />
            )}
            {hasCanUpdateExternalUsersPermission && isAddEditExternalDialogOpen && (
                <AddEditUserSidebar
                    data={currentEditUser}
                    onClose={closeExternalDialog}
                    onSave={handleClickOnExternalSave}
                    options={{ isExternal: true, isExternalCreate: currentEditUser.status === null }}
                />
            )}
        </div>
    ) : (
        <></>
    );
};

export default React.memo(UsersPage);
