import { Reducer } from '@reduxjs/toolkit';
import { StateActionTypes, IStateState } from './types';

const initialState: IStateState = {
    data: undefined,
};

export const stateReducer: Reducer<IStateState, StateActionTypes> = (state = initialState, action) => {
    switch (action.type) {
        case 'SET_STATES':
            return { ...state, data: state.data };
        default:
            return state;
    }
};
