import React from 'react';
import BaseCustomInput from 'common/components/BaseCustomInput/BaseCustomInput';
import { SearchFilterInputProps } from '../type';

const SearchFilterInput = ({
    value,
    title,
    onInput,
    onClearClick,
    iconClass = 'icon-search',
    className,
    maxLength,
    searchtitle = 'Search',
}: SearchFilterInputProps) => {
    const handleChangeInput = (event: React.ChangeEvent<HTMLInputElement> | React.ChangeEvent<HTMLTextAreaElement>) => {
        onInput(event.target.value);
    };

    return (
        <BaseCustomInput
            inputId={`${title}MainFilterInput`}
            labelId={`${title}MainFilterLabel`}
            className={`${className ?? ''} search-filter-inner`}
            inputName="headerSearchInput"
            onClearClick={onClearClick}
            iconClass={iconClass}
            inputType="text"
            fieldType="input"
            placeholderText={`${searchtitle} ${title}`}
            inputValue={value}
            isWithIcon={true}
            onInputChange={handleChangeInput}
            maxLength={maxLength}
        />
    );
};

export default React.memo(SearchFilterInput);
