import { FilterHelper } from '../filter-helper';
import { IMSLoginParams, IMSLoginResponse } from './types';
import { InfiniteServerClient } from '../http-clients';
class MSAuth {
    defaultParams: IMSLoginParams;
    authorizationData: IMSLoginResponse;

    constructor(params?: IMSLoginParams) {
        this.defaultParams = {
            response_type: 'code',
            response_mode: 'query',
            redirect_uri: window.location.origin + '/ms-auth-callback.html',
            ...params,
        };
    }

    sendCode(code?: string) {
        return InfiniteServerClient.post(`auth/providers/ms-graph`, { code: code ?? this.authorizationData?.code }).then(
            (response) => {
                return response.data;
            },
            (error) => {
                throw error;
            }
        );
    }

    login(params?: IMSLoginParams) {
        return new Promise<IMSLoginResponse>((resolve, reject) => {
            try {
                const urlParams = {
                    ...this.defaultParams,
                    ...params,
                };

                console.log('urlParams===========================');
                console.log(urlParams);
                console.log('====================================');

                window.open(
                    encodeURI(
                        'https://login.microsoftonline.com/organizations/oauth2/v2.0/authorize?' +
                            FilterHelper.toURLQueryParams(urlParams).join('&')
                    ),
                    '_blank',
                    'location=yes,height=870,width=820,scrollbars=yes,status=yes'
                );

                const handleMassage = (event: any) => {
                    if (event?.data.type === 'msAuthLoginSuccess') {
                        window.removeEventListener('message', handleMassage);
                        this.authorizationData = event.data;
                        resolve(event.data);
                    }
                };

                window.addEventListener('message', handleMassage, false);
            } catch (e) {
                reject(e);
            }
        });
    }

    async loginPopup(params?: IMSLoginParams) {
        await this.login(params);
        await this.sendCode();

        return Promise.resolve(this.authorizationData);
    }
}

export const msAuth = new MSAuth();
export default MSAuth;
