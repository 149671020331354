import AssignToDropdown from 'common/components/assign-to-dropdown/assign-to-dropdown';
import Chip from 'common/components/chip/chip';
import DropdownOption from 'common/models/dropdown-option';
import React from 'react';
import { LeadHeaderProps } from './types';

import './_styles.scss';

const LeadHeader: React.FC<LeadHeaderProps> = ({ lead, onAssignSelect }) => {
    const handleChangeAssignTo = (option: DropdownOption) => {
        onAssignSelect(option.value);
    };

    return (
        <div className="lead-header-info">
            <div className="lead-general-info">
                <div className="bio">
                    <div className="personal-data">
                        <div className="fullname">
                            {lead.firstName} {lead.lastName}
                        </div>
                        <div className="note">{lead.disciplines.join(', ')}</div>
                    </div>
                </div>
                <div className="contact-info">
                    <div className="contact-info-wrapper">
                        {lead.phoneNumber && <Chip iconClass="icon-phone" content={lead.phoneNumber} isPhoneNumber />}
                        {lead.email && <Chip iconClass="icon-email" content={lead.email} />}
                    </div>
                    <div className="general-info-actions-1">
                        <div className="general-info-actions-wrapp">
                            <AssignToDropdown defaultValue={lead.assignedTo} onChange={handleChangeAssignTo} />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default React.memo(LeadHeader);
