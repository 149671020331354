import { InfiniteServerClient } from 'common/helpers/http-clients';
import { INursingHomeEntity } from './types';

export class NursingHomeEntityService {
    get(legalEntityId: number): Promise<INursingHomeEntity> {
        return InfiniteServerClient.get<INursingHomeEntity>(`/nursing-home/entities/${legalEntityId}`).then(
            (response) => {
                return response.data;
            },
            (error) => {
                throw error;
            }
        );
    }

    setOvertimeAgreement(entityId: number, overtimeAgreement: boolean): Promise<INursingHomeEntity> {
        return InfiniteServerClient.put<INursingHomeEntity>(`/nursing-home/entities/${entityId}/overtime-agreement`, {
            overtimeAgreement,
        }).then(
            (response) => {
                return response.data;
            },
            (error) => {
                throw error;
            }
        );
    }
}

export default new NursingHomeEntityService();
