import React from 'react';
import './card.scss';
import { CardWrapperProps } from './types';

const CardWrapper: React.FC<CardWrapperProps> = ({ children, className = '', refs, ...props }) => {
    return (
        <div ref={refs ?? null} className={`card-wrapper ${className}`} {...props}>
            {children}
        </div>
    );
};

export default React.memo(CardWrapper);
