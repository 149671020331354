import React, { useMemo, useState } from 'react';
import { PatientBasicInfoTabProp } from '../../types';
import { Card, CardWrapper, getPhonesCardConfig, getEmailsCardConfig } from 'common/components/card';
import { ICardConfig } from 'common/components/card/types';
import './patient-basic-info-tab.scss';
import UpdateAvatarSection from 'common/components/update-avatar-section/update-avatar-section';
import { fetchPatientDetails, updatePatientProfilePhotoRequest } from 'app/store/patients-details/action-creators';
import { dateTimeConstants } from 'common/constants/common';
import PatientBasicInfoEditForm from './patient-basic-info-edit-form';
import PatientService from 'common/services/api/patient/patient-service';
import { IGender, IPatientBasicInfo } from 'common/services/api/patient/types';
import { trackPromise } from 'react-promise-tracker';
import { useDispatch } from 'react-redux';
import { ThunkDispatch } from 'redux-thunk';
import { Action } from '@reduxjs/toolkit';
import moment from 'moment';
import { SequenceHelper } from 'common/helpers/sequence-helper';
import { CommunicationHelper } from 'common/helpers/communication-helper';
import { labels } from 'common/constants/labels';
import { getPhysicianCardConfig } from 'common/components/card/card-helper';
import { IAttachPhysicianModel, IPhysiciansViewModel } from 'common/services/api/physicians/types';
import { hasAddress } from 'common/helpers/address-helper';

const PatientBasicInfoTab: React.FC<PatientBasicInfoTabProp> = ({ patientId, patientDetails }) => {
    const [isEditDialogOpen, toggleEditDialog] = useState(false);
    const dispatch = useDispatch<ThunkDispatch<any, any, Action>>();

    const rightPatientDetailsConfig: ICardConfig[] = [
        ...getPhonesCardConfig(patientDetails.phones),
        ...getEmailsCardConfig(patientDetails.emails),
        ...rightBottomPatientDetailsConfig,
    ];

    const leftPatientDetailsConfig: ICardConfig[] = [
        ...leftPatientDetailsConfigDefault,
        ...getPhysicianCardConfig(patientDetails.physicians),
    ];

    const handleAvatarChange = (file: File) => {
        return updatePatientProfilePhotoRequest(patientId, file);
    };

    // Edit
    const handleClickOnEdit = () => {
        toggleEditDialog(true);
    };

    const handleClickSaveButton = (basicInfo: IPatientBasicInfo) => {
        const requestBasicInfo = { ...basicInfo };

        const basicInfoValidCommunicationInfo = CommunicationHelper.Validate({
            phones: basicInfo.phones,
            isPhonesRequiered: true,
            emails: basicInfo.emails,
            isEmailsRequiered: false,
        });

        const physicians: IAttachPhysicianModel[] = basicInfo.physicians
            .map((physician: IPhysiciansViewModel) => {
                return {
                    id: physician?.id,
                    physicianId: physician?.physician?.id,
                    practiceId: physician?.practice?.id,
                };
            })
            .filter((physician: IAttachPhysicianModel) => physician?.physicianId);

        requestBasicInfo.phones = basicInfoValidCommunicationInfo.phones;
        requestBasicInfo.emails = basicInfoValidCommunicationInfo.emails;

        SequenceHelper.setSequenceItems(requestBasicInfo?.emails);
        SequenceHelper.setSequenceItems(requestBasicInfo?.phones);
        SequenceHelper.setSequenceItems(physicians);

        return trackPromise(
            PatientService.editBasicInfo({ ...requestBasicInfo, physicians }).then(async () => {
                await trackPromise(dispatch(fetchPatientDetails(patientId)));
                toggleEditDialog(false);
            })
        );
    };

    const handleClickCancelButton = () => {
        toggleEditDialog(false);
    };

    const addressDetails = useMemo(() => {
        if (hasAddress(patientDetails)) {
            return patientDetails;
        }

        const facility = patientDetails?.facility;

        return {
            ...patientDetails,
            ...facility?.address,
            county: facility.county,
            zip: facility?.address?.postalCode,
        };
    }, [patientDetails]);

    return (
        <div className="patient-basic-info">
            <button className="base-text-btn white patient-basic-info__btn-edit" onClick={handleClickOnEdit}>
                <i className="icon-edit icon" />
                Edit
            </button>
            <CardWrapper>
                <Card
                    defaultValue="-"
                    className="patients-card patients-card-left"
                    config={leftPatientDetailsConfig}
                    data={patientDetails}
                />
                <CardWrapper className="card-wrapper--vertical card-wrapper--enrollment">
                    <Card
                        defaultValue="-"
                        className="patients-card patients-card-right"
                        config={rightPatientDetailsConfig}
                        data={addressDetails}
                    />
                </CardWrapper>
            </CardWrapper>
            <UpdateAvatarSection handleFileChange={handleAvatarChange} currentImageData={patientDetails.profilePhoto} />
            {isEditDialogOpen && (
                <PatientBasicInfoEditForm patient={patientDetails} onSave={handleClickSaveButton} onCancel={handleClickCancelButton} />
            )}
        </div>
    );
};

const leftPatientDetailsConfigDefault: ICardConfig[] = [
    { title: 'Patient ID', field: 'patientAgencyCode', width: '100%' },
    { title: 'First name', field: 'firstName', width: '100%' },
    { title: labels.middleNameInitial, field: 'middleName', width: '100%' },
    { title: 'Last Name', field: 'lastName', width: '100%' },
    { title: 'DOB', field: 'dateOfBirth', width: '100%', render: (date) => moment(date).utc().format(dateTimeConstants.MM_DD_YYYY) },
    { title: 'Birth Gender', field: 'gender', width: '100%', render: (gender: IGender) => gender?.name || '-' },
    { title: 'Age', field: 'age', width: '100%', render: (age) => (age > 0 ? age : '-') },
    { title: 'Customer', field: 'customer', width: '100%', render: (customer) => customer.name },
    { title: 'Facility', field: 'facility', width: '100%', render: (facility) => facility.name },
    { title: 'Population', field: 'population', width: '100%', render: (population) => population.name },
];

const rightBottomPatientDetailsConfig: ICardConfig[] = [
    { title: 'Address 1', field: 'address1', width: '100%' },
    { title: 'Address 2', field: 'address2', width: '100%' },
    { title: 'City', field: 'city', width: '50%' },
    { title: 'State', field: 'state', width: '50%', render: (state) => state?.name },
    { title: 'County', field: 'county', width: '50%', render: (county) => county?.name },
    { title: 'Zip', field: 'zip', width: '50%' },
    { title: 'Special instructions', field: 'specialInstructions', width: '50%' },
];

export default PatientBasicInfoTab;
