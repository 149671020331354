import React from 'react';
import FieldList from './field-list';
import FieldSet from '../field-set/field-set';
import InputField from '../field/input-field';
import { FieldListWrapperProps } from './types';
import { FieldValue } from '../../models/field-value';

const FieldListEmails = ({
    data: emails,
    errors,
    onChange,
    name = 'emails',
    isRequired = true,
    tabIndex,
    className,
}: FieldListWrapperProps) => {
    return (
        <FieldList
            name={name}
            isDragNDrop
            values={emails}
            defaultValue={{ email: '' }}
            addButtonText="Add Email Address"
            onChange={(values: any[]) => {
                onChange({ name: name, value: values });
            }}
            renderInput={(value: any, index: number) => (
                <FieldSet
                    name={name}
                    errors={errors}
                    customClass={`edit-field email ${index === 0 ? 'main-item' : ''} ${isRequired && 'required-field-star'} ${
                        className ?? ''
                    }`}
                    renderError={(error: any) => error[index]?.email && error[index]?.email[0]}
                >
                    <InputField
                        placeholder="Email Address"
                        onChange={(data: FieldValue) => {
                            emails[index] = { ...emails[index], email: data.value };
                            onChange({ name: name, value: [...emails] });
                        }}
                        value={value.email}
                        tabIndex={tabIndex}
                    />
                </FieldSet>
            )}
        />
    );
};

export default FieldListEmails;
