import googleGeocodingService from 'common/services/google-geocoding-service';
import React, { useCallback, useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import useTherapistMap from 'common/hooks/use-therapist-map/use-therapist-map';
import MapTopFilter from 'common/components/map/map-top-filter/map-top-filter';
import { MapWrapper } from 'common/components/map/map-wrapper/map-wrapper';
import { MapInfoWindow } from 'common/components/map/map-info-window/map-info-window';
import { ResultCounter } from 'common/components/map/result-counter/result-counter';
import DataPagination from 'common/components/data-pagination/data-pagination';
import './_styles.scss';
import MapCluster from 'common/components/map/map-cluster/map-cluster';
import { DisplayAddressType, MapType, IMapPointResponse, IMapPoint } from 'common/services/api/map/types';
import mapService from 'common/services/api/map/map-service';
import { PlacementInfo } from 'common/components/map/map-info-window/placement-info/placement-info';
import { TherapistMapFilterWrapper } from 'common/components/map/therapist-map-filter/therapist-map-filter-wrapper';
import { RequestType } from 'common/constants/types';
import ToggleViewMode from 'common/components/toggle-view-mode/toggle-view-mode';
import { DisplayMode } from 'common/components/toggle-view-mode/types';
import TherapistAvailabilityMapFilter from './therapist-availability-map-filter';
import { AvailableTherapistMapDialogProps, IAvailableTherapistMapFilter } from './types';
import { isRequestData, isTherapistData, toMapPoint } from 'common/helpers/map-helper';
import AvailableTherapistInfo from './available-therapist-info';
import RequestInfo from 'common/components/map/map-info-window/request-info/request-info';

const AvailableTherapistMapDialog: React.FC<AvailableTherapistMapDialogProps> = ({
    zip,
    addressDetails,
    filter,
    onListClick,
    iconArray,
    onSelectedTherapistsChange,
    request,
}) => {
    const [windowInfo, setWindowInfoData] = useState<IMapPoint | null>();
    const [selectedTherapistIds, setSelectedTherapistIds] = useState<number[]>([]);

    const fetchFunction = useCallback(async (searchModel) => {
        const response: any = await mapService.searchInRadius(searchModel);
        const responsePlacements: any = await mapService.searchInRadiusPlacements(searchModel);

        return [...response, ...responsePlacements];
    }, []);

    const [
        result,
        searchModel,
        handleCenterChange,
        handleMilesChange,
        handleDisplayTypeChange,
        handleTherapistFilterChange,
        handleExport,
        handleClearFilters,
    ] = useTherapistMap({
        fetchFunction: fetchFunction,
        exportFunction: mapService.exportTherapistsOnMap,
        mapType: MapType.Request,
        defaultFilter: filter,
    });

    useEffect(() => {
        const zipCode = addressDetails?.zip || zip;
        googleGeocodingService.geocode(addressDetails?.city ?? '', addressDetails?.address1 ?? '', zipCode).then(
            (position) => {
                handleCenterChange(position, zipCode);
            },
            (errorMessage) => toast.error(errorMessage)
        );
    }, [addressDetails, handleCenterChange, zip]);

    const handleClickOnMarker = (item: IMapPoint) => {
        setWindowInfoData(item);
    };

    const handleClickOnCloseWindow = () => {
        setWindowInfoData(null);
    };

    const handleAddClick = (id: number) => {
        if (request.therapists?.some((i: any) => i.id === id)) {
            return;
        }

        let result = [];

        if (selectedTherapistIds.some((i) => i === id)) {
            result = [...selectedTherapistIds.filter((i) => i !== id)];
        } else {
            const therapists = selectedTherapistIds;
            therapists.push(id);
            result = [...therapists];
        }

        setSelectedTherapistIds(result);
        onSelectedTherapistsChange(result);
    };

    const therapistFilter: IAvailableTherapistMapFilter = searchModel.filter as IAvailableTherapistMapFilter;

    const getRequestStatus = useCallback(
        (therapistId: number) => {
            if (!request) {
                return null;
            }

            const requestTherapist = request.therapists?.find((i: any) => i.id === therapistId);

            if (requestTherapist?.status) {
                return requestTherapist?.status;
            }

            return selectedTherapistIds.some((id) => id === therapistId) ? 'Added' : 'Add';
        },
        [request, selectedTherapistIds]
    );

    useEffect(() => {
        setWindowInfoData(null);
    }, [result]);

    const [getPoints, setPoints] = useState<IMapPoint[]>([]);
    const [hideMarker, setHideMarker] = useState<any>({});
    useEffect(() => {
        const markers = [];

        if (searchModel.center && !hideMarker.ClusterSearch && !hideMarker.Request) {
            const currentRequestPoint: IMapPointResponse = {
                latitude: searchModel.center.latitude,
                longitude: searchModel.center.longitude,
                id: -1,
                type: DisplayAddressType.Request,
            };
            markers.push(currentRequestPoint);
        }

        setPoints(toMapPoint([...markers, ...result])?.filter((point: any) => !hideMarker[point.type]));
    }, [hideMarker, result, searchModel.center]);

    const getInfo = (point: IMapPoint, handleClickBack: () => void) => {
        let result: any = [];

        result = point.data.map((item, i) => {
            if (isTherapistData(item)) {
                return (
                    <AvailableTherapistInfo
                        id={item.id}
                        key={i}
                        onAddClick={() => handleAddClick(item.id)}
                        requestStatus={getRequestStatus(item.id)}
                    />
                );
            }

            if (isRequestData(item)) {
                return (
                    <RequestInfo
                        id={item.id}
                        key={i}
                        index={i}
                        type={item.type?.toLowerCase()?.startsWith('hc') ? RequestType.HomeCare : RequestType.NursingHome}
                    />
                );
            } else {
                return <PlacementInfo placement={item.data} type={item.type} key={i} index={i} />;
            }
        });

        return <DataPagination onBack={handleClickBack}>{result}</DataPagination>;
    };

    const getAddress = () => {
        if (addressDetails) {
            const { address1, city, state } = addressDetails;
            if (!address1 && !city && !addressDetails?.zip && !zip) {
                return '';
            }

            return `${address1 ?? ''} ${city ?? ''} ${state?.name ?? ''} ${addressDetails?.zip ?? zip}`.trim();
        } else {
            return zip;
        }
    };

    return (
        <div className="therapists-on-map-page edit-therapist-form available-therapist-map map-fix">
            <MapTopFilter
                address={getAddress()}
                radius={searchModel.radius}
                onExportClick={handleExport}
                onCenterChange={handleCenterChange}
                onClearFilterClick={handleClearFilters}
                onRadiusChange={handleMilesChange}
            >
                <ToggleViewMode activeMode={DisplayMode.Map} onListClick={onListClick} onMapClick={null} />
            </MapTopFilter>
            <div className="map-wrapper">
                <TherapistMapFilterWrapper>
                    <TherapistAvailabilityMapFilter onChange={handleTherapistFilterChange} filter={therapistFilter} />
                </TherapistMapFilterWrapper>
                <MapWrapper
                    iconArray={iconArray}
                    points={getPoints}
                    center={searchModel.center}
                    radius={searchModel.radius}
                    onMarkerClick={handleClickOnMarker}
                    onDisplayTypeChange={handleDisplayTypeChange}
                    onManualDisplayTypeChange={setHideMarker}
                >
                    {windowInfo && (
                        <MapInfoWindow
                            onCloseClick={handleClickOnCloseWindow}
                            position={{ latitude: windowInfo.latitude, longitude: windowInfo.longitude }}
                        >
                            <MapCluster point={windowInfo} getType={getInfo} />
                        </MapInfoWindow>
                    )}
                </MapWrapper>
                <ResultCounter result={result} />
            </div>
        </div>
    );
};

export default React.memo(AvailableTherapistMapDialog);
