export type TherapistTableProps = {
    updateTable?: boolean;
    onMapClick: (filters: ITherapistCustomFilters, zip: string) => void;
};

export interface ITherapistCustomFilters {
    search: string;
    assignedDepartment: number[];
    assignedSubDepartment: number[];
    employmentStatus: string[];
    complianceStatus: string[];
    hiringDays?: number;
}

export const ExpiredDocumentReportType = 'expired_document';
