import React, { useState } from 'react';
import { TherapistMapFilterWrapperProps } from './types';

export const TherapistMapFilterWrapper = ({ children }: TherapistMapFilterWrapperProps) => {
    const [collapsed, setCollapsed] = useState(false);

    return (
        <div className={`map-therapist-filter ${collapsed ? 'collapsed' : ''}`}>
            <div className="map-therapist-filter-children">{children}</div>
            <div className={'collapsed-button'} onClick={() => setCollapsed((prev) => !prev)}>
                <div></div>
            </div>
        </div>
    );
};
