import React, { useCallback } from 'react';
import { IForm } from 'common/models/types';
import FieldSet from 'common/components/field-set/field-set';
import InputField from 'common/components/field/input-field';
import DropdownOption from 'common/models/dropdown-option';
import { IPhysiciansRow } from 'common/services/api/physicians/types';
import PaginationAutocomplete from 'common/components/field/pagination-autocomplete/pagination-autocomplete';
import { useTabIndex } from 'common/hooks/use-tab-index';
import { generalValidationRules } from 'common/validation/general-validation-rules';
import { IState } from 'common/services/api/state/types';
import { StateService } from 'common/services/api/state/state-service';
import NumberField from 'common/components/field/number-field';
import FieldListPhones from 'common/components/field-list/field-list-phones';
import FieldListEmails from 'common/components/field-list/fild-list-emails';
import { ConverterHelper } from 'common/helpers/converter-helper';
import physiciansService from 'common/services/api/physicians/physicians-service';
import { physicianPhoneTypes } from 'common/constants/patients/phone-types';
import baseService from 'common/services/api/base/base-service';

const AddEditPhysicianForm = ({ data: physician, errors, onChange, options }: IForm<IPhysiciansRow>) => {
    const { getTabIndex } = useTabIndex();

    const isAddressTouched = useCallback(() => {
        return physician.postalCode || physician.address1 || physician.city || physician.state?.id;
    }, [physician.address1, physician.city, physician.state, physician.postalCode]);

    const generateAddressClassName = useCallback(() => {
        return isAddressTouched() ? 'edit-physician-field required-field-star' : 'edit-physician-field';
    }, [isAddressTouched]);

    const generatePhoneClassName = useCallback(() => {
        return physician.practices?.length > 0 || physician.emails?.filter((item) => item.email)?.length > 0
            ? 'edit-physician-field'
            : 'edit-physician-field required-field-star';
    }, [physician.practices, physician.emails]);

    const generateEmailClassName = useCallback(() => {
        return physician.practices?.length > 0 || physician.phones?.filter((item) => item.number)?.length > 0
            ? 'edit-physician-field'
            : 'edit-physician-field required-field-star';
    }, [physician.practices, physician.phones]);

    const stateConvertFunction = (state: IState) => {
        return new DropdownOption(state.id, state.name);
    };

    return (
        <>
            <div className="edit-form outline-none" tabIndex={getTabIndex()}>
                <FieldSet errors={errors} name="firstName" customClass="edit-field required-field-star">
                    <InputField
                        id="firstName"
                        placeholder="First Name"
                        name="firstName"
                        value={physician?.firstName}
                        tabIndex={getTabIndex()}
                        onChange={onChange}
                    />
                </FieldSet>
                <FieldSet errors={errors} name="lastName" customClass="edit-field required-field-star">
                    <InputField
                        id="lastName"
                        placeholder="Last Name"
                        name="lastName"
                        value={physician?.lastName}
                        tabIndex={getTabIndex()}
                        onChange={onChange}
                    />
                </FieldSet>
                <FieldSet name="npiNumber" errors={errors} customClass="edit-field required-field-star">
                    <NumberField
                        id="npiNumber"
                        classic
                        maxLength={generalValidationRules.npiNumber.maxLength}
                        placeholder="NPI"
                        name="npiNumber"
                        tabIndex={getTabIndex()}
                        onChange={onChange}
                        value={physician.npiNumber}
                    />
                </FieldSet>
                <FieldSet name="licenseTypes" errors={errors} customClass="edit-therapist-field">
                    <PaginationAutocomplete
                        clientSide
                        fetchData={() => baseService.getOptions(null, null, null, 'LicenseType', null)}
                        convertFunction={ConverterHelper.typeOfLicenseConvert}
                        multiselect
                        id="licenseTypes"
                        defaultValue={physician?.licenseTypes?.map(ConverterHelper.typeOfLicenseConvert) ?? []}
                        placeholder="Type of License"
                        name="licenseTypes"
                        tabIndex={getTabIndex()}
                        onDelete={() => onChange({ name: 'licenseTypes', value: undefined })}
                        onSelect={(item) => {
                            onChange({
                                name: 'licenseTypes',
                                value: item.map((item: any) => {
                                    return { id: item?.value, name: item?.label };
                                }),
                            });
                        }}
                    />
                </FieldSet>
                <FieldSet name="specialty" errors={errors} customClass="edit-therapist-field">
                    <PaginationAutocomplete
                        clientSide
                        fetchData={() => baseService.getOptions(null, null, null, 'Speciality', null)}
                        convertFunction={ConverterHelper.specialtyConvert}
                        multiselect
                        id="specialty"
                        defaultValue={physician?.specialities?.map(ConverterHelper.specialtyConvert) ?? []}
                        placeholder="Specialty"
                        name="specialty"
                        tabIndex={getTabIndex()}
                        onDelete={() => onChange({ name: 'specialty', value: undefined })}
                        onSelect={(item) =>
                            onChange({
                                name: 'specialities',
                                value: item.map((item: any) => {
                                    return { id: item?.value, name: item?.label };
                                }),
                            })
                        }
                    />
                </FieldSet>
                {!options.entityId && (
                    <FieldSet name="practices" errors={errors} customClass="edit-therapist-field">
                        <PaginationAutocomplete
                            clientSide
                            fetchData={physiciansService.getDoctorOfficeOptions}
                            convertFunction={ConverterHelper.doctorOfficeConvert}
                            multiselect
                            id="practices"
                            defaultValue={physician?.practices?.map(ConverterHelper.doctorOfficeConvert) ?? []}
                            placeholder="Practice"
                            name="practices"
                            tabIndex={getTabIndex()}
                            onDelete={() => onChange({ name: 'practices', value: undefined })}
                            onSelect={(item) =>
                                onChange({
                                    name: 'practices',
                                    value: item.map((item: any) => {
                                        return { id: item?.value, name: item?.label };
                                    }),
                                })
                            }
                        />
                    </FieldSet>
                )}
                <FieldSet name="address1" errors={errors} customClass={generateAddressClassName()}>
                    <InputField
                        id="address1"
                        placeholder="Address 1"
                        name="address1"
                        onChange={onChange}
                        value={physician?.address1}
                        tabIndex={getTabIndex()}
                    />
                </FieldSet>
                <FieldSet name="address2" errors={errors} customClass="edit-therapist-field">
                    <InputField
                        id="address2"
                        placeholder="Address 2"
                        name="address2"
                        onChange={onChange}
                        value={physician?.address2}
                        tabIndex={getTabIndex()}
                    />
                </FieldSet>
                <FieldSet name="city" errors={errors} customClass={generateAddressClassName()}>
                    <InputField
                        id="city"
                        placeholder="City"
                        name="city"
                        maxLength={generalValidationRules.city.maxLength}
                        onChange={onChange}
                        value={physician?.city}
                        tabIndex={getTabIndex()}
                    />
                </FieldSet>
                <FieldSet name="stateId" errors={errors} customClass={`${generateAddressClassName()} state-autocomplete`}>
                    <PaginationAutocomplete
                        fetchData={new StateService().getAll}
                        convertFunction={stateConvertFunction}
                        id="state"
                        defaultValue={physician?.state?.name}
                        placeholder="State"
                        name="state"
                        tabIndex={getTabIndex()}
                        onDelete={() => onChange({ name: 'state', value: undefined })}
                        onSelect={(item) => onChange({ name: 'state', value: { id: item?.value, name: item?.label } })}
                    />
                </FieldSet>
                <FieldSet name="postalCode" errors={errors} customClass={generateAddressClassName()}>
                    <NumberField
                        id="zip"
                        classic
                        maxLength={generalValidationRules.zip.maxLength}
                        placeholder="Zip"
                        name="postalCode"
                        tabIndex={getTabIndex()}
                        onChange={onChange}
                        value={physician.postalCode}
                    />
                </FieldSet>

                <FieldListPhones
                    data={physician.phones}
                    errors={errors}
                    onChange={onChange}
                    phoneTypes={physicianPhoneTypes}
                    tabIndex={getTabIndex()}
                    className={generatePhoneClassName()}
                />

                <FieldListEmails
                    data={physician.emails}
                    errors={errors}
                    onChange={onChange}
                    isRequired={false}
                    tabIndex={getTabIndex()}
                    className={generateEmailClassName()}
                />
            </div>
        </>
    );
};

export default React.memo(AddEditPhysicianForm);
