import React from 'react';
import { ButtonToolbarProps, IButtonToolbarItem } from './types';
import PropTypes from 'prop-types';
import { useCallback } from 'react';
import './button-toolbar.scss';
const ButtonToolbar: React.FC<ButtonToolbarProps> = ({ currentId, buttons, className, onClick }) => {
    const renderButtonItem = useCallback(
        (item: IButtonToolbarItem, index: number) => {
            const className = `button-item ${currentId === item.value ? 'active' : ''} `;

            return (
                <button onClick={() => onClick(item.value)} key={index} className={className}>
                    {item.title}
                </button>
            );
        },
        [currentId, onClick]
    );

    return <div className={`button-toolbar ${className ? className : ''}`}>{buttons.map(renderButtonItem)}</div>;
};

ButtonToolbar.propTypes = {
    buttons: PropTypes.array.isRequired,
};

export default React.memo(ButtonToolbar);
