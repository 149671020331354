import React, { useState, useEffect } from 'react';
import { ValidationOptions } from 'settings.json';
import Field from './field';
import './input-field.scss';
import { getUniqueName } from '../../helpers/string-helper';
import { IField, IInputField, IInputFieldType } from './types';

const password = 'password';
const text = 'text';

const InputField = (props: IField & IInputField) => {
    const [isFocused, toggleFocus] = useState(false);
    const [value, setValue] = useState(props.value ?? '');
    const [type, setType] = useState<IInputFieldType>(props.type ?? text);

    useEffect(() => {
        setValue(props.value ?? '');
    }, [props.value]);

    const handleOnFocus = (e: React.ChangeEvent<HTMLInputElement>) => {
        toggleFocus(true);

        if (props.onFocus) {
            props.onFocus(e);
        }
    };

    const handleOnBlur = (e: React.ChangeEvent<HTMLInputElement>) => {
        toggleFocus(false);

        if (props.onBlur) {
            props.onBlur(e);
        }
    };

    const handleOnKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
        if (props.onKeyDown) {
            props.onKeyDown(e);
        }
    };

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const currentValue = event.target.value;

        if (props.allowOnlyDigits) {
            event.target.value = currentValue.replace(/[^0-9]*/g, '');
            if (currentValue !== event.target.value) {
                event.stopPropagation();

                return false;
            }
        }
        setValue(currentValue);
        if (props.onChange) props.onChange({ value: currentValue, name: props.name });
    };

    const handleClickViewPassword = () => {
        setType((prevType) => (prevType === text ? password : text));
    };

    return (
        <Field {...props} value={value} isFocused={isFocused} customClass={`infinite-input-field-outer ${props.className ?? ''}`}>
            <div className="infinite-input-field">
                <input
                    readOnly={props.readOnly}
                    disabled={props.disabled}
                    ref={props.inputRef}
                    maxLength={props.maxLength || ValidationOptions.InputMaxLength}
                    id={props.type === password ? props.id : props.id + getUniqueName('.')}
                    type={type || props.type || 'text'}
                    className={`${props.inputClass ?? ''} ${props.id ?? ''}`}
                    onKeyDown={handleOnKeyDown}
                    onFocus={handleOnFocus}
                    onBlur={handleOnBlur}
                    required={props.required}
                    onChange={handleChange}
                    value={props.stateless ? undefined : value}
                    tabIndex={props.tabIndex as number}
                    autoComplete={props.type === password ? 'on' : getUniqueName()}
                />
                {props.type === password && (
                    <span
                        className={`view-password icon icon-eye-${eyeToggleMap[type ?? password] as string}`}
                        onClick={handleClickViewPassword}
                    ></span>
                )}
            </div>
        </Field>
    );
};

const eyeToggleMap = {
    [password]: 'on',
    [text]: 'off',
};

export default InputField;
