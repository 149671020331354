import React, { useCallback, useState } from 'react';
import FieldSet from 'common/components/field-set/field-set';
import InputField from 'common/components/field/input-field';
import DropdownOption from 'common/models/dropdown-option';
import RightSideBar from 'common/components/right-side-bar/right-side-bar';
import PaginationAutocomplete from 'common/components/field/pagination-autocomplete/pagination-autocomplete';
import { ValidationHelper } from 'common/helpers/validation-helper';
import { ObjectHelper } from 'common/helpers/object-helper';
import { StateService } from 'common/services/api/state/state-service';
import { generalValidationRules } from 'common/validation/general-validation-rules';
import FieldList from 'common/components/field-list/field-list';
import PhoneTypeInput from 'common/components/phone-type-input/phone-type-input';
import { SequenceHelper } from 'common/helpers/sequence-helper';
import { FieldValue } from 'common/models/field-value';
import { ConverterHelper } from 'common/helpers/converter-helper';
import { ContactInfoEditFormProps } from './types';
import { IEditContactInfo } from 'common/services/api/therapist/types';

// TO-DO: Set tab indexes on the form
const ContactInfoEditForm: React.FC<ContactInfoEditFormProps> = ({ therapist, onSave, onCancel }) => {
    const [contactInfo, setContactInfo] = useState<IEditContactInfo>({
        address1: therapist.address1,
        address2: therapist.address2,
        zip: therapist.zip,
        stateId: therapist.state?.id,
        city: therapist.city,
        phones: therapist.phones.length === 0 ? [{ type: 'Home', number: null }] : ObjectHelper.deepCopy(therapist.phones),
        email: therapist.email,
        internalEmail: therapist.internalEmail,
        position: null,
    });

    const [contactErrorData, setErrorData] = useState<any>({});

    const handleFieldChange = (data: FieldValue) => {
        setContactInfo((prevInfo) => ({ ...(prevInfo as IEditContactInfo), [data.name]: data.value }));
        setErrorData((prevError: any) => ({ ...prevError, [data.name]: undefined }));
    };

    const isAddressTouched = useCallback(() => {
        return contactInfo.zip || contactInfo.address1 || contactInfo.city || contactInfo.stateId;
    }, [contactInfo.address1, contactInfo.city, contactInfo.stateId, contactInfo.zip]);

    const phones = [new DropdownOption('Home', 'Home'), new DropdownOption('CellPhone', 'Cell')];

    const handleSave = () => {
        SequenceHelper.setSequenceItems(contactInfo?.phones);

        onSave(contactInfo).catch((error: any) => {
            const errors = ValidationHelper.handleErrorResponse(error);
            setErrorData(errors);
        });
    };

    const generateAddressClassName = useCallback(() => {
        return isAddressTouched() ? 'edit-therapist-field required-field-star' : 'edit-therapist-field';
    }, [isAddressTouched]);

    return (
        <div>
            <RightSideBar title="Edit Contact Info" onCancel={onCancel} onSave={handleSave}>
                <div className="edit-therapist-form">
                    <FieldSet name="address1" errors={contactErrorData} customClass={generateAddressClassName()}>
                        <InputField
                            placeholder="Address 1"
                            name="address1"
                            onChange={handleFieldChange}
                            value={contactInfo.address1}
                        ></InputField>
                    </FieldSet>
                    <FieldSet name="address2" errors={contactErrorData} customClass="edit-therapist-field">
                        <InputField
                            placeholder="Address 2"
                            name="address2"
                            onChange={handleFieldChange}
                            value={contactInfo.address2}
                        ></InputField>
                    </FieldSet>
                    <FieldSet name="city" errors={contactErrorData} customClass={generateAddressClassName()}>
                        <InputField
                            placeholder="City"
                            name="city"
                            maxLength={generalValidationRules.city.maxLength}
                            onChange={handleFieldChange}
                            value={contactInfo.city}
                        ></InputField>
                    </FieldSet>
                    <FieldSet name="stateId" errors={contactErrorData} customClass={`${generateAddressClassName()} state-autocomplete`}>
                        <PaginationAutocomplete
                            fetchData={new StateService().getAll}
                            convertFunction={ConverterHelper.stateConvert}
                            id="state"
                            defaultValue={therapist.state?.name}
                            placeholder="State"
                            name="stateId"
                            onDelete={() => handleFieldChange({ name: 'stateId', value: undefined })}
                            onSelect={(item) => handleFieldChange({ name: 'stateId', value: item.value })}
                            tabIndex={null}
                        ></PaginationAutocomplete>
                    </FieldSet>
                    <FieldSet name="zip" errors={contactErrorData} customClass={generateAddressClassName()}>
                        <InputField
                            id="zip"
                            allowOnlyDigits={true}
                            placeholder="Zip"
                            name="zip"
                            maxLength={generalValidationRules.zip.maxLength}
                            onChange={handleFieldChange}
                            value={contactInfo.zip}
                        />
                    </FieldSet>
                    <FieldList
                        name="phones"
                        isDragNDrop
                        values={[...contactInfo.phones]}
                        defaultValue={{ type: phones[0].value, number: '' }}
                        addButtonText="Add Phone"
                        onChange={(values) => handleFieldChange({ name: 'phones', value: values })}
                        renderInput={(value, index) => (
                            <FieldSet
                                name="phones"
                                errors={contactErrorData}
                                customClass={`edit-phone ${index === 0 ? 'main-item' : ''} required-field-star`}
                                renderError={(error: any) => error[index]?.number[0]}
                            >
                                <PhoneTypeInput
                                    errors={contactErrorData['phones']?.[index]}
                                    phoneTypes={phones}
                                    onChange={(value) => {
                                        contactInfo.phones[index] = { ...value };
                                        handleFieldChange({ name: 'phones', value: [...contactInfo.phones] });
                                    }}
                                    value={value}
                                />
                            </FieldSet>
                        )}
                    />
                    <FieldSet name="email" errors={contactErrorData} customClass="edit-therapist-field required-field-star">
                        <InputField placeholder="Email" name="email" onChange={handleFieldChange} value={contactInfo.email}></InputField>
                    </FieldSet>
                    <FieldSet name="internalEmail" errors={contactErrorData} customClass="edit-therapist-field">
                        <InputField
                            placeholder="Internal Email"
                            name="internalEmail"
                            onChange={handleFieldChange}
                            value={contactInfo.internalEmail}
                        ></InputField>
                    </FieldSet>
                </div>
            </RightSideBar>
            <div className="right-sidebar-overlay"></div>
        </div>
    );
};

export default ContactInfoEditForm;
