import React from 'react';
import Tabs from 'common/components/tabs/tabs';
import Tab from 'common/components/tabs/tab';
import ContactInfoTab from './contact-info-tab/contact-info-tab';
import PreferencesTab from './preferences-tab/preferences-tab';
import GeneralInfoTab from './general-info-tab/general-info-tab';

const BasicInfoTab: React.FC = () => {
    return (
        <div>
            <Tabs orientation="vertical" parentTabUrl="basic-info" className="provider-general-tab">
                <Tab id="generalInfo" linkClassName={'custom-link'} text="General Info">
                    <GeneralInfoTab />
                </Tab>
                <Tab id="basicInfo" linkClassName={'custom-link'} text="Contact Info">
                    <ContactInfoTab />
                </Tab>
                <Tab id="preferences" linkClassName={'custom-link'} text="Preferences">
                    <PreferencesTab />
                </Tab>
            </Tabs>
        </div>
    );
};

export default BasicInfoTab;
