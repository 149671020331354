import Breadcrumb from 'common/components/Breadcrumb/breadcrumb';
import DetailsHeaderInfo from 'common/components/details-header-info/details-header-info';
import ServerNavigation from 'common/components/details-navigation/server-navigation';
import TooltipButton from 'common/components/reopened-button/tooltip-button';
import Tab from 'common/components/tabs/tab';
import Tabs from 'common/components/tabs/tabs';
import NursingHomeRequestService from 'common/services/api/nursing-home-requests/nursing-home-request-service';
import {
    INursingHomeRequestCloseReason,
    INursingHomeRequestDetails,
    NursingHomeRequestStatus,
} from 'common/services/api/nursing-home-requests/types';
import { IToggleFeatureList } from 'common/services/api/settings/types';
import { NavigationRoutes } from 'models/routes/navigation-routes';
import React, { useCallback, useEffect, useState } from 'react';
import { trackPromise } from 'react-promise-tracker';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router';
import { Link } from 'react-router-dom';
import NursingHomeRequestCloseSidebar from '../nursing-home-request-form/nursing-home-request-close-sidebar';
import NursingHomeRequestGeneralInfoTab from './general-info-tab/nursing-home-request-general-info-tab';
import { INursingHomeRequestDetailsContext, INursingHomeRequestParamType } from './types';

export const NursingHomeRequestDetailsContext = React.createContext<INursingHomeRequestDetailsContext>(null);

const NursingHomeRequestDetailsPage = () => {
    const [requestDetails, setRequestDetails] = useState<INursingHomeRequestDetails>();

    const featureList: IToggleFeatureList = useSelector((state: any) => state.siteSettings?.settings?.features || {});

    const params = useParams<INursingHomeRequestParamType>();
    const requestId = Number.parseInt(params?.requestId);

    const items = [
        { to: '/nursing-home/requests', label: 'Requests' },
        { to: '', label: 'Request Details' },
    ];

    const [isCloseBarOpen, setCloseBarOpen] = useState(false);

    const updateDetailsPage = useCallback(
        () => trackPromise(NursingHomeRequestService.getDetails(requestId)).then((detailsData) => setRequestDetails(detailsData)),
        [requestId]
    );

    useEffect(() => {
        if ((!requestDetails && requestId) || requestDetails?.id !== requestId) {
            updateDetailsPage();
        }
    }, [updateDetailsPage, requestDetails, requestId]);

    const handleSeeActivityButton = () => {
        window.open(`/logging?object=NursingHomeRequests&objectId=${requestId}`, '_blank').focus();
    };

    const handleAssignToUser = (userId: string) => {
        NursingHomeRequestService.assignToUser(requestId, userId).then(() => {
            updateDetailsPage();
        });
    };

    const isClosed = () => requestDetails?.status === NursingHomeRequestStatus.Closed;

    // Open / Cansel / Save
    const handleOnCloseClick = () => {
        setCloseBarOpen(true);
    };

    const handleCancelClick = () => {
        setCloseBarOpen(false);
    };

    const handleClickCloseRequest = (requestReason: INursingHomeRequestCloseReason) => {
        return trackPromise(NursingHomeRequestService.closeSingleRequest(requestId, requestReason)).then(() => {
            setCloseBarOpen(false);
            setRequestDetails(null);
        });
    };

    return (
        <NursingHomeRequestDetailsContext.Provider value={{ setRequestDetails, updateDetailsPage }}>
            <div className="details request">
                <header>
                    {featureList.isDetailsNavigationEnabled && (
                        <ServerNavigation
                            entityApiBaseUrl="nursing-home/requests"
                            entityId={requestId}
                            navigationRoute={NavigationRoutes.nursinghomerequestDetailsRoute}
                        />
                    )}
                    <div className="header-mock">
                        <Breadcrumb>
                            {items.map(({ to, label }) => (
                                <Link key={to} to={to}>
                                    {label}
                                </Link>
                            ))}
                        </Breadcrumb>
                        <div className="button-block">
                            {!isClosed() && (
                                <TooltipButton id="closeRequestBtn" onClick={handleOnCloseClick} className="blue">
                                    Close Request
                                </TooltipButton>
                            )}
                        </div>
                    </div>
                    {requestDetails && (
                        <DetailsHeaderInfo
                            details={{
                                ...requestDetails,
                                name: requestDetails.entity?.name,
                                createdAt: requestDetails.createdDate,
                                assignedTo: requestDetails.assignedTo,
                            }}
                            handleSeeActivityButton={handleSeeActivityButton}
                            onAssignUser={handleAssignToUser}
                        />
                    )}
                </header>
                <Tabs orientation="horizontal">
                    <Tab linkClassName={'custom-link'} text="General Info">
                        {requestDetails && <NursingHomeRequestGeneralInfoTab requestId={requestId} requestDetails={requestDetails} />}
                    </Tab>
                </Tabs>
                {isCloseBarOpen && <NursingHomeRequestCloseSidebar onClose={handleCancelClick} onSave={handleClickCloseRequest} />}
            </div>
        </NursingHomeRequestDetailsContext.Provider>
    );
};

export default NursingHomeRequestDetailsPage;
