import Button from 'common/components/Button/button';
import React, { useCallback } from 'react';
import { ShowAllButtonProps } from '../type';
const isFunction = require('lodash.isfunction');

const ShowAllButton = ({ title = '', buttonName, onClick }: ShowAllButtonProps) => {
    const handleClickOnShowOnButton = useCallback(() => {
        if (isFunction(onClick)) onClick();
    }, [onClick]);

    return (
        <Button id={`${title.replace(/\\s/g, '')}ShowAll`} onClick={handleClickOnShowOnButton} className="base-text-btn white">
            {buttonName}
        </Button>
    );
};

export default React.memo(ShowAllButton);
