import React, { useState, useRef, useEffect } from 'react';
import onClickOutside from 'react-onclickoutside';
import InfinitePopper from 'common/components/infinite-popper/infinite-popper';
import { customOffsetModifier } from '../infinite-popper/constants';

function CustomSmallDropdown(props) {
    const [isDropdownOpen, toggleDropdownState] = useState(false);
    CustomSmallDropdown['handleClickOutside_' + props.buttonId] = () => !props?.popper && toggleDropdownState(false);

    const iconClass = props.iconClass;
    const buttonId = props.buttonId;
    const optionsList = props.optionsList;
    const onOptionClick = props.onOptionClick;
    const isPopper = props?.popper;
    const width = props?.width ?? 200;
    const dropdownWrapper = useRef();
    const dropdownOptionList = useRef();
    const customModifiers = props.modifiers === undefined ? [customOffsetModifier] : props.modifiers;

    const onOptionClickHandle = (value) => {
        onOptionClick(value);
        toggleDropdownState(!isDropdownOpen);
    };

    const renderOptionsList = () => {
        return optionsList.map((item, index) => (
            <span className="option-item" id={`${item}_${index}`} key={item} onClick={() => onOptionClickHandle(item)}>
                {item}
            </span>
        ));
    };

    useEffect(() => {
        if (isDropdownOpen && props.initDropdownPosition) {
            props.initDropdownPosition(dropdownWrapper.current, dropdownOptionList.current);
        }
        if (props.isDropdownInTable)
            isDropdownOpen
                ? (dropdownWrapper.current.parentNode.parentNode.style.zIndex = '10')
                : (dropdownWrapper.current.parentNode.parentNode.style.zIndex = '3');
    }, [isDropdownOpen]);

    const optionList = (
        <div
            className={`custom-small-dropdown-options-list ${isPopper ? 'popper-container' : ''}`}
            ref={dropdownOptionList}
            id="sessionsDropdownWrapper"
        >
            {renderOptionsList()}
        </div>
    );

    return (
        <div className="custom-small-dropdown-wrapper" ref={dropdownWrapper}>
            <button
                id={buttonId}
                className={
                    isDropdownOpen
                        ? `active-btn custom-small-dropdown-button default-small-btn-with-icon ${iconClass}`
                        : `custom-small-dropdown-button default-small-btn-with-icon ${iconClass}`
                }
                onClick={() => toggleDropdownState(!isDropdownOpen)}
            ></button>
            {isDropdownOpen && isPopper && (
                <InfinitePopper
                    className="patients-in-zip-dialog-wrapper"
                    target={dropdownWrapper?.current}
                    width={width}
                    onClickOutside={() => toggleDropdownState(false)}
                    options={{
                        placement: 'bottom-end',
                        modifiers: customModifiers
                            ? customModifiers
                            : [
                                  {
                                      name: 'offset',
                                      options: {
                                          offset: [-2, 5],
                                      },
                                  },
                              ],
                    }}
                >
                    {optionList}
                </InfinitePopper>
            )}
            {isDropdownOpen && !isPopper && optionList}
        </div>
    );
}

const clickOutsideConfig = {
    handleClickOutside: ({ props }) => CustomSmallDropdown['handleClickOutside_' + props.buttonId],
};

export default onClickOutside(CustomSmallDropdown, clickOutsideConfig);
