import DropdownOption from './dropdown-option';

class DepartmentDropdownOption extends DropdownOption {
    isOrientationRequired: boolean;
    hasSubDepartments: boolean;

    constructor(value: number, label: string, isOrientationRequired: boolean, hasSubDepartments: boolean, className: string = undefined) {
        super(value, label, className);
        this.isOrientationRequired = isOrientationRequired;
        this.hasSubDepartments = hasSubDepartments;
    }
}

export default DepartmentDropdownOption;
