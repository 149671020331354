import { InfiniteServerClient } from 'common/helpers/http-clients';
import {
    IPlaceOfService,
    IPOSContactOption,
    ISchoolGridModel,
    ISchoolGridRow,
    ISchoolName,
    ISchoolNewRequest,
    ISchoolOption,
    ISchoolRequestDetails,
    ISchoolRequestTherapistAssignment,
} from './types';
import { IOption } from '../../../models/types';
import TherapyService from '../therapy/therapy-service';
import { departmentKey } from '../../../constants/department-key';
import { ITherapyTypeOption } from '../therapy/types';
import { IEntityOption } from '../entity/types';
import moment from 'moment';
import { DateTimeHelper } from '../../../helpers/date-time-helper';
import { ICreateNoteRequest } from 'common/models/create-note-request';
import { AddTherapistToRequest, ITherapistRequestStatusForm } from '../requests/types';
import { INote } from 'common/models/note';
import { IRequestDeclineReason } from 'features/request-page/components/request-forms/types';
import { TherapistRequestStatus } from 'common/components/assigned-therapist-list/types';

const getOptions = <T>(url: string, term?: string, skip?: number, take?: number): Promise<T[]> => {
    return InfiniteServerClient.get(url, { params: { term, skip, take } }).then(
        (response) => {
            return response?.data;
        },
        (error) => {
            throw error;
        }
    );
};

export class SchoolService {
    getAllYearOptions(): Promise<ISchoolOption[]> {
        return getOptions<ISchoolOption>(`/school/years?pastYears=true`);
    }

    getYearOptions(): Promise<ISchoolOption[]> {
        return getOptions<ISchoolOption>(`/school/years?pastYears=false`);
    }

    getTherapyOptions(term: string, skip: number, take: number): Promise<ITherapyTypeOption[]> {
        return TherapyService.getTherapyTypeOptions(departmentKey.SCHOOL, term, skip, take);
    }

    getBidRankOptions(): Promise<IOption[]> {
        return getOptions('/options?fieldId=BidRank');
    }

    getPopulationOptions(): Promise<IOption[]> {
        return getOptions('/options?fieldId=SchoolLevelPopulation');
    }

    getPriorityLevelOptions(): Promise<IOption[]> {
        return getOptions('/options?fieldId=PriorityLevel');
    }

    getContractTypeOptions(): Promise<IOption[]> {
        return getOptions('/options?fieldId=ContractType');
    }

    getDOEContactOptions(): Promise<IEntityOption[]> {
        return getOptions('/school/entities/doe/contacts');
    }

    getSchoolNameOptions(term: string, skip: number, take: number): Promise<ISchoolName[]> {
        return InfiniteServerClient.get('/school/entities', { params: { term, skip, take } }).then(
            (response) => {
                return response?.data;
            },
            (error) => {
                throw error;
            }
        );
    }

    getPOSContactOptions(entityId: number): any {
        return (term: string, skip: number, take: number): Promise<IPOSContactOption[]> => {
            return getOptions(`/entities/${entityId}/contacts`, term, skip, take);
        };
    }

    create(schoolRequest: ISchoolNewRequest): Promise<number> {
        return InfiniteServerClient.post(`/school/requests`, schoolRequest).then(
            (response) => {
                return response.data;
            },
            (error) => {
                throw error;
            }
        );
    }

    update(id: number, schoolRequest: ISchoolNewRequest): Promise<void> {
        return InfiniteServerClient.put(`/school/requests/${id}`, schoolRequest).then(
            (response) => {
                return response.data;
            },
            (error) => {
                throw error;
            }
        );
    }

    getData(params?: any): Promise<ISchoolGridRow[]> {
        return InfiniteServerClient.get('/school/requests', { params }).then(
            (response) => {
                return response.data.map((item: ISchoolGridModel) => this._transformSchoolToRows(item));
            },
            (error) => {
                throw error;
            }
        );
    }

    getDetails(requestId: number): Promise<ISchoolRequestDetails> {
        return InfiniteServerClient.get(`/school/requests/${requestId}`).then(
            (response) => {
                return this._transformRequestDetails(response.data);
            },
            (error) => {
                throw error;
            }
        );
    }

    addTherapistToRequest(addModel: AddTherapistToRequest): Promise<void> {
        return InfiniteServerClient.post(`/school/requests/${addModel.requestId}/therapists`, addModel).then(
            (response) => {
                return response.data;
            },
            (error) => {
                throw error;
            }
        );
    }

    createNote(requestId: number, request: ICreateNoteRequest): Promise<INote> {
        return InfiniteServerClient.post<INote>(`/school/requests/${requestId}/notes`, request || {}).then(
            (response) => {
                return response.data;
            },
            (error) => {
                throw error;
            }
        );
    }

    addNoteToTherapistRequest(requestId: number, therapistId: number, note: ICreateNoteRequest) {
        return InfiniteServerClient.post(`/school/requests/${requestId}/notes`, { ...note, therapistId }).then(
            (response) => {
                return response.data;
            },

            (error) => {
                throw error;
            }
        );
    }

    setDeclineStatus(id: number, { reasonId, comment }: IRequestDeclineReason) {
        return InfiniteServerClient.put(`/school/requests/${id}/actions/decline-request`, {
            ReasonId: reasonId,
            Comment: comment,
        }).then(
            (response) => {
                return response.data;
            },

            (error) => {
                throw error;
            }
        );
    }

    setStatusToTherapistRequest(requestId: number, therapistId: number, statusForm: ITherapistRequestStatusForm) {
        return InfiniteServerClient.put(`/school/requests/${requestId}/therapists/${therapistId}/status`, statusForm).then(
            (response) => {
                return response.data;
            },

            (error) => {
                throw error;
            }
        );
    }

    _transformSchoolToRows(item: ISchoolGridModel): ISchoolGridRow {
        const schoolStatus: any = {
            0: 'Undefined',
            1: 'New',
            2: 'In Progress',
            3: 'Partially Covered',
            4: 'Pending Interview',
            5: 'Complete',
            6: 'Declined',
        };

        const start = moment(item.createdAt);
        const end = moment(new Date());

        const result = {
            caseId: item.id,
            dbn: item.placeOfService?.dbn,
            bidRank: item.bidRank?.name,
            daysOpen: Math.floor(moment.duration(end.diff(start)).asDays()),
            therapy: item.serviceType?.name,
            lang: !item.language || item.language?.name === 'English' ? 'EN' : item.language?.abbreviation ?? item.language?.name,
            stdnts: formatArrayForExport(item.providers?.map((provider) => provider.students + '')),
            days: formatArrayForExport(item.providers?.map((provider) => provider.days + '')),
            startDate: DateTimeHelper.format(item.startDate, 'MM/DD/YYYY', true),
            endDate: DateTimeHelper.format(item.endDate, 'MM/DD/YYYY', true),
            placeOfService: formatPlaceOfService(item.placeOfService),
            status: schoolStatus[item.status],
            schoolYear: item.year + '',
            populations: [item.population?.name],
            priorityLevel: [item.priorityLevel?.name],
            schoolName: item.placeOfService?.name,
            ...this._transformAssignedTherapistsToGrid(item.therapists),
        };

        return result;
    }

    _transformAssignedTherapistsToGrid(therapists: ISchoolRequestTherapistAssignment[]) {
        const assignedTo: string[] = [];
        const assignedBy: string[] = [];
        const assignedDate: string[] = [];

        therapists?.forEach((therapist) => {
            if (therapist.status === TherapistRequestStatus.Assigned) {
                assignedTo.push(therapist.fullName);
                assignedBy.push(therapist.updatedBy.fullName);
                assignedDate.push(DateTimeHelper.format(therapist.updatedAt, 'MM/DD/YYYY', true));
            }
        });

        return {
            assignedTo: formatArrayForExport(assignedTo),
            assignedBy: formatArrayForExport(assignedBy),
            assignedDate: formatArrayForExport(assignedDate),
        };
    }

    _transformRequestDetails(details: ISchoolRequestDetails): ISchoolRequestDetails {
        const result: ISchoolRequestDetails = {
            ...details,
            doeContact: {
                ...details.doeContact,
                name: details.doeContact?.contact?.fullName,
            },
            posContact: details.posContact && {
                ...details.posContact,
                name: details.posContact?.contact?.fullName,
            },
        };

        return result;
    }
}

const voidOrComma = (str: string) => {
    return str?.length > 0 ? str + ', ' : '';
};

const formatPlaceOfService = (pos: IPlaceOfService) => {
    return (
        voidOrComma(pos.name) +
        voidOrComma(pos.address?.address1) +
        voidOrComma(pos.address?.city) +
        voidOrComma(pos.address?.state?.name) +
        voidOrComma(pos.address?.postalCode)
    ).replace(/,\s*$/, '');
};

// Is used because ag-grid export does not support value-formatting
const formatArrayForExport = (values: string[]): string[] => {
    values.toString = (): string => {
        return values.join(', ');
    };

    return values;
};

export default new SchoolService();
