import { Dispatch } from '@reduxjs/toolkit';
import PatientService from 'common/services/api/patient/patient-service';
import { IPatientGridItem } from 'common/services/api/patient/types';
import { ThunkResult } from '../department/types';
import { ISetPatientsAction } from './types';

export const setPatients = (payload: IPatientGridItem[]): ISetPatientsAction => {
    return {
        patients: payload,
        type: 'SET_PATIENTS',
    };
};

export const fetchPatients = (): ThunkResult<Promise<IPatientGridItem[]>> => {
    return async (dispatch: Dispatch) => {
        const patients = await PatientService.getAll();
        dispatch(setPatients(patients));

        return patients;
    };
};
