import { useState } from 'react';
import { IDisableOptions, IFormItem, IFormSidebar, ILabelOptions } from '../models/types';
import { ValidationHelper } from 'common/helpers/validation-helper';
import { UseFormSidebarProps } from './types';
import { isArray } from 'lodash';
import { ContactActionType } from 'common/constants/entity-type';
import { toast } from 'react-toastify';

const useFormSidebar = <T>(initState: T, { onClose, onSave, errorKey, errorConfigs }: IFormSidebar<T, null> & UseFormSidebarProps): any => {
    const [data, setData] = useState<T & ILabelOptions & IDisableOptions>(initState);
    const [errorData, setErrorData] = useState({});

    const handleChangeData = (data: IFormItem) => {
        setData((prevInfo) => ({ ...prevInfo, [data.name]: data.value, labels: { ...prevInfo?.labels, [data.name]: data.lable } }));
        resetErrorData(data.name, data?.parentName);
    };

    const resetErrorData = (fieldName: string, group: string) => {
        setErrorData((prevError: any) => ({
            ...prevError,
            [fieldName]: undefined,
            [group]: { ...prevError?.[group], [fieldName]: undefined },
        }));
    };

    const handleCancelClick = () => {
        setErrorData({});
        onClose();
    };

    const handleOnSaveClick = (contactActionType?: ContactActionType) => {
        const restData = { ...data };
        delete restData.labels;
        delete restData.disableSave;
        onSave(restData, contactActionType).catch((error: any) => {
            const errorData = error?.response?.data ?? error;
            if (!errorData || (errorKey && !errorData[errorKey])) return;

            if (error?.response?.status === 422) {
                toast.error(errorData);

                return;
            }

            if (errorData.errorCode && isArray(errorConfigs)) {
                const errorConfigItem = errorConfigs.find((i) => i.errorCode === errorData.errorCode);
                if (errorConfigItem) {
                    if (Array.isArray(errorConfigItem.field)) {
                        const errorDataList: any = {};
                        errorConfigItem.field.forEach((field: string) => (errorDataList[field] = [errorConfigItem.error]));
                        setErrorData(errorDataList);
                    } else {
                        setErrorData({ [errorConfigItem.field]: [errorConfigItem.error] });
                    }

                    return;
                }
            }
            const errors = ValidationHelper.handleErrorResponse(errorData || errorData[errorKey]);
            console.log('errors was', errors);
            setErrorData(errors);
        });
    };

    return [data, errorData, handleChangeData, handleCancelClick, handleOnSaveClick, setData, setErrorData, resetErrorData];
};

export default useFormSidebar;
