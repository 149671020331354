import { IUserBaseInfo } from 'common/services/api/user/types';

export interface INote {
    id: number;
    note: string;
    reminderDate?: Date;
    snoozedReminderDate?: Date;
    completeDate?: Date;
    createdAt?: Date;
    createdBy?: IUserBaseInfo;
    entity?: IEntityNote;
}

export interface IEntityNote {
    id: number;
    type: EntityNoteType;
    name: string;
}

export enum EntityNoteType {
    Therapist = 'Therapist',
    Patient = 'Patient',
    Request = 'Request',
    NursingHomeRequest = 'NursingHomeRequest',
    LegalEntity = 'LegalEntity',
    Lead = 'Lead',
    SchoolRequest = 'SchoolRequest',
}
