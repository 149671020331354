import { ColumnApi, GridApi } from 'ag-grid-community';
import { CellClickedEvent, GridReadyEvent } from 'ag-grid-community/dist/lib/events';
import AgTable from 'common/components/ag-table/ag-table';
import { IParamsWatcher } from 'common/components/ag-table/types';
import ClearFiltersButton from 'common/components/header/clear-filters-button/clear-filters-button';
import ExportButton from 'common/components/header/export-button/export-button';
import HeaderLeftSide from 'common/components/header/header-left-side';
import HeaderRightSide from 'common/components/header/header-right-side';
import HeaderWrapper from 'common/components/header/header-wrapper';
import SearchFilterInput from 'common/components/header/search-filter-input/search-filter-input';
import ColumnsVisibleFilterSelect from 'common/components/header/сolumns-visible-filter-select/columns-visible-filter-select';
import { LocalStoreRepository } from 'common/helpers/repository/local-store-repository';
import { useSearchInputWithStore } from 'common/hooks/use-serch-input';
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { ExtentedDefaultTableProps } from 'common/constants/types';
import { LocalTableRepository } from 'common/helpers/repository/local-table-repository';
import { defaultColDefClient, excelStyles } from 'common/helpers/ag-helper';
import schoolService from 'common/services/api/school/school-service';
import DropdownOption from 'common/models/dropdown-option';
import AddEditSchoolSidebar from './add-edit-school-sidebar/add-edit-school-sidebar';
import { ISchoolGridFilters, ISchoolGridRow, ISchoolNewRequest } from 'common/services/api/school/types';
import FilterSelect from 'common/components/header/multiple-filter-select/filter-select';
import { schoolRequestColumnDefs } from './school-list-config';
import { trackPromise } from 'react-promise-tracker';
import { NavigationRoutes } from 'models/routes/navigation-routes';
import { useHistory } from 'react-router-dom';
import MultipleBadgesFilter from 'common/components/header/multiple-badges-filter/multiple-badges-filter';

const SchoolList: React.FC<ExtentedDefaultTableProps> = ({ onGridReady, storePrefix = '', extendGridOptions }) => {
    const gridRef = useRef<any>();
    const [gridApi, setGridApi] = useState<GridApi>(null);
    const [gridColumnApi, setColumnApi] = useState<ColumnApi>(null);
    const [defaultKeys, setDefaultKeys] = useState([]);
    const [badgeList, setBadgeList] = useState(defaultBadgeList);
    const history = useHistory();

    const tableRepository = new LocalTableRepository(`${storePrefix}-school-table`, 'v10');
    const badgeRepository = new LocalStoreRepository(`${storePrefix}-school-list-badge`, 'v2');

    const { search, handleSearchInputChange, setSearch } = useSearchInputWithStore(
        new LocalStoreRepository(`${storePrefix}-school-search`, 'v1'),
        (value: string) => {
            if (gridRef.current?.api) {
                gridRef.current.api.setQuickFilter(value);
            }
        },
        0
    );

    const paramsWatcher = useRef<IParamsWatcher>();

    // Fetch data
    const [topFilters, setTopFilters] = useState<ISchoolGridFilters>({
        schoolYears: [],
        populations: [],
        priorityLevels: [],
    });
    const [rows, setRows] = useState<ISchoolGridRow[]>();

    const updateDataTable = () => {
        schoolService.getData().then((response) => {
            setTopFilterData(response);

            setRows(response);
            updateCount(response);
        });
    };

    const setTopFilterData = (response: ISchoolGridRow[]) => {
        const yearsSet = new Set<string>();
        const populationSet = new Set<string>();
        const priorityLevelSet = new Set<string>();

        response.forEach((i) => {
            yearsSet.add(i.schoolYear);

            i.populations.filter((i) => i).forEach((pop) => populationSet.add(pop));
            i.priorityLevel.filter((i) => i).forEach((level) => priorityLevelSet.add(level));
        });

        setTopFilters({
            schoolYears: Array.from(yearsSet)
                .sort()
                .map((year) => new DropdownOption(year + '', year + '')),
            populations: Array.from(populationSet)
                .sort()
                .map((pop) => new DropdownOption(pop, pop)),
            priorityLevels: Array.from(priorityLevelSet)
                .sort()
                .map((level) => new DropdownOption(level, level)),
        });
    };

    useEffect(() => {
        updateDataTable();
    }, []);

    // Config
    const gridOptions = useMemo(
        () => ({
            ...extendGridOptions,
            onCellClicked: (event: CellClickedEvent) => {
                const { caseId } = event.data;
                const requestsIds: any[] = [];

                event.api.forEachNodeAfterFilterAndSort((row) => requestsIds.push(row.data.caseId));

                history.push({ pathname: NavigationRoutes.schoolRequestDetailsRoute(caseId) }, { requestsIds });

                if (extendGridOptions?.onCellClicked) extendGridOptions?.onCellClicked(event);
            },
            columnDefs: extendGridOptions?.columnDefs ?? schoolRequestColumnDefs,
            excelStyles: excelStyles,
            defaultColDef: defaultColDefClient,
            frameworkComponents: {
                statusRender: (props: any) => {
                    const cellValue = (props.valueFormatted ? props.valueFormatted : props.value) || '';

                    return (
                        <span className={`school-status d-flex fixed-width ${cellValue.replace(/\s+/g, '').toLowerCase()}`}>
                            {cellValue}
                        </span>
                    );
                },
                objectLinkValueRender: (props: any) => {
                    const cellValue = (props.valueFormatted ? props.valueFormatted : props.value) || '';

                    return <span className="object-link">{cellValue}</span>;
                },
                ...extendGridOptions?.frameworkComponents,
            },
        }),
        [extendGridOptions, history]
    );

    const handleGridReady = (params: GridReadyEvent) => {
        setGridApi(params.api);
        setColumnApi(params.columnApi);

        if (onGridReady) {
            onGridReady({ params, extraParams: { setCreateDialogOpen } });
        }
    };

    // Side Bar
    const [isCreateDialogOpen, setCreateDialogOpen] = useState<boolean>(false);

    const handleOnClose = () => {
        setCreateDialogOpen(false);
    };

    const handleOnSave = (schoolForm: ISchoolNewRequest) => {
        return trackPromise(schoolService.create(schoolForm)).then((response: any) => {
            setCreateDialogOpen(false);
            history.push({ pathname: NavigationRoutes.schoolRequestDetailsRoute(response) });
        });
    };

    // Badge
    const searchRef = useRef(null);
    searchRef.current = search;

    const handleFiltersClear = () => {
        setSearch('');
        setDefaultKeys([]);
        gridRef.current?.api.setQuickFilter('');
    };

    const handleBadgeCompare = (rows: ISchoolGridRow[]) => {
        const allFiltersModels = gridRef.current?.api.getFilterModel();

        return rows?.filter((row: ISchoolGridRow) => {
            const search = searchRef.current?.toLowerCase();

            // check search value
            if (search?.length > 0) {
                if (!row?.dbn?.toLowerCase().includes(search) && !row?.placeOfService?.toLowerCase().includes(search)) {
                    return false;
                }
            }

            // apply filter set
            for (const [key, value] of Object.entries(row)) {
                if (key !== 'status' && allFiltersModels[key]?.filterType === 'set' && value !== null && value !== undefined) {
                    const filterValues = allFiltersModels[key]?.values;
                    let cellValue = value;

                    if (Array.isArray(value)) {
                        cellValue = value.join(',');
                    }

                    cellValue = cellValue.toString();

                    // Accept line if it's filtered by (blank) value.
                    if (filterValues.includes(null) && value?.includes(undefined)) {
                        return true;
                    }

                    if (!filterValues.some((filterValue: string) => cellValue?.toLowerCase() === filterValue?.toLowerCase())) {
                        return false;
                    }
                }
            }

            return true;
        });
    };

    const updateCount = useCallback(
        (insertedRows?: any) => {
            const stateCount: any = {};
            const filteredRows = handleBadgeCompare(insertedRows ? insertedRows : rows);

            filteredRows?.forEach((row: any) => {
                if (!stateCount[row.status]) {
                    stateCount[row.status] = 0;
                }

                stateCount[row.status]++;
            });

            setBadgeList(
                badgeList.map((item) => ({
                    value: item.value,
                    title: item.title,
                    body: stateCount[item.value],
                }))
            );
        },
        [rows, badgeList]
    );

    return (
        <>
            <div className="main-info-wrapper ag-table-wrapper">
                <HeaderWrapper>
                    <HeaderLeftSide>
                        <SearchFilterInput title="Requests" value={search} onInput={handleSearchInputChange} />
                        <ColumnsVisibleFilterSelect title="schools" gridApi={gridApi} gridColumnApi={gridColumnApi} selectAll />
                        <FilterSelect
                            items={topFilters.schoolYears}
                            gridApi={gridApi}
                            colId="schoolYear"
                            id="schoolSelectFilter"
                            label="School Year"
                            multiselect
                            selectAll
                            clearFilters
                            defaultMultipleSelectLabel="All School Years"
                        />

                        <FilterSelect
                            items={topFilters.populations}
                            gridApi={gridApi}
                            colId="populations"
                            id="populationsSelectFilter"
                            label="Population"
                            multiselect
                            selectAll
                            clearFilters
                            defaultMultipleSelectLabel="All Populations"
                        />

                        <FilterSelect
                            items={topFilters.priorityLevels}
                            gridApi={gridApi}
                            colId="priorityLevel"
                            id="prioritySelectFilter"
                            label="Priority"
                            multiselect
                            selectAll
                            clearFilters
                            isNullable
                            defaultMultipleSelectLabel="All Priorities"
                        />
                    </HeaderLeftSide>
                    <HeaderRightSide>
                        <ExportButton title="schoolRequest" paramsWatcher={paramsWatcher} gridRef={gridRef} />
                        <ClearFiltersButton title="schools" gridApi={gridApi} gridColumnApi={gridColumnApi} onClick={handleFiltersClear} />
                        {/*{filters.entityId && <AddButton title="Add School " onClick={handleClickAddPhysician} />}*/}
                    </HeaderRightSide>
                </HeaderWrapper>
                <MultipleBadgesFilter
                    items={badgeList}
                    defaultKeys={defaultKeys}
                    setDefaultKeys={setDefaultKeys}
                    repository={badgeRepository}
                    gridApi={gridApi}
                    updateCount={updateCount}
                    statusLabel={SchoolRequestStatusLabel}
                    statusToRequestCount={SchoolRequestStatusToRequestCount}
                    convertStringToRequestStatus={convertBadgeStringToRequestStatus}
                />
                <AgTable
                    clientOptions={{ rowData: rows }}
                    gridRef={gridRef}
                    clientSide
                    onGridReady={handleGridReady}
                    gridOptions={gridOptions}
                    onFiltersLoadedFromUrl={handleFiltersClear}
                    repository={tableRepository}
                    paramsWatcher={paramsWatcher}
                />
            </div>
            {isCreateDialogOpen && <AddEditSchoolSidebar onClose={handleOnClose} onSave={handleOnSave} />}
        </>
    );
};

export enum SchoolRequestStatusCount {
    New = 'New',
    InProgress = 'In Progress',
    PartiallyCovered = 'Partially Covered',
    PendingInterview = 'Pending Interview',
    Complete = 'Complete',
    Declined = 'Declined',
}

const convertBadgeStringToRequestStatus = (value: string) => value as SchoolRequestStatusCount;

const defaultBadgeList = [
    { value: SchoolRequestStatusCount.New, title: 'New', body: 0 },
    { value: SchoolRequestStatusCount.InProgress, title: 'In Progress', body: 0 },
    { value: SchoolRequestStatusCount.PartiallyCovered, title: 'Partially Covered', body: 0 },
    { value: SchoolRequestStatusCount.PendingInterview, title: 'Pending Interview', body: 0 },
    { value: SchoolRequestStatusCount.Complete, title: 'Complete', body: 0 },
    { value: SchoolRequestStatusCount.Declined, title: 'Declined', body: 0 },
];

export const SchoolRequestStatusLabel = new Map<SchoolRequestStatusCount, string>([
    [SchoolRequestStatusCount.New, 'New'],
    [SchoolRequestStatusCount.InProgress, 'In Progress'],
    [SchoolRequestStatusCount.PartiallyCovered, 'Partially Covered'],
    [SchoolRequestStatusCount.PendingInterview, 'Pending Interview'],
    [SchoolRequestStatusCount.Complete, 'Complete'],
    [SchoolRequestStatusCount.Declined, 'Declined'],
]);

export const SchoolRequestStatusToRequestCount = new Map<string, SchoolRequestStatusCount>([
    ['New', SchoolRequestStatusCount.New],
    ['In Progress', SchoolRequestStatusCount.InProgress],
    ['Partially Covered', SchoolRequestStatusCount.PartiallyCovered],
    ['Pending Interview', SchoolRequestStatusCount.PendingInterview],
    ['Complete', SchoolRequestStatusCount.Complete],
    ['Declined', SchoolRequestStatusCount.Declined],
]);

export default SchoolList;
