import { IUserBaseInfo } from 'common/services/api/user/types';
import moment from 'moment';

export const InterviewListDefenition = [
    {
        headerName: 'Host Name',
        field: 'host',
        flex: 1,
        minWidth: 200,
        filter: 'agCustomFilter',
        valueFormatter: (props: any) => (props.value as IUserBaseInfo)?.fullName,
    },
    {
        headerName: 'Date',
        field: 'dateTime',
        filter: 'agDateColumnFilter',
        width: 150,
        initialSort: 'asc',
        filterParams: {
            isDateTime: true,
        },
        valueFormatter: (props: any) => moment(props.value).local().format('MM/DD/YYYY'),
    },
    {
        headerName: 'Time',
        field: 'dateTime',
        width: 150,
        sortable: false,
        valueFormatter: (props: any) => moment(props.value).local().format('hh:mm A'),
    },
    {
        headerName: 'Duration',
        field: 'duration',
        width: 150,
        valueFormatter: (props: any) => `${props.value} min`,
        filter: 'agCustomFilter',
    },
    {
        headerName: 'Location',
        field: 'location',
        cellRenderer: 'locationRenderer',
        filter: 'agCustomFilter',
    },
    {
        headerName: 'Participants',
        field: 'participants',
        filter: 'agCustomFilter',
        width: 250,
        sortable: false,
        valueFormatter: (props: any) => {
            const participants: IUserBaseInfo[] = (props.value as IUserBaseInfo[]) || [];

            return participants?.map((i) => i.fullName)?.join(', ');
        },
    },
    {
        headerName: 'Status',
        field: 'status',
        cellRenderer: 'statusRenderer',
        width: 150,
        filter: 'agCustomFilter',
    },
    {
        headerName: '',
        colId: 'doteMenu',
        pinned: 'right',
        sortable: false,
        cellRenderer: 'doteRender',
        cellClass: ['dote-cell'],
        width: 40,
        hideForExport: true,
    },
];
