import React, { useEffect, useState } from 'react';
import './App.scss';
import { Switch, Route, Router, Redirect } from 'react-router-dom';
import { history } from '../common/helpers/history';
import LoginPage from '../features/authentication/login-page/login-page.container';
import { connect, useDispatch, useSelector } from 'react-redux';
import DefaultLayout from '../common/components/layout/default-layout';
import { PrivateRoute } from '../common/components/private-route/private-route';
import TherapistPage from '../features/therapist-page/therapist-page';
import { ACCESS_TOKEN_KEY } from '../common/constants/local-storage-keys';
import TherapistDetailsPage from '../features/therapist-details-page/therapist-details-page';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.min.css';
import DocumentViewer from 'features/document-viewer/components/document-viewer';
import DocumentManagement from 'features/document-management/document-management';
import LeadsPage from 'features/leads/leads-page';
import { fetchSiteSettings } from './store/settings/action-creators';
import RequestsPage from 'features/request-page/requests-page';
import NursingHomeRequestsPage from 'features/nursing-home-requests-page/nursing-home-requests-page';
import RequestDetailsPage from 'features/request-page/components/request-details/request-details-page';
import LoggingPage from 'features/logging-page/logging-page';
import PatientDetailsPage from 'features/patients-page/components/patient-details/patient-details-page';
import PatientsPage from 'features/patients-page/patients-page';
import PatientsExternalPage from 'features/patients-page/patients-external-page';
import moment from 'moment-timezone';
import SettingsPage from 'features/settings-page/settings-page';
import { useHasPermission } from 'common/hooks/use-has-permission';
import { getWidgetPermissions, PermissionType } from 'common/models/permission-type';
import NotesPage from 'features/notes-page/notes-page';
import StaffingAgenciesPage from 'features/staffing-agencies-page/staffing-agencies-page';
import EntitiesPage from 'features/entities-page/entities-page';
import EntityDetailsPage from 'features/entities-page/components/entity-details/entity-details-page';
import TherapistsMapPage from 'features/therapists-map-page/therapists-map-page';
import UsersPage from 'features/users-page/users-page';
import RolesPage from 'features/roles-page/roles-page';
import SetupPasswordPage from 'features/update-password-page/update-password-page';
import DashboardPage from 'features/dashboard-page/dashboard-page';
import NursingHomeRequestDetailsPage from 'features/nursing-home-requests-page/components/request-details/nursing-home-request-details-page';
import { logOut, setRedirectTo } from 'features/authentication/auth.actions';
import { useIdleTimer } from 'react-idle-timer';
import { useIsUserType } from '../common/hooks/use-is-user-type';
import ForgotPasswordPage from '../features/fogot-password/fogot-password-page';
import RequestPageExternal from '../features/request-page/requests-page-external';
import { UpdatePasswordType } from 'features/update-password-page/types';
import { trackPromise } from 'react-promise-tracker';
import PhysiciansPage from 'features/physicians-page/physicians-page';
import AvailabilityPortal from 'features/availability-portal-page/availability-portal';
import RefreshPopup from '../common/components/inifinite-popups/refresh-popup';
import FollowUpsPage from '../features/placement-follow-ups-page/follow-ups-page';
import LeadDetailsPage from 'features/leads/components/lead-details/lead-details-page';
import SchoolPage from '../features/school-page/school-page';
import NotificationsContainer from '../common/components/notifications/notifications-container';
import SchoolRequestDetailsPage from 'features/school-page/components/school-request-details/school-request-details-page';

const INACTIVE_TIMEOUT = 1000 * 60 * 30;

function App({ loggedIn }) {
    const dispatch = useDispatch();
    moment.tz.setDefault('Etc/UTC');

    useEffect(() => {
        if (loggedIn) {
            trackPromise(dispatch(fetchSiteSettings()));
        }
    }, [dispatch, loggedIn]);

    const hasSettingsEditPermission = useHasPermission(PermissionType.SettingsEdit);
    const hasCanViewInternalUsersPermission = useHasPermission(PermissionType.CanViewInternalUsers);
    const hasCanUpdateInternalUsersPermission = useHasPermission(PermissionType.CanUpdateInternalUsers);
    const hasCanViewExternalUsersPermission = useHasPermission(PermissionType.CanViewExternalUsers);
    const hasCanUpdateExternalUsersPermission = useHasPermission(PermissionType.CanUpdateExternalUsers);
    const hasCanManageRolesPermission = useHasPermission(PermissionType.CanManageRoles);
    const hasAccessToDashboardMenu = useHasPermission(...getWidgetPermissions());
    const { isExternal, isInfiniteUser, isTherapistUser } = useIsUserType();

    const [firstLocation] = useState(history.location.pathname);
    useEffect(() => {
        dispatch(setRedirectTo(firstLocation));
    }, [dispatch, firstLocation]);

    const featureList = useSelector((state) => state.siteSettings?.settings?.features || {});
    const versionModal = useSelector((state) => state.versionModal?.visible);

    const handleOnIdle = () => {
        // eslint-disable-next-line @typescript-eslint/no-use-before-define
        console.log(`User was inactive for 30 minutes. Last active date: ${moment(getLastActiveTime())}`);
        dispatch(logOut());
    };

    const { getLastActiveTime } = useIdleTimer({
        timeout: INACTIVE_TIMEOUT,
        onIdle: handleOnIdle,
        crossTab: {
            emitOnAllTabs: true,
        },
    });

    return (
        <>
            <div className="infinity-app">
                <Router history={history}>
                    <Switch>
                        <Route path="/login" exact component={LoginPage} />
                        <Route path="/forgot-password" exact component={ForgotPasswordPage} />
                        <Route path="/setup-new-password" exact render={() => <SetupPasswordPage type={UpdatePasswordType.Setup} />} />
                        <Route path="/reset-password" exact render={() => <SetupPasswordPage type={UpdatePasswordType.Reset} />} />

                        {!loggedIn && <Redirect to={{ pathname: '/login' }}></Redirect>}
                        {loggedIn && isExternal && (
                            <Switch>
                                <PrivateRoute path="/files/:fileId/view">
                                    <DocumentViewer></DocumentViewer>
                                </PrivateRoute>
                                <DefaultLayout>
                                    <Switch>
                                        <PrivateRoute
                                            path="/requests/:requestId"
                                            isLoggedIn={loggedIn}
                                            isAvailable={featureList.isHCEnabled}
                                        >
                                            <RequestDetailsPage />
                                        </PrivateRoute>
                                        <PrivateRoute
                                            path="/patients/:patientId"
                                            isLoggedIn={loggedIn}
                                            isAvailable={featureList.isHCEnabled}
                                        >
                                            <PatientDetailsPage />
                                        </PrivateRoute>
                                        <PrivateRoute path="/requests" isLoggedIn={loggedIn} isAvailable={featureList.isHCEnabled}>
                                            <RequestPageExternal />
                                        </PrivateRoute>
                                        <PrivateRoute path="/patients" isLoggedIn={loggedIn} isAvailable={featureList.isHCEnabled}>
                                            <PatientsExternalPage />
                                        </PrivateRoute>
                                    </Switch>
                                    <Route exact path="/" render={() => <Redirect to={`/requests`} />} />
                                </DefaultLayout>
                            </Switch>
                        )}
                        {loggedIn && (isInfiniteUser || isTherapistUser) && (
                            <Switch>
                                <PrivateRoute path="/files/:fileId/view">
                                    <DocumentViewer></DocumentViewer>
                                </PrivateRoute>
                                <DefaultLayout>
                                    <Switch>
                                        <PrivateRoute path="/providers/:therapistId" isloggedIn={loggedIn}>
                                            <TherapistDetailsPage></TherapistDetailsPage>
                                        </PrivateRoute>
                                        {featureList.isTherapistsMapEnabled && (
                                            <PrivateRoute path="/providers-map" isloggedIn={loggedIn}>
                                                <TherapistsMapPage></TherapistsMapPage>
                                            </PrivateRoute>
                                        )}
                                        <PrivateRoute path="/providers" isloggedIn={loggedIn}>
                                            <TherapistPage />
                                        </PrivateRoute>
                                        {hasAccessToDashboardMenu && (
                                            <PrivateRoute path="/dashboard" isloggedIn={loggedIn}>
                                                <DashboardPage />
                                            </PrivateRoute>
                                        )}
                                        <PrivateRoute
                                            path="/leads/:leadId"
                                            isLoggedIn={loggedIn}
                                            isAvailable={featureList.isLeadManagementEnabled}
                                        >
                                            <LeadDetailsPage />
                                        </PrivateRoute>
                                        {featureList.isLeadManagementEnabled && (
                                            <PrivateRoute
                                                path="/leads"
                                                isloggedIn={loggedIn}
                                                isAvailable={featureList.isLeadManagementEnabled}
                                            >
                                                <LeadsPage />
                                            </PrivateRoute>
                                        )}
                                        <PrivateRoute path="/documents" isloggedIn={loggedIn}>
                                            <DocumentManagement />
                                        </PrivateRoute>
                                        <PrivateRoute path="/entities/:entityId" isLoggedIn={loggedIn}>
                                            <EntityDetailsPage />
                                        </PrivateRoute>
                                        <PrivateRoute path="/entities" isLoggedIn={loggedIn}>
                                            <EntitiesPage />
                                        </PrivateRoute>
                                        <PrivateRoute
                                            path="/requests/:requestId"
                                            isLoggedIn={loggedIn}
                                            isAvailable={featureList.isHCEnabled}
                                        >
                                            <RequestDetailsPage />
                                        </PrivateRoute>
                                        <PrivateRoute
                                            path="/school/requests/:requestId"
                                            isLoggedIn={loggedIn}
                                            isAvailable={featureList.isSchoolRequestsEnabled}
                                        >
                                            <SchoolRequestDetailsPage />
                                        </PrivateRoute>
                                        <PrivateRoute
                                            path="/patients/:patientId"
                                            isLoggedIn={loggedIn}
                                            isAvailable={featureList.isHCEnabled}
                                        >
                                            <PatientDetailsPage />
                                        </PrivateRoute>
                                        <PrivateRoute path="/nursing-home/requests/:requestId" isLoggedIn={loggedIn}>
                                            <NursingHomeRequestDetailsPage />
                                        </PrivateRoute>
                                        <PrivateRoute path="/nursing-home/requests" isLoggedIn={loggedIn}>
                                            <NursingHomeRequestsPage />
                                        </PrivateRoute>
                                        <PrivateRoute
                                            path="/school/requests"
                                            isLoggedIn={loggedIn}
                                            isAvailable={featureList.isSchoolRequestsEnabled}
                                        >
                                            <SchoolPage />
                                        </PrivateRoute>
                                        <PrivateRoute path="/placement-follow-ups" isLoggedIn={loggedIn}>
                                            <FollowUpsPage />
                                        </PrivateRoute>
                                        <PrivateRoute path="/requests" isLoggedIn={loggedIn} isAvailable={featureList.isHCEnabled}>
                                            <RequestsPage />
                                        </PrivateRoute>
                                        <PrivateRoute path="/logging" isLoggedIn={loggedIn} isAvailable={featureList.isHCEnabled}>
                                            <LoggingPage />
                                        </PrivateRoute>
                                        <PrivateRoute path="/patients" isLoggedIn={loggedIn} isAvailable={featureList.isHCEnabled}>
                                            <PatientsPage />
                                        </PrivateRoute>
                                        <PrivateRoute path="/physicians" isLoggedIn={loggedIn}>
                                            <PhysiciansPage />
                                        </PrivateRoute>
                                        <PrivateRoute path="/availability" isLoggedIn={loggedIn}>
                                            <AvailabilityPortal />
                                        </PrivateRoute>
                                        <PrivateRoute
                                            path="/settings"
                                            isLoggedIn={loggedIn}
                                            component={SettingsPage}
                                            isAvailable={featureList.isHCEnabled}
                                            hasAccess={hasSettingsEditPermission}
                                        />
                                        <PrivateRoute
                                            path="/users"
                                            isLoggedIn={loggedIn}
                                            component={UsersPage}
                                            hasAccess={
                                                hasCanViewInternalUsersPermission ||
                                                hasCanViewExternalUsersPermission ||
                                                hasCanUpdateInternalUsersPermission ||
                                                hasCanUpdateExternalUsersPermission
                                            }
                                        />
                                        <PrivateRoute
                                            path="/roles"
                                            isLoggedIn={loggedIn}
                                            component={RolesPage}
                                            hasAccess={hasCanManageRolesPermission}
                                        />
                                        <PrivateRoute path="/notes" isLoggedIn={loggedIn}>
                                            <NotesPage />
                                        </PrivateRoute>
                                        {featureList.isStaffingAgenciesEnabled && (
                                            <PrivateRoute
                                                path="/staffing-agencies"
                                                isLoggedIn={loggedIn}
                                                isAvailable={featureList.isStaffingAgenciesEnabled}
                                            >
                                                <StaffingAgenciesPage />
                                            </PrivateRoute>
                                        )}
                                    </Switch>
                                    {hasAccessToDashboardMenu !== null && (
                                        <Route
                                            exact
                                            path="/"
                                            render={() => <Redirect to={hasAccessToDashboardMenu ? `/dashboard` : '/providers'} />}
                                        />
                                    )}
                                </DefaultLayout>
                            </Switch>
                        )}
                    </Switch>
                    {featureList.isNotificationsEnabled && !isExternal && <NotificationsContainer loggedIn={loggedIn} />}
                </Router>
                <ToastContainer
                    position="top-center"
                    autoClose={5000}
                    hideProgressBar
                    newestOnTop={false}
                    closeOnClick
                    rtl={false}
                    pauseOnFocusLoss
                    draggable
                    pauseOnHover
                />
                {versionModal && <RefreshPopup />}
            </div>
        </>
    );
}

const mapStateToProps = (state) => ({
    loggedIn: state.auth.loggedIn || localStorage.getItem(ACCESS_TOKEN_KEY),
});

export default connect(mapStateToProps)(App);
