import React, { useEffect, useRef, useState } from 'react';
import DropdownAutocomplete from 'common/components/field/dropdown/dropdown-autocomplete';
import DropdownAutoPopulate from 'common/components/field/dropdown-auto-populate/dropdown-auto-populate';
import { PaginationOptions } from './models/pagination-options';
import { PaginationAutocompleteProps } from './models/pagination-autucomplete-props';
import { CommonSettings } from 'settings.json';
import DropdownOption from '../../../models/dropdown-option';

const PaginationAutocomplete = <T, TOption>(props: PaginationAutocompleteProps<T, TOption>) => {
    const { fetchTrigger, onDataLoaded } = props;
    const [term, setTerm] = useState<string>(props.defaultTerm);
    const [items, setItems] = useState<Array<TOption>>([]);
    const [pagination, setPagination] = useState<PaginationOptions>({ take: props.take || CommonSettings.DefaultPaginationSize, skip: 0 });
    const prevSkip = useRef<number>();
    const [selectedItems, setSelected] = useState<string[]>();
    const clientSideAutocompleteRef = useRef(false);
    const clientSideBuffer = useRef(null);
    const prevFetchTrigger = useRef(fetchTrigger);

    useEffect(() => {
        let shouldUpdate = true;

        if (clientSideAutocompleteRef.current && fetchTrigger === prevFetchTrigger.current) {
            if (term?.length > 0 && clientSideBuffer.current) {
                const termTrim: string = term?.trim()?.toLowerCase();
                const compareFunction = props.onCompareTerm
                    ? props.onCompareTerm(termTrim)
                    : (item: DropdownOption) => item?.label?.toLowerCase()?.includes(termTrim);

                setItems(clientSideBuffer.current.filter(compareFunction));
            } else {
                setItems(clientSideBuffer.current);
            }
        } else if (props.fetchData) {
            prevFetchTrigger.current = fetchTrigger;
            props.fetchData(term, pagination.skip, pagination.take, props.name).then((data) => {
                if (data.length > 0 && props.selectedItems?.length > 0) {
                    const selectedItems = data
                        .filter((d: any) => props.selectedItems.some((s: number) => d.id === s))
                        .map((d: any) => d.name);
                    setSelected([...selectedItems]);
                }
                if (data.length < pagination.take) {
                    setPagination((pagination) => ({ ...pagination, isLastPageLoaded: true }));
                }
                let currentItems: TOption[];
                if (prevSkip.current !== pagination.skip) {
                    // add items
                    prevSkip.current = pagination.skip;
                    setItems((items: any) => {
                        currentItems = [...items, ...data.map(props.convertFunction)];
                        if (props.clientSide) {
                            clientSideBuffer.current = currentItems;
                            clientSideAutocompleteRef.current = true;
                        }

                        return currentItems;
                    });
                } else {
                    if (!shouldUpdate) {
                        return;
                    }
                    // set items
                    currentItems = data.map(props.convertFunction);

                    // add/remove static options
                    if (term?.length > 0) {
                        setItems(currentItems);
                    } else {
                        setItems([...(props.staicItems ?? []), ...currentItems]);
                    }
                }

                if (props.clientSide) {
                    clientSideBuffer.current = currentItems;
                    clientSideAutocompleteRef.current = true;
                }

                if (onDataLoaded) {
                    onDataLoaded(currentItems);
                }
            });
        }

        return () => {
            shouldUpdate = false;
        };
    }, [pagination.skip, term, fetchTrigger]);

    const handleChange = (value: string) => {
        props.onInputChange?.(value);
        prevSkip.current = 0;
        setPagination((pagination) => ({ ...pagination, skip: 0, isLastPageLoaded: false }));
        setTerm(value);
    };

    const handleSelect = (item: any) => {
        props.onSelect?.(item as DropdownOption | DropdownOption[]);
        if (!props.multiselect) {
            setTerm('');
        }
        prevSkip.current = 0;
        setPagination((pagination) => ({ ...pagination, skip: 0, isLastPageLoaded: false }));
    };

    const handleScrollEnd = () => {
        if (!pagination.isLastPageLoaded) {
            setPagination((pagination) => ({ ...pagination, skip: pagination.skip + pagination.take }));
        }
    };

    const handleOnBluer = () => {
        prevSkip.current = 0;
        setPagination((pagination) => ({ ...pagination, skip: 0, isLastPageLoaded: false }));
        setTerm('');
    };

    return (
        <div className={props.className ? props.className : ''}>
            {props.autoPopulateDropdown ? (
                <DropdownAutoPopulate
                    hasError={props.hasError}
                    id={props.id}
                    items={items as DropdownOption[]}
                    name={props.name}
                    tabIndex={props.tabIndex}
                    onBlur={handleOnBluer}
                    onDelete={props.onDelete}
                    label={props.placeholder || ''}
                    onScrollEnd={handleScrollEnd}
                    value={props.defaultValue || props.defaultTerm}
                    onChange={handleChange}
                    onSelect={handleSelect}
                    autoPopulateDropdownCallback={props.autoPopulateDropdownCallback}
                    fetchTrigger={fetchTrigger}
                    errorMessage={props.errorMessage}
                    maxInputLength={props.maxInputLength}
                />
            ) : (
                <DropdownAutocomplete
                    multiselect={props?.multiselect || false}
                    readOnly={props?.readOnly}
                    disabled={props?.disabled}
                    hasError={props.hasError}
                    id={props.id}
                    cleanable={props.cleanable ?? true}
                    items={items as DropdownOption[]}
                    name={props.name}
                    tabIndex={props.tabIndex}
                    onBlur={handleOnBluer}
                    onDelete={props.onDelete}
                    showSelectedCounter={props.showSelectedCounter}
                    defaultSelectedLabel={props.defaultSelectedLabel}
                    isHandleOnSave={props.isHandleOnSave}
                    label={props.placeholder || ''}
                    onScrollEnd={handleScrollEnd}
                    value={props.defaultValue || selectedItems}
                    onChange={handleChange}
                    onSelect={handleSelect}
                    onClose={() => {
                        if (props.multiselect) {
                            setTerm('');
                        }
                    }}
                    selectDebounceMs={props.selectDebounceMs}
                    fetchTrigger={fetchTrigger}
                    optionWrapper={props.onOptionWrapper}
                    onRenderOption={props.onRenderOption}
                    onFormatOption={props.onFormatOption}
                    onRenderInput={props.onRenderInput}
                />
            )}
        </div>
    );
};

export default PaginationAutocomplete;
