import { Dispatch } from '@reduxjs/toolkit';
import { DisciplineService } from 'common/services/api/disciplince/disciplince-service';
import { IDiscipline } from 'common/services/api/disciplince/types';
import { ISetDisciplinesAction, ThunkResult } from './types';

export const setDisciplinces = (payload: IDiscipline[]): ISetDisciplinesAction => {
    return {
        discplines: payload,
        type: 'SET_DISCIPLINES',
    };
};

export const fetchDisciplines = (): ThunkResult<Promise<IDiscipline[]>> => {
    return async (dispatch: Dispatch) => {
        const disciplines = await new DisciplineService().getAll();
        dispatch(setDisciplinces(disciplines));

        return disciplines;
    };
};
