import Avatar from 'common/components/Avatar/Avatar';
import Chip from 'common/components/chip/chip';
import React from 'react';
import Moment from 'moment';
import StatusDropdown from 'common/components/status-dropdown/status-dropdown';
import DropdownOption from 'common/models/dropdown-option';
import { IEntityDetails } from 'common/services/api/entity/types';
import { EntityStatus, displayEntityStatus } from './constant';
import { dateTimeConstants } from 'common/constants/common';
import './entity-header-info.scss';

type EntityHeaderInfoProp = {
    details: IEntityDetails;
    handleChangeStatus: (id: number, status: any) => void;
};

const EntityHeaderInfo: React.FC<EntityHeaderInfoProp> = ({ details, handleChangeStatus }) => {
    const { phone, profileImage, createdAt, name, functionLinks } = details;

    const options = [
        new DropdownOption(EntityStatus.ACTIVE.value, EntityStatus.ACTIVE.label),
        new DropdownOption(EntityStatus.ARCHIVED.value, EntityStatus.ARCHIVED.label),
    ];

    const statusClass = details.status?.toLowerCase ? details.status?.toLowerCase() : null;

    return (
        <div className="general-info entity-header-info">
            <div className="bio">
                {profileImage && <Avatar imageData={profileImage} />}
                <div className="personal-data">
                    <div className="fullname">{name}</div>
                    <div className="note">{functionLinks && functionLinks.map((type) => type?.legalFunction?.name).join(', ')}</div>
                    <div className="bio-footer">
                        <span className="description">Created on {Moment(createdAt).local().format(dateTimeConstants.MMM_D_YYY)}</span>
                        <span className={`bio-status ${statusClass}`}>{displayEntityStatus(details.status)}</span>
                    </div>
                </div>
            </div>
            <div className="contact-info">
                <div className="contact-info-wrapper">{phone && <Chip iconClass="icon-phone" content={phone} isPhoneNumber />}</div>
                <div className="general-info-actions">
                    <StatusDropdown
                        options={options}
                        currentStatus={details.status}
                        onChange={(item: DropdownOption) => handleChangeStatus(details.id, item)}
                    />
                </div>
            </div>
        </div>
    );
};

export default EntityHeaderInfo;
