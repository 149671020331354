import React, { useCallback, useState } from 'react';
import { useSelector } from 'react-redux';
import BackgroundCheckDialog from './background-check-dialog';
import BackgroundChecksTable from './background-checks-table/background-checks-table';
import { BackgroundCheckOptions } from './types';

const BackgroundChecksTab: React.FC = () => {
    const therapistId = useSelector((state: any) => state.therapistDetails?.therapist?.id);

    const [forceUpdate, setForceUpdate] = useState(false);
    const [addTherapistBackgroundCheckOptions, setTherapistBackgroundCheckOptions] = useState<BackgroundCheckOptions>({
        isOpen: false,
        isEdit: false,
        isHistory: false,
        backgroundCheck: undefined,
    });

    const handleCloseAddingBackgroundCheckDialog = () => {
        setForceUpdate(true);
        setTherapistBackgroundCheckOptions({ isOpen: false, isEdit: false, isHistory: false, backgroundCheck: undefined });
        setForceUpdate(false);
    };

    const handleClickOnAddBackgroundCheck = useCallback((document: any) => {
        setTherapistBackgroundCheckOptions({ isOpen: true, isEdit: false, isHistory: false, backgroundCheck: document });
    }, []);

    const handleClickOnEditBackgroundCheck = useCallback((document: any) => {
        setTherapistBackgroundCheckOptions({ isOpen: true, isEdit: true, isHistory: false, backgroundCheck: document });
    }, []);

    const handleClickOnEditBackgroundCheckHistory = useCallback((document: any) => {
        setTherapistBackgroundCheckOptions({ isOpen: true, isEdit: true, isHistory: true, backgroundCheck: document });
    }, []);

    return (
        <div className="therapist-background-check">
            <BackgroundChecksTable
                therapistId={therapistId}
                onAddBackgroundCheckClick={handleClickOnAddBackgroundCheck}
                onEditBackgroundCheckClick={handleClickOnEditBackgroundCheck}
                onEditBackgroundCheckHistoryClick={handleClickOnEditBackgroundCheckHistory}
                forceUpdate={forceUpdate}
            />

            {addTherapistBackgroundCheckOptions.isOpen && (
                <BackgroundCheckDialog
                    therapistId={therapistId}
                    isEdit={addTherapistBackgroundCheckOptions.isEdit}
                    isHistory={addTherapistBackgroundCheckOptions.isHistory}
                    onCancel={handleCloseAddingBackgroundCheckDialog}
                    backgroundCheck={addTherapistBackgroundCheckOptions.backgroundCheck}
                ></BackgroundCheckDialog>
            )}
        </div>
    );
};

export default BackgroundChecksTab;
