import React from 'react';
import './chip.scss';
import { IChip } from './types';

const Chip = ({ iconClass, content, isPhoneNumber = false, onClick = undefined }: IChip) => {
    const handleClick = () => {
        if (onClick) {
            onClick();
        }
    };

    return (
        <div className="chip">
            <span className={`icon ${iconClass ?? ''}`}></span>
            {!isPhoneNumber && (
                <span className="text" onClick={handleClick}>
                    {content}
                </span>
            )}
            {isPhoneNumber && (
                <span className="text" onClick={handleClick}>
                    {`(${content.substring(0, 3)}) ${content.substring(3, 6)}-${content.substring(6, content.length)}`}
                </span>
            )}
        </div>
    );
};

export default Chip;
