import React, { useEffect } from 'react';
import classes from './Spinner.module.scss';
import { usePromiseTracker } from 'react-promise-tracker';

function Spinner() {
    useEffect(() => {
        const allElementsAtPage = document.querySelectorAll('body > #root > *');
        allElementsAtPage.forEach((node) => {
            const children = node.querySelectorAll('*');
            promiseInProgress ? node.setAttribute('tabindex', '-1') : node.removeAttribute('tabindex');
            children.forEach((element) => {
                promiseInProgress ? element.setAttribute('tabindex', '-1') : element.removeAttribute('tabindex');
            });
        });
    });

    const { promiseInProgress } = usePromiseTracker();

    return (
        <div>
            {promiseInProgress && (
                <div className="main-spinner-wrapper">
                    <div className={classes.loadingSpinner}></div>
                </div>
            )}
        </div>
    );
}

export default Spinner;
