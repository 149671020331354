import { CUSTOMER } from 'common/constants/common';
import RequestTable, { requestColumnDefs } from 'features/request-page/components/requests-table';
import React from 'react';
import { GeneralInfoTabProp } from '../types';

const EntityRequestListTab: React.FC<GeneralInfoTabProp> = ({ id: entityId }) => {
    return (
        <div className="entity-child-tab tabs-active-horizontal table-page">
            <RequestTable
                storePrefix="entity-requests"
                extendGridOptions={{
                    columnDefs: customerColumnDef,
                }}
                extendDefaultCustomFilters={{ entityId: entityId }}
                serachTitle="Entity Requests"
            />
        </div>
    );
};

const customerColumnDef = [...requestColumnDefs];
const indexCustomerName = requestColumnDefs.findIndex((columnConfig) => columnConfig.field === CUSTOMER);

if (indexCustomerName > -1) {
    customerColumnDef[indexCustomerName] = {
        field: CUSTOMER,
        headerName: 'Customer',
        cellRenderer: 'objectLinkValueRender',
        flex: 1,
        width: 230,
        hide: true,
        resizable: true,
    };
}

export default EntityRequestListTab;
