import { IOption } from 'common/models/types';

export class CoverageTypeService {
    getOptions(_term?: string, _skip?: number, _take?: number, _fieldId?: string, _correlatedIds?: number[]): Promise<IOption[]> {
        return new Promise((resolve) => resolve({})).then(
            () => {
                return [
                    { id: 0, name: 'Individual Days' },
                    { id: 1, name: 'Date Range' },
                ];
            },
            (error) => {
                throw error;
            }
        );
    }
}

export default new CoverageTypeService();
