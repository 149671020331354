export const TherapistStatus = {
    NEW: { label: 'New', value: 'New' },
    SCHEDULE_INTERVIEW: { label: 'Schedule Interview', value: 'ScheduleInterview' },
    INTERVIEW_SCHEDULED: { label: 'Interview Scheduled', value: 'InterviewScheduled' },
    ONBOARDING: { label: 'On Boarding', value: 'OnBoarding' },
    ACTIVE: { label: 'Active', value: 'Active' },
    INACTIVE: { label: 'Inactive', value: 'Inactive' },
    IN_PROCESS: { label: 'In Process', value: 'InProcess' },
    READY_TO_WORK: { label: 'Ready to work', value: 'ReadyToWork' },
    UNKNOWN: { label: 'Unknown', value: 'Unknown' },
    PAUSE: { label: 'Pause', value: 'Pause' },
};

export const displayTherapistStatus = (value: string) => {
    if (!value) {
        return '';
    }

    return Object.values(TherapistStatus).find((x) => x.value === value)?.label;
};
